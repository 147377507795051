import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  Hex: any;
  Json: any;
  Long: any;
  RGBAHue: any;
  RGBATransparency: any;
  RichTextAST: any;
};

export type Accordion = Entity & {
  __typename?: 'Accordion';
  content: RichText;
  /** The unique identifier */
  id: Scalars['ID'];
  label: Scalars['String'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Accordion>;
  /** System stage field */
  stage: Stage;
};

export type AccordionLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type AccordionConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: AccordionWhereUniqueInput;
};

/** A connection to a list of items. */
export type AccordionConnection = {
  __typename?: 'AccordionConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<AccordionEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type AccordionCreateInput = {
  /** content input for default locale (default) */
  content: Scalars['RichTextAST'];
  /** label input for default locale (default) */
  label: Scalars['String'];
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<AccordionCreateLocalizationsInput>;
};

export type AccordionCreateLocalizationDataInput = {
  content: Scalars['RichTextAST'];
  label: Scalars['String'];
};

export type AccordionCreateLocalizationInput = {
  /** Localization input */
  data: AccordionCreateLocalizationDataInput;
  locale: Locale;
};

export type AccordionCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<AccordionCreateLocalizationInput>>;
};

export type AccordionCreateManyInlineInput = {
  /** Create and connect multiple existing Accordion documents */
  create?: InputMaybe<Array<AccordionCreateInput>>;
};

export type AccordionCreateOneInlineInput = {
  /** Create and connect one Accordion document */
  create?: InputMaybe<AccordionCreateInput>;
};

export type AccordionCreateWithPositionInput = {
  /** Document to create */
  data: AccordionCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type AccordionEdge = {
  __typename?: 'AccordionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Accordion;
};

export type AccordionGroup = Entity & {
  __typename?: 'AccordionGroup';
  accordions: Array<Accordion>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System stage field */
  stage: Stage;
};

export type AccordionGroupAccordionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AccordionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AccordionWhereInput>;
};

export type AccordionGroupConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: AccordionGroupWhereUniqueInput;
};

/** A connection to a list of items. */
export type AccordionGroupConnection = {
  __typename?: 'AccordionGroupConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<AccordionGroupEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type AccordionGroupCreateInput = {
  accordions: AccordionCreateManyInlineInput;
};

export type AccordionGroupCreateManyInlineInput = {
  /** Create and connect multiple existing AccordionGroup documents */
  create?: InputMaybe<Array<AccordionGroupCreateInput>>;
};

export type AccordionGroupCreateOneInlineInput = {
  /** Create and connect one AccordionGroup document */
  create?: InputMaybe<AccordionGroupCreateInput>;
};

export type AccordionGroupCreateWithPositionInput = {
  /** Document to create */
  data: AccordionGroupCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type AccordionGroupEdge = {
  __typename?: 'AccordionGroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: AccordionGroup;
};

/** Identifies documents */
export type AccordionGroupManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AccordionGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AccordionGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AccordionGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  accordions_every?: InputMaybe<AccordionWhereInput>;
  accordions_none?: InputMaybe<AccordionWhereInput>;
  accordions_some?: InputMaybe<AccordionWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
};

export enum AccordionGroupOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type AccordionGroupParent = CBlock;

export type AccordionGroupParentConnectInput = {
  CBlock?: InputMaybe<CBlockConnectInput>;
};

export type AccordionGroupParentCreateInput = {
  CBlock?: InputMaybe<CBlockCreateInput>;
};

export type AccordionGroupParentCreateManyInlineInput = {
  /** Connect multiple existing AccordionGroupParent documents */
  connect?: InputMaybe<Array<AccordionGroupParentWhereUniqueInput>>;
  /** Create and connect multiple existing AccordionGroupParent documents */
  create?: InputMaybe<Array<AccordionGroupParentCreateInput>>;
};

export type AccordionGroupParentCreateOneInlineInput = {
  /** Connect one existing AccordionGroupParent document */
  connect?: InputMaybe<AccordionGroupParentWhereUniqueInput>;
  /** Create and connect one AccordionGroupParent document */
  create?: InputMaybe<AccordionGroupParentCreateInput>;
};

export type AccordionGroupParentUpdateInput = {
  CBlock?: InputMaybe<CBlockUpdateInput>;
};

export type AccordionGroupParentUpdateManyInlineInput = {
  /** Connect multiple existing AccordionGroupParent documents */
  connect?: InputMaybe<Array<AccordionGroupParentConnectInput>>;
  /** Create and connect multiple AccordionGroupParent documents */
  create?: InputMaybe<Array<AccordionGroupParentCreateInput>>;
  /** Delete multiple AccordionGroupParent documents */
  delete?: InputMaybe<Array<AccordionGroupParentWhereUniqueInput>>;
  /** Disconnect multiple AccordionGroupParent documents */
  disconnect?: InputMaybe<Array<AccordionGroupParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing AccordionGroupParent documents */
  set?: InputMaybe<Array<AccordionGroupParentWhereUniqueInput>>;
  /** Update multiple AccordionGroupParent documents */
  update?: InputMaybe<
    Array<AccordionGroupParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple AccordionGroupParent documents */
  upsert?: InputMaybe<
    Array<AccordionGroupParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type AccordionGroupParentUpdateManyWithNestedWhereInput = {
  CBlock?: InputMaybe<CBlockUpdateManyWithNestedWhereInput>;
};

export type AccordionGroupParentUpdateOneInlineInput = {
  /** Connect existing AccordionGroupParent document */
  connect?: InputMaybe<AccordionGroupParentWhereUniqueInput>;
  /** Create and connect one AccordionGroupParent document */
  create?: InputMaybe<AccordionGroupParentCreateInput>;
  /** Delete currently connected AccordionGroupParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected AccordionGroupParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single AccordionGroupParent document */
  update?: InputMaybe<AccordionGroupParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single AccordionGroupParent document */
  upsert?: InputMaybe<AccordionGroupParentUpsertWithNestedWhereUniqueInput>;
};

export type AccordionGroupParentUpdateWithNestedWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockUpdateWithNestedWhereUniqueInput>;
};

export type AccordionGroupParentUpsertWithNestedWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockUpsertWithNestedWhereUniqueInput>;
};

export type AccordionGroupParentWhereInput = {
  CBlock?: InputMaybe<CBlockWhereInput>;
};

export type AccordionGroupParentWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockWhereUniqueInput>;
};

export type AccordionGroupUpdateInput = {
  accordions?: InputMaybe<AccordionUpdateManyInlineInput>;
};

export type AccordionGroupUpdateManyInlineInput = {
  /** Create and connect multiple AccordionGroup component instances */
  create?: InputMaybe<Array<AccordionGroupCreateWithPositionInput>>;
  /** Delete multiple AccordionGroup documents */
  delete?: InputMaybe<Array<AccordionGroupWhereUniqueInput>>;
  /** Update multiple AccordionGroup component instances */
  update?: InputMaybe<
    Array<AccordionGroupUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple AccordionGroup component instances */
  upsert?: InputMaybe<
    Array<AccordionGroupUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type AccordionGroupUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']>;
};

export type AccordionGroupUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: AccordionGroupUpdateManyInput;
  /** Document search */
  where: AccordionGroupWhereInput;
};

export type AccordionGroupUpdateOneInlineInput = {
  /** Create and connect one AccordionGroup document */
  create?: InputMaybe<AccordionGroupCreateInput>;
  /** Delete currently connected AccordionGroup document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single AccordionGroup document */
  update?: InputMaybe<AccordionGroupUpdateWithNestedWhereUniqueInput>;
  /** Upsert single AccordionGroup document */
  upsert?: InputMaybe<AccordionGroupUpsertWithNestedWhereUniqueInput>;
};

export type AccordionGroupUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<AccordionGroupUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: AccordionGroupWhereUniqueInput;
};

export type AccordionGroupUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: AccordionGroupUpdateInput;
  /** Unique document search */
  where: AccordionGroupWhereUniqueInput;
};

export type AccordionGroupUpsertInput = {
  /** Create document if it didn't exist */
  create: AccordionGroupCreateInput;
  /** Update document if it exists */
  update: AccordionGroupUpdateInput;
};

export type AccordionGroupUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<AccordionGroupUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: AccordionGroupWhereUniqueInput;
};

export type AccordionGroupUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: AccordionGroupUpsertInput;
  /** Unique document search */
  where: AccordionGroupWhereUniqueInput;
};

/** Identifies documents */
export type AccordionGroupWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AccordionGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AccordionGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AccordionGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  accordions_every?: InputMaybe<AccordionWhereInput>;
  accordions_none?: InputMaybe<AccordionWhereInput>;
  accordions_some?: InputMaybe<AccordionWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
};

/** References AccordionGroup record uniquely */
export type AccordionGroupWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Identifies documents */
export type AccordionManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AccordionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AccordionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AccordionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
};

export enum AccordionOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
}

export type AccordionParent = AccordionGroup | EmbedAccordionGroup;

export type AccordionParentConnectInput = {
  AccordionGroup?: InputMaybe<AccordionGroupConnectInput>;
  EmbedAccordionGroup?: InputMaybe<EmbedAccordionGroupConnectInput>;
};

export type AccordionParentCreateInput = {
  AccordionGroup?: InputMaybe<AccordionGroupCreateInput>;
  EmbedAccordionGroup?: InputMaybe<EmbedAccordionGroupCreateInput>;
};

export type AccordionParentCreateManyInlineInput = {
  /** Connect multiple existing AccordionParent documents */
  connect?: InputMaybe<Array<AccordionParentWhereUniqueInput>>;
  /** Create and connect multiple existing AccordionParent documents */
  create?: InputMaybe<Array<AccordionParentCreateInput>>;
};

export type AccordionParentCreateOneInlineInput = {
  /** Connect one existing AccordionParent document */
  connect?: InputMaybe<AccordionParentWhereUniqueInput>;
  /** Create and connect one AccordionParent document */
  create?: InputMaybe<AccordionParentCreateInput>;
};

export type AccordionParentUpdateInput = {
  AccordionGroup?: InputMaybe<AccordionGroupUpdateInput>;
  EmbedAccordionGroup?: InputMaybe<EmbedAccordionGroupUpdateInput>;
};

export type AccordionParentUpdateManyInlineInput = {
  /** Connect multiple existing AccordionParent documents */
  connect?: InputMaybe<Array<AccordionParentConnectInput>>;
  /** Create and connect multiple AccordionParent documents */
  create?: InputMaybe<Array<AccordionParentCreateInput>>;
  /** Delete multiple AccordionParent documents */
  delete?: InputMaybe<Array<AccordionParentWhereUniqueInput>>;
  /** Disconnect multiple AccordionParent documents */
  disconnect?: InputMaybe<Array<AccordionParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing AccordionParent documents */
  set?: InputMaybe<Array<AccordionParentWhereUniqueInput>>;
  /** Update multiple AccordionParent documents */
  update?: InputMaybe<Array<AccordionParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple AccordionParent documents */
  upsert?: InputMaybe<Array<AccordionParentUpsertWithNestedWhereUniqueInput>>;
};

export type AccordionParentUpdateManyWithNestedWhereInput = {
  AccordionGroup?: InputMaybe<AccordionGroupUpdateManyWithNestedWhereInput>;
  EmbedAccordionGroup?: InputMaybe<EmbedAccordionGroupUpdateManyWithNestedWhereInput>;
};

export type AccordionParentUpdateOneInlineInput = {
  /** Connect existing AccordionParent document */
  connect?: InputMaybe<AccordionParentWhereUniqueInput>;
  /** Create and connect one AccordionParent document */
  create?: InputMaybe<AccordionParentCreateInput>;
  /** Delete currently connected AccordionParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected AccordionParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single AccordionParent document */
  update?: InputMaybe<AccordionParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single AccordionParent document */
  upsert?: InputMaybe<AccordionParentUpsertWithNestedWhereUniqueInput>;
};

export type AccordionParentUpdateWithNestedWhereUniqueInput = {
  AccordionGroup?: InputMaybe<AccordionGroupUpdateWithNestedWhereUniqueInput>;
  EmbedAccordionGroup?: InputMaybe<EmbedAccordionGroupUpdateWithNestedWhereUniqueInput>;
};

export type AccordionParentUpsertWithNestedWhereUniqueInput = {
  AccordionGroup?: InputMaybe<AccordionGroupUpsertWithNestedWhereUniqueInput>;
  EmbedAccordionGroup?: InputMaybe<EmbedAccordionGroupUpsertWithNestedWhereUniqueInput>;
};

export type AccordionParentWhereInput = {
  AccordionGroup?: InputMaybe<AccordionGroupWhereInput>;
  EmbedAccordionGroup?: InputMaybe<EmbedAccordionGroupWhereInput>;
};

export type AccordionParentWhereUniqueInput = {
  AccordionGroup?: InputMaybe<AccordionGroupWhereUniqueInput>;
  EmbedAccordionGroup?: InputMaybe<EmbedAccordionGroupWhereUniqueInput>;
};

export type AccordionUpdateInput = {
  /** content input for default locale (default) */
  content?: InputMaybe<Scalars['RichTextAST']>;
  /** label input for default locale (default) */
  label?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<AccordionUpdateLocalizationsInput>;
};

export type AccordionUpdateLocalizationDataInput = {
  content?: InputMaybe<Scalars['RichTextAST']>;
  label?: InputMaybe<Scalars['String']>;
};

export type AccordionUpdateLocalizationInput = {
  data: AccordionUpdateLocalizationDataInput;
  locale: Locale;
};

export type AccordionUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<AccordionCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<AccordionUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<AccordionUpsertLocalizationInput>>;
};

export type AccordionUpdateManyInlineInput = {
  /** Create and connect multiple Accordion component instances */
  create?: InputMaybe<Array<AccordionCreateWithPositionInput>>;
  /** Delete multiple Accordion documents */
  delete?: InputMaybe<Array<AccordionWhereUniqueInput>>;
  /** Update multiple Accordion component instances */
  update?: InputMaybe<
    Array<AccordionUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple Accordion component instances */
  upsert?: InputMaybe<
    Array<AccordionUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type AccordionUpdateManyInput = {
  /** content input for default locale (default) */
  content?: InputMaybe<Scalars['RichTextAST']>;
  /** label input for default locale (default) */
  label?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<AccordionUpdateManyLocalizationsInput>;
};

export type AccordionUpdateManyLocalizationDataInput = {
  content?: InputMaybe<Scalars['RichTextAST']>;
  label?: InputMaybe<Scalars['String']>;
};

export type AccordionUpdateManyLocalizationInput = {
  data: AccordionUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type AccordionUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<AccordionUpdateManyLocalizationInput>>;
};

export type AccordionUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: AccordionUpdateManyInput;
  /** Document search */
  where: AccordionWhereInput;
};

export type AccordionUpdateOneInlineInput = {
  /** Create and connect one Accordion document */
  create?: InputMaybe<AccordionCreateInput>;
  /** Delete currently connected Accordion document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single Accordion document */
  update?: InputMaybe<AccordionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Accordion document */
  upsert?: InputMaybe<AccordionUpsertWithNestedWhereUniqueInput>;
};

export type AccordionUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<AccordionUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: AccordionWhereUniqueInput;
};

export type AccordionUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: AccordionUpdateInput;
  /** Unique document search */
  where: AccordionWhereUniqueInput;
};

export type AccordionUpsertInput = {
  /** Create document if it didn't exist */
  create: AccordionCreateInput;
  /** Update document if it exists */
  update: AccordionUpdateInput;
};

export type AccordionUpsertLocalizationInput = {
  create: AccordionCreateLocalizationDataInput;
  locale: Locale;
  update: AccordionUpdateLocalizationDataInput;
};

export type AccordionUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<AccordionUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: AccordionWhereUniqueInput;
};

export type AccordionUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: AccordionUpsertInput;
  /** Unique document search */
  where: AccordionWhereUniqueInput;
};

/** Identifies documents */
export type AccordionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AccordionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AccordionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AccordionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  label?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  label_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  label_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  label_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  label_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  label_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  label_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  label_starts_with?: InputMaybe<Scalars['String']>;
};

/** References Accordion record uniquely */
export type AccordionWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Size for Action Group. */
export enum ActionGroupSize {
  Full = 'full',
}

export type Aggregate = {
  __typename?: 'Aggregate';
  count: Scalars['Int'];
};

/** Represents alignment. */
export enum Alignment {
  Centre = 'Centre',
  Left = 'Left',
  Right = 'Right',
}

/** Speed modifier for various animation */
export enum AnimationSpeed {
  Fast = 'fast',
  Normal = 'normal',
  Slow = 'slow',
  VeryFast = 'very_fast',
  VerySlow = 'very_slow',
}

export type Article = Entity &
  Node & {
    __typename?: 'Article';
    /** Add components to display after the Article content. Overwrites any additional content specified in the Category Model. */
    additionalContent?: Maybe<ArticleAdditionalContent>;
    articleType?: Maybe<ArticleType>;
    authors: Array<Author>;
    category?: Maybe<Category>;
    /** This field represents the content of the article. */
    content: ArticleContentRichText;
    /** The time the document was created */
    createdAt: Scalars['DateTime'];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<Article>;
    /** Represents a short passage in the article. */
    excerpt?: Maybe<Scalars['String']>;
    /** Represents an image content file for the article. */
    featureImage: Array<Asset>;
    featuredArticleSites: Array<Site>;
    hideEmbeddedFormInSection?: Maybe<Section>;
    /** List of Article versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars['ID'];
    /** System Locale field */
    locale: Locale;
    /** Get the other localizations for this document */
    localizations: Array<Article>;
    /** media url of article, supported domains: youtu.be, youtube.com/embed, youtube.com/watch, players.brightcove.net, megaphone.fm, spotify.com */
    mediaUrl?: Maybe<Scalars['String']>;
    /** It is used for migration identification purposes. Represents a value from wp_posts.ID. */
    migratedPostID?: Maybe<Scalars['Int']>;
    /** It identifies a WordPress site value. */
    migratedSiteID?: Maybe<Scalars['String']>;
    /** It identifies a post of URL that is migrated from the WordPress site. */
    migratedURL?: Maybe<Scalars['String']>;
    /** Podcast information and links */
    podcast?: Maybe<Podcast>;
    /** Represents the publish date of the article.. */
    publishDate: Scalars['Date'];
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    seo?: Maybe<Seo>;
    /** Toggle excerpt visibility in article */
    showExcerpt: Scalars['Boolean'];
    sites: Array<Site>;
    /** Represents a tag name for the article that contains keywords separated by hyphens. */
    slug: Scalars['String'];
    /** System stage field */
    stage: Stage;
    /** Represents an integer value that shows how long it will take to read the content. */
    timeToRead?: Maybe<Scalars['Float']>;
    /** It describes articles title.  */
    title: Scalars['String'];
    topic: Array<Topic>;
    /** The time the document was updated */
    updatedAt: Scalars['DateTime'];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type ArticleAdditionalContentArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ArticleAuthorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AuthorOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AuthorWhereInput>;
};

export type ArticleCategoryArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ArticleCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type ArticleCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ArticleDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type ArticleFeatureImageArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetWhereInput>;
};

export type ArticleFeaturedArticleSitesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<SiteOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SiteWhereInput>;
};

export type ArticleHideEmbeddedFormInSectionArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ArticleHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};

export type ArticleLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type ArticlePodcastArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ArticlePublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type ArticlePublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ArticleScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type ArticleSeoArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ArticleSitesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<SiteOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SiteWhereInput>;
};

export type ArticleTopicArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<TopicOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TopicWhereInput>;
};

export type ArticleUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type ArticleUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** A minimal container component used to add additional content to an Article. */
export type ArticleAdditionalContent = Entity & {
  __typename?: 'ArticleAdditionalContent';
  box?: Maybe<CBox>;
  components: Array<ArticleAdditionalContentcomponentsUnion>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System stage field */
  stage: Stage;
  tile?: Maybe<CTile>;
};

/** A minimal container component used to add additional content to an Article. */
export type ArticleAdditionalContentBoxArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** A minimal container component used to add additional content to an Article. */
export type ArticleAdditionalContentComponentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
};

/** A minimal container component used to add additional content to an Article. */
export type ArticleAdditionalContentTileArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ArticleAdditionalContentConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ArticleAdditionalContentWhereUniqueInput;
};

/** A connection to a list of items. */
export type ArticleAdditionalContentConnection = {
  __typename?: 'ArticleAdditionalContentConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ArticleAdditionalContentEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ArticleAdditionalContentCreateInput = {
  box?: InputMaybe<CBoxCreateOneInlineInput>;
  components?: InputMaybe<ArticleAdditionalContentcomponentsUnionCreateManyInlineInput>;
  tile?: InputMaybe<CTileCreateOneInlineInput>;
};

export type ArticleAdditionalContentCreateManyInlineInput = {
  /** Create and connect multiple existing ArticleAdditionalContent documents */
  create?: InputMaybe<Array<ArticleAdditionalContentCreateInput>>;
};

export type ArticleAdditionalContentCreateOneInlineInput = {
  /** Create and connect one ArticleAdditionalContent document */
  create?: InputMaybe<ArticleAdditionalContentCreateInput>;
};

export type ArticleAdditionalContentCreateWithPositionInput = {
  /** Document to create */
  data: ArticleAdditionalContentCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type ArticleAdditionalContentEdge = {
  __typename?: 'ArticleAdditionalContentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ArticleAdditionalContent;
};

/** Identifies documents */
export type ArticleAdditionalContentManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ArticleAdditionalContentWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ArticleAdditionalContentWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ArticleAdditionalContentWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  box?: InputMaybe<CBoxWhereInput>;
  /** All values in which the union is empty. */
  components_empty?: InputMaybe<Scalars['Boolean']>;
  /** Matches if the modular component contains at least one connection to the item provided to the filter */
  components_some?: InputMaybe<ArticleAdditionalContentcomponentsUnionWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  tile?: InputMaybe<CTileWhereInput>;
};

export enum ArticleAdditionalContentOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type ArticleAdditionalContentParent = Article | Category;

export type ArticleAdditionalContentParentConnectInput = {
  Article?: InputMaybe<ArticleConnectInput>;
  Category?: InputMaybe<CategoryConnectInput>;
};

export type ArticleAdditionalContentParentCreateInput = {
  Article?: InputMaybe<ArticleCreateInput>;
  Category?: InputMaybe<CategoryCreateInput>;
};

export type ArticleAdditionalContentParentCreateManyInlineInput = {
  /** Connect multiple existing ArticleAdditionalContentParent documents */
  connect?: InputMaybe<Array<ArticleAdditionalContentParentWhereUniqueInput>>;
  /** Create and connect multiple existing ArticleAdditionalContentParent documents */
  create?: InputMaybe<Array<ArticleAdditionalContentParentCreateInput>>;
};

export type ArticleAdditionalContentParentCreateOneInlineInput = {
  /** Connect one existing ArticleAdditionalContentParent document */
  connect?: InputMaybe<ArticleAdditionalContentParentWhereUniqueInput>;
  /** Create and connect one ArticleAdditionalContentParent document */
  create?: InputMaybe<ArticleAdditionalContentParentCreateInput>;
};

export type ArticleAdditionalContentParentUpdateInput = {
  Article?: InputMaybe<ArticleUpdateInput>;
  Category?: InputMaybe<CategoryUpdateInput>;
};

export type ArticleAdditionalContentParentUpdateManyInlineInput = {
  /** Connect multiple existing ArticleAdditionalContentParent documents */
  connect?: InputMaybe<Array<ArticleAdditionalContentParentConnectInput>>;
  /** Create and connect multiple ArticleAdditionalContentParent documents */
  create?: InputMaybe<Array<ArticleAdditionalContentParentCreateInput>>;
  /** Delete multiple ArticleAdditionalContentParent documents */
  delete?: InputMaybe<Array<ArticleAdditionalContentParentWhereUniqueInput>>;
  /** Disconnect multiple ArticleAdditionalContentParent documents */
  disconnect?: InputMaybe<
    Array<ArticleAdditionalContentParentWhereUniqueInput>
  >;
  /** Override currently-connected documents with multiple existing ArticleAdditionalContentParent documents */
  set?: InputMaybe<Array<ArticleAdditionalContentParentWhereUniqueInput>>;
  /** Update multiple ArticleAdditionalContentParent documents */
  update?: InputMaybe<
    Array<ArticleAdditionalContentParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple ArticleAdditionalContentParent documents */
  upsert?: InputMaybe<
    Array<ArticleAdditionalContentParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type ArticleAdditionalContentParentUpdateManyWithNestedWhereInput = {
  Article?: InputMaybe<ArticleUpdateManyWithNestedWhereInput>;
  Category?: InputMaybe<CategoryUpdateManyWithNestedWhereInput>;
};

export type ArticleAdditionalContentParentUpdateOneInlineInput = {
  /** Connect existing ArticleAdditionalContentParent document */
  connect?: InputMaybe<ArticleAdditionalContentParentWhereUniqueInput>;
  /** Create and connect one ArticleAdditionalContentParent document */
  create?: InputMaybe<ArticleAdditionalContentParentCreateInput>;
  /** Delete currently connected ArticleAdditionalContentParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected ArticleAdditionalContentParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single ArticleAdditionalContentParent document */
  update?: InputMaybe<ArticleAdditionalContentParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ArticleAdditionalContentParent document */
  upsert?: InputMaybe<ArticleAdditionalContentParentUpsertWithNestedWhereUniqueInput>;
};

export type ArticleAdditionalContentParentUpdateWithNestedWhereUniqueInput = {
  Article?: InputMaybe<ArticleUpdateWithNestedWhereUniqueInput>;
  Category?: InputMaybe<CategoryUpdateWithNestedWhereUniqueInput>;
};

export type ArticleAdditionalContentParentUpsertWithNestedWhereUniqueInput = {
  Article?: InputMaybe<ArticleUpsertWithNestedWhereUniqueInput>;
  Category?: InputMaybe<CategoryUpsertWithNestedWhereUniqueInput>;
};

export type ArticleAdditionalContentParentWhereInput = {
  Article?: InputMaybe<ArticleWhereInput>;
  Category?: InputMaybe<CategoryWhereInput>;
};

export type ArticleAdditionalContentParentWhereUniqueInput = {
  Article?: InputMaybe<ArticleWhereUniqueInput>;
  Category?: InputMaybe<CategoryWhereUniqueInput>;
};

export type ArticleAdditionalContentUpdateInput = {
  box?: InputMaybe<CBoxUpdateOneInlineInput>;
  components?: InputMaybe<ArticleAdditionalContentcomponentsUnionUpdateManyInlineInput>;
  tile?: InputMaybe<CTileUpdateOneInlineInput>;
};

export type ArticleAdditionalContentUpdateManyInlineInput = {
  /** Create and connect multiple ArticleAdditionalContent component instances */
  create?: InputMaybe<Array<ArticleAdditionalContentCreateWithPositionInput>>;
  /** Delete multiple ArticleAdditionalContent documents */
  delete?: InputMaybe<Array<ArticleAdditionalContentWhereUniqueInput>>;
  /** Update multiple ArticleAdditionalContent component instances */
  update?: InputMaybe<
    Array<ArticleAdditionalContentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple ArticleAdditionalContent component instances */
  upsert?: InputMaybe<
    Array<ArticleAdditionalContentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type ArticleAdditionalContentUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']>;
};

export type ArticleAdditionalContentUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ArticleAdditionalContentUpdateManyInput;
  /** Document search */
  where: ArticleAdditionalContentWhereInput;
};

export type ArticleAdditionalContentUpdateOneInlineInput = {
  /** Create and connect one ArticleAdditionalContent document */
  create?: InputMaybe<ArticleAdditionalContentCreateInput>;
  /** Delete currently connected ArticleAdditionalContent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single ArticleAdditionalContent document */
  update?: InputMaybe<ArticleAdditionalContentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ArticleAdditionalContent document */
  upsert?: InputMaybe<ArticleAdditionalContentUpsertWithNestedWhereUniqueInput>;
};

export type ArticleAdditionalContentUpdateWithNestedWhereUniqueAndPositionInput =
  {
    /** Document to update */
    data?: InputMaybe<ArticleAdditionalContentUpdateInput>;
    /** Position in the list of existing component instances, will default to appending at the end of list */
    position?: InputMaybe<ConnectPositionInput>;
    /** Unique component instance search */
    where: ArticleAdditionalContentWhereUniqueInput;
  };

export type ArticleAdditionalContentUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ArticleAdditionalContentUpdateInput;
  /** Unique document search */
  where: ArticleAdditionalContentWhereUniqueInput;
};

export type ArticleAdditionalContentUpsertInput = {
  /** Create document if it didn't exist */
  create: ArticleAdditionalContentCreateInput;
  /** Update document if it exists */
  update: ArticleAdditionalContentUpdateInput;
};

export type ArticleAdditionalContentUpsertWithNestedWhereUniqueAndPositionInput =
  {
    /** Document to upsert */
    data?: InputMaybe<ArticleAdditionalContentUpsertInput>;
    /** Position in the list of existing component instances, will default to appending at the end of list */
    position?: InputMaybe<ConnectPositionInput>;
    /** Unique component instance search */
    where: ArticleAdditionalContentWhereUniqueInput;
  };

export type ArticleAdditionalContentUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ArticleAdditionalContentUpsertInput;
  /** Unique document search */
  where: ArticleAdditionalContentWhereUniqueInput;
};

/** Identifies documents */
export type ArticleAdditionalContentWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ArticleAdditionalContentWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ArticleAdditionalContentWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ArticleAdditionalContentWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  box?: InputMaybe<CBoxWhereInput>;
  /** All values in which the union is empty. */
  components_empty?: InputMaybe<Scalars['Boolean']>;
  /** Matches if the modular component contains at least one connection to the item provided to the filter */
  components_some?: InputMaybe<ArticleAdditionalContentcomponentsUnionWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  tile?: InputMaybe<CTileWhereInput>;
};

/** References ArticleAdditionalContent record uniquely */
export type ArticleAdditionalContentWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type ArticleAdditionalContentcomponentsUnion = LatestArticlesByCategory;

export type ArticleAdditionalContentcomponentsUnionConnectInput = {
  LatestArticlesByCategory?: InputMaybe<LatestArticlesByCategoryConnectInput>;
};

export type ArticleAdditionalContentcomponentsUnionCreateInput = {
  LatestArticlesByCategory?: InputMaybe<LatestArticlesByCategoryCreateInput>;
};

export type ArticleAdditionalContentcomponentsUnionCreateManyInlineInput = {
  /** Create and connect multiple existing ArticleAdditionalContentcomponentsUnion documents */
  create?: InputMaybe<
    Array<ArticleAdditionalContentcomponentsUnionCreateInput>
  >;
};

export type ArticleAdditionalContentcomponentsUnionCreateOneInlineInput = {
  /** Create and connect one ArticleAdditionalContentcomponentsUnion document */
  create?: InputMaybe<ArticleAdditionalContentcomponentsUnionCreateInput>;
};

export type ArticleAdditionalContentcomponentsUnionCreateWithPositionInput = {
  LatestArticlesByCategory?: InputMaybe<LatestArticlesByCategoryCreateWithPositionInput>;
};

export type ArticleAdditionalContentcomponentsUnionUpdateInput = {
  LatestArticlesByCategory?: InputMaybe<LatestArticlesByCategoryUpdateInput>;
};

export type ArticleAdditionalContentcomponentsUnionUpdateManyInlineInput = {
  /** Create and connect multiple ArticleAdditionalContentcomponentsUnion component instances */
  create?: InputMaybe<
    Array<ArticleAdditionalContentcomponentsUnionCreateWithPositionInput>
  >;
  /** Delete multiple ArticleAdditionalContentcomponentsUnion documents */
  delete?: InputMaybe<
    Array<ArticleAdditionalContentcomponentsUnionWhereUniqueInput>
  >;
  /** Update multiple ArticleAdditionalContentcomponentsUnion component instances */
  update?: InputMaybe<
    Array<ArticleAdditionalContentcomponentsUnionUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple ArticleAdditionalContentcomponentsUnion component instances */
  upsert?: InputMaybe<
    Array<ArticleAdditionalContentcomponentsUnionUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type ArticleAdditionalContentcomponentsUnionUpdateManyWithNestedWhereInput =
  {
    LatestArticlesByCategory?: InputMaybe<LatestArticlesByCategoryUpdateManyWithNestedWhereInput>;
  };

export type ArticleAdditionalContentcomponentsUnionUpdateOneInlineInput = {
  /** Create and connect one ArticleAdditionalContentcomponentsUnion document */
  create?: InputMaybe<ArticleAdditionalContentcomponentsUnionCreateInput>;
  /** Delete currently connected ArticleAdditionalContentcomponentsUnion document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single ArticleAdditionalContentcomponentsUnion document */
  update?: InputMaybe<ArticleAdditionalContentcomponentsUnionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ArticleAdditionalContentcomponentsUnion document */
  upsert?: InputMaybe<ArticleAdditionalContentcomponentsUnionUpsertWithNestedWhereUniqueInput>;
};

export type ArticleAdditionalContentcomponentsUnionUpdateWithNestedWhereUniqueAndPositionInput =
  {
    LatestArticlesByCategory?: InputMaybe<LatestArticlesByCategoryUpdateWithNestedWhereUniqueAndPositionInput>;
  };

export type ArticleAdditionalContentcomponentsUnionUpdateWithNestedWhereUniqueInput =
  {
    LatestArticlesByCategory?: InputMaybe<LatestArticlesByCategoryUpdateWithNestedWhereUniqueInput>;
  };

export type ArticleAdditionalContentcomponentsUnionUpsertWithNestedWhereUniqueAndPositionInput =
  {
    LatestArticlesByCategory?: InputMaybe<LatestArticlesByCategoryUpsertWithNestedWhereUniqueAndPositionInput>;
  };

export type ArticleAdditionalContentcomponentsUnionUpsertWithNestedWhereUniqueInput =
  {
    LatestArticlesByCategory?: InputMaybe<LatestArticlesByCategoryUpsertWithNestedWhereUniqueInput>;
  };

export type ArticleAdditionalContentcomponentsUnionWhereInput = {
  LatestArticlesByCategory?: InputMaybe<LatestArticlesByCategoryWhereInput>;
};

export type ArticleAdditionalContentcomponentsUnionWhereUniqueInput = {
  LatestArticlesByCategory?: InputMaybe<LatestArticlesByCategoryWhereUniqueInput>;
};

export type ArticleConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ArticleWhereUniqueInput;
};

/** A connection to a list of items. */
export type ArticleConnection = {
  __typename?: 'ArticleConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ArticleEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ArticleContentRichText = {
  __typename?: 'ArticleContentRichText';
  /** Returns HTMl representation */
  html: Scalars['String'];
  json: Scalars['RichTextAST'];
  /** Returns Markdown representation */
  markdown: Scalars['String'];
  /** @deprecated Please use the 'json' field */
  raw: Scalars['RichTextAST'];
  references: Array<ArticleContentRichTextEmbeddedTypes>;
  /** Returns plain-text contents of RichText */
  text: Scalars['String'];
};

export type ArticleContentRichTextReferencesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type ArticleContentRichTextEmbeddedTypes =
  | EmbedAccordionGroup
  | EmbedCustomContent;

export type ArticleCreateInput = {
  additionalContent?: InputMaybe<ArticleAdditionalContentCreateOneInlineInput>;
  articleType?: InputMaybe<ArticleType>;
  authors?: InputMaybe<AuthorCreateManyInlineInput>;
  category?: InputMaybe<CategoryCreateOneInlineInput>;
  clxhi093r20m707t86884a4ek?: InputMaybe<CBannerArticleCreateManyInlineInput>;
  /** content input for default locale (default) */
  content: Scalars['RichTextAST'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** excerpt input for default locale (default) */
  excerpt?: InputMaybe<Scalars['String']>;
  featureImage?: InputMaybe<AssetCreateManyInlineInput>;
  featuredArticleSites?: InputMaybe<SiteCreateManyInlineInput>;
  hideEmbeddedFormInSection?: InputMaybe<SectionCreateOneInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<ArticleCreateLocalizationsInput>;
  /** mediaUrl input for default locale (default) */
  mediaUrl?: InputMaybe<Scalars['String']>;
  /** migratedPostID input for default locale (default) */
  migratedPostID?: InputMaybe<Scalars['Int']>;
  /** migratedSiteID input for default locale (default) */
  migratedSiteID?: InputMaybe<Scalars['String']>;
  /** migratedURL input for default locale (default) */
  migratedURL?: InputMaybe<Scalars['String']>;
  podcast?: InputMaybe<PodcastCreateOneInlineInput>;
  /** publishDate input for default locale (default) */
  publishDate: Scalars['Date'];
  seo?: InputMaybe<SeoCreateOneInlineInput>;
  showExcerpt: Scalars['Boolean'];
  sites?: InputMaybe<SiteCreateManyInlineInput>;
  /** slug input for default locale (default) */
  slug: Scalars['String'];
  /** timeToRead input for default locale (default) */
  timeToRead?: InputMaybe<Scalars['Float']>;
  /** title input for default locale (default) */
  title: Scalars['String'];
  topic?: InputMaybe<TopicCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ArticleCreateLocalizationDataInput = {
  content: Scalars['RichTextAST'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  excerpt?: InputMaybe<Scalars['String']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  migratedPostID?: InputMaybe<Scalars['Int']>;
  migratedSiteID?: InputMaybe<Scalars['String']>;
  migratedURL?: InputMaybe<Scalars['String']>;
  publishDate: Scalars['Date'];
  slug: Scalars['String'];
  timeToRead?: InputMaybe<Scalars['Float']>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ArticleCreateLocalizationInput = {
  /** Localization input */
  data: ArticleCreateLocalizationDataInput;
  locale: Locale;
};

export type ArticleCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<ArticleCreateLocalizationInput>>;
};

export type ArticleCreateManyInlineInput = {
  /** Connect multiple existing Article documents */
  connect?: InputMaybe<Array<ArticleWhereUniqueInput>>;
  /** Create and connect multiple existing Article documents */
  create?: InputMaybe<Array<ArticleCreateInput>>;
};

export type ArticleCreateOneInlineInput = {
  /** Connect one existing Article document */
  connect?: InputMaybe<ArticleWhereUniqueInput>;
  /** Create and connect one Article document */
  create?: InputMaybe<ArticleCreateInput>;
};

/** An edge in a connection. */
export type ArticleEdge = {
  __typename?: 'ArticleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Article;
};

/** Available features for the article */
export enum ArticleFeature {
  Category = 'category',
  Excerpt = 'excerpt',
  PublishDate = 'publishDate',
  ReadTime = 'readTime',
}

/** Identifies documents */
export type ArticleManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ArticleWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ArticleWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ArticleWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  additionalContent?: InputMaybe<ArticleAdditionalContentWhereInput>;
  articleType?: InputMaybe<ArticleType>;
  /** All values that are contained in given list. */
  articleType_in?: InputMaybe<Array<InputMaybe<ArticleType>>>;
  /** Any other value that exists and is not equal to the given value. */
  articleType_not?: InputMaybe<ArticleType>;
  /** All values that are not contained in given list. */
  articleType_not_in?: InputMaybe<Array<InputMaybe<ArticleType>>>;
  authors_every?: InputMaybe<AuthorWhereInput>;
  authors_none?: InputMaybe<AuthorWhereInput>;
  authors_some?: InputMaybe<AuthorWhereInput>;
  category?: InputMaybe<CategoryWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<ArticleWhereStageInput>;
  documentInStages_none?: InputMaybe<ArticleWhereStageInput>;
  documentInStages_some?: InputMaybe<ArticleWhereStageInput>;
  featureImage_every?: InputMaybe<AssetWhereInput>;
  featureImage_none?: InputMaybe<AssetWhereInput>;
  featureImage_some?: InputMaybe<AssetWhereInput>;
  featuredArticleSites_every?: InputMaybe<SiteWhereInput>;
  featuredArticleSites_none?: InputMaybe<SiteWhereInput>;
  featuredArticleSites_some?: InputMaybe<SiteWhereInput>;
  hideEmbeddedFormInSection?: InputMaybe<SectionWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  podcast?: InputMaybe<PodcastWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  seo?: InputMaybe<SeoWhereInput>;
  showExcerpt?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  showExcerpt_not?: InputMaybe<Scalars['Boolean']>;
  sites_every?: InputMaybe<SiteWhereInput>;
  sites_none?: InputMaybe<SiteWhereInput>;
  sites_some?: InputMaybe<SiteWhereInput>;
  topic_every?: InputMaybe<TopicWhereInput>;
  topic_none?: InputMaybe<TopicWhereInput>;
  topic_some?: InputMaybe<TopicWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum ArticleOrderByInput {
  ArticleTypeAsc = 'articleType_ASC',
  ArticleTypeDesc = 'articleType_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  ExcerptAsc = 'excerpt_ASC',
  ExcerptDesc = 'excerpt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MediaUrlAsc = 'mediaUrl_ASC',
  MediaUrlDesc = 'mediaUrl_DESC',
  MigratedPostIdAsc = 'migratedPostID_ASC',
  MigratedPostIdDesc = 'migratedPostID_DESC',
  MigratedSiteIdAsc = 'migratedSiteID_ASC',
  MigratedSiteIdDesc = 'migratedSiteID_DESC',
  MigratedUrlAsc = 'migratedURL_ASC',
  MigratedUrlDesc = 'migratedURL_DESC',
  PublishDateAsc = 'publishDate_ASC',
  PublishDateDesc = 'publishDate_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  ShowExcerptAsc = 'showExcerpt_ASC',
  ShowExcerptDesc = 'showExcerpt_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  TimeToReadAsc = 'timeToRead_ASC',
  TimeToReadDesc = 'timeToRead_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** Represents the article type values. */
export enum ArticleType {
  Article = 'Article',
  Infographic = 'Infographic',
  Podcast = 'Podcast',
  Video = 'Video',
}

export type ArticleUpdateInput = {
  additionalContent?: InputMaybe<ArticleAdditionalContentUpdateOneInlineInput>;
  articleType?: InputMaybe<ArticleType>;
  authors?: InputMaybe<AuthorUpdateManyInlineInput>;
  category?: InputMaybe<CategoryUpdateOneInlineInput>;
  clxhi093r20m707t86884a4ek?: InputMaybe<CBannerArticleUpdateManyInlineInput>;
  /** content input for default locale (default) */
  content?: InputMaybe<Scalars['RichTextAST']>;
  /** excerpt input for default locale (default) */
  excerpt?: InputMaybe<Scalars['String']>;
  featureImage?: InputMaybe<AssetUpdateManyInlineInput>;
  featuredArticleSites?: InputMaybe<SiteUpdateManyInlineInput>;
  hideEmbeddedFormInSection?: InputMaybe<SectionUpdateOneInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<ArticleUpdateLocalizationsInput>;
  /** mediaUrl input for default locale (default) */
  mediaUrl?: InputMaybe<Scalars['String']>;
  /** migratedPostID input for default locale (default) */
  migratedPostID?: InputMaybe<Scalars['Int']>;
  /** migratedSiteID input for default locale (default) */
  migratedSiteID?: InputMaybe<Scalars['String']>;
  /** migratedURL input for default locale (default) */
  migratedURL?: InputMaybe<Scalars['String']>;
  podcast?: InputMaybe<PodcastUpdateOneInlineInput>;
  /** publishDate input for default locale (default) */
  publishDate?: InputMaybe<Scalars['Date']>;
  seo?: InputMaybe<SeoUpdateOneInlineInput>;
  showExcerpt?: InputMaybe<Scalars['Boolean']>;
  sites?: InputMaybe<SiteUpdateManyInlineInput>;
  /** slug input for default locale (default) */
  slug?: InputMaybe<Scalars['String']>;
  /** timeToRead input for default locale (default) */
  timeToRead?: InputMaybe<Scalars['Float']>;
  /** title input for default locale (default) */
  title?: InputMaybe<Scalars['String']>;
  topic?: InputMaybe<TopicUpdateManyInlineInput>;
};

export type ArticleUpdateLocalizationDataInput = {
  content?: InputMaybe<Scalars['RichTextAST']>;
  excerpt?: InputMaybe<Scalars['String']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  migratedPostID?: InputMaybe<Scalars['Int']>;
  migratedSiteID?: InputMaybe<Scalars['String']>;
  migratedURL?: InputMaybe<Scalars['String']>;
  publishDate?: InputMaybe<Scalars['Date']>;
  slug?: InputMaybe<Scalars['String']>;
  timeToRead?: InputMaybe<Scalars['Float']>;
  title?: InputMaybe<Scalars['String']>;
};

export type ArticleUpdateLocalizationInput = {
  data: ArticleUpdateLocalizationDataInput;
  locale: Locale;
};

export type ArticleUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<ArticleCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<ArticleUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<ArticleUpsertLocalizationInput>>;
};

export type ArticleUpdateManyInlineInput = {
  /** Connect multiple existing Article documents */
  connect?: InputMaybe<Array<ArticleConnectInput>>;
  /** Create and connect multiple Article documents */
  create?: InputMaybe<Array<ArticleCreateInput>>;
  /** Delete multiple Article documents */
  delete?: InputMaybe<Array<ArticleWhereUniqueInput>>;
  /** Disconnect multiple Article documents */
  disconnect?: InputMaybe<Array<ArticleWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Article documents */
  set?: InputMaybe<Array<ArticleWhereUniqueInput>>;
  /** Update multiple Article documents */
  update?: InputMaybe<Array<ArticleUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Article documents */
  upsert?: InputMaybe<Array<ArticleUpsertWithNestedWhereUniqueInput>>;
};

export type ArticleUpdateManyInput = {
  articleType?: InputMaybe<ArticleType>;
  /** content input for default locale (default) */
  content?: InputMaybe<Scalars['RichTextAST']>;
  /** excerpt input for default locale (default) */
  excerpt?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<ArticleUpdateManyLocalizationsInput>;
  /** mediaUrl input for default locale (default) */
  mediaUrl?: InputMaybe<Scalars['String']>;
  /** migratedPostID input for default locale (default) */
  migratedPostID?: InputMaybe<Scalars['Int']>;
  /** migratedSiteID input for default locale (default) */
  migratedSiteID?: InputMaybe<Scalars['String']>;
  /** migratedURL input for default locale (default) */
  migratedURL?: InputMaybe<Scalars['String']>;
  /** publishDate input for default locale (default) */
  publishDate?: InputMaybe<Scalars['Date']>;
  showExcerpt?: InputMaybe<Scalars['Boolean']>;
  /** timeToRead input for default locale (default) */
  timeToRead?: InputMaybe<Scalars['Float']>;
  /** title input for default locale (default) */
  title?: InputMaybe<Scalars['String']>;
};

export type ArticleUpdateManyLocalizationDataInput = {
  content?: InputMaybe<Scalars['RichTextAST']>;
  excerpt?: InputMaybe<Scalars['String']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  migratedPostID?: InputMaybe<Scalars['Int']>;
  migratedSiteID?: InputMaybe<Scalars['String']>;
  migratedURL?: InputMaybe<Scalars['String']>;
  publishDate?: InputMaybe<Scalars['Date']>;
  timeToRead?: InputMaybe<Scalars['Float']>;
  title?: InputMaybe<Scalars['String']>;
};

export type ArticleUpdateManyLocalizationInput = {
  data: ArticleUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type ArticleUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<ArticleUpdateManyLocalizationInput>>;
};

export type ArticleUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ArticleUpdateManyInput;
  /** Document search */
  where: ArticleWhereInput;
};

export type ArticleUpdateOneInlineInput = {
  /** Connect existing Article document */
  connect?: InputMaybe<ArticleWhereUniqueInput>;
  /** Create and connect one Article document */
  create?: InputMaybe<ArticleCreateInput>;
  /** Delete currently connected Article document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected Article document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single Article document */
  update?: InputMaybe<ArticleUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Article document */
  upsert?: InputMaybe<ArticleUpsertWithNestedWhereUniqueInput>;
};

export type ArticleUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ArticleUpdateInput;
  /** Unique document search */
  where: ArticleWhereUniqueInput;
};

export type ArticleUpsertInput = {
  /** Create document if it didn't exist */
  create: ArticleCreateInput;
  /** Update document if it exists */
  update: ArticleUpdateInput;
};

export type ArticleUpsertLocalizationInput = {
  create: ArticleCreateLocalizationDataInput;
  locale: Locale;
  update: ArticleUpdateLocalizationDataInput;
};

export type ArticleUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ArticleUpsertInput;
  /** Unique document search */
  where: ArticleWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type ArticleWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type ArticleWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ArticleWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ArticleWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ArticleWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  additionalContent?: InputMaybe<ArticleAdditionalContentWhereInput>;
  articleType?: InputMaybe<ArticleType>;
  /** All values that are contained in given list. */
  articleType_in?: InputMaybe<Array<InputMaybe<ArticleType>>>;
  /** Any other value that exists and is not equal to the given value. */
  articleType_not?: InputMaybe<ArticleType>;
  /** All values that are not contained in given list. */
  articleType_not_in?: InputMaybe<Array<InputMaybe<ArticleType>>>;
  authors_every?: InputMaybe<AuthorWhereInput>;
  authors_none?: InputMaybe<AuthorWhereInput>;
  authors_some?: InputMaybe<AuthorWhereInput>;
  category?: InputMaybe<CategoryWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<ArticleWhereStageInput>;
  documentInStages_none?: InputMaybe<ArticleWhereStageInput>;
  documentInStages_some?: InputMaybe<ArticleWhereStageInput>;
  excerpt?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  excerpt_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  excerpt_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  excerpt_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  excerpt_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  excerpt_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  excerpt_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  excerpt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  excerpt_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  excerpt_starts_with?: InputMaybe<Scalars['String']>;
  featureImage_every?: InputMaybe<AssetWhereInput>;
  featureImage_none?: InputMaybe<AssetWhereInput>;
  featureImage_some?: InputMaybe<AssetWhereInput>;
  featuredArticleSites_every?: InputMaybe<SiteWhereInput>;
  featuredArticleSites_none?: InputMaybe<SiteWhereInput>;
  featuredArticleSites_some?: InputMaybe<SiteWhereInput>;
  hideEmbeddedFormInSection?: InputMaybe<SectionWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  mediaUrl_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  mediaUrl_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  mediaUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  mediaUrl_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  mediaUrl_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  mediaUrl_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  mediaUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  mediaUrl_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  mediaUrl_starts_with?: InputMaybe<Scalars['String']>;
  migratedPostID?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  migratedPostID_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  migratedPostID_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  migratedPostID_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** All values less than the given value. */
  migratedPostID_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  migratedPostID_lte?: InputMaybe<Scalars['Int']>;
  /** Any other value that exists and is not equal to the given value. */
  migratedPostID_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  migratedPostID_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  migratedSiteID?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  migratedSiteID_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  migratedSiteID_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  migratedSiteID_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  migratedSiteID_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  migratedSiteID_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  migratedSiteID_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  migratedSiteID_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  migratedSiteID_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  migratedSiteID_starts_with?: InputMaybe<Scalars['String']>;
  migratedURL?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  migratedURL_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  migratedURL_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  migratedURL_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  migratedURL_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  migratedURL_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  migratedURL_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  migratedURL_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  migratedURL_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  migratedURL_starts_with?: InputMaybe<Scalars['String']>;
  podcast?: InputMaybe<PodcastWhereInput>;
  publishDate?: InputMaybe<Scalars['Date']>;
  /** All values greater than the given value. */
  publishDate_gt?: InputMaybe<Scalars['Date']>;
  /** All values greater than or equal the given value. */
  publishDate_gte?: InputMaybe<Scalars['Date']>;
  /** All values that are contained in given list. */
  publishDate_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  /** All values less than the given value. */
  publishDate_lt?: InputMaybe<Scalars['Date']>;
  /** All values less than or equal the given value. */
  publishDate_lte?: InputMaybe<Scalars['Date']>;
  /** Any other value that exists and is not equal to the given value. */
  publishDate_not?: InputMaybe<Scalars['Date']>;
  /** All values that are not contained in given list. */
  publishDate_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  seo?: InputMaybe<SeoWhereInput>;
  showExcerpt?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  showExcerpt_not?: InputMaybe<Scalars['Boolean']>;
  sites_every?: InputMaybe<SiteWhereInput>;
  sites_none?: InputMaybe<SiteWhereInput>;
  sites_some?: InputMaybe<SiteWhereInput>;
  slug?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']>;
  timeToRead?: InputMaybe<Scalars['Float']>;
  /** All values greater than the given value. */
  timeToRead_gt?: InputMaybe<Scalars['Float']>;
  /** All values greater than or equal the given value. */
  timeToRead_gte?: InputMaybe<Scalars['Float']>;
  /** All values that are contained in given list. */
  timeToRead_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  /** All values less than the given value. */
  timeToRead_lt?: InputMaybe<Scalars['Float']>;
  /** All values less than or equal the given value. */
  timeToRead_lte?: InputMaybe<Scalars['Float']>;
  /** Any other value that exists and is not equal to the given value. */
  timeToRead_not?: InputMaybe<Scalars['Float']>;
  /** All values that are not contained in given list. */
  timeToRead_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  title?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']>;
  topic_every?: InputMaybe<TopicWhereInput>;
  topic_none?: InputMaybe<TopicWhereInput>;
  topic_some?: InputMaybe<TopicWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type ArticleWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ArticleWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ArticleWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ArticleWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<ArticleWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Article record uniquely */
export type ArticleWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Asset system model */
export type Asset = Entity &
  Node & {
    __typename?: 'Asset';
    /** Alternate text for the asset. */
    alt?: Maybe<Scalars['String']>;
    /** The time the document was created */
    createdAt: Scalars['DateTime'];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** It describes the asset. */
    description?: Maybe<Scalars['String']>;
    /** Get the document in other stages */
    documentInStages: Array<Asset>;
    /** The file name */
    fileName: Scalars['String'];
    /** The file handle */
    handle: Scalars['String'];
    /** The height of the file */
    height?: Maybe<Scalars['Float']>;
    /** List of Asset versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars['ID'];
    imageAuthor: Array<Author>;
    imageCImageLink: Array<CImageLink>;
    /** System Locale field */
    locale: Locale;
    /** Get the other localizations for this document */
    localizations: Array<Asset>;
    migratedURL?: Maybe<Scalars['String']>;
    /** The mime type of the file */
    mimeType?: Maybe<Scalars['String']>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    relatedImage?: Maybe<Article>;
    scheduledIn: Array<ScheduledOperation>;
    sites?: Maybe<Site>;
    /** The file size */
    size?: Maybe<Scalars['Float']>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars['DateTime'];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
    /** Returns information you need to upload the asset. The type of upload is dependant on what you pass into asset creations as upload type. */
    upload?: Maybe<AssetUpload>;
    /** Get the url for the asset with provided transformations applied. */
    url: Scalars['String'];
    /** The file width */
    width?: Maybe<Scalars['Float']>;
  };

/** Asset system model */
export type AssetCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

/** Asset system model */
export type AssetCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Asset system model */
export type AssetDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

/** Asset system model */
export type AssetHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};

/** Asset system model */
export type AssetImageAuthorArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AuthorOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AuthorWhereInput>;
};

/** Asset system model */
export type AssetImageCImageLinkArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<CImageLinkOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CImageLinkWhereInput>;
};

/** Asset system model */
export type AssetLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

/** Asset system model */
export type AssetPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

/** Asset system model */
export type AssetPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Asset system model */
export type AssetRelatedImageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Asset system model */
export type AssetScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

/** Asset system model */
export type AssetSitesArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Asset system model */
export type AssetUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

/** Asset system model */
export type AssetUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Asset system model */
export type AssetUrlArgs = {
  transformation?: InputMaybe<AssetTransformationInput>;
};

export type AssetConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: AssetWhereUniqueInput;
};

/** A connection to a list of items. */
export type AssetConnection = {
  __typename?: 'AssetConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<AssetEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type AssetCreateInput = {
  /** alt input for default locale (default) */
  alt?: InputMaybe<Scalars['String']>;
  assetTestimonialImage?: InputMaybe<TestimonialCreateOneInlineInput>;
  assetsGallery?: InputMaybe<CGalleryCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  documentWebForm?: InputMaybe<WebFormCreateManyInlineInput>;
  fileName?: InputMaybe<Scalars['String']>;
  imageAuthor?: InputMaybe<AuthorCreateManyInlineInput>;
  imageCBannerAward?: InputMaybe<CBannerAwardCreateManyInlineInput>;
  imageCBannerInline?: InputMaybe<CBannerInlineCreateManyInlineInput>;
  imageCBannerPrimary?: InputMaybe<CBannerPrimaryCreateManyInlineInput>;
  imageCBannerShopfront?: InputMaybe<CBannerShopfrontCreateManyInlineInput>;
  imageCCardPrimary?: InputMaybe<CCardPrimaryCreateManyInlineInput>;
  imageCImageBlock?: InputMaybe<CImageBlockCreateManyInlineInput>;
  imageCImageLink?: InputMaybe<CImageLinkCreateManyInlineInput>;
  imageDivider?: InputMaybe<DividerCreateManyInlineInput>;
  imageInlineDivider?: InputMaybe<InlineDividerCreateManyInlineInput>;
  imageKeyDatesBlock?: InputMaybe<KeyStepsBlockCreateManyInlineInput>;
  imageProfileBio?: InputMaybe<ProfileBioCreateManyInlineInput>;
  imageRelatedImage?: InputMaybe<ImageCreateOneInlineInput>;
  jobAdRelatedImage?: InputMaybe<CCardJobAdCreateOneInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<AssetCreateLocalizationsInput>;
  logoCustomFooter?: InputMaybe<CustomFooterCreateManyInlineInput>;
  migratedURL?: InputMaybe<Scalars['String']>;
  relatedImage?: InputMaybe<ArticleCreateOneInlineInput>;
  relatedSeo?: InputMaybe<SeoCreateOneInlineInput>;
  sites?: InputMaybe<SiteCreateOneInlineInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** Optionally the system can upload a file for you, for that you need to provide a publicly accessible url */
  uploadUrl?: InputMaybe<Scalars['String']>;
};

export type AssetCreateLocalizationDataInput = {
  alt?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  fileName?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** Optionally the system can upload a file for you, for that you need to provide a publicly accessible url */
  uploadUrl?: InputMaybe<Scalars['String']>;
};

export type AssetCreateLocalizationInput = {
  /** Localization input */
  data: AssetCreateLocalizationDataInput;
  locale: Locale;
};

export type AssetCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<AssetCreateLocalizationInput>>;
};

export type AssetCreateManyInlineInput = {
  /** Connect multiple existing Asset documents */
  connect?: InputMaybe<Array<AssetWhereUniqueInput>>;
  /** Create and connect multiple existing Asset documents */
  create?: InputMaybe<Array<AssetCreateInput>>;
};

export type AssetCreateOneInlineInput = {
  /** Connect one existing Asset document */
  connect?: InputMaybe<AssetWhereUniqueInput>;
  /** Create and connect one Asset document */
  create?: InputMaybe<AssetCreateInput>;
};

/** An edge in a connection. */
export type AssetEdge = {
  __typename?: 'AssetEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Asset;
};

/** Identifies documents */
export type AssetManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AssetWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AssetWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AssetWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  description?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']>;
  documentInStages_every?: InputMaybe<AssetWhereStageInput>;
  documentInStages_none?: InputMaybe<AssetWhereStageInput>;
  documentInStages_some?: InputMaybe<AssetWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  imageAuthor_every?: InputMaybe<AuthorWhereInput>;
  imageAuthor_none?: InputMaybe<AuthorWhereInput>;
  imageAuthor_some?: InputMaybe<AuthorWhereInput>;
  imageCImageLink_every?: InputMaybe<CImageLinkWhereInput>;
  imageCImageLink_none?: InputMaybe<CImageLinkWhereInput>;
  imageCImageLink_some?: InputMaybe<CImageLinkWhereInput>;
  migratedURL?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  migratedURL_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  migratedURL_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  migratedURL_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  migratedURL_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  migratedURL_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  migratedURL_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  migratedURL_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  migratedURL_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  migratedURL_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  relatedImage?: InputMaybe<ArticleWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  sites?: InputMaybe<SiteWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  upload?: InputMaybe<AssetUploadWhereInput>;
};

export enum AssetOrderByInput {
  AltAsc = 'alt_ASC',
  AltDesc = 'alt_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  FileNameAsc = 'fileName_ASC',
  FileNameDesc = 'fileName_DESC',
  HandleAsc = 'handle_ASC',
  HandleDesc = 'handle_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MigratedUrlAsc = 'migratedURL_ASC',
  MigratedUrlDesc = 'migratedURL_DESC',
  MimeTypeAsc = 'mimeType_ASC',
  MimeTypeDesc = 'mimeType_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WidthAsc = 'width_ASC',
  WidthDesc = 'width_DESC',
}

/** Identifies documents */
export type AssetSingleRelationWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AssetSingleRelationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AssetSingleRelationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AssetSingleRelationWhereInput>>;
  upload?: InputMaybe<AssetUploadWhereInput>;
};

/** Transformations for Assets */
export type AssetTransformationInput = {
  document?: InputMaybe<DocumentTransformationInput>;
  image?: InputMaybe<ImageTransformationInput>;
  /** Pass true if you want to validate the passed transformation parameters */
  validateOptions?: InputMaybe<Scalars['Boolean']>;
};

export type AssetUpdateInput = {
  /** alt input for default locale (default) */
  alt?: InputMaybe<Scalars['String']>;
  assetTestimonialImage?: InputMaybe<TestimonialUpdateOneInlineInput>;
  assetsGallery?: InputMaybe<CGalleryUpdateManyInlineInput>;
  description?: InputMaybe<Scalars['String']>;
  documentWebForm?: InputMaybe<WebFormUpdateManyInlineInput>;
  fileName?: InputMaybe<Scalars['String']>;
  imageAuthor?: InputMaybe<AuthorUpdateManyInlineInput>;
  imageCBannerAward?: InputMaybe<CBannerAwardUpdateManyInlineInput>;
  imageCBannerInline?: InputMaybe<CBannerInlineUpdateManyInlineInput>;
  imageCBannerPrimary?: InputMaybe<CBannerPrimaryUpdateManyInlineInput>;
  imageCBannerShopfront?: InputMaybe<CBannerShopfrontUpdateManyInlineInput>;
  imageCCardPrimary?: InputMaybe<CCardPrimaryUpdateManyInlineInput>;
  imageCImageBlock?: InputMaybe<CImageBlockUpdateManyInlineInput>;
  imageCImageLink?: InputMaybe<CImageLinkUpdateManyInlineInput>;
  imageDivider?: InputMaybe<DividerUpdateManyInlineInput>;
  imageInlineDivider?: InputMaybe<InlineDividerUpdateManyInlineInput>;
  imageKeyDatesBlock?: InputMaybe<KeyStepsBlockUpdateManyInlineInput>;
  imageProfileBio?: InputMaybe<ProfileBioUpdateManyInlineInput>;
  imageRelatedImage?: InputMaybe<ImageUpdateOneInlineInput>;
  jobAdRelatedImage?: InputMaybe<CCardJobAdUpdateOneInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<AssetUpdateLocalizationsInput>;
  logoCustomFooter?: InputMaybe<CustomFooterUpdateManyInlineInput>;
  migratedURL?: InputMaybe<Scalars['String']>;
  /** Use this to define if its a reupload for the asset */
  reUpload?: InputMaybe<Scalars['Boolean']>;
  relatedImage?: InputMaybe<ArticleUpdateOneInlineInput>;
  relatedSeo?: InputMaybe<SeoUpdateOneInlineInput>;
  sites?: InputMaybe<SiteUpdateOneInlineInput>;
  /** Optionally the system can upload a file for you, for that you need to provide a publicly accessible url */
  uploadUrl?: InputMaybe<Scalars['String']>;
};

export type AssetUpdateLocalizationDataInput = {
  alt?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
  /** Use this to define if its a reupload for the asset */
  reUpload?: InputMaybe<Scalars['Boolean']>;
  /** Optionally the system can upload a file for you, for that you need to provide a publicly accessible url */
  uploadUrl?: InputMaybe<Scalars['String']>;
};

export type AssetUpdateLocalizationInput = {
  data: AssetUpdateLocalizationDataInput;
  locale: Locale;
};

export type AssetUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<AssetCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<AssetUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<AssetUpsertLocalizationInput>>;
};

export type AssetUpdateManyInlineInput = {
  /** Connect multiple existing Asset documents */
  connect?: InputMaybe<Array<AssetConnectInput>>;
  /** Create and connect multiple Asset documents */
  create?: InputMaybe<Array<AssetCreateInput>>;
  /** Delete multiple Asset documents */
  delete?: InputMaybe<Array<AssetWhereUniqueInput>>;
  /** Disconnect multiple Asset documents */
  disconnect?: InputMaybe<Array<AssetWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Asset documents */
  set?: InputMaybe<Array<AssetWhereUniqueInput>>;
  /** Update multiple Asset documents */
  update?: InputMaybe<Array<AssetUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Asset documents */
  upsert?: InputMaybe<Array<AssetUpsertWithNestedWhereUniqueInput>>;
};

export type AssetUpdateManyInput = {
  /** alt input for default locale (default) */
  alt?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<AssetUpdateManyLocalizationsInput>;
  migratedURL?: InputMaybe<Scalars['String']>;
};

export type AssetUpdateManyLocalizationDataInput = {
  alt?: InputMaybe<Scalars['String']>;
};

export type AssetUpdateManyLocalizationInput = {
  data: AssetUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type AssetUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<AssetUpdateManyLocalizationInput>>;
};

export type AssetUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: AssetUpdateManyInput;
  /** Document search */
  where: AssetWhereInput;
};

export type AssetUpdateOneInlineInput = {
  /** Connect existing Asset document */
  connect?: InputMaybe<AssetWhereUniqueInput>;
  /** Create and connect one Asset document */
  create?: InputMaybe<AssetCreateInput>;
  /** Delete currently connected Asset document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected Asset document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single Asset document */
  update?: InputMaybe<AssetUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Asset document */
  upsert?: InputMaybe<AssetUpsertWithNestedWhereUniqueInput>;
};

export type AssetUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: AssetUpdateInput;
  /** Unique document search */
  where: AssetWhereUniqueInput;
};

/** Asset Upload */
export type AssetUpload = {
  __typename?: 'AssetUpload';
  /** Asset Upload Error */
  error?: Maybe<AssetUploadError>;
  /** Expiry Timestamp */
  expiresAt?: Maybe<Scalars['DateTime']>;
  /** Asset Request Data for upload */
  requestPostData?: Maybe<AssetUploadRequestPostData>;
  /** Asset Request Data for upload */
  status?: Maybe<AssetUploadStatus>;
};

/** Represents asset upload error */
export type AssetUploadError = {
  __typename?: 'AssetUploadError';
  code: Scalars['String'];
  message: Scalars['String'];
};

/** Asset Upload Request Post Data */
export type AssetUploadRequestPostData = {
  __typename?: 'AssetUploadRequestPostData';
  /** The algorithm to use in the form field. This value should be passed in the `X-Amz-Algorithm` form field. */
  algorithm: Scalars['String'];
  /** The credential to use in the form field. This value should be passed in the `X-Amz-Credential` form field. */
  credential: Scalars['String'];
  /** The date the request was signed, formatted as YYYYMMDDTHHMMSSZ. This value should be passed in the `X-Amz-Date` header. */
  date: Scalars['String'];
  /** The key to use in the form field. This value should be passed in the `Key` form field. */
  key: Scalars['String'];
  /** The policy to use in the form field. This value should be passed in the `Policy` form field. */
  policy: Scalars['String'];
  /** The security token to use in the form field. This field is optional only pass it if its not null. This value should be passed in the `X-Amz-Security-Token` form field if not null. */
  securityToken?: Maybe<Scalars['String']>;
  /** The signature to use in the form field. This value should be passed in the `X-Amz-Signature` form field. */
  signature: Scalars['String'];
  /** The URL to which the file should be uploaded with a POST request. */
  url: Scalars['String'];
};

/** System Asset Upload Status */
export enum AssetUploadStatus {
  AssetCreatePending = 'ASSET_CREATE_PENDING',
  AssetErrorUpload = 'ASSET_ERROR_UPLOAD',
  AssetUpdatePending = 'ASSET_UPDATE_PENDING',
  AssetUploadComplete = 'ASSET_UPLOAD_COMPLETE',
}

/** Identifies documents */
export type AssetUploadWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AssetUploadWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AssetUploadWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AssetUploadWhereInput>>;
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  expiresAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  expiresAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  expiresAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  expiresAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  expiresAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  expiresAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  expiresAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  status?: InputMaybe<AssetUploadStatus>;
  /** All values that are contained in given list. */
  status_in?: InputMaybe<Array<InputMaybe<AssetUploadStatus>>>;
  /** Any other value that exists and is not equal to the given value. */
  status_not?: InputMaybe<AssetUploadStatus>;
  /** All values that are not contained in given list. */
  status_not_in?: InputMaybe<Array<InputMaybe<AssetUploadStatus>>>;
};

/** Identifies documents */
export type AssetUploadWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AssetUploadWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AssetUploadWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AssetUploadWhereInput>>;
  expiresAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  expiresAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  expiresAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  expiresAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  expiresAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  expiresAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  expiresAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  expiresAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  status?: InputMaybe<AssetUploadStatus>;
  /** All values that are contained in given list. */
  status_in?: InputMaybe<Array<InputMaybe<AssetUploadStatus>>>;
  /** Any other value that exists and is not equal to the given value. */
  status_not?: InputMaybe<AssetUploadStatus>;
  /** All values that are not contained in given list. */
  status_not_in?: InputMaybe<Array<InputMaybe<AssetUploadStatus>>>;
};

export type AssetUpsertInput = {
  /** Create document if it didn't exist */
  create: AssetCreateInput;
  /** Update document if it exists */
  update: AssetUpdateInput;
};

export type AssetUpsertLocalizationInput = {
  create: AssetCreateLocalizationDataInput;
  locale: Locale;
  update: AssetUpdateLocalizationDataInput;
};

export type AssetUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: AssetUpsertInput;
  /** Unique document search */
  where: AssetWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type AssetWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type AssetWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AssetWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AssetWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AssetWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  alt?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  alt_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  alt_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  alt_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  alt_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  alt_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  alt_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  alt_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  alt_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  alt_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  description?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']>;
  documentInStages_every?: InputMaybe<AssetWhereStageInput>;
  documentInStages_none?: InputMaybe<AssetWhereStageInput>;
  documentInStages_some?: InputMaybe<AssetWhereStageInput>;
  fileName?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  fileName_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  fileName_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  fileName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  fileName_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  fileName_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  fileName_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  fileName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  fileName_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  fileName_starts_with?: InputMaybe<Scalars['String']>;
  handle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  handle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  handle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  handle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  handle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  handle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  handle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  handle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  handle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  handle_starts_with?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Float']>;
  /** All values greater than the given value. */
  height_gt?: InputMaybe<Scalars['Float']>;
  /** All values greater than or equal the given value. */
  height_gte?: InputMaybe<Scalars['Float']>;
  /** All values that are contained in given list. */
  height_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  /** All values less than the given value. */
  height_lt?: InputMaybe<Scalars['Float']>;
  /** All values less than or equal the given value. */
  height_lte?: InputMaybe<Scalars['Float']>;
  /** Any other value that exists and is not equal to the given value. */
  height_not?: InputMaybe<Scalars['Float']>;
  /** All values that are not contained in given list. */
  height_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  imageAuthor_every?: InputMaybe<AuthorWhereInput>;
  imageAuthor_none?: InputMaybe<AuthorWhereInput>;
  imageAuthor_some?: InputMaybe<AuthorWhereInput>;
  imageCImageLink_every?: InputMaybe<CImageLinkWhereInput>;
  imageCImageLink_none?: InputMaybe<CImageLinkWhereInput>;
  imageCImageLink_some?: InputMaybe<CImageLinkWhereInput>;
  migratedURL?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  migratedURL_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  migratedURL_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  migratedURL_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  migratedURL_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  migratedURL_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  migratedURL_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  migratedURL_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  migratedURL_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  migratedURL_starts_with?: InputMaybe<Scalars['String']>;
  mimeType?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  mimeType_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  mimeType_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  mimeType_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  mimeType_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  mimeType_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  mimeType_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  mimeType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  mimeType_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  mimeType_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  relatedImage?: InputMaybe<ArticleWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  sites?: InputMaybe<SiteWhereInput>;
  size?: InputMaybe<Scalars['Float']>;
  /** All values greater than the given value. */
  size_gt?: InputMaybe<Scalars['Float']>;
  /** All values greater than or equal the given value. */
  size_gte?: InputMaybe<Scalars['Float']>;
  /** All values that are contained in given list. */
  size_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  /** All values less than the given value. */
  size_lt?: InputMaybe<Scalars['Float']>;
  /** All values less than or equal the given value. */
  size_lte?: InputMaybe<Scalars['Float']>;
  /** Any other value that exists and is not equal to the given value. */
  size_not?: InputMaybe<Scalars['Float']>;
  /** All values that are not contained in given list. */
  size_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  upload?: InputMaybe<AssetUploadWhereInput>;
  width?: InputMaybe<Scalars['Float']>;
  /** All values greater than the given value. */
  width_gt?: InputMaybe<Scalars['Float']>;
  /** All values greater than or equal the given value. */
  width_gte?: InputMaybe<Scalars['Float']>;
  /** All values that are contained in given list. */
  width_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  /** All values less than the given value. */
  width_lt?: InputMaybe<Scalars['Float']>;
  /** All values less than or equal the given value. */
  width_lte?: InputMaybe<Scalars['Float']>;
  /** Any other value that exists and is not equal to the given value. */
  width_not?: InputMaybe<Scalars['Float']>;
  /** All values that are not contained in given list. */
  width_not_in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type AssetWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AssetWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AssetWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AssetWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<AssetWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Asset record uniquely */
export type AssetWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Author = Entity &
  Node & {
    __typename?: 'Author';
    articles: Array<Article>;
    /** The time the document was created */
    createdAt: Scalars['DateTime'];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Description for Author */
    description?: Maybe<RichText>;
    /** Get the document in other stages */
    documentInStages: Array<Author>;
    /** List of Author versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars['ID'];
    /** Image for the Author */
    image?: Maybe<Asset>;
    /** System Locale field */
    locale: Locale;
    /** Get the other localizations for this document */
    localizations: Array<Author>;
    /** author name */
    name: Scalars['String'];
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    sites: Array<Site>;
    /** Slug for Author */
    slug: Scalars['String'];
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars['DateTime'];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type AuthorArticlesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ArticleOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ArticleWhereInput>;
};

export type AuthorCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type AuthorCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type AuthorDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type AuthorHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};

export type AuthorImageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
  where?: InputMaybe<AssetSingleRelationWhereInput>;
};

export type AuthorLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type AuthorPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type AuthorPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type AuthorScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type AuthorSitesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<SiteOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SiteWhereInput>;
};

export type AuthorUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type AuthorUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type AuthorConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: AuthorWhereUniqueInput;
};

/** A connection to a list of items. */
export type AuthorConnection = {
  __typename?: 'AuthorConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<AuthorEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type AuthorCreateInput = {
  articles?: InputMaybe<ArticleCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** description input for default locale (default) */
  description?: InputMaybe<Scalars['RichTextAST']>;
  image?: InputMaybe<AssetCreateOneInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<AuthorCreateLocalizationsInput>;
  /** name input for default locale (default) */
  name: Scalars['String'];
  sites?: InputMaybe<SiteCreateManyInlineInput>;
  /** slug input for default locale (default) */
  slug: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AuthorCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['RichTextAST']>;
  name: Scalars['String'];
  slug: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AuthorCreateLocalizationInput = {
  /** Localization input */
  data: AuthorCreateLocalizationDataInput;
  locale: Locale;
};

export type AuthorCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<AuthorCreateLocalizationInput>>;
};

export type AuthorCreateManyInlineInput = {
  /** Connect multiple existing Author documents */
  connect?: InputMaybe<Array<AuthorWhereUniqueInput>>;
  /** Create and connect multiple existing Author documents */
  create?: InputMaybe<Array<AuthorCreateInput>>;
};

export type AuthorCreateOneInlineInput = {
  /** Connect one existing Author document */
  connect?: InputMaybe<AuthorWhereUniqueInput>;
  /** Create and connect one Author document */
  create?: InputMaybe<AuthorCreateInput>;
};

/** An edge in a connection. */
export type AuthorEdge = {
  __typename?: 'AuthorEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Author;
};

/** Identifies documents */
export type AuthorManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AuthorWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AuthorWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AuthorWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  articles_every?: InputMaybe<ArticleWhereInput>;
  articles_none?: InputMaybe<ArticleWhereInput>;
  articles_some?: InputMaybe<ArticleWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<AuthorWhereStageInput>;
  documentInStages_none?: InputMaybe<AuthorWhereStageInput>;
  documentInStages_some?: InputMaybe<AuthorWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AssetWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  sites_every?: InputMaybe<SiteWhereInput>;
  sites_none?: InputMaybe<SiteWhereInput>;
  sites_some?: InputMaybe<SiteWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum AuthorOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type AuthorUpdateInput = {
  articles?: InputMaybe<ArticleUpdateManyInlineInput>;
  /** description input for default locale (default) */
  description?: InputMaybe<Scalars['RichTextAST']>;
  image?: InputMaybe<AssetUpdateOneInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<AuthorUpdateLocalizationsInput>;
  /** name input for default locale (default) */
  name?: InputMaybe<Scalars['String']>;
  sites?: InputMaybe<SiteUpdateManyInlineInput>;
  /** slug input for default locale (default) */
  slug?: InputMaybe<Scalars['String']>;
};

export type AuthorUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['RichTextAST']>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type AuthorUpdateLocalizationInput = {
  data: AuthorUpdateLocalizationDataInput;
  locale: Locale;
};

export type AuthorUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<AuthorCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<AuthorUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<AuthorUpsertLocalizationInput>>;
};

export type AuthorUpdateManyInlineInput = {
  /** Connect multiple existing Author documents */
  connect?: InputMaybe<Array<AuthorConnectInput>>;
  /** Create and connect multiple Author documents */
  create?: InputMaybe<Array<AuthorCreateInput>>;
  /** Delete multiple Author documents */
  delete?: InputMaybe<Array<AuthorWhereUniqueInput>>;
  /** Disconnect multiple Author documents */
  disconnect?: InputMaybe<Array<AuthorWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Author documents */
  set?: InputMaybe<Array<AuthorWhereUniqueInput>>;
  /** Update multiple Author documents */
  update?: InputMaybe<Array<AuthorUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Author documents */
  upsert?: InputMaybe<Array<AuthorUpsertWithNestedWhereUniqueInput>>;
};

export type AuthorUpdateManyInput = {
  /** description input for default locale (default) */
  description?: InputMaybe<Scalars['RichTextAST']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<AuthorUpdateManyLocalizationsInput>;
};

export type AuthorUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['RichTextAST']>;
};

export type AuthorUpdateManyLocalizationInput = {
  data: AuthorUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type AuthorUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<AuthorUpdateManyLocalizationInput>>;
};

export type AuthorUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: AuthorUpdateManyInput;
  /** Document search */
  where: AuthorWhereInput;
};

export type AuthorUpdateOneInlineInput = {
  /** Connect existing Author document */
  connect?: InputMaybe<AuthorWhereUniqueInput>;
  /** Create and connect one Author document */
  create?: InputMaybe<AuthorCreateInput>;
  /** Delete currently connected Author document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected Author document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single Author document */
  update?: InputMaybe<AuthorUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Author document */
  upsert?: InputMaybe<AuthorUpsertWithNestedWhereUniqueInput>;
};

export type AuthorUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: AuthorUpdateInput;
  /** Unique document search */
  where: AuthorWhereUniqueInput;
};

export type AuthorUpsertInput = {
  /** Create document if it didn't exist */
  create: AuthorCreateInput;
  /** Update document if it exists */
  update: AuthorUpdateInput;
};

export type AuthorUpsertLocalizationInput = {
  create: AuthorCreateLocalizationDataInput;
  locale: Locale;
  update: AuthorUpdateLocalizationDataInput;
};

export type AuthorUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: AuthorUpsertInput;
  /** Unique document search */
  where: AuthorWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type AuthorWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type AuthorWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AuthorWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AuthorWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AuthorWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  articles_every?: InputMaybe<ArticleWhereInput>;
  articles_none?: InputMaybe<ArticleWhereInput>;
  articles_some?: InputMaybe<ArticleWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<AuthorWhereStageInput>;
  documentInStages_none?: InputMaybe<AuthorWhereStageInput>;
  documentInStages_some?: InputMaybe<AuthorWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AssetWhereInput>;
  name?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  sites_every?: InputMaybe<SiteWhereInput>;
  sites_none?: InputMaybe<SiteWhereInput>;
  sites_some?: InputMaybe<SiteWhereInput>;
  slug?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type AuthorWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AuthorWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AuthorWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AuthorWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<AuthorWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Author record uniquely */
export type AuthorWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Heading background color */
export enum BackgroundColor {
  Brand = 'brand',
  BrandAccent = 'brandAccent',
  BrandAccentSoft = 'brandAccentSoft',
  CautionLight = 'cautionLight',
  CriticalLight = 'criticalLight',
  FormAccentSoft = 'formAccentSoft',
  InfoLight = 'infoLight',
  Neutral = 'neutral',
  NeutralSoft = 'neutralSoft',
  PositiveLight = 'positiveLight',
  PromoteLight = 'promoteLight',
}

/** This component is used for badge */
export type Badge = Entity & {
  __typename?: 'Badge';
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Badge>;
  /** System stage field */
  stage: Stage;
  /** Represents text for the badge. */
  text: Scalars['String'];
  /** Represents how badge tone should be aligned */
  tone: Tone;
};

/** This component is used for badge */
export type BadgeLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type BadgeConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: BadgeWhereUniqueInput;
};

/** A connection to a list of items. */
export type BadgeConnection = {
  __typename?: 'BadgeConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<BadgeEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type BadgeCreateInput = {
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<BadgeCreateLocalizationsInput>;
  /** text input for default locale (default) */
  text: Scalars['String'];
  tone: Tone;
};

export type BadgeCreateLocalizationDataInput = {
  text: Scalars['String'];
};

export type BadgeCreateLocalizationInput = {
  /** Localization input */
  data: BadgeCreateLocalizationDataInput;
  locale: Locale;
};

export type BadgeCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<BadgeCreateLocalizationInput>>;
};

export type BadgeCreateManyInlineInput = {
  /** Create and connect multiple existing Badge documents */
  create?: InputMaybe<Array<BadgeCreateInput>>;
};

export type BadgeCreateOneInlineInput = {
  /** Create and connect one Badge document */
  create?: InputMaybe<BadgeCreateInput>;
};

export type BadgeCreateWithPositionInput = {
  /** Document to create */
  data: BadgeCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type BadgeEdge = {
  __typename?: 'BadgeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Badge;
};

/** Identifies documents */
export type BadgeManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BadgeWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BadgeWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BadgeWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  tone?: InputMaybe<Tone>;
  /** All values that are contained in given list. */
  tone_in?: InputMaybe<Array<InputMaybe<Tone>>>;
  /** Any other value that exists and is not equal to the given value. */
  tone_not?: InputMaybe<Tone>;
  /** All values that are not contained in given list. */
  tone_not_in?: InputMaybe<Array<InputMaybe<Tone>>>;
};

export enum BadgeOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  ToneAsc = 'tone_ASC',
  ToneDesc = 'tone_DESC',
}

export type BadgeParent = CBannerInline | CCardJobAd | CCardPrimary | TipsCard;

export type BadgeParentConnectInput = {
  CBannerInline?: InputMaybe<CBannerInlineConnectInput>;
  CCardJobAd?: InputMaybe<CCardJobAdConnectInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryConnectInput>;
  TipsCard?: InputMaybe<TipsCardConnectInput>;
};

export type BadgeParentCreateInput = {
  CBannerInline?: InputMaybe<CBannerInlineCreateInput>;
  CCardJobAd?: InputMaybe<CCardJobAdCreateInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryCreateInput>;
  TipsCard?: InputMaybe<TipsCardCreateInput>;
};

export type BadgeParentCreateManyInlineInput = {
  /** Create and connect multiple existing BadgeParent documents */
  create?: InputMaybe<Array<BadgeParentCreateInput>>;
};

export type BadgeParentCreateOneInlineInput = {
  /** Create and connect one BadgeParent document */
  create?: InputMaybe<BadgeParentCreateInput>;
};

export type BadgeParentCreateWithPositionInput = {
  CBannerInline?: InputMaybe<CBannerInlineCreateWithPositionInput>;
  CCardJobAd?: InputMaybe<CCardJobAdCreateWithPositionInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryCreateWithPositionInput>;
  TipsCard?: InputMaybe<TipsCardCreateWithPositionInput>;
};

export type BadgeParentUpdateInput = {
  CBannerInline?: InputMaybe<CBannerInlineUpdateInput>;
  CCardJobAd?: InputMaybe<CCardJobAdUpdateInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryUpdateInput>;
  TipsCard?: InputMaybe<TipsCardUpdateInput>;
};

export type BadgeParentUpdateManyInlineInput = {
  /** Create and connect multiple BadgeParent component instances */
  create?: InputMaybe<Array<BadgeParentCreateWithPositionInput>>;
  /** Delete multiple BadgeParent documents */
  delete?: InputMaybe<Array<BadgeParentWhereUniqueInput>>;
  /** Update multiple BadgeParent component instances */
  update?: InputMaybe<
    Array<BadgeParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple BadgeParent component instances */
  upsert?: InputMaybe<
    Array<BadgeParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type BadgeParentUpdateManyWithNestedWhereInput = {
  CBannerInline?: InputMaybe<CBannerInlineUpdateManyWithNestedWhereInput>;
  CCardJobAd?: InputMaybe<CCardJobAdUpdateManyWithNestedWhereInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryUpdateManyWithNestedWhereInput>;
  TipsCard?: InputMaybe<TipsCardUpdateManyWithNestedWhereInput>;
};

export type BadgeParentUpdateOneInlineInput = {
  /** Create and connect one BadgeParent document */
  create?: InputMaybe<BadgeParentCreateInput>;
  /** Delete currently connected BadgeParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single BadgeParent document */
  update?: InputMaybe<BadgeParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single BadgeParent document */
  upsert?: InputMaybe<BadgeParentUpsertWithNestedWhereUniqueInput>;
};

export type BadgeParentUpdateWithNestedWhereUniqueAndPositionInput = {
  CBannerInline?: InputMaybe<CBannerInlineUpdateWithNestedWhereUniqueAndPositionInput>;
  CCardJobAd?: InputMaybe<CCardJobAdUpdateWithNestedWhereUniqueAndPositionInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryUpdateWithNestedWhereUniqueAndPositionInput>;
  TipsCard?: InputMaybe<TipsCardUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type BadgeParentUpdateWithNestedWhereUniqueInput = {
  CBannerInline?: InputMaybe<CBannerInlineUpdateWithNestedWhereUniqueInput>;
  CCardJobAd?: InputMaybe<CCardJobAdUpdateWithNestedWhereUniqueInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryUpdateWithNestedWhereUniqueInput>;
  TipsCard?: InputMaybe<TipsCardUpdateWithNestedWhereUniqueInput>;
};

export type BadgeParentUpsertWithNestedWhereUniqueAndPositionInput = {
  CBannerInline?: InputMaybe<CBannerInlineUpsertWithNestedWhereUniqueAndPositionInput>;
  CCardJobAd?: InputMaybe<CCardJobAdUpsertWithNestedWhereUniqueAndPositionInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryUpsertWithNestedWhereUniqueAndPositionInput>;
  TipsCard?: InputMaybe<TipsCardUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type BadgeParentUpsertWithNestedWhereUniqueInput = {
  CBannerInline?: InputMaybe<CBannerInlineUpsertWithNestedWhereUniqueInput>;
  CCardJobAd?: InputMaybe<CCardJobAdUpsertWithNestedWhereUniqueInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryUpsertWithNestedWhereUniqueInput>;
  TipsCard?: InputMaybe<TipsCardUpsertWithNestedWhereUniqueInput>;
};

export type BadgeParentWhereInput = {
  CBannerInline?: InputMaybe<CBannerInlineWhereInput>;
  CCardJobAd?: InputMaybe<CCardJobAdWhereInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryWhereInput>;
  TipsCard?: InputMaybe<TipsCardWhereInput>;
};

export type BadgeParentWhereUniqueInput = {
  CBannerInline?: InputMaybe<CBannerInlineWhereUniqueInput>;
  CCardJobAd?: InputMaybe<CCardJobAdWhereUniqueInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryWhereUniqueInput>;
  TipsCard?: InputMaybe<TipsCardWhereUniqueInput>;
};

export type BadgeUpdateInput = {
  /** Manage document localizations */
  localizations?: InputMaybe<BadgeUpdateLocalizationsInput>;
  /** text input for default locale (default) */
  text?: InputMaybe<Scalars['String']>;
  tone?: InputMaybe<Tone>;
};

export type BadgeUpdateLocalizationDataInput = {
  text?: InputMaybe<Scalars['String']>;
};

export type BadgeUpdateLocalizationInput = {
  data: BadgeUpdateLocalizationDataInput;
  locale: Locale;
};

export type BadgeUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<BadgeCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<BadgeUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<BadgeUpsertLocalizationInput>>;
};

export type BadgeUpdateManyInlineInput = {
  /** Create and connect multiple Badge component instances */
  create?: InputMaybe<Array<BadgeCreateWithPositionInput>>;
  /** Delete multiple Badge documents */
  delete?: InputMaybe<Array<BadgeWhereUniqueInput>>;
  /** Update multiple Badge component instances */
  update?: InputMaybe<Array<BadgeUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple Badge component instances */
  upsert?: InputMaybe<Array<BadgeUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type BadgeUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<BadgeUpdateManyLocalizationsInput>;
  /** text input for default locale (default) */
  text?: InputMaybe<Scalars['String']>;
  tone?: InputMaybe<Tone>;
};

export type BadgeUpdateManyLocalizationDataInput = {
  text?: InputMaybe<Scalars['String']>;
};

export type BadgeUpdateManyLocalizationInput = {
  data: BadgeUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type BadgeUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<BadgeUpdateManyLocalizationInput>>;
};

export type BadgeUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: BadgeUpdateManyInput;
  /** Document search */
  where: BadgeWhereInput;
};

export type BadgeUpdateOneInlineInput = {
  /** Create and connect one Badge document */
  create?: InputMaybe<BadgeCreateInput>;
  /** Delete currently connected Badge document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single Badge document */
  update?: InputMaybe<BadgeUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Badge document */
  upsert?: InputMaybe<BadgeUpsertWithNestedWhereUniqueInput>;
};

export type BadgeUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<BadgeUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: BadgeWhereUniqueInput;
};

export type BadgeUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: BadgeUpdateInput;
  /** Unique document search */
  where: BadgeWhereUniqueInput;
};

export type BadgeUpsertInput = {
  /** Create document if it didn't exist */
  create: BadgeCreateInput;
  /** Update document if it exists */
  update: BadgeUpdateInput;
};

export type BadgeUpsertLocalizationInput = {
  create: BadgeCreateLocalizationDataInput;
  locale: Locale;
  update: BadgeUpdateLocalizationDataInput;
};

export type BadgeUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<BadgeUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: BadgeWhereUniqueInput;
};

export type BadgeUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: BadgeUpsertInput;
  /** Unique document search */
  where: BadgeWhereUniqueInput;
};

/** Identifies documents */
export type BadgeWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BadgeWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BadgeWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BadgeWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  text?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  text_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  text_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  text_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  text_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  text_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  text_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  text_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  text_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  text_starts_with?: InputMaybe<Scalars['String']>;
  tone?: InputMaybe<Tone>;
  /** All values that are contained in given list. */
  tone_in?: InputMaybe<Array<InputMaybe<Tone>>>;
  /** Any other value that exists and is not equal to the given value. */
  tone_not?: InputMaybe<Tone>;
  /** All values that are not contained in given list. */
  tone_not_in?: InputMaybe<Array<InputMaybe<Tone>>>;
};

/** References Badge record uniquely */
export type BadgeWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Represents Banner image adjustment */
export enum BannerImageAdjustment {
  Enlarged = 'Enlarged',
  Normal = 'Normal',
}

/** Values for primary banner image height */
export enum BannerImageHeight {
  Large = 'Large',
  Medium = 'Medium',
  Small = 'Small',
}

export type BannerItem = Entity &
  Node & {
    __typename?: 'BannerItem';
    backgroundColor?: Maybe<BackgroundColor>;
    bannerItemDecoration?: Maybe<CustomDecoration>;
    borderRadius?: Maybe<BorderRadius>;
    c_actionGroup?: Maybe<CActionGroup>;
    /** The time the document was created */
    createdAt: Scalars['DateTime'];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Toggle to remove horizontal padding. Useful to allow banner to be used in full width with c_box container */
    disableHorizontalPadding?: Maybe<Scalars['Boolean']>;
    /** Get the document in other stages */
    documentInStages: Array<BannerItem>;
    heading?: Maybe<CHeading>;
    /** List of BannerItem versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars['ID'];
    paragraph?: Maybe<CParagraph>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    sites: Array<Site>;
    /** Check this to ignore default locale */
    skipDefaultLocale?: Maybe<Scalars['Boolean']>;
    slug?: Maybe<Scalars['String']>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars['DateTime'];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type BannerItemC_ActionGroupArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type BannerItemCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type BannerItemDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type BannerItemHeadingArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type BannerItemHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};

export type BannerItemParagraphArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type BannerItemPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type BannerItemScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type BannerItemSitesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<SiteOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SiteWhereInput>;
};

export type BannerItemUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type BannerItemConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: BannerItemWhereUniqueInput;
};

/** A connection to a list of items. */
export type BannerItemConnection = {
  __typename?: 'BannerItemConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<BannerItemEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type BannerItemCreateInput = {
  backgroundColor?: InputMaybe<BackgroundColor>;
  bannerItemDecoration?: InputMaybe<CustomDecoration>;
  borderRadius?: InputMaybe<BorderRadius>;
  c_actionGroup?: InputMaybe<CActionGroupCreateOneInlineInput>;
  cm0aqxtzh5xhb07um0wtm2dd0?: InputMaybe<BannerItemListCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  disableHorizontalPadding?: InputMaybe<Scalars['Boolean']>;
  heading?: InputMaybe<CHeadingCreateOneInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<BannerItemCreateLocalizationsInput>;
  paragraph?: InputMaybe<CParagraphCreateOneInlineInput>;
  sites?: InputMaybe<SiteCreateManyInlineInput>;
  skipDefaultLocale?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BannerItemCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BannerItemCreateLocalizationInput = {
  /** Localization input */
  data: BannerItemCreateLocalizationDataInput;
  locale: Locale;
};

export type BannerItemCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<BannerItemCreateLocalizationInput>>;
};

export type BannerItemCreateManyInlineInput = {
  /** Connect multiple existing BannerItem documents */
  connect?: InputMaybe<Array<BannerItemWhereUniqueInput>>;
  /** Create and connect multiple existing BannerItem documents */
  create?: InputMaybe<Array<BannerItemCreateInput>>;
};

export type BannerItemCreateOneInlineInput = {
  /** Connect one existing BannerItem document */
  connect?: InputMaybe<BannerItemWhereUniqueInput>;
  /** Create and connect one BannerItem document */
  create?: InputMaybe<BannerItemCreateInput>;
};

/** An edge in a connection. */
export type BannerItemEdge = {
  __typename?: 'BannerItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: BannerItem;
};

export type BannerItemList = Entity & {
  __typename?: 'BannerItemList';
  bannerItems: Array<BannerItem>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System stage field */
  stage: Stage;
};

export type BannerItemListBannerItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<BannerItemOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BannerItemWhereInput>;
};

export type BannerItemListConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: BannerItemListWhereUniqueInput;
};

/** A connection to a list of items. */
export type BannerItemListConnection = {
  __typename?: 'BannerItemListConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<BannerItemListEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type BannerItemListCreateInput = {
  bannerItems?: InputMaybe<BannerItemCreateManyInlineInput>;
};

export type BannerItemListCreateManyInlineInput = {
  /** Create and connect multiple existing BannerItemList documents */
  create?: InputMaybe<Array<BannerItemListCreateInput>>;
};

export type BannerItemListCreateOneInlineInput = {
  /** Create and connect one BannerItemList document */
  create?: InputMaybe<BannerItemListCreateInput>;
};

export type BannerItemListCreateWithPositionInput = {
  /** Document to create */
  data: BannerItemListCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type BannerItemListEdge = {
  __typename?: 'BannerItemListEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: BannerItemList;
};

/** Identifies documents */
export type BannerItemListManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BannerItemListWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BannerItemListWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BannerItemListWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  bannerItems_every?: InputMaybe<BannerItemWhereInput>;
  bannerItems_none?: InputMaybe<BannerItemWhereInput>;
  bannerItems_some?: InputMaybe<BannerItemWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
};

export enum BannerItemListOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type BannerItemListParent = CBlock;

export type BannerItemListParentConnectInput = {
  CBlock?: InputMaybe<CBlockConnectInput>;
};

export type BannerItemListParentCreateInput = {
  CBlock?: InputMaybe<CBlockCreateInput>;
};

export type BannerItemListParentCreateManyInlineInput = {
  /** Connect multiple existing BannerItemListParent documents */
  connect?: InputMaybe<Array<BannerItemListParentWhereUniqueInput>>;
  /** Create and connect multiple existing BannerItemListParent documents */
  create?: InputMaybe<Array<BannerItemListParentCreateInput>>;
};

export type BannerItemListParentCreateOneInlineInput = {
  /** Connect one existing BannerItemListParent document */
  connect?: InputMaybe<BannerItemListParentWhereUniqueInput>;
  /** Create and connect one BannerItemListParent document */
  create?: InputMaybe<BannerItemListParentCreateInput>;
};

export type BannerItemListParentUpdateInput = {
  CBlock?: InputMaybe<CBlockUpdateInput>;
};

export type BannerItemListParentUpdateManyInlineInput = {
  /** Connect multiple existing BannerItemListParent documents */
  connect?: InputMaybe<Array<BannerItemListParentConnectInput>>;
  /** Create and connect multiple BannerItemListParent documents */
  create?: InputMaybe<Array<BannerItemListParentCreateInput>>;
  /** Delete multiple BannerItemListParent documents */
  delete?: InputMaybe<Array<BannerItemListParentWhereUniqueInput>>;
  /** Disconnect multiple BannerItemListParent documents */
  disconnect?: InputMaybe<Array<BannerItemListParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing BannerItemListParent documents */
  set?: InputMaybe<Array<BannerItemListParentWhereUniqueInput>>;
  /** Update multiple BannerItemListParent documents */
  update?: InputMaybe<
    Array<BannerItemListParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple BannerItemListParent documents */
  upsert?: InputMaybe<
    Array<BannerItemListParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type BannerItemListParentUpdateManyWithNestedWhereInput = {
  CBlock?: InputMaybe<CBlockUpdateManyWithNestedWhereInput>;
};

export type BannerItemListParentUpdateOneInlineInput = {
  /** Connect existing BannerItemListParent document */
  connect?: InputMaybe<BannerItemListParentWhereUniqueInput>;
  /** Create and connect one BannerItemListParent document */
  create?: InputMaybe<BannerItemListParentCreateInput>;
  /** Delete currently connected BannerItemListParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected BannerItemListParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single BannerItemListParent document */
  update?: InputMaybe<BannerItemListParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single BannerItemListParent document */
  upsert?: InputMaybe<BannerItemListParentUpsertWithNestedWhereUniqueInput>;
};

export type BannerItemListParentUpdateWithNestedWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockUpdateWithNestedWhereUniqueInput>;
};

export type BannerItemListParentUpsertWithNestedWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockUpsertWithNestedWhereUniqueInput>;
};

export type BannerItemListParentWhereInput = {
  CBlock?: InputMaybe<CBlockWhereInput>;
};

export type BannerItemListParentWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockWhereUniqueInput>;
};

export type BannerItemListUpdateInput = {
  bannerItems?: InputMaybe<BannerItemUpdateManyInlineInput>;
};

export type BannerItemListUpdateManyInlineInput = {
  /** Create and connect multiple BannerItemList component instances */
  create?: InputMaybe<Array<BannerItemListCreateWithPositionInput>>;
  /** Delete multiple BannerItemList documents */
  delete?: InputMaybe<Array<BannerItemListWhereUniqueInput>>;
  /** Update multiple BannerItemList component instances */
  update?: InputMaybe<
    Array<BannerItemListUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple BannerItemList component instances */
  upsert?: InputMaybe<
    Array<BannerItemListUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type BannerItemListUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']>;
};

export type BannerItemListUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: BannerItemListUpdateManyInput;
  /** Document search */
  where: BannerItemListWhereInput;
};

export type BannerItemListUpdateOneInlineInput = {
  /** Create and connect one BannerItemList document */
  create?: InputMaybe<BannerItemListCreateInput>;
  /** Delete currently connected BannerItemList document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single BannerItemList document */
  update?: InputMaybe<BannerItemListUpdateWithNestedWhereUniqueInput>;
  /** Upsert single BannerItemList document */
  upsert?: InputMaybe<BannerItemListUpsertWithNestedWhereUniqueInput>;
};

export type BannerItemListUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<BannerItemListUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: BannerItemListWhereUniqueInput;
};

export type BannerItemListUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: BannerItemListUpdateInput;
  /** Unique document search */
  where: BannerItemListWhereUniqueInput;
};

export type BannerItemListUpsertInput = {
  /** Create document if it didn't exist */
  create: BannerItemListCreateInput;
  /** Update document if it exists */
  update: BannerItemListUpdateInput;
};

export type BannerItemListUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<BannerItemListUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: BannerItemListWhereUniqueInput;
};

export type BannerItemListUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: BannerItemListUpsertInput;
  /** Unique document search */
  where: BannerItemListWhereUniqueInput;
};

/** Identifies documents */
export type BannerItemListWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BannerItemListWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BannerItemListWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BannerItemListWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  bannerItems_every?: InputMaybe<BannerItemWhereInput>;
  bannerItems_none?: InputMaybe<BannerItemWhereInput>;
  bannerItems_some?: InputMaybe<BannerItemWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
};

/** References BannerItemList record uniquely */
export type BannerItemListWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Identifies documents */
export type BannerItemManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BannerItemWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BannerItemWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BannerItemWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  backgroundColor?: InputMaybe<BackgroundColor>;
  /** All values that are contained in given list. */
  backgroundColor_in?: InputMaybe<Array<InputMaybe<BackgroundColor>>>;
  /** Any other value that exists and is not equal to the given value. */
  backgroundColor_not?: InputMaybe<BackgroundColor>;
  /** All values that are not contained in given list. */
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<BackgroundColor>>>;
  bannerItemDecoration?: InputMaybe<CustomDecoration>;
  /** All values that are contained in given list. */
  bannerItemDecoration_in?: InputMaybe<Array<InputMaybe<CustomDecoration>>>;
  /** Any other value that exists and is not equal to the given value. */
  bannerItemDecoration_not?: InputMaybe<CustomDecoration>;
  /** All values that are not contained in given list. */
  bannerItemDecoration_not_in?: InputMaybe<Array<InputMaybe<CustomDecoration>>>;
  borderRadius?: InputMaybe<BorderRadius>;
  /** All values that are contained in given list. */
  borderRadius_in?: InputMaybe<Array<InputMaybe<BorderRadius>>>;
  /** Any other value that exists and is not equal to the given value. */
  borderRadius_not?: InputMaybe<BorderRadius>;
  /** All values that are not contained in given list. */
  borderRadius_not_in?: InputMaybe<Array<InputMaybe<BorderRadius>>>;
  c_actionGroup?: InputMaybe<CActionGroupWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  disableHorizontalPadding?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  disableHorizontalPadding_not?: InputMaybe<Scalars['Boolean']>;
  documentInStages_every?: InputMaybe<BannerItemWhereStageInput>;
  documentInStages_none?: InputMaybe<BannerItemWhereStageInput>;
  documentInStages_some?: InputMaybe<BannerItemWhereStageInput>;
  heading?: InputMaybe<CHeadingWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  paragraph?: InputMaybe<CParagraphWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  sites_every?: InputMaybe<SiteWhereInput>;
  sites_none?: InputMaybe<SiteWhereInput>;
  sites_some?: InputMaybe<SiteWhereInput>;
  skipDefaultLocale?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  skipDefaultLocale_not?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum BannerItemOrderByInput {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  BannerItemDecorationAsc = 'bannerItemDecoration_ASC',
  BannerItemDecorationDesc = 'bannerItemDecoration_DESC',
  BorderRadiusAsc = 'borderRadius_ASC',
  BorderRadiusDesc = 'borderRadius_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DisableHorizontalPaddingAsc = 'disableHorizontalPadding_ASC',
  DisableHorizontalPaddingDesc = 'disableHorizontalPadding_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SkipDefaultLocaleAsc = 'skipDefaultLocale_ASC',
  SkipDefaultLocaleDesc = 'skipDefaultLocale_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type BannerItemUpdateInput = {
  backgroundColor?: InputMaybe<BackgroundColor>;
  bannerItemDecoration?: InputMaybe<CustomDecoration>;
  borderRadius?: InputMaybe<BorderRadius>;
  c_actionGroup?: InputMaybe<CActionGroupUpdateOneInlineInput>;
  cm0aqxtzh5xhb07um0wtm2dd0?: InputMaybe<BannerItemListUpdateManyInlineInput>;
  disableHorizontalPadding?: InputMaybe<Scalars['Boolean']>;
  heading?: InputMaybe<CHeadingUpdateOneInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<BannerItemUpdateLocalizationsInput>;
  paragraph?: InputMaybe<CParagraphUpdateOneInlineInput>;
  sites?: InputMaybe<SiteUpdateManyInlineInput>;
  skipDefaultLocale?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type BannerItemUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<BannerItemCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
};

export type BannerItemUpdateManyInlineInput = {
  /** Connect multiple existing BannerItem documents */
  connect?: InputMaybe<Array<BannerItemConnectInput>>;
  /** Create and connect multiple BannerItem documents */
  create?: InputMaybe<Array<BannerItemCreateInput>>;
  /** Delete multiple BannerItem documents */
  delete?: InputMaybe<Array<BannerItemWhereUniqueInput>>;
  /** Disconnect multiple BannerItem documents */
  disconnect?: InputMaybe<Array<BannerItemWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing BannerItem documents */
  set?: InputMaybe<Array<BannerItemWhereUniqueInput>>;
  /** Update multiple BannerItem documents */
  update?: InputMaybe<Array<BannerItemUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple BannerItem documents */
  upsert?: InputMaybe<Array<BannerItemUpsertWithNestedWhereUniqueInput>>;
};

export type BannerItemUpdateManyInput = {
  backgroundColor?: InputMaybe<BackgroundColor>;
  bannerItemDecoration?: InputMaybe<CustomDecoration>;
  borderRadius?: InputMaybe<BorderRadius>;
  disableHorizontalPadding?: InputMaybe<Scalars['Boolean']>;
  skipDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type BannerItemUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: BannerItemUpdateManyInput;
  /** Document search */
  where: BannerItemWhereInput;
};

export type BannerItemUpdateOneInlineInput = {
  /** Connect existing BannerItem document */
  connect?: InputMaybe<BannerItemWhereUniqueInput>;
  /** Create and connect one BannerItem document */
  create?: InputMaybe<BannerItemCreateInput>;
  /** Delete currently connected BannerItem document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected BannerItem document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single BannerItem document */
  update?: InputMaybe<BannerItemUpdateWithNestedWhereUniqueInput>;
  /** Upsert single BannerItem document */
  upsert?: InputMaybe<BannerItemUpsertWithNestedWhereUniqueInput>;
};

export type BannerItemUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: BannerItemUpdateInput;
  /** Unique document search */
  where: BannerItemWhereUniqueInput;
};

export type BannerItemUpsertInput = {
  /** Create document if it didn't exist */
  create: BannerItemCreateInput;
  /** Update document if it exists */
  update: BannerItemUpdateInput;
};

export type BannerItemUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: BannerItemUpsertInput;
  /** Unique document search */
  where: BannerItemWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type BannerItemWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type BannerItemWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BannerItemWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BannerItemWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BannerItemWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  backgroundColor?: InputMaybe<BackgroundColor>;
  /** All values that are contained in given list. */
  backgroundColor_in?: InputMaybe<Array<InputMaybe<BackgroundColor>>>;
  /** Any other value that exists and is not equal to the given value. */
  backgroundColor_not?: InputMaybe<BackgroundColor>;
  /** All values that are not contained in given list. */
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<BackgroundColor>>>;
  bannerItemDecoration?: InputMaybe<CustomDecoration>;
  /** All values that are contained in given list. */
  bannerItemDecoration_in?: InputMaybe<Array<InputMaybe<CustomDecoration>>>;
  /** Any other value that exists and is not equal to the given value. */
  bannerItemDecoration_not?: InputMaybe<CustomDecoration>;
  /** All values that are not contained in given list. */
  bannerItemDecoration_not_in?: InputMaybe<Array<InputMaybe<CustomDecoration>>>;
  borderRadius?: InputMaybe<BorderRadius>;
  /** All values that are contained in given list. */
  borderRadius_in?: InputMaybe<Array<InputMaybe<BorderRadius>>>;
  /** Any other value that exists and is not equal to the given value. */
  borderRadius_not?: InputMaybe<BorderRadius>;
  /** All values that are not contained in given list. */
  borderRadius_not_in?: InputMaybe<Array<InputMaybe<BorderRadius>>>;
  c_actionGroup?: InputMaybe<CActionGroupWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  disableHorizontalPadding?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  disableHorizontalPadding_not?: InputMaybe<Scalars['Boolean']>;
  documentInStages_every?: InputMaybe<BannerItemWhereStageInput>;
  documentInStages_none?: InputMaybe<BannerItemWhereStageInput>;
  documentInStages_some?: InputMaybe<BannerItemWhereStageInput>;
  heading?: InputMaybe<CHeadingWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  paragraph?: InputMaybe<CParagraphWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  sites_every?: InputMaybe<SiteWhereInput>;
  sites_none?: InputMaybe<SiteWhereInput>;
  sites_some?: InputMaybe<SiteWhereInput>;
  skipDefaultLocale?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  skipDefaultLocale_not?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type BannerItemWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BannerItemWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BannerItemWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BannerItemWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<BannerItemWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References BannerItem record uniquely */
export type BannerItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
};

/** Represents Banner styles */
export enum BannerStyle {
  Background = 'Background',
  Inline = 'Inline',
  Primary = 'Primary',
  Tertiary = 'Tertiary',
}

export type BatchPayload = {
  __typename?: 'BatchPayload';
  /** The number of nodes that have been affected by the Batch operation. */
  count: Scalars['Long'];
};

/** Configure border radius for Box. */
export enum BorderRadius {
  Full = 'full',
  Large = 'large',
  None = 'none',
  Small = 'small',
  Standard = 'standard',
  Xlarge = 'xlarge',
}

export enum BoxDisplay {
  Block = 'block',
  Flex = 'flex',
  InlineBlock = 'inlineBlock',
}

export enum BoxJustifyContent {
  Center = 'center',
  FlexEnd = 'flexEnd',
  FlexStart = 'flexStart',
}

/** Represents the available box shadows properties. */
export enum BoxShadows {
  BorderBrandAccent = 'borderBrandAccent',
  BorderBrandAccentLarge = 'borderBrandAccentLarge',
  BorderBrandAccentLight = 'borderBrandAccentLight',
  BorderBrandAccentLightLarge = 'borderBrandAccentLightLarge',
  BorderCaution = 'borderCaution',
  BorderCautionLight = 'borderCautionLight',
  BorderCritical = 'borderCritical',
  BorderCriticalLarge = 'borderCriticalLarge',
  BorderCriticalLight = 'borderCriticalLight',
  BorderCriticalLightLarge = 'borderCriticalLightLarge',
  BorderField = 'borderField',
  BorderFormAccent = 'borderFormAccent',
  BorderFormAccentLarge = 'borderFormAccentLarge',
  BorderFormAccentLight = 'borderFormAccentLight',
  BorderFormAccentLightLarge = 'borderFormAccentLightLarge',
  BorderInfo = 'borderInfo',
  BorderInfoLight = 'borderInfoLight',
  BorderNeutral = 'borderNeutral',
  BorderNeutralInverted = 'borderNeutralInverted',
  BorderNeutralInvertedLarge = 'borderNeutralInvertedLarge',
  BorderNeutralLarge = 'borderNeutralLarge',
  BorderNeutralLight = 'borderNeutralLight',
  BorderPositive = 'borderPositive',
  BorderPositiveLight = 'borderPositiveLight',
  BorderPromote = 'borderPromote',
  BorderPromoteLight = 'borderPromoteLight',
  Large = 'large',
  Medium = 'medium',
  None = 'none',
  OutlineFocus = 'outlineFocus',
  Small = 'small',
}

export type BraidComponent = Entity & {
  __typename?: 'BraidComponent';
  braidCode: Scalars['String'];
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<BraidComponent>;
  /** System stage field */
  stage: Stage;
};

export type BraidComponentLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type BraidComponentConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: BraidComponentWhereUniqueInput;
};

/** A connection to a list of items. */
export type BraidComponentConnection = {
  __typename?: 'BraidComponentConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<BraidComponentEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type BraidComponentCreateInput = {
  /** braidCode input for default locale (default) */
  braidCode: Scalars['String'];
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<BraidComponentCreateLocalizationsInput>;
};

export type BraidComponentCreateLocalizationDataInput = {
  braidCode: Scalars['String'];
};

export type BraidComponentCreateLocalizationInput = {
  /** Localization input */
  data: BraidComponentCreateLocalizationDataInput;
  locale: Locale;
};

export type BraidComponentCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<BraidComponentCreateLocalizationInput>>;
};

export type BraidComponentCreateManyInlineInput = {
  /** Create and connect multiple existing BraidComponent documents */
  create?: InputMaybe<Array<BraidComponentCreateInput>>;
};

export type BraidComponentCreateOneInlineInput = {
  /** Create and connect one BraidComponent document */
  create?: InputMaybe<BraidComponentCreateInput>;
};

export type BraidComponentCreateWithPositionInput = {
  /** Document to create */
  data: BraidComponentCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type BraidComponentEdge = {
  __typename?: 'BraidComponentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: BraidComponent;
};

/** Identifies documents */
export type BraidComponentManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BraidComponentWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BraidComponentWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BraidComponentWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
};

export enum BraidComponentOrderByInput {
  BraidCodeAsc = 'braidCode_ASC',
  BraidCodeDesc = 'braidCode_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type BraidComponentParent = CCustomComponent;

export type BraidComponentParentConnectInput = {
  CCustomComponent?: InputMaybe<CCustomComponentConnectInput>;
};

export type BraidComponentParentCreateInput = {
  CCustomComponent?: InputMaybe<CCustomComponentCreateInput>;
};

export type BraidComponentParentCreateManyInlineInput = {
  /** Create and connect multiple existing BraidComponentParent documents */
  create?: InputMaybe<Array<BraidComponentParentCreateInput>>;
};

export type BraidComponentParentCreateOneInlineInput = {
  /** Create and connect one BraidComponentParent document */
  create?: InputMaybe<BraidComponentParentCreateInput>;
};

export type BraidComponentParentCreateWithPositionInput = {
  CCustomComponent?: InputMaybe<CCustomComponentCreateWithPositionInput>;
};

export type BraidComponentParentUpdateInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpdateInput>;
};

export type BraidComponentParentUpdateManyInlineInput = {
  /** Create and connect multiple BraidComponentParent component instances */
  create?: InputMaybe<Array<BraidComponentParentCreateWithPositionInput>>;
  /** Delete multiple BraidComponentParent documents */
  delete?: InputMaybe<Array<BraidComponentParentWhereUniqueInput>>;
  /** Update multiple BraidComponentParent component instances */
  update?: InputMaybe<
    Array<BraidComponentParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple BraidComponentParent component instances */
  upsert?: InputMaybe<
    Array<BraidComponentParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type BraidComponentParentUpdateManyWithNestedWhereInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpdateManyWithNestedWhereInput>;
};

export type BraidComponentParentUpdateOneInlineInput = {
  /** Create and connect one BraidComponentParent document */
  create?: InputMaybe<BraidComponentParentCreateInput>;
  /** Delete currently connected BraidComponentParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single BraidComponentParent document */
  update?: InputMaybe<BraidComponentParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single BraidComponentParent document */
  upsert?: InputMaybe<BraidComponentParentUpsertWithNestedWhereUniqueInput>;
};

export type BraidComponentParentUpdateWithNestedWhereUniqueAndPositionInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type BraidComponentParentUpdateWithNestedWhereUniqueInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpdateWithNestedWhereUniqueInput>;
};

export type BraidComponentParentUpsertWithNestedWhereUniqueAndPositionInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type BraidComponentParentUpsertWithNestedWhereUniqueInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpsertWithNestedWhereUniqueInput>;
};

export type BraidComponentParentWhereInput = {
  CCustomComponent?: InputMaybe<CCustomComponentWhereInput>;
};

export type BraidComponentParentWhereUniqueInput = {
  CCustomComponent?: InputMaybe<CCustomComponentWhereUniqueInput>;
};

export type BraidComponentUpdateInput = {
  /** braidCode input for default locale (default) */
  braidCode?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<BraidComponentUpdateLocalizationsInput>;
};

export type BraidComponentUpdateLocalizationDataInput = {
  braidCode?: InputMaybe<Scalars['String']>;
};

export type BraidComponentUpdateLocalizationInput = {
  data: BraidComponentUpdateLocalizationDataInput;
  locale: Locale;
};

export type BraidComponentUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<BraidComponentCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<BraidComponentUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<BraidComponentUpsertLocalizationInput>>;
};

export type BraidComponentUpdateManyInlineInput = {
  /** Create and connect multiple BraidComponent component instances */
  create?: InputMaybe<Array<BraidComponentCreateWithPositionInput>>;
  /** Delete multiple BraidComponent documents */
  delete?: InputMaybe<Array<BraidComponentWhereUniqueInput>>;
  /** Update multiple BraidComponent component instances */
  update?: InputMaybe<
    Array<BraidComponentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple BraidComponent component instances */
  upsert?: InputMaybe<
    Array<BraidComponentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type BraidComponentUpdateManyInput = {
  /** braidCode input for default locale (default) */
  braidCode?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<BraidComponentUpdateManyLocalizationsInput>;
};

export type BraidComponentUpdateManyLocalizationDataInput = {
  braidCode?: InputMaybe<Scalars['String']>;
};

export type BraidComponentUpdateManyLocalizationInput = {
  data: BraidComponentUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type BraidComponentUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<BraidComponentUpdateManyLocalizationInput>>;
};

export type BraidComponentUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: BraidComponentUpdateManyInput;
  /** Document search */
  where: BraidComponentWhereInput;
};

export type BraidComponentUpdateOneInlineInput = {
  /** Create and connect one BraidComponent document */
  create?: InputMaybe<BraidComponentCreateInput>;
  /** Delete currently connected BraidComponent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single BraidComponent document */
  update?: InputMaybe<BraidComponentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single BraidComponent document */
  upsert?: InputMaybe<BraidComponentUpsertWithNestedWhereUniqueInput>;
};

export type BraidComponentUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<BraidComponentUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: BraidComponentWhereUniqueInput;
};

export type BraidComponentUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: BraidComponentUpdateInput;
  /** Unique document search */
  where: BraidComponentWhereUniqueInput;
};

export type BraidComponentUpsertInput = {
  /** Create document if it didn't exist */
  create: BraidComponentCreateInput;
  /** Update document if it exists */
  update: BraidComponentUpdateInput;
};

export type BraidComponentUpsertLocalizationInput = {
  create: BraidComponentCreateLocalizationDataInput;
  locale: Locale;
  update: BraidComponentUpdateLocalizationDataInput;
};

export type BraidComponentUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<BraidComponentUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: BraidComponentWhereUniqueInput;
};

export type BraidComponentUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: BraidComponentUpsertInput;
  /** Unique document search */
  where: BraidComponentWhereUniqueInput;
};

/** Identifies documents */
export type BraidComponentWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BraidComponentWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BraidComponentWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BraidComponentWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  braidCode?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  braidCode_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  braidCode_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  braidCode_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  braidCode_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  braidCode_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  braidCode_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  braidCode_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  braidCode_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  braidCode_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
};

/** References BraidComponent record uniquely */
export type BraidComponentWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Represents the range of named Braid sizes. */
export enum BraidSizes {
  Gutter = 'gutter',
  Large = 'large',
  Medium = 'medium',
  None = 'none',
  Small = 'small',
  Xlarge = 'xlarge',
  Xsmall = 'xsmall',
  Xxlarge = 'xxlarge',
  Xxsmall = 'xxsmall',
  Xxxlarge = 'xxxlarge',
}

/** Seek brands */
export enum Brands {
  Jobsdb = 'jobsdb',
  Jobstreet = 'jobstreet',
  Seek = 'seek',
}

/** Used to embed Brightcove videos. */
export type BrightcoveVideo = Entity & {
  __typename?: 'BrightcoveVideo';
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<BrightcoveVideo>;
  /** System stage field */
  stage: Stage;
  videoId?: Maybe<Scalars['String']>;
  videoUrl: Scalars['String'];
};

/** Used to embed Brightcove videos. */
export type BrightcoveVideoLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type BrightcoveVideoConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: BrightcoveVideoWhereUniqueInput;
};

/** A connection to a list of items. */
export type BrightcoveVideoConnection = {
  __typename?: 'BrightcoveVideoConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<BrightcoveVideoEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type BrightcoveVideoCreateInput = {
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<BrightcoveVideoCreateLocalizationsInput>;
  /** videoId input for default locale (default) */
  videoId?: InputMaybe<Scalars['String']>;
  /** videoUrl input for default locale (default) */
  videoUrl: Scalars['String'];
};

export type BrightcoveVideoCreateLocalizationDataInput = {
  videoId?: InputMaybe<Scalars['String']>;
  videoUrl: Scalars['String'];
};

export type BrightcoveVideoCreateLocalizationInput = {
  /** Localization input */
  data: BrightcoveVideoCreateLocalizationDataInput;
  locale: Locale;
};

export type BrightcoveVideoCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<BrightcoveVideoCreateLocalizationInput>>;
};

export type BrightcoveVideoCreateManyInlineInput = {
  /** Create and connect multiple existing BrightcoveVideo documents */
  create?: InputMaybe<Array<BrightcoveVideoCreateInput>>;
};

export type BrightcoveVideoCreateOneInlineInput = {
  /** Create and connect one BrightcoveVideo document */
  create?: InputMaybe<BrightcoveVideoCreateInput>;
};

export type BrightcoveVideoCreateWithPositionInput = {
  /** Document to create */
  data: BrightcoveVideoCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type BrightcoveVideoEdge = {
  __typename?: 'BrightcoveVideoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: BrightcoveVideo;
};

/** Identifies documents */
export type BrightcoveVideoManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BrightcoveVideoWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BrightcoveVideoWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BrightcoveVideoWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
};

export enum BrightcoveVideoOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VideoIdAsc = 'videoId_ASC',
  VideoIdDesc = 'videoId_DESC',
  VideoUrlAsc = 'videoUrl_ASC',
  VideoUrlDesc = 'videoUrl_DESC',
}

export type BrightcoveVideoParent = DialogVideo | Video;

export type BrightcoveVideoParentConnectInput = {
  DialogVideo?: InputMaybe<DialogVideoConnectInput>;
  Video?: InputMaybe<VideoConnectInput>;
};

export type BrightcoveVideoParentCreateInput = {
  DialogVideo?: InputMaybe<DialogVideoCreateInput>;
  Video?: InputMaybe<VideoCreateInput>;
};

export type BrightcoveVideoParentCreateManyInlineInput = {
  /** Create and connect multiple existing BrightcoveVideoParent documents */
  create?: InputMaybe<Array<BrightcoveVideoParentCreateInput>>;
};

export type BrightcoveVideoParentCreateOneInlineInput = {
  /** Create and connect one BrightcoveVideoParent document */
  create?: InputMaybe<BrightcoveVideoParentCreateInput>;
};

export type BrightcoveVideoParentCreateWithPositionInput = {
  DialogVideo?: InputMaybe<DialogVideoCreateWithPositionInput>;
  Video?: InputMaybe<VideoCreateWithPositionInput>;
};

export type BrightcoveVideoParentUpdateInput = {
  DialogVideo?: InputMaybe<DialogVideoUpdateInput>;
  Video?: InputMaybe<VideoUpdateInput>;
};

export type BrightcoveVideoParentUpdateManyInlineInput = {
  /** Create and connect multiple BrightcoveVideoParent component instances */
  create?: InputMaybe<Array<BrightcoveVideoParentCreateWithPositionInput>>;
  /** Delete multiple BrightcoveVideoParent documents */
  delete?: InputMaybe<Array<BrightcoveVideoParentWhereUniqueInput>>;
  /** Update multiple BrightcoveVideoParent component instances */
  update?: InputMaybe<
    Array<BrightcoveVideoParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple BrightcoveVideoParent component instances */
  upsert?: InputMaybe<
    Array<BrightcoveVideoParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type BrightcoveVideoParentUpdateManyWithNestedWhereInput = {
  DialogVideo?: InputMaybe<DialogVideoUpdateManyWithNestedWhereInput>;
  Video?: InputMaybe<VideoUpdateManyWithNestedWhereInput>;
};

export type BrightcoveVideoParentUpdateOneInlineInput = {
  /** Create and connect one BrightcoveVideoParent document */
  create?: InputMaybe<BrightcoveVideoParentCreateInput>;
  /** Delete currently connected BrightcoveVideoParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single BrightcoveVideoParent document */
  update?: InputMaybe<BrightcoveVideoParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single BrightcoveVideoParent document */
  upsert?: InputMaybe<BrightcoveVideoParentUpsertWithNestedWhereUniqueInput>;
};

export type BrightcoveVideoParentUpdateWithNestedWhereUniqueAndPositionInput = {
  DialogVideo?: InputMaybe<DialogVideoUpdateWithNestedWhereUniqueAndPositionInput>;
  Video?: InputMaybe<VideoUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type BrightcoveVideoParentUpdateWithNestedWhereUniqueInput = {
  DialogVideo?: InputMaybe<DialogVideoUpdateWithNestedWhereUniqueInput>;
  Video?: InputMaybe<VideoUpdateWithNestedWhereUniqueInput>;
};

export type BrightcoveVideoParentUpsertWithNestedWhereUniqueAndPositionInput = {
  DialogVideo?: InputMaybe<DialogVideoUpsertWithNestedWhereUniqueAndPositionInput>;
  Video?: InputMaybe<VideoUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type BrightcoveVideoParentUpsertWithNestedWhereUniqueInput = {
  DialogVideo?: InputMaybe<DialogVideoUpsertWithNestedWhereUniqueInput>;
  Video?: InputMaybe<VideoUpsertWithNestedWhereUniqueInput>;
};

export type BrightcoveVideoParentWhereInput = {
  DialogVideo?: InputMaybe<DialogVideoWhereInput>;
  Video?: InputMaybe<VideoWhereInput>;
};

export type BrightcoveVideoParentWhereUniqueInput = {
  DialogVideo?: InputMaybe<DialogVideoWhereUniqueInput>;
  Video?: InputMaybe<VideoWhereUniqueInput>;
};

export type BrightcoveVideoUpdateInput = {
  /** Manage document localizations */
  localizations?: InputMaybe<BrightcoveVideoUpdateLocalizationsInput>;
  /** videoId input for default locale (default) */
  videoId?: InputMaybe<Scalars['String']>;
  /** videoUrl input for default locale (default) */
  videoUrl?: InputMaybe<Scalars['String']>;
};

export type BrightcoveVideoUpdateLocalizationDataInput = {
  videoId?: InputMaybe<Scalars['String']>;
  videoUrl?: InputMaybe<Scalars['String']>;
};

export type BrightcoveVideoUpdateLocalizationInput = {
  data: BrightcoveVideoUpdateLocalizationDataInput;
  locale: Locale;
};

export type BrightcoveVideoUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<BrightcoveVideoCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<BrightcoveVideoUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<BrightcoveVideoUpsertLocalizationInput>>;
};

export type BrightcoveVideoUpdateManyInlineInput = {
  /** Create and connect multiple BrightcoveVideo component instances */
  create?: InputMaybe<Array<BrightcoveVideoCreateWithPositionInput>>;
  /** Delete multiple BrightcoveVideo documents */
  delete?: InputMaybe<Array<BrightcoveVideoWhereUniqueInput>>;
  /** Update multiple BrightcoveVideo component instances */
  update?: InputMaybe<
    Array<BrightcoveVideoUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple BrightcoveVideo component instances */
  upsert?: InputMaybe<
    Array<BrightcoveVideoUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type BrightcoveVideoUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<BrightcoveVideoUpdateManyLocalizationsInput>;
  /** videoId input for default locale (default) */
  videoId?: InputMaybe<Scalars['String']>;
  /** videoUrl input for default locale (default) */
  videoUrl?: InputMaybe<Scalars['String']>;
};

export type BrightcoveVideoUpdateManyLocalizationDataInput = {
  videoId?: InputMaybe<Scalars['String']>;
  videoUrl?: InputMaybe<Scalars['String']>;
};

export type BrightcoveVideoUpdateManyLocalizationInput = {
  data: BrightcoveVideoUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type BrightcoveVideoUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<BrightcoveVideoUpdateManyLocalizationInput>>;
};

export type BrightcoveVideoUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: BrightcoveVideoUpdateManyInput;
  /** Document search */
  where: BrightcoveVideoWhereInput;
};

export type BrightcoveVideoUpdateOneInlineInput = {
  /** Create and connect one BrightcoveVideo document */
  create?: InputMaybe<BrightcoveVideoCreateInput>;
  /** Delete currently connected BrightcoveVideo document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single BrightcoveVideo document */
  update?: InputMaybe<BrightcoveVideoUpdateWithNestedWhereUniqueInput>;
  /** Upsert single BrightcoveVideo document */
  upsert?: InputMaybe<BrightcoveVideoUpsertWithNestedWhereUniqueInput>;
};

export type BrightcoveVideoUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<BrightcoveVideoUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: BrightcoveVideoWhereUniqueInput;
};

export type BrightcoveVideoUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: BrightcoveVideoUpdateInput;
  /** Unique document search */
  where: BrightcoveVideoWhereUniqueInput;
};

export type BrightcoveVideoUpsertInput = {
  /** Create document if it didn't exist */
  create: BrightcoveVideoCreateInput;
  /** Update document if it exists */
  update: BrightcoveVideoUpdateInput;
};

export type BrightcoveVideoUpsertLocalizationInput = {
  create: BrightcoveVideoCreateLocalizationDataInput;
  locale: Locale;
  update: BrightcoveVideoUpdateLocalizationDataInput;
};

export type BrightcoveVideoUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<BrightcoveVideoUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: BrightcoveVideoWhereUniqueInput;
};

export type BrightcoveVideoUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: BrightcoveVideoUpsertInput;
  /** Unique document search */
  where: BrightcoveVideoWhereUniqueInput;
};

/** Identifies documents */
export type BrightcoveVideoWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BrightcoveVideoWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BrightcoveVideoWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BrightcoveVideoWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  videoId?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  videoId_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  videoId_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  videoId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  videoId_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  videoId_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  videoId_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  videoId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  videoId_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  videoId_starts_with?: InputMaybe<Scalars['String']>;
  videoUrl?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  videoUrl_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  videoUrl_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  videoUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  videoUrl_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  videoUrl_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  videoUrl_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  videoUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  videoUrl_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  videoUrl_starts_with?: InputMaybe<Scalars['String']>;
};

/** References BrightcoveVideo record uniquely */
export type BrightcoveVideoWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Bug bounty form. */
export type BugcrowdForm = Entity & {
  __typename?: 'BugcrowdForm';
  /** The unique identifier */
  id: Scalars['ID'];
  /** System stage field */
  stage: Stage;
};

export type BugcrowdFormConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: BugcrowdFormWhereUniqueInput;
};

/** A connection to a list of items. */
export type BugcrowdFormConnection = {
  __typename?: 'BugcrowdFormConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<BugcrowdFormEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type BugcrowdFormCreateInput = {
  /** No fields in create input */
  _?: InputMaybe<Scalars['String']>;
};

export type BugcrowdFormCreateManyInlineInput = {
  /** Create and connect multiple existing BugcrowdForm documents */
  create?: InputMaybe<Array<BugcrowdFormCreateInput>>;
};

export type BugcrowdFormCreateOneInlineInput = {
  /** Create and connect one BugcrowdForm document */
  create?: InputMaybe<BugcrowdFormCreateInput>;
};

export type BugcrowdFormCreateWithPositionInput = {
  /** Document to create */
  data: BugcrowdFormCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type BugcrowdFormEdge = {
  __typename?: 'BugcrowdFormEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: BugcrowdForm;
};

/** Identifies documents */
export type BugcrowdFormManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BugcrowdFormWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BugcrowdFormWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BugcrowdFormWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
};

export enum BugcrowdFormOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type BugcrowdFormParent = CCustomComponent;

export type BugcrowdFormParentConnectInput = {
  CCustomComponent?: InputMaybe<CCustomComponentConnectInput>;
};

export type BugcrowdFormParentCreateInput = {
  CCustomComponent?: InputMaybe<CCustomComponentCreateInput>;
};

export type BugcrowdFormParentCreateManyInlineInput = {
  /** Create and connect multiple existing BugcrowdFormParent documents */
  create?: InputMaybe<Array<BugcrowdFormParentCreateInput>>;
};

export type BugcrowdFormParentCreateOneInlineInput = {
  /** Create and connect one BugcrowdFormParent document */
  create?: InputMaybe<BugcrowdFormParentCreateInput>;
};

export type BugcrowdFormParentCreateWithPositionInput = {
  CCustomComponent?: InputMaybe<CCustomComponentCreateWithPositionInput>;
};

export type BugcrowdFormParentUpdateInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpdateInput>;
};

export type BugcrowdFormParentUpdateManyInlineInput = {
  /** Create and connect multiple BugcrowdFormParent component instances */
  create?: InputMaybe<Array<BugcrowdFormParentCreateWithPositionInput>>;
  /** Delete multiple BugcrowdFormParent documents */
  delete?: InputMaybe<Array<BugcrowdFormParentWhereUniqueInput>>;
  /** Update multiple BugcrowdFormParent component instances */
  update?: InputMaybe<
    Array<BugcrowdFormParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple BugcrowdFormParent component instances */
  upsert?: InputMaybe<
    Array<BugcrowdFormParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type BugcrowdFormParentUpdateManyWithNestedWhereInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpdateManyWithNestedWhereInput>;
};

export type BugcrowdFormParentUpdateOneInlineInput = {
  /** Create and connect one BugcrowdFormParent document */
  create?: InputMaybe<BugcrowdFormParentCreateInput>;
  /** Delete currently connected BugcrowdFormParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single BugcrowdFormParent document */
  update?: InputMaybe<BugcrowdFormParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single BugcrowdFormParent document */
  upsert?: InputMaybe<BugcrowdFormParentUpsertWithNestedWhereUniqueInput>;
};

export type BugcrowdFormParentUpdateWithNestedWhereUniqueAndPositionInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type BugcrowdFormParentUpdateWithNestedWhereUniqueInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpdateWithNestedWhereUniqueInput>;
};

export type BugcrowdFormParentUpsertWithNestedWhereUniqueAndPositionInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type BugcrowdFormParentUpsertWithNestedWhereUniqueInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpsertWithNestedWhereUniqueInput>;
};

export type BugcrowdFormParentWhereInput = {
  CCustomComponent?: InputMaybe<CCustomComponentWhereInput>;
};

export type BugcrowdFormParentWhereUniqueInput = {
  CCustomComponent?: InputMaybe<CCustomComponentWhereUniqueInput>;
};

export type BugcrowdFormUpdateInput = {
  /** No fields in update input */
  _?: InputMaybe<Scalars['String']>;
};

export type BugcrowdFormUpdateManyInlineInput = {
  /** Create and connect multiple BugcrowdForm component instances */
  create?: InputMaybe<Array<BugcrowdFormCreateWithPositionInput>>;
  /** Delete multiple BugcrowdForm documents */
  delete?: InputMaybe<Array<BugcrowdFormWhereUniqueInput>>;
  /** Update multiple BugcrowdForm component instances */
  update?: InputMaybe<
    Array<BugcrowdFormUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple BugcrowdForm component instances */
  upsert?: InputMaybe<
    Array<BugcrowdFormUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type BugcrowdFormUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']>;
};

export type BugcrowdFormUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: BugcrowdFormUpdateManyInput;
  /** Document search */
  where: BugcrowdFormWhereInput;
};

export type BugcrowdFormUpdateOneInlineInput = {
  /** Create and connect one BugcrowdForm document */
  create?: InputMaybe<BugcrowdFormCreateInput>;
  /** Delete currently connected BugcrowdForm document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single BugcrowdForm document */
  update?: InputMaybe<BugcrowdFormUpdateWithNestedWhereUniqueInput>;
  /** Upsert single BugcrowdForm document */
  upsert?: InputMaybe<BugcrowdFormUpsertWithNestedWhereUniqueInput>;
};

export type BugcrowdFormUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<BugcrowdFormUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: BugcrowdFormWhereUniqueInput;
};

export type BugcrowdFormUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: BugcrowdFormUpdateInput;
  /** Unique document search */
  where: BugcrowdFormWhereUniqueInput;
};

export type BugcrowdFormUpsertInput = {
  /** Create document if it didn't exist */
  create: BugcrowdFormCreateInput;
  /** Update document if it exists */
  update: BugcrowdFormUpdateInput;
};

export type BugcrowdFormUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<BugcrowdFormUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: BugcrowdFormWhereUniqueInput;
};

export type BugcrowdFormUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: BugcrowdFormUpsertInput;
  /** Unique document search */
  where: BugcrowdFormWhereUniqueInput;
};

/** Identifies documents */
export type BugcrowdFormWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<BugcrowdFormWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<BugcrowdFormWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<BugcrowdFormWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
};

/** References BugcrowdForm record uniquely */
export type BugcrowdFormWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type CActionGroup = Entity & {
  __typename?: 'CActionGroup';
  /** Size of Action Group. */
  actionGroupSize?: Maybe<ActionGroupSize>;
  actions: Array<Union_Actions_Clbvqgt4j01rj01ul45jv8qk0>;
  boxAlignment?: Maybe<BoxJustifyContent>;
  /** Wraps the CTAs in a box and defines its display. */
  display?: Maybe<BoxDisplay>;
  /** The unique identifier */
  id: Scalars['ID'];
  inline?: Maybe<CInline>;
  /** System stage field */
  stage: Stage;
};

export type CActionGroupActionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type CActionGroupInlineArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CActionGroupConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CActionGroupWhereUniqueInput;
};

/** A connection to a list of items. */
export type CActionGroupConnection = {
  __typename?: 'CActionGroupConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CActionGroupEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CActionGroupCreateInput = {
  actionGroupSize?: InputMaybe<ActionGroupSize>;
  actions?: InputMaybe<Union_Actions_Clbvqgt4j01rj01ul45jv8qk0CreateManyInlineInput>;
  boxAlignment?: InputMaybe<BoxJustifyContent>;
  display?: InputMaybe<BoxDisplay>;
  inline?: InputMaybe<CInlineCreateOneInlineInput>;
};

export type CActionGroupCreateManyInlineInput = {
  /** Create and connect multiple existing CActionGroup documents */
  create?: InputMaybe<Array<CActionGroupCreateInput>>;
};

export type CActionGroupCreateOneInlineInput = {
  /** Create and connect one CActionGroup document */
  create?: InputMaybe<CActionGroupCreateInput>;
};

export type CActionGroupCreateWithPositionInput = {
  /** Document to create */
  data: CActionGroupCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type CActionGroupEdge = {
  __typename?: 'CActionGroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CActionGroup;
};

/** Identifies documents */
export type CActionGroupManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CActionGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CActionGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CActionGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  actionGroupSize?: InputMaybe<ActionGroupSize>;
  /** All values that are contained in given list. */
  actionGroupSize_in?: InputMaybe<Array<InputMaybe<ActionGroupSize>>>;
  /** Any other value that exists and is not equal to the given value. */
  actionGroupSize_not?: InputMaybe<ActionGroupSize>;
  /** All values that are not contained in given list. */
  actionGroupSize_not_in?: InputMaybe<Array<InputMaybe<ActionGroupSize>>>;
  /** All values in which the union is empty */
  actions_empty?: InputMaybe<Scalars['Boolean']>;
  /** Matches if the union contains at least one connection to the provided item to the filter */
  actions_some?: InputMaybe<Union_Actions_Clbvqgt4j01rj01ul45jv8qk0WhereInput>;
  boxAlignment?: InputMaybe<BoxJustifyContent>;
  /** All values that are contained in given list. */
  boxAlignment_in?: InputMaybe<Array<InputMaybe<BoxJustifyContent>>>;
  /** Any other value that exists and is not equal to the given value. */
  boxAlignment_not?: InputMaybe<BoxJustifyContent>;
  /** All values that are not contained in given list. */
  boxAlignment_not_in?: InputMaybe<Array<InputMaybe<BoxJustifyContent>>>;
  display?: InputMaybe<BoxDisplay>;
  /** All values that are contained in given list. */
  display_in?: InputMaybe<Array<InputMaybe<BoxDisplay>>>;
  /** Any other value that exists and is not equal to the given value. */
  display_not?: InputMaybe<BoxDisplay>;
  /** All values that are not contained in given list. */
  display_not_in?: InputMaybe<Array<InputMaybe<BoxDisplay>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  inline?: InputMaybe<CInlineWhereInput>;
};

export enum CActionGroupOrderByInput {
  ActionGroupSizeAsc = 'actionGroupSize_ASC',
  ActionGroupSizeDesc = 'actionGroupSize_DESC',
  BoxAlignmentAsc = 'boxAlignment_ASC',
  BoxAlignmentDesc = 'boxAlignment_DESC',
  DisplayAsc = 'display_ASC',
  DisplayDesc = 'display_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type CActionGroupParent =
  | BannerItem
  | CBannerAward
  | CBannerInline
  | CBannerPrimary
  | CBlock
  | CCardJobAd
  | CCardPrimary
  | Divider;

export type CActionGroupParentConnectInput = {
  BannerItem?: InputMaybe<BannerItemConnectInput>;
  CBannerAward?: InputMaybe<CBannerAwardConnectInput>;
  CBannerInline?: InputMaybe<CBannerInlineConnectInput>;
  CBannerPrimary?: InputMaybe<CBannerPrimaryConnectInput>;
  CBlock?: InputMaybe<CBlockConnectInput>;
  CCardJobAd?: InputMaybe<CCardJobAdConnectInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryConnectInput>;
  Divider?: InputMaybe<DividerConnectInput>;
};

export type CActionGroupParentCreateInput = {
  BannerItem?: InputMaybe<BannerItemCreateInput>;
  CBannerAward?: InputMaybe<CBannerAwardCreateInput>;
  CBannerInline?: InputMaybe<CBannerInlineCreateInput>;
  CBannerPrimary?: InputMaybe<CBannerPrimaryCreateInput>;
  CBlock?: InputMaybe<CBlockCreateInput>;
  CCardJobAd?: InputMaybe<CCardJobAdCreateInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryCreateInput>;
  Divider?: InputMaybe<DividerCreateInput>;
};

export type CActionGroupParentCreateManyInlineInput = {
  /** Connect multiple existing CActionGroupParent documents */
  connect?: InputMaybe<Array<CActionGroupParentWhereUniqueInput>>;
  /** Create and connect multiple existing CActionGroupParent documents */
  create?: InputMaybe<Array<CActionGroupParentCreateInput>>;
};

export type CActionGroupParentCreateOneInlineInput = {
  /** Connect one existing CActionGroupParent document */
  connect?: InputMaybe<CActionGroupParentWhereUniqueInput>;
  /** Create and connect one CActionGroupParent document */
  create?: InputMaybe<CActionGroupParentCreateInput>;
};

export type CActionGroupParentUpdateInput = {
  BannerItem?: InputMaybe<BannerItemUpdateInput>;
  CBannerAward?: InputMaybe<CBannerAwardUpdateInput>;
  CBannerInline?: InputMaybe<CBannerInlineUpdateInput>;
  CBannerPrimary?: InputMaybe<CBannerPrimaryUpdateInput>;
  CBlock?: InputMaybe<CBlockUpdateInput>;
  CCardJobAd?: InputMaybe<CCardJobAdUpdateInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryUpdateInput>;
  Divider?: InputMaybe<DividerUpdateInput>;
};

export type CActionGroupParentUpdateManyInlineInput = {
  /** Connect multiple existing CActionGroupParent documents */
  connect?: InputMaybe<Array<CActionGroupParentConnectInput>>;
  /** Create and connect multiple CActionGroupParent documents */
  create?: InputMaybe<Array<CActionGroupParentCreateInput>>;
  /** Delete multiple CActionGroupParent documents */
  delete?: InputMaybe<Array<CActionGroupParentWhereUniqueInput>>;
  /** Disconnect multiple CActionGroupParent documents */
  disconnect?: InputMaybe<Array<CActionGroupParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing CActionGroupParent documents */
  set?: InputMaybe<Array<CActionGroupParentWhereUniqueInput>>;
  /** Update multiple CActionGroupParent documents */
  update?: InputMaybe<
    Array<CActionGroupParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple CActionGroupParent documents */
  upsert?: InputMaybe<
    Array<CActionGroupParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type CActionGroupParentUpdateManyWithNestedWhereInput = {
  BannerItem?: InputMaybe<BannerItemUpdateManyWithNestedWhereInput>;
  CBannerAward?: InputMaybe<CBannerAwardUpdateManyWithNestedWhereInput>;
  CBannerInline?: InputMaybe<CBannerInlineUpdateManyWithNestedWhereInput>;
  CBannerPrimary?: InputMaybe<CBannerPrimaryUpdateManyWithNestedWhereInput>;
  CBlock?: InputMaybe<CBlockUpdateManyWithNestedWhereInput>;
  CCardJobAd?: InputMaybe<CCardJobAdUpdateManyWithNestedWhereInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryUpdateManyWithNestedWhereInput>;
  Divider?: InputMaybe<DividerUpdateManyWithNestedWhereInput>;
};

export type CActionGroupParentUpdateOneInlineInput = {
  /** Connect existing CActionGroupParent document */
  connect?: InputMaybe<CActionGroupParentWhereUniqueInput>;
  /** Create and connect one CActionGroupParent document */
  create?: InputMaybe<CActionGroupParentCreateInput>;
  /** Delete currently connected CActionGroupParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected CActionGroupParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single CActionGroupParent document */
  update?: InputMaybe<CActionGroupParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CActionGroupParent document */
  upsert?: InputMaybe<CActionGroupParentUpsertWithNestedWhereUniqueInput>;
};

export type CActionGroupParentUpdateWithNestedWhereUniqueInput = {
  BannerItem?: InputMaybe<BannerItemUpdateWithNestedWhereUniqueInput>;
  CBannerAward?: InputMaybe<CBannerAwardUpdateWithNestedWhereUniqueInput>;
  CBannerInline?: InputMaybe<CBannerInlineUpdateWithNestedWhereUniqueInput>;
  CBannerPrimary?: InputMaybe<CBannerPrimaryUpdateWithNestedWhereUniqueInput>;
  CBlock?: InputMaybe<CBlockUpdateWithNestedWhereUniqueInput>;
  CCardJobAd?: InputMaybe<CCardJobAdUpdateWithNestedWhereUniqueInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryUpdateWithNestedWhereUniqueInput>;
  Divider?: InputMaybe<DividerUpdateWithNestedWhereUniqueInput>;
};

export type CActionGroupParentUpsertWithNestedWhereUniqueInput = {
  BannerItem?: InputMaybe<BannerItemUpsertWithNestedWhereUniqueInput>;
  CBannerAward?: InputMaybe<CBannerAwardUpsertWithNestedWhereUniqueInput>;
  CBannerInline?: InputMaybe<CBannerInlineUpsertWithNestedWhereUniqueInput>;
  CBannerPrimary?: InputMaybe<CBannerPrimaryUpsertWithNestedWhereUniqueInput>;
  CBlock?: InputMaybe<CBlockUpsertWithNestedWhereUniqueInput>;
  CCardJobAd?: InputMaybe<CCardJobAdUpsertWithNestedWhereUniqueInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryUpsertWithNestedWhereUniqueInput>;
  Divider?: InputMaybe<DividerUpsertWithNestedWhereUniqueInput>;
};

export type CActionGroupParentWhereInput = {
  BannerItem?: InputMaybe<BannerItemWhereInput>;
  CBannerAward?: InputMaybe<CBannerAwardWhereInput>;
  CBannerInline?: InputMaybe<CBannerInlineWhereInput>;
  CBannerPrimary?: InputMaybe<CBannerPrimaryWhereInput>;
  CBlock?: InputMaybe<CBlockWhereInput>;
  CCardJobAd?: InputMaybe<CCardJobAdWhereInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryWhereInput>;
  Divider?: InputMaybe<DividerWhereInput>;
};

export type CActionGroupParentWhereUniqueInput = {
  BannerItem?: InputMaybe<BannerItemWhereUniqueInput>;
  CBannerAward?: InputMaybe<CBannerAwardWhereUniqueInput>;
  CBannerInline?: InputMaybe<CBannerInlineWhereUniqueInput>;
  CBannerPrimary?: InputMaybe<CBannerPrimaryWhereUniqueInput>;
  CBlock?: InputMaybe<CBlockWhereUniqueInput>;
  CCardJobAd?: InputMaybe<CCardJobAdWhereUniqueInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryWhereUniqueInput>;
  Divider?: InputMaybe<DividerWhereUniqueInput>;
};

export type CActionGroupUpdateInput = {
  actionGroupSize?: InputMaybe<ActionGroupSize>;
  actions?: InputMaybe<Union_Actions_Clbvqgt4j01rj01ul45jv8qk0UpdateManyInlineInput>;
  boxAlignment?: InputMaybe<BoxJustifyContent>;
  display?: InputMaybe<BoxDisplay>;
  inline?: InputMaybe<CInlineUpdateOneInlineInput>;
};

export type CActionGroupUpdateManyInlineInput = {
  /** Create and connect multiple CActionGroup component instances */
  create?: InputMaybe<Array<CActionGroupCreateWithPositionInput>>;
  /** Delete multiple CActionGroup documents */
  delete?: InputMaybe<Array<CActionGroupWhereUniqueInput>>;
  /** Update multiple CActionGroup component instances */
  update?: InputMaybe<
    Array<CActionGroupUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CActionGroup component instances */
  upsert?: InputMaybe<
    Array<CActionGroupUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CActionGroupUpdateManyInput = {
  actionGroupSize?: InputMaybe<ActionGroupSize>;
  boxAlignment?: InputMaybe<BoxJustifyContent>;
  display?: InputMaybe<BoxDisplay>;
};

export type CActionGroupUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CActionGroupUpdateManyInput;
  /** Document search */
  where: CActionGroupWhereInput;
};

export type CActionGroupUpdateOneInlineInput = {
  /** Create and connect one CActionGroup document */
  create?: InputMaybe<CActionGroupCreateInput>;
  /** Delete currently connected CActionGroup document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CActionGroup document */
  update?: InputMaybe<CActionGroupUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CActionGroup document */
  upsert?: InputMaybe<CActionGroupUpsertWithNestedWhereUniqueInput>;
};

export type CActionGroupUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<CActionGroupUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CActionGroupWhereUniqueInput;
};

export type CActionGroupUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CActionGroupUpdateInput;
  /** Unique document search */
  where: CActionGroupWhereUniqueInput;
};

export type CActionGroupUpsertInput = {
  /** Create document if it didn't exist */
  create: CActionGroupCreateInput;
  /** Update document if it exists */
  update: CActionGroupUpdateInput;
};

export type CActionGroupUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<CActionGroupUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CActionGroupWhereUniqueInput;
};

export type CActionGroupUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CActionGroupUpsertInput;
  /** Unique document search */
  where: CActionGroupWhereUniqueInput;
};

/** Identifies documents */
export type CActionGroupWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CActionGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CActionGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CActionGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  actionGroupSize?: InputMaybe<ActionGroupSize>;
  /** All values that are contained in given list. */
  actionGroupSize_in?: InputMaybe<Array<InputMaybe<ActionGroupSize>>>;
  /** Any other value that exists and is not equal to the given value. */
  actionGroupSize_not?: InputMaybe<ActionGroupSize>;
  /** All values that are not contained in given list. */
  actionGroupSize_not_in?: InputMaybe<Array<InputMaybe<ActionGroupSize>>>;
  /** All values in which the union is empty */
  actions_empty?: InputMaybe<Scalars['Boolean']>;
  /** Matches if the union contains at least one connection to the provided item to the filter */
  actions_some?: InputMaybe<Union_Actions_Clbvqgt4j01rj01ul45jv8qk0WhereInput>;
  boxAlignment?: InputMaybe<BoxJustifyContent>;
  /** All values that are contained in given list. */
  boxAlignment_in?: InputMaybe<Array<InputMaybe<BoxJustifyContent>>>;
  /** Any other value that exists and is not equal to the given value. */
  boxAlignment_not?: InputMaybe<BoxJustifyContent>;
  /** All values that are not contained in given list. */
  boxAlignment_not_in?: InputMaybe<Array<InputMaybe<BoxJustifyContent>>>;
  display?: InputMaybe<BoxDisplay>;
  /** All values that are contained in given list. */
  display_in?: InputMaybe<Array<InputMaybe<BoxDisplay>>>;
  /** Any other value that exists and is not equal to the given value. */
  display_not?: InputMaybe<BoxDisplay>;
  /** All values that are not contained in given list. */
  display_not_in?: InputMaybe<Array<InputMaybe<BoxDisplay>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  inline?: InputMaybe<CInlineWhereInput>;
};

/** References CActionGroup record uniquely */
export type CActionGroupWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Alert component */
export type CAlert = Entity & {
  __typename?: 'CAlert';
  /** The unique identifier */
  id: Scalars['ID'];
  paragraph: CParagraph;
  /** System stage field */
  stage: Stage;
  tone: CAlertTone;
};

/** Alert component */
export type CAlertParagraphArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CAlertConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CAlertWhereUniqueInput;
};

/** A connection to a list of items. */
export type CAlertConnection = {
  __typename?: 'CAlertConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CAlertEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CAlertCreateInput = {
  paragraph: CParagraphCreateOneInlineInput;
  tone: CAlertTone;
};

export type CAlertCreateManyInlineInput = {
  /** Create and connect multiple existing CAlert documents */
  create?: InputMaybe<Array<CAlertCreateInput>>;
};

export type CAlertCreateOneInlineInput = {
  /** Create and connect one CAlert document */
  create?: InputMaybe<CAlertCreateInput>;
};

export type CAlertCreateWithPositionInput = {
  /** Document to create */
  data: CAlertCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type CAlertEdge = {
  __typename?: 'CAlertEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CAlert;
};

/** Identifies documents */
export type CAlertManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CAlertWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CAlertWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CAlertWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  paragraph?: InputMaybe<CParagraphWhereInput>;
  tone?: InputMaybe<CAlertTone>;
  /** All values that are contained in given list. */
  tone_in?: InputMaybe<Array<InputMaybe<CAlertTone>>>;
  /** Any other value that exists and is not equal to the given value. */
  tone_not?: InputMaybe<CAlertTone>;
  /** All values that are not contained in given list. */
  tone_not_in?: InputMaybe<Array<InputMaybe<CAlertTone>>>;
};

export enum CAlertOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ToneAsc = 'tone_ASC',
  ToneDesc = 'tone_DESC',
}

export type CAlertParent = CBlock;

export type CAlertParentConnectInput = {
  CBlock?: InputMaybe<CBlockConnectInput>;
};

export type CAlertParentCreateInput = {
  CBlock?: InputMaybe<CBlockCreateInput>;
};

export type CAlertParentCreateManyInlineInput = {
  /** Connect multiple existing CAlertParent documents */
  connect?: InputMaybe<Array<CAlertParentWhereUniqueInput>>;
  /** Create and connect multiple existing CAlertParent documents */
  create?: InputMaybe<Array<CAlertParentCreateInput>>;
};

export type CAlertParentCreateOneInlineInput = {
  /** Connect one existing CAlertParent document */
  connect?: InputMaybe<CAlertParentWhereUniqueInput>;
  /** Create and connect one CAlertParent document */
  create?: InputMaybe<CAlertParentCreateInput>;
};

export type CAlertParentUpdateInput = {
  CBlock?: InputMaybe<CBlockUpdateInput>;
};

export type CAlertParentUpdateManyInlineInput = {
  /** Connect multiple existing CAlertParent documents */
  connect?: InputMaybe<Array<CAlertParentConnectInput>>;
  /** Create and connect multiple CAlertParent documents */
  create?: InputMaybe<Array<CAlertParentCreateInput>>;
  /** Delete multiple CAlertParent documents */
  delete?: InputMaybe<Array<CAlertParentWhereUniqueInput>>;
  /** Disconnect multiple CAlertParent documents */
  disconnect?: InputMaybe<Array<CAlertParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing CAlertParent documents */
  set?: InputMaybe<Array<CAlertParentWhereUniqueInput>>;
  /** Update multiple CAlertParent documents */
  update?: InputMaybe<Array<CAlertParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple CAlertParent documents */
  upsert?: InputMaybe<Array<CAlertParentUpsertWithNestedWhereUniqueInput>>;
};

export type CAlertParentUpdateManyWithNestedWhereInput = {
  CBlock?: InputMaybe<CBlockUpdateManyWithNestedWhereInput>;
};

export type CAlertParentUpdateOneInlineInput = {
  /** Connect existing CAlertParent document */
  connect?: InputMaybe<CAlertParentWhereUniqueInput>;
  /** Create and connect one CAlertParent document */
  create?: InputMaybe<CAlertParentCreateInput>;
  /** Delete currently connected CAlertParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected CAlertParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single CAlertParent document */
  update?: InputMaybe<CAlertParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CAlertParent document */
  upsert?: InputMaybe<CAlertParentUpsertWithNestedWhereUniqueInput>;
};

export type CAlertParentUpdateWithNestedWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockUpdateWithNestedWhereUniqueInput>;
};

export type CAlertParentUpsertWithNestedWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockUpsertWithNestedWhereUniqueInput>;
};

export type CAlertParentWhereInput = {
  CBlock?: InputMaybe<CBlockWhereInput>;
};

export type CAlertParentWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockWhereUniqueInput>;
};

/** Represents C_Alert Tone value. */
export enum CAlertTone {
  Caution = 'caution',
  Critical = 'critical',
  Info = 'info',
  Positive = 'positive',
  Promote = 'promote',
}

export type CAlertUpdateInput = {
  paragraph?: InputMaybe<CParagraphUpdateOneInlineInput>;
  tone?: InputMaybe<CAlertTone>;
};

export type CAlertUpdateManyInlineInput = {
  /** Create and connect multiple CAlert component instances */
  create?: InputMaybe<Array<CAlertCreateWithPositionInput>>;
  /** Delete multiple CAlert documents */
  delete?: InputMaybe<Array<CAlertWhereUniqueInput>>;
  /** Update multiple CAlert component instances */
  update?: InputMaybe<Array<CAlertUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple CAlert component instances */
  upsert?: InputMaybe<Array<CAlertUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type CAlertUpdateManyInput = {
  tone?: InputMaybe<CAlertTone>;
};

export type CAlertUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CAlertUpdateManyInput;
  /** Document search */
  where: CAlertWhereInput;
};

export type CAlertUpdateOneInlineInput = {
  /** Create and connect one CAlert document */
  create?: InputMaybe<CAlertCreateInput>;
  /** Delete currently connected CAlert document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CAlert document */
  update?: InputMaybe<CAlertUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CAlert document */
  upsert?: InputMaybe<CAlertUpsertWithNestedWhereUniqueInput>;
};

export type CAlertUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<CAlertUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CAlertWhereUniqueInput;
};

export type CAlertUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CAlertUpdateInput;
  /** Unique document search */
  where: CAlertWhereUniqueInput;
};

export type CAlertUpsertInput = {
  /** Create document if it didn't exist */
  create: CAlertCreateInput;
  /** Update document if it exists */
  update: CAlertUpdateInput;
};

export type CAlertUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<CAlertUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CAlertWhereUniqueInput;
};

export type CAlertUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CAlertUpsertInput;
  /** Unique document search */
  where: CAlertWhereUniqueInput;
};

/** Identifies documents */
export type CAlertWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CAlertWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CAlertWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CAlertWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  paragraph?: InputMaybe<CParagraphWhereInput>;
  tone?: InputMaybe<CAlertTone>;
  /** All values that are contained in given list. */
  tone_in?: InputMaybe<Array<InputMaybe<CAlertTone>>>;
  /** Any other value that exists and is not equal to the given value. */
  tone_not?: InputMaybe<CAlertTone>;
  /** All values that are not contained in given list. */
  tone_not_in?: InputMaybe<Array<InputMaybe<CAlertTone>>>;
};

/** References CAlert record uniquely */
export type CAlertWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type CBanner = Entity & {
  __typename?: 'CBanner';
  banner_items?: Maybe<CBannerbanner_ItemsUnion>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System stage field */
  stage: Stage;
};

export type CBannerBanner_ItemsArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CBannerArticle = Entity & {
  __typename?: 'CBannerArticle';
  article?: Maybe<Article>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<CBannerArticle>;
  name?: Maybe<Scalars['String']>;
  /** System stage field */
  stage: Stage;
};

export type CBannerArticleArticleArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CBannerArticleLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type CBannerArticleConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CBannerArticleWhereUniqueInput;
};

/** A connection to a list of items. */
export type CBannerArticleConnection = {
  __typename?: 'CBannerArticleConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CBannerArticleEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CBannerArticleCreateInput = {
  article?: InputMaybe<ArticleCreateOneInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<CBannerArticleCreateLocalizationsInput>;
  /** name input for default locale (default) */
  name?: InputMaybe<Scalars['String']>;
};

export type CBannerArticleCreateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type CBannerArticleCreateLocalizationInput = {
  /** Localization input */
  data: CBannerArticleCreateLocalizationDataInput;
  locale: Locale;
};

export type CBannerArticleCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<CBannerArticleCreateLocalizationInput>>;
};

export type CBannerArticleCreateManyInlineInput = {
  /** Create and connect multiple existing CBannerArticle documents */
  create?: InputMaybe<Array<CBannerArticleCreateInput>>;
};

export type CBannerArticleCreateOneInlineInput = {
  /** Create and connect one CBannerArticle document */
  create?: InputMaybe<CBannerArticleCreateInput>;
};

export type CBannerArticleCreateWithPositionInput = {
  /** Document to create */
  data: CBannerArticleCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type CBannerArticleEdge = {
  __typename?: 'CBannerArticleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CBannerArticle;
};

/** Identifies documents */
export type CBannerArticleManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CBannerArticleWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CBannerArticleWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CBannerArticleWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  article?: InputMaybe<ArticleWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
};

export enum CBannerArticleOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
}

export type CBannerArticleParent = CBanner;

export type CBannerArticleParentConnectInput = {
  CBanner?: InputMaybe<CBannerConnectInput>;
};

export type CBannerArticleParentCreateInput = {
  CBanner?: InputMaybe<CBannerCreateInput>;
};

export type CBannerArticleParentCreateManyInlineInput = {
  /** Create and connect multiple existing CBannerArticleParent documents */
  create?: InputMaybe<Array<CBannerArticleParentCreateInput>>;
};

export type CBannerArticleParentCreateOneInlineInput = {
  /** Create and connect one CBannerArticleParent document */
  create?: InputMaybe<CBannerArticleParentCreateInput>;
};

export type CBannerArticleParentCreateWithPositionInput = {
  CBanner?: InputMaybe<CBannerCreateWithPositionInput>;
};

export type CBannerArticleParentUpdateInput = {
  CBanner?: InputMaybe<CBannerUpdateInput>;
};

export type CBannerArticleParentUpdateManyInlineInput = {
  /** Create and connect multiple CBannerArticleParent component instances */
  create?: InputMaybe<Array<CBannerArticleParentCreateWithPositionInput>>;
  /** Delete multiple CBannerArticleParent documents */
  delete?: InputMaybe<Array<CBannerArticleParentWhereUniqueInput>>;
  /** Update multiple CBannerArticleParent component instances */
  update?: InputMaybe<
    Array<CBannerArticleParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CBannerArticleParent component instances */
  upsert?: InputMaybe<
    Array<CBannerArticleParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CBannerArticleParentUpdateManyWithNestedWhereInput = {
  CBanner?: InputMaybe<CBannerUpdateManyWithNestedWhereInput>;
};

export type CBannerArticleParentUpdateOneInlineInput = {
  /** Create and connect one CBannerArticleParent document */
  create?: InputMaybe<CBannerArticleParentCreateInput>;
  /** Delete currently connected CBannerArticleParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CBannerArticleParent document */
  update?: InputMaybe<CBannerArticleParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CBannerArticleParent document */
  upsert?: InputMaybe<CBannerArticleParentUpsertWithNestedWhereUniqueInput>;
};

export type CBannerArticleParentUpdateWithNestedWhereUniqueAndPositionInput = {
  CBanner?: InputMaybe<CBannerUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type CBannerArticleParentUpdateWithNestedWhereUniqueInput = {
  CBanner?: InputMaybe<CBannerUpdateWithNestedWhereUniqueInput>;
};

export type CBannerArticleParentUpsertWithNestedWhereUniqueAndPositionInput = {
  CBanner?: InputMaybe<CBannerUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type CBannerArticleParentUpsertWithNestedWhereUniqueInput = {
  CBanner?: InputMaybe<CBannerUpsertWithNestedWhereUniqueInput>;
};

export type CBannerArticleParentWhereInput = {
  CBanner?: InputMaybe<CBannerWhereInput>;
};

export type CBannerArticleParentWhereUniqueInput = {
  CBanner?: InputMaybe<CBannerWhereUniqueInput>;
};

export type CBannerArticleUpdateInput = {
  article?: InputMaybe<ArticleUpdateOneInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<CBannerArticleUpdateLocalizationsInput>;
  /** name input for default locale (default) */
  name?: InputMaybe<Scalars['String']>;
};

export type CBannerArticleUpdateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type CBannerArticleUpdateLocalizationInput = {
  data: CBannerArticleUpdateLocalizationDataInput;
  locale: Locale;
};

export type CBannerArticleUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<CBannerArticleCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<CBannerArticleUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<CBannerArticleUpsertLocalizationInput>>;
};

export type CBannerArticleUpdateManyInlineInput = {
  /** Create and connect multiple CBannerArticle component instances */
  create?: InputMaybe<Array<CBannerArticleCreateWithPositionInput>>;
  /** Delete multiple CBannerArticle documents */
  delete?: InputMaybe<Array<CBannerArticleWhereUniqueInput>>;
  /** Update multiple CBannerArticle component instances */
  update?: InputMaybe<
    Array<CBannerArticleUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CBannerArticle component instances */
  upsert?: InputMaybe<
    Array<CBannerArticleUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CBannerArticleUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<CBannerArticleUpdateManyLocalizationsInput>;
  /** name input for default locale (default) */
  name?: InputMaybe<Scalars['String']>;
};

export type CBannerArticleUpdateManyLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type CBannerArticleUpdateManyLocalizationInput = {
  data: CBannerArticleUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type CBannerArticleUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<CBannerArticleUpdateManyLocalizationInput>>;
};

export type CBannerArticleUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CBannerArticleUpdateManyInput;
  /** Document search */
  where: CBannerArticleWhereInput;
};

export type CBannerArticleUpdateOneInlineInput = {
  /** Create and connect one CBannerArticle document */
  create?: InputMaybe<CBannerArticleCreateInput>;
  /** Delete currently connected CBannerArticle document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CBannerArticle document */
  update?: InputMaybe<CBannerArticleUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CBannerArticle document */
  upsert?: InputMaybe<CBannerArticleUpsertWithNestedWhereUniqueInput>;
};

export type CBannerArticleUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<CBannerArticleUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CBannerArticleWhereUniqueInput;
};

export type CBannerArticleUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CBannerArticleUpdateInput;
  /** Unique document search */
  where: CBannerArticleWhereUniqueInput;
};

export type CBannerArticleUpsertInput = {
  /** Create document if it didn't exist */
  create: CBannerArticleCreateInput;
  /** Update document if it exists */
  update: CBannerArticleUpdateInput;
};

export type CBannerArticleUpsertLocalizationInput = {
  create: CBannerArticleCreateLocalizationDataInput;
  locale: Locale;
  update: CBannerArticleUpdateLocalizationDataInput;
};

export type CBannerArticleUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<CBannerArticleUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CBannerArticleWhereUniqueInput;
};

export type CBannerArticleUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CBannerArticleUpsertInput;
  /** Unique document search */
  where: CBannerArticleWhereUniqueInput;
};

/** Identifies documents */
export type CBannerArticleWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CBannerArticleWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CBannerArticleWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CBannerArticleWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  article?: InputMaybe<ArticleWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']>;
};

/** References CBannerArticle record uniquely */
export type CBannerArticleWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Banner for the Award sites (SARA & STAR) */
export type CBannerAward = Entity & {
  __typename?: 'CBannerAward';
  actionGroup?: Maybe<CActionGroup>;
  backgroundImage?: Maybe<Asset>;
  heading?: Maybe<CHeading>;
  /** The unique identifier */
  id: Scalars['ID'];
  paragraph?: Maybe<CParagraph>;
  /** System stage field */
  stage: Stage;
  video?: Maybe<Video>;
};

/** Banner for the Award sites (SARA & STAR) */
export type CBannerAwardActionGroupArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Banner for the Award sites (SARA & STAR) */
export type CBannerAwardBackgroundImageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
  where?: InputMaybe<AssetSingleRelationWhereInput>;
};

/** Banner for the Award sites (SARA & STAR) */
export type CBannerAwardHeadingArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Banner for the Award sites (SARA & STAR) */
export type CBannerAwardParagraphArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Banner for the Award sites (SARA & STAR) */
export type CBannerAwardVideoArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CBannerAwardConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CBannerAwardWhereUniqueInput;
};

/** A connection to a list of items. */
export type CBannerAwardConnection = {
  __typename?: 'CBannerAwardConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CBannerAwardEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CBannerAwardCreateInput = {
  actionGroup?: InputMaybe<CActionGroupCreateOneInlineInput>;
  backgroundImage?: InputMaybe<AssetCreateOneInlineInput>;
  heading?: InputMaybe<CHeadingCreateOneInlineInput>;
  paragraph?: InputMaybe<CParagraphCreateOneInlineInput>;
  video?: InputMaybe<VideoCreateOneInlineInput>;
};

export type CBannerAwardCreateManyInlineInput = {
  /** Create and connect multiple existing CBannerAward documents */
  create?: InputMaybe<Array<CBannerAwardCreateInput>>;
};

export type CBannerAwardCreateOneInlineInput = {
  /** Create and connect one CBannerAward document */
  create?: InputMaybe<CBannerAwardCreateInput>;
};

export type CBannerAwardCreateWithPositionInput = {
  /** Document to create */
  data: CBannerAwardCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type CBannerAwardEdge = {
  __typename?: 'CBannerAwardEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CBannerAward;
};

/** Identifies documents */
export type CBannerAwardManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CBannerAwardWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CBannerAwardWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CBannerAwardWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  actionGroup?: InputMaybe<CActionGroupWhereInput>;
  backgroundImage?: InputMaybe<AssetWhereInput>;
  heading?: InputMaybe<CHeadingWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  paragraph?: InputMaybe<CParagraphWhereInput>;
  video?: InputMaybe<VideoWhereInput>;
};

export enum CBannerAwardOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type CBannerAwardParent = Page;

export type CBannerAwardParentConnectInput = {
  Page?: InputMaybe<PageConnectInput>;
};

export type CBannerAwardParentCreateInput = {
  Page?: InputMaybe<PageCreateInput>;
};

export type CBannerAwardParentCreateManyInlineInput = {
  /** Connect multiple existing CBannerAwardParent documents */
  connect?: InputMaybe<Array<CBannerAwardParentWhereUniqueInput>>;
  /** Create and connect multiple existing CBannerAwardParent documents */
  create?: InputMaybe<Array<CBannerAwardParentCreateInput>>;
};

export type CBannerAwardParentCreateOneInlineInput = {
  /** Connect one existing CBannerAwardParent document */
  connect?: InputMaybe<CBannerAwardParentWhereUniqueInput>;
  /** Create and connect one CBannerAwardParent document */
  create?: InputMaybe<CBannerAwardParentCreateInput>;
};

export type CBannerAwardParentUpdateInput = {
  Page?: InputMaybe<PageUpdateInput>;
};

export type CBannerAwardParentUpdateManyInlineInput = {
  /** Connect multiple existing CBannerAwardParent documents */
  connect?: InputMaybe<Array<CBannerAwardParentConnectInput>>;
  /** Create and connect multiple CBannerAwardParent documents */
  create?: InputMaybe<Array<CBannerAwardParentCreateInput>>;
  /** Delete multiple CBannerAwardParent documents */
  delete?: InputMaybe<Array<CBannerAwardParentWhereUniqueInput>>;
  /** Disconnect multiple CBannerAwardParent documents */
  disconnect?: InputMaybe<Array<CBannerAwardParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing CBannerAwardParent documents */
  set?: InputMaybe<Array<CBannerAwardParentWhereUniqueInput>>;
  /** Update multiple CBannerAwardParent documents */
  update?: InputMaybe<
    Array<CBannerAwardParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple CBannerAwardParent documents */
  upsert?: InputMaybe<
    Array<CBannerAwardParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type CBannerAwardParentUpdateManyWithNestedWhereInput = {
  Page?: InputMaybe<PageUpdateManyWithNestedWhereInput>;
};

export type CBannerAwardParentUpdateOneInlineInput = {
  /** Connect existing CBannerAwardParent document */
  connect?: InputMaybe<CBannerAwardParentWhereUniqueInput>;
  /** Create and connect one CBannerAwardParent document */
  create?: InputMaybe<CBannerAwardParentCreateInput>;
  /** Delete currently connected CBannerAwardParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected CBannerAwardParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single CBannerAwardParent document */
  update?: InputMaybe<CBannerAwardParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CBannerAwardParent document */
  upsert?: InputMaybe<CBannerAwardParentUpsertWithNestedWhereUniqueInput>;
};

export type CBannerAwardParentUpdateWithNestedWhereUniqueInput = {
  Page?: InputMaybe<PageUpdateWithNestedWhereUniqueInput>;
};

export type CBannerAwardParentUpsertWithNestedWhereUniqueInput = {
  Page?: InputMaybe<PageUpsertWithNestedWhereUniqueInput>;
};

export type CBannerAwardParentWhereInput = {
  Page?: InputMaybe<PageWhereInput>;
};

export type CBannerAwardParentWhereUniqueInput = {
  Page?: InputMaybe<PageWhereUniqueInput>;
};

export type CBannerAwardUpdateInput = {
  actionGroup?: InputMaybe<CActionGroupUpdateOneInlineInput>;
  backgroundImage?: InputMaybe<AssetUpdateOneInlineInput>;
  heading?: InputMaybe<CHeadingUpdateOneInlineInput>;
  paragraph?: InputMaybe<CParagraphUpdateOneInlineInput>;
  video?: InputMaybe<VideoUpdateOneInlineInput>;
};

export type CBannerAwardUpdateManyInlineInput = {
  /** Create and connect multiple CBannerAward component instances */
  create?: InputMaybe<Array<CBannerAwardCreateWithPositionInput>>;
  /** Delete multiple CBannerAward documents */
  delete?: InputMaybe<Array<CBannerAwardWhereUniqueInput>>;
  /** Update multiple CBannerAward component instances */
  update?: InputMaybe<
    Array<CBannerAwardUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CBannerAward component instances */
  upsert?: InputMaybe<
    Array<CBannerAwardUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CBannerAwardUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']>;
};

export type CBannerAwardUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CBannerAwardUpdateManyInput;
  /** Document search */
  where: CBannerAwardWhereInput;
};

export type CBannerAwardUpdateOneInlineInput = {
  /** Create and connect one CBannerAward document */
  create?: InputMaybe<CBannerAwardCreateInput>;
  /** Delete currently connected CBannerAward document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CBannerAward document */
  update?: InputMaybe<CBannerAwardUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CBannerAward document */
  upsert?: InputMaybe<CBannerAwardUpsertWithNestedWhereUniqueInput>;
};

export type CBannerAwardUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<CBannerAwardUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CBannerAwardWhereUniqueInput;
};

export type CBannerAwardUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CBannerAwardUpdateInput;
  /** Unique document search */
  where: CBannerAwardWhereUniqueInput;
};

export type CBannerAwardUpsertInput = {
  /** Create document if it didn't exist */
  create: CBannerAwardCreateInput;
  /** Update document if it exists */
  update: CBannerAwardUpdateInput;
};

export type CBannerAwardUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<CBannerAwardUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CBannerAwardWhereUniqueInput;
};

export type CBannerAwardUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CBannerAwardUpsertInput;
  /** Unique document search */
  where: CBannerAwardWhereUniqueInput;
};

/** Identifies documents */
export type CBannerAwardWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CBannerAwardWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CBannerAwardWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CBannerAwardWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  actionGroup?: InputMaybe<CActionGroupWhereInput>;
  backgroundImage?: InputMaybe<AssetWhereInput>;
  heading?: InputMaybe<CHeadingWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  paragraph?: InputMaybe<CParagraphWhereInput>;
  video?: InputMaybe<VideoWhereInput>;
};

/** References CBannerAward record uniquely */
export type CBannerAwardWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type CBannerConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CBannerWhereUniqueInput;
};

/** A connection to a list of items. */
export type CBannerConnection = {
  __typename?: 'CBannerConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CBannerEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CBannerCreateInput = {
  banner_items?: InputMaybe<CBannerbanner_ItemsUnionCreateOneInlineInput>;
};

export type CBannerCreateManyInlineInput = {
  /** Create and connect multiple existing CBanner documents */
  create?: InputMaybe<Array<CBannerCreateInput>>;
};

export type CBannerCreateOneInlineInput = {
  /** Create and connect one CBanner document */
  create?: InputMaybe<CBannerCreateInput>;
};

export type CBannerCreateWithPositionInput = {
  /** Document to create */
  data: CBannerCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type CBannerEdge = {
  __typename?: 'CBannerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CBanner;
};

export type CBannerInline = Entity & {
  __typename?: 'CBannerInline';
  actionGroup?: Maybe<CActionGroup>;
  /** Badge component for the C_Banner Inline. */
  badge?: Maybe<Badge>;
  /** Select Custom Component to be rendered instead of normal heading and paragraph */
  customComponentRenderer?: Maybe<CustomComponent>;
  heading?: Maybe<CHeading>;
  /** The unique identifier */
  id: Scalars['ID'];
  image?: Maybe<Asset>;
  /** Choose how rounded the box border should be. */
  imageBorderRadius?: Maybe<BorderRadius>;
  /** Hidden Image below window size */
  imageHiddenBelow?: Maybe<HiddenBelow>;
  /** Divider component similar to the one in Primary Banner */
  inlineDivider?: Maybe<InlineDivider>;
  paragraph?: Maybe<CParagraph>;
  /** Reverses the image and content order on desktop. */
  reverse?: Maybe<Scalars['Boolean']>;
  /** Reverse the image and content positioning on desktop, mobile or both. */
  reverseContent?: Maybe<ReverseContent>;
  /** System stage field */
  stage: Stage;
  /** Video replaces the image asset */
  video?: Maybe<Video>;
};

export type CBannerInlineActionGroupArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CBannerInlineBadgeArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CBannerInlineHeadingArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CBannerInlineImageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
  where?: InputMaybe<AssetSingleRelationWhereInput>;
};

export type CBannerInlineInlineDividerArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CBannerInlineParagraphArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CBannerInlineVideoArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CBannerInlineConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CBannerInlineWhereUniqueInput;
};

/** A connection to a list of items. */
export type CBannerInlineConnection = {
  __typename?: 'CBannerInlineConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CBannerInlineEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CBannerInlineCreateInput = {
  actionGroup?: InputMaybe<CActionGroupCreateOneInlineInput>;
  badge?: InputMaybe<BadgeCreateOneInlineInput>;
  customComponentRenderer?: InputMaybe<CustomComponent>;
  heading?: InputMaybe<CHeadingCreateOneInlineInput>;
  image?: InputMaybe<AssetCreateOneInlineInput>;
  imageBorderRadius?: InputMaybe<BorderRadius>;
  imageHiddenBelow?: InputMaybe<HiddenBelow>;
  inlineDivider?: InputMaybe<InlineDividerCreateOneInlineInput>;
  paragraph?: InputMaybe<CParagraphCreateOneInlineInput>;
  reverse?: InputMaybe<Scalars['Boolean']>;
  reverseContent?: InputMaybe<ReverseContent>;
  video?: InputMaybe<VideoCreateOneInlineInput>;
};

export type CBannerInlineCreateManyInlineInput = {
  /** Create and connect multiple existing CBannerInline documents */
  create?: InputMaybe<Array<CBannerInlineCreateInput>>;
};

export type CBannerInlineCreateOneInlineInput = {
  /** Create and connect one CBannerInline document */
  create?: InputMaybe<CBannerInlineCreateInput>;
};

export type CBannerInlineCreateWithPositionInput = {
  /** Document to create */
  data: CBannerInlineCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type CBannerInlineEdge = {
  __typename?: 'CBannerInlineEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CBannerInline;
};

/** Identifies documents */
export type CBannerInlineManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CBannerInlineWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CBannerInlineWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CBannerInlineWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  actionGroup?: InputMaybe<CActionGroupWhereInput>;
  badge?: InputMaybe<BadgeWhereInput>;
  customComponentRenderer?: InputMaybe<CustomComponent>;
  /** All values that are contained in given list. */
  customComponentRenderer_in?: InputMaybe<Array<InputMaybe<CustomComponent>>>;
  /** Any other value that exists and is not equal to the given value. */
  customComponentRenderer_not?: InputMaybe<CustomComponent>;
  /** All values that are not contained in given list. */
  customComponentRenderer_not_in?: InputMaybe<
    Array<InputMaybe<CustomComponent>>
  >;
  heading?: InputMaybe<CHeadingWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AssetWhereInput>;
  imageBorderRadius?: InputMaybe<BorderRadius>;
  /** All values that are contained in given list. */
  imageBorderRadius_in?: InputMaybe<Array<InputMaybe<BorderRadius>>>;
  /** Any other value that exists and is not equal to the given value. */
  imageBorderRadius_not?: InputMaybe<BorderRadius>;
  /** All values that are not contained in given list. */
  imageBorderRadius_not_in?: InputMaybe<Array<InputMaybe<BorderRadius>>>;
  imageHiddenBelow?: InputMaybe<HiddenBelow>;
  /** All values that are contained in given list. */
  imageHiddenBelow_in?: InputMaybe<Array<InputMaybe<HiddenBelow>>>;
  /** Any other value that exists and is not equal to the given value. */
  imageHiddenBelow_not?: InputMaybe<HiddenBelow>;
  /** All values that are not contained in given list. */
  imageHiddenBelow_not_in?: InputMaybe<Array<InputMaybe<HiddenBelow>>>;
  inlineDivider?: InputMaybe<InlineDividerWhereInput>;
  paragraph?: InputMaybe<CParagraphWhereInput>;
  reverse?: InputMaybe<Scalars['Boolean']>;
  reverseContent?: InputMaybe<ReverseContent>;
  /** All values that are contained in given list. */
  reverseContent_in?: InputMaybe<Array<InputMaybe<ReverseContent>>>;
  /** Any other value that exists and is not equal to the given value. */
  reverseContent_not?: InputMaybe<ReverseContent>;
  /** All values that are not contained in given list. */
  reverseContent_not_in?: InputMaybe<Array<InputMaybe<ReverseContent>>>;
  /** Any other value that exists and is not equal to the given value. */
  reverse_not?: InputMaybe<Scalars['Boolean']>;
  video?: InputMaybe<VideoWhereInput>;
};

export enum CBannerInlineOrderByInput {
  CustomComponentRendererAsc = 'customComponentRenderer_ASC',
  CustomComponentRendererDesc = 'customComponentRenderer_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ImageBorderRadiusAsc = 'imageBorderRadius_ASC',
  ImageBorderRadiusDesc = 'imageBorderRadius_DESC',
  ImageHiddenBelowAsc = 'imageHiddenBelow_ASC',
  ImageHiddenBelowDesc = 'imageHiddenBelow_DESC',
  ReverseContentAsc = 'reverseContent_ASC',
  ReverseContentDesc = 'reverseContent_DESC',
  ReverseAsc = 'reverse_ASC',
  ReverseDesc = 'reverse_DESC',
}

export type CBannerInlineParent = CBanner;

export type CBannerInlineParentConnectInput = {
  CBanner?: InputMaybe<CBannerConnectInput>;
};

export type CBannerInlineParentCreateInput = {
  CBanner?: InputMaybe<CBannerCreateInput>;
};

export type CBannerInlineParentCreateManyInlineInput = {
  /** Create and connect multiple existing CBannerInlineParent documents */
  create?: InputMaybe<Array<CBannerInlineParentCreateInput>>;
};

export type CBannerInlineParentCreateOneInlineInput = {
  /** Create and connect one CBannerInlineParent document */
  create?: InputMaybe<CBannerInlineParentCreateInput>;
};

export type CBannerInlineParentCreateWithPositionInput = {
  CBanner?: InputMaybe<CBannerCreateWithPositionInput>;
};

export type CBannerInlineParentUpdateInput = {
  CBanner?: InputMaybe<CBannerUpdateInput>;
};

export type CBannerInlineParentUpdateManyInlineInput = {
  /** Create and connect multiple CBannerInlineParent component instances */
  create?: InputMaybe<Array<CBannerInlineParentCreateWithPositionInput>>;
  /** Delete multiple CBannerInlineParent documents */
  delete?: InputMaybe<Array<CBannerInlineParentWhereUniqueInput>>;
  /** Update multiple CBannerInlineParent component instances */
  update?: InputMaybe<
    Array<CBannerInlineParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CBannerInlineParent component instances */
  upsert?: InputMaybe<
    Array<CBannerInlineParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CBannerInlineParentUpdateManyWithNestedWhereInput = {
  CBanner?: InputMaybe<CBannerUpdateManyWithNestedWhereInput>;
};

export type CBannerInlineParentUpdateOneInlineInput = {
  /** Create and connect one CBannerInlineParent document */
  create?: InputMaybe<CBannerInlineParentCreateInput>;
  /** Delete currently connected CBannerInlineParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CBannerInlineParent document */
  update?: InputMaybe<CBannerInlineParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CBannerInlineParent document */
  upsert?: InputMaybe<CBannerInlineParentUpsertWithNestedWhereUniqueInput>;
};

export type CBannerInlineParentUpdateWithNestedWhereUniqueAndPositionInput = {
  CBanner?: InputMaybe<CBannerUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type CBannerInlineParentUpdateWithNestedWhereUniqueInput = {
  CBanner?: InputMaybe<CBannerUpdateWithNestedWhereUniqueInput>;
};

export type CBannerInlineParentUpsertWithNestedWhereUniqueAndPositionInput = {
  CBanner?: InputMaybe<CBannerUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type CBannerInlineParentUpsertWithNestedWhereUniqueInput = {
  CBanner?: InputMaybe<CBannerUpsertWithNestedWhereUniqueInput>;
};

export type CBannerInlineParentWhereInput = {
  CBanner?: InputMaybe<CBannerWhereInput>;
};

export type CBannerInlineParentWhereUniqueInput = {
  CBanner?: InputMaybe<CBannerWhereUniqueInput>;
};

export type CBannerInlineUpdateInput = {
  actionGroup?: InputMaybe<CActionGroupUpdateOneInlineInput>;
  badge?: InputMaybe<BadgeUpdateOneInlineInput>;
  customComponentRenderer?: InputMaybe<CustomComponent>;
  heading?: InputMaybe<CHeadingUpdateOneInlineInput>;
  image?: InputMaybe<AssetUpdateOneInlineInput>;
  imageBorderRadius?: InputMaybe<BorderRadius>;
  imageHiddenBelow?: InputMaybe<HiddenBelow>;
  inlineDivider?: InputMaybe<InlineDividerUpdateOneInlineInput>;
  paragraph?: InputMaybe<CParagraphUpdateOneInlineInput>;
  reverse?: InputMaybe<Scalars['Boolean']>;
  reverseContent?: InputMaybe<ReverseContent>;
  video?: InputMaybe<VideoUpdateOneInlineInput>;
};

export type CBannerInlineUpdateManyInlineInput = {
  /** Create and connect multiple CBannerInline component instances */
  create?: InputMaybe<Array<CBannerInlineCreateWithPositionInput>>;
  /** Delete multiple CBannerInline documents */
  delete?: InputMaybe<Array<CBannerInlineWhereUniqueInput>>;
  /** Update multiple CBannerInline component instances */
  update?: InputMaybe<
    Array<CBannerInlineUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CBannerInline component instances */
  upsert?: InputMaybe<
    Array<CBannerInlineUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CBannerInlineUpdateManyInput = {
  customComponentRenderer?: InputMaybe<CustomComponent>;
  imageBorderRadius?: InputMaybe<BorderRadius>;
  imageHiddenBelow?: InputMaybe<HiddenBelow>;
  reverse?: InputMaybe<Scalars['Boolean']>;
  reverseContent?: InputMaybe<ReverseContent>;
};

export type CBannerInlineUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CBannerInlineUpdateManyInput;
  /** Document search */
  where: CBannerInlineWhereInput;
};

export type CBannerInlineUpdateOneInlineInput = {
  /** Create and connect one CBannerInline document */
  create?: InputMaybe<CBannerInlineCreateInput>;
  /** Delete currently connected CBannerInline document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CBannerInline document */
  update?: InputMaybe<CBannerInlineUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CBannerInline document */
  upsert?: InputMaybe<CBannerInlineUpsertWithNestedWhereUniqueInput>;
};

export type CBannerInlineUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<CBannerInlineUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CBannerInlineWhereUniqueInput;
};

export type CBannerInlineUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CBannerInlineUpdateInput;
  /** Unique document search */
  where: CBannerInlineWhereUniqueInput;
};

export type CBannerInlineUpsertInput = {
  /** Create document if it didn't exist */
  create: CBannerInlineCreateInput;
  /** Update document if it exists */
  update: CBannerInlineUpdateInput;
};

export type CBannerInlineUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<CBannerInlineUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CBannerInlineWhereUniqueInput;
};

export type CBannerInlineUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CBannerInlineUpsertInput;
  /** Unique document search */
  where: CBannerInlineWhereUniqueInput;
};

/** Identifies documents */
export type CBannerInlineWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CBannerInlineWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CBannerInlineWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CBannerInlineWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  actionGroup?: InputMaybe<CActionGroupWhereInput>;
  badge?: InputMaybe<BadgeWhereInput>;
  customComponentRenderer?: InputMaybe<CustomComponent>;
  /** All values that are contained in given list. */
  customComponentRenderer_in?: InputMaybe<Array<InputMaybe<CustomComponent>>>;
  /** Any other value that exists and is not equal to the given value. */
  customComponentRenderer_not?: InputMaybe<CustomComponent>;
  /** All values that are not contained in given list. */
  customComponentRenderer_not_in?: InputMaybe<
    Array<InputMaybe<CustomComponent>>
  >;
  heading?: InputMaybe<CHeadingWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AssetWhereInput>;
  imageBorderRadius?: InputMaybe<BorderRadius>;
  /** All values that are contained in given list. */
  imageBorderRadius_in?: InputMaybe<Array<InputMaybe<BorderRadius>>>;
  /** Any other value that exists and is not equal to the given value. */
  imageBorderRadius_not?: InputMaybe<BorderRadius>;
  /** All values that are not contained in given list. */
  imageBorderRadius_not_in?: InputMaybe<Array<InputMaybe<BorderRadius>>>;
  imageHiddenBelow?: InputMaybe<HiddenBelow>;
  /** All values that are contained in given list. */
  imageHiddenBelow_in?: InputMaybe<Array<InputMaybe<HiddenBelow>>>;
  /** Any other value that exists and is not equal to the given value. */
  imageHiddenBelow_not?: InputMaybe<HiddenBelow>;
  /** All values that are not contained in given list. */
  imageHiddenBelow_not_in?: InputMaybe<Array<InputMaybe<HiddenBelow>>>;
  inlineDivider?: InputMaybe<InlineDividerWhereInput>;
  paragraph?: InputMaybe<CParagraphWhereInput>;
  reverse?: InputMaybe<Scalars['Boolean']>;
  reverseContent?: InputMaybe<ReverseContent>;
  /** All values that are contained in given list. */
  reverseContent_in?: InputMaybe<Array<InputMaybe<ReverseContent>>>;
  /** Any other value that exists and is not equal to the given value. */
  reverseContent_not?: InputMaybe<ReverseContent>;
  /** All values that are not contained in given list. */
  reverseContent_not_in?: InputMaybe<Array<InputMaybe<ReverseContent>>>;
  /** Any other value that exists and is not equal to the given value. */
  reverse_not?: InputMaybe<Scalars['Boolean']>;
  video?: InputMaybe<VideoWhereInput>;
};

/** References CBannerInline record uniquely */
export type CBannerInlineWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Identifies documents */
export type CBannerManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CBannerWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CBannerWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CBannerWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  /** All values in which the modular component is connected to the given models */
  banner_items?: InputMaybe<CBannerbanner_ItemsUnionWhereInput>;
  /** All values in which the union is empty. */
  banner_items_empty?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
};

export enum CBannerOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type CBannerParent = CBlock;

export type CBannerParentConnectInput = {
  CBlock?: InputMaybe<CBlockConnectInput>;
};

export type CBannerParentCreateInput = {
  CBlock?: InputMaybe<CBlockCreateInput>;
};

export type CBannerParentCreateManyInlineInput = {
  /** Connect multiple existing CBannerParent documents */
  connect?: InputMaybe<Array<CBannerParentWhereUniqueInput>>;
  /** Create and connect multiple existing CBannerParent documents */
  create?: InputMaybe<Array<CBannerParentCreateInput>>;
};

export type CBannerParentCreateOneInlineInput = {
  /** Connect one existing CBannerParent document */
  connect?: InputMaybe<CBannerParentWhereUniqueInput>;
  /** Create and connect one CBannerParent document */
  create?: InputMaybe<CBannerParentCreateInput>;
};

export type CBannerParentUpdateInput = {
  CBlock?: InputMaybe<CBlockUpdateInput>;
};

export type CBannerParentUpdateManyInlineInput = {
  /** Connect multiple existing CBannerParent documents */
  connect?: InputMaybe<Array<CBannerParentConnectInput>>;
  /** Create and connect multiple CBannerParent documents */
  create?: InputMaybe<Array<CBannerParentCreateInput>>;
  /** Delete multiple CBannerParent documents */
  delete?: InputMaybe<Array<CBannerParentWhereUniqueInput>>;
  /** Disconnect multiple CBannerParent documents */
  disconnect?: InputMaybe<Array<CBannerParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing CBannerParent documents */
  set?: InputMaybe<Array<CBannerParentWhereUniqueInput>>;
  /** Update multiple CBannerParent documents */
  update?: InputMaybe<Array<CBannerParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple CBannerParent documents */
  upsert?: InputMaybe<Array<CBannerParentUpsertWithNestedWhereUniqueInput>>;
};

export type CBannerParentUpdateManyWithNestedWhereInput = {
  CBlock?: InputMaybe<CBlockUpdateManyWithNestedWhereInput>;
};

export type CBannerParentUpdateOneInlineInput = {
  /** Connect existing CBannerParent document */
  connect?: InputMaybe<CBannerParentWhereUniqueInput>;
  /** Create and connect one CBannerParent document */
  create?: InputMaybe<CBannerParentCreateInput>;
  /** Delete currently connected CBannerParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected CBannerParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single CBannerParent document */
  update?: InputMaybe<CBannerParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CBannerParent document */
  upsert?: InputMaybe<CBannerParentUpsertWithNestedWhereUniqueInput>;
};

export type CBannerParentUpdateWithNestedWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockUpdateWithNestedWhereUniqueInput>;
};

export type CBannerParentUpsertWithNestedWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockUpsertWithNestedWhereUniqueInput>;
};

export type CBannerParentWhereInput = {
  CBlock?: InputMaybe<CBlockWhereInput>;
};

export type CBannerParentWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockWhereUniqueInput>;
};

export type CBannerPrimary = Entity & {
  __typename?: 'CBannerPrimary';
  actionGroup?: Maybe<CActionGroup>;
  divider?: Maybe<Divider>;
  footnotes?: Maybe<CParagraph>;
  heading?: Maybe<CHeading>;
  /** The unique identifier */
  id: Scalars['ID'];
  image: Asset;
  /** Modifies the primary banner image height. Small (300px), Medium (450px) and Large (600px). Default height is Large. */
  imageHeight?: Maybe<BannerImageHeight>;
  /** Align the content of the Primary Banner to the right. Default is left. */
  isContentRight?: Maybe<Scalars['Boolean']>;
  /** Display Branding Logo in Primary Banner */
  logoDisplay?: Maybe<Scalars['Boolean']>;
  paragraph?: Maybe<CParagraph>;
  /** System stage field */
  stage: Stage;
};

export type CBannerPrimaryActionGroupArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CBannerPrimaryDividerArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CBannerPrimaryFootnotesArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CBannerPrimaryHeadingArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CBannerPrimaryImageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
  where?: InputMaybe<AssetSingleRelationWhereInput>;
};

export type CBannerPrimaryParagraphArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CBannerPrimaryConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CBannerPrimaryWhereUniqueInput;
};

/** A connection to a list of items. */
export type CBannerPrimaryConnection = {
  __typename?: 'CBannerPrimaryConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CBannerPrimaryEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CBannerPrimaryCreateInput = {
  actionGroup?: InputMaybe<CActionGroupCreateOneInlineInput>;
  divider?: InputMaybe<DividerCreateOneInlineInput>;
  footnotes?: InputMaybe<CParagraphCreateOneInlineInput>;
  heading?: InputMaybe<CHeadingCreateOneInlineInput>;
  image: AssetCreateOneInlineInput;
  imageHeight?: InputMaybe<BannerImageHeight>;
  isContentRight?: InputMaybe<Scalars['Boolean']>;
  logoDisplay?: InputMaybe<Scalars['Boolean']>;
  paragraph?: InputMaybe<CParagraphCreateOneInlineInput>;
};

export type CBannerPrimaryCreateManyInlineInput = {
  /** Create and connect multiple existing CBannerPrimary documents */
  create?: InputMaybe<Array<CBannerPrimaryCreateInput>>;
};

export type CBannerPrimaryCreateOneInlineInput = {
  /** Create and connect one CBannerPrimary document */
  create?: InputMaybe<CBannerPrimaryCreateInput>;
};

export type CBannerPrimaryCreateWithPositionInput = {
  /** Document to create */
  data: CBannerPrimaryCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type CBannerPrimaryEdge = {
  __typename?: 'CBannerPrimaryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CBannerPrimary;
};

/** Identifies documents */
export type CBannerPrimaryManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CBannerPrimaryWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CBannerPrimaryWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CBannerPrimaryWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  actionGroup?: InputMaybe<CActionGroupWhereInput>;
  divider?: InputMaybe<DividerWhereInput>;
  footnotes?: InputMaybe<CParagraphWhereInput>;
  heading?: InputMaybe<CHeadingWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AssetWhereInput>;
  imageHeight?: InputMaybe<BannerImageHeight>;
  /** All values that are contained in given list. */
  imageHeight_in?: InputMaybe<Array<InputMaybe<BannerImageHeight>>>;
  /** Any other value that exists and is not equal to the given value. */
  imageHeight_not?: InputMaybe<BannerImageHeight>;
  /** All values that are not contained in given list. */
  imageHeight_not_in?: InputMaybe<Array<InputMaybe<BannerImageHeight>>>;
  isContentRight?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  isContentRight_not?: InputMaybe<Scalars['Boolean']>;
  logoDisplay?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  logoDisplay_not?: InputMaybe<Scalars['Boolean']>;
  paragraph?: InputMaybe<CParagraphWhereInput>;
};

export enum CBannerPrimaryOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ImageHeightAsc = 'imageHeight_ASC',
  ImageHeightDesc = 'imageHeight_DESC',
  IsContentRightAsc = 'isContentRight_ASC',
  IsContentRightDesc = 'isContentRight_DESC',
  LogoDisplayAsc = 'logoDisplay_ASC',
  LogoDisplayDesc = 'logoDisplay_DESC',
}

export type CBannerPrimaryParent = Page;

export type CBannerPrimaryParentConnectInput = {
  Page?: InputMaybe<PageConnectInput>;
};

export type CBannerPrimaryParentCreateInput = {
  Page?: InputMaybe<PageCreateInput>;
};

export type CBannerPrimaryParentCreateManyInlineInput = {
  /** Connect multiple existing CBannerPrimaryParent documents */
  connect?: InputMaybe<Array<CBannerPrimaryParentWhereUniqueInput>>;
  /** Create and connect multiple existing CBannerPrimaryParent documents */
  create?: InputMaybe<Array<CBannerPrimaryParentCreateInput>>;
};

export type CBannerPrimaryParentCreateOneInlineInput = {
  /** Connect one existing CBannerPrimaryParent document */
  connect?: InputMaybe<CBannerPrimaryParentWhereUniqueInput>;
  /** Create and connect one CBannerPrimaryParent document */
  create?: InputMaybe<CBannerPrimaryParentCreateInput>;
};

export type CBannerPrimaryParentUpdateInput = {
  Page?: InputMaybe<PageUpdateInput>;
};

export type CBannerPrimaryParentUpdateManyInlineInput = {
  /** Connect multiple existing CBannerPrimaryParent documents */
  connect?: InputMaybe<Array<CBannerPrimaryParentConnectInput>>;
  /** Create and connect multiple CBannerPrimaryParent documents */
  create?: InputMaybe<Array<CBannerPrimaryParentCreateInput>>;
  /** Delete multiple CBannerPrimaryParent documents */
  delete?: InputMaybe<Array<CBannerPrimaryParentWhereUniqueInput>>;
  /** Disconnect multiple CBannerPrimaryParent documents */
  disconnect?: InputMaybe<Array<CBannerPrimaryParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing CBannerPrimaryParent documents */
  set?: InputMaybe<Array<CBannerPrimaryParentWhereUniqueInput>>;
  /** Update multiple CBannerPrimaryParent documents */
  update?: InputMaybe<
    Array<CBannerPrimaryParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple CBannerPrimaryParent documents */
  upsert?: InputMaybe<
    Array<CBannerPrimaryParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type CBannerPrimaryParentUpdateManyWithNestedWhereInput = {
  Page?: InputMaybe<PageUpdateManyWithNestedWhereInput>;
};

export type CBannerPrimaryParentUpdateOneInlineInput = {
  /** Connect existing CBannerPrimaryParent document */
  connect?: InputMaybe<CBannerPrimaryParentWhereUniqueInput>;
  /** Create and connect one CBannerPrimaryParent document */
  create?: InputMaybe<CBannerPrimaryParentCreateInput>;
  /** Delete currently connected CBannerPrimaryParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected CBannerPrimaryParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single CBannerPrimaryParent document */
  update?: InputMaybe<CBannerPrimaryParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CBannerPrimaryParent document */
  upsert?: InputMaybe<CBannerPrimaryParentUpsertWithNestedWhereUniqueInput>;
};

export type CBannerPrimaryParentUpdateWithNestedWhereUniqueInput = {
  Page?: InputMaybe<PageUpdateWithNestedWhereUniqueInput>;
};

export type CBannerPrimaryParentUpsertWithNestedWhereUniqueInput = {
  Page?: InputMaybe<PageUpsertWithNestedWhereUniqueInput>;
};

export type CBannerPrimaryParentWhereInput = {
  Page?: InputMaybe<PageWhereInput>;
};

export type CBannerPrimaryParentWhereUniqueInput = {
  Page?: InputMaybe<PageWhereUniqueInput>;
};

export type CBannerPrimaryUpdateInput = {
  actionGroup?: InputMaybe<CActionGroupUpdateOneInlineInput>;
  divider?: InputMaybe<DividerUpdateOneInlineInput>;
  footnotes?: InputMaybe<CParagraphUpdateOneInlineInput>;
  heading?: InputMaybe<CHeadingUpdateOneInlineInput>;
  image?: InputMaybe<AssetUpdateOneInlineInput>;
  imageHeight?: InputMaybe<BannerImageHeight>;
  isContentRight?: InputMaybe<Scalars['Boolean']>;
  logoDisplay?: InputMaybe<Scalars['Boolean']>;
  paragraph?: InputMaybe<CParagraphUpdateOneInlineInput>;
};

export type CBannerPrimaryUpdateManyInlineInput = {
  /** Create and connect multiple CBannerPrimary component instances */
  create?: InputMaybe<Array<CBannerPrimaryCreateWithPositionInput>>;
  /** Delete multiple CBannerPrimary documents */
  delete?: InputMaybe<Array<CBannerPrimaryWhereUniqueInput>>;
  /** Update multiple CBannerPrimary component instances */
  update?: InputMaybe<
    Array<CBannerPrimaryUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CBannerPrimary component instances */
  upsert?: InputMaybe<
    Array<CBannerPrimaryUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CBannerPrimaryUpdateManyInput = {
  imageHeight?: InputMaybe<BannerImageHeight>;
  isContentRight?: InputMaybe<Scalars['Boolean']>;
  logoDisplay?: InputMaybe<Scalars['Boolean']>;
};

export type CBannerPrimaryUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CBannerPrimaryUpdateManyInput;
  /** Document search */
  where: CBannerPrimaryWhereInput;
};

export type CBannerPrimaryUpdateOneInlineInput = {
  /** Create and connect one CBannerPrimary document */
  create?: InputMaybe<CBannerPrimaryCreateInput>;
  /** Delete currently connected CBannerPrimary document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CBannerPrimary document */
  update?: InputMaybe<CBannerPrimaryUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CBannerPrimary document */
  upsert?: InputMaybe<CBannerPrimaryUpsertWithNestedWhereUniqueInput>;
};

export type CBannerPrimaryUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<CBannerPrimaryUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CBannerPrimaryWhereUniqueInput;
};

export type CBannerPrimaryUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CBannerPrimaryUpdateInput;
  /** Unique document search */
  where: CBannerPrimaryWhereUniqueInput;
};

export type CBannerPrimaryUpsertInput = {
  /** Create document if it didn't exist */
  create: CBannerPrimaryCreateInput;
  /** Update document if it exists */
  update: CBannerPrimaryUpdateInput;
};

export type CBannerPrimaryUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<CBannerPrimaryUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CBannerPrimaryWhereUniqueInput;
};

export type CBannerPrimaryUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CBannerPrimaryUpsertInput;
  /** Unique document search */
  where: CBannerPrimaryWhereUniqueInput;
};

/** Identifies documents */
export type CBannerPrimaryWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CBannerPrimaryWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CBannerPrimaryWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CBannerPrimaryWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  actionGroup?: InputMaybe<CActionGroupWhereInput>;
  divider?: InputMaybe<DividerWhereInput>;
  footnotes?: InputMaybe<CParagraphWhereInput>;
  heading?: InputMaybe<CHeadingWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AssetWhereInput>;
  imageHeight?: InputMaybe<BannerImageHeight>;
  /** All values that are contained in given list. */
  imageHeight_in?: InputMaybe<Array<InputMaybe<BannerImageHeight>>>;
  /** Any other value that exists and is not equal to the given value. */
  imageHeight_not?: InputMaybe<BannerImageHeight>;
  /** All values that are not contained in given list. */
  imageHeight_not_in?: InputMaybe<Array<InputMaybe<BannerImageHeight>>>;
  isContentRight?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  isContentRight_not?: InputMaybe<Scalars['Boolean']>;
  logoDisplay?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  logoDisplay_not?: InputMaybe<Scalars['Boolean']>;
  paragraph?: InputMaybe<CParagraphWhereInput>;
};

/** References CBannerPrimary record uniquely */
export type CBannerPrimaryWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Hero banner for Hirer shopfront homepage */
export type CBannerShopfront = Entity & {
  __typename?: 'CBannerShopfront';
  footnotes: Array<RichTextEditorWrapper>;
  heading?: Maybe<CHeading>;
  /** The unique identifier */
  id: Scalars['ID'];
  image: Asset;
  paragraph?: Maybe<CParagraph>;
  /** System stage field */
  stage: Stage;
};

/** Hero banner for Hirer shopfront homepage */
export type CBannerShopfrontFootnotesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<RichTextEditorWrapperOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RichTextEditorWrapperWhereInput>;
};

/** Hero banner for Hirer shopfront homepage */
export type CBannerShopfrontHeadingArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Hero banner for Hirer shopfront homepage */
export type CBannerShopfrontImageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
  where?: InputMaybe<AssetSingleRelationWhereInput>;
};

/** Hero banner for Hirer shopfront homepage */
export type CBannerShopfrontParagraphArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CBannerShopfrontConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CBannerShopfrontWhereUniqueInput;
};

/** A connection to a list of items. */
export type CBannerShopfrontConnection = {
  __typename?: 'CBannerShopfrontConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CBannerShopfrontEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CBannerShopfrontCreateInput = {
  footnotes?: InputMaybe<RichTextEditorWrapperCreateManyInlineInput>;
  heading?: InputMaybe<CHeadingCreateOneInlineInput>;
  image: AssetCreateOneInlineInput;
  paragraph?: InputMaybe<CParagraphCreateOneInlineInput>;
};

export type CBannerShopfrontCreateManyInlineInput = {
  /** Create and connect multiple existing CBannerShopfront documents */
  create?: InputMaybe<Array<CBannerShopfrontCreateInput>>;
};

export type CBannerShopfrontCreateOneInlineInput = {
  /** Create and connect one CBannerShopfront document */
  create?: InputMaybe<CBannerShopfrontCreateInput>;
};

export type CBannerShopfrontCreateWithPositionInput = {
  /** Document to create */
  data: CBannerShopfrontCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type CBannerShopfrontEdge = {
  __typename?: 'CBannerShopfrontEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CBannerShopfront;
};

/** Identifies documents */
export type CBannerShopfrontManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CBannerShopfrontWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CBannerShopfrontWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CBannerShopfrontWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  footnotes_every?: InputMaybe<RichTextEditorWrapperWhereInput>;
  footnotes_none?: InputMaybe<RichTextEditorWrapperWhereInput>;
  footnotes_some?: InputMaybe<RichTextEditorWrapperWhereInput>;
  heading?: InputMaybe<CHeadingWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AssetWhereInput>;
  paragraph?: InputMaybe<CParagraphWhereInput>;
};

export enum CBannerShopfrontOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type CBannerShopfrontParent = Page;

export type CBannerShopfrontParentConnectInput = {
  Page?: InputMaybe<PageConnectInput>;
};

export type CBannerShopfrontParentCreateInput = {
  Page?: InputMaybe<PageCreateInput>;
};

export type CBannerShopfrontParentCreateManyInlineInput = {
  /** Connect multiple existing CBannerShopfrontParent documents */
  connect?: InputMaybe<Array<CBannerShopfrontParentWhereUniqueInput>>;
  /** Create and connect multiple existing CBannerShopfrontParent documents */
  create?: InputMaybe<Array<CBannerShopfrontParentCreateInput>>;
};

export type CBannerShopfrontParentCreateOneInlineInput = {
  /** Connect one existing CBannerShopfrontParent document */
  connect?: InputMaybe<CBannerShopfrontParentWhereUniqueInput>;
  /** Create and connect one CBannerShopfrontParent document */
  create?: InputMaybe<CBannerShopfrontParentCreateInput>;
};

export type CBannerShopfrontParentUpdateInput = {
  Page?: InputMaybe<PageUpdateInput>;
};

export type CBannerShopfrontParentUpdateManyInlineInput = {
  /** Connect multiple existing CBannerShopfrontParent documents */
  connect?: InputMaybe<Array<CBannerShopfrontParentConnectInput>>;
  /** Create and connect multiple CBannerShopfrontParent documents */
  create?: InputMaybe<Array<CBannerShopfrontParentCreateInput>>;
  /** Delete multiple CBannerShopfrontParent documents */
  delete?: InputMaybe<Array<CBannerShopfrontParentWhereUniqueInput>>;
  /** Disconnect multiple CBannerShopfrontParent documents */
  disconnect?: InputMaybe<Array<CBannerShopfrontParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing CBannerShopfrontParent documents */
  set?: InputMaybe<Array<CBannerShopfrontParentWhereUniqueInput>>;
  /** Update multiple CBannerShopfrontParent documents */
  update?: InputMaybe<
    Array<CBannerShopfrontParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple CBannerShopfrontParent documents */
  upsert?: InputMaybe<
    Array<CBannerShopfrontParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type CBannerShopfrontParentUpdateManyWithNestedWhereInput = {
  Page?: InputMaybe<PageUpdateManyWithNestedWhereInput>;
};

export type CBannerShopfrontParentUpdateOneInlineInput = {
  /** Connect existing CBannerShopfrontParent document */
  connect?: InputMaybe<CBannerShopfrontParentWhereUniqueInput>;
  /** Create and connect one CBannerShopfrontParent document */
  create?: InputMaybe<CBannerShopfrontParentCreateInput>;
  /** Delete currently connected CBannerShopfrontParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected CBannerShopfrontParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single CBannerShopfrontParent document */
  update?: InputMaybe<CBannerShopfrontParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CBannerShopfrontParent document */
  upsert?: InputMaybe<CBannerShopfrontParentUpsertWithNestedWhereUniqueInput>;
};

export type CBannerShopfrontParentUpdateWithNestedWhereUniqueInput = {
  Page?: InputMaybe<PageUpdateWithNestedWhereUniqueInput>;
};

export type CBannerShopfrontParentUpsertWithNestedWhereUniqueInput = {
  Page?: InputMaybe<PageUpsertWithNestedWhereUniqueInput>;
};

export type CBannerShopfrontParentWhereInput = {
  Page?: InputMaybe<PageWhereInput>;
};

export type CBannerShopfrontParentWhereUniqueInput = {
  Page?: InputMaybe<PageWhereUniqueInput>;
};

export type CBannerShopfrontUpdateInput = {
  footnotes?: InputMaybe<RichTextEditorWrapperUpdateManyInlineInput>;
  heading?: InputMaybe<CHeadingUpdateOneInlineInput>;
  image?: InputMaybe<AssetUpdateOneInlineInput>;
  paragraph?: InputMaybe<CParagraphUpdateOneInlineInput>;
};

export type CBannerShopfrontUpdateManyInlineInput = {
  /** Create and connect multiple CBannerShopfront component instances */
  create?: InputMaybe<Array<CBannerShopfrontCreateWithPositionInput>>;
  /** Delete multiple CBannerShopfront documents */
  delete?: InputMaybe<Array<CBannerShopfrontWhereUniqueInput>>;
  /** Update multiple CBannerShopfront component instances */
  update?: InputMaybe<
    Array<CBannerShopfrontUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CBannerShopfront component instances */
  upsert?: InputMaybe<
    Array<CBannerShopfrontUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CBannerShopfrontUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']>;
};

export type CBannerShopfrontUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CBannerShopfrontUpdateManyInput;
  /** Document search */
  where: CBannerShopfrontWhereInput;
};

export type CBannerShopfrontUpdateOneInlineInput = {
  /** Create and connect one CBannerShopfront document */
  create?: InputMaybe<CBannerShopfrontCreateInput>;
  /** Delete currently connected CBannerShopfront document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CBannerShopfront document */
  update?: InputMaybe<CBannerShopfrontUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CBannerShopfront document */
  upsert?: InputMaybe<CBannerShopfrontUpsertWithNestedWhereUniqueInput>;
};

export type CBannerShopfrontUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<CBannerShopfrontUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CBannerShopfrontWhereUniqueInput;
};

export type CBannerShopfrontUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CBannerShopfrontUpdateInput;
  /** Unique document search */
  where: CBannerShopfrontWhereUniqueInput;
};

export type CBannerShopfrontUpsertInput = {
  /** Create document if it didn't exist */
  create: CBannerShopfrontCreateInput;
  /** Update document if it exists */
  update: CBannerShopfrontUpdateInput;
};

export type CBannerShopfrontUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<CBannerShopfrontUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CBannerShopfrontWhereUniqueInput;
};

export type CBannerShopfrontUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CBannerShopfrontUpsertInput;
  /** Unique document search */
  where: CBannerShopfrontWhereUniqueInput;
};

/** Identifies documents */
export type CBannerShopfrontWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CBannerShopfrontWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CBannerShopfrontWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CBannerShopfrontWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  footnotes_every?: InputMaybe<RichTextEditorWrapperWhereInput>;
  footnotes_none?: InputMaybe<RichTextEditorWrapperWhereInput>;
  footnotes_some?: InputMaybe<RichTextEditorWrapperWhereInput>;
  heading?: InputMaybe<CHeadingWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AssetWhereInput>;
  paragraph?: InputMaybe<CParagraphWhereInput>;
};

/** References CBannerShopfront record uniquely */
export type CBannerShopfrontWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type CBannerUpdateInput = {
  banner_items?: InputMaybe<CBannerbanner_ItemsUnionUpdateOneInlineInput>;
};

export type CBannerUpdateManyInlineInput = {
  /** Create and connect multiple CBanner component instances */
  create?: InputMaybe<Array<CBannerCreateWithPositionInput>>;
  /** Delete multiple CBanner documents */
  delete?: InputMaybe<Array<CBannerWhereUniqueInput>>;
  /** Update multiple CBanner component instances */
  update?: InputMaybe<
    Array<CBannerUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CBanner component instances */
  upsert?: InputMaybe<
    Array<CBannerUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CBannerUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']>;
};

export type CBannerUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CBannerUpdateManyInput;
  /** Document search */
  where: CBannerWhereInput;
};

export type CBannerUpdateOneInlineInput = {
  /** Create and connect one CBanner document */
  create?: InputMaybe<CBannerCreateInput>;
  /** Delete currently connected CBanner document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CBanner document */
  update?: InputMaybe<CBannerUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CBanner document */
  upsert?: InputMaybe<CBannerUpsertWithNestedWhereUniqueInput>;
};

export type CBannerUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<CBannerUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CBannerWhereUniqueInput;
};

export type CBannerUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CBannerUpdateInput;
  /** Unique document search */
  where: CBannerWhereUniqueInput;
};

export type CBannerUpsertInput = {
  /** Create document if it didn't exist */
  create: CBannerCreateInput;
  /** Update document if it exists */
  update: CBannerUpdateInput;
};

export type CBannerUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<CBannerUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CBannerWhereUniqueInput;
};

export type CBannerUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CBannerUpsertInput;
  /** Unique document search */
  where: CBannerWhereUniqueInput;
};

/** Identifies documents */
export type CBannerWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CBannerWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CBannerWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CBannerWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  /** All values in which the modular component is connected to the given models */
  banner_items?: InputMaybe<CBannerbanner_ItemsUnionWhereInput>;
  /** All values in which the union is empty. */
  banner_items_empty?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
};

/** References CBanner record uniquely */
export type CBannerWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type CBannerbanner_ItemsUnion = CBannerArticle | CBannerInline;

export type CBannerbanner_ItemsUnionConnectInput = {
  CBannerArticle?: InputMaybe<CBannerArticleConnectInput>;
  CBannerInline?: InputMaybe<CBannerInlineConnectInput>;
};

export type CBannerbanner_ItemsUnionCreateInput = {
  CBannerArticle?: InputMaybe<CBannerArticleCreateInput>;
  CBannerInline?: InputMaybe<CBannerInlineCreateInput>;
};

export type CBannerbanner_ItemsUnionCreateManyInlineInput = {
  /** Create and connect multiple existing CBannerbanner_itemsUnion documents */
  create?: InputMaybe<Array<CBannerbanner_ItemsUnionCreateInput>>;
};

export type CBannerbanner_ItemsUnionCreateOneInlineInput = {
  /** Create and connect one CBannerbanner_itemsUnion document */
  create?: InputMaybe<CBannerbanner_ItemsUnionCreateInput>;
};

export type CBannerbanner_ItemsUnionCreateWithPositionInput = {
  CBannerArticle?: InputMaybe<CBannerArticleCreateWithPositionInput>;
  CBannerInline?: InputMaybe<CBannerInlineCreateWithPositionInput>;
};

export type CBannerbanner_ItemsUnionUpdateInput = {
  CBannerArticle?: InputMaybe<CBannerArticleUpdateInput>;
  CBannerInline?: InputMaybe<CBannerInlineUpdateInput>;
};

export type CBannerbanner_ItemsUnionUpdateManyInlineInput = {
  /** Create and connect multiple CBannerbanner_itemsUnion component instances */
  create?: InputMaybe<Array<CBannerbanner_ItemsUnionCreateWithPositionInput>>;
  /** Delete multiple CBannerbanner_itemsUnion documents */
  delete?: InputMaybe<Array<CBannerbanner_ItemsUnionWhereUniqueInput>>;
  /** Update multiple CBannerbanner_itemsUnion component instances */
  update?: InputMaybe<
    Array<CBannerbanner_ItemsUnionUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CBannerbanner_itemsUnion component instances */
  upsert?: InputMaybe<
    Array<CBannerbanner_ItemsUnionUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CBannerbanner_ItemsUnionUpdateManyWithNestedWhereInput = {
  CBannerArticle?: InputMaybe<CBannerArticleUpdateManyWithNestedWhereInput>;
  CBannerInline?: InputMaybe<CBannerInlineUpdateManyWithNestedWhereInput>;
};

export type CBannerbanner_ItemsUnionUpdateOneInlineInput = {
  /** Create and connect one CBannerbanner_itemsUnion document */
  create?: InputMaybe<CBannerbanner_ItemsUnionCreateInput>;
  /** Delete currently connected CBannerbanner_itemsUnion document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CBannerbanner_itemsUnion document */
  update?: InputMaybe<CBannerbanner_ItemsUnionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CBannerbanner_itemsUnion document */
  upsert?: InputMaybe<CBannerbanner_ItemsUnionUpsertWithNestedWhereUniqueInput>;
};

export type CBannerbanner_ItemsUnionUpdateWithNestedWhereUniqueAndPositionInput =
  {
    CBannerArticle?: InputMaybe<CBannerArticleUpdateWithNestedWhereUniqueAndPositionInput>;
    CBannerInline?: InputMaybe<CBannerInlineUpdateWithNestedWhereUniqueAndPositionInput>;
  };

export type CBannerbanner_ItemsUnionUpdateWithNestedWhereUniqueInput = {
  CBannerArticle?: InputMaybe<CBannerArticleUpdateWithNestedWhereUniqueInput>;
  CBannerInline?: InputMaybe<CBannerInlineUpdateWithNestedWhereUniqueInput>;
};

export type CBannerbanner_ItemsUnionUpsertWithNestedWhereUniqueAndPositionInput =
  {
    CBannerArticle?: InputMaybe<CBannerArticleUpsertWithNestedWhereUniqueAndPositionInput>;
    CBannerInline?: InputMaybe<CBannerInlineUpsertWithNestedWhereUniqueAndPositionInput>;
  };

export type CBannerbanner_ItemsUnionUpsertWithNestedWhereUniqueInput = {
  CBannerArticle?: InputMaybe<CBannerArticleUpsertWithNestedWhereUniqueInput>;
  CBannerInline?: InputMaybe<CBannerInlineUpsertWithNestedWhereUniqueInput>;
};

export type CBannerbanner_ItemsUnionWhereInput = {
  CBannerArticle?: InputMaybe<CBannerArticleWhereInput>;
  CBannerInline?: InputMaybe<CBannerInlineWhereInput>;
};

export type CBannerbanner_ItemsUnionWhereUniqueInput = {
  CBannerArticle?: InputMaybe<CBannerArticleWhereUniqueInput>;
  CBannerInline?: InputMaybe<CBannerInlineWhereUniqueInput>;
};

export type CBlock = Entity &
  Node & {
    __typename?: 'CBlock';
    box?: Maybe<CBox>;
    /** The time the document was created */
    createdAt: Scalars['DateTime'];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<CBlock>;
    /** List of CBlock versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars['ID'];
    items: Array<CBlockitemsUnion>;
    /** A name to identify this container. Used for searches within Hygraph. */
    name: Scalars['String'];
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** Check this to ignore the default locale */
    skipDefaultLocale?: Maybe<Scalars['Boolean']>;
    /** System stage field */
    stage: Stage;
    tile?: Maybe<CTile>;
    /** The time the document was updated */
    updatedAt: Scalars['DateTime'];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type CBlockBoxArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CBlockCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CBlockDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type CBlockHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};

export type CBlockItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type CBlockPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CBlockScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type CBlockTileArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CBlockUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CBlockConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CBlockWhereUniqueInput;
};

/** A connection to a list of items. */
export type CBlockConnection = {
  __typename?: 'CBlockConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CBlockEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CBlockCreateInput = {
  box?: InputMaybe<CBoxCreateOneInlineInput>;
  clazfk1im1p7w01un6sywc0z4?: InputMaybe<CContainerCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  items?: InputMaybe<CBlockitemsUnionCreateManyInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<CBlockCreateLocalizationsInput>;
  name: Scalars['String'];
  skipDefaultLocale?: InputMaybe<Scalars['Boolean']>;
  tile?: InputMaybe<CTileCreateOneInlineInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CBlockCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CBlockCreateLocalizationInput = {
  /** Localization input */
  data: CBlockCreateLocalizationDataInput;
  locale: Locale;
};

export type CBlockCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<CBlockCreateLocalizationInput>>;
};

export type CBlockCreateManyInlineInput = {
  /** Connect multiple existing CBlock documents */
  connect?: InputMaybe<Array<CBlockWhereUniqueInput>>;
  /** Create and connect multiple existing CBlock documents */
  create?: InputMaybe<Array<CBlockCreateInput>>;
};

export type CBlockCreateOneInlineInput = {
  /** Connect one existing CBlock document */
  connect?: InputMaybe<CBlockWhereUniqueInput>;
  /** Create and connect one CBlock document */
  create?: InputMaybe<CBlockCreateInput>;
};

/** An edge in a connection. */
export type CBlockEdge = {
  __typename?: 'CBlockEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CBlock;
};

/** Identifies documents */
export type CBlockManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CBlockWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CBlockWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CBlockWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  box?: InputMaybe<CBoxWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<CBlockWhereStageInput>;
  documentInStages_none?: InputMaybe<CBlockWhereStageInput>;
  documentInStages_some?: InputMaybe<CBlockWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values in which the union is empty. */
  items_empty?: InputMaybe<Scalars['Boolean']>;
  /** Matches if the modular component contains at least one connection to the item provided to the filter */
  items_some?: InputMaybe<CBlockitemsUnionWhereInput>;
  name?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  skipDefaultLocale?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  skipDefaultLocale_not?: InputMaybe<Scalars['Boolean']>;
  tile?: InputMaybe<CTileWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum CBlockOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SkipDefaultLocaleAsc = 'skipDefaultLocale_ASC',
  SkipDefaultLocaleDesc = 'skipDefaultLocale_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type CBlockUpdateInput = {
  box?: InputMaybe<CBoxUpdateOneInlineInput>;
  clazfk1im1p7w01un6sywc0z4?: InputMaybe<CContainerUpdateManyInlineInput>;
  items?: InputMaybe<CBlockitemsUnionUpdateManyInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<CBlockUpdateLocalizationsInput>;
  name?: InputMaybe<Scalars['String']>;
  skipDefaultLocale?: InputMaybe<Scalars['Boolean']>;
  tile?: InputMaybe<CTileUpdateOneInlineInput>;
};

export type CBlockUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<CBlockCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
};

export type CBlockUpdateManyInlineInput = {
  /** Connect multiple existing CBlock documents */
  connect?: InputMaybe<Array<CBlockConnectInput>>;
  /** Create and connect multiple CBlock documents */
  create?: InputMaybe<Array<CBlockCreateInput>>;
  /** Delete multiple CBlock documents */
  delete?: InputMaybe<Array<CBlockWhereUniqueInput>>;
  /** Disconnect multiple CBlock documents */
  disconnect?: InputMaybe<Array<CBlockWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing CBlock documents */
  set?: InputMaybe<Array<CBlockWhereUniqueInput>>;
  /** Update multiple CBlock documents */
  update?: InputMaybe<Array<CBlockUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple CBlock documents */
  upsert?: InputMaybe<Array<CBlockUpsertWithNestedWhereUniqueInput>>;
};

export type CBlockUpdateManyInput = {
  name?: InputMaybe<Scalars['String']>;
  skipDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type CBlockUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CBlockUpdateManyInput;
  /** Document search */
  where: CBlockWhereInput;
};

export type CBlockUpdateOneInlineInput = {
  /** Connect existing CBlock document */
  connect?: InputMaybe<CBlockWhereUniqueInput>;
  /** Create and connect one CBlock document */
  create?: InputMaybe<CBlockCreateInput>;
  /** Delete currently connected CBlock document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected CBlock document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single CBlock document */
  update?: InputMaybe<CBlockUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CBlock document */
  upsert?: InputMaybe<CBlockUpsertWithNestedWhereUniqueInput>;
};

export type CBlockUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CBlockUpdateInput;
  /** Unique document search */
  where: CBlockWhereUniqueInput;
};

export type CBlockUpsertInput = {
  /** Create document if it didn't exist */
  create: CBlockCreateInput;
  /** Update document if it exists */
  update: CBlockUpdateInput;
};

export type CBlockUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CBlockUpsertInput;
  /** Unique document search */
  where: CBlockWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type CBlockWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type CBlockWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CBlockWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CBlockWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CBlockWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  box?: InputMaybe<CBoxWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<CBlockWhereStageInput>;
  documentInStages_none?: InputMaybe<CBlockWhereStageInput>;
  documentInStages_some?: InputMaybe<CBlockWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values in which the union is empty. */
  items_empty?: InputMaybe<Scalars['Boolean']>;
  /** Matches if the modular component contains at least one connection to the item provided to the filter */
  items_some?: InputMaybe<CBlockitemsUnionWhereInput>;
  name?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  skipDefaultLocale?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  skipDefaultLocale_not?: InputMaybe<Scalars['Boolean']>;
  tile?: InputMaybe<CTileWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type CBlockWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CBlockWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CBlockWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CBlockWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<CBlockWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References CBlock record uniquely */
export type CBlockWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type CBlockitemsUnion =
  | AccordionGroup
  | BannerItemList
  | CActionGroup
  | CAlert
  | CBanner
  | CCardGroup
  | CCustomComponent
  | CGallery
  | CHeading
  | CIconBlockGroup
  | CImageBlockGroup
  | CParagraph
  | CStepBlockGroup
  | CTestimonialGroup
  | Datalab
  | Disclosure
  | DocumentList
  | KeyStepsBlock
  | LatestArticlesByCategory
  | ProfileBio
  | RichTextEditorWrapper
  | TabGroup
  | TopicList
  | Video
  | VideoDialogGroup;

export type CBlockitemsUnionConnectInput = {
  AccordionGroup?: InputMaybe<AccordionGroupConnectInput>;
  BannerItemList?: InputMaybe<BannerItemListConnectInput>;
  CActionGroup?: InputMaybe<CActionGroupConnectInput>;
  CAlert?: InputMaybe<CAlertConnectInput>;
  CBanner?: InputMaybe<CBannerConnectInput>;
  CCardGroup?: InputMaybe<CCardGroupConnectInput>;
  CCustomComponent?: InputMaybe<CCustomComponentConnectInput>;
  CGallery?: InputMaybe<CGalleryConnectInput>;
  CHeading?: InputMaybe<CHeadingConnectInput>;
  CIconBlockGroup?: InputMaybe<CIconBlockGroupConnectInput>;
  CImageBlockGroup?: InputMaybe<CImageBlockGroupConnectInput>;
  CParagraph?: InputMaybe<CParagraphConnectInput>;
  CStepBlockGroup?: InputMaybe<CStepBlockGroupConnectInput>;
  CTestimonialGroup?: InputMaybe<CTestimonialGroupConnectInput>;
  Datalab?: InputMaybe<DatalabConnectInput>;
  Disclosure?: InputMaybe<DisclosureConnectInput>;
  DocumentList?: InputMaybe<DocumentListConnectInput>;
  KeyStepsBlock?: InputMaybe<KeyStepsBlockConnectInput>;
  LatestArticlesByCategory?: InputMaybe<LatestArticlesByCategoryConnectInput>;
  ProfileBio?: InputMaybe<ProfileBioConnectInput>;
  RichTextEditorWrapper?: InputMaybe<RichTextEditorWrapperConnectInput>;
  TabGroup?: InputMaybe<TabGroupConnectInput>;
  TopicList?: InputMaybe<TopicListConnectInput>;
  Video?: InputMaybe<VideoConnectInput>;
  VideoDialogGroup?: InputMaybe<VideoDialogGroupConnectInput>;
};

export type CBlockitemsUnionCreateInput = {
  AccordionGroup?: InputMaybe<AccordionGroupCreateInput>;
  BannerItemList?: InputMaybe<BannerItemListCreateInput>;
  CActionGroup?: InputMaybe<CActionGroupCreateInput>;
  CAlert?: InputMaybe<CAlertCreateInput>;
  CBanner?: InputMaybe<CBannerCreateInput>;
  CCardGroup?: InputMaybe<CCardGroupCreateInput>;
  CCustomComponent?: InputMaybe<CCustomComponentCreateInput>;
  CGallery?: InputMaybe<CGalleryCreateInput>;
  CHeading?: InputMaybe<CHeadingCreateInput>;
  CIconBlockGroup?: InputMaybe<CIconBlockGroupCreateInput>;
  CImageBlockGroup?: InputMaybe<CImageBlockGroupCreateInput>;
  CParagraph?: InputMaybe<CParagraphCreateInput>;
  CStepBlockGroup?: InputMaybe<CStepBlockGroupCreateInput>;
  CTestimonialGroup?: InputMaybe<CTestimonialGroupCreateInput>;
  Datalab?: InputMaybe<DatalabCreateInput>;
  Disclosure?: InputMaybe<DisclosureCreateInput>;
  DocumentList?: InputMaybe<DocumentListCreateInput>;
  KeyStepsBlock?: InputMaybe<KeyStepsBlockCreateInput>;
  LatestArticlesByCategory?: InputMaybe<LatestArticlesByCategoryCreateInput>;
  ProfileBio?: InputMaybe<ProfileBioCreateInput>;
  RichTextEditorWrapper?: InputMaybe<RichTextEditorWrapperCreateInput>;
  TabGroup?: InputMaybe<TabGroupCreateInput>;
  TopicList?: InputMaybe<TopicListCreateInput>;
  Video?: InputMaybe<VideoCreateInput>;
  VideoDialogGroup?: InputMaybe<VideoDialogGroupCreateInput>;
};

export type CBlockitemsUnionCreateManyInlineInput = {
  /** Create and connect multiple existing CBlockitemsUnion documents */
  create?: InputMaybe<Array<CBlockitemsUnionCreateInput>>;
};

export type CBlockitemsUnionCreateOneInlineInput = {
  /** Create and connect one CBlockitemsUnion document */
  create?: InputMaybe<CBlockitemsUnionCreateInput>;
};

export type CBlockitemsUnionCreateWithPositionInput = {
  AccordionGroup?: InputMaybe<AccordionGroupCreateWithPositionInput>;
  BannerItemList?: InputMaybe<BannerItemListCreateWithPositionInput>;
  CActionGroup?: InputMaybe<CActionGroupCreateWithPositionInput>;
  CAlert?: InputMaybe<CAlertCreateWithPositionInput>;
  CBanner?: InputMaybe<CBannerCreateWithPositionInput>;
  CCardGroup?: InputMaybe<CCardGroupCreateWithPositionInput>;
  CCustomComponent?: InputMaybe<CCustomComponentCreateWithPositionInput>;
  CGallery?: InputMaybe<CGalleryCreateWithPositionInput>;
  CHeading?: InputMaybe<CHeadingCreateWithPositionInput>;
  CIconBlockGroup?: InputMaybe<CIconBlockGroupCreateWithPositionInput>;
  CImageBlockGroup?: InputMaybe<CImageBlockGroupCreateWithPositionInput>;
  CParagraph?: InputMaybe<CParagraphCreateWithPositionInput>;
  CStepBlockGroup?: InputMaybe<CStepBlockGroupCreateWithPositionInput>;
  CTestimonialGroup?: InputMaybe<CTestimonialGroupCreateWithPositionInput>;
  Datalab?: InputMaybe<DatalabCreateWithPositionInput>;
  Disclosure?: InputMaybe<DisclosureCreateWithPositionInput>;
  DocumentList?: InputMaybe<DocumentListCreateWithPositionInput>;
  KeyStepsBlock?: InputMaybe<KeyStepsBlockCreateWithPositionInput>;
  LatestArticlesByCategory?: InputMaybe<LatestArticlesByCategoryCreateWithPositionInput>;
  ProfileBio?: InputMaybe<ProfileBioCreateWithPositionInput>;
  RichTextEditorWrapper?: InputMaybe<RichTextEditorWrapperCreateWithPositionInput>;
  TabGroup?: InputMaybe<TabGroupCreateWithPositionInput>;
  TopicList?: InputMaybe<TopicListCreateWithPositionInput>;
  Video?: InputMaybe<VideoCreateWithPositionInput>;
  VideoDialogGroup?: InputMaybe<VideoDialogGroupCreateWithPositionInput>;
};

export type CBlockitemsUnionUpdateInput = {
  AccordionGroup?: InputMaybe<AccordionGroupUpdateInput>;
  BannerItemList?: InputMaybe<BannerItemListUpdateInput>;
  CActionGroup?: InputMaybe<CActionGroupUpdateInput>;
  CAlert?: InputMaybe<CAlertUpdateInput>;
  CBanner?: InputMaybe<CBannerUpdateInput>;
  CCardGroup?: InputMaybe<CCardGroupUpdateInput>;
  CCustomComponent?: InputMaybe<CCustomComponentUpdateInput>;
  CGallery?: InputMaybe<CGalleryUpdateInput>;
  CHeading?: InputMaybe<CHeadingUpdateInput>;
  CIconBlockGroup?: InputMaybe<CIconBlockGroupUpdateInput>;
  CImageBlockGroup?: InputMaybe<CImageBlockGroupUpdateInput>;
  CParagraph?: InputMaybe<CParagraphUpdateInput>;
  CStepBlockGroup?: InputMaybe<CStepBlockGroupUpdateInput>;
  CTestimonialGroup?: InputMaybe<CTestimonialGroupUpdateInput>;
  Datalab?: InputMaybe<DatalabUpdateInput>;
  Disclosure?: InputMaybe<DisclosureUpdateInput>;
  DocumentList?: InputMaybe<DocumentListUpdateInput>;
  KeyStepsBlock?: InputMaybe<KeyStepsBlockUpdateInput>;
  LatestArticlesByCategory?: InputMaybe<LatestArticlesByCategoryUpdateInput>;
  ProfileBio?: InputMaybe<ProfileBioUpdateInput>;
  RichTextEditorWrapper?: InputMaybe<RichTextEditorWrapperUpdateInput>;
  TabGroup?: InputMaybe<TabGroupUpdateInput>;
  TopicList?: InputMaybe<TopicListUpdateInput>;
  Video?: InputMaybe<VideoUpdateInput>;
  VideoDialogGroup?: InputMaybe<VideoDialogGroupUpdateInput>;
};

export type CBlockitemsUnionUpdateManyInlineInput = {
  /** Create and connect multiple CBlockitemsUnion component instances */
  create?: InputMaybe<Array<CBlockitemsUnionCreateWithPositionInput>>;
  /** Delete multiple CBlockitemsUnion documents */
  delete?: InputMaybe<Array<CBlockitemsUnionWhereUniqueInput>>;
  /** Update multiple CBlockitemsUnion component instances */
  update?: InputMaybe<
    Array<CBlockitemsUnionUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CBlockitemsUnion component instances */
  upsert?: InputMaybe<
    Array<CBlockitemsUnionUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CBlockitemsUnionUpdateManyWithNestedWhereInput = {
  AccordionGroup?: InputMaybe<AccordionGroupUpdateManyWithNestedWhereInput>;
  BannerItemList?: InputMaybe<BannerItemListUpdateManyWithNestedWhereInput>;
  CActionGroup?: InputMaybe<CActionGroupUpdateManyWithNestedWhereInput>;
  CAlert?: InputMaybe<CAlertUpdateManyWithNestedWhereInput>;
  CBanner?: InputMaybe<CBannerUpdateManyWithNestedWhereInput>;
  CCardGroup?: InputMaybe<CCardGroupUpdateManyWithNestedWhereInput>;
  CCustomComponent?: InputMaybe<CCustomComponentUpdateManyWithNestedWhereInput>;
  CGallery?: InputMaybe<CGalleryUpdateManyWithNestedWhereInput>;
  CHeading?: InputMaybe<CHeadingUpdateManyWithNestedWhereInput>;
  CIconBlockGroup?: InputMaybe<CIconBlockGroupUpdateManyWithNestedWhereInput>;
  CImageBlockGroup?: InputMaybe<CImageBlockGroupUpdateManyWithNestedWhereInput>;
  CParagraph?: InputMaybe<CParagraphUpdateManyWithNestedWhereInput>;
  CStepBlockGroup?: InputMaybe<CStepBlockGroupUpdateManyWithNestedWhereInput>;
  CTestimonialGroup?: InputMaybe<CTestimonialGroupUpdateManyWithNestedWhereInput>;
  Datalab?: InputMaybe<DatalabUpdateManyWithNestedWhereInput>;
  Disclosure?: InputMaybe<DisclosureUpdateManyWithNestedWhereInput>;
  DocumentList?: InputMaybe<DocumentListUpdateManyWithNestedWhereInput>;
  KeyStepsBlock?: InputMaybe<KeyStepsBlockUpdateManyWithNestedWhereInput>;
  LatestArticlesByCategory?: InputMaybe<LatestArticlesByCategoryUpdateManyWithNestedWhereInput>;
  ProfileBio?: InputMaybe<ProfileBioUpdateManyWithNestedWhereInput>;
  RichTextEditorWrapper?: InputMaybe<RichTextEditorWrapperUpdateManyWithNestedWhereInput>;
  TabGroup?: InputMaybe<TabGroupUpdateManyWithNestedWhereInput>;
  TopicList?: InputMaybe<TopicListUpdateManyWithNestedWhereInput>;
  Video?: InputMaybe<VideoUpdateManyWithNestedWhereInput>;
  VideoDialogGroup?: InputMaybe<VideoDialogGroupUpdateManyWithNestedWhereInput>;
};

export type CBlockitemsUnionUpdateOneInlineInput = {
  /** Create and connect one CBlockitemsUnion document */
  create?: InputMaybe<CBlockitemsUnionCreateInput>;
  /** Delete currently connected CBlockitemsUnion document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CBlockitemsUnion document */
  update?: InputMaybe<CBlockitemsUnionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CBlockitemsUnion document */
  upsert?: InputMaybe<CBlockitemsUnionUpsertWithNestedWhereUniqueInput>;
};

export type CBlockitemsUnionUpdateWithNestedWhereUniqueAndPositionInput = {
  AccordionGroup?: InputMaybe<AccordionGroupUpdateWithNestedWhereUniqueAndPositionInput>;
  BannerItemList?: InputMaybe<BannerItemListUpdateWithNestedWhereUniqueAndPositionInput>;
  CActionGroup?: InputMaybe<CActionGroupUpdateWithNestedWhereUniqueAndPositionInput>;
  CAlert?: InputMaybe<CAlertUpdateWithNestedWhereUniqueAndPositionInput>;
  CBanner?: InputMaybe<CBannerUpdateWithNestedWhereUniqueAndPositionInput>;
  CCardGroup?: InputMaybe<CCardGroupUpdateWithNestedWhereUniqueAndPositionInput>;
  CCustomComponent?: InputMaybe<CCustomComponentUpdateWithNestedWhereUniqueAndPositionInput>;
  CGallery?: InputMaybe<CGalleryUpdateWithNestedWhereUniqueAndPositionInput>;
  CHeading?: InputMaybe<CHeadingUpdateWithNestedWhereUniqueAndPositionInput>;
  CIconBlockGroup?: InputMaybe<CIconBlockGroupUpdateWithNestedWhereUniqueAndPositionInput>;
  CImageBlockGroup?: InputMaybe<CImageBlockGroupUpdateWithNestedWhereUniqueAndPositionInput>;
  CParagraph?: InputMaybe<CParagraphUpdateWithNestedWhereUniqueAndPositionInput>;
  CStepBlockGroup?: InputMaybe<CStepBlockGroupUpdateWithNestedWhereUniqueAndPositionInput>;
  CTestimonialGroup?: InputMaybe<CTestimonialGroupUpdateWithNestedWhereUniqueAndPositionInput>;
  Datalab?: InputMaybe<DatalabUpdateWithNestedWhereUniqueAndPositionInput>;
  Disclosure?: InputMaybe<DisclosureUpdateWithNestedWhereUniqueAndPositionInput>;
  DocumentList?: InputMaybe<DocumentListUpdateWithNestedWhereUniqueAndPositionInput>;
  KeyStepsBlock?: InputMaybe<KeyStepsBlockUpdateWithNestedWhereUniqueAndPositionInput>;
  LatestArticlesByCategory?: InputMaybe<LatestArticlesByCategoryUpdateWithNestedWhereUniqueAndPositionInput>;
  ProfileBio?: InputMaybe<ProfileBioUpdateWithNestedWhereUniqueAndPositionInput>;
  RichTextEditorWrapper?: InputMaybe<RichTextEditorWrapperUpdateWithNestedWhereUniqueAndPositionInput>;
  TabGroup?: InputMaybe<TabGroupUpdateWithNestedWhereUniqueAndPositionInput>;
  TopicList?: InputMaybe<TopicListUpdateWithNestedWhereUniqueAndPositionInput>;
  Video?: InputMaybe<VideoUpdateWithNestedWhereUniqueAndPositionInput>;
  VideoDialogGroup?: InputMaybe<VideoDialogGroupUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type CBlockitemsUnionUpdateWithNestedWhereUniqueInput = {
  AccordionGroup?: InputMaybe<AccordionGroupUpdateWithNestedWhereUniqueInput>;
  BannerItemList?: InputMaybe<BannerItemListUpdateWithNestedWhereUniqueInput>;
  CActionGroup?: InputMaybe<CActionGroupUpdateWithNestedWhereUniqueInput>;
  CAlert?: InputMaybe<CAlertUpdateWithNestedWhereUniqueInput>;
  CBanner?: InputMaybe<CBannerUpdateWithNestedWhereUniqueInput>;
  CCardGroup?: InputMaybe<CCardGroupUpdateWithNestedWhereUniqueInput>;
  CCustomComponent?: InputMaybe<CCustomComponentUpdateWithNestedWhereUniqueInput>;
  CGallery?: InputMaybe<CGalleryUpdateWithNestedWhereUniqueInput>;
  CHeading?: InputMaybe<CHeadingUpdateWithNestedWhereUniqueInput>;
  CIconBlockGroup?: InputMaybe<CIconBlockGroupUpdateWithNestedWhereUniqueInput>;
  CImageBlockGroup?: InputMaybe<CImageBlockGroupUpdateWithNestedWhereUniqueInput>;
  CParagraph?: InputMaybe<CParagraphUpdateWithNestedWhereUniqueInput>;
  CStepBlockGroup?: InputMaybe<CStepBlockGroupUpdateWithNestedWhereUniqueInput>;
  CTestimonialGroup?: InputMaybe<CTestimonialGroupUpdateWithNestedWhereUniqueInput>;
  Datalab?: InputMaybe<DatalabUpdateWithNestedWhereUniqueInput>;
  Disclosure?: InputMaybe<DisclosureUpdateWithNestedWhereUniqueInput>;
  DocumentList?: InputMaybe<DocumentListUpdateWithNestedWhereUniqueInput>;
  KeyStepsBlock?: InputMaybe<KeyStepsBlockUpdateWithNestedWhereUniqueInput>;
  LatestArticlesByCategory?: InputMaybe<LatestArticlesByCategoryUpdateWithNestedWhereUniqueInput>;
  ProfileBio?: InputMaybe<ProfileBioUpdateWithNestedWhereUniqueInput>;
  RichTextEditorWrapper?: InputMaybe<RichTextEditorWrapperUpdateWithNestedWhereUniqueInput>;
  TabGroup?: InputMaybe<TabGroupUpdateWithNestedWhereUniqueInput>;
  TopicList?: InputMaybe<TopicListUpdateWithNestedWhereUniqueInput>;
  Video?: InputMaybe<VideoUpdateWithNestedWhereUniqueInput>;
  VideoDialogGroup?: InputMaybe<VideoDialogGroupUpdateWithNestedWhereUniqueInput>;
};

export type CBlockitemsUnionUpsertWithNestedWhereUniqueAndPositionInput = {
  AccordionGroup?: InputMaybe<AccordionGroupUpsertWithNestedWhereUniqueAndPositionInput>;
  BannerItemList?: InputMaybe<BannerItemListUpsertWithNestedWhereUniqueAndPositionInput>;
  CActionGroup?: InputMaybe<CActionGroupUpsertWithNestedWhereUniqueAndPositionInput>;
  CAlert?: InputMaybe<CAlertUpsertWithNestedWhereUniqueAndPositionInput>;
  CBanner?: InputMaybe<CBannerUpsertWithNestedWhereUniqueAndPositionInput>;
  CCardGroup?: InputMaybe<CCardGroupUpsertWithNestedWhereUniqueAndPositionInput>;
  CCustomComponent?: InputMaybe<CCustomComponentUpsertWithNestedWhereUniqueAndPositionInput>;
  CGallery?: InputMaybe<CGalleryUpsertWithNestedWhereUniqueAndPositionInput>;
  CHeading?: InputMaybe<CHeadingUpsertWithNestedWhereUniqueAndPositionInput>;
  CIconBlockGroup?: InputMaybe<CIconBlockGroupUpsertWithNestedWhereUniqueAndPositionInput>;
  CImageBlockGroup?: InputMaybe<CImageBlockGroupUpsertWithNestedWhereUniqueAndPositionInput>;
  CParagraph?: InputMaybe<CParagraphUpsertWithNestedWhereUniqueAndPositionInput>;
  CStepBlockGroup?: InputMaybe<CStepBlockGroupUpsertWithNestedWhereUniqueAndPositionInput>;
  CTestimonialGroup?: InputMaybe<CTestimonialGroupUpsertWithNestedWhereUniqueAndPositionInput>;
  Datalab?: InputMaybe<DatalabUpsertWithNestedWhereUniqueAndPositionInput>;
  Disclosure?: InputMaybe<DisclosureUpsertWithNestedWhereUniqueAndPositionInput>;
  DocumentList?: InputMaybe<DocumentListUpsertWithNestedWhereUniqueAndPositionInput>;
  KeyStepsBlock?: InputMaybe<KeyStepsBlockUpsertWithNestedWhereUniqueAndPositionInput>;
  LatestArticlesByCategory?: InputMaybe<LatestArticlesByCategoryUpsertWithNestedWhereUniqueAndPositionInput>;
  ProfileBio?: InputMaybe<ProfileBioUpsertWithNestedWhereUniqueAndPositionInput>;
  RichTextEditorWrapper?: InputMaybe<RichTextEditorWrapperUpsertWithNestedWhereUniqueAndPositionInput>;
  TabGroup?: InputMaybe<TabGroupUpsertWithNestedWhereUniqueAndPositionInput>;
  TopicList?: InputMaybe<TopicListUpsertWithNestedWhereUniqueAndPositionInput>;
  Video?: InputMaybe<VideoUpsertWithNestedWhereUniqueAndPositionInput>;
  VideoDialogGroup?: InputMaybe<VideoDialogGroupUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type CBlockitemsUnionUpsertWithNestedWhereUniqueInput = {
  AccordionGroup?: InputMaybe<AccordionGroupUpsertWithNestedWhereUniqueInput>;
  BannerItemList?: InputMaybe<BannerItemListUpsertWithNestedWhereUniqueInput>;
  CActionGroup?: InputMaybe<CActionGroupUpsertWithNestedWhereUniqueInput>;
  CAlert?: InputMaybe<CAlertUpsertWithNestedWhereUniqueInput>;
  CBanner?: InputMaybe<CBannerUpsertWithNestedWhereUniqueInput>;
  CCardGroup?: InputMaybe<CCardGroupUpsertWithNestedWhereUniqueInput>;
  CCustomComponent?: InputMaybe<CCustomComponentUpsertWithNestedWhereUniqueInput>;
  CGallery?: InputMaybe<CGalleryUpsertWithNestedWhereUniqueInput>;
  CHeading?: InputMaybe<CHeadingUpsertWithNestedWhereUniqueInput>;
  CIconBlockGroup?: InputMaybe<CIconBlockGroupUpsertWithNestedWhereUniqueInput>;
  CImageBlockGroup?: InputMaybe<CImageBlockGroupUpsertWithNestedWhereUniqueInput>;
  CParagraph?: InputMaybe<CParagraphUpsertWithNestedWhereUniqueInput>;
  CStepBlockGroup?: InputMaybe<CStepBlockGroupUpsertWithNestedWhereUniqueInput>;
  CTestimonialGroup?: InputMaybe<CTestimonialGroupUpsertWithNestedWhereUniqueInput>;
  Datalab?: InputMaybe<DatalabUpsertWithNestedWhereUniqueInput>;
  Disclosure?: InputMaybe<DisclosureUpsertWithNestedWhereUniqueInput>;
  DocumentList?: InputMaybe<DocumentListUpsertWithNestedWhereUniqueInput>;
  KeyStepsBlock?: InputMaybe<KeyStepsBlockUpsertWithNestedWhereUniqueInput>;
  LatestArticlesByCategory?: InputMaybe<LatestArticlesByCategoryUpsertWithNestedWhereUniqueInput>;
  ProfileBio?: InputMaybe<ProfileBioUpsertWithNestedWhereUniqueInput>;
  RichTextEditorWrapper?: InputMaybe<RichTextEditorWrapperUpsertWithNestedWhereUniqueInput>;
  TabGroup?: InputMaybe<TabGroupUpsertWithNestedWhereUniqueInput>;
  TopicList?: InputMaybe<TopicListUpsertWithNestedWhereUniqueInput>;
  Video?: InputMaybe<VideoUpsertWithNestedWhereUniqueInput>;
  VideoDialogGroup?: InputMaybe<VideoDialogGroupUpsertWithNestedWhereUniqueInput>;
};

export type CBlockitemsUnionWhereInput = {
  AccordionGroup?: InputMaybe<AccordionGroupWhereInput>;
  BannerItemList?: InputMaybe<BannerItemListWhereInput>;
  CActionGroup?: InputMaybe<CActionGroupWhereInput>;
  CAlert?: InputMaybe<CAlertWhereInput>;
  CBanner?: InputMaybe<CBannerWhereInput>;
  CCardGroup?: InputMaybe<CCardGroupWhereInput>;
  CCustomComponent?: InputMaybe<CCustomComponentWhereInput>;
  CGallery?: InputMaybe<CGalleryWhereInput>;
  CHeading?: InputMaybe<CHeadingWhereInput>;
  CIconBlockGroup?: InputMaybe<CIconBlockGroupWhereInput>;
  CImageBlockGroup?: InputMaybe<CImageBlockGroupWhereInput>;
  CParagraph?: InputMaybe<CParagraphWhereInput>;
  CStepBlockGroup?: InputMaybe<CStepBlockGroupWhereInput>;
  CTestimonialGroup?: InputMaybe<CTestimonialGroupWhereInput>;
  Datalab?: InputMaybe<DatalabWhereInput>;
  Disclosure?: InputMaybe<DisclosureWhereInput>;
  DocumentList?: InputMaybe<DocumentListWhereInput>;
  KeyStepsBlock?: InputMaybe<KeyStepsBlockWhereInput>;
  LatestArticlesByCategory?: InputMaybe<LatestArticlesByCategoryWhereInput>;
  ProfileBio?: InputMaybe<ProfileBioWhereInput>;
  RichTextEditorWrapper?: InputMaybe<RichTextEditorWrapperWhereInput>;
  TabGroup?: InputMaybe<TabGroupWhereInput>;
  TopicList?: InputMaybe<TopicListWhereInput>;
  Video?: InputMaybe<VideoWhereInput>;
  VideoDialogGroup?: InputMaybe<VideoDialogGroupWhereInput>;
};

export type CBlockitemsUnionWhereUniqueInput = {
  AccordionGroup?: InputMaybe<AccordionGroupWhereUniqueInput>;
  BannerItemList?: InputMaybe<BannerItemListWhereUniqueInput>;
  CActionGroup?: InputMaybe<CActionGroupWhereUniqueInput>;
  CAlert?: InputMaybe<CAlertWhereUniqueInput>;
  CBanner?: InputMaybe<CBannerWhereUniqueInput>;
  CCardGroup?: InputMaybe<CCardGroupWhereUniqueInput>;
  CCustomComponent?: InputMaybe<CCustomComponentWhereUniqueInput>;
  CGallery?: InputMaybe<CGalleryWhereUniqueInput>;
  CHeading?: InputMaybe<CHeadingWhereUniqueInput>;
  CIconBlockGroup?: InputMaybe<CIconBlockGroupWhereUniqueInput>;
  CImageBlockGroup?: InputMaybe<CImageBlockGroupWhereUniqueInput>;
  CParagraph?: InputMaybe<CParagraphWhereUniqueInput>;
  CStepBlockGroup?: InputMaybe<CStepBlockGroupWhereUniqueInput>;
  CTestimonialGroup?: InputMaybe<CTestimonialGroupWhereUniqueInput>;
  Datalab?: InputMaybe<DatalabWhereUniqueInput>;
  Disclosure?: InputMaybe<DisclosureWhereUniqueInput>;
  DocumentList?: InputMaybe<DocumentListWhereUniqueInput>;
  KeyStepsBlock?: InputMaybe<KeyStepsBlockWhereUniqueInput>;
  LatestArticlesByCategory?: InputMaybe<LatestArticlesByCategoryWhereUniqueInput>;
  ProfileBio?: InputMaybe<ProfileBioWhereUniqueInput>;
  RichTextEditorWrapper?: InputMaybe<RichTextEditorWrapperWhereUniqueInput>;
  TabGroup?: InputMaybe<TabGroupWhereUniqueInput>;
  TopicList?: InputMaybe<TopicListWhereUniqueInput>;
  Video?: InputMaybe<VideoWhereUniqueInput>;
  VideoDialogGroup?: InputMaybe<VideoDialogGroupWhereUniqueInput>;
};

export type CBox = Entity & {
  __typename?: 'CBox';
  background?: Maybe<CBoxBackground>;
  /** The border style on the box */
  blockBorder?: Maybe<BoxShadows>;
  /** Choose how rounded the box border should be. */
  borderRadius?: Maybe<BorderRadius>;
  customDecoration?: Maybe<CustomDecoration>;
  display?: Maybe<BoxDisplay>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** Set "Display" to "flex" for these values to work. */
  justifyContent?: Maybe<BoxJustifyContent>;
  /** The vertical padding on the box */
  paddingHeight?: Maybe<BraidSizes>;
  /** The horizontal padding on the box */
  paddingWidth?: Maybe<BraidSizes>;
  /** System stage field */
  stage: Stage;
  textAlign?: Maybe<CTextAlign>;
  /** Maximum content width */
  width?: Maybe<Width>;
};

export enum CBoxBackground {
  Body = 'body',
  Brand = 'brand',
  BrandAccent = 'brandAccent',
  BrandAccentSoft = 'brandAccentSoft',
  InfoLight = 'infoLight',
  InfoLightNeutral = 'infoLightNeutral',
  LightCyan = 'lightCyan',
  LightPurple = 'lightPurple',
  LightTurquoise = 'lightTurquoise',
  Link600 = 'link600',
  NeutralLight = 'neutralLight',
  SeekBlueLight800 = 'seekBlueLight800',
  Surface = 'surface',
  Teal = 'teal',
  Violet = 'violet',
}

export type CBoxConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CBoxWhereUniqueInput;
};

/** A connection to a list of items. */
export type CBoxConnection = {
  __typename?: 'CBoxConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CBoxEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CBoxCreateInput = {
  background?: InputMaybe<CBoxBackground>;
  blockBorder?: InputMaybe<BoxShadows>;
  borderRadius?: InputMaybe<BorderRadius>;
  customDecoration?: InputMaybe<CustomDecoration>;
  display?: InputMaybe<BoxDisplay>;
  justifyContent?: InputMaybe<BoxJustifyContent>;
  paddingHeight?: InputMaybe<BraidSizes>;
  paddingWidth?: InputMaybe<BraidSizes>;
  textAlign?: InputMaybe<CTextAlign>;
  width?: InputMaybe<Width>;
};

export type CBoxCreateManyInlineInput = {
  /** Create and connect multiple existing CBox documents */
  create?: InputMaybe<Array<CBoxCreateInput>>;
};

export type CBoxCreateOneInlineInput = {
  /** Create and connect one CBox document */
  create?: InputMaybe<CBoxCreateInput>;
};

export type CBoxCreateWithPositionInput = {
  /** Document to create */
  data: CBoxCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type CBoxEdge = {
  __typename?: 'CBoxEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CBox;
};

/** Identifies documents */
export type CBoxManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CBoxWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CBoxWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CBoxWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  background?: InputMaybe<CBoxBackground>;
  /** All values that are contained in given list. */
  background_in?: InputMaybe<Array<InputMaybe<CBoxBackground>>>;
  /** Any other value that exists and is not equal to the given value. */
  background_not?: InputMaybe<CBoxBackground>;
  /** All values that are not contained in given list. */
  background_not_in?: InputMaybe<Array<InputMaybe<CBoxBackground>>>;
  blockBorder?: InputMaybe<BoxShadows>;
  /** All values that are contained in given list. */
  blockBorder_in?: InputMaybe<Array<InputMaybe<BoxShadows>>>;
  /** Any other value that exists and is not equal to the given value. */
  blockBorder_not?: InputMaybe<BoxShadows>;
  /** All values that are not contained in given list. */
  blockBorder_not_in?: InputMaybe<Array<InputMaybe<BoxShadows>>>;
  borderRadius?: InputMaybe<BorderRadius>;
  /** All values that are contained in given list. */
  borderRadius_in?: InputMaybe<Array<InputMaybe<BorderRadius>>>;
  /** Any other value that exists and is not equal to the given value. */
  borderRadius_not?: InputMaybe<BorderRadius>;
  /** All values that are not contained in given list. */
  borderRadius_not_in?: InputMaybe<Array<InputMaybe<BorderRadius>>>;
  customDecoration?: InputMaybe<CustomDecoration>;
  /** All values that are contained in given list. */
  customDecoration_in?: InputMaybe<Array<InputMaybe<CustomDecoration>>>;
  /** Any other value that exists and is not equal to the given value. */
  customDecoration_not?: InputMaybe<CustomDecoration>;
  /** All values that are not contained in given list. */
  customDecoration_not_in?: InputMaybe<Array<InputMaybe<CustomDecoration>>>;
  display?: InputMaybe<BoxDisplay>;
  /** All values that are contained in given list. */
  display_in?: InputMaybe<Array<InputMaybe<BoxDisplay>>>;
  /** Any other value that exists and is not equal to the given value. */
  display_not?: InputMaybe<BoxDisplay>;
  /** All values that are not contained in given list. */
  display_not_in?: InputMaybe<Array<InputMaybe<BoxDisplay>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  justifyContent?: InputMaybe<BoxJustifyContent>;
  /** All values that are contained in given list. */
  justifyContent_in?: InputMaybe<Array<InputMaybe<BoxJustifyContent>>>;
  /** Any other value that exists and is not equal to the given value. */
  justifyContent_not?: InputMaybe<BoxJustifyContent>;
  /** All values that are not contained in given list. */
  justifyContent_not_in?: InputMaybe<Array<InputMaybe<BoxJustifyContent>>>;
  paddingHeight?: InputMaybe<BraidSizes>;
  /** All values that are contained in given list. */
  paddingHeight_in?: InputMaybe<Array<InputMaybe<BraidSizes>>>;
  /** Any other value that exists and is not equal to the given value. */
  paddingHeight_not?: InputMaybe<BraidSizes>;
  /** All values that are not contained in given list. */
  paddingHeight_not_in?: InputMaybe<Array<InputMaybe<BraidSizes>>>;
  paddingWidth?: InputMaybe<BraidSizes>;
  /** All values that are contained in given list. */
  paddingWidth_in?: InputMaybe<Array<InputMaybe<BraidSizes>>>;
  /** Any other value that exists and is not equal to the given value. */
  paddingWidth_not?: InputMaybe<BraidSizes>;
  /** All values that are not contained in given list. */
  paddingWidth_not_in?: InputMaybe<Array<InputMaybe<BraidSizes>>>;
  textAlign?: InputMaybe<CTextAlign>;
  /** All values that are contained in given list. */
  textAlign_in?: InputMaybe<Array<InputMaybe<CTextAlign>>>;
  /** Any other value that exists and is not equal to the given value. */
  textAlign_not?: InputMaybe<CTextAlign>;
  /** All values that are not contained in given list. */
  textAlign_not_in?: InputMaybe<Array<InputMaybe<CTextAlign>>>;
  width?: InputMaybe<Width>;
  /** All values that are contained in given list. */
  width_in?: InputMaybe<Array<InputMaybe<Width>>>;
  /** Any other value that exists and is not equal to the given value. */
  width_not?: InputMaybe<Width>;
  /** All values that are not contained in given list. */
  width_not_in?: InputMaybe<Array<InputMaybe<Width>>>;
};

export enum CBoxOrderByInput {
  BackgroundAsc = 'background_ASC',
  BackgroundDesc = 'background_DESC',
  BlockBorderAsc = 'blockBorder_ASC',
  BlockBorderDesc = 'blockBorder_DESC',
  BorderRadiusAsc = 'borderRadius_ASC',
  BorderRadiusDesc = 'borderRadius_DESC',
  CustomDecorationAsc = 'customDecoration_ASC',
  CustomDecorationDesc = 'customDecoration_DESC',
  DisplayAsc = 'display_ASC',
  DisplayDesc = 'display_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  JustifyContentAsc = 'justifyContent_ASC',
  JustifyContentDesc = 'justifyContent_DESC',
  PaddingHeightAsc = 'paddingHeight_ASC',
  PaddingHeightDesc = 'paddingHeight_DESC',
  PaddingWidthAsc = 'paddingWidth_ASC',
  PaddingWidthDesc = 'paddingWidth_DESC',
  TextAlignAsc = 'textAlign_ASC',
  TextAlignDesc = 'textAlign_DESC',
  WidthAsc = 'width_ASC',
  WidthDesc = 'width_DESC',
}

export type CBoxParent =
  | ArticleAdditionalContent
  | CBlock
  | CContainer
  | CImageBlockGroup
  | Video;

export type CBoxParentConnectInput = {
  ArticleAdditionalContent?: InputMaybe<ArticleAdditionalContentConnectInput>;
  CBlock?: InputMaybe<CBlockConnectInput>;
  CContainer?: InputMaybe<CContainerConnectInput>;
  CImageBlockGroup?: InputMaybe<CImageBlockGroupConnectInput>;
  Video?: InputMaybe<VideoConnectInput>;
};

export type CBoxParentCreateInput = {
  ArticleAdditionalContent?: InputMaybe<ArticleAdditionalContentCreateInput>;
  CBlock?: InputMaybe<CBlockCreateInput>;
  CContainer?: InputMaybe<CContainerCreateInput>;
  CImageBlockGroup?: InputMaybe<CImageBlockGroupCreateInput>;
  Video?: InputMaybe<VideoCreateInput>;
};

export type CBoxParentCreateManyInlineInput = {
  /** Connect multiple existing CBoxParent documents */
  connect?: InputMaybe<Array<CBoxParentWhereUniqueInput>>;
  /** Create and connect multiple existing CBoxParent documents */
  create?: InputMaybe<Array<CBoxParentCreateInput>>;
};

export type CBoxParentCreateOneInlineInput = {
  /** Connect one existing CBoxParent document */
  connect?: InputMaybe<CBoxParentWhereUniqueInput>;
  /** Create and connect one CBoxParent document */
  create?: InputMaybe<CBoxParentCreateInput>;
};

export type CBoxParentUpdateInput = {
  ArticleAdditionalContent?: InputMaybe<ArticleAdditionalContentUpdateInput>;
  CBlock?: InputMaybe<CBlockUpdateInput>;
  CContainer?: InputMaybe<CContainerUpdateInput>;
  CImageBlockGroup?: InputMaybe<CImageBlockGroupUpdateInput>;
  Video?: InputMaybe<VideoUpdateInput>;
};

export type CBoxParentUpdateManyInlineInput = {
  /** Connect multiple existing CBoxParent documents */
  connect?: InputMaybe<Array<CBoxParentConnectInput>>;
  /** Create and connect multiple CBoxParent documents */
  create?: InputMaybe<Array<CBoxParentCreateInput>>;
  /** Delete multiple CBoxParent documents */
  delete?: InputMaybe<Array<CBoxParentWhereUniqueInput>>;
  /** Disconnect multiple CBoxParent documents */
  disconnect?: InputMaybe<Array<CBoxParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing CBoxParent documents */
  set?: InputMaybe<Array<CBoxParentWhereUniqueInput>>;
  /** Update multiple CBoxParent documents */
  update?: InputMaybe<Array<CBoxParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple CBoxParent documents */
  upsert?: InputMaybe<Array<CBoxParentUpsertWithNestedWhereUniqueInput>>;
};

export type CBoxParentUpdateManyWithNestedWhereInput = {
  ArticleAdditionalContent?: InputMaybe<ArticleAdditionalContentUpdateManyWithNestedWhereInput>;
  CBlock?: InputMaybe<CBlockUpdateManyWithNestedWhereInput>;
  CContainer?: InputMaybe<CContainerUpdateManyWithNestedWhereInput>;
  CImageBlockGroup?: InputMaybe<CImageBlockGroupUpdateManyWithNestedWhereInput>;
  Video?: InputMaybe<VideoUpdateManyWithNestedWhereInput>;
};

export type CBoxParentUpdateOneInlineInput = {
  /** Connect existing CBoxParent document */
  connect?: InputMaybe<CBoxParentWhereUniqueInput>;
  /** Create and connect one CBoxParent document */
  create?: InputMaybe<CBoxParentCreateInput>;
  /** Delete currently connected CBoxParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected CBoxParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single CBoxParent document */
  update?: InputMaybe<CBoxParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CBoxParent document */
  upsert?: InputMaybe<CBoxParentUpsertWithNestedWhereUniqueInput>;
};

export type CBoxParentUpdateWithNestedWhereUniqueInput = {
  ArticleAdditionalContent?: InputMaybe<ArticleAdditionalContentUpdateWithNestedWhereUniqueInput>;
  CBlock?: InputMaybe<CBlockUpdateWithNestedWhereUniqueInput>;
  CContainer?: InputMaybe<CContainerUpdateWithNestedWhereUniqueInput>;
  CImageBlockGroup?: InputMaybe<CImageBlockGroupUpdateWithNestedWhereUniqueInput>;
  Video?: InputMaybe<VideoUpdateWithNestedWhereUniqueInput>;
};

export type CBoxParentUpsertWithNestedWhereUniqueInput = {
  ArticleAdditionalContent?: InputMaybe<ArticleAdditionalContentUpsertWithNestedWhereUniqueInput>;
  CBlock?: InputMaybe<CBlockUpsertWithNestedWhereUniqueInput>;
  CContainer?: InputMaybe<CContainerUpsertWithNestedWhereUniqueInput>;
  CImageBlockGroup?: InputMaybe<CImageBlockGroupUpsertWithNestedWhereUniqueInput>;
  Video?: InputMaybe<VideoUpsertWithNestedWhereUniqueInput>;
};

export type CBoxParentWhereInput = {
  ArticleAdditionalContent?: InputMaybe<ArticleAdditionalContentWhereInput>;
  CBlock?: InputMaybe<CBlockWhereInput>;
  CContainer?: InputMaybe<CContainerWhereInput>;
  CImageBlockGroup?: InputMaybe<CImageBlockGroupWhereInput>;
  Video?: InputMaybe<VideoWhereInput>;
};

export type CBoxParentWhereUniqueInput = {
  ArticleAdditionalContent?: InputMaybe<ArticleAdditionalContentWhereUniqueInput>;
  CBlock?: InputMaybe<CBlockWhereUniqueInput>;
  CContainer?: InputMaybe<CContainerWhereUniqueInput>;
  CImageBlockGroup?: InputMaybe<CImageBlockGroupWhereUniqueInput>;
  Video?: InputMaybe<VideoWhereUniqueInput>;
};

export type CBoxUpdateInput = {
  background?: InputMaybe<CBoxBackground>;
  blockBorder?: InputMaybe<BoxShadows>;
  borderRadius?: InputMaybe<BorderRadius>;
  customDecoration?: InputMaybe<CustomDecoration>;
  display?: InputMaybe<BoxDisplay>;
  justifyContent?: InputMaybe<BoxJustifyContent>;
  paddingHeight?: InputMaybe<BraidSizes>;
  paddingWidth?: InputMaybe<BraidSizes>;
  textAlign?: InputMaybe<CTextAlign>;
  width?: InputMaybe<Width>;
};

export type CBoxUpdateManyInlineInput = {
  /** Create and connect multiple CBox component instances */
  create?: InputMaybe<Array<CBoxCreateWithPositionInput>>;
  /** Delete multiple CBox documents */
  delete?: InputMaybe<Array<CBoxWhereUniqueInput>>;
  /** Update multiple CBox component instances */
  update?: InputMaybe<Array<CBoxUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple CBox component instances */
  upsert?: InputMaybe<Array<CBoxUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type CBoxUpdateManyInput = {
  background?: InputMaybe<CBoxBackground>;
  blockBorder?: InputMaybe<BoxShadows>;
  borderRadius?: InputMaybe<BorderRadius>;
  customDecoration?: InputMaybe<CustomDecoration>;
  display?: InputMaybe<BoxDisplay>;
  justifyContent?: InputMaybe<BoxJustifyContent>;
  paddingHeight?: InputMaybe<BraidSizes>;
  paddingWidth?: InputMaybe<BraidSizes>;
  textAlign?: InputMaybe<CTextAlign>;
  width?: InputMaybe<Width>;
};

export type CBoxUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CBoxUpdateManyInput;
  /** Document search */
  where: CBoxWhereInput;
};

export type CBoxUpdateOneInlineInput = {
  /** Create and connect one CBox document */
  create?: InputMaybe<CBoxCreateInput>;
  /** Delete currently connected CBox document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CBox document */
  update?: InputMaybe<CBoxUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CBox document */
  upsert?: InputMaybe<CBoxUpsertWithNestedWhereUniqueInput>;
};

export type CBoxUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<CBoxUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CBoxWhereUniqueInput;
};

export type CBoxUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CBoxUpdateInput;
  /** Unique document search */
  where: CBoxWhereUniqueInput;
};

export type CBoxUpsertInput = {
  /** Create document if it didn't exist */
  create: CBoxCreateInput;
  /** Update document if it exists */
  update: CBoxUpdateInput;
};

export type CBoxUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<CBoxUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CBoxWhereUniqueInput;
};

export type CBoxUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CBoxUpsertInput;
  /** Unique document search */
  where: CBoxWhereUniqueInput;
};

/** Identifies documents */
export type CBoxWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CBoxWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CBoxWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CBoxWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  background?: InputMaybe<CBoxBackground>;
  /** All values that are contained in given list. */
  background_in?: InputMaybe<Array<InputMaybe<CBoxBackground>>>;
  /** Any other value that exists and is not equal to the given value. */
  background_not?: InputMaybe<CBoxBackground>;
  /** All values that are not contained in given list. */
  background_not_in?: InputMaybe<Array<InputMaybe<CBoxBackground>>>;
  blockBorder?: InputMaybe<BoxShadows>;
  /** All values that are contained in given list. */
  blockBorder_in?: InputMaybe<Array<InputMaybe<BoxShadows>>>;
  /** Any other value that exists and is not equal to the given value. */
  blockBorder_not?: InputMaybe<BoxShadows>;
  /** All values that are not contained in given list. */
  blockBorder_not_in?: InputMaybe<Array<InputMaybe<BoxShadows>>>;
  borderRadius?: InputMaybe<BorderRadius>;
  /** All values that are contained in given list. */
  borderRadius_in?: InputMaybe<Array<InputMaybe<BorderRadius>>>;
  /** Any other value that exists and is not equal to the given value. */
  borderRadius_not?: InputMaybe<BorderRadius>;
  /** All values that are not contained in given list. */
  borderRadius_not_in?: InputMaybe<Array<InputMaybe<BorderRadius>>>;
  customDecoration?: InputMaybe<CustomDecoration>;
  /** All values that are contained in given list. */
  customDecoration_in?: InputMaybe<Array<InputMaybe<CustomDecoration>>>;
  /** Any other value that exists and is not equal to the given value. */
  customDecoration_not?: InputMaybe<CustomDecoration>;
  /** All values that are not contained in given list. */
  customDecoration_not_in?: InputMaybe<Array<InputMaybe<CustomDecoration>>>;
  display?: InputMaybe<BoxDisplay>;
  /** All values that are contained in given list. */
  display_in?: InputMaybe<Array<InputMaybe<BoxDisplay>>>;
  /** Any other value that exists and is not equal to the given value. */
  display_not?: InputMaybe<BoxDisplay>;
  /** All values that are not contained in given list. */
  display_not_in?: InputMaybe<Array<InputMaybe<BoxDisplay>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  justifyContent?: InputMaybe<BoxJustifyContent>;
  /** All values that are contained in given list. */
  justifyContent_in?: InputMaybe<Array<InputMaybe<BoxJustifyContent>>>;
  /** Any other value that exists and is not equal to the given value. */
  justifyContent_not?: InputMaybe<BoxJustifyContent>;
  /** All values that are not contained in given list. */
  justifyContent_not_in?: InputMaybe<Array<InputMaybe<BoxJustifyContent>>>;
  paddingHeight?: InputMaybe<BraidSizes>;
  /** All values that are contained in given list. */
  paddingHeight_in?: InputMaybe<Array<InputMaybe<BraidSizes>>>;
  /** Any other value that exists and is not equal to the given value. */
  paddingHeight_not?: InputMaybe<BraidSizes>;
  /** All values that are not contained in given list. */
  paddingHeight_not_in?: InputMaybe<Array<InputMaybe<BraidSizes>>>;
  paddingWidth?: InputMaybe<BraidSizes>;
  /** All values that are contained in given list. */
  paddingWidth_in?: InputMaybe<Array<InputMaybe<BraidSizes>>>;
  /** Any other value that exists and is not equal to the given value. */
  paddingWidth_not?: InputMaybe<BraidSizes>;
  /** All values that are not contained in given list. */
  paddingWidth_not_in?: InputMaybe<Array<InputMaybe<BraidSizes>>>;
  textAlign?: InputMaybe<CTextAlign>;
  /** All values that are contained in given list. */
  textAlign_in?: InputMaybe<Array<InputMaybe<CTextAlign>>>;
  /** Any other value that exists and is not equal to the given value. */
  textAlign_not?: InputMaybe<CTextAlign>;
  /** All values that are not contained in given list. */
  textAlign_not_in?: InputMaybe<Array<InputMaybe<CTextAlign>>>;
  width?: InputMaybe<Width>;
  /** All values that are contained in given list. */
  width_in?: InputMaybe<Array<InputMaybe<Width>>>;
  /** Any other value that exists and is not equal to the given value. */
  width_not?: InputMaybe<Width>;
  /** All values that are not contained in given list. */
  width_not_in?: InputMaybe<Array<InputMaybe<Width>>>;
};

/** References CBox record uniquely */
export type CBoxWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type CButtonLink = Entity &
  Node & {
    __typename?: 'CButtonLink';
    /** The time the document was created */
    createdAt: Scalars['DateTime'];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<CButtonLink>;
    /** List of CButtonLink versions */
    history: Array<Version>;
    /** Icon for Button Link. */
    icon?: Maybe<Icon>;
    /** Choose position to display icon. */
    iconPosition?: Maybe<IconPosition>;
    /** The unique identifier */
    id: Scalars['ID'];
    link?: Maybe<CButtonLinklinkUnion>;
    /** System Locale field */
    locale: Locale;
    /** Get the other localizations for this document */
    localizations: Array<CButtonLink>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    size?: Maybe<CButtonLinkSize>;
    /** System stage field */
    stage: Stage;
    text: Scalars['String'];
    tone?: Maybe<CButtonLinkTone>;
    /** The time the document was updated */
    updatedAt: Scalars['DateTime'];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
    variant?: Maybe<CButtonVariant>;
  };

export type CButtonLinkCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type CButtonLinkCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CButtonLinkDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type CButtonLinkHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};

export type CButtonLinkLinkArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CButtonLinkLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type CButtonLinkPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type CButtonLinkPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CButtonLinkScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type CButtonLinkUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type CButtonLinkUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CButtonLinkConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CButtonLinkWhereUniqueInput;
};

/** A connection to a list of items. */
export type CButtonLinkConnection = {
  __typename?: 'CButtonLinkConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CButtonLinkEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CButtonLinkCreateInput = {
  cly2hlg5q31xz06tf7om86ghf?: InputMaybe<CImageBlockCreateManyInlineInput>;
  cm1p9lw3m03s107tchk7w41h4?: InputMaybe<TipsCardCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  icon?: InputMaybe<Icon>;
  iconPosition?: InputMaybe<IconPosition>;
  link?: InputMaybe<CButtonLinklinkUnionCreateOneInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<CButtonLinkCreateLocalizationsInput>;
  size?: InputMaybe<CButtonLinkSize>;
  /** text input for default locale (default) */
  text: Scalars['String'];
  tone?: InputMaybe<CButtonLinkTone>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  variant?: InputMaybe<CButtonVariant>;
};

export type CButtonLinkCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  text: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CButtonLinkCreateLocalizationInput = {
  /** Localization input */
  data: CButtonLinkCreateLocalizationDataInput;
  locale: Locale;
};

export type CButtonLinkCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<CButtonLinkCreateLocalizationInput>>;
};

export type CButtonLinkCreateManyInlineInput = {
  /** Connect multiple existing CButtonLink documents */
  connect?: InputMaybe<Array<CButtonLinkWhereUniqueInput>>;
  /** Create and connect multiple existing CButtonLink documents */
  create?: InputMaybe<Array<CButtonLinkCreateInput>>;
};

export type CButtonLinkCreateOneInlineInput = {
  /** Connect one existing CButtonLink document */
  connect?: InputMaybe<CButtonLinkWhereUniqueInput>;
  /** Create and connect one CButtonLink document */
  create?: InputMaybe<CButtonLinkCreateInput>;
};

/** An edge in a connection. */
export type CButtonLinkEdge = {
  __typename?: 'CButtonLinkEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CButtonLink;
};

/** Identifies documents */
export type CButtonLinkManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CButtonLinkWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CButtonLinkWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CButtonLinkWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<CButtonLinkWhereStageInput>;
  documentInStages_none?: InputMaybe<CButtonLinkWhereStageInput>;
  documentInStages_some?: InputMaybe<CButtonLinkWhereStageInput>;
  icon?: InputMaybe<Icon>;
  iconPosition?: InputMaybe<IconPosition>;
  /** All values that are contained in given list. */
  iconPosition_in?: InputMaybe<Array<InputMaybe<IconPosition>>>;
  /** Any other value that exists and is not equal to the given value. */
  iconPosition_not?: InputMaybe<IconPosition>;
  /** All values that are not contained in given list. */
  iconPosition_not_in?: InputMaybe<Array<InputMaybe<IconPosition>>>;
  /** All values that are contained in given list. */
  icon_in?: InputMaybe<Array<InputMaybe<Icon>>>;
  /** Any other value that exists and is not equal to the given value. */
  icon_not?: InputMaybe<Icon>;
  /** All values that are not contained in given list. */
  icon_not_in?: InputMaybe<Array<InputMaybe<Icon>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values in which the modular component is connected to the given models */
  link?: InputMaybe<CButtonLinklinkUnionWhereInput>;
  /** All values in which the union is empty. */
  link_empty?: InputMaybe<Scalars['Boolean']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  size?: InputMaybe<CButtonLinkSize>;
  /** All values that are contained in given list. */
  size_in?: InputMaybe<Array<InputMaybe<CButtonLinkSize>>>;
  /** Any other value that exists and is not equal to the given value. */
  size_not?: InputMaybe<CButtonLinkSize>;
  /** All values that are not contained in given list. */
  size_not_in?: InputMaybe<Array<InputMaybe<CButtonLinkSize>>>;
  tone?: InputMaybe<CButtonLinkTone>;
  /** All values that are contained in given list. */
  tone_in?: InputMaybe<Array<InputMaybe<CButtonLinkTone>>>;
  /** Any other value that exists and is not equal to the given value. */
  tone_not?: InputMaybe<CButtonLinkTone>;
  /** All values that are not contained in given list. */
  tone_not_in?: InputMaybe<Array<InputMaybe<CButtonLinkTone>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  variant?: InputMaybe<CButtonVariant>;
  /** All values that are contained in given list. */
  variant_in?: InputMaybe<Array<InputMaybe<CButtonVariant>>>;
  /** Any other value that exists and is not equal to the given value. */
  variant_not?: InputMaybe<CButtonVariant>;
  /** All values that are not contained in given list. */
  variant_not_in?: InputMaybe<Array<InputMaybe<CButtonVariant>>>;
};

export enum CButtonLinkOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IconPositionAsc = 'iconPosition_ASC',
  IconPositionDesc = 'iconPosition_DESC',
  IconAsc = 'icon_ASC',
  IconDesc = 'icon_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  ToneAsc = 'tone_ASC',
  ToneDesc = 'tone_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VariantAsc = 'variant_ASC',
  VariantDesc = 'variant_DESC',
}

export enum CButtonLinkSize {
  Small = 'small',
  Standard = 'standard',
}

export enum CButtonLinkTone {
  BrandAccent = 'brandAccent',
  Critical = 'critical',
  FormAccent = 'formAccent',
  Neutral = 'neutral',
}

export type CButtonLinkUpdateInput = {
  cly2hlg5q31xz06tf7om86ghf?: InputMaybe<CImageBlockUpdateManyInlineInput>;
  cm1p9lw3m03s107tchk7w41h4?: InputMaybe<TipsCardUpdateManyInlineInput>;
  icon?: InputMaybe<Icon>;
  iconPosition?: InputMaybe<IconPosition>;
  link?: InputMaybe<CButtonLinklinkUnionUpdateOneInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<CButtonLinkUpdateLocalizationsInput>;
  size?: InputMaybe<CButtonLinkSize>;
  /** text input for default locale (default) */
  text?: InputMaybe<Scalars['String']>;
  tone?: InputMaybe<CButtonLinkTone>;
  variant?: InputMaybe<CButtonVariant>;
};

export type CButtonLinkUpdateLocalizationDataInput = {
  text?: InputMaybe<Scalars['String']>;
};

export type CButtonLinkUpdateLocalizationInput = {
  data: CButtonLinkUpdateLocalizationDataInput;
  locale: Locale;
};

export type CButtonLinkUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<CButtonLinkCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<CButtonLinkUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<CButtonLinkUpsertLocalizationInput>>;
};

export type CButtonLinkUpdateManyInlineInput = {
  /** Connect multiple existing CButtonLink documents */
  connect?: InputMaybe<Array<CButtonLinkConnectInput>>;
  /** Create and connect multiple CButtonLink documents */
  create?: InputMaybe<Array<CButtonLinkCreateInput>>;
  /** Delete multiple CButtonLink documents */
  delete?: InputMaybe<Array<CButtonLinkWhereUniqueInput>>;
  /** Disconnect multiple CButtonLink documents */
  disconnect?: InputMaybe<Array<CButtonLinkWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing CButtonLink documents */
  set?: InputMaybe<Array<CButtonLinkWhereUniqueInput>>;
  /** Update multiple CButtonLink documents */
  update?: InputMaybe<Array<CButtonLinkUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple CButtonLink documents */
  upsert?: InputMaybe<Array<CButtonLinkUpsertWithNestedWhereUniqueInput>>;
};

export type CButtonLinkUpdateManyInput = {
  icon?: InputMaybe<Icon>;
  iconPosition?: InputMaybe<IconPosition>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<CButtonLinkUpdateManyLocalizationsInput>;
  size?: InputMaybe<CButtonLinkSize>;
  /** text input for default locale (default) */
  text?: InputMaybe<Scalars['String']>;
  tone?: InputMaybe<CButtonLinkTone>;
  variant?: InputMaybe<CButtonVariant>;
};

export type CButtonLinkUpdateManyLocalizationDataInput = {
  text?: InputMaybe<Scalars['String']>;
};

export type CButtonLinkUpdateManyLocalizationInput = {
  data: CButtonLinkUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type CButtonLinkUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<CButtonLinkUpdateManyLocalizationInput>>;
};

export type CButtonLinkUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CButtonLinkUpdateManyInput;
  /** Document search */
  where: CButtonLinkWhereInput;
};

export type CButtonLinkUpdateOneInlineInput = {
  /** Connect existing CButtonLink document */
  connect?: InputMaybe<CButtonLinkWhereUniqueInput>;
  /** Create and connect one CButtonLink document */
  create?: InputMaybe<CButtonLinkCreateInput>;
  /** Delete currently connected CButtonLink document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected CButtonLink document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single CButtonLink document */
  update?: InputMaybe<CButtonLinkUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CButtonLink document */
  upsert?: InputMaybe<CButtonLinkUpsertWithNestedWhereUniqueInput>;
};

export type CButtonLinkUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CButtonLinkUpdateInput;
  /** Unique document search */
  where: CButtonLinkWhereUniqueInput;
};

export type CButtonLinkUpsertInput = {
  /** Create document if it didn't exist */
  create: CButtonLinkCreateInput;
  /** Update document if it exists */
  update: CButtonLinkUpdateInput;
};

export type CButtonLinkUpsertLocalizationInput = {
  create: CButtonLinkCreateLocalizationDataInput;
  locale: Locale;
  update: CButtonLinkUpdateLocalizationDataInput;
};

export type CButtonLinkUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CButtonLinkUpsertInput;
  /** Unique document search */
  where: CButtonLinkWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type CButtonLinkWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type CButtonLinkWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CButtonLinkWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CButtonLinkWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CButtonLinkWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<CButtonLinkWhereStageInput>;
  documentInStages_none?: InputMaybe<CButtonLinkWhereStageInput>;
  documentInStages_some?: InputMaybe<CButtonLinkWhereStageInput>;
  icon?: InputMaybe<Icon>;
  iconPosition?: InputMaybe<IconPosition>;
  /** All values that are contained in given list. */
  iconPosition_in?: InputMaybe<Array<InputMaybe<IconPosition>>>;
  /** Any other value that exists and is not equal to the given value. */
  iconPosition_not?: InputMaybe<IconPosition>;
  /** All values that are not contained in given list. */
  iconPosition_not_in?: InputMaybe<Array<InputMaybe<IconPosition>>>;
  /** All values that are contained in given list. */
  icon_in?: InputMaybe<Array<InputMaybe<Icon>>>;
  /** Any other value that exists and is not equal to the given value. */
  icon_not?: InputMaybe<Icon>;
  /** All values that are not contained in given list. */
  icon_not_in?: InputMaybe<Array<InputMaybe<Icon>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values in which the modular component is connected to the given models */
  link?: InputMaybe<CButtonLinklinkUnionWhereInput>;
  /** All values in which the union is empty. */
  link_empty?: InputMaybe<Scalars['Boolean']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  size?: InputMaybe<CButtonLinkSize>;
  /** All values that are contained in given list. */
  size_in?: InputMaybe<Array<InputMaybe<CButtonLinkSize>>>;
  /** Any other value that exists and is not equal to the given value. */
  size_not?: InputMaybe<CButtonLinkSize>;
  /** All values that are not contained in given list. */
  size_not_in?: InputMaybe<Array<InputMaybe<CButtonLinkSize>>>;
  text?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  text_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  text_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  text_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  text_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  text_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  text_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  text_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  text_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  text_starts_with?: InputMaybe<Scalars['String']>;
  tone?: InputMaybe<CButtonLinkTone>;
  /** All values that are contained in given list. */
  tone_in?: InputMaybe<Array<InputMaybe<CButtonLinkTone>>>;
  /** Any other value that exists and is not equal to the given value. */
  tone_not?: InputMaybe<CButtonLinkTone>;
  /** All values that are not contained in given list. */
  tone_not_in?: InputMaybe<Array<InputMaybe<CButtonLinkTone>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  variant?: InputMaybe<CButtonVariant>;
  /** All values that are contained in given list. */
  variant_in?: InputMaybe<Array<InputMaybe<CButtonVariant>>>;
  /** Any other value that exists and is not equal to the given value. */
  variant_not?: InputMaybe<CButtonVariant>;
  /** All values that are not contained in given list. */
  variant_not_in?: InputMaybe<Array<InputMaybe<CButtonVariant>>>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type CButtonLinkWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CButtonLinkWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CButtonLinkWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CButtonLinkWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<CButtonLinkWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References CButtonLink record uniquely */
export type CButtonLinkWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type CButtonLinklinkUnion = Link;

export type CButtonLinklinkUnionConnectInput = {
  Link?: InputMaybe<LinkConnectInput>;
};

export type CButtonLinklinkUnionCreateInput = {
  Link?: InputMaybe<LinkCreateInput>;
};

export type CButtonLinklinkUnionCreateManyInlineInput = {
  /** Create and connect multiple existing CButtonLinklinkUnion documents */
  create?: InputMaybe<Array<CButtonLinklinkUnionCreateInput>>;
};

export type CButtonLinklinkUnionCreateOneInlineInput = {
  /** Create and connect one CButtonLinklinkUnion document */
  create?: InputMaybe<CButtonLinklinkUnionCreateInput>;
};

export type CButtonLinklinkUnionCreateWithPositionInput = {
  Link?: InputMaybe<LinkCreateWithPositionInput>;
};

export type CButtonLinklinkUnionUpdateInput = {
  Link?: InputMaybe<LinkUpdateInput>;
};

export type CButtonLinklinkUnionUpdateManyInlineInput = {
  /** Create and connect multiple CButtonLinklinkUnion component instances */
  create?: InputMaybe<Array<CButtonLinklinkUnionCreateWithPositionInput>>;
  /** Delete multiple CButtonLinklinkUnion documents */
  delete?: InputMaybe<Array<CButtonLinklinkUnionWhereUniqueInput>>;
  /** Update multiple CButtonLinklinkUnion component instances */
  update?: InputMaybe<
    Array<CButtonLinklinkUnionUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CButtonLinklinkUnion component instances */
  upsert?: InputMaybe<
    Array<CButtonLinklinkUnionUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CButtonLinklinkUnionUpdateManyWithNestedWhereInput = {
  Link?: InputMaybe<LinkUpdateManyWithNestedWhereInput>;
};

export type CButtonLinklinkUnionUpdateOneInlineInput = {
  /** Create and connect one CButtonLinklinkUnion document */
  create?: InputMaybe<CButtonLinklinkUnionCreateInput>;
  /** Delete currently connected CButtonLinklinkUnion document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CButtonLinklinkUnion document */
  update?: InputMaybe<CButtonLinklinkUnionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CButtonLinklinkUnion document */
  upsert?: InputMaybe<CButtonLinklinkUnionUpsertWithNestedWhereUniqueInput>;
};

export type CButtonLinklinkUnionUpdateWithNestedWhereUniqueAndPositionInput = {
  Link?: InputMaybe<LinkUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type CButtonLinklinkUnionUpdateWithNestedWhereUniqueInput = {
  Link?: InputMaybe<LinkUpdateWithNestedWhereUniqueInput>;
};

export type CButtonLinklinkUnionUpsertWithNestedWhereUniqueAndPositionInput = {
  Link?: InputMaybe<LinkUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type CButtonLinklinkUnionUpsertWithNestedWhereUniqueInput = {
  Link?: InputMaybe<LinkUpsertWithNestedWhereUniqueInput>;
};

export type CButtonLinklinkUnionWhereInput = {
  Link?: InputMaybe<LinkWhereInput>;
};

export type CButtonLinklinkUnionWhereUniqueInput = {
  Link?: InputMaybe<LinkWhereUniqueInput>;
};

export enum CButtonVariant {
  Ghost = 'ghost',
  Soft = 'soft',
  Solid = 'solid',
  Transparent = 'transparent',
}

export type CCardGroup = Entity & {
  __typename?: 'CCardGroup';
  cards: Array<CCardGroupcardsUnion>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System stage field */
  stage: Stage;
  tile?: Maybe<CTile>;
};

export type CCardGroupCardsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type CCardGroupTileArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CCardGroupConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CCardGroupWhereUniqueInput;
};

/** A connection to a list of items. */
export type CCardGroupConnection = {
  __typename?: 'CCardGroupConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CCardGroupEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CCardGroupCreateInput = {
  cards?: InputMaybe<CCardGroupcardsUnionCreateManyInlineInput>;
  tile?: InputMaybe<CTileCreateOneInlineInput>;
};

export type CCardGroupCreateManyInlineInput = {
  /** Create and connect multiple existing CCardGroup documents */
  create?: InputMaybe<Array<CCardGroupCreateInput>>;
};

export type CCardGroupCreateOneInlineInput = {
  /** Create and connect one CCardGroup document */
  create?: InputMaybe<CCardGroupCreateInput>;
};

export type CCardGroupCreateWithPositionInput = {
  /** Document to create */
  data: CCardGroupCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type CCardGroupEdge = {
  __typename?: 'CCardGroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CCardGroup;
};

/** Identifies documents */
export type CCardGroupManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CCardGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CCardGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CCardGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  /** All values in which the union is empty. */
  cards_empty?: InputMaybe<Scalars['Boolean']>;
  /** Matches if the modular component contains at least one connection to the item provided to the filter */
  cards_some?: InputMaybe<CCardGroupcardsUnionWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  tile?: InputMaybe<CTileWhereInput>;
};

export enum CCardGroupOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type CCardGroupParent = CBlock;

export type CCardGroupParentConnectInput = {
  CBlock?: InputMaybe<CBlockConnectInput>;
};

export type CCardGroupParentCreateInput = {
  CBlock?: InputMaybe<CBlockCreateInput>;
};

export type CCardGroupParentCreateManyInlineInput = {
  /** Connect multiple existing CCardGroupParent documents */
  connect?: InputMaybe<Array<CCardGroupParentWhereUniqueInput>>;
  /** Create and connect multiple existing CCardGroupParent documents */
  create?: InputMaybe<Array<CCardGroupParentCreateInput>>;
};

export type CCardGroupParentCreateOneInlineInput = {
  /** Connect one existing CCardGroupParent document */
  connect?: InputMaybe<CCardGroupParentWhereUniqueInput>;
  /** Create and connect one CCardGroupParent document */
  create?: InputMaybe<CCardGroupParentCreateInput>;
};

export type CCardGroupParentUpdateInput = {
  CBlock?: InputMaybe<CBlockUpdateInput>;
};

export type CCardGroupParentUpdateManyInlineInput = {
  /** Connect multiple existing CCardGroupParent documents */
  connect?: InputMaybe<Array<CCardGroupParentConnectInput>>;
  /** Create and connect multiple CCardGroupParent documents */
  create?: InputMaybe<Array<CCardGroupParentCreateInput>>;
  /** Delete multiple CCardGroupParent documents */
  delete?: InputMaybe<Array<CCardGroupParentWhereUniqueInput>>;
  /** Disconnect multiple CCardGroupParent documents */
  disconnect?: InputMaybe<Array<CCardGroupParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing CCardGroupParent documents */
  set?: InputMaybe<Array<CCardGroupParentWhereUniqueInput>>;
  /** Update multiple CCardGroupParent documents */
  update?: InputMaybe<Array<CCardGroupParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple CCardGroupParent documents */
  upsert?: InputMaybe<Array<CCardGroupParentUpsertWithNestedWhereUniqueInput>>;
};

export type CCardGroupParentUpdateManyWithNestedWhereInput = {
  CBlock?: InputMaybe<CBlockUpdateManyWithNestedWhereInput>;
};

export type CCardGroupParentUpdateOneInlineInput = {
  /** Connect existing CCardGroupParent document */
  connect?: InputMaybe<CCardGroupParentWhereUniqueInput>;
  /** Create and connect one CCardGroupParent document */
  create?: InputMaybe<CCardGroupParentCreateInput>;
  /** Delete currently connected CCardGroupParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected CCardGroupParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single CCardGroupParent document */
  update?: InputMaybe<CCardGroupParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CCardGroupParent document */
  upsert?: InputMaybe<CCardGroupParentUpsertWithNestedWhereUniqueInput>;
};

export type CCardGroupParentUpdateWithNestedWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockUpdateWithNestedWhereUniqueInput>;
};

export type CCardGroupParentUpsertWithNestedWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockUpsertWithNestedWhereUniqueInput>;
};

export type CCardGroupParentWhereInput = {
  CBlock?: InputMaybe<CBlockWhereInput>;
};

export type CCardGroupParentWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockWhereUniqueInput>;
};

export type CCardGroupUpdateInput = {
  cards?: InputMaybe<CCardGroupcardsUnionUpdateManyInlineInput>;
  tile?: InputMaybe<CTileUpdateOneInlineInput>;
};

export type CCardGroupUpdateManyInlineInput = {
  /** Create and connect multiple CCardGroup component instances */
  create?: InputMaybe<Array<CCardGroupCreateWithPositionInput>>;
  /** Delete multiple CCardGroup documents */
  delete?: InputMaybe<Array<CCardGroupWhereUniqueInput>>;
  /** Update multiple CCardGroup component instances */
  update?: InputMaybe<
    Array<CCardGroupUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CCardGroup component instances */
  upsert?: InputMaybe<
    Array<CCardGroupUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CCardGroupUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']>;
};

export type CCardGroupUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CCardGroupUpdateManyInput;
  /** Document search */
  where: CCardGroupWhereInput;
};

export type CCardGroupUpdateOneInlineInput = {
  /** Create and connect one CCardGroup document */
  create?: InputMaybe<CCardGroupCreateInput>;
  /** Delete currently connected CCardGroup document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CCardGroup document */
  update?: InputMaybe<CCardGroupUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CCardGroup document */
  upsert?: InputMaybe<CCardGroupUpsertWithNestedWhereUniqueInput>;
};

export type CCardGroupUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<CCardGroupUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CCardGroupWhereUniqueInput;
};

export type CCardGroupUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CCardGroupUpdateInput;
  /** Unique document search */
  where: CCardGroupWhereUniqueInput;
};

export type CCardGroupUpsertInput = {
  /** Create document if it didn't exist */
  create: CCardGroupCreateInput;
  /** Update document if it exists */
  update: CCardGroupUpdateInput;
};

export type CCardGroupUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<CCardGroupUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CCardGroupWhereUniqueInput;
};

export type CCardGroupUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CCardGroupUpsertInput;
  /** Unique document search */
  where: CCardGroupWhereUniqueInput;
};

/** Identifies documents */
export type CCardGroupWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CCardGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CCardGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CCardGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  /** All values in which the union is empty. */
  cards_empty?: InputMaybe<Scalars['Boolean']>;
  /** Matches if the modular component contains at least one connection to the item provided to the filter */
  cards_some?: InputMaybe<CCardGroupcardsUnionWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  tile?: InputMaybe<CTileWhereInput>;
};

/** References CCardGroup record uniquely */
export type CCardGroupWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type CCardGroupcardsUnion = CCardJobAd | CCardPrimary;

export type CCardGroupcardsUnionConnectInput = {
  CCardJobAd?: InputMaybe<CCardJobAdConnectInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryConnectInput>;
};

export type CCardGroupcardsUnionCreateInput = {
  CCardJobAd?: InputMaybe<CCardJobAdCreateInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryCreateInput>;
};

export type CCardGroupcardsUnionCreateManyInlineInput = {
  /** Create and connect multiple existing CCardGroupcardsUnion documents */
  create?: InputMaybe<Array<CCardGroupcardsUnionCreateInput>>;
};

export type CCardGroupcardsUnionCreateOneInlineInput = {
  /** Create and connect one CCardGroupcardsUnion document */
  create?: InputMaybe<CCardGroupcardsUnionCreateInput>;
};

export type CCardGroupcardsUnionCreateWithPositionInput = {
  CCardJobAd?: InputMaybe<CCardJobAdCreateWithPositionInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryCreateWithPositionInput>;
};

export type CCardGroupcardsUnionUpdateInput = {
  CCardJobAd?: InputMaybe<CCardJobAdUpdateInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryUpdateInput>;
};

export type CCardGroupcardsUnionUpdateManyInlineInput = {
  /** Create and connect multiple CCardGroupcardsUnion component instances */
  create?: InputMaybe<Array<CCardGroupcardsUnionCreateWithPositionInput>>;
  /** Delete multiple CCardGroupcardsUnion documents */
  delete?: InputMaybe<Array<CCardGroupcardsUnionWhereUniqueInput>>;
  /** Update multiple CCardGroupcardsUnion component instances */
  update?: InputMaybe<
    Array<CCardGroupcardsUnionUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CCardGroupcardsUnion component instances */
  upsert?: InputMaybe<
    Array<CCardGroupcardsUnionUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CCardGroupcardsUnionUpdateManyWithNestedWhereInput = {
  CCardJobAd?: InputMaybe<CCardJobAdUpdateManyWithNestedWhereInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryUpdateManyWithNestedWhereInput>;
};

export type CCardGroupcardsUnionUpdateOneInlineInput = {
  /** Create and connect one CCardGroupcardsUnion document */
  create?: InputMaybe<CCardGroupcardsUnionCreateInput>;
  /** Delete currently connected CCardGroupcardsUnion document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CCardGroupcardsUnion document */
  update?: InputMaybe<CCardGroupcardsUnionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CCardGroupcardsUnion document */
  upsert?: InputMaybe<CCardGroupcardsUnionUpsertWithNestedWhereUniqueInput>;
};

export type CCardGroupcardsUnionUpdateWithNestedWhereUniqueAndPositionInput = {
  CCardJobAd?: InputMaybe<CCardJobAdUpdateWithNestedWhereUniqueAndPositionInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type CCardGroupcardsUnionUpdateWithNestedWhereUniqueInput = {
  CCardJobAd?: InputMaybe<CCardJobAdUpdateWithNestedWhereUniqueInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryUpdateWithNestedWhereUniqueInput>;
};

export type CCardGroupcardsUnionUpsertWithNestedWhereUniqueAndPositionInput = {
  CCardJobAd?: InputMaybe<CCardJobAdUpsertWithNestedWhereUniqueAndPositionInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type CCardGroupcardsUnionUpsertWithNestedWhereUniqueInput = {
  CCardJobAd?: InputMaybe<CCardJobAdUpsertWithNestedWhereUniqueInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryUpsertWithNestedWhereUniqueInput>;
};

export type CCardGroupcardsUnionWhereInput = {
  CCardJobAd?: InputMaybe<CCardJobAdWhereInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryWhereInput>;
};

export type CCardGroupcardsUnionWhereUniqueInput = {
  CCardJobAd?: InputMaybe<CCardJobAdWhereUniqueInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryWhereUniqueInput>;
};

/** Job Ad Card component */
export type CCardJobAd = Entity & {
  __typename?: 'CCardJobAd';
  actionGroup?: Maybe<CActionGroup>;
  badge?: Maybe<Badge>;
  /** Set a border or box-shadow. (default "borderNeutralLight") */
  border?: Maybe<BoxShadows>;
  heading?: Maybe<CHeading>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** Represents an image content file. */
  image?: Maybe<Asset>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<CCardJobAd>;
  paragraph?: Maybe<CParagraph>;
  /** System stage field */
  stage: Stage;
  subHeading?: Maybe<Scalars['String']>;
};

/** Job Ad Card component */
export type CCardJobAdActionGroupArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Job Ad Card component */
export type CCardJobAdBadgeArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Job Ad Card component */
export type CCardJobAdHeadingArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Job Ad Card component */
export type CCardJobAdImageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
  where?: InputMaybe<AssetSingleRelationWhereInput>;
};

/** Job Ad Card component */
export type CCardJobAdLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

/** Job Ad Card component */
export type CCardJobAdParagraphArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CCardJobAdConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CCardJobAdWhereUniqueInput;
};

/** A connection to a list of items. */
export type CCardJobAdConnection = {
  __typename?: 'CCardJobAdConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CCardJobAdEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CCardJobAdCreateInput = {
  actionGroup?: InputMaybe<CActionGroupCreateOneInlineInput>;
  badge?: InputMaybe<BadgeCreateOneInlineInput>;
  border?: InputMaybe<BoxShadows>;
  heading?: InputMaybe<CHeadingCreateOneInlineInput>;
  image?: InputMaybe<AssetCreateOneInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<CCardJobAdCreateLocalizationsInput>;
  paragraph?: InputMaybe<CParagraphCreateOneInlineInput>;
  /** subHeading input for default locale (default) */
  subHeading?: InputMaybe<Scalars['String']>;
};

export type CCardJobAdCreateLocalizationDataInput = {
  subHeading?: InputMaybe<Scalars['String']>;
};

export type CCardJobAdCreateLocalizationInput = {
  /** Localization input */
  data: CCardJobAdCreateLocalizationDataInput;
  locale: Locale;
};

export type CCardJobAdCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<CCardJobAdCreateLocalizationInput>>;
};

export type CCardJobAdCreateManyInlineInput = {
  /** Create and connect multiple existing CCardJobAd documents */
  create?: InputMaybe<Array<CCardJobAdCreateInput>>;
};

export type CCardJobAdCreateOneInlineInput = {
  /** Create and connect one CCardJobAd document */
  create?: InputMaybe<CCardJobAdCreateInput>;
};

export type CCardJobAdCreateWithPositionInput = {
  /** Document to create */
  data: CCardJobAdCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type CCardJobAdEdge = {
  __typename?: 'CCardJobAdEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CCardJobAd;
};

/** Identifies documents */
export type CCardJobAdManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CCardJobAdWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CCardJobAdWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CCardJobAdWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  actionGroup?: InputMaybe<CActionGroupWhereInput>;
  badge?: InputMaybe<BadgeWhereInput>;
  border?: InputMaybe<BoxShadows>;
  /** All values that are contained in given list. */
  border_in?: InputMaybe<Array<InputMaybe<BoxShadows>>>;
  /** Any other value that exists and is not equal to the given value. */
  border_not?: InputMaybe<BoxShadows>;
  /** All values that are not contained in given list. */
  border_not_in?: InputMaybe<Array<InputMaybe<BoxShadows>>>;
  heading?: InputMaybe<CHeadingWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AssetWhereInput>;
  paragraph?: InputMaybe<CParagraphWhereInput>;
};

export enum CCardJobAdOrderByInput {
  BorderAsc = 'border_ASC',
  BorderDesc = 'border_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SubHeadingAsc = 'subHeading_ASC',
  SubHeadingDesc = 'subHeading_DESC',
}

export type CCardJobAdParent = CCardGroup;

export type CCardJobAdParentConnectInput = {
  CCardGroup?: InputMaybe<CCardGroupConnectInput>;
};

export type CCardJobAdParentCreateInput = {
  CCardGroup?: InputMaybe<CCardGroupCreateInput>;
};

export type CCardJobAdParentCreateManyInlineInput = {
  /** Create and connect multiple existing CCardJobAdParent documents */
  create?: InputMaybe<Array<CCardJobAdParentCreateInput>>;
};

export type CCardJobAdParentCreateOneInlineInput = {
  /** Create and connect one CCardJobAdParent document */
  create?: InputMaybe<CCardJobAdParentCreateInput>;
};

export type CCardJobAdParentCreateWithPositionInput = {
  CCardGroup?: InputMaybe<CCardGroupCreateWithPositionInput>;
};

export type CCardJobAdParentUpdateInput = {
  CCardGroup?: InputMaybe<CCardGroupUpdateInput>;
};

export type CCardJobAdParentUpdateManyInlineInput = {
  /** Create and connect multiple CCardJobAdParent component instances */
  create?: InputMaybe<Array<CCardJobAdParentCreateWithPositionInput>>;
  /** Delete multiple CCardJobAdParent documents */
  delete?: InputMaybe<Array<CCardJobAdParentWhereUniqueInput>>;
  /** Update multiple CCardJobAdParent component instances */
  update?: InputMaybe<
    Array<CCardJobAdParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CCardJobAdParent component instances */
  upsert?: InputMaybe<
    Array<CCardJobAdParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CCardJobAdParentUpdateManyWithNestedWhereInput = {
  CCardGroup?: InputMaybe<CCardGroupUpdateManyWithNestedWhereInput>;
};

export type CCardJobAdParentUpdateOneInlineInput = {
  /** Create and connect one CCardJobAdParent document */
  create?: InputMaybe<CCardJobAdParentCreateInput>;
  /** Delete currently connected CCardJobAdParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CCardJobAdParent document */
  update?: InputMaybe<CCardJobAdParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CCardJobAdParent document */
  upsert?: InputMaybe<CCardJobAdParentUpsertWithNestedWhereUniqueInput>;
};

export type CCardJobAdParentUpdateWithNestedWhereUniqueAndPositionInput = {
  CCardGroup?: InputMaybe<CCardGroupUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type CCardJobAdParentUpdateWithNestedWhereUniqueInput = {
  CCardGroup?: InputMaybe<CCardGroupUpdateWithNestedWhereUniqueInput>;
};

export type CCardJobAdParentUpsertWithNestedWhereUniqueAndPositionInput = {
  CCardGroup?: InputMaybe<CCardGroupUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type CCardJobAdParentUpsertWithNestedWhereUniqueInput = {
  CCardGroup?: InputMaybe<CCardGroupUpsertWithNestedWhereUniqueInput>;
};

export type CCardJobAdParentWhereInput = {
  CCardGroup?: InputMaybe<CCardGroupWhereInput>;
};

export type CCardJobAdParentWhereUniqueInput = {
  CCardGroup?: InputMaybe<CCardGroupWhereUniqueInput>;
};

export type CCardJobAdUpdateInput = {
  actionGroup?: InputMaybe<CActionGroupUpdateOneInlineInput>;
  badge?: InputMaybe<BadgeUpdateOneInlineInput>;
  border?: InputMaybe<BoxShadows>;
  heading?: InputMaybe<CHeadingUpdateOneInlineInput>;
  image?: InputMaybe<AssetUpdateOneInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<CCardJobAdUpdateLocalizationsInput>;
  paragraph?: InputMaybe<CParagraphUpdateOneInlineInput>;
  /** subHeading input for default locale (default) */
  subHeading?: InputMaybe<Scalars['String']>;
};

export type CCardJobAdUpdateLocalizationDataInput = {
  subHeading?: InputMaybe<Scalars['String']>;
};

export type CCardJobAdUpdateLocalizationInput = {
  data: CCardJobAdUpdateLocalizationDataInput;
  locale: Locale;
};

export type CCardJobAdUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<CCardJobAdCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<CCardJobAdUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<CCardJobAdUpsertLocalizationInput>>;
};

export type CCardJobAdUpdateManyInlineInput = {
  /** Create and connect multiple CCardJobAd component instances */
  create?: InputMaybe<Array<CCardJobAdCreateWithPositionInput>>;
  /** Delete multiple CCardJobAd documents */
  delete?: InputMaybe<Array<CCardJobAdWhereUniqueInput>>;
  /** Update multiple CCardJobAd component instances */
  update?: InputMaybe<
    Array<CCardJobAdUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CCardJobAd component instances */
  upsert?: InputMaybe<
    Array<CCardJobAdUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CCardJobAdUpdateManyInput = {
  border?: InputMaybe<BoxShadows>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<CCardJobAdUpdateManyLocalizationsInput>;
  /** subHeading input for default locale (default) */
  subHeading?: InputMaybe<Scalars['String']>;
};

export type CCardJobAdUpdateManyLocalizationDataInput = {
  subHeading?: InputMaybe<Scalars['String']>;
};

export type CCardJobAdUpdateManyLocalizationInput = {
  data: CCardJobAdUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type CCardJobAdUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<CCardJobAdUpdateManyLocalizationInput>>;
};

export type CCardJobAdUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CCardJobAdUpdateManyInput;
  /** Document search */
  where: CCardJobAdWhereInput;
};

export type CCardJobAdUpdateOneInlineInput = {
  /** Create and connect one CCardJobAd document */
  create?: InputMaybe<CCardJobAdCreateInput>;
  /** Delete currently connected CCardJobAd document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CCardJobAd document */
  update?: InputMaybe<CCardJobAdUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CCardJobAd document */
  upsert?: InputMaybe<CCardJobAdUpsertWithNestedWhereUniqueInput>;
};

export type CCardJobAdUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<CCardJobAdUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CCardJobAdWhereUniqueInput;
};

export type CCardJobAdUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CCardJobAdUpdateInput;
  /** Unique document search */
  where: CCardJobAdWhereUniqueInput;
};

export type CCardJobAdUpsertInput = {
  /** Create document if it didn't exist */
  create: CCardJobAdCreateInput;
  /** Update document if it exists */
  update: CCardJobAdUpdateInput;
};

export type CCardJobAdUpsertLocalizationInput = {
  create: CCardJobAdCreateLocalizationDataInput;
  locale: Locale;
  update: CCardJobAdUpdateLocalizationDataInput;
};

export type CCardJobAdUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<CCardJobAdUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CCardJobAdWhereUniqueInput;
};

export type CCardJobAdUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CCardJobAdUpsertInput;
  /** Unique document search */
  where: CCardJobAdWhereUniqueInput;
};

/** Identifies documents */
export type CCardJobAdWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CCardJobAdWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CCardJobAdWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CCardJobAdWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  actionGroup?: InputMaybe<CActionGroupWhereInput>;
  badge?: InputMaybe<BadgeWhereInput>;
  border?: InputMaybe<BoxShadows>;
  /** All values that are contained in given list. */
  border_in?: InputMaybe<Array<InputMaybe<BoxShadows>>>;
  /** Any other value that exists and is not equal to the given value. */
  border_not?: InputMaybe<BoxShadows>;
  /** All values that are not contained in given list. */
  border_not_in?: InputMaybe<Array<InputMaybe<BoxShadows>>>;
  heading?: InputMaybe<CHeadingWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AssetWhereInput>;
  paragraph?: InputMaybe<CParagraphWhereInput>;
  subHeading?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  subHeading_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  subHeading_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  subHeading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  subHeading_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  subHeading_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  subHeading_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  subHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  subHeading_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  subHeading_starts_with?: InputMaybe<Scalars['String']>;
};

/** References CCardJobAd record uniquely */
export type CCardJobAdWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type CCardPrimary = Entity & {
  __typename?: 'CCardPrimary';
  actionGroup?: Maybe<CActionGroup>;
  badge?: Maybe<Badge>;
  /** Set a border or box-shadow. (default "borderNeutralLight") */
  border?: Maybe<BoxShadows>;
  borderRadius?: Maybe<BorderRadius>;
  /** Control the alignment of the each component in the primary card */
  cardContentAlignment?: Maybe<BoxJustifyContent>;
  heading?: Maybe<CHeading>;
  /** The unique identifier */
  id: Scalars['ID'];
  image?: Maybe<Asset>;
  numericalBlock?: Maybe<NumericalBlock>;
  paragraph?: Maybe<CParagraph>;
  /** System stage field */
  stage: Stage;
  /** Text Alignment/Position for Card */
  textAlignment?: Maybe<CTextAlign>;
};

export type CCardPrimaryActionGroupArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CCardPrimaryBadgeArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CCardPrimaryHeadingArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CCardPrimaryImageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
  where?: InputMaybe<AssetSingleRelationWhereInput>;
};

export type CCardPrimaryNumericalBlockArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CCardPrimaryParagraphArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CCardPrimaryConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CCardPrimaryWhereUniqueInput;
};

/** A connection to a list of items. */
export type CCardPrimaryConnection = {
  __typename?: 'CCardPrimaryConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CCardPrimaryEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CCardPrimaryCreateInput = {
  actionGroup?: InputMaybe<CActionGroupCreateOneInlineInput>;
  badge?: InputMaybe<BadgeCreateOneInlineInput>;
  border?: InputMaybe<BoxShadows>;
  borderRadius?: InputMaybe<BorderRadius>;
  cardContentAlignment?: InputMaybe<BoxJustifyContent>;
  heading?: InputMaybe<CHeadingCreateOneInlineInput>;
  image?: InputMaybe<AssetCreateOneInlineInput>;
  numericalBlock?: InputMaybe<NumericalBlockCreateOneInlineInput>;
  paragraph?: InputMaybe<CParagraphCreateOneInlineInput>;
  textAlignment?: InputMaybe<CTextAlign>;
};

export type CCardPrimaryCreateManyInlineInput = {
  /** Create and connect multiple existing CCardPrimary documents */
  create?: InputMaybe<Array<CCardPrimaryCreateInput>>;
};

export type CCardPrimaryCreateOneInlineInput = {
  /** Create and connect one CCardPrimary document */
  create?: InputMaybe<CCardPrimaryCreateInput>;
};

export type CCardPrimaryCreateWithPositionInput = {
  /** Document to create */
  data: CCardPrimaryCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type CCardPrimaryEdge = {
  __typename?: 'CCardPrimaryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CCardPrimary;
};

/** Identifies documents */
export type CCardPrimaryManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CCardPrimaryWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CCardPrimaryWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CCardPrimaryWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  actionGroup?: InputMaybe<CActionGroupWhereInput>;
  badge?: InputMaybe<BadgeWhereInput>;
  border?: InputMaybe<BoxShadows>;
  borderRadius?: InputMaybe<BorderRadius>;
  /** All values that are contained in given list. */
  borderRadius_in?: InputMaybe<Array<InputMaybe<BorderRadius>>>;
  /** Any other value that exists and is not equal to the given value. */
  borderRadius_not?: InputMaybe<BorderRadius>;
  /** All values that are not contained in given list. */
  borderRadius_not_in?: InputMaybe<Array<InputMaybe<BorderRadius>>>;
  /** All values that are contained in given list. */
  border_in?: InputMaybe<Array<InputMaybe<BoxShadows>>>;
  /** Any other value that exists and is not equal to the given value. */
  border_not?: InputMaybe<BoxShadows>;
  /** All values that are not contained in given list. */
  border_not_in?: InputMaybe<Array<InputMaybe<BoxShadows>>>;
  cardContentAlignment?: InputMaybe<BoxJustifyContent>;
  /** All values that are contained in given list. */
  cardContentAlignment_in?: InputMaybe<Array<InputMaybe<BoxJustifyContent>>>;
  /** Any other value that exists and is not equal to the given value. */
  cardContentAlignment_not?: InputMaybe<BoxJustifyContent>;
  /** All values that are not contained in given list. */
  cardContentAlignment_not_in?: InputMaybe<
    Array<InputMaybe<BoxJustifyContent>>
  >;
  heading?: InputMaybe<CHeadingWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AssetWhereInput>;
  numericalBlock?: InputMaybe<NumericalBlockWhereInput>;
  paragraph?: InputMaybe<CParagraphWhereInput>;
  textAlignment?: InputMaybe<CTextAlign>;
  /** All values that are contained in given list. */
  textAlignment_in?: InputMaybe<Array<InputMaybe<CTextAlign>>>;
  /** Any other value that exists and is not equal to the given value. */
  textAlignment_not?: InputMaybe<CTextAlign>;
  /** All values that are not contained in given list. */
  textAlignment_not_in?: InputMaybe<Array<InputMaybe<CTextAlign>>>;
};

export enum CCardPrimaryOrderByInput {
  BorderRadiusAsc = 'borderRadius_ASC',
  BorderRadiusDesc = 'borderRadius_DESC',
  BorderAsc = 'border_ASC',
  BorderDesc = 'border_DESC',
  CardContentAlignmentAsc = 'cardContentAlignment_ASC',
  CardContentAlignmentDesc = 'cardContentAlignment_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TextAlignmentAsc = 'textAlignment_ASC',
  TextAlignmentDesc = 'textAlignment_DESC',
}

export type CCardPrimaryParent = CCardGroup;

export type CCardPrimaryParentConnectInput = {
  CCardGroup?: InputMaybe<CCardGroupConnectInput>;
};

export type CCardPrimaryParentCreateInput = {
  CCardGroup?: InputMaybe<CCardGroupCreateInput>;
};

export type CCardPrimaryParentCreateManyInlineInput = {
  /** Create and connect multiple existing CCardPrimaryParent documents */
  create?: InputMaybe<Array<CCardPrimaryParentCreateInput>>;
};

export type CCardPrimaryParentCreateOneInlineInput = {
  /** Create and connect one CCardPrimaryParent document */
  create?: InputMaybe<CCardPrimaryParentCreateInput>;
};

export type CCardPrimaryParentCreateWithPositionInput = {
  CCardGroup?: InputMaybe<CCardGroupCreateWithPositionInput>;
};

export type CCardPrimaryParentUpdateInput = {
  CCardGroup?: InputMaybe<CCardGroupUpdateInput>;
};

export type CCardPrimaryParentUpdateManyInlineInput = {
  /** Create and connect multiple CCardPrimaryParent component instances */
  create?: InputMaybe<Array<CCardPrimaryParentCreateWithPositionInput>>;
  /** Delete multiple CCardPrimaryParent documents */
  delete?: InputMaybe<Array<CCardPrimaryParentWhereUniqueInput>>;
  /** Update multiple CCardPrimaryParent component instances */
  update?: InputMaybe<
    Array<CCardPrimaryParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CCardPrimaryParent component instances */
  upsert?: InputMaybe<
    Array<CCardPrimaryParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CCardPrimaryParentUpdateManyWithNestedWhereInput = {
  CCardGroup?: InputMaybe<CCardGroupUpdateManyWithNestedWhereInput>;
};

export type CCardPrimaryParentUpdateOneInlineInput = {
  /** Create and connect one CCardPrimaryParent document */
  create?: InputMaybe<CCardPrimaryParentCreateInput>;
  /** Delete currently connected CCardPrimaryParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CCardPrimaryParent document */
  update?: InputMaybe<CCardPrimaryParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CCardPrimaryParent document */
  upsert?: InputMaybe<CCardPrimaryParentUpsertWithNestedWhereUniqueInput>;
};

export type CCardPrimaryParentUpdateWithNestedWhereUniqueAndPositionInput = {
  CCardGroup?: InputMaybe<CCardGroupUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type CCardPrimaryParentUpdateWithNestedWhereUniqueInput = {
  CCardGroup?: InputMaybe<CCardGroupUpdateWithNestedWhereUniqueInput>;
};

export type CCardPrimaryParentUpsertWithNestedWhereUniqueAndPositionInput = {
  CCardGroup?: InputMaybe<CCardGroupUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type CCardPrimaryParentUpsertWithNestedWhereUniqueInput = {
  CCardGroup?: InputMaybe<CCardGroupUpsertWithNestedWhereUniqueInput>;
};

export type CCardPrimaryParentWhereInput = {
  CCardGroup?: InputMaybe<CCardGroupWhereInput>;
};

export type CCardPrimaryParentWhereUniqueInput = {
  CCardGroup?: InputMaybe<CCardGroupWhereUniqueInput>;
};

export type CCardPrimaryUpdateInput = {
  actionGroup?: InputMaybe<CActionGroupUpdateOneInlineInput>;
  badge?: InputMaybe<BadgeUpdateOneInlineInput>;
  border?: InputMaybe<BoxShadows>;
  borderRadius?: InputMaybe<BorderRadius>;
  cardContentAlignment?: InputMaybe<BoxJustifyContent>;
  heading?: InputMaybe<CHeadingUpdateOneInlineInput>;
  image?: InputMaybe<AssetUpdateOneInlineInput>;
  numericalBlock?: InputMaybe<NumericalBlockUpdateOneInlineInput>;
  paragraph?: InputMaybe<CParagraphUpdateOneInlineInput>;
  textAlignment?: InputMaybe<CTextAlign>;
};

export type CCardPrimaryUpdateManyInlineInput = {
  /** Create and connect multiple CCardPrimary component instances */
  create?: InputMaybe<Array<CCardPrimaryCreateWithPositionInput>>;
  /** Delete multiple CCardPrimary documents */
  delete?: InputMaybe<Array<CCardPrimaryWhereUniqueInput>>;
  /** Update multiple CCardPrimary component instances */
  update?: InputMaybe<
    Array<CCardPrimaryUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CCardPrimary component instances */
  upsert?: InputMaybe<
    Array<CCardPrimaryUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CCardPrimaryUpdateManyInput = {
  border?: InputMaybe<BoxShadows>;
  borderRadius?: InputMaybe<BorderRadius>;
  cardContentAlignment?: InputMaybe<BoxJustifyContent>;
  textAlignment?: InputMaybe<CTextAlign>;
};

export type CCardPrimaryUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CCardPrimaryUpdateManyInput;
  /** Document search */
  where: CCardPrimaryWhereInput;
};

export type CCardPrimaryUpdateOneInlineInput = {
  /** Create and connect one CCardPrimary document */
  create?: InputMaybe<CCardPrimaryCreateInput>;
  /** Delete currently connected CCardPrimary document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CCardPrimary document */
  update?: InputMaybe<CCardPrimaryUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CCardPrimary document */
  upsert?: InputMaybe<CCardPrimaryUpsertWithNestedWhereUniqueInput>;
};

export type CCardPrimaryUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<CCardPrimaryUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CCardPrimaryWhereUniqueInput;
};

export type CCardPrimaryUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CCardPrimaryUpdateInput;
  /** Unique document search */
  where: CCardPrimaryWhereUniqueInput;
};

export type CCardPrimaryUpsertInput = {
  /** Create document if it didn't exist */
  create: CCardPrimaryCreateInput;
  /** Update document if it exists */
  update: CCardPrimaryUpdateInput;
};

export type CCardPrimaryUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<CCardPrimaryUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CCardPrimaryWhereUniqueInput;
};

export type CCardPrimaryUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CCardPrimaryUpsertInput;
  /** Unique document search */
  where: CCardPrimaryWhereUniqueInput;
};

/** Identifies documents */
export type CCardPrimaryWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CCardPrimaryWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CCardPrimaryWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CCardPrimaryWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  actionGroup?: InputMaybe<CActionGroupWhereInput>;
  badge?: InputMaybe<BadgeWhereInput>;
  border?: InputMaybe<BoxShadows>;
  borderRadius?: InputMaybe<BorderRadius>;
  /** All values that are contained in given list. */
  borderRadius_in?: InputMaybe<Array<InputMaybe<BorderRadius>>>;
  /** Any other value that exists and is not equal to the given value. */
  borderRadius_not?: InputMaybe<BorderRadius>;
  /** All values that are not contained in given list. */
  borderRadius_not_in?: InputMaybe<Array<InputMaybe<BorderRadius>>>;
  /** All values that are contained in given list. */
  border_in?: InputMaybe<Array<InputMaybe<BoxShadows>>>;
  /** Any other value that exists and is not equal to the given value. */
  border_not?: InputMaybe<BoxShadows>;
  /** All values that are not contained in given list. */
  border_not_in?: InputMaybe<Array<InputMaybe<BoxShadows>>>;
  cardContentAlignment?: InputMaybe<BoxJustifyContent>;
  /** All values that are contained in given list. */
  cardContentAlignment_in?: InputMaybe<Array<InputMaybe<BoxJustifyContent>>>;
  /** Any other value that exists and is not equal to the given value. */
  cardContentAlignment_not?: InputMaybe<BoxJustifyContent>;
  /** All values that are not contained in given list. */
  cardContentAlignment_not_in?: InputMaybe<
    Array<InputMaybe<BoxJustifyContent>>
  >;
  heading?: InputMaybe<CHeadingWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AssetWhereInput>;
  numericalBlock?: InputMaybe<NumericalBlockWhereInput>;
  paragraph?: InputMaybe<CParagraphWhereInput>;
  textAlignment?: InputMaybe<CTextAlign>;
  /** All values that are contained in given list. */
  textAlignment_in?: InputMaybe<Array<InputMaybe<CTextAlign>>>;
  /** Any other value that exists and is not equal to the given value. */
  textAlignment_not?: InputMaybe<CTextAlign>;
  /** All values that are not contained in given list. */
  textAlignment_not_in?: InputMaybe<Array<InputMaybe<CTextAlign>>>;
};

/** References CCardPrimary record uniquely */
export type CCardPrimaryWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type CContainer = Entity & {
  __typename?: 'CContainer';
  blocks: Array<CBlock>;
  box?: Maybe<CBox>;
  /** Adds a HTML "id" attribute. Used for anchor links. */
  htmlId?: Maybe<Scalars['String']>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System stage field */
  stage: Stage;
  tile?: Maybe<CTile>;
};

export type CContainerBlocksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<CBlockOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CBlockWhereInput>;
};

export type CContainerBoxArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CContainerTileArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CContainerConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CContainerWhereUniqueInput;
};

/** A connection to a list of items. */
export type CContainerConnection = {
  __typename?: 'CContainerConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CContainerEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CContainerCreateInput = {
  blocks?: InputMaybe<CBlockCreateManyInlineInput>;
  box?: InputMaybe<CBoxCreateOneInlineInput>;
  htmlId?: InputMaybe<Scalars['String']>;
  tile?: InputMaybe<CTileCreateOneInlineInput>;
};

export type CContainerCreateManyInlineInput = {
  /** Create and connect multiple existing CContainer documents */
  create?: InputMaybe<Array<CContainerCreateInput>>;
};

export type CContainerCreateOneInlineInput = {
  /** Create and connect one CContainer document */
  create?: InputMaybe<CContainerCreateInput>;
};

export type CContainerCreateWithPositionInput = {
  /** Document to create */
  data: CContainerCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type CContainerEdge = {
  __typename?: 'CContainerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CContainer;
};

/** Identifies documents */
export type CContainerManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CContainerWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CContainerWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CContainerWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  blocks_every?: InputMaybe<CBlockWhereInput>;
  blocks_none?: InputMaybe<CBlockWhereInput>;
  blocks_some?: InputMaybe<CBlockWhereInput>;
  box?: InputMaybe<CBoxWhereInput>;
  htmlId?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  htmlId_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  htmlId_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  htmlId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  htmlId_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  htmlId_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  htmlId_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  htmlId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  htmlId_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  htmlId_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  tile?: InputMaybe<CTileWhereInput>;
};

export enum CContainerOrderByInput {
  HtmlIdAsc = 'htmlId_ASC',
  HtmlIdDesc = 'htmlId_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type CContainerParent = Page;

export type CContainerParentConnectInput = {
  Page?: InputMaybe<PageConnectInput>;
};

export type CContainerParentCreateInput = {
  Page?: InputMaybe<PageCreateInput>;
};

export type CContainerParentCreateManyInlineInput = {
  /** Connect multiple existing CContainerParent documents */
  connect?: InputMaybe<Array<CContainerParentWhereUniqueInput>>;
  /** Create and connect multiple existing CContainerParent documents */
  create?: InputMaybe<Array<CContainerParentCreateInput>>;
};

export type CContainerParentCreateOneInlineInput = {
  /** Connect one existing CContainerParent document */
  connect?: InputMaybe<CContainerParentWhereUniqueInput>;
  /** Create and connect one CContainerParent document */
  create?: InputMaybe<CContainerParentCreateInput>;
};

export type CContainerParentUpdateInput = {
  Page?: InputMaybe<PageUpdateInput>;
};

export type CContainerParentUpdateManyInlineInput = {
  /** Connect multiple existing CContainerParent documents */
  connect?: InputMaybe<Array<CContainerParentConnectInput>>;
  /** Create and connect multiple CContainerParent documents */
  create?: InputMaybe<Array<CContainerParentCreateInput>>;
  /** Delete multiple CContainerParent documents */
  delete?: InputMaybe<Array<CContainerParentWhereUniqueInput>>;
  /** Disconnect multiple CContainerParent documents */
  disconnect?: InputMaybe<Array<CContainerParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing CContainerParent documents */
  set?: InputMaybe<Array<CContainerParentWhereUniqueInput>>;
  /** Update multiple CContainerParent documents */
  update?: InputMaybe<Array<CContainerParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple CContainerParent documents */
  upsert?: InputMaybe<Array<CContainerParentUpsertWithNestedWhereUniqueInput>>;
};

export type CContainerParentUpdateManyWithNestedWhereInput = {
  Page?: InputMaybe<PageUpdateManyWithNestedWhereInput>;
};

export type CContainerParentUpdateOneInlineInput = {
  /** Connect existing CContainerParent document */
  connect?: InputMaybe<CContainerParentWhereUniqueInput>;
  /** Create and connect one CContainerParent document */
  create?: InputMaybe<CContainerParentCreateInput>;
  /** Delete currently connected CContainerParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected CContainerParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single CContainerParent document */
  update?: InputMaybe<CContainerParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CContainerParent document */
  upsert?: InputMaybe<CContainerParentUpsertWithNestedWhereUniqueInput>;
};

export type CContainerParentUpdateWithNestedWhereUniqueInput = {
  Page?: InputMaybe<PageUpdateWithNestedWhereUniqueInput>;
};

export type CContainerParentUpsertWithNestedWhereUniqueInput = {
  Page?: InputMaybe<PageUpsertWithNestedWhereUniqueInput>;
};

export type CContainerParentWhereInput = {
  Page?: InputMaybe<PageWhereInput>;
};

export type CContainerParentWhereUniqueInput = {
  Page?: InputMaybe<PageWhereUniqueInput>;
};

export type CContainerUpdateInput = {
  blocks?: InputMaybe<CBlockUpdateManyInlineInput>;
  box?: InputMaybe<CBoxUpdateOneInlineInput>;
  htmlId?: InputMaybe<Scalars['String']>;
  tile?: InputMaybe<CTileUpdateOneInlineInput>;
};

export type CContainerUpdateManyInlineInput = {
  /** Create and connect multiple CContainer component instances */
  create?: InputMaybe<Array<CContainerCreateWithPositionInput>>;
  /** Delete multiple CContainer documents */
  delete?: InputMaybe<Array<CContainerWhereUniqueInput>>;
  /** Update multiple CContainer component instances */
  update?: InputMaybe<
    Array<CContainerUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CContainer component instances */
  upsert?: InputMaybe<
    Array<CContainerUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CContainerUpdateManyInput = {
  htmlId?: InputMaybe<Scalars['String']>;
};

export type CContainerUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CContainerUpdateManyInput;
  /** Document search */
  where: CContainerWhereInput;
};

export type CContainerUpdateOneInlineInput = {
  /** Create and connect one CContainer document */
  create?: InputMaybe<CContainerCreateInput>;
  /** Delete currently connected CContainer document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CContainer document */
  update?: InputMaybe<CContainerUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CContainer document */
  upsert?: InputMaybe<CContainerUpsertWithNestedWhereUniqueInput>;
};

export type CContainerUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<CContainerUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CContainerWhereUniqueInput;
};

export type CContainerUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CContainerUpdateInput;
  /** Unique document search */
  where: CContainerWhereUniqueInput;
};

export type CContainerUpsertInput = {
  /** Create document if it didn't exist */
  create: CContainerCreateInput;
  /** Update document if it exists */
  update: CContainerUpdateInput;
};

export type CContainerUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<CContainerUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CContainerWhereUniqueInput;
};

export type CContainerUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CContainerUpsertInput;
  /** Unique document search */
  where: CContainerWhereUniqueInput;
};

/** Identifies documents */
export type CContainerWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CContainerWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CContainerWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CContainerWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  blocks_every?: InputMaybe<CBlockWhereInput>;
  blocks_none?: InputMaybe<CBlockWhereInput>;
  blocks_some?: InputMaybe<CBlockWhereInput>;
  box?: InputMaybe<CBoxWhereInput>;
  htmlId?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  htmlId_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  htmlId_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  htmlId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  htmlId_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  htmlId_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  htmlId_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  htmlId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  htmlId_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  htmlId_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  tile?: InputMaybe<CTileWhereInput>;
};

/** References CContainer record uniquely */
export type CContainerWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type CCustomComponent = Entity & {
  __typename?: 'CCustomComponent';
  component?: Maybe<CCustomComponentcomponentUnion>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<CCustomComponent>;
  name?: Maybe<Scalars['String']>;
  /** System stage field */
  stage: Stage;
};

export type CCustomComponentComponentArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CCustomComponentLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type CCustomComponentConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CCustomComponentWhereUniqueInput;
};

/** A connection to a list of items. */
export type CCustomComponentConnection = {
  __typename?: 'CCustomComponentConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CCustomComponentEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CCustomComponentCreateInput = {
  component?: InputMaybe<CCustomComponentcomponentUnionCreateOneInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<CCustomComponentCreateLocalizationsInput>;
  /** name input for default locale (default) */
  name?: InputMaybe<Scalars['String']>;
};

export type CCustomComponentCreateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type CCustomComponentCreateLocalizationInput = {
  /** Localization input */
  data: CCustomComponentCreateLocalizationDataInput;
  locale: Locale;
};

export type CCustomComponentCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<CCustomComponentCreateLocalizationInput>>;
};

export type CCustomComponentCreateManyInlineInput = {
  /** Create and connect multiple existing CCustomComponent documents */
  create?: InputMaybe<Array<CCustomComponentCreateInput>>;
};

export type CCustomComponentCreateOneInlineInput = {
  /** Create and connect one CCustomComponent document */
  create?: InputMaybe<CCustomComponentCreateInput>;
};

export type CCustomComponentCreateWithPositionInput = {
  /** Document to create */
  data: CCustomComponentCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type CCustomComponentEdge = {
  __typename?: 'CCustomComponentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CCustomComponent;
};

/** Identifies documents */
export type CCustomComponentManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CCustomComponentWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CCustomComponentWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CCustomComponentWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  /** All values in which the modular component is connected to the given models */
  component?: InputMaybe<CCustomComponentcomponentUnionWhereInput>;
  /** All values in which the union is empty. */
  component_empty?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
};

export enum CCustomComponentOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
}

export type CCustomComponentParent = CBlock;

export type CCustomComponentParentConnectInput = {
  CBlock?: InputMaybe<CBlockConnectInput>;
};

export type CCustomComponentParentCreateInput = {
  CBlock?: InputMaybe<CBlockCreateInput>;
};

export type CCustomComponentParentCreateManyInlineInput = {
  /** Connect multiple existing CCustomComponentParent documents */
  connect?: InputMaybe<Array<CCustomComponentParentWhereUniqueInput>>;
  /** Create and connect multiple existing CCustomComponentParent documents */
  create?: InputMaybe<Array<CCustomComponentParentCreateInput>>;
};

export type CCustomComponentParentCreateOneInlineInput = {
  /** Connect one existing CCustomComponentParent document */
  connect?: InputMaybe<CCustomComponentParentWhereUniqueInput>;
  /** Create and connect one CCustomComponentParent document */
  create?: InputMaybe<CCustomComponentParentCreateInput>;
};

export type CCustomComponentParentUpdateInput = {
  CBlock?: InputMaybe<CBlockUpdateInput>;
};

export type CCustomComponentParentUpdateManyInlineInput = {
  /** Connect multiple existing CCustomComponentParent documents */
  connect?: InputMaybe<Array<CCustomComponentParentConnectInput>>;
  /** Create and connect multiple CCustomComponentParent documents */
  create?: InputMaybe<Array<CCustomComponentParentCreateInput>>;
  /** Delete multiple CCustomComponentParent documents */
  delete?: InputMaybe<Array<CCustomComponentParentWhereUniqueInput>>;
  /** Disconnect multiple CCustomComponentParent documents */
  disconnect?: InputMaybe<Array<CCustomComponentParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing CCustomComponentParent documents */
  set?: InputMaybe<Array<CCustomComponentParentWhereUniqueInput>>;
  /** Update multiple CCustomComponentParent documents */
  update?: InputMaybe<
    Array<CCustomComponentParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple CCustomComponentParent documents */
  upsert?: InputMaybe<
    Array<CCustomComponentParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type CCustomComponentParentUpdateManyWithNestedWhereInput = {
  CBlock?: InputMaybe<CBlockUpdateManyWithNestedWhereInput>;
};

export type CCustomComponentParentUpdateOneInlineInput = {
  /** Connect existing CCustomComponentParent document */
  connect?: InputMaybe<CCustomComponentParentWhereUniqueInput>;
  /** Create and connect one CCustomComponentParent document */
  create?: InputMaybe<CCustomComponentParentCreateInput>;
  /** Delete currently connected CCustomComponentParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected CCustomComponentParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single CCustomComponentParent document */
  update?: InputMaybe<CCustomComponentParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CCustomComponentParent document */
  upsert?: InputMaybe<CCustomComponentParentUpsertWithNestedWhereUniqueInput>;
};

export type CCustomComponentParentUpdateWithNestedWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockUpdateWithNestedWhereUniqueInput>;
};

export type CCustomComponentParentUpsertWithNestedWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockUpsertWithNestedWhereUniqueInput>;
};

export type CCustomComponentParentWhereInput = {
  CBlock?: InputMaybe<CBlockWhereInput>;
};

export type CCustomComponentParentWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockWhereUniqueInput>;
};

export type CCustomComponentUpdateInput = {
  component?: InputMaybe<CCustomComponentcomponentUnionUpdateOneInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<CCustomComponentUpdateLocalizationsInput>;
  /** name input for default locale (default) */
  name?: InputMaybe<Scalars['String']>;
};

export type CCustomComponentUpdateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type CCustomComponentUpdateLocalizationInput = {
  data: CCustomComponentUpdateLocalizationDataInput;
  locale: Locale;
};

export type CCustomComponentUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<CCustomComponentCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<CCustomComponentUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<CCustomComponentUpsertLocalizationInput>>;
};

export type CCustomComponentUpdateManyInlineInput = {
  /** Create and connect multiple CCustomComponent component instances */
  create?: InputMaybe<Array<CCustomComponentCreateWithPositionInput>>;
  /** Delete multiple CCustomComponent documents */
  delete?: InputMaybe<Array<CCustomComponentWhereUniqueInput>>;
  /** Update multiple CCustomComponent component instances */
  update?: InputMaybe<
    Array<CCustomComponentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CCustomComponent component instances */
  upsert?: InputMaybe<
    Array<CCustomComponentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CCustomComponentUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<CCustomComponentUpdateManyLocalizationsInput>;
  /** name input for default locale (default) */
  name?: InputMaybe<Scalars['String']>;
};

export type CCustomComponentUpdateManyLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type CCustomComponentUpdateManyLocalizationInput = {
  data: CCustomComponentUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type CCustomComponentUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<CCustomComponentUpdateManyLocalizationInput>>;
};

export type CCustomComponentUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CCustomComponentUpdateManyInput;
  /** Document search */
  where: CCustomComponentWhereInput;
};

export type CCustomComponentUpdateOneInlineInput = {
  /** Create and connect one CCustomComponent document */
  create?: InputMaybe<CCustomComponentCreateInput>;
  /** Delete currently connected CCustomComponent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CCustomComponent document */
  update?: InputMaybe<CCustomComponentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CCustomComponent document */
  upsert?: InputMaybe<CCustomComponentUpsertWithNestedWhereUniqueInput>;
};

export type CCustomComponentUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<CCustomComponentUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CCustomComponentWhereUniqueInput;
};

export type CCustomComponentUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CCustomComponentUpdateInput;
  /** Unique document search */
  where: CCustomComponentWhereUniqueInput;
};

export type CCustomComponentUpsertInput = {
  /** Create document if it didn't exist */
  create: CCustomComponentCreateInput;
  /** Update document if it exists */
  update: CCustomComponentUpdateInput;
};

export type CCustomComponentUpsertLocalizationInput = {
  create: CCustomComponentCreateLocalizationDataInput;
  locale: Locale;
  update: CCustomComponentUpdateLocalizationDataInput;
};

export type CCustomComponentUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<CCustomComponentUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CCustomComponentWhereUniqueInput;
};

export type CCustomComponentUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CCustomComponentUpsertInput;
  /** Unique document search */
  where: CCustomComponentWhereUniqueInput;
};

/** Identifies documents */
export type CCustomComponentWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CCustomComponentWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CCustomComponentWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CCustomComponentWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  /** All values in which the modular component is connected to the given models */
  component?: InputMaybe<CCustomComponentcomponentUnionWhereInput>;
  /** All values in which the union is empty. */
  component_empty?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']>;
};

/** References CCustomComponent record uniquely */
export type CCustomComponentWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type CCustomComponentcomponentUnion =
  | BraidComponent
  | BugcrowdForm
  | CForm
  | InterviewBuilder
  | InvestorInformation
  | JobAdFeatureChart
  | LoaDataLab
  | ProfileVisibilityCardGroup
  | SearchBox
  | SocialMediaShare
  | WebForm;

export type CCustomComponentcomponentUnionConnectInput = {
  BraidComponent?: InputMaybe<BraidComponentConnectInput>;
  BugcrowdForm?: InputMaybe<BugcrowdFormConnectInput>;
  CForm?: InputMaybe<CFormConnectInput>;
  InterviewBuilder?: InputMaybe<InterviewBuilderConnectInput>;
  InvestorInformation?: InputMaybe<InvestorInformationConnectInput>;
  JobAdFeatureChart?: InputMaybe<JobAdFeatureChartConnectInput>;
  LoaDataLab?: InputMaybe<LoaDataLabConnectInput>;
  ProfileVisibilityCardGroup?: InputMaybe<ProfileVisibilityCardGroupConnectInput>;
  SearchBox?: InputMaybe<SearchBoxConnectInput>;
  SocialMediaShare?: InputMaybe<SocialMediaShareConnectInput>;
  WebForm?: InputMaybe<WebFormConnectInput>;
};

export type CCustomComponentcomponentUnionCreateInput = {
  BraidComponent?: InputMaybe<BraidComponentCreateInput>;
  BugcrowdForm?: InputMaybe<BugcrowdFormCreateInput>;
  CForm?: InputMaybe<CFormCreateInput>;
  InterviewBuilder?: InputMaybe<InterviewBuilderCreateInput>;
  InvestorInformation?: InputMaybe<InvestorInformationCreateInput>;
  JobAdFeatureChart?: InputMaybe<JobAdFeatureChartCreateInput>;
  LoaDataLab?: InputMaybe<LoaDataLabCreateInput>;
  ProfileVisibilityCardGroup?: InputMaybe<ProfileVisibilityCardGroupCreateInput>;
  SearchBox?: InputMaybe<SearchBoxCreateInput>;
  SocialMediaShare?: InputMaybe<SocialMediaShareCreateInput>;
  WebForm?: InputMaybe<WebFormCreateInput>;
};

export type CCustomComponentcomponentUnionCreateManyInlineInput = {
  /** Create and connect multiple existing CCustomComponentcomponentUnion documents */
  create?: InputMaybe<Array<CCustomComponentcomponentUnionCreateInput>>;
};

export type CCustomComponentcomponentUnionCreateOneInlineInput = {
  /** Create and connect one CCustomComponentcomponentUnion document */
  create?: InputMaybe<CCustomComponentcomponentUnionCreateInput>;
};

export type CCustomComponentcomponentUnionCreateWithPositionInput = {
  BraidComponent?: InputMaybe<BraidComponentCreateWithPositionInput>;
  BugcrowdForm?: InputMaybe<BugcrowdFormCreateWithPositionInput>;
  CForm?: InputMaybe<CFormCreateWithPositionInput>;
  InterviewBuilder?: InputMaybe<InterviewBuilderCreateWithPositionInput>;
  InvestorInformation?: InputMaybe<InvestorInformationCreateWithPositionInput>;
  JobAdFeatureChart?: InputMaybe<JobAdFeatureChartCreateWithPositionInput>;
  LoaDataLab?: InputMaybe<LoaDataLabCreateWithPositionInput>;
  ProfileVisibilityCardGroup?: InputMaybe<ProfileVisibilityCardGroupCreateWithPositionInput>;
  SearchBox?: InputMaybe<SearchBoxCreateWithPositionInput>;
  SocialMediaShare?: InputMaybe<SocialMediaShareCreateWithPositionInput>;
  WebForm?: InputMaybe<WebFormCreateWithPositionInput>;
};

export type CCustomComponentcomponentUnionUpdateInput = {
  BraidComponent?: InputMaybe<BraidComponentUpdateInput>;
  BugcrowdForm?: InputMaybe<BugcrowdFormUpdateInput>;
  CForm?: InputMaybe<CFormUpdateInput>;
  InterviewBuilder?: InputMaybe<InterviewBuilderUpdateInput>;
  InvestorInformation?: InputMaybe<InvestorInformationUpdateInput>;
  JobAdFeatureChart?: InputMaybe<JobAdFeatureChartUpdateInput>;
  LoaDataLab?: InputMaybe<LoaDataLabUpdateInput>;
  ProfileVisibilityCardGroup?: InputMaybe<ProfileVisibilityCardGroupUpdateInput>;
  SearchBox?: InputMaybe<SearchBoxUpdateInput>;
  SocialMediaShare?: InputMaybe<SocialMediaShareUpdateInput>;
  WebForm?: InputMaybe<WebFormUpdateInput>;
};

export type CCustomComponentcomponentUnionUpdateManyInlineInput = {
  /** Create and connect multiple CCustomComponentcomponentUnion component instances */
  create?: InputMaybe<
    Array<CCustomComponentcomponentUnionCreateWithPositionInput>
  >;
  /** Delete multiple CCustomComponentcomponentUnion documents */
  delete?: InputMaybe<Array<CCustomComponentcomponentUnionWhereUniqueInput>>;
  /** Update multiple CCustomComponentcomponentUnion component instances */
  update?: InputMaybe<
    Array<CCustomComponentcomponentUnionUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CCustomComponentcomponentUnion component instances */
  upsert?: InputMaybe<
    Array<CCustomComponentcomponentUnionUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CCustomComponentcomponentUnionUpdateManyWithNestedWhereInput = {
  BraidComponent?: InputMaybe<BraidComponentUpdateManyWithNestedWhereInput>;
  BugcrowdForm?: InputMaybe<BugcrowdFormUpdateManyWithNestedWhereInput>;
  CForm?: InputMaybe<CFormUpdateManyWithNestedWhereInput>;
  InterviewBuilder?: InputMaybe<InterviewBuilderUpdateManyWithNestedWhereInput>;
  InvestorInformation?: InputMaybe<InvestorInformationUpdateManyWithNestedWhereInput>;
  JobAdFeatureChart?: InputMaybe<JobAdFeatureChartUpdateManyWithNestedWhereInput>;
  LoaDataLab?: InputMaybe<LoaDataLabUpdateManyWithNestedWhereInput>;
  ProfileVisibilityCardGroup?: InputMaybe<ProfileVisibilityCardGroupUpdateManyWithNestedWhereInput>;
  SearchBox?: InputMaybe<SearchBoxUpdateManyWithNestedWhereInput>;
  SocialMediaShare?: InputMaybe<SocialMediaShareUpdateManyWithNestedWhereInput>;
  WebForm?: InputMaybe<WebFormUpdateManyWithNestedWhereInput>;
};

export type CCustomComponentcomponentUnionUpdateOneInlineInput = {
  /** Create and connect one CCustomComponentcomponentUnion document */
  create?: InputMaybe<CCustomComponentcomponentUnionCreateInput>;
  /** Delete currently connected CCustomComponentcomponentUnion document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CCustomComponentcomponentUnion document */
  update?: InputMaybe<CCustomComponentcomponentUnionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CCustomComponentcomponentUnion document */
  upsert?: InputMaybe<CCustomComponentcomponentUnionUpsertWithNestedWhereUniqueInput>;
};

export type CCustomComponentcomponentUnionUpdateWithNestedWhereUniqueAndPositionInput =
  {
    BraidComponent?: InputMaybe<BraidComponentUpdateWithNestedWhereUniqueAndPositionInput>;
    BugcrowdForm?: InputMaybe<BugcrowdFormUpdateWithNestedWhereUniqueAndPositionInput>;
    CForm?: InputMaybe<CFormUpdateWithNestedWhereUniqueAndPositionInput>;
    InterviewBuilder?: InputMaybe<InterviewBuilderUpdateWithNestedWhereUniqueAndPositionInput>;
    InvestorInformation?: InputMaybe<InvestorInformationUpdateWithNestedWhereUniqueAndPositionInput>;
    JobAdFeatureChart?: InputMaybe<JobAdFeatureChartUpdateWithNestedWhereUniqueAndPositionInput>;
    LoaDataLab?: InputMaybe<LoaDataLabUpdateWithNestedWhereUniqueAndPositionInput>;
    ProfileVisibilityCardGroup?: InputMaybe<ProfileVisibilityCardGroupUpdateWithNestedWhereUniqueAndPositionInput>;
    SearchBox?: InputMaybe<SearchBoxUpdateWithNestedWhereUniqueAndPositionInput>;
    SocialMediaShare?: InputMaybe<SocialMediaShareUpdateWithNestedWhereUniqueAndPositionInput>;
    WebForm?: InputMaybe<WebFormUpdateWithNestedWhereUniqueAndPositionInput>;
  };

export type CCustomComponentcomponentUnionUpdateWithNestedWhereUniqueInput = {
  BraidComponent?: InputMaybe<BraidComponentUpdateWithNestedWhereUniqueInput>;
  BugcrowdForm?: InputMaybe<BugcrowdFormUpdateWithNestedWhereUniqueInput>;
  CForm?: InputMaybe<CFormUpdateWithNestedWhereUniqueInput>;
  InterviewBuilder?: InputMaybe<InterviewBuilderUpdateWithNestedWhereUniqueInput>;
  InvestorInformation?: InputMaybe<InvestorInformationUpdateWithNestedWhereUniqueInput>;
  JobAdFeatureChart?: InputMaybe<JobAdFeatureChartUpdateWithNestedWhereUniqueInput>;
  LoaDataLab?: InputMaybe<LoaDataLabUpdateWithNestedWhereUniqueInput>;
  ProfileVisibilityCardGroup?: InputMaybe<ProfileVisibilityCardGroupUpdateWithNestedWhereUniqueInput>;
  SearchBox?: InputMaybe<SearchBoxUpdateWithNestedWhereUniqueInput>;
  SocialMediaShare?: InputMaybe<SocialMediaShareUpdateWithNestedWhereUniqueInput>;
  WebForm?: InputMaybe<WebFormUpdateWithNestedWhereUniqueInput>;
};

export type CCustomComponentcomponentUnionUpsertWithNestedWhereUniqueAndPositionInput =
  {
    BraidComponent?: InputMaybe<BraidComponentUpsertWithNestedWhereUniqueAndPositionInput>;
    BugcrowdForm?: InputMaybe<BugcrowdFormUpsertWithNestedWhereUniqueAndPositionInput>;
    CForm?: InputMaybe<CFormUpsertWithNestedWhereUniqueAndPositionInput>;
    InterviewBuilder?: InputMaybe<InterviewBuilderUpsertWithNestedWhereUniqueAndPositionInput>;
    InvestorInformation?: InputMaybe<InvestorInformationUpsertWithNestedWhereUniqueAndPositionInput>;
    JobAdFeatureChart?: InputMaybe<JobAdFeatureChartUpsertWithNestedWhereUniqueAndPositionInput>;
    LoaDataLab?: InputMaybe<LoaDataLabUpsertWithNestedWhereUniqueAndPositionInput>;
    ProfileVisibilityCardGroup?: InputMaybe<ProfileVisibilityCardGroupUpsertWithNestedWhereUniqueAndPositionInput>;
    SearchBox?: InputMaybe<SearchBoxUpsertWithNestedWhereUniqueAndPositionInput>;
    SocialMediaShare?: InputMaybe<SocialMediaShareUpsertWithNestedWhereUniqueAndPositionInput>;
    WebForm?: InputMaybe<WebFormUpsertWithNestedWhereUniqueAndPositionInput>;
  };

export type CCustomComponentcomponentUnionUpsertWithNestedWhereUniqueInput = {
  BraidComponent?: InputMaybe<BraidComponentUpsertWithNestedWhereUniqueInput>;
  BugcrowdForm?: InputMaybe<BugcrowdFormUpsertWithNestedWhereUniqueInput>;
  CForm?: InputMaybe<CFormUpsertWithNestedWhereUniqueInput>;
  InterviewBuilder?: InputMaybe<InterviewBuilderUpsertWithNestedWhereUniqueInput>;
  InvestorInformation?: InputMaybe<InvestorInformationUpsertWithNestedWhereUniqueInput>;
  JobAdFeatureChart?: InputMaybe<JobAdFeatureChartUpsertWithNestedWhereUniqueInput>;
  LoaDataLab?: InputMaybe<LoaDataLabUpsertWithNestedWhereUniqueInput>;
  ProfileVisibilityCardGroup?: InputMaybe<ProfileVisibilityCardGroupUpsertWithNestedWhereUniqueInput>;
  SearchBox?: InputMaybe<SearchBoxUpsertWithNestedWhereUniqueInput>;
  SocialMediaShare?: InputMaybe<SocialMediaShareUpsertWithNestedWhereUniqueInput>;
  WebForm?: InputMaybe<WebFormUpsertWithNestedWhereUniqueInput>;
};

export type CCustomComponentcomponentUnionWhereInput = {
  BraidComponent?: InputMaybe<BraidComponentWhereInput>;
  BugcrowdForm?: InputMaybe<BugcrowdFormWhereInput>;
  CForm?: InputMaybe<CFormWhereInput>;
  InterviewBuilder?: InputMaybe<InterviewBuilderWhereInput>;
  InvestorInformation?: InputMaybe<InvestorInformationWhereInput>;
  JobAdFeatureChart?: InputMaybe<JobAdFeatureChartWhereInput>;
  LoaDataLab?: InputMaybe<LoaDataLabWhereInput>;
  ProfileVisibilityCardGroup?: InputMaybe<ProfileVisibilityCardGroupWhereInput>;
  SearchBox?: InputMaybe<SearchBoxWhereInput>;
  SocialMediaShare?: InputMaybe<SocialMediaShareWhereInput>;
  WebForm?: InputMaybe<WebFormWhereInput>;
};

export type CCustomComponentcomponentUnionWhereUniqueInput = {
  BraidComponent?: InputMaybe<BraidComponentWhereUniqueInput>;
  BugcrowdForm?: InputMaybe<BugcrowdFormWhereUniqueInput>;
  CForm?: InputMaybe<CFormWhereUniqueInput>;
  InterviewBuilder?: InputMaybe<InterviewBuilderWhereUniqueInput>;
  InvestorInformation?: InputMaybe<InvestorInformationWhereUniqueInput>;
  JobAdFeatureChart?: InputMaybe<JobAdFeatureChartWhereUniqueInput>;
  LoaDataLab?: InputMaybe<LoaDataLabWhereUniqueInput>;
  ProfileVisibilityCardGroup?: InputMaybe<ProfileVisibilityCardGroupWhereUniqueInput>;
  SearchBox?: InputMaybe<SearchBoxWhereUniqueInput>;
  SocialMediaShare?: InputMaybe<SocialMediaShareWhereUniqueInput>;
  WebForm?: InputMaybe<WebFormWhereUniqueInput>;
};

/** A wrapper for Form model */
export type CForm = Entity & {
  __typename?: 'CForm';
  form?: Maybe<Form>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System stage field */
  stage: Stage;
};

/** A wrapper for Form model */
export type CFormFormArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CFormConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CFormWhereUniqueInput;
};

/** A connection to a list of items. */
export type CFormConnection = {
  __typename?: 'CFormConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CFormEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CFormCreateInput = {
  form?: InputMaybe<FormCreateOneInlineInput>;
};

export type CFormCreateManyInlineInput = {
  /** Create and connect multiple existing CForm documents */
  create?: InputMaybe<Array<CFormCreateInput>>;
};

export type CFormCreateOneInlineInput = {
  /** Create and connect one CForm document */
  create?: InputMaybe<CFormCreateInput>;
};

export type CFormCreateWithPositionInput = {
  /** Document to create */
  data: CFormCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type CFormEdge = {
  __typename?: 'CFormEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CForm;
};

/** Identifies documents */
export type CFormManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CFormWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CFormWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CFormWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  form?: InputMaybe<FormWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
};

export enum CFormOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type CFormParent = CCustomComponent;

export type CFormParentConnectInput = {
  CCustomComponent?: InputMaybe<CCustomComponentConnectInput>;
};

export type CFormParentCreateInput = {
  CCustomComponent?: InputMaybe<CCustomComponentCreateInput>;
};

export type CFormParentCreateManyInlineInput = {
  /** Create and connect multiple existing CFormParent documents */
  create?: InputMaybe<Array<CFormParentCreateInput>>;
};

export type CFormParentCreateOneInlineInput = {
  /** Create and connect one CFormParent document */
  create?: InputMaybe<CFormParentCreateInput>;
};

export type CFormParentCreateWithPositionInput = {
  CCustomComponent?: InputMaybe<CCustomComponentCreateWithPositionInput>;
};

export type CFormParentUpdateInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpdateInput>;
};

export type CFormParentUpdateManyInlineInput = {
  /** Create and connect multiple CFormParent component instances */
  create?: InputMaybe<Array<CFormParentCreateWithPositionInput>>;
  /** Delete multiple CFormParent documents */
  delete?: InputMaybe<Array<CFormParentWhereUniqueInput>>;
  /** Update multiple CFormParent component instances */
  update?: InputMaybe<
    Array<CFormParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CFormParent component instances */
  upsert?: InputMaybe<
    Array<CFormParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CFormParentUpdateManyWithNestedWhereInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpdateManyWithNestedWhereInput>;
};

export type CFormParentUpdateOneInlineInput = {
  /** Create and connect one CFormParent document */
  create?: InputMaybe<CFormParentCreateInput>;
  /** Delete currently connected CFormParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CFormParent document */
  update?: InputMaybe<CFormParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CFormParent document */
  upsert?: InputMaybe<CFormParentUpsertWithNestedWhereUniqueInput>;
};

export type CFormParentUpdateWithNestedWhereUniqueAndPositionInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type CFormParentUpdateWithNestedWhereUniqueInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpdateWithNestedWhereUniqueInput>;
};

export type CFormParentUpsertWithNestedWhereUniqueAndPositionInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type CFormParentUpsertWithNestedWhereUniqueInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpsertWithNestedWhereUniqueInput>;
};

export type CFormParentWhereInput = {
  CCustomComponent?: InputMaybe<CCustomComponentWhereInput>;
};

export type CFormParentWhereUniqueInput = {
  CCustomComponent?: InputMaybe<CCustomComponentWhereUniqueInput>;
};

/** integrated form type */
export enum CFormType {
  MailChimp = 'mailChimp',
  Salesforce = 'salesforce',
  SaraEnquiry = 'saraEnquiry',
}

export type CFormUpdateInput = {
  form?: InputMaybe<FormUpdateOneInlineInput>;
};

export type CFormUpdateManyInlineInput = {
  /** Create and connect multiple CForm component instances */
  create?: InputMaybe<Array<CFormCreateWithPositionInput>>;
  /** Delete multiple CForm documents */
  delete?: InputMaybe<Array<CFormWhereUniqueInput>>;
  /** Update multiple CForm component instances */
  update?: InputMaybe<Array<CFormUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple CForm component instances */
  upsert?: InputMaybe<Array<CFormUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type CFormUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']>;
};

export type CFormUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CFormUpdateManyInput;
  /** Document search */
  where: CFormWhereInput;
};

export type CFormUpdateOneInlineInput = {
  /** Create and connect one CForm document */
  create?: InputMaybe<CFormCreateInput>;
  /** Delete currently connected CForm document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CForm document */
  update?: InputMaybe<CFormUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CForm document */
  upsert?: InputMaybe<CFormUpsertWithNestedWhereUniqueInput>;
};

export type CFormUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<CFormUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CFormWhereUniqueInput;
};

export type CFormUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CFormUpdateInput;
  /** Unique document search */
  where: CFormWhereUniqueInput;
};

export type CFormUpsertInput = {
  /** Create document if it didn't exist */
  create: CFormCreateInput;
  /** Update document if it exists */
  update: CFormUpdateInput;
};

export type CFormUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<CFormUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CFormWhereUniqueInput;
};

export type CFormUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CFormUpsertInput;
  /** Unique document search */
  where: CFormWhereUniqueInput;
};

/** Identifies documents */
export type CFormWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CFormWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CFormWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CFormWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  form?: InputMaybe<FormWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
};

/** References CForm record uniquely */
export type CFormWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type CGallery = Entity & {
  __typename?: 'CGallery';
  assets: Array<Asset>;
  /** Render the gallery as a carousel */
  carousel?: Maybe<Scalars['Boolean']>;
  /** The unique identifier */
  id: Scalars['ID'];
  imageBorderRadius?: Maybe<BorderRadius>;
  /** System stage field */
  stage: Stage;
  tile?: Maybe<CTile>;
};

export type CGalleryAssetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetWhereInput>;
};

export type CGalleryTileArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CGalleryConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CGalleryWhereUniqueInput;
};

/** A connection to a list of items. */
export type CGalleryConnection = {
  __typename?: 'CGalleryConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CGalleryEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CGalleryCreateInput = {
  assets: AssetCreateManyInlineInput;
  carousel?: InputMaybe<Scalars['Boolean']>;
  imageBorderRadius?: InputMaybe<BorderRadius>;
  tile?: InputMaybe<CTileCreateOneInlineInput>;
};

export type CGalleryCreateManyInlineInput = {
  /** Create and connect multiple existing CGallery documents */
  create?: InputMaybe<Array<CGalleryCreateInput>>;
};

export type CGalleryCreateOneInlineInput = {
  /** Create and connect one CGallery document */
  create?: InputMaybe<CGalleryCreateInput>;
};

export type CGalleryCreateWithPositionInput = {
  /** Document to create */
  data: CGalleryCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type CGalleryEdge = {
  __typename?: 'CGalleryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CGallery;
};

/** Identifies documents */
export type CGalleryManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CGalleryWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CGalleryWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CGalleryWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  assets_every?: InputMaybe<AssetWhereInput>;
  assets_none?: InputMaybe<AssetWhereInput>;
  assets_some?: InputMaybe<AssetWhereInput>;
  carousel?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  carousel_not?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  imageBorderRadius?: InputMaybe<BorderRadius>;
  /** All values that are contained in given list. */
  imageBorderRadius_in?: InputMaybe<Array<InputMaybe<BorderRadius>>>;
  /** Any other value that exists and is not equal to the given value. */
  imageBorderRadius_not?: InputMaybe<BorderRadius>;
  /** All values that are not contained in given list. */
  imageBorderRadius_not_in?: InputMaybe<Array<InputMaybe<BorderRadius>>>;
  tile?: InputMaybe<CTileWhereInput>;
};

export enum CGalleryOrderByInput {
  CarouselAsc = 'carousel_ASC',
  CarouselDesc = 'carousel_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ImageBorderRadiusAsc = 'imageBorderRadius_ASC',
  ImageBorderRadiusDesc = 'imageBorderRadius_DESC',
}

export type CGalleryParent = CBlock;

export type CGalleryParentConnectInput = {
  CBlock?: InputMaybe<CBlockConnectInput>;
};

export type CGalleryParentCreateInput = {
  CBlock?: InputMaybe<CBlockCreateInput>;
};

export type CGalleryParentCreateManyInlineInput = {
  /** Connect multiple existing CGalleryParent documents */
  connect?: InputMaybe<Array<CGalleryParentWhereUniqueInput>>;
  /** Create and connect multiple existing CGalleryParent documents */
  create?: InputMaybe<Array<CGalleryParentCreateInput>>;
};

export type CGalleryParentCreateOneInlineInput = {
  /** Connect one existing CGalleryParent document */
  connect?: InputMaybe<CGalleryParentWhereUniqueInput>;
  /** Create and connect one CGalleryParent document */
  create?: InputMaybe<CGalleryParentCreateInput>;
};

export type CGalleryParentUpdateInput = {
  CBlock?: InputMaybe<CBlockUpdateInput>;
};

export type CGalleryParentUpdateManyInlineInput = {
  /** Connect multiple existing CGalleryParent documents */
  connect?: InputMaybe<Array<CGalleryParentConnectInput>>;
  /** Create and connect multiple CGalleryParent documents */
  create?: InputMaybe<Array<CGalleryParentCreateInput>>;
  /** Delete multiple CGalleryParent documents */
  delete?: InputMaybe<Array<CGalleryParentWhereUniqueInput>>;
  /** Disconnect multiple CGalleryParent documents */
  disconnect?: InputMaybe<Array<CGalleryParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing CGalleryParent documents */
  set?: InputMaybe<Array<CGalleryParentWhereUniqueInput>>;
  /** Update multiple CGalleryParent documents */
  update?: InputMaybe<Array<CGalleryParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple CGalleryParent documents */
  upsert?: InputMaybe<Array<CGalleryParentUpsertWithNestedWhereUniqueInput>>;
};

export type CGalleryParentUpdateManyWithNestedWhereInput = {
  CBlock?: InputMaybe<CBlockUpdateManyWithNestedWhereInput>;
};

export type CGalleryParentUpdateOneInlineInput = {
  /** Connect existing CGalleryParent document */
  connect?: InputMaybe<CGalleryParentWhereUniqueInput>;
  /** Create and connect one CGalleryParent document */
  create?: InputMaybe<CGalleryParentCreateInput>;
  /** Delete currently connected CGalleryParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected CGalleryParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single CGalleryParent document */
  update?: InputMaybe<CGalleryParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CGalleryParent document */
  upsert?: InputMaybe<CGalleryParentUpsertWithNestedWhereUniqueInput>;
};

export type CGalleryParentUpdateWithNestedWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockUpdateWithNestedWhereUniqueInput>;
};

export type CGalleryParentUpsertWithNestedWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockUpsertWithNestedWhereUniqueInput>;
};

export type CGalleryParentWhereInput = {
  CBlock?: InputMaybe<CBlockWhereInput>;
};

export type CGalleryParentWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockWhereUniqueInput>;
};

export type CGalleryUpdateInput = {
  assets?: InputMaybe<AssetUpdateManyInlineInput>;
  carousel?: InputMaybe<Scalars['Boolean']>;
  imageBorderRadius?: InputMaybe<BorderRadius>;
  tile?: InputMaybe<CTileUpdateOneInlineInput>;
};

export type CGalleryUpdateManyInlineInput = {
  /** Create and connect multiple CGallery component instances */
  create?: InputMaybe<Array<CGalleryCreateWithPositionInput>>;
  /** Delete multiple CGallery documents */
  delete?: InputMaybe<Array<CGalleryWhereUniqueInput>>;
  /** Update multiple CGallery component instances */
  update?: InputMaybe<
    Array<CGalleryUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CGallery component instances */
  upsert?: InputMaybe<
    Array<CGalleryUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CGalleryUpdateManyInput = {
  carousel?: InputMaybe<Scalars['Boolean']>;
  imageBorderRadius?: InputMaybe<BorderRadius>;
};

export type CGalleryUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CGalleryUpdateManyInput;
  /** Document search */
  where: CGalleryWhereInput;
};

export type CGalleryUpdateOneInlineInput = {
  /** Create and connect one CGallery document */
  create?: InputMaybe<CGalleryCreateInput>;
  /** Delete currently connected CGallery document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CGallery document */
  update?: InputMaybe<CGalleryUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CGallery document */
  upsert?: InputMaybe<CGalleryUpsertWithNestedWhereUniqueInput>;
};

export type CGalleryUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<CGalleryUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CGalleryWhereUniqueInput;
};

export type CGalleryUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CGalleryUpdateInput;
  /** Unique document search */
  where: CGalleryWhereUniqueInput;
};

export type CGalleryUpsertInput = {
  /** Create document if it didn't exist */
  create: CGalleryCreateInput;
  /** Update document if it exists */
  update: CGalleryUpdateInput;
};

export type CGalleryUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<CGalleryUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CGalleryWhereUniqueInput;
};

export type CGalleryUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CGalleryUpsertInput;
  /** Unique document search */
  where: CGalleryWhereUniqueInput;
};

/** Identifies documents */
export type CGalleryWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CGalleryWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CGalleryWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CGalleryWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  assets_every?: InputMaybe<AssetWhereInput>;
  assets_none?: InputMaybe<AssetWhereInput>;
  assets_some?: InputMaybe<AssetWhereInput>;
  carousel?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  carousel_not?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  imageBorderRadius?: InputMaybe<BorderRadius>;
  /** All values that are contained in given list. */
  imageBorderRadius_in?: InputMaybe<Array<InputMaybe<BorderRadius>>>;
  /** Any other value that exists and is not equal to the given value. */
  imageBorderRadius_not?: InputMaybe<BorderRadius>;
  /** All values that are not contained in given list. */
  imageBorderRadius_not_in?: InputMaybe<Array<InputMaybe<BorderRadius>>>;
  tile?: InputMaybe<CTileWhereInput>;
};

/** References CGallery record uniquely */
export type CGalleryWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type CHeading = Entity & {
  __typename?: 'CHeading';
  /** The HTML tag.- Developer field */
  component?: Maybe<Scalars['String']>;
  /** A Custom Heading size to override Heading "Level", by default will use "Level" as the size */
  customHeadingSize?: Maybe<CustomFontSize>;
  icon?: Maybe<Icon>;
  /** The unique identifier */
  id: Scalars['ID'];
  level: Scalars['Int'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<CHeading>;
  /** Temporary override for adding additional space above the Heading */
  paddingTopOverride?: Maybe<CHeadingpaddingTopOverrideUnion>;
  /** System stage field */
  stage: Stage;
  text: Scalars['String'];
  tone?: Maybe<CTextTone>;
  weight?: Maybe<CTextWeight>;
};

export type CHeadingLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type CHeadingPaddingTopOverrideArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CHeadingConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CHeadingWhereUniqueInput;
};

/** A connection to a list of items. */
export type CHeadingConnection = {
  __typename?: 'CHeadingConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CHeadingEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CHeadingCreateInput = {
  component?: InputMaybe<Scalars['String']>;
  customHeadingSize?: InputMaybe<CustomFontSize>;
  icon?: InputMaybe<Icon>;
  level: Scalars['Int'];
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<CHeadingCreateLocalizationsInput>;
  paddingTopOverride?: InputMaybe<CHeadingpaddingTopOverrideUnionCreateOneInlineInput>;
  /** text input for default locale (default) */
  text: Scalars['String'];
  tone?: InputMaybe<CTextTone>;
  weight?: InputMaybe<CTextWeight>;
};

export type CHeadingCreateLocalizationDataInput = {
  text: Scalars['String'];
};

export type CHeadingCreateLocalizationInput = {
  /** Localization input */
  data: CHeadingCreateLocalizationDataInput;
  locale: Locale;
};

export type CHeadingCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<CHeadingCreateLocalizationInput>>;
};

export type CHeadingCreateManyInlineInput = {
  /** Create and connect multiple existing CHeading documents */
  create?: InputMaybe<Array<CHeadingCreateInput>>;
};

export type CHeadingCreateOneInlineInput = {
  /** Create and connect one CHeading document */
  create?: InputMaybe<CHeadingCreateInput>;
};

export type CHeadingCreateWithPositionInput = {
  /** Document to create */
  data: CHeadingCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type CHeadingEdge = {
  __typename?: 'CHeadingEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CHeading;
};

/** Identifies documents */
export type CHeadingManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CHeadingWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CHeadingWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CHeadingWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  component?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  component_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  component_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  component_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  component_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  component_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  component_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  component_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  component_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  component_starts_with?: InputMaybe<Scalars['String']>;
  customHeadingSize?: InputMaybe<CustomFontSize>;
  /** All values that are contained in given list. */
  customHeadingSize_in?: InputMaybe<Array<InputMaybe<CustomFontSize>>>;
  /** Any other value that exists and is not equal to the given value. */
  customHeadingSize_not?: InputMaybe<CustomFontSize>;
  /** All values that are not contained in given list. */
  customHeadingSize_not_in?: InputMaybe<Array<InputMaybe<CustomFontSize>>>;
  icon?: InputMaybe<Icon>;
  /** All values that are contained in given list. */
  icon_in?: InputMaybe<Array<InputMaybe<Icon>>>;
  /** Any other value that exists and is not equal to the given value. */
  icon_not?: InputMaybe<Icon>;
  /** All values that are not contained in given list. */
  icon_not_in?: InputMaybe<Array<InputMaybe<Icon>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  level?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  level_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  level_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  level_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** All values less than the given value. */
  level_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  level_lte?: InputMaybe<Scalars['Int']>;
  /** Any other value that exists and is not equal to the given value. */
  level_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  level_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** All values in which the modular component is connected to the given models */
  paddingTopOverride?: InputMaybe<CHeadingpaddingTopOverrideUnionWhereInput>;
  /** All values in which the union is empty. */
  paddingTopOverride_empty?: InputMaybe<Scalars['Boolean']>;
  tone?: InputMaybe<CTextTone>;
  /** All values that are contained in given list. */
  tone_in?: InputMaybe<Array<InputMaybe<CTextTone>>>;
  /** Any other value that exists and is not equal to the given value. */
  tone_not?: InputMaybe<CTextTone>;
  /** All values that are not contained in given list. */
  tone_not_in?: InputMaybe<Array<InputMaybe<CTextTone>>>;
  weight?: InputMaybe<CTextWeight>;
  /** All values that are contained in given list. */
  weight_in?: InputMaybe<Array<InputMaybe<CTextWeight>>>;
  /** Any other value that exists and is not equal to the given value. */
  weight_not?: InputMaybe<CTextWeight>;
  /** All values that are not contained in given list. */
  weight_not_in?: InputMaybe<Array<InputMaybe<CTextWeight>>>;
};

export enum CHeadingOrderByInput {
  ComponentAsc = 'component_ASC',
  ComponentDesc = 'component_DESC',
  CustomHeadingSizeAsc = 'customHeadingSize_ASC',
  CustomHeadingSizeDesc = 'customHeadingSize_DESC',
  IconAsc = 'icon_ASC',
  IconDesc = 'icon_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LevelAsc = 'level_ASC',
  LevelDesc = 'level_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  ToneAsc = 'tone_ASC',
  ToneDesc = 'tone_DESC',
  WeightAsc = 'weight_ASC',
  WeightDesc = 'weight_DESC',
}

export type CHeadingParent =
  | BannerItem
  | CBannerAward
  | CBannerInline
  | CBannerPrimary
  | CBannerShopfront
  | CBlock
  | CCardJobAd
  | CCardPrimary
  | CIconBlock
  | CImageBlock
  | CStepBlock
  | DialogItem
  | KeyStepsBlock
  | PromotionalBanner;

export type CHeadingParentConnectInput = {
  BannerItem?: InputMaybe<BannerItemConnectInput>;
  CBannerAward?: InputMaybe<CBannerAwardConnectInput>;
  CBannerInline?: InputMaybe<CBannerInlineConnectInput>;
  CBannerPrimary?: InputMaybe<CBannerPrimaryConnectInput>;
  CBannerShopfront?: InputMaybe<CBannerShopfrontConnectInput>;
  CBlock?: InputMaybe<CBlockConnectInput>;
  CCardJobAd?: InputMaybe<CCardJobAdConnectInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryConnectInput>;
  CIconBlock?: InputMaybe<CIconBlockConnectInput>;
  CImageBlock?: InputMaybe<CImageBlockConnectInput>;
  CStepBlock?: InputMaybe<CStepBlockConnectInput>;
  DialogItem?: InputMaybe<DialogItemConnectInput>;
  KeyStepsBlock?: InputMaybe<KeyStepsBlockConnectInput>;
  PromotionalBanner?: InputMaybe<PromotionalBannerConnectInput>;
};

export type CHeadingParentCreateInput = {
  BannerItem?: InputMaybe<BannerItemCreateInput>;
  CBannerAward?: InputMaybe<CBannerAwardCreateInput>;
  CBannerInline?: InputMaybe<CBannerInlineCreateInput>;
  CBannerPrimary?: InputMaybe<CBannerPrimaryCreateInput>;
  CBannerShopfront?: InputMaybe<CBannerShopfrontCreateInput>;
  CBlock?: InputMaybe<CBlockCreateInput>;
  CCardJobAd?: InputMaybe<CCardJobAdCreateInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryCreateInput>;
  CIconBlock?: InputMaybe<CIconBlockCreateInput>;
  CImageBlock?: InputMaybe<CImageBlockCreateInput>;
  CStepBlock?: InputMaybe<CStepBlockCreateInput>;
  DialogItem?: InputMaybe<DialogItemCreateInput>;
  KeyStepsBlock?: InputMaybe<KeyStepsBlockCreateInput>;
  PromotionalBanner?: InputMaybe<PromotionalBannerCreateInput>;
};

export type CHeadingParentCreateManyInlineInput = {
  /** Connect multiple existing CHeadingParent documents */
  connect?: InputMaybe<Array<CHeadingParentWhereUniqueInput>>;
  /** Create and connect multiple existing CHeadingParent documents */
  create?: InputMaybe<Array<CHeadingParentCreateInput>>;
};

export type CHeadingParentCreateOneInlineInput = {
  /** Connect one existing CHeadingParent document */
  connect?: InputMaybe<CHeadingParentWhereUniqueInput>;
  /** Create and connect one CHeadingParent document */
  create?: InputMaybe<CHeadingParentCreateInput>;
};

export type CHeadingParentUpdateInput = {
  BannerItem?: InputMaybe<BannerItemUpdateInput>;
  CBannerAward?: InputMaybe<CBannerAwardUpdateInput>;
  CBannerInline?: InputMaybe<CBannerInlineUpdateInput>;
  CBannerPrimary?: InputMaybe<CBannerPrimaryUpdateInput>;
  CBannerShopfront?: InputMaybe<CBannerShopfrontUpdateInput>;
  CBlock?: InputMaybe<CBlockUpdateInput>;
  CCardJobAd?: InputMaybe<CCardJobAdUpdateInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryUpdateInput>;
  CIconBlock?: InputMaybe<CIconBlockUpdateInput>;
  CImageBlock?: InputMaybe<CImageBlockUpdateInput>;
  CStepBlock?: InputMaybe<CStepBlockUpdateInput>;
  DialogItem?: InputMaybe<DialogItemUpdateInput>;
  KeyStepsBlock?: InputMaybe<KeyStepsBlockUpdateInput>;
  PromotionalBanner?: InputMaybe<PromotionalBannerUpdateInput>;
};

export type CHeadingParentUpdateManyInlineInput = {
  /** Connect multiple existing CHeadingParent documents */
  connect?: InputMaybe<Array<CHeadingParentConnectInput>>;
  /** Create and connect multiple CHeadingParent documents */
  create?: InputMaybe<Array<CHeadingParentCreateInput>>;
  /** Delete multiple CHeadingParent documents */
  delete?: InputMaybe<Array<CHeadingParentWhereUniqueInput>>;
  /** Disconnect multiple CHeadingParent documents */
  disconnect?: InputMaybe<Array<CHeadingParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing CHeadingParent documents */
  set?: InputMaybe<Array<CHeadingParentWhereUniqueInput>>;
  /** Update multiple CHeadingParent documents */
  update?: InputMaybe<Array<CHeadingParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple CHeadingParent documents */
  upsert?: InputMaybe<Array<CHeadingParentUpsertWithNestedWhereUniqueInput>>;
};

export type CHeadingParentUpdateManyWithNestedWhereInput = {
  BannerItem?: InputMaybe<BannerItemUpdateManyWithNestedWhereInput>;
  CBannerAward?: InputMaybe<CBannerAwardUpdateManyWithNestedWhereInput>;
  CBannerInline?: InputMaybe<CBannerInlineUpdateManyWithNestedWhereInput>;
  CBannerPrimary?: InputMaybe<CBannerPrimaryUpdateManyWithNestedWhereInput>;
  CBannerShopfront?: InputMaybe<CBannerShopfrontUpdateManyWithNestedWhereInput>;
  CBlock?: InputMaybe<CBlockUpdateManyWithNestedWhereInput>;
  CCardJobAd?: InputMaybe<CCardJobAdUpdateManyWithNestedWhereInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryUpdateManyWithNestedWhereInput>;
  CIconBlock?: InputMaybe<CIconBlockUpdateManyWithNestedWhereInput>;
  CImageBlock?: InputMaybe<CImageBlockUpdateManyWithNestedWhereInput>;
  CStepBlock?: InputMaybe<CStepBlockUpdateManyWithNestedWhereInput>;
  DialogItem?: InputMaybe<DialogItemUpdateManyWithNestedWhereInput>;
  KeyStepsBlock?: InputMaybe<KeyStepsBlockUpdateManyWithNestedWhereInput>;
  PromotionalBanner?: InputMaybe<PromotionalBannerUpdateManyWithNestedWhereInput>;
};

export type CHeadingParentUpdateOneInlineInput = {
  /** Connect existing CHeadingParent document */
  connect?: InputMaybe<CHeadingParentWhereUniqueInput>;
  /** Create and connect one CHeadingParent document */
  create?: InputMaybe<CHeadingParentCreateInput>;
  /** Delete currently connected CHeadingParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected CHeadingParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single CHeadingParent document */
  update?: InputMaybe<CHeadingParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CHeadingParent document */
  upsert?: InputMaybe<CHeadingParentUpsertWithNestedWhereUniqueInput>;
};

export type CHeadingParentUpdateWithNestedWhereUniqueInput = {
  BannerItem?: InputMaybe<BannerItemUpdateWithNestedWhereUniqueInput>;
  CBannerAward?: InputMaybe<CBannerAwardUpdateWithNestedWhereUniqueInput>;
  CBannerInline?: InputMaybe<CBannerInlineUpdateWithNestedWhereUniqueInput>;
  CBannerPrimary?: InputMaybe<CBannerPrimaryUpdateWithNestedWhereUniqueInput>;
  CBannerShopfront?: InputMaybe<CBannerShopfrontUpdateWithNestedWhereUniqueInput>;
  CBlock?: InputMaybe<CBlockUpdateWithNestedWhereUniqueInput>;
  CCardJobAd?: InputMaybe<CCardJobAdUpdateWithNestedWhereUniqueInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryUpdateWithNestedWhereUniqueInput>;
  CIconBlock?: InputMaybe<CIconBlockUpdateWithNestedWhereUniqueInput>;
  CImageBlock?: InputMaybe<CImageBlockUpdateWithNestedWhereUniqueInput>;
  CStepBlock?: InputMaybe<CStepBlockUpdateWithNestedWhereUniqueInput>;
  DialogItem?: InputMaybe<DialogItemUpdateWithNestedWhereUniqueInput>;
  KeyStepsBlock?: InputMaybe<KeyStepsBlockUpdateWithNestedWhereUniqueInput>;
  PromotionalBanner?: InputMaybe<PromotionalBannerUpdateWithNestedWhereUniqueInput>;
};

export type CHeadingParentUpsertWithNestedWhereUniqueInput = {
  BannerItem?: InputMaybe<BannerItemUpsertWithNestedWhereUniqueInput>;
  CBannerAward?: InputMaybe<CBannerAwardUpsertWithNestedWhereUniqueInput>;
  CBannerInline?: InputMaybe<CBannerInlineUpsertWithNestedWhereUniqueInput>;
  CBannerPrimary?: InputMaybe<CBannerPrimaryUpsertWithNestedWhereUniqueInput>;
  CBannerShopfront?: InputMaybe<CBannerShopfrontUpsertWithNestedWhereUniqueInput>;
  CBlock?: InputMaybe<CBlockUpsertWithNestedWhereUniqueInput>;
  CCardJobAd?: InputMaybe<CCardJobAdUpsertWithNestedWhereUniqueInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryUpsertWithNestedWhereUniqueInput>;
  CIconBlock?: InputMaybe<CIconBlockUpsertWithNestedWhereUniqueInput>;
  CImageBlock?: InputMaybe<CImageBlockUpsertWithNestedWhereUniqueInput>;
  CStepBlock?: InputMaybe<CStepBlockUpsertWithNestedWhereUniqueInput>;
  DialogItem?: InputMaybe<DialogItemUpsertWithNestedWhereUniqueInput>;
  KeyStepsBlock?: InputMaybe<KeyStepsBlockUpsertWithNestedWhereUniqueInput>;
  PromotionalBanner?: InputMaybe<PromotionalBannerUpsertWithNestedWhereUniqueInput>;
};

export type CHeadingParentWhereInput = {
  BannerItem?: InputMaybe<BannerItemWhereInput>;
  CBannerAward?: InputMaybe<CBannerAwardWhereInput>;
  CBannerInline?: InputMaybe<CBannerInlineWhereInput>;
  CBannerPrimary?: InputMaybe<CBannerPrimaryWhereInput>;
  CBannerShopfront?: InputMaybe<CBannerShopfrontWhereInput>;
  CBlock?: InputMaybe<CBlockWhereInput>;
  CCardJobAd?: InputMaybe<CCardJobAdWhereInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryWhereInput>;
  CIconBlock?: InputMaybe<CIconBlockWhereInput>;
  CImageBlock?: InputMaybe<CImageBlockWhereInput>;
  CStepBlock?: InputMaybe<CStepBlockWhereInput>;
  DialogItem?: InputMaybe<DialogItemWhereInput>;
  KeyStepsBlock?: InputMaybe<KeyStepsBlockWhereInput>;
  PromotionalBanner?: InputMaybe<PromotionalBannerWhereInput>;
};

export type CHeadingParentWhereUniqueInput = {
  BannerItem?: InputMaybe<BannerItemWhereUniqueInput>;
  CBannerAward?: InputMaybe<CBannerAwardWhereUniqueInput>;
  CBannerInline?: InputMaybe<CBannerInlineWhereUniqueInput>;
  CBannerPrimary?: InputMaybe<CBannerPrimaryWhereUniqueInput>;
  CBannerShopfront?: InputMaybe<CBannerShopfrontWhereUniqueInput>;
  CBlock?: InputMaybe<CBlockWhereUniqueInput>;
  CCardJobAd?: InputMaybe<CCardJobAdWhereUniqueInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryWhereUniqueInput>;
  CIconBlock?: InputMaybe<CIconBlockWhereUniqueInput>;
  CImageBlock?: InputMaybe<CImageBlockWhereUniqueInput>;
  CStepBlock?: InputMaybe<CStepBlockWhereUniqueInput>;
  DialogItem?: InputMaybe<DialogItemWhereUniqueInput>;
  KeyStepsBlock?: InputMaybe<KeyStepsBlockWhereUniqueInput>;
  PromotionalBanner?: InputMaybe<PromotionalBannerWhereUniqueInput>;
};

export type CHeadingUpdateInput = {
  component?: InputMaybe<Scalars['String']>;
  customHeadingSize?: InputMaybe<CustomFontSize>;
  icon?: InputMaybe<Icon>;
  level?: InputMaybe<Scalars['Int']>;
  /** Manage document localizations */
  localizations?: InputMaybe<CHeadingUpdateLocalizationsInput>;
  paddingTopOverride?: InputMaybe<CHeadingpaddingTopOverrideUnionUpdateOneInlineInput>;
  /** text input for default locale (default) */
  text?: InputMaybe<Scalars['String']>;
  tone?: InputMaybe<CTextTone>;
  weight?: InputMaybe<CTextWeight>;
};

export type CHeadingUpdateLocalizationDataInput = {
  text?: InputMaybe<Scalars['String']>;
};

export type CHeadingUpdateLocalizationInput = {
  data: CHeadingUpdateLocalizationDataInput;
  locale: Locale;
};

export type CHeadingUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<CHeadingCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<CHeadingUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<CHeadingUpsertLocalizationInput>>;
};

export type CHeadingUpdateManyInlineInput = {
  /** Create and connect multiple CHeading component instances */
  create?: InputMaybe<Array<CHeadingCreateWithPositionInput>>;
  /** Delete multiple CHeading documents */
  delete?: InputMaybe<Array<CHeadingWhereUniqueInput>>;
  /** Update multiple CHeading component instances */
  update?: InputMaybe<
    Array<CHeadingUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CHeading component instances */
  upsert?: InputMaybe<
    Array<CHeadingUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CHeadingUpdateManyInput = {
  component?: InputMaybe<Scalars['String']>;
  customHeadingSize?: InputMaybe<CustomFontSize>;
  icon?: InputMaybe<Icon>;
  level?: InputMaybe<Scalars['Int']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<CHeadingUpdateManyLocalizationsInput>;
  /** text input for default locale (default) */
  text?: InputMaybe<Scalars['String']>;
  tone?: InputMaybe<CTextTone>;
  weight?: InputMaybe<CTextWeight>;
};

export type CHeadingUpdateManyLocalizationDataInput = {
  text?: InputMaybe<Scalars['String']>;
};

export type CHeadingUpdateManyLocalizationInput = {
  data: CHeadingUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type CHeadingUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<CHeadingUpdateManyLocalizationInput>>;
};

export type CHeadingUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CHeadingUpdateManyInput;
  /** Document search */
  where: CHeadingWhereInput;
};

export type CHeadingUpdateOneInlineInput = {
  /** Create and connect one CHeading document */
  create?: InputMaybe<CHeadingCreateInput>;
  /** Delete currently connected CHeading document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CHeading document */
  update?: InputMaybe<CHeadingUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CHeading document */
  upsert?: InputMaybe<CHeadingUpsertWithNestedWhereUniqueInput>;
};

export type CHeadingUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<CHeadingUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CHeadingWhereUniqueInput;
};

export type CHeadingUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CHeadingUpdateInput;
  /** Unique document search */
  where: CHeadingWhereUniqueInput;
};

export type CHeadingUpsertInput = {
  /** Create document if it didn't exist */
  create: CHeadingCreateInput;
  /** Update document if it exists */
  update: CHeadingUpdateInput;
};

export type CHeadingUpsertLocalizationInput = {
  create: CHeadingCreateLocalizationDataInput;
  locale: Locale;
  update: CHeadingUpdateLocalizationDataInput;
};

export type CHeadingUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<CHeadingUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CHeadingWhereUniqueInput;
};

export type CHeadingUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CHeadingUpsertInput;
  /** Unique document search */
  where: CHeadingWhereUniqueInput;
};

/** Identifies documents */
export type CHeadingWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CHeadingWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CHeadingWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CHeadingWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  component?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  component_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  component_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  component_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  component_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  component_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  component_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  component_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  component_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  component_starts_with?: InputMaybe<Scalars['String']>;
  customHeadingSize?: InputMaybe<CustomFontSize>;
  /** All values that are contained in given list. */
  customHeadingSize_in?: InputMaybe<Array<InputMaybe<CustomFontSize>>>;
  /** Any other value that exists and is not equal to the given value. */
  customHeadingSize_not?: InputMaybe<CustomFontSize>;
  /** All values that are not contained in given list. */
  customHeadingSize_not_in?: InputMaybe<Array<InputMaybe<CustomFontSize>>>;
  icon?: InputMaybe<Icon>;
  /** All values that are contained in given list. */
  icon_in?: InputMaybe<Array<InputMaybe<Icon>>>;
  /** Any other value that exists and is not equal to the given value. */
  icon_not?: InputMaybe<Icon>;
  /** All values that are not contained in given list. */
  icon_not_in?: InputMaybe<Array<InputMaybe<Icon>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  level?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  level_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  level_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  level_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** All values less than the given value. */
  level_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  level_lte?: InputMaybe<Scalars['Int']>;
  /** Any other value that exists and is not equal to the given value. */
  level_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  level_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** All values in which the modular component is connected to the given models */
  paddingTopOverride?: InputMaybe<CHeadingpaddingTopOverrideUnionWhereInput>;
  /** All values in which the union is empty. */
  paddingTopOverride_empty?: InputMaybe<Scalars['Boolean']>;
  text?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  text_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  text_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  text_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  text_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  text_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  text_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  text_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  text_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  text_starts_with?: InputMaybe<Scalars['String']>;
  tone?: InputMaybe<CTextTone>;
  /** All values that are contained in given list. */
  tone_in?: InputMaybe<Array<InputMaybe<CTextTone>>>;
  /** Any other value that exists and is not equal to the given value. */
  tone_not?: InputMaybe<CTextTone>;
  /** All values that are not contained in given list. */
  tone_not_in?: InputMaybe<Array<InputMaybe<CTextTone>>>;
  weight?: InputMaybe<CTextWeight>;
  /** All values that are contained in given list. */
  weight_in?: InputMaybe<Array<InputMaybe<CTextWeight>>>;
  /** Any other value that exists and is not equal to the given value. */
  weight_not?: InputMaybe<CTextWeight>;
  /** All values that are not contained in given list. */
  weight_not_in?: InputMaybe<Array<InputMaybe<CTextWeight>>>;
};

/** References CHeading record uniquely */
export type CHeadingWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type CHeadingpaddingTopOverrideUnion = ResponsiveSpace | Space;

export type CHeadingpaddingTopOverrideUnionConnectInput = {
  ResponsiveSpace?: InputMaybe<ResponsiveSpaceConnectInput>;
  Space?: InputMaybe<SpaceConnectInput>;
};

export type CHeadingpaddingTopOverrideUnionCreateInput = {
  ResponsiveSpace?: InputMaybe<ResponsiveSpaceCreateInput>;
  Space?: InputMaybe<SpaceCreateInput>;
};

export type CHeadingpaddingTopOverrideUnionCreateManyInlineInput = {
  /** Create and connect multiple existing CHeadingpaddingTopOverrideUnion documents */
  create?: InputMaybe<Array<CHeadingpaddingTopOverrideUnionCreateInput>>;
};

export type CHeadingpaddingTopOverrideUnionCreateOneInlineInput = {
  /** Create and connect one CHeadingpaddingTopOverrideUnion document */
  create?: InputMaybe<CHeadingpaddingTopOverrideUnionCreateInput>;
};

export type CHeadingpaddingTopOverrideUnionCreateWithPositionInput = {
  ResponsiveSpace?: InputMaybe<ResponsiveSpaceCreateWithPositionInput>;
  Space?: InputMaybe<SpaceCreateWithPositionInput>;
};

export type CHeadingpaddingTopOverrideUnionUpdateInput = {
  ResponsiveSpace?: InputMaybe<ResponsiveSpaceUpdateInput>;
  Space?: InputMaybe<SpaceUpdateInput>;
};

export type CHeadingpaddingTopOverrideUnionUpdateManyInlineInput = {
  /** Create and connect multiple CHeadingpaddingTopOverrideUnion component instances */
  create?: InputMaybe<
    Array<CHeadingpaddingTopOverrideUnionCreateWithPositionInput>
  >;
  /** Delete multiple CHeadingpaddingTopOverrideUnion documents */
  delete?: InputMaybe<Array<CHeadingpaddingTopOverrideUnionWhereUniqueInput>>;
  /** Update multiple CHeadingpaddingTopOverrideUnion component instances */
  update?: InputMaybe<
    Array<CHeadingpaddingTopOverrideUnionUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CHeadingpaddingTopOverrideUnion component instances */
  upsert?: InputMaybe<
    Array<CHeadingpaddingTopOverrideUnionUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CHeadingpaddingTopOverrideUnionUpdateManyWithNestedWhereInput = {
  ResponsiveSpace?: InputMaybe<ResponsiveSpaceUpdateManyWithNestedWhereInput>;
  Space?: InputMaybe<SpaceUpdateManyWithNestedWhereInput>;
};

export type CHeadingpaddingTopOverrideUnionUpdateOneInlineInput = {
  /** Create and connect one CHeadingpaddingTopOverrideUnion document */
  create?: InputMaybe<CHeadingpaddingTopOverrideUnionCreateInput>;
  /** Delete currently connected CHeadingpaddingTopOverrideUnion document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CHeadingpaddingTopOverrideUnion document */
  update?: InputMaybe<CHeadingpaddingTopOverrideUnionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CHeadingpaddingTopOverrideUnion document */
  upsert?: InputMaybe<CHeadingpaddingTopOverrideUnionUpsertWithNestedWhereUniqueInput>;
};

export type CHeadingpaddingTopOverrideUnionUpdateWithNestedWhereUniqueAndPositionInput =
  {
    ResponsiveSpace?: InputMaybe<ResponsiveSpaceUpdateWithNestedWhereUniqueAndPositionInput>;
    Space?: InputMaybe<SpaceUpdateWithNestedWhereUniqueAndPositionInput>;
  };

export type CHeadingpaddingTopOverrideUnionUpdateWithNestedWhereUniqueInput = {
  ResponsiveSpace?: InputMaybe<ResponsiveSpaceUpdateWithNestedWhereUniqueInput>;
  Space?: InputMaybe<SpaceUpdateWithNestedWhereUniqueInput>;
};

export type CHeadingpaddingTopOverrideUnionUpsertWithNestedWhereUniqueAndPositionInput =
  {
    ResponsiveSpace?: InputMaybe<ResponsiveSpaceUpsertWithNestedWhereUniqueAndPositionInput>;
    Space?: InputMaybe<SpaceUpsertWithNestedWhereUniqueAndPositionInput>;
  };

export type CHeadingpaddingTopOverrideUnionUpsertWithNestedWhereUniqueInput = {
  ResponsiveSpace?: InputMaybe<ResponsiveSpaceUpsertWithNestedWhereUniqueInput>;
  Space?: InputMaybe<SpaceUpsertWithNestedWhereUniqueInput>;
};

export type CHeadingpaddingTopOverrideUnionWhereInput = {
  ResponsiveSpace?: InputMaybe<ResponsiveSpaceWhereInput>;
  Space?: InputMaybe<SpaceWhereInput>;
};

export type CHeadingpaddingTopOverrideUnionWhereUniqueInput = {
  ResponsiveSpace?: InputMaybe<ResponsiveSpaceWhereUniqueInput>;
  Space?: InputMaybe<SpaceWhereUniqueInput>;
};

export type CIconBlock = Entity & {
  __typename?: 'CIconBlock';
  heading?: Maybe<CHeading>;
  icon: Icon;
  /** The unique identifier */
  id: Scalars['ID'];
  paragraph?: Maybe<CParagraph>;
  /** System stage field */
  stage: Stage;
};

export type CIconBlockHeadingArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CIconBlockParagraphArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CIconBlockConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CIconBlockWhereUniqueInput;
};

/** A connection to a list of items. */
export type CIconBlockConnection = {
  __typename?: 'CIconBlockConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CIconBlockEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CIconBlockCreateInput = {
  heading?: InputMaybe<CHeadingCreateOneInlineInput>;
  icon: Icon;
  paragraph?: InputMaybe<CParagraphCreateOneInlineInput>;
};

export type CIconBlockCreateManyInlineInput = {
  /** Create and connect multiple existing CIconBlock documents */
  create?: InputMaybe<Array<CIconBlockCreateInput>>;
};

export type CIconBlockCreateOneInlineInput = {
  /** Create and connect one CIconBlock document */
  create?: InputMaybe<CIconBlockCreateInput>;
};

export type CIconBlockCreateWithPositionInput = {
  /** Document to create */
  data: CIconBlockCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type CIconBlockEdge = {
  __typename?: 'CIconBlockEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CIconBlock;
};

export type CIconBlockGroup = Entity & {
  __typename?: 'CIconBlockGroup';
  blockBackground?: Maybe<CBoxBackground>;
  iconAlignment?: Maybe<BoxJustifyContent>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** Sets whether the block icon layout is inline or stacked */
  inlineBlockItem?: Maybe<Scalars['Boolean']>;
  items: Array<CIconBlock>;
  /** System stage field */
  stage: Stage;
  tile?: Maybe<CTile>;
};

export type CIconBlockGroupItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<CIconBlockOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CIconBlockWhereInput>;
};

export type CIconBlockGroupTileArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CIconBlockGroupConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CIconBlockGroupWhereUniqueInput;
};

/** A connection to a list of items. */
export type CIconBlockGroupConnection = {
  __typename?: 'CIconBlockGroupConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CIconBlockGroupEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CIconBlockGroupCreateInput = {
  blockBackground?: InputMaybe<CBoxBackground>;
  iconAlignment?: InputMaybe<BoxJustifyContent>;
  inlineBlockItem?: InputMaybe<Scalars['Boolean']>;
  items?: InputMaybe<CIconBlockCreateManyInlineInput>;
  tile?: InputMaybe<CTileCreateOneInlineInput>;
};

export type CIconBlockGroupCreateManyInlineInput = {
  /** Create and connect multiple existing CIconBlockGroup documents */
  create?: InputMaybe<Array<CIconBlockGroupCreateInput>>;
};

export type CIconBlockGroupCreateOneInlineInput = {
  /** Create and connect one CIconBlockGroup document */
  create?: InputMaybe<CIconBlockGroupCreateInput>;
};

export type CIconBlockGroupCreateWithPositionInput = {
  /** Document to create */
  data: CIconBlockGroupCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type CIconBlockGroupEdge = {
  __typename?: 'CIconBlockGroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CIconBlockGroup;
};

/** Identifies documents */
export type CIconBlockGroupManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CIconBlockGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CIconBlockGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CIconBlockGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  blockBackground?: InputMaybe<CBoxBackground>;
  /** All values that are contained in given list. */
  blockBackground_in?: InputMaybe<Array<InputMaybe<CBoxBackground>>>;
  /** Any other value that exists and is not equal to the given value. */
  blockBackground_not?: InputMaybe<CBoxBackground>;
  /** All values that are not contained in given list. */
  blockBackground_not_in?: InputMaybe<Array<InputMaybe<CBoxBackground>>>;
  iconAlignment?: InputMaybe<BoxJustifyContent>;
  /** All values that are contained in given list. */
  iconAlignment_in?: InputMaybe<Array<InputMaybe<BoxJustifyContent>>>;
  /** Any other value that exists and is not equal to the given value. */
  iconAlignment_not?: InputMaybe<BoxJustifyContent>;
  /** All values that are not contained in given list. */
  iconAlignment_not_in?: InputMaybe<Array<InputMaybe<BoxJustifyContent>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  inlineBlockItem?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  inlineBlockItem_not?: InputMaybe<Scalars['Boolean']>;
  items_every?: InputMaybe<CIconBlockWhereInput>;
  items_none?: InputMaybe<CIconBlockWhereInput>;
  items_some?: InputMaybe<CIconBlockWhereInput>;
  tile?: InputMaybe<CTileWhereInput>;
};

export enum CIconBlockGroupOrderByInput {
  BlockBackgroundAsc = 'blockBackground_ASC',
  BlockBackgroundDesc = 'blockBackground_DESC',
  IconAlignmentAsc = 'iconAlignment_ASC',
  IconAlignmentDesc = 'iconAlignment_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InlineBlockItemAsc = 'inlineBlockItem_ASC',
  InlineBlockItemDesc = 'inlineBlockItem_DESC',
}

export type CIconBlockGroupParent = CBlock;

export type CIconBlockGroupParentConnectInput = {
  CBlock?: InputMaybe<CBlockConnectInput>;
};

export type CIconBlockGroupParentCreateInput = {
  CBlock?: InputMaybe<CBlockCreateInput>;
};

export type CIconBlockGroupParentCreateManyInlineInput = {
  /** Connect multiple existing CIconBlockGroupParent documents */
  connect?: InputMaybe<Array<CIconBlockGroupParentWhereUniqueInput>>;
  /** Create and connect multiple existing CIconBlockGroupParent documents */
  create?: InputMaybe<Array<CIconBlockGroupParentCreateInput>>;
};

export type CIconBlockGroupParentCreateOneInlineInput = {
  /** Connect one existing CIconBlockGroupParent document */
  connect?: InputMaybe<CIconBlockGroupParentWhereUniqueInput>;
  /** Create and connect one CIconBlockGroupParent document */
  create?: InputMaybe<CIconBlockGroupParentCreateInput>;
};

export type CIconBlockGroupParentUpdateInput = {
  CBlock?: InputMaybe<CBlockUpdateInput>;
};

export type CIconBlockGroupParentUpdateManyInlineInput = {
  /** Connect multiple existing CIconBlockGroupParent documents */
  connect?: InputMaybe<Array<CIconBlockGroupParentConnectInput>>;
  /** Create and connect multiple CIconBlockGroupParent documents */
  create?: InputMaybe<Array<CIconBlockGroupParentCreateInput>>;
  /** Delete multiple CIconBlockGroupParent documents */
  delete?: InputMaybe<Array<CIconBlockGroupParentWhereUniqueInput>>;
  /** Disconnect multiple CIconBlockGroupParent documents */
  disconnect?: InputMaybe<Array<CIconBlockGroupParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing CIconBlockGroupParent documents */
  set?: InputMaybe<Array<CIconBlockGroupParentWhereUniqueInput>>;
  /** Update multiple CIconBlockGroupParent documents */
  update?: InputMaybe<
    Array<CIconBlockGroupParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple CIconBlockGroupParent documents */
  upsert?: InputMaybe<
    Array<CIconBlockGroupParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type CIconBlockGroupParentUpdateManyWithNestedWhereInput = {
  CBlock?: InputMaybe<CBlockUpdateManyWithNestedWhereInput>;
};

export type CIconBlockGroupParentUpdateOneInlineInput = {
  /** Connect existing CIconBlockGroupParent document */
  connect?: InputMaybe<CIconBlockGroupParentWhereUniqueInput>;
  /** Create and connect one CIconBlockGroupParent document */
  create?: InputMaybe<CIconBlockGroupParentCreateInput>;
  /** Delete currently connected CIconBlockGroupParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected CIconBlockGroupParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single CIconBlockGroupParent document */
  update?: InputMaybe<CIconBlockGroupParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CIconBlockGroupParent document */
  upsert?: InputMaybe<CIconBlockGroupParentUpsertWithNestedWhereUniqueInput>;
};

export type CIconBlockGroupParentUpdateWithNestedWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockUpdateWithNestedWhereUniqueInput>;
};

export type CIconBlockGroupParentUpsertWithNestedWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockUpsertWithNestedWhereUniqueInput>;
};

export type CIconBlockGroupParentWhereInput = {
  CBlock?: InputMaybe<CBlockWhereInput>;
};

export type CIconBlockGroupParentWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockWhereUniqueInput>;
};

export type CIconBlockGroupUpdateInput = {
  blockBackground?: InputMaybe<CBoxBackground>;
  iconAlignment?: InputMaybe<BoxJustifyContent>;
  inlineBlockItem?: InputMaybe<Scalars['Boolean']>;
  items?: InputMaybe<CIconBlockUpdateManyInlineInput>;
  tile?: InputMaybe<CTileUpdateOneInlineInput>;
};

export type CIconBlockGroupUpdateManyInlineInput = {
  /** Create and connect multiple CIconBlockGroup component instances */
  create?: InputMaybe<Array<CIconBlockGroupCreateWithPositionInput>>;
  /** Delete multiple CIconBlockGroup documents */
  delete?: InputMaybe<Array<CIconBlockGroupWhereUniqueInput>>;
  /** Update multiple CIconBlockGroup component instances */
  update?: InputMaybe<
    Array<CIconBlockGroupUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CIconBlockGroup component instances */
  upsert?: InputMaybe<
    Array<CIconBlockGroupUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CIconBlockGroupUpdateManyInput = {
  blockBackground?: InputMaybe<CBoxBackground>;
  iconAlignment?: InputMaybe<BoxJustifyContent>;
  inlineBlockItem?: InputMaybe<Scalars['Boolean']>;
};

export type CIconBlockGroupUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CIconBlockGroupUpdateManyInput;
  /** Document search */
  where: CIconBlockGroupWhereInput;
};

export type CIconBlockGroupUpdateOneInlineInput = {
  /** Create and connect one CIconBlockGroup document */
  create?: InputMaybe<CIconBlockGroupCreateInput>;
  /** Delete currently connected CIconBlockGroup document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CIconBlockGroup document */
  update?: InputMaybe<CIconBlockGroupUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CIconBlockGroup document */
  upsert?: InputMaybe<CIconBlockGroupUpsertWithNestedWhereUniqueInput>;
};

export type CIconBlockGroupUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<CIconBlockGroupUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CIconBlockGroupWhereUniqueInput;
};

export type CIconBlockGroupUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CIconBlockGroupUpdateInput;
  /** Unique document search */
  where: CIconBlockGroupWhereUniqueInput;
};

export type CIconBlockGroupUpsertInput = {
  /** Create document if it didn't exist */
  create: CIconBlockGroupCreateInput;
  /** Update document if it exists */
  update: CIconBlockGroupUpdateInput;
};

export type CIconBlockGroupUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<CIconBlockGroupUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CIconBlockGroupWhereUniqueInput;
};

export type CIconBlockGroupUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CIconBlockGroupUpsertInput;
  /** Unique document search */
  where: CIconBlockGroupWhereUniqueInput;
};

/** Identifies documents */
export type CIconBlockGroupWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CIconBlockGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CIconBlockGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CIconBlockGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  blockBackground?: InputMaybe<CBoxBackground>;
  /** All values that are contained in given list. */
  blockBackground_in?: InputMaybe<Array<InputMaybe<CBoxBackground>>>;
  /** Any other value that exists and is not equal to the given value. */
  blockBackground_not?: InputMaybe<CBoxBackground>;
  /** All values that are not contained in given list. */
  blockBackground_not_in?: InputMaybe<Array<InputMaybe<CBoxBackground>>>;
  iconAlignment?: InputMaybe<BoxJustifyContent>;
  /** All values that are contained in given list. */
  iconAlignment_in?: InputMaybe<Array<InputMaybe<BoxJustifyContent>>>;
  /** Any other value that exists and is not equal to the given value. */
  iconAlignment_not?: InputMaybe<BoxJustifyContent>;
  /** All values that are not contained in given list. */
  iconAlignment_not_in?: InputMaybe<Array<InputMaybe<BoxJustifyContent>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  inlineBlockItem?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  inlineBlockItem_not?: InputMaybe<Scalars['Boolean']>;
  items_every?: InputMaybe<CIconBlockWhereInput>;
  items_none?: InputMaybe<CIconBlockWhereInput>;
  items_some?: InputMaybe<CIconBlockWhereInput>;
  tile?: InputMaybe<CTileWhereInput>;
};

/** References CIconBlockGroup record uniquely */
export type CIconBlockGroupWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Identifies documents */
export type CIconBlockManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CIconBlockWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CIconBlockWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CIconBlockWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  heading?: InputMaybe<CHeadingWhereInput>;
  icon?: InputMaybe<Icon>;
  /** All values that are contained in given list. */
  icon_in?: InputMaybe<Array<InputMaybe<Icon>>>;
  /** Any other value that exists and is not equal to the given value. */
  icon_not?: InputMaybe<Icon>;
  /** All values that are not contained in given list. */
  icon_not_in?: InputMaybe<Array<InputMaybe<Icon>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  paragraph?: InputMaybe<CParagraphWhereInput>;
};

export enum CIconBlockOrderByInput {
  IconAsc = 'icon_ASC',
  IconDesc = 'icon_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type CIconBlockParent = CIconBlockGroup;

export type CIconBlockParentConnectInput = {
  CIconBlockGroup?: InputMaybe<CIconBlockGroupConnectInput>;
};

export type CIconBlockParentCreateInput = {
  CIconBlockGroup?: InputMaybe<CIconBlockGroupCreateInput>;
};

export type CIconBlockParentCreateManyInlineInput = {
  /** Create and connect multiple existing CIconBlockParent documents */
  create?: InputMaybe<Array<CIconBlockParentCreateInput>>;
};

export type CIconBlockParentCreateOneInlineInput = {
  /** Create and connect one CIconBlockParent document */
  create?: InputMaybe<CIconBlockParentCreateInput>;
};

export type CIconBlockParentCreateWithPositionInput = {
  CIconBlockGroup?: InputMaybe<CIconBlockGroupCreateWithPositionInput>;
};

export type CIconBlockParentUpdateInput = {
  CIconBlockGroup?: InputMaybe<CIconBlockGroupUpdateInput>;
};

export type CIconBlockParentUpdateManyInlineInput = {
  /** Create and connect multiple CIconBlockParent component instances */
  create?: InputMaybe<Array<CIconBlockParentCreateWithPositionInput>>;
  /** Delete multiple CIconBlockParent documents */
  delete?: InputMaybe<Array<CIconBlockParentWhereUniqueInput>>;
  /** Update multiple CIconBlockParent component instances */
  update?: InputMaybe<
    Array<CIconBlockParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CIconBlockParent component instances */
  upsert?: InputMaybe<
    Array<CIconBlockParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CIconBlockParentUpdateManyWithNestedWhereInput = {
  CIconBlockGroup?: InputMaybe<CIconBlockGroupUpdateManyWithNestedWhereInput>;
};

export type CIconBlockParentUpdateOneInlineInput = {
  /** Create and connect one CIconBlockParent document */
  create?: InputMaybe<CIconBlockParentCreateInput>;
  /** Delete currently connected CIconBlockParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CIconBlockParent document */
  update?: InputMaybe<CIconBlockParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CIconBlockParent document */
  upsert?: InputMaybe<CIconBlockParentUpsertWithNestedWhereUniqueInput>;
};

export type CIconBlockParentUpdateWithNestedWhereUniqueAndPositionInput = {
  CIconBlockGroup?: InputMaybe<CIconBlockGroupUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type CIconBlockParentUpdateWithNestedWhereUniqueInput = {
  CIconBlockGroup?: InputMaybe<CIconBlockGroupUpdateWithNestedWhereUniqueInput>;
};

export type CIconBlockParentUpsertWithNestedWhereUniqueAndPositionInput = {
  CIconBlockGroup?: InputMaybe<CIconBlockGroupUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type CIconBlockParentUpsertWithNestedWhereUniqueInput = {
  CIconBlockGroup?: InputMaybe<CIconBlockGroupUpsertWithNestedWhereUniqueInput>;
};

export type CIconBlockParentWhereInput = {
  CIconBlockGroup?: InputMaybe<CIconBlockGroupWhereInput>;
};

export type CIconBlockParentWhereUniqueInput = {
  CIconBlockGroup?: InputMaybe<CIconBlockGroupWhereUniqueInput>;
};

export type CIconBlockUpdateInput = {
  heading?: InputMaybe<CHeadingUpdateOneInlineInput>;
  icon?: InputMaybe<Icon>;
  paragraph?: InputMaybe<CParagraphUpdateOneInlineInput>;
};

export type CIconBlockUpdateManyInlineInput = {
  /** Create and connect multiple CIconBlock component instances */
  create?: InputMaybe<Array<CIconBlockCreateWithPositionInput>>;
  /** Delete multiple CIconBlock documents */
  delete?: InputMaybe<Array<CIconBlockWhereUniqueInput>>;
  /** Update multiple CIconBlock component instances */
  update?: InputMaybe<
    Array<CIconBlockUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CIconBlock component instances */
  upsert?: InputMaybe<
    Array<CIconBlockUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CIconBlockUpdateManyInput = {
  icon?: InputMaybe<Icon>;
};

export type CIconBlockUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CIconBlockUpdateManyInput;
  /** Document search */
  where: CIconBlockWhereInput;
};

export type CIconBlockUpdateOneInlineInput = {
  /** Create and connect one CIconBlock document */
  create?: InputMaybe<CIconBlockCreateInput>;
  /** Delete currently connected CIconBlock document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CIconBlock document */
  update?: InputMaybe<CIconBlockUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CIconBlock document */
  upsert?: InputMaybe<CIconBlockUpsertWithNestedWhereUniqueInput>;
};

export type CIconBlockUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<CIconBlockUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CIconBlockWhereUniqueInput;
};

export type CIconBlockUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CIconBlockUpdateInput;
  /** Unique document search */
  where: CIconBlockWhereUniqueInput;
};

export type CIconBlockUpsertInput = {
  /** Create document if it didn't exist */
  create: CIconBlockCreateInput;
  /** Update document if it exists */
  update: CIconBlockUpdateInput;
};

export type CIconBlockUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<CIconBlockUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CIconBlockWhereUniqueInput;
};

export type CIconBlockUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CIconBlockUpsertInput;
  /** Unique document search */
  where: CIconBlockWhereUniqueInput;
};

/** Identifies documents */
export type CIconBlockWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CIconBlockWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CIconBlockWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CIconBlockWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  heading?: InputMaybe<CHeadingWhereInput>;
  icon?: InputMaybe<Icon>;
  /** All values that are contained in given list. */
  icon_in?: InputMaybe<Array<InputMaybe<Icon>>>;
  /** Any other value that exists and is not equal to the given value. */
  icon_not?: InputMaybe<Icon>;
  /** All values that are not contained in given list. */
  icon_not_in?: InputMaybe<Array<InputMaybe<Icon>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  paragraph?: InputMaybe<CParagraphWhereInput>;
};

/** References CIconBlock record uniquely */
export type CIconBlockWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type CImageBlock = Entity & {
  __typename?: 'CImageBlock';
  /** Button link for Image Block. */
  cButtonLink?: Maybe<CButtonLink>;
  /** Text link for Image Block. */
  cTextLink?: Maybe<CTextLink>;
  dialogButton?: Maybe<DialogButton>;
  heading?: Maybe<CHeading>;
  /** The unique identifier */
  id: Scalars['ID'];
  image?: Maybe<Asset>;
  paragraph?: Maybe<CParagraph>;
  reverseContent?: Maybe<ReverseContent>;
  /** System stage field */
  stage: Stage;
};

export type CImageBlockCButtonLinkArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CImageBlockCTextLinkArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CImageBlockDialogButtonArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CImageBlockHeadingArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CImageBlockImageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
  where?: InputMaybe<AssetSingleRelationWhereInput>;
};

export type CImageBlockParagraphArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CImageBlockConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CImageBlockWhereUniqueInput;
};

/** A connection to a list of items. */
export type CImageBlockConnection = {
  __typename?: 'CImageBlockConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CImageBlockEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CImageBlockCreateInput = {
  cButtonLink?: InputMaybe<CButtonLinkCreateOneInlineInput>;
  cTextLink?: InputMaybe<CTextLinkCreateOneInlineInput>;
  dialogButton?: InputMaybe<DialogButtonCreateOneInlineInput>;
  heading?: InputMaybe<CHeadingCreateOneInlineInput>;
  image?: InputMaybe<AssetCreateOneInlineInput>;
  paragraph?: InputMaybe<CParagraphCreateOneInlineInput>;
  reverseContent?: InputMaybe<ReverseContent>;
};

export type CImageBlockCreateManyInlineInput = {
  /** Create and connect multiple existing CImageBlock documents */
  create?: InputMaybe<Array<CImageBlockCreateInput>>;
};

export type CImageBlockCreateOneInlineInput = {
  /** Create and connect one CImageBlock document */
  create?: InputMaybe<CImageBlockCreateInput>;
};

export type CImageBlockCreateWithPositionInput = {
  /** Document to create */
  data: CImageBlockCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type CImageBlockEdge = {
  __typename?: 'CImageBlockEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CImageBlock;
};

export type CImageBlockGroup = Entity & {
  __typename?: 'CImageBlockGroup';
  blockBackground?: Maybe<CBoxBackground>;
  blockBorder?: Maybe<BoxShadows>;
  box?: Maybe<CBox>;
  /** The unique identifier */
  id: Scalars['ID'];
  imageAlignment?: Maybe<BoxJustifyContent>;
  /** Sets whether the block image layout is inline or stacked */
  inlineBlockItem?: Maybe<Scalars['Boolean']>;
  items: Array<CImageBlock>;
  /** When Inline, stack the content when below which device size? */
  stackBelow?: Maybe<HiddenBelow>;
  /** System stage field */
  stage: Stage;
  tile?: Maybe<CTile>;
};

export type CImageBlockGroupBoxArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CImageBlockGroupItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<CImageBlockOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CImageBlockWhereInput>;
};

export type CImageBlockGroupTileArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CImageBlockGroupConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CImageBlockGroupWhereUniqueInput;
};

/** A connection to a list of items. */
export type CImageBlockGroupConnection = {
  __typename?: 'CImageBlockGroupConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CImageBlockGroupEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CImageBlockGroupCreateInput = {
  blockBackground?: InputMaybe<CBoxBackground>;
  blockBorder?: InputMaybe<BoxShadows>;
  box?: InputMaybe<CBoxCreateOneInlineInput>;
  imageAlignment?: InputMaybe<BoxJustifyContent>;
  inlineBlockItem?: InputMaybe<Scalars['Boolean']>;
  items?: InputMaybe<CImageBlockCreateManyInlineInput>;
  stackBelow?: InputMaybe<HiddenBelow>;
  tile?: InputMaybe<CTileCreateOneInlineInput>;
};

export type CImageBlockGroupCreateManyInlineInput = {
  /** Create and connect multiple existing CImageBlockGroup documents */
  create?: InputMaybe<Array<CImageBlockGroupCreateInput>>;
};

export type CImageBlockGroupCreateOneInlineInput = {
  /** Create and connect one CImageBlockGroup document */
  create?: InputMaybe<CImageBlockGroupCreateInput>;
};

export type CImageBlockGroupCreateWithPositionInput = {
  /** Document to create */
  data: CImageBlockGroupCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type CImageBlockGroupEdge = {
  __typename?: 'CImageBlockGroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CImageBlockGroup;
};

/** Identifies documents */
export type CImageBlockGroupManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CImageBlockGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CImageBlockGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CImageBlockGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  blockBackground?: InputMaybe<CBoxBackground>;
  /** All values that are contained in given list. */
  blockBackground_in?: InputMaybe<Array<InputMaybe<CBoxBackground>>>;
  /** Any other value that exists and is not equal to the given value. */
  blockBackground_not?: InputMaybe<CBoxBackground>;
  /** All values that are not contained in given list. */
  blockBackground_not_in?: InputMaybe<Array<InputMaybe<CBoxBackground>>>;
  blockBorder?: InputMaybe<BoxShadows>;
  /** All values that are contained in given list. */
  blockBorder_in?: InputMaybe<Array<InputMaybe<BoxShadows>>>;
  /** Any other value that exists and is not equal to the given value. */
  blockBorder_not?: InputMaybe<BoxShadows>;
  /** All values that are not contained in given list. */
  blockBorder_not_in?: InputMaybe<Array<InputMaybe<BoxShadows>>>;
  box?: InputMaybe<CBoxWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  imageAlignment?: InputMaybe<BoxJustifyContent>;
  /** All values that are contained in given list. */
  imageAlignment_in?: InputMaybe<Array<InputMaybe<BoxJustifyContent>>>;
  /** Any other value that exists and is not equal to the given value. */
  imageAlignment_not?: InputMaybe<BoxJustifyContent>;
  /** All values that are not contained in given list. */
  imageAlignment_not_in?: InputMaybe<Array<InputMaybe<BoxJustifyContent>>>;
  inlineBlockItem?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  inlineBlockItem_not?: InputMaybe<Scalars['Boolean']>;
  items_every?: InputMaybe<CImageBlockWhereInput>;
  items_none?: InputMaybe<CImageBlockWhereInput>;
  items_some?: InputMaybe<CImageBlockWhereInput>;
  stackBelow?: InputMaybe<HiddenBelow>;
  /** All values that are contained in given list. */
  stackBelow_in?: InputMaybe<Array<InputMaybe<HiddenBelow>>>;
  /** Any other value that exists and is not equal to the given value. */
  stackBelow_not?: InputMaybe<HiddenBelow>;
  /** All values that are not contained in given list. */
  stackBelow_not_in?: InputMaybe<Array<InputMaybe<HiddenBelow>>>;
  tile?: InputMaybe<CTileWhereInput>;
};

export enum CImageBlockGroupOrderByInput {
  BlockBackgroundAsc = 'blockBackground_ASC',
  BlockBackgroundDesc = 'blockBackground_DESC',
  BlockBorderAsc = 'blockBorder_ASC',
  BlockBorderDesc = 'blockBorder_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ImageAlignmentAsc = 'imageAlignment_ASC',
  ImageAlignmentDesc = 'imageAlignment_DESC',
  InlineBlockItemAsc = 'inlineBlockItem_ASC',
  InlineBlockItemDesc = 'inlineBlockItem_DESC',
  StackBelowAsc = 'stackBelow_ASC',
  StackBelowDesc = 'stackBelow_DESC',
}

export type CImageBlockGroupParent = CBlock;

export type CImageBlockGroupParentConnectInput = {
  CBlock?: InputMaybe<CBlockConnectInput>;
};

export type CImageBlockGroupParentCreateInput = {
  CBlock?: InputMaybe<CBlockCreateInput>;
};

export type CImageBlockGroupParentCreateManyInlineInput = {
  /** Connect multiple existing CImageBlockGroupParent documents */
  connect?: InputMaybe<Array<CImageBlockGroupParentWhereUniqueInput>>;
  /** Create and connect multiple existing CImageBlockGroupParent documents */
  create?: InputMaybe<Array<CImageBlockGroupParentCreateInput>>;
};

export type CImageBlockGroupParentCreateOneInlineInput = {
  /** Connect one existing CImageBlockGroupParent document */
  connect?: InputMaybe<CImageBlockGroupParentWhereUniqueInput>;
  /** Create and connect one CImageBlockGroupParent document */
  create?: InputMaybe<CImageBlockGroupParentCreateInput>;
};

export type CImageBlockGroupParentUpdateInput = {
  CBlock?: InputMaybe<CBlockUpdateInput>;
};

export type CImageBlockGroupParentUpdateManyInlineInput = {
  /** Connect multiple existing CImageBlockGroupParent documents */
  connect?: InputMaybe<Array<CImageBlockGroupParentConnectInput>>;
  /** Create and connect multiple CImageBlockGroupParent documents */
  create?: InputMaybe<Array<CImageBlockGroupParentCreateInput>>;
  /** Delete multiple CImageBlockGroupParent documents */
  delete?: InputMaybe<Array<CImageBlockGroupParentWhereUniqueInput>>;
  /** Disconnect multiple CImageBlockGroupParent documents */
  disconnect?: InputMaybe<Array<CImageBlockGroupParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing CImageBlockGroupParent documents */
  set?: InputMaybe<Array<CImageBlockGroupParentWhereUniqueInput>>;
  /** Update multiple CImageBlockGroupParent documents */
  update?: InputMaybe<
    Array<CImageBlockGroupParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple CImageBlockGroupParent documents */
  upsert?: InputMaybe<
    Array<CImageBlockGroupParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type CImageBlockGroupParentUpdateManyWithNestedWhereInput = {
  CBlock?: InputMaybe<CBlockUpdateManyWithNestedWhereInput>;
};

export type CImageBlockGroupParentUpdateOneInlineInput = {
  /** Connect existing CImageBlockGroupParent document */
  connect?: InputMaybe<CImageBlockGroupParentWhereUniqueInput>;
  /** Create and connect one CImageBlockGroupParent document */
  create?: InputMaybe<CImageBlockGroupParentCreateInput>;
  /** Delete currently connected CImageBlockGroupParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected CImageBlockGroupParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single CImageBlockGroupParent document */
  update?: InputMaybe<CImageBlockGroupParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CImageBlockGroupParent document */
  upsert?: InputMaybe<CImageBlockGroupParentUpsertWithNestedWhereUniqueInput>;
};

export type CImageBlockGroupParentUpdateWithNestedWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockUpdateWithNestedWhereUniqueInput>;
};

export type CImageBlockGroupParentUpsertWithNestedWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockUpsertWithNestedWhereUniqueInput>;
};

export type CImageBlockGroupParentWhereInput = {
  CBlock?: InputMaybe<CBlockWhereInput>;
};

export type CImageBlockGroupParentWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockWhereUniqueInput>;
};

export type CImageBlockGroupUpdateInput = {
  blockBackground?: InputMaybe<CBoxBackground>;
  blockBorder?: InputMaybe<BoxShadows>;
  box?: InputMaybe<CBoxUpdateOneInlineInput>;
  imageAlignment?: InputMaybe<BoxJustifyContent>;
  inlineBlockItem?: InputMaybe<Scalars['Boolean']>;
  items?: InputMaybe<CImageBlockUpdateManyInlineInput>;
  stackBelow?: InputMaybe<HiddenBelow>;
  tile?: InputMaybe<CTileUpdateOneInlineInput>;
};

export type CImageBlockGroupUpdateManyInlineInput = {
  /** Create and connect multiple CImageBlockGroup component instances */
  create?: InputMaybe<Array<CImageBlockGroupCreateWithPositionInput>>;
  /** Delete multiple CImageBlockGroup documents */
  delete?: InputMaybe<Array<CImageBlockGroupWhereUniqueInput>>;
  /** Update multiple CImageBlockGroup component instances */
  update?: InputMaybe<
    Array<CImageBlockGroupUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CImageBlockGroup component instances */
  upsert?: InputMaybe<
    Array<CImageBlockGroupUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CImageBlockGroupUpdateManyInput = {
  blockBackground?: InputMaybe<CBoxBackground>;
  blockBorder?: InputMaybe<BoxShadows>;
  imageAlignment?: InputMaybe<BoxJustifyContent>;
  inlineBlockItem?: InputMaybe<Scalars['Boolean']>;
  stackBelow?: InputMaybe<HiddenBelow>;
};

export type CImageBlockGroupUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CImageBlockGroupUpdateManyInput;
  /** Document search */
  where: CImageBlockGroupWhereInput;
};

export type CImageBlockGroupUpdateOneInlineInput = {
  /** Create and connect one CImageBlockGroup document */
  create?: InputMaybe<CImageBlockGroupCreateInput>;
  /** Delete currently connected CImageBlockGroup document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CImageBlockGroup document */
  update?: InputMaybe<CImageBlockGroupUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CImageBlockGroup document */
  upsert?: InputMaybe<CImageBlockGroupUpsertWithNestedWhereUniqueInput>;
};

export type CImageBlockGroupUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<CImageBlockGroupUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CImageBlockGroupWhereUniqueInput;
};

export type CImageBlockGroupUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CImageBlockGroupUpdateInput;
  /** Unique document search */
  where: CImageBlockGroupWhereUniqueInput;
};

export type CImageBlockGroupUpsertInput = {
  /** Create document if it didn't exist */
  create: CImageBlockGroupCreateInput;
  /** Update document if it exists */
  update: CImageBlockGroupUpdateInput;
};

export type CImageBlockGroupUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<CImageBlockGroupUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CImageBlockGroupWhereUniqueInput;
};

export type CImageBlockGroupUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CImageBlockGroupUpsertInput;
  /** Unique document search */
  where: CImageBlockGroupWhereUniqueInput;
};

/** Identifies documents */
export type CImageBlockGroupWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CImageBlockGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CImageBlockGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CImageBlockGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  blockBackground?: InputMaybe<CBoxBackground>;
  /** All values that are contained in given list. */
  blockBackground_in?: InputMaybe<Array<InputMaybe<CBoxBackground>>>;
  /** Any other value that exists and is not equal to the given value. */
  blockBackground_not?: InputMaybe<CBoxBackground>;
  /** All values that are not contained in given list. */
  blockBackground_not_in?: InputMaybe<Array<InputMaybe<CBoxBackground>>>;
  blockBorder?: InputMaybe<BoxShadows>;
  /** All values that are contained in given list. */
  blockBorder_in?: InputMaybe<Array<InputMaybe<BoxShadows>>>;
  /** Any other value that exists and is not equal to the given value. */
  blockBorder_not?: InputMaybe<BoxShadows>;
  /** All values that are not contained in given list. */
  blockBorder_not_in?: InputMaybe<Array<InputMaybe<BoxShadows>>>;
  box?: InputMaybe<CBoxWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  imageAlignment?: InputMaybe<BoxJustifyContent>;
  /** All values that are contained in given list. */
  imageAlignment_in?: InputMaybe<Array<InputMaybe<BoxJustifyContent>>>;
  /** Any other value that exists and is not equal to the given value. */
  imageAlignment_not?: InputMaybe<BoxJustifyContent>;
  /** All values that are not contained in given list. */
  imageAlignment_not_in?: InputMaybe<Array<InputMaybe<BoxJustifyContent>>>;
  inlineBlockItem?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  inlineBlockItem_not?: InputMaybe<Scalars['Boolean']>;
  items_every?: InputMaybe<CImageBlockWhereInput>;
  items_none?: InputMaybe<CImageBlockWhereInput>;
  items_some?: InputMaybe<CImageBlockWhereInput>;
  stackBelow?: InputMaybe<HiddenBelow>;
  /** All values that are contained in given list. */
  stackBelow_in?: InputMaybe<Array<InputMaybe<HiddenBelow>>>;
  /** Any other value that exists and is not equal to the given value. */
  stackBelow_not?: InputMaybe<HiddenBelow>;
  /** All values that are not contained in given list. */
  stackBelow_not_in?: InputMaybe<Array<InputMaybe<HiddenBelow>>>;
  tile?: InputMaybe<CTileWhereInput>;
};

/** References CImageBlockGroup record uniquely */
export type CImageBlockGroupWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Identifies documents */
export type CImageBlockManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CImageBlockWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CImageBlockWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CImageBlockWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  cButtonLink?: InputMaybe<CButtonLinkWhereInput>;
  cTextLink?: InputMaybe<CTextLinkWhereInput>;
  dialogButton?: InputMaybe<DialogButtonWhereInput>;
  heading?: InputMaybe<CHeadingWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AssetWhereInput>;
  paragraph?: InputMaybe<CParagraphWhereInput>;
  reverseContent?: InputMaybe<ReverseContent>;
  /** All values that are contained in given list. */
  reverseContent_in?: InputMaybe<Array<InputMaybe<ReverseContent>>>;
  /** Any other value that exists and is not equal to the given value. */
  reverseContent_not?: InputMaybe<ReverseContent>;
  /** All values that are not contained in given list. */
  reverseContent_not_in?: InputMaybe<Array<InputMaybe<ReverseContent>>>;
};

export enum CImageBlockOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ReverseContentAsc = 'reverseContent_ASC',
  ReverseContentDesc = 'reverseContent_DESC',
}

export type CImageBlockParent = CImageBlockGroup;

export type CImageBlockParentConnectInput = {
  CImageBlockGroup?: InputMaybe<CImageBlockGroupConnectInput>;
};

export type CImageBlockParentCreateInput = {
  CImageBlockGroup?: InputMaybe<CImageBlockGroupCreateInput>;
};

export type CImageBlockParentCreateManyInlineInput = {
  /** Create and connect multiple existing CImageBlockParent documents */
  create?: InputMaybe<Array<CImageBlockParentCreateInput>>;
};

export type CImageBlockParentCreateOneInlineInput = {
  /** Create and connect one CImageBlockParent document */
  create?: InputMaybe<CImageBlockParentCreateInput>;
};

export type CImageBlockParentCreateWithPositionInput = {
  CImageBlockGroup?: InputMaybe<CImageBlockGroupCreateWithPositionInput>;
};

export type CImageBlockParentUpdateInput = {
  CImageBlockGroup?: InputMaybe<CImageBlockGroupUpdateInput>;
};

export type CImageBlockParentUpdateManyInlineInput = {
  /** Create and connect multiple CImageBlockParent component instances */
  create?: InputMaybe<Array<CImageBlockParentCreateWithPositionInput>>;
  /** Delete multiple CImageBlockParent documents */
  delete?: InputMaybe<Array<CImageBlockParentWhereUniqueInput>>;
  /** Update multiple CImageBlockParent component instances */
  update?: InputMaybe<
    Array<CImageBlockParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CImageBlockParent component instances */
  upsert?: InputMaybe<
    Array<CImageBlockParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CImageBlockParentUpdateManyWithNestedWhereInput = {
  CImageBlockGroup?: InputMaybe<CImageBlockGroupUpdateManyWithNestedWhereInput>;
};

export type CImageBlockParentUpdateOneInlineInput = {
  /** Create and connect one CImageBlockParent document */
  create?: InputMaybe<CImageBlockParentCreateInput>;
  /** Delete currently connected CImageBlockParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CImageBlockParent document */
  update?: InputMaybe<CImageBlockParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CImageBlockParent document */
  upsert?: InputMaybe<CImageBlockParentUpsertWithNestedWhereUniqueInput>;
};

export type CImageBlockParentUpdateWithNestedWhereUniqueAndPositionInput = {
  CImageBlockGroup?: InputMaybe<CImageBlockGroupUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type CImageBlockParentUpdateWithNestedWhereUniqueInput = {
  CImageBlockGroup?: InputMaybe<CImageBlockGroupUpdateWithNestedWhereUniqueInput>;
};

export type CImageBlockParentUpsertWithNestedWhereUniqueAndPositionInput = {
  CImageBlockGroup?: InputMaybe<CImageBlockGroupUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type CImageBlockParentUpsertWithNestedWhereUniqueInput = {
  CImageBlockGroup?: InputMaybe<CImageBlockGroupUpsertWithNestedWhereUniqueInput>;
};

export type CImageBlockParentWhereInput = {
  CImageBlockGroup?: InputMaybe<CImageBlockGroupWhereInput>;
};

export type CImageBlockParentWhereUniqueInput = {
  CImageBlockGroup?: InputMaybe<CImageBlockGroupWhereUniqueInput>;
};

export type CImageBlockUpdateInput = {
  cButtonLink?: InputMaybe<CButtonLinkUpdateOneInlineInput>;
  cTextLink?: InputMaybe<CTextLinkUpdateOneInlineInput>;
  dialogButton?: InputMaybe<DialogButtonUpdateOneInlineInput>;
  heading?: InputMaybe<CHeadingUpdateOneInlineInput>;
  image?: InputMaybe<AssetUpdateOneInlineInput>;
  paragraph?: InputMaybe<CParagraphUpdateOneInlineInput>;
  reverseContent?: InputMaybe<ReverseContent>;
};

export type CImageBlockUpdateManyInlineInput = {
  /** Create and connect multiple CImageBlock component instances */
  create?: InputMaybe<Array<CImageBlockCreateWithPositionInput>>;
  /** Delete multiple CImageBlock documents */
  delete?: InputMaybe<Array<CImageBlockWhereUniqueInput>>;
  /** Update multiple CImageBlock component instances */
  update?: InputMaybe<
    Array<CImageBlockUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CImageBlock component instances */
  upsert?: InputMaybe<
    Array<CImageBlockUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CImageBlockUpdateManyInput = {
  reverseContent?: InputMaybe<ReverseContent>;
};

export type CImageBlockUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CImageBlockUpdateManyInput;
  /** Document search */
  where: CImageBlockWhereInput;
};

export type CImageBlockUpdateOneInlineInput = {
  /** Create and connect one CImageBlock document */
  create?: InputMaybe<CImageBlockCreateInput>;
  /** Delete currently connected CImageBlock document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CImageBlock document */
  update?: InputMaybe<CImageBlockUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CImageBlock document */
  upsert?: InputMaybe<CImageBlockUpsertWithNestedWhereUniqueInput>;
};

export type CImageBlockUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<CImageBlockUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CImageBlockWhereUniqueInput;
};

export type CImageBlockUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CImageBlockUpdateInput;
  /** Unique document search */
  where: CImageBlockWhereUniqueInput;
};

export type CImageBlockUpsertInput = {
  /** Create document if it didn't exist */
  create: CImageBlockCreateInput;
  /** Update document if it exists */
  update: CImageBlockUpdateInput;
};

export type CImageBlockUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<CImageBlockUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CImageBlockWhereUniqueInput;
};

export type CImageBlockUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CImageBlockUpsertInput;
  /** Unique document search */
  where: CImageBlockWhereUniqueInput;
};

/** Identifies documents */
export type CImageBlockWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CImageBlockWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CImageBlockWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CImageBlockWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  cButtonLink?: InputMaybe<CButtonLinkWhereInput>;
  cTextLink?: InputMaybe<CTextLinkWhereInput>;
  dialogButton?: InputMaybe<DialogButtonWhereInput>;
  heading?: InputMaybe<CHeadingWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AssetWhereInput>;
  paragraph?: InputMaybe<CParagraphWhereInput>;
  reverseContent?: InputMaybe<ReverseContent>;
  /** All values that are contained in given list. */
  reverseContent_in?: InputMaybe<Array<InputMaybe<ReverseContent>>>;
  /** Any other value that exists and is not equal to the given value. */
  reverseContent_not?: InputMaybe<ReverseContent>;
  /** All values that are not contained in given list. */
  reverseContent_not_in?: InputMaybe<Array<InputMaybe<ReverseContent>>>;
};

/** References CImageBlock record uniquely */
export type CImageBlockWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type CImageLink = Entity &
  Node & {
    __typename?: 'CImageLink';
    /** The time the document was created */
    createdAt: Scalars['DateTime'];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<CImageLink>;
    /** List of CImageLink versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars['ID'];
    /** Asset (Image) for the button */
    image: Asset;
    link?: Maybe<Link>;
    /** System Locale field */
    locale: Locale;
    /** Get the other localizations for this document */
    localizations: Array<CImageLink>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    title?: Maybe<Scalars['String']>;
    /** The time the document was updated */
    updatedAt: Scalars['DateTime'];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type CImageLinkCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type CImageLinkCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CImageLinkDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type CImageLinkHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};

export type CImageLinkImageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
  where?: InputMaybe<AssetSingleRelationWhereInput>;
};

export type CImageLinkLinkArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CImageLinkLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type CImageLinkPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type CImageLinkPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CImageLinkScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type CImageLinkUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type CImageLinkUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CImageLinkConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CImageLinkWhereUniqueInput;
};

/** A connection to a list of items. */
export type CImageLinkConnection = {
  __typename?: 'CImageLinkConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CImageLinkEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CImageLinkCreateInput = {
  cm0yixtpp00ky062w52mlc4n2?: InputMaybe<CustomHeaderCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  image: AssetCreateOneInlineInput;
  link?: InputMaybe<LinkCreateOneInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<CImageLinkCreateLocalizationsInput>;
  /** title input for default locale (default) */
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CImageLinkCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CImageLinkCreateLocalizationInput = {
  /** Localization input */
  data: CImageLinkCreateLocalizationDataInput;
  locale: Locale;
};

export type CImageLinkCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<CImageLinkCreateLocalizationInput>>;
};

export type CImageLinkCreateManyInlineInput = {
  /** Connect multiple existing CImageLink documents */
  connect?: InputMaybe<Array<CImageLinkWhereUniqueInput>>;
  /** Create and connect multiple existing CImageLink documents */
  create?: InputMaybe<Array<CImageLinkCreateInput>>;
};

export type CImageLinkCreateOneInlineInput = {
  /** Connect one existing CImageLink document */
  connect?: InputMaybe<CImageLinkWhereUniqueInput>;
  /** Create and connect one CImageLink document */
  create?: InputMaybe<CImageLinkCreateInput>;
};

/** An edge in a connection. */
export type CImageLinkEdge = {
  __typename?: 'CImageLinkEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CImageLink;
};

/** Identifies documents */
export type CImageLinkManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CImageLinkWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CImageLinkWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CImageLinkWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<CImageLinkWhereStageInput>;
  documentInStages_none?: InputMaybe<CImageLinkWhereStageInput>;
  documentInStages_some?: InputMaybe<CImageLinkWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AssetWhereInput>;
  link?: InputMaybe<LinkWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum CImageLinkOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type CImageLinkUpdateInput = {
  cm0yixtpp00ky062w52mlc4n2?: InputMaybe<CustomHeaderUpdateManyInlineInput>;
  image?: InputMaybe<AssetUpdateOneInlineInput>;
  link?: InputMaybe<LinkUpdateOneInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<CImageLinkUpdateLocalizationsInput>;
  /** title input for default locale (default) */
  title?: InputMaybe<Scalars['String']>;
};

export type CImageLinkUpdateLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']>;
};

export type CImageLinkUpdateLocalizationInput = {
  data: CImageLinkUpdateLocalizationDataInput;
  locale: Locale;
};

export type CImageLinkUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<CImageLinkCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<CImageLinkUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<CImageLinkUpsertLocalizationInput>>;
};

export type CImageLinkUpdateManyInlineInput = {
  /** Connect multiple existing CImageLink documents */
  connect?: InputMaybe<Array<CImageLinkConnectInput>>;
  /** Create and connect multiple CImageLink documents */
  create?: InputMaybe<Array<CImageLinkCreateInput>>;
  /** Delete multiple CImageLink documents */
  delete?: InputMaybe<Array<CImageLinkWhereUniqueInput>>;
  /** Disconnect multiple CImageLink documents */
  disconnect?: InputMaybe<Array<CImageLinkWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing CImageLink documents */
  set?: InputMaybe<Array<CImageLinkWhereUniqueInput>>;
  /** Update multiple CImageLink documents */
  update?: InputMaybe<Array<CImageLinkUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple CImageLink documents */
  upsert?: InputMaybe<Array<CImageLinkUpsertWithNestedWhereUniqueInput>>;
};

export type CImageLinkUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<CImageLinkUpdateManyLocalizationsInput>;
  /** title input for default locale (default) */
  title?: InputMaybe<Scalars['String']>;
};

export type CImageLinkUpdateManyLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']>;
};

export type CImageLinkUpdateManyLocalizationInput = {
  data: CImageLinkUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type CImageLinkUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<CImageLinkUpdateManyLocalizationInput>>;
};

export type CImageLinkUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CImageLinkUpdateManyInput;
  /** Document search */
  where: CImageLinkWhereInput;
};

export type CImageLinkUpdateOneInlineInput = {
  /** Connect existing CImageLink document */
  connect?: InputMaybe<CImageLinkWhereUniqueInput>;
  /** Create and connect one CImageLink document */
  create?: InputMaybe<CImageLinkCreateInput>;
  /** Delete currently connected CImageLink document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected CImageLink document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single CImageLink document */
  update?: InputMaybe<CImageLinkUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CImageLink document */
  upsert?: InputMaybe<CImageLinkUpsertWithNestedWhereUniqueInput>;
};

export type CImageLinkUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CImageLinkUpdateInput;
  /** Unique document search */
  where: CImageLinkWhereUniqueInput;
};

export type CImageLinkUpsertInput = {
  /** Create document if it didn't exist */
  create: CImageLinkCreateInput;
  /** Update document if it exists */
  update: CImageLinkUpdateInput;
};

export type CImageLinkUpsertLocalizationInput = {
  create: CImageLinkCreateLocalizationDataInput;
  locale: Locale;
  update: CImageLinkUpdateLocalizationDataInput;
};

export type CImageLinkUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CImageLinkUpsertInput;
  /** Unique document search */
  where: CImageLinkWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type CImageLinkWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type CImageLinkWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CImageLinkWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CImageLinkWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CImageLinkWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<CImageLinkWhereStageInput>;
  documentInStages_none?: InputMaybe<CImageLinkWhereStageInput>;
  documentInStages_some?: InputMaybe<CImageLinkWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AssetWhereInput>;
  link?: InputMaybe<LinkWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  title?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type CImageLinkWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CImageLinkWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CImageLinkWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CImageLinkWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<CImageLinkWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References CImageLink record uniquely */
export type CImageLinkWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type CInline = Entity & {
  __typename?: 'CInline';
  align?: Maybe<CTextAlign>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System stage field */
  stage: Stage;
};

export type CInlineConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CInlineWhereUniqueInput;
};

/** A connection to a list of items. */
export type CInlineConnection = {
  __typename?: 'CInlineConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CInlineEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CInlineCreateInput = {
  align?: InputMaybe<CTextAlign>;
};

export type CInlineCreateManyInlineInput = {
  /** Create and connect multiple existing CInline documents */
  create?: InputMaybe<Array<CInlineCreateInput>>;
};

export type CInlineCreateOneInlineInput = {
  /** Create and connect one CInline document */
  create?: InputMaybe<CInlineCreateInput>;
};

export type CInlineCreateWithPositionInput = {
  /** Document to create */
  data: CInlineCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type CInlineEdge = {
  __typename?: 'CInlineEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CInline;
};

/** Identifies documents */
export type CInlineManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CInlineWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CInlineWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CInlineWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  align?: InputMaybe<CTextAlign>;
  /** All values that are contained in given list. */
  align_in?: InputMaybe<Array<InputMaybe<CTextAlign>>>;
  /** Any other value that exists and is not equal to the given value. */
  align_not?: InputMaybe<CTextAlign>;
  /** All values that are not contained in given list. */
  align_not_in?: InputMaybe<Array<InputMaybe<CTextAlign>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
};

export enum CInlineOrderByInput {
  AlignAsc = 'align_ASC',
  AlignDesc = 'align_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type CInlineParent = CActionGroup;

export type CInlineParentConnectInput = {
  CActionGroup?: InputMaybe<CActionGroupConnectInput>;
};

export type CInlineParentCreateInput = {
  CActionGroup?: InputMaybe<CActionGroupCreateInput>;
};

export type CInlineParentCreateManyInlineInput = {
  /** Create and connect multiple existing CInlineParent documents */
  create?: InputMaybe<Array<CInlineParentCreateInput>>;
};

export type CInlineParentCreateOneInlineInput = {
  /** Create and connect one CInlineParent document */
  create?: InputMaybe<CInlineParentCreateInput>;
};

export type CInlineParentCreateWithPositionInput = {
  CActionGroup?: InputMaybe<CActionGroupCreateWithPositionInput>;
};

export type CInlineParentUpdateInput = {
  CActionGroup?: InputMaybe<CActionGroupUpdateInput>;
};

export type CInlineParentUpdateManyInlineInput = {
  /** Create and connect multiple CInlineParent component instances */
  create?: InputMaybe<Array<CInlineParentCreateWithPositionInput>>;
  /** Delete multiple CInlineParent documents */
  delete?: InputMaybe<Array<CInlineParentWhereUniqueInput>>;
  /** Update multiple CInlineParent component instances */
  update?: InputMaybe<
    Array<CInlineParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CInlineParent component instances */
  upsert?: InputMaybe<
    Array<CInlineParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CInlineParentUpdateManyWithNestedWhereInput = {
  CActionGroup?: InputMaybe<CActionGroupUpdateManyWithNestedWhereInput>;
};

export type CInlineParentUpdateOneInlineInput = {
  /** Create and connect one CInlineParent document */
  create?: InputMaybe<CInlineParentCreateInput>;
  /** Delete currently connected CInlineParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CInlineParent document */
  update?: InputMaybe<CInlineParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CInlineParent document */
  upsert?: InputMaybe<CInlineParentUpsertWithNestedWhereUniqueInput>;
};

export type CInlineParentUpdateWithNestedWhereUniqueAndPositionInput = {
  CActionGroup?: InputMaybe<CActionGroupUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type CInlineParentUpdateWithNestedWhereUniqueInput = {
  CActionGroup?: InputMaybe<CActionGroupUpdateWithNestedWhereUniqueInput>;
};

export type CInlineParentUpsertWithNestedWhereUniqueAndPositionInput = {
  CActionGroup?: InputMaybe<CActionGroupUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type CInlineParentUpsertWithNestedWhereUniqueInput = {
  CActionGroup?: InputMaybe<CActionGroupUpsertWithNestedWhereUniqueInput>;
};

export type CInlineParentWhereInput = {
  CActionGroup?: InputMaybe<CActionGroupWhereInput>;
};

export type CInlineParentWhereUniqueInput = {
  CActionGroup?: InputMaybe<CActionGroupWhereUniqueInput>;
};

export type CInlineUpdateInput = {
  align?: InputMaybe<CTextAlign>;
};

export type CInlineUpdateManyInlineInput = {
  /** Create and connect multiple CInline component instances */
  create?: InputMaybe<Array<CInlineCreateWithPositionInput>>;
  /** Delete multiple CInline documents */
  delete?: InputMaybe<Array<CInlineWhereUniqueInput>>;
  /** Update multiple CInline component instances */
  update?: InputMaybe<
    Array<CInlineUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CInline component instances */
  upsert?: InputMaybe<
    Array<CInlineUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CInlineUpdateManyInput = {
  align?: InputMaybe<CTextAlign>;
};

export type CInlineUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CInlineUpdateManyInput;
  /** Document search */
  where: CInlineWhereInput;
};

export type CInlineUpdateOneInlineInput = {
  /** Create and connect one CInline document */
  create?: InputMaybe<CInlineCreateInput>;
  /** Delete currently connected CInline document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CInline document */
  update?: InputMaybe<CInlineUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CInline document */
  upsert?: InputMaybe<CInlineUpsertWithNestedWhereUniqueInput>;
};

export type CInlineUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<CInlineUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CInlineWhereUniqueInput;
};

export type CInlineUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CInlineUpdateInput;
  /** Unique document search */
  where: CInlineWhereUniqueInput;
};

export type CInlineUpsertInput = {
  /** Create document if it didn't exist */
  create: CInlineCreateInput;
  /** Update document if it exists */
  update: CInlineUpdateInput;
};

export type CInlineUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<CInlineUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CInlineWhereUniqueInput;
};

export type CInlineUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CInlineUpsertInput;
  /** Unique document search */
  where: CInlineWhereUniqueInput;
};

/** Identifies documents */
export type CInlineWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CInlineWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CInlineWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CInlineWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  align?: InputMaybe<CTextAlign>;
  /** All values that are contained in given list. */
  align_in?: InputMaybe<Array<InputMaybe<CTextAlign>>>;
  /** Any other value that exists and is not equal to the given value. */
  align_not?: InputMaybe<CTextAlign>;
  /** All values that are not contained in given list. */
  align_not_in?: InputMaybe<Array<InputMaybe<CTextAlign>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
};

/** References CInline record uniquely */
export type CInlineWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type CParagraph = Entity & {
  __typename?: 'CParagraph';
  align?: Maybe<CTextAlign>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<CParagraph>;
  marginBottom?: Maybe<CParagraphMarginBottom>;
  size?: Maybe<CTextSize>;
  /** System stage field */
  stage: Stage;
  text?: Maybe<RichText>;
  tone?: Maybe<CTextTone>;
};

export type CParagraphLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type CParagraphConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CParagraphWhereUniqueInput;
};

/** A connection to a list of items. */
export type CParagraphConnection = {
  __typename?: 'CParagraphConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CParagraphEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CParagraphCreateInput = {
  align?: InputMaybe<CTextAlign>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<CParagraphCreateLocalizationsInput>;
  marginBottom?: InputMaybe<CParagraphMarginBottom>;
  size?: InputMaybe<CTextSize>;
  /** text input for default locale (default) */
  text?: InputMaybe<Scalars['RichTextAST']>;
  tone?: InputMaybe<CTextTone>;
};

export type CParagraphCreateLocalizationDataInput = {
  text?: InputMaybe<Scalars['RichTextAST']>;
};

export type CParagraphCreateLocalizationInput = {
  /** Localization input */
  data: CParagraphCreateLocalizationDataInput;
  locale: Locale;
};

export type CParagraphCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<CParagraphCreateLocalizationInput>>;
};

export type CParagraphCreateManyInlineInput = {
  /** Create and connect multiple existing CParagraph documents */
  create?: InputMaybe<Array<CParagraphCreateInput>>;
};

export type CParagraphCreateOneInlineInput = {
  /** Create and connect one CParagraph document */
  create?: InputMaybe<CParagraphCreateInput>;
};

export type CParagraphCreateWithPositionInput = {
  /** Document to create */
  data: CParagraphCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type CParagraphEdge = {
  __typename?: 'CParagraphEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CParagraph;
};

/** Identifies documents */
export type CParagraphManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CParagraphWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CParagraphWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CParagraphWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  align?: InputMaybe<CTextAlign>;
  /** All values that are contained in given list. */
  align_in?: InputMaybe<Array<InputMaybe<CTextAlign>>>;
  /** Any other value that exists and is not equal to the given value. */
  align_not?: InputMaybe<CTextAlign>;
  /** All values that are not contained in given list. */
  align_not_in?: InputMaybe<Array<InputMaybe<CTextAlign>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  marginBottom?: InputMaybe<CParagraphMarginBottom>;
  /** All values that are contained in given list. */
  marginBottom_in?: InputMaybe<Array<InputMaybe<CParagraphMarginBottom>>>;
  /** Any other value that exists and is not equal to the given value. */
  marginBottom_not?: InputMaybe<CParagraphMarginBottom>;
  /** All values that are not contained in given list. */
  marginBottom_not_in?: InputMaybe<Array<InputMaybe<CParagraphMarginBottom>>>;
  size?: InputMaybe<CTextSize>;
  /** All values that are contained in given list. */
  size_in?: InputMaybe<Array<InputMaybe<CTextSize>>>;
  /** Any other value that exists and is not equal to the given value. */
  size_not?: InputMaybe<CTextSize>;
  /** All values that are not contained in given list. */
  size_not_in?: InputMaybe<Array<InputMaybe<CTextSize>>>;
  tone?: InputMaybe<CTextTone>;
  /** All values that are contained in given list. */
  tone_in?: InputMaybe<Array<InputMaybe<CTextTone>>>;
  /** Any other value that exists and is not equal to the given value. */
  tone_not?: InputMaybe<CTextTone>;
  /** All values that are not contained in given list. */
  tone_not_in?: InputMaybe<Array<InputMaybe<CTextTone>>>;
};

/** Represents C_Paragraph margin bottom value. */
export enum CParagraphMarginBottom {
  Gutter = 'gutter',
  Large = 'large',
  Medium = 'medium',
  None = 'none',
  Small = 'small',
  Xlarge = 'xlarge',
  Xsmall = 'xsmall',
  Xxlarge = 'xxlarge',
  Xxsmall = 'xxsmall',
}

export enum CParagraphOrderByInput {
  AlignAsc = 'align_ASC',
  AlignDesc = 'align_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MarginBottomAsc = 'marginBottom_ASC',
  MarginBottomDesc = 'marginBottom_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  ToneAsc = 'tone_ASC',
  ToneDesc = 'tone_DESC',
}

export type CParagraphParent =
  | BannerItem
  | CAlert
  | CBannerAward
  | CBannerInline
  | CBannerPrimary
  | CBannerShopfront
  | CBlock
  | CCardJobAd
  | CCardPrimary
  | CIconBlock
  | CImageBlock
  | CStepBlock
  | DialogItem
  | PromotionalBanner;

export type CParagraphParentConnectInput = {
  BannerItem?: InputMaybe<BannerItemConnectInput>;
  CAlert?: InputMaybe<CAlertConnectInput>;
  CBannerAward?: InputMaybe<CBannerAwardConnectInput>;
  CBannerInline?: InputMaybe<CBannerInlineConnectInput>;
  CBannerPrimary?: InputMaybe<CBannerPrimaryConnectInput>;
  CBannerShopfront?: InputMaybe<CBannerShopfrontConnectInput>;
  CBlock?: InputMaybe<CBlockConnectInput>;
  CCardJobAd?: InputMaybe<CCardJobAdConnectInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryConnectInput>;
  CIconBlock?: InputMaybe<CIconBlockConnectInput>;
  CImageBlock?: InputMaybe<CImageBlockConnectInput>;
  CStepBlock?: InputMaybe<CStepBlockConnectInput>;
  DialogItem?: InputMaybe<DialogItemConnectInput>;
  PromotionalBanner?: InputMaybe<PromotionalBannerConnectInput>;
};

export type CParagraphParentCreateInput = {
  BannerItem?: InputMaybe<BannerItemCreateInput>;
  CAlert?: InputMaybe<CAlertCreateInput>;
  CBannerAward?: InputMaybe<CBannerAwardCreateInput>;
  CBannerInline?: InputMaybe<CBannerInlineCreateInput>;
  CBannerPrimary?: InputMaybe<CBannerPrimaryCreateInput>;
  CBannerShopfront?: InputMaybe<CBannerShopfrontCreateInput>;
  CBlock?: InputMaybe<CBlockCreateInput>;
  CCardJobAd?: InputMaybe<CCardJobAdCreateInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryCreateInput>;
  CIconBlock?: InputMaybe<CIconBlockCreateInput>;
  CImageBlock?: InputMaybe<CImageBlockCreateInput>;
  CStepBlock?: InputMaybe<CStepBlockCreateInput>;
  DialogItem?: InputMaybe<DialogItemCreateInput>;
  PromotionalBanner?: InputMaybe<PromotionalBannerCreateInput>;
};

export type CParagraphParentCreateManyInlineInput = {
  /** Connect multiple existing CParagraphParent documents */
  connect?: InputMaybe<Array<CParagraphParentWhereUniqueInput>>;
  /** Create and connect multiple existing CParagraphParent documents */
  create?: InputMaybe<Array<CParagraphParentCreateInput>>;
};

export type CParagraphParentCreateOneInlineInput = {
  /** Connect one existing CParagraphParent document */
  connect?: InputMaybe<CParagraphParentWhereUniqueInput>;
  /** Create and connect one CParagraphParent document */
  create?: InputMaybe<CParagraphParentCreateInput>;
};

export type CParagraphParentUpdateInput = {
  BannerItem?: InputMaybe<BannerItemUpdateInput>;
  CAlert?: InputMaybe<CAlertUpdateInput>;
  CBannerAward?: InputMaybe<CBannerAwardUpdateInput>;
  CBannerInline?: InputMaybe<CBannerInlineUpdateInput>;
  CBannerPrimary?: InputMaybe<CBannerPrimaryUpdateInput>;
  CBannerShopfront?: InputMaybe<CBannerShopfrontUpdateInput>;
  CBlock?: InputMaybe<CBlockUpdateInput>;
  CCardJobAd?: InputMaybe<CCardJobAdUpdateInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryUpdateInput>;
  CIconBlock?: InputMaybe<CIconBlockUpdateInput>;
  CImageBlock?: InputMaybe<CImageBlockUpdateInput>;
  CStepBlock?: InputMaybe<CStepBlockUpdateInput>;
  DialogItem?: InputMaybe<DialogItemUpdateInput>;
  PromotionalBanner?: InputMaybe<PromotionalBannerUpdateInput>;
};

export type CParagraphParentUpdateManyInlineInput = {
  /** Connect multiple existing CParagraphParent documents */
  connect?: InputMaybe<Array<CParagraphParentConnectInput>>;
  /** Create and connect multiple CParagraphParent documents */
  create?: InputMaybe<Array<CParagraphParentCreateInput>>;
  /** Delete multiple CParagraphParent documents */
  delete?: InputMaybe<Array<CParagraphParentWhereUniqueInput>>;
  /** Disconnect multiple CParagraphParent documents */
  disconnect?: InputMaybe<Array<CParagraphParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing CParagraphParent documents */
  set?: InputMaybe<Array<CParagraphParentWhereUniqueInput>>;
  /** Update multiple CParagraphParent documents */
  update?: InputMaybe<Array<CParagraphParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple CParagraphParent documents */
  upsert?: InputMaybe<Array<CParagraphParentUpsertWithNestedWhereUniqueInput>>;
};

export type CParagraphParentUpdateManyWithNestedWhereInput = {
  BannerItem?: InputMaybe<BannerItemUpdateManyWithNestedWhereInput>;
  CAlert?: InputMaybe<CAlertUpdateManyWithNestedWhereInput>;
  CBannerAward?: InputMaybe<CBannerAwardUpdateManyWithNestedWhereInput>;
  CBannerInline?: InputMaybe<CBannerInlineUpdateManyWithNestedWhereInput>;
  CBannerPrimary?: InputMaybe<CBannerPrimaryUpdateManyWithNestedWhereInput>;
  CBannerShopfront?: InputMaybe<CBannerShopfrontUpdateManyWithNestedWhereInput>;
  CBlock?: InputMaybe<CBlockUpdateManyWithNestedWhereInput>;
  CCardJobAd?: InputMaybe<CCardJobAdUpdateManyWithNestedWhereInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryUpdateManyWithNestedWhereInput>;
  CIconBlock?: InputMaybe<CIconBlockUpdateManyWithNestedWhereInput>;
  CImageBlock?: InputMaybe<CImageBlockUpdateManyWithNestedWhereInput>;
  CStepBlock?: InputMaybe<CStepBlockUpdateManyWithNestedWhereInput>;
  DialogItem?: InputMaybe<DialogItemUpdateManyWithNestedWhereInput>;
  PromotionalBanner?: InputMaybe<PromotionalBannerUpdateManyWithNestedWhereInput>;
};

export type CParagraphParentUpdateOneInlineInput = {
  /** Connect existing CParagraphParent document */
  connect?: InputMaybe<CParagraphParentWhereUniqueInput>;
  /** Create and connect one CParagraphParent document */
  create?: InputMaybe<CParagraphParentCreateInput>;
  /** Delete currently connected CParagraphParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected CParagraphParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single CParagraphParent document */
  update?: InputMaybe<CParagraphParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CParagraphParent document */
  upsert?: InputMaybe<CParagraphParentUpsertWithNestedWhereUniqueInput>;
};

export type CParagraphParentUpdateWithNestedWhereUniqueInput = {
  BannerItem?: InputMaybe<BannerItemUpdateWithNestedWhereUniqueInput>;
  CAlert?: InputMaybe<CAlertUpdateWithNestedWhereUniqueInput>;
  CBannerAward?: InputMaybe<CBannerAwardUpdateWithNestedWhereUniqueInput>;
  CBannerInline?: InputMaybe<CBannerInlineUpdateWithNestedWhereUniqueInput>;
  CBannerPrimary?: InputMaybe<CBannerPrimaryUpdateWithNestedWhereUniqueInput>;
  CBannerShopfront?: InputMaybe<CBannerShopfrontUpdateWithNestedWhereUniqueInput>;
  CBlock?: InputMaybe<CBlockUpdateWithNestedWhereUniqueInput>;
  CCardJobAd?: InputMaybe<CCardJobAdUpdateWithNestedWhereUniqueInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryUpdateWithNestedWhereUniqueInput>;
  CIconBlock?: InputMaybe<CIconBlockUpdateWithNestedWhereUniqueInput>;
  CImageBlock?: InputMaybe<CImageBlockUpdateWithNestedWhereUniqueInput>;
  CStepBlock?: InputMaybe<CStepBlockUpdateWithNestedWhereUniqueInput>;
  DialogItem?: InputMaybe<DialogItemUpdateWithNestedWhereUniqueInput>;
  PromotionalBanner?: InputMaybe<PromotionalBannerUpdateWithNestedWhereUniqueInput>;
};

export type CParagraphParentUpsertWithNestedWhereUniqueInput = {
  BannerItem?: InputMaybe<BannerItemUpsertWithNestedWhereUniqueInput>;
  CAlert?: InputMaybe<CAlertUpsertWithNestedWhereUniqueInput>;
  CBannerAward?: InputMaybe<CBannerAwardUpsertWithNestedWhereUniqueInput>;
  CBannerInline?: InputMaybe<CBannerInlineUpsertWithNestedWhereUniqueInput>;
  CBannerPrimary?: InputMaybe<CBannerPrimaryUpsertWithNestedWhereUniqueInput>;
  CBannerShopfront?: InputMaybe<CBannerShopfrontUpsertWithNestedWhereUniqueInput>;
  CBlock?: InputMaybe<CBlockUpsertWithNestedWhereUniqueInput>;
  CCardJobAd?: InputMaybe<CCardJobAdUpsertWithNestedWhereUniqueInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryUpsertWithNestedWhereUniqueInput>;
  CIconBlock?: InputMaybe<CIconBlockUpsertWithNestedWhereUniqueInput>;
  CImageBlock?: InputMaybe<CImageBlockUpsertWithNestedWhereUniqueInput>;
  CStepBlock?: InputMaybe<CStepBlockUpsertWithNestedWhereUniqueInput>;
  DialogItem?: InputMaybe<DialogItemUpsertWithNestedWhereUniqueInput>;
  PromotionalBanner?: InputMaybe<PromotionalBannerUpsertWithNestedWhereUniqueInput>;
};

export type CParagraphParentWhereInput = {
  BannerItem?: InputMaybe<BannerItemWhereInput>;
  CAlert?: InputMaybe<CAlertWhereInput>;
  CBannerAward?: InputMaybe<CBannerAwardWhereInput>;
  CBannerInline?: InputMaybe<CBannerInlineWhereInput>;
  CBannerPrimary?: InputMaybe<CBannerPrimaryWhereInput>;
  CBannerShopfront?: InputMaybe<CBannerShopfrontWhereInput>;
  CBlock?: InputMaybe<CBlockWhereInput>;
  CCardJobAd?: InputMaybe<CCardJobAdWhereInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryWhereInput>;
  CIconBlock?: InputMaybe<CIconBlockWhereInput>;
  CImageBlock?: InputMaybe<CImageBlockWhereInput>;
  CStepBlock?: InputMaybe<CStepBlockWhereInput>;
  DialogItem?: InputMaybe<DialogItemWhereInput>;
  PromotionalBanner?: InputMaybe<PromotionalBannerWhereInput>;
};

export type CParagraphParentWhereUniqueInput = {
  BannerItem?: InputMaybe<BannerItemWhereUniqueInput>;
  CAlert?: InputMaybe<CAlertWhereUniqueInput>;
  CBannerAward?: InputMaybe<CBannerAwardWhereUniqueInput>;
  CBannerInline?: InputMaybe<CBannerInlineWhereUniqueInput>;
  CBannerPrimary?: InputMaybe<CBannerPrimaryWhereUniqueInput>;
  CBannerShopfront?: InputMaybe<CBannerShopfrontWhereUniqueInput>;
  CBlock?: InputMaybe<CBlockWhereUniqueInput>;
  CCardJobAd?: InputMaybe<CCardJobAdWhereUniqueInput>;
  CCardPrimary?: InputMaybe<CCardPrimaryWhereUniqueInput>;
  CIconBlock?: InputMaybe<CIconBlockWhereUniqueInput>;
  CImageBlock?: InputMaybe<CImageBlockWhereUniqueInput>;
  CStepBlock?: InputMaybe<CStepBlockWhereUniqueInput>;
  DialogItem?: InputMaybe<DialogItemWhereUniqueInput>;
  PromotionalBanner?: InputMaybe<PromotionalBannerWhereUniqueInput>;
};

export type CParagraphUpdateInput = {
  align?: InputMaybe<CTextAlign>;
  /** Manage document localizations */
  localizations?: InputMaybe<CParagraphUpdateLocalizationsInput>;
  marginBottom?: InputMaybe<CParagraphMarginBottom>;
  size?: InputMaybe<CTextSize>;
  /** text input for default locale (default) */
  text?: InputMaybe<Scalars['RichTextAST']>;
  tone?: InputMaybe<CTextTone>;
};

export type CParagraphUpdateLocalizationDataInput = {
  text?: InputMaybe<Scalars['RichTextAST']>;
};

export type CParagraphUpdateLocalizationInput = {
  data: CParagraphUpdateLocalizationDataInput;
  locale: Locale;
};

export type CParagraphUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<CParagraphCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<CParagraphUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<CParagraphUpsertLocalizationInput>>;
};

export type CParagraphUpdateManyInlineInput = {
  /** Create and connect multiple CParagraph component instances */
  create?: InputMaybe<Array<CParagraphCreateWithPositionInput>>;
  /** Delete multiple CParagraph documents */
  delete?: InputMaybe<Array<CParagraphWhereUniqueInput>>;
  /** Update multiple CParagraph component instances */
  update?: InputMaybe<
    Array<CParagraphUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CParagraph component instances */
  upsert?: InputMaybe<
    Array<CParagraphUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CParagraphUpdateManyInput = {
  align?: InputMaybe<CTextAlign>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<CParagraphUpdateManyLocalizationsInput>;
  marginBottom?: InputMaybe<CParagraphMarginBottom>;
  size?: InputMaybe<CTextSize>;
  /** text input for default locale (default) */
  text?: InputMaybe<Scalars['RichTextAST']>;
  tone?: InputMaybe<CTextTone>;
};

export type CParagraphUpdateManyLocalizationDataInput = {
  text?: InputMaybe<Scalars['RichTextAST']>;
};

export type CParagraphUpdateManyLocalizationInput = {
  data: CParagraphUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type CParagraphUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<CParagraphUpdateManyLocalizationInput>>;
};

export type CParagraphUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CParagraphUpdateManyInput;
  /** Document search */
  where: CParagraphWhereInput;
};

export type CParagraphUpdateOneInlineInput = {
  /** Create and connect one CParagraph document */
  create?: InputMaybe<CParagraphCreateInput>;
  /** Delete currently connected CParagraph document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CParagraph document */
  update?: InputMaybe<CParagraphUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CParagraph document */
  upsert?: InputMaybe<CParagraphUpsertWithNestedWhereUniqueInput>;
};

export type CParagraphUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<CParagraphUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CParagraphWhereUniqueInput;
};

export type CParagraphUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CParagraphUpdateInput;
  /** Unique document search */
  where: CParagraphWhereUniqueInput;
};

export type CParagraphUpsertInput = {
  /** Create document if it didn't exist */
  create: CParagraphCreateInput;
  /** Update document if it exists */
  update: CParagraphUpdateInput;
};

export type CParagraphUpsertLocalizationInput = {
  create: CParagraphCreateLocalizationDataInput;
  locale: Locale;
  update: CParagraphUpdateLocalizationDataInput;
};

export type CParagraphUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<CParagraphUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CParagraphWhereUniqueInput;
};

export type CParagraphUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CParagraphUpsertInput;
  /** Unique document search */
  where: CParagraphWhereUniqueInput;
};

/** Identifies documents */
export type CParagraphWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CParagraphWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CParagraphWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CParagraphWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  align?: InputMaybe<CTextAlign>;
  /** All values that are contained in given list. */
  align_in?: InputMaybe<Array<InputMaybe<CTextAlign>>>;
  /** Any other value that exists and is not equal to the given value. */
  align_not?: InputMaybe<CTextAlign>;
  /** All values that are not contained in given list. */
  align_not_in?: InputMaybe<Array<InputMaybe<CTextAlign>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  marginBottom?: InputMaybe<CParagraphMarginBottom>;
  /** All values that are contained in given list. */
  marginBottom_in?: InputMaybe<Array<InputMaybe<CParagraphMarginBottom>>>;
  /** Any other value that exists and is not equal to the given value. */
  marginBottom_not?: InputMaybe<CParagraphMarginBottom>;
  /** All values that are not contained in given list. */
  marginBottom_not_in?: InputMaybe<Array<InputMaybe<CParagraphMarginBottom>>>;
  size?: InputMaybe<CTextSize>;
  /** All values that are contained in given list. */
  size_in?: InputMaybe<Array<InputMaybe<CTextSize>>>;
  /** Any other value that exists and is not equal to the given value. */
  size_not?: InputMaybe<CTextSize>;
  /** All values that are not contained in given list. */
  size_not_in?: InputMaybe<Array<InputMaybe<CTextSize>>>;
  tone?: InputMaybe<CTextTone>;
  /** All values that are contained in given list. */
  tone_in?: InputMaybe<Array<InputMaybe<CTextTone>>>;
  /** Any other value that exists and is not equal to the given value. */
  tone_not?: InputMaybe<CTextTone>;
  /** All values that are not contained in given list. */
  tone_not_in?: InputMaybe<Array<InputMaybe<CTextTone>>>;
};

/** References CParagraph record uniquely */
export type CParagraphWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Represents C_Stack Space value. */
export enum CStackSpace {
  Gutter = 'gutter',
  Large = 'large',
  Medium = 'medium',
  None = 'none',
  Small = 'small',
  Xlarge = 'xlarge',
  Xsmall = 'xsmall',
  Xxlarge = 'xxlarge',
  Xxsmall = 'xxsmall',
}

export type CStepBlock = Entity & {
  __typename?: 'CStepBlock';
  heading?: Maybe<CHeading>;
  /** The unique identifier */
  id: Scalars['ID'];
  paragraph?: Maybe<CParagraph>;
  /** System stage field */
  stage: Stage;
};

export type CStepBlockHeadingArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CStepBlockParagraphArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CStepBlockConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CStepBlockWhereUniqueInput;
};

/** A connection to a list of items. */
export type CStepBlockConnection = {
  __typename?: 'CStepBlockConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CStepBlockEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CStepBlockCreateInput = {
  heading?: InputMaybe<CHeadingCreateOneInlineInput>;
  paragraph?: InputMaybe<CParagraphCreateOneInlineInput>;
};

export type CStepBlockCreateManyInlineInput = {
  /** Create and connect multiple existing CStepBlock documents */
  create?: InputMaybe<Array<CStepBlockCreateInput>>;
};

export type CStepBlockCreateOneInlineInput = {
  /** Create and connect one CStepBlock document */
  create?: InputMaybe<CStepBlockCreateInput>;
};

export type CStepBlockCreateWithPositionInput = {
  /** Document to create */
  data: CStepBlockCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type CStepBlockEdge = {
  __typename?: 'CStepBlockEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CStepBlock;
};

export type CStepBlockGroup = Entity & {
  __typename?: 'CStepBlockGroup';
  /** The unique identifier */
  id: Scalars['ID'];
  /** Sets whether the steps layout is inline or stacked */
  inlineBlockItem?: Maybe<Scalars['Boolean']>;
  items: Array<CStepBlock>;
  /** System stage field */
  stage: Stage;
  tile?: Maybe<CTile>;
};

export type CStepBlockGroupItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<CStepBlockOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CStepBlockWhereInput>;
};

export type CStepBlockGroupTileArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CStepBlockGroupConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CStepBlockGroupWhereUniqueInput;
};

/** A connection to a list of items. */
export type CStepBlockGroupConnection = {
  __typename?: 'CStepBlockGroupConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CStepBlockGroupEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CStepBlockGroupCreateInput = {
  inlineBlockItem?: InputMaybe<Scalars['Boolean']>;
  items?: InputMaybe<CStepBlockCreateManyInlineInput>;
  tile?: InputMaybe<CTileCreateOneInlineInput>;
};

export type CStepBlockGroupCreateManyInlineInput = {
  /** Create and connect multiple existing CStepBlockGroup documents */
  create?: InputMaybe<Array<CStepBlockGroupCreateInput>>;
};

export type CStepBlockGroupCreateOneInlineInput = {
  /** Create and connect one CStepBlockGroup document */
  create?: InputMaybe<CStepBlockGroupCreateInput>;
};

export type CStepBlockGroupCreateWithPositionInput = {
  /** Document to create */
  data: CStepBlockGroupCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type CStepBlockGroupEdge = {
  __typename?: 'CStepBlockGroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CStepBlockGroup;
};

/** Identifies documents */
export type CStepBlockGroupManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CStepBlockGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CStepBlockGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CStepBlockGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  inlineBlockItem?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  inlineBlockItem_not?: InputMaybe<Scalars['Boolean']>;
  items_every?: InputMaybe<CStepBlockWhereInput>;
  items_none?: InputMaybe<CStepBlockWhereInput>;
  items_some?: InputMaybe<CStepBlockWhereInput>;
  tile?: InputMaybe<CTileWhereInput>;
};

export enum CStepBlockGroupOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InlineBlockItemAsc = 'inlineBlockItem_ASC',
  InlineBlockItemDesc = 'inlineBlockItem_DESC',
}

export type CStepBlockGroupParent = CBlock;

export type CStepBlockGroupParentConnectInput = {
  CBlock?: InputMaybe<CBlockConnectInput>;
};

export type CStepBlockGroupParentCreateInput = {
  CBlock?: InputMaybe<CBlockCreateInput>;
};

export type CStepBlockGroupParentCreateManyInlineInput = {
  /** Connect multiple existing CStepBlockGroupParent documents */
  connect?: InputMaybe<Array<CStepBlockGroupParentWhereUniqueInput>>;
  /** Create and connect multiple existing CStepBlockGroupParent documents */
  create?: InputMaybe<Array<CStepBlockGroupParentCreateInput>>;
};

export type CStepBlockGroupParentCreateOneInlineInput = {
  /** Connect one existing CStepBlockGroupParent document */
  connect?: InputMaybe<CStepBlockGroupParentWhereUniqueInput>;
  /** Create and connect one CStepBlockGroupParent document */
  create?: InputMaybe<CStepBlockGroupParentCreateInput>;
};

export type CStepBlockGroupParentUpdateInput = {
  CBlock?: InputMaybe<CBlockUpdateInput>;
};

export type CStepBlockGroupParentUpdateManyInlineInput = {
  /** Connect multiple existing CStepBlockGroupParent documents */
  connect?: InputMaybe<Array<CStepBlockGroupParentConnectInput>>;
  /** Create and connect multiple CStepBlockGroupParent documents */
  create?: InputMaybe<Array<CStepBlockGroupParentCreateInput>>;
  /** Delete multiple CStepBlockGroupParent documents */
  delete?: InputMaybe<Array<CStepBlockGroupParentWhereUniqueInput>>;
  /** Disconnect multiple CStepBlockGroupParent documents */
  disconnect?: InputMaybe<Array<CStepBlockGroupParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing CStepBlockGroupParent documents */
  set?: InputMaybe<Array<CStepBlockGroupParentWhereUniqueInput>>;
  /** Update multiple CStepBlockGroupParent documents */
  update?: InputMaybe<
    Array<CStepBlockGroupParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple CStepBlockGroupParent documents */
  upsert?: InputMaybe<
    Array<CStepBlockGroupParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type CStepBlockGroupParentUpdateManyWithNestedWhereInput = {
  CBlock?: InputMaybe<CBlockUpdateManyWithNestedWhereInput>;
};

export type CStepBlockGroupParentUpdateOneInlineInput = {
  /** Connect existing CStepBlockGroupParent document */
  connect?: InputMaybe<CStepBlockGroupParentWhereUniqueInput>;
  /** Create and connect one CStepBlockGroupParent document */
  create?: InputMaybe<CStepBlockGroupParentCreateInput>;
  /** Delete currently connected CStepBlockGroupParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected CStepBlockGroupParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single CStepBlockGroupParent document */
  update?: InputMaybe<CStepBlockGroupParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CStepBlockGroupParent document */
  upsert?: InputMaybe<CStepBlockGroupParentUpsertWithNestedWhereUniqueInput>;
};

export type CStepBlockGroupParentUpdateWithNestedWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockUpdateWithNestedWhereUniqueInput>;
};

export type CStepBlockGroupParentUpsertWithNestedWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockUpsertWithNestedWhereUniqueInput>;
};

export type CStepBlockGroupParentWhereInput = {
  CBlock?: InputMaybe<CBlockWhereInput>;
};

export type CStepBlockGroupParentWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockWhereUniqueInput>;
};

export type CStepBlockGroupUpdateInput = {
  inlineBlockItem?: InputMaybe<Scalars['Boolean']>;
  items?: InputMaybe<CStepBlockUpdateManyInlineInput>;
  tile?: InputMaybe<CTileUpdateOneInlineInput>;
};

export type CStepBlockGroupUpdateManyInlineInput = {
  /** Create and connect multiple CStepBlockGroup component instances */
  create?: InputMaybe<Array<CStepBlockGroupCreateWithPositionInput>>;
  /** Delete multiple CStepBlockGroup documents */
  delete?: InputMaybe<Array<CStepBlockGroupWhereUniqueInput>>;
  /** Update multiple CStepBlockGroup component instances */
  update?: InputMaybe<
    Array<CStepBlockGroupUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CStepBlockGroup component instances */
  upsert?: InputMaybe<
    Array<CStepBlockGroupUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CStepBlockGroupUpdateManyInput = {
  inlineBlockItem?: InputMaybe<Scalars['Boolean']>;
};

export type CStepBlockGroupUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CStepBlockGroupUpdateManyInput;
  /** Document search */
  where: CStepBlockGroupWhereInput;
};

export type CStepBlockGroupUpdateOneInlineInput = {
  /** Create and connect one CStepBlockGroup document */
  create?: InputMaybe<CStepBlockGroupCreateInput>;
  /** Delete currently connected CStepBlockGroup document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CStepBlockGroup document */
  update?: InputMaybe<CStepBlockGroupUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CStepBlockGroup document */
  upsert?: InputMaybe<CStepBlockGroupUpsertWithNestedWhereUniqueInput>;
};

export type CStepBlockGroupUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<CStepBlockGroupUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CStepBlockGroupWhereUniqueInput;
};

export type CStepBlockGroupUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CStepBlockGroupUpdateInput;
  /** Unique document search */
  where: CStepBlockGroupWhereUniqueInput;
};

export type CStepBlockGroupUpsertInput = {
  /** Create document if it didn't exist */
  create: CStepBlockGroupCreateInput;
  /** Update document if it exists */
  update: CStepBlockGroupUpdateInput;
};

export type CStepBlockGroupUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<CStepBlockGroupUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CStepBlockGroupWhereUniqueInput;
};

export type CStepBlockGroupUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CStepBlockGroupUpsertInput;
  /** Unique document search */
  where: CStepBlockGroupWhereUniqueInput;
};

/** Identifies documents */
export type CStepBlockGroupWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CStepBlockGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CStepBlockGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CStepBlockGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  inlineBlockItem?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  inlineBlockItem_not?: InputMaybe<Scalars['Boolean']>;
  items_every?: InputMaybe<CStepBlockWhereInput>;
  items_none?: InputMaybe<CStepBlockWhereInput>;
  items_some?: InputMaybe<CStepBlockWhereInput>;
  tile?: InputMaybe<CTileWhereInput>;
};

/** References CStepBlockGroup record uniquely */
export type CStepBlockGroupWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Identifies documents */
export type CStepBlockManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CStepBlockWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CStepBlockWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CStepBlockWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  heading?: InputMaybe<CHeadingWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  paragraph?: InputMaybe<CParagraphWhereInput>;
};

export enum CStepBlockOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type CStepBlockParent = CStepBlockGroup | KeyStepsBlock;

export type CStepBlockParentConnectInput = {
  CStepBlockGroup?: InputMaybe<CStepBlockGroupConnectInput>;
  KeyStepsBlock?: InputMaybe<KeyStepsBlockConnectInput>;
};

export type CStepBlockParentCreateInput = {
  CStepBlockGroup?: InputMaybe<CStepBlockGroupCreateInput>;
  KeyStepsBlock?: InputMaybe<KeyStepsBlockCreateInput>;
};

export type CStepBlockParentCreateManyInlineInput = {
  /** Create and connect multiple existing CStepBlockParent documents */
  create?: InputMaybe<Array<CStepBlockParentCreateInput>>;
};

export type CStepBlockParentCreateOneInlineInput = {
  /** Create and connect one CStepBlockParent document */
  create?: InputMaybe<CStepBlockParentCreateInput>;
};

export type CStepBlockParentCreateWithPositionInput = {
  CStepBlockGroup?: InputMaybe<CStepBlockGroupCreateWithPositionInput>;
  KeyStepsBlock?: InputMaybe<KeyStepsBlockCreateWithPositionInput>;
};

export type CStepBlockParentUpdateInput = {
  CStepBlockGroup?: InputMaybe<CStepBlockGroupUpdateInput>;
  KeyStepsBlock?: InputMaybe<KeyStepsBlockUpdateInput>;
};

export type CStepBlockParentUpdateManyInlineInput = {
  /** Create and connect multiple CStepBlockParent component instances */
  create?: InputMaybe<Array<CStepBlockParentCreateWithPositionInput>>;
  /** Delete multiple CStepBlockParent documents */
  delete?: InputMaybe<Array<CStepBlockParentWhereUniqueInput>>;
  /** Update multiple CStepBlockParent component instances */
  update?: InputMaybe<
    Array<CStepBlockParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CStepBlockParent component instances */
  upsert?: InputMaybe<
    Array<CStepBlockParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CStepBlockParentUpdateManyWithNestedWhereInput = {
  CStepBlockGroup?: InputMaybe<CStepBlockGroupUpdateManyWithNestedWhereInput>;
  KeyStepsBlock?: InputMaybe<KeyStepsBlockUpdateManyWithNestedWhereInput>;
};

export type CStepBlockParentUpdateOneInlineInput = {
  /** Create and connect one CStepBlockParent document */
  create?: InputMaybe<CStepBlockParentCreateInput>;
  /** Delete currently connected CStepBlockParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CStepBlockParent document */
  update?: InputMaybe<CStepBlockParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CStepBlockParent document */
  upsert?: InputMaybe<CStepBlockParentUpsertWithNestedWhereUniqueInput>;
};

export type CStepBlockParentUpdateWithNestedWhereUniqueAndPositionInput = {
  CStepBlockGroup?: InputMaybe<CStepBlockGroupUpdateWithNestedWhereUniqueAndPositionInput>;
  KeyStepsBlock?: InputMaybe<KeyStepsBlockUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type CStepBlockParentUpdateWithNestedWhereUniqueInput = {
  CStepBlockGroup?: InputMaybe<CStepBlockGroupUpdateWithNestedWhereUniqueInput>;
  KeyStepsBlock?: InputMaybe<KeyStepsBlockUpdateWithNestedWhereUniqueInput>;
};

export type CStepBlockParentUpsertWithNestedWhereUniqueAndPositionInput = {
  CStepBlockGroup?: InputMaybe<CStepBlockGroupUpsertWithNestedWhereUniqueAndPositionInput>;
  KeyStepsBlock?: InputMaybe<KeyStepsBlockUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type CStepBlockParentUpsertWithNestedWhereUniqueInput = {
  CStepBlockGroup?: InputMaybe<CStepBlockGroupUpsertWithNestedWhereUniqueInput>;
  KeyStepsBlock?: InputMaybe<KeyStepsBlockUpsertWithNestedWhereUniqueInput>;
};

export type CStepBlockParentWhereInput = {
  CStepBlockGroup?: InputMaybe<CStepBlockGroupWhereInput>;
  KeyStepsBlock?: InputMaybe<KeyStepsBlockWhereInput>;
};

export type CStepBlockParentWhereUniqueInput = {
  CStepBlockGroup?: InputMaybe<CStepBlockGroupWhereUniqueInput>;
  KeyStepsBlock?: InputMaybe<KeyStepsBlockWhereUniqueInput>;
};

export type CStepBlockUpdateInput = {
  heading?: InputMaybe<CHeadingUpdateOneInlineInput>;
  paragraph?: InputMaybe<CParagraphUpdateOneInlineInput>;
};

export type CStepBlockUpdateManyInlineInput = {
  /** Create and connect multiple CStepBlock component instances */
  create?: InputMaybe<Array<CStepBlockCreateWithPositionInput>>;
  /** Delete multiple CStepBlock documents */
  delete?: InputMaybe<Array<CStepBlockWhereUniqueInput>>;
  /** Update multiple CStepBlock component instances */
  update?: InputMaybe<
    Array<CStepBlockUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CStepBlock component instances */
  upsert?: InputMaybe<
    Array<CStepBlockUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CStepBlockUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']>;
};

export type CStepBlockUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CStepBlockUpdateManyInput;
  /** Document search */
  where: CStepBlockWhereInput;
};

export type CStepBlockUpdateOneInlineInput = {
  /** Create and connect one CStepBlock document */
  create?: InputMaybe<CStepBlockCreateInput>;
  /** Delete currently connected CStepBlock document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CStepBlock document */
  update?: InputMaybe<CStepBlockUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CStepBlock document */
  upsert?: InputMaybe<CStepBlockUpsertWithNestedWhereUniqueInput>;
};

export type CStepBlockUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<CStepBlockUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CStepBlockWhereUniqueInput;
};

export type CStepBlockUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CStepBlockUpdateInput;
  /** Unique document search */
  where: CStepBlockWhereUniqueInput;
};

export type CStepBlockUpsertInput = {
  /** Create document if it didn't exist */
  create: CStepBlockCreateInput;
  /** Update document if it exists */
  update: CStepBlockUpdateInput;
};

export type CStepBlockUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<CStepBlockUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CStepBlockWhereUniqueInput;
};

export type CStepBlockUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CStepBlockUpsertInput;
  /** Unique document search */
  where: CStepBlockWhereUniqueInput;
};

/** Identifies documents */
export type CStepBlockWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CStepBlockWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CStepBlockWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CStepBlockWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  heading?: InputMaybe<CHeadingWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  paragraph?: InputMaybe<CParagraphWhereInput>;
};

/** References CStepBlock record uniquely */
export type CStepBlockWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Represents variant styles for CTA */
export enum CtaVariantStyle {
  Ghost = 'ghost',
  Soft = 'soft',
  Transparent = 'transparent',
}

export type CTestimonial = Entity & {
  __typename?: 'CTestimonial';
  /** Author of testimonial */
  author?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<CTestimonial>;
  quote?: Maybe<Scalars['String']>;
  /** System stage field */
  stage: Stage;
};

export type CTestimonialLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type CTestimonialConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CTestimonialWhereUniqueInput;
};

/** A connection to a list of items. */
export type CTestimonialConnection = {
  __typename?: 'CTestimonialConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CTestimonialEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CTestimonialCreateInput = {
  /** author input for default locale (default) */
  author?: InputMaybe<Scalars['String']>;
  /** company input for default locale (default) */
  company?: InputMaybe<Scalars['String']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<CTestimonialCreateLocalizationsInput>;
  /** quote input for default locale (default) */
  quote?: InputMaybe<Scalars['String']>;
};

export type CTestimonialCreateLocalizationDataInput = {
  author?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  quote?: InputMaybe<Scalars['String']>;
};

export type CTestimonialCreateLocalizationInput = {
  /** Localization input */
  data: CTestimonialCreateLocalizationDataInput;
  locale: Locale;
};

export type CTestimonialCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<CTestimonialCreateLocalizationInput>>;
};

export type CTestimonialCreateManyInlineInput = {
  /** Create and connect multiple existing CTestimonial documents */
  create?: InputMaybe<Array<CTestimonialCreateInput>>;
};

export type CTestimonialCreateOneInlineInput = {
  /** Create and connect one CTestimonial document */
  create?: InputMaybe<CTestimonialCreateInput>;
};

export type CTestimonialCreateWithPositionInput = {
  /** Document to create */
  data: CTestimonialCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type CTestimonialEdge = {
  __typename?: 'CTestimonialEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CTestimonial;
};

export type CTestimonialGroup = Entity & {
  __typename?: 'CTestimonialGroup';
  /** Set the background colour of each Testimonial Card. Default is 'surface'. */
  cardBackground?: Maybe<CBoxBackground>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System stage field */
  stage: Stage;
  testimonials: Array<CTestimonial>;
};

export type CTestimonialGroupTestimonialsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<CTestimonialOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CTestimonialWhereInput>;
};

export type CTestimonialGroupConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CTestimonialGroupWhereUniqueInput;
};

/** A connection to a list of items. */
export type CTestimonialGroupConnection = {
  __typename?: 'CTestimonialGroupConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CTestimonialGroupEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CTestimonialGroupCreateInput = {
  cardBackground?: InputMaybe<CBoxBackground>;
  testimonials?: InputMaybe<CTestimonialCreateManyInlineInput>;
};

export type CTestimonialGroupCreateManyInlineInput = {
  /** Create and connect multiple existing CTestimonialGroup documents */
  create?: InputMaybe<Array<CTestimonialGroupCreateInput>>;
};

export type CTestimonialGroupCreateOneInlineInput = {
  /** Create and connect one CTestimonialGroup document */
  create?: InputMaybe<CTestimonialGroupCreateInput>;
};

export type CTestimonialGroupCreateWithPositionInput = {
  /** Document to create */
  data: CTestimonialGroupCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type CTestimonialGroupEdge = {
  __typename?: 'CTestimonialGroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CTestimonialGroup;
};

/** Identifies documents */
export type CTestimonialGroupManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CTestimonialGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CTestimonialGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CTestimonialGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  cardBackground?: InputMaybe<CBoxBackground>;
  /** All values that are contained in given list. */
  cardBackground_in?: InputMaybe<Array<InputMaybe<CBoxBackground>>>;
  /** Any other value that exists and is not equal to the given value. */
  cardBackground_not?: InputMaybe<CBoxBackground>;
  /** All values that are not contained in given list. */
  cardBackground_not_in?: InputMaybe<Array<InputMaybe<CBoxBackground>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  testimonials_every?: InputMaybe<CTestimonialWhereInput>;
  testimonials_none?: InputMaybe<CTestimonialWhereInput>;
  testimonials_some?: InputMaybe<CTestimonialWhereInput>;
};

export enum CTestimonialGroupOrderByInput {
  CardBackgroundAsc = 'cardBackground_ASC',
  CardBackgroundDesc = 'cardBackground_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type CTestimonialGroupParent = CBlock;

export type CTestimonialGroupParentConnectInput = {
  CBlock?: InputMaybe<CBlockConnectInput>;
};

export type CTestimonialGroupParentCreateInput = {
  CBlock?: InputMaybe<CBlockCreateInput>;
};

export type CTestimonialGroupParentCreateManyInlineInput = {
  /** Connect multiple existing CTestimonialGroupParent documents */
  connect?: InputMaybe<Array<CTestimonialGroupParentWhereUniqueInput>>;
  /** Create and connect multiple existing CTestimonialGroupParent documents */
  create?: InputMaybe<Array<CTestimonialGroupParentCreateInput>>;
};

export type CTestimonialGroupParentCreateOneInlineInput = {
  /** Connect one existing CTestimonialGroupParent document */
  connect?: InputMaybe<CTestimonialGroupParentWhereUniqueInput>;
  /** Create and connect one CTestimonialGroupParent document */
  create?: InputMaybe<CTestimonialGroupParentCreateInput>;
};

export type CTestimonialGroupParentUpdateInput = {
  CBlock?: InputMaybe<CBlockUpdateInput>;
};

export type CTestimonialGroupParentUpdateManyInlineInput = {
  /** Connect multiple existing CTestimonialGroupParent documents */
  connect?: InputMaybe<Array<CTestimonialGroupParentConnectInput>>;
  /** Create and connect multiple CTestimonialGroupParent documents */
  create?: InputMaybe<Array<CTestimonialGroupParentCreateInput>>;
  /** Delete multiple CTestimonialGroupParent documents */
  delete?: InputMaybe<Array<CTestimonialGroupParentWhereUniqueInput>>;
  /** Disconnect multiple CTestimonialGroupParent documents */
  disconnect?: InputMaybe<Array<CTestimonialGroupParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing CTestimonialGroupParent documents */
  set?: InputMaybe<Array<CTestimonialGroupParentWhereUniqueInput>>;
  /** Update multiple CTestimonialGroupParent documents */
  update?: InputMaybe<
    Array<CTestimonialGroupParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple CTestimonialGroupParent documents */
  upsert?: InputMaybe<
    Array<CTestimonialGroupParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type CTestimonialGroupParentUpdateManyWithNestedWhereInput = {
  CBlock?: InputMaybe<CBlockUpdateManyWithNestedWhereInput>;
};

export type CTestimonialGroupParentUpdateOneInlineInput = {
  /** Connect existing CTestimonialGroupParent document */
  connect?: InputMaybe<CTestimonialGroupParentWhereUniqueInput>;
  /** Create and connect one CTestimonialGroupParent document */
  create?: InputMaybe<CTestimonialGroupParentCreateInput>;
  /** Delete currently connected CTestimonialGroupParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected CTestimonialGroupParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single CTestimonialGroupParent document */
  update?: InputMaybe<CTestimonialGroupParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CTestimonialGroupParent document */
  upsert?: InputMaybe<CTestimonialGroupParentUpsertWithNestedWhereUniqueInput>;
};

export type CTestimonialGroupParentUpdateWithNestedWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockUpdateWithNestedWhereUniqueInput>;
};

export type CTestimonialGroupParentUpsertWithNestedWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockUpsertWithNestedWhereUniqueInput>;
};

export type CTestimonialGroupParentWhereInput = {
  CBlock?: InputMaybe<CBlockWhereInput>;
};

export type CTestimonialGroupParentWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockWhereUniqueInput>;
};

export type CTestimonialGroupUpdateInput = {
  cardBackground?: InputMaybe<CBoxBackground>;
  testimonials?: InputMaybe<CTestimonialUpdateManyInlineInput>;
};

export type CTestimonialGroupUpdateManyInlineInput = {
  /** Create and connect multiple CTestimonialGroup component instances */
  create?: InputMaybe<Array<CTestimonialGroupCreateWithPositionInput>>;
  /** Delete multiple CTestimonialGroup documents */
  delete?: InputMaybe<Array<CTestimonialGroupWhereUniqueInput>>;
  /** Update multiple CTestimonialGroup component instances */
  update?: InputMaybe<
    Array<CTestimonialGroupUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CTestimonialGroup component instances */
  upsert?: InputMaybe<
    Array<CTestimonialGroupUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CTestimonialGroupUpdateManyInput = {
  cardBackground?: InputMaybe<CBoxBackground>;
};

export type CTestimonialGroupUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CTestimonialGroupUpdateManyInput;
  /** Document search */
  where: CTestimonialGroupWhereInput;
};

export type CTestimonialGroupUpdateOneInlineInput = {
  /** Create and connect one CTestimonialGroup document */
  create?: InputMaybe<CTestimonialGroupCreateInput>;
  /** Delete currently connected CTestimonialGroup document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CTestimonialGroup document */
  update?: InputMaybe<CTestimonialGroupUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CTestimonialGroup document */
  upsert?: InputMaybe<CTestimonialGroupUpsertWithNestedWhereUniqueInput>;
};

export type CTestimonialGroupUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<CTestimonialGroupUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CTestimonialGroupWhereUniqueInput;
};

export type CTestimonialGroupUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CTestimonialGroupUpdateInput;
  /** Unique document search */
  where: CTestimonialGroupWhereUniqueInput;
};

export type CTestimonialGroupUpsertInput = {
  /** Create document if it didn't exist */
  create: CTestimonialGroupCreateInput;
  /** Update document if it exists */
  update: CTestimonialGroupUpdateInput;
};

export type CTestimonialGroupUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<CTestimonialGroupUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CTestimonialGroupWhereUniqueInput;
};

export type CTestimonialGroupUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CTestimonialGroupUpsertInput;
  /** Unique document search */
  where: CTestimonialGroupWhereUniqueInput;
};

/** Identifies documents */
export type CTestimonialGroupWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CTestimonialGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CTestimonialGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CTestimonialGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  cardBackground?: InputMaybe<CBoxBackground>;
  /** All values that are contained in given list. */
  cardBackground_in?: InputMaybe<Array<InputMaybe<CBoxBackground>>>;
  /** Any other value that exists and is not equal to the given value. */
  cardBackground_not?: InputMaybe<CBoxBackground>;
  /** All values that are not contained in given list. */
  cardBackground_not_in?: InputMaybe<Array<InputMaybe<CBoxBackground>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  testimonials_every?: InputMaybe<CTestimonialWhereInput>;
  testimonials_none?: InputMaybe<CTestimonialWhereInput>;
  testimonials_some?: InputMaybe<CTestimonialWhereInput>;
};

/** References CTestimonialGroup record uniquely */
export type CTestimonialGroupWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Identifies documents */
export type CTestimonialManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CTestimonialWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CTestimonialWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CTestimonialWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
};

export enum CTestimonialOrderByInput {
  AuthorAsc = 'author_ASC',
  AuthorDesc = 'author_DESC',
  CompanyAsc = 'company_ASC',
  CompanyDesc = 'company_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  QuoteAsc = 'quote_ASC',
  QuoteDesc = 'quote_DESC',
}

export type CTestimonialParent = CTestimonialGroup;

export type CTestimonialParentConnectInput = {
  CTestimonialGroup?: InputMaybe<CTestimonialGroupConnectInput>;
};

export type CTestimonialParentCreateInput = {
  CTestimonialGroup?: InputMaybe<CTestimonialGroupCreateInput>;
};

export type CTestimonialParentCreateManyInlineInput = {
  /** Create and connect multiple existing CTestimonialParent documents */
  create?: InputMaybe<Array<CTestimonialParentCreateInput>>;
};

export type CTestimonialParentCreateOneInlineInput = {
  /** Create and connect one CTestimonialParent document */
  create?: InputMaybe<CTestimonialParentCreateInput>;
};

export type CTestimonialParentCreateWithPositionInput = {
  CTestimonialGroup?: InputMaybe<CTestimonialGroupCreateWithPositionInput>;
};

export type CTestimonialParentUpdateInput = {
  CTestimonialGroup?: InputMaybe<CTestimonialGroupUpdateInput>;
};

export type CTestimonialParentUpdateManyInlineInput = {
  /** Create and connect multiple CTestimonialParent component instances */
  create?: InputMaybe<Array<CTestimonialParentCreateWithPositionInput>>;
  /** Delete multiple CTestimonialParent documents */
  delete?: InputMaybe<Array<CTestimonialParentWhereUniqueInput>>;
  /** Update multiple CTestimonialParent component instances */
  update?: InputMaybe<
    Array<CTestimonialParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CTestimonialParent component instances */
  upsert?: InputMaybe<
    Array<CTestimonialParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CTestimonialParentUpdateManyWithNestedWhereInput = {
  CTestimonialGroup?: InputMaybe<CTestimonialGroupUpdateManyWithNestedWhereInput>;
};

export type CTestimonialParentUpdateOneInlineInput = {
  /** Create and connect one CTestimonialParent document */
  create?: InputMaybe<CTestimonialParentCreateInput>;
  /** Delete currently connected CTestimonialParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CTestimonialParent document */
  update?: InputMaybe<CTestimonialParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CTestimonialParent document */
  upsert?: InputMaybe<CTestimonialParentUpsertWithNestedWhereUniqueInput>;
};

export type CTestimonialParentUpdateWithNestedWhereUniqueAndPositionInput = {
  CTestimonialGroup?: InputMaybe<CTestimonialGroupUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type CTestimonialParentUpdateWithNestedWhereUniqueInput = {
  CTestimonialGroup?: InputMaybe<CTestimonialGroupUpdateWithNestedWhereUniqueInput>;
};

export type CTestimonialParentUpsertWithNestedWhereUniqueAndPositionInput = {
  CTestimonialGroup?: InputMaybe<CTestimonialGroupUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type CTestimonialParentUpsertWithNestedWhereUniqueInput = {
  CTestimonialGroup?: InputMaybe<CTestimonialGroupUpsertWithNestedWhereUniqueInput>;
};

export type CTestimonialParentWhereInput = {
  CTestimonialGroup?: InputMaybe<CTestimonialGroupWhereInput>;
};

export type CTestimonialParentWhereUniqueInput = {
  CTestimonialGroup?: InputMaybe<CTestimonialGroupWhereUniqueInput>;
};

export type CTestimonialUpdateInput = {
  /** author input for default locale (default) */
  author?: InputMaybe<Scalars['String']>;
  /** company input for default locale (default) */
  company?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<CTestimonialUpdateLocalizationsInput>;
  /** quote input for default locale (default) */
  quote?: InputMaybe<Scalars['String']>;
};

export type CTestimonialUpdateLocalizationDataInput = {
  author?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  quote?: InputMaybe<Scalars['String']>;
};

export type CTestimonialUpdateLocalizationInput = {
  data: CTestimonialUpdateLocalizationDataInput;
  locale: Locale;
};

export type CTestimonialUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<CTestimonialCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<CTestimonialUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<CTestimonialUpsertLocalizationInput>>;
};

export type CTestimonialUpdateManyInlineInput = {
  /** Create and connect multiple CTestimonial component instances */
  create?: InputMaybe<Array<CTestimonialCreateWithPositionInput>>;
  /** Delete multiple CTestimonial documents */
  delete?: InputMaybe<Array<CTestimonialWhereUniqueInput>>;
  /** Update multiple CTestimonial component instances */
  update?: InputMaybe<
    Array<CTestimonialUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CTestimonial component instances */
  upsert?: InputMaybe<
    Array<CTestimonialUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CTestimonialUpdateManyInput = {
  /** author input for default locale (default) */
  author?: InputMaybe<Scalars['String']>;
  /** company input for default locale (default) */
  company?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<CTestimonialUpdateManyLocalizationsInput>;
  /** quote input for default locale (default) */
  quote?: InputMaybe<Scalars['String']>;
};

export type CTestimonialUpdateManyLocalizationDataInput = {
  author?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  quote?: InputMaybe<Scalars['String']>;
};

export type CTestimonialUpdateManyLocalizationInput = {
  data: CTestimonialUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type CTestimonialUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<CTestimonialUpdateManyLocalizationInput>>;
};

export type CTestimonialUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CTestimonialUpdateManyInput;
  /** Document search */
  where: CTestimonialWhereInput;
};

export type CTestimonialUpdateOneInlineInput = {
  /** Create and connect one CTestimonial document */
  create?: InputMaybe<CTestimonialCreateInput>;
  /** Delete currently connected CTestimonial document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CTestimonial document */
  update?: InputMaybe<CTestimonialUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CTestimonial document */
  upsert?: InputMaybe<CTestimonialUpsertWithNestedWhereUniqueInput>;
};

export type CTestimonialUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<CTestimonialUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CTestimonialWhereUniqueInput;
};

export type CTestimonialUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CTestimonialUpdateInput;
  /** Unique document search */
  where: CTestimonialWhereUniqueInput;
};

export type CTestimonialUpsertInput = {
  /** Create document if it didn't exist */
  create: CTestimonialCreateInput;
  /** Update document if it exists */
  update: CTestimonialUpdateInput;
};

export type CTestimonialUpsertLocalizationInput = {
  create: CTestimonialCreateLocalizationDataInput;
  locale: Locale;
  update: CTestimonialUpdateLocalizationDataInput;
};

export type CTestimonialUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<CTestimonialUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CTestimonialWhereUniqueInput;
};

export type CTestimonialUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CTestimonialUpsertInput;
  /** Unique document search */
  where: CTestimonialWhereUniqueInput;
};

/** Identifies documents */
export type CTestimonialWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CTestimonialWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CTestimonialWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CTestimonialWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  author?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  author_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  author_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  author_starts_with?: InputMaybe<Scalars['String']>;
  company?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  company_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  company_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  company_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  company_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  company_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  company_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  company_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  company_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  company_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  quote?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  quote_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  quote_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  quote_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  quote_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  quote_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  quote_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  quote_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  quote_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  quote_starts_with?: InputMaybe<Scalars['String']>;
};

/** References CTestimonial record uniquely */
export type CTestimonialWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export enum CTextAlign {
  Center = 'center',
  Left = 'left',
  Right = 'right',
}

export type CTextLink = Entity &
  Node & {
    __typename?: 'CTextLink';
    /** The time the document was created */
    createdAt: Scalars['DateTime'];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<CTextLink>;
    /** List of CTextLink versions */
    history: Array<Version>;
    /** icon for text link */
    icon?: Maybe<Icon>;
    /** position of the icon */
    iconPosition?: Maybe<IconPosition>;
    /** The unique identifier */
    id: Scalars['ID'];
    link?: Maybe<Link>;
    /** System Locale field */
    locale: Locale;
    /** Get the other localizations for this document */
    localizations: Array<CTextLink>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** Check this to ignore the default locale */
    skipDefaultLocale?: Maybe<Scalars['Boolean']>;
    /** System stage field */
    stage: Stage;
    text: Scalars['String'];
    /** The time the document was updated */
    updatedAt: Scalars['DateTime'];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
    weight?: Maybe<CTextWeight>;
  };

export type CTextLinkCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type CTextLinkCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CTextLinkDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type CTextLinkHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};

export type CTextLinkLinkArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CTextLinkLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type CTextLinkPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type CTextLinkPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CTextLinkScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type CTextLinkUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type CTextLinkUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CTextLinkConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CTextLinkWhereUniqueInput;
};

/** A connection to a list of items. */
export type CTextLinkConnection = {
  __typename?: 'CTextLinkConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CTextLinkEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CTextLinkCreateInput = {
  clwpwfglo0zs907te2ad5hjqu?: InputMaybe<SeekHeaderCreateManyInlineInput>;
  cly2hyd7331zn06tf6qg6biiw?: InputMaybe<CImageBlockCreateManyInlineInput>;
  cm0yiisdw00gn062wgbpka3o6?: InputMaybe<TextLinkGroupCreateManyInlineInput>;
  cm0yik3mj00hd062w7dufdab9?: InputMaybe<TextLinkGroupCreateManyInlineInput>;
  cm1p9kvka03rm07tc49610tsl?: InputMaybe<TipsCardCreateManyInlineInput>;
  cm71bohqq1tuv07ujej672mqt?: InputMaybe<CustomFooterCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  icon?: InputMaybe<Icon>;
  iconPosition?: InputMaybe<IconPosition>;
  link?: InputMaybe<LinkCreateOneInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<CTextLinkCreateLocalizationsInput>;
  skipDefaultLocale?: InputMaybe<Scalars['Boolean']>;
  /** text input for default locale (default) */
  text: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  weight?: InputMaybe<CTextWeight>;
};

export type CTextLinkCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  text: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CTextLinkCreateLocalizationInput = {
  /** Localization input */
  data: CTextLinkCreateLocalizationDataInput;
  locale: Locale;
};

export type CTextLinkCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<CTextLinkCreateLocalizationInput>>;
};

export type CTextLinkCreateManyInlineInput = {
  /** Connect multiple existing CTextLink documents */
  connect?: InputMaybe<Array<CTextLinkWhereUniqueInput>>;
  /** Create and connect multiple existing CTextLink documents */
  create?: InputMaybe<Array<CTextLinkCreateInput>>;
};

export type CTextLinkCreateOneInlineInput = {
  /** Connect one existing CTextLink document */
  connect?: InputMaybe<CTextLinkWhereUniqueInput>;
  /** Create and connect one CTextLink document */
  create?: InputMaybe<CTextLinkCreateInput>;
};

/** An edge in a connection. */
export type CTextLinkEdge = {
  __typename?: 'CTextLinkEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CTextLink;
};

/** Identifies documents */
export type CTextLinkManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CTextLinkWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CTextLinkWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CTextLinkWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<CTextLinkWhereStageInput>;
  documentInStages_none?: InputMaybe<CTextLinkWhereStageInput>;
  documentInStages_some?: InputMaybe<CTextLinkWhereStageInput>;
  icon?: InputMaybe<Icon>;
  iconPosition?: InputMaybe<IconPosition>;
  /** All values that are contained in given list. */
  iconPosition_in?: InputMaybe<Array<InputMaybe<IconPosition>>>;
  /** Any other value that exists and is not equal to the given value. */
  iconPosition_not?: InputMaybe<IconPosition>;
  /** All values that are not contained in given list. */
  iconPosition_not_in?: InputMaybe<Array<InputMaybe<IconPosition>>>;
  /** All values that are contained in given list. */
  icon_in?: InputMaybe<Array<InputMaybe<Icon>>>;
  /** Any other value that exists and is not equal to the given value. */
  icon_not?: InputMaybe<Icon>;
  /** All values that are not contained in given list. */
  icon_not_in?: InputMaybe<Array<InputMaybe<Icon>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  link?: InputMaybe<LinkWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  skipDefaultLocale?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  skipDefaultLocale_not?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  weight?: InputMaybe<CTextWeight>;
  /** All values that are contained in given list. */
  weight_in?: InputMaybe<Array<InputMaybe<CTextWeight>>>;
  /** Any other value that exists and is not equal to the given value. */
  weight_not?: InputMaybe<CTextWeight>;
  /** All values that are not contained in given list. */
  weight_not_in?: InputMaybe<Array<InputMaybe<CTextWeight>>>;
};

export enum CTextLinkOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IconPositionAsc = 'iconPosition_ASC',
  IconPositionDesc = 'iconPosition_DESC',
  IconAsc = 'icon_ASC',
  IconDesc = 'icon_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SkipDefaultLocaleAsc = 'skipDefaultLocale_ASC',
  SkipDefaultLocaleDesc = 'skipDefaultLocale_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  WeightAsc = 'weight_ASC',
  WeightDesc = 'weight_DESC',
}

export type CTextLinkUpdateInput = {
  clwpwfglo0zs907te2ad5hjqu?: InputMaybe<SeekHeaderUpdateManyInlineInput>;
  cly2hyd7331zn06tf6qg6biiw?: InputMaybe<CImageBlockUpdateManyInlineInput>;
  cm0yiisdw00gn062wgbpka3o6?: InputMaybe<TextLinkGroupUpdateManyInlineInput>;
  cm0yik3mj00hd062w7dufdab9?: InputMaybe<TextLinkGroupUpdateManyInlineInput>;
  cm1p9kvka03rm07tc49610tsl?: InputMaybe<TipsCardUpdateManyInlineInput>;
  cm71bohqq1tuv07ujej672mqt?: InputMaybe<CustomFooterUpdateManyInlineInput>;
  icon?: InputMaybe<Icon>;
  iconPosition?: InputMaybe<IconPosition>;
  link?: InputMaybe<LinkUpdateOneInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<CTextLinkUpdateLocalizationsInput>;
  skipDefaultLocale?: InputMaybe<Scalars['Boolean']>;
  /** text input for default locale (default) */
  text?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<CTextWeight>;
};

export type CTextLinkUpdateLocalizationDataInput = {
  text?: InputMaybe<Scalars['String']>;
};

export type CTextLinkUpdateLocalizationInput = {
  data: CTextLinkUpdateLocalizationDataInput;
  locale: Locale;
};

export type CTextLinkUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<CTextLinkCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<CTextLinkUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<CTextLinkUpsertLocalizationInput>>;
};

export type CTextLinkUpdateManyInlineInput = {
  /** Connect multiple existing CTextLink documents */
  connect?: InputMaybe<Array<CTextLinkConnectInput>>;
  /** Create and connect multiple CTextLink documents */
  create?: InputMaybe<Array<CTextLinkCreateInput>>;
  /** Delete multiple CTextLink documents */
  delete?: InputMaybe<Array<CTextLinkWhereUniqueInput>>;
  /** Disconnect multiple CTextLink documents */
  disconnect?: InputMaybe<Array<CTextLinkWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing CTextLink documents */
  set?: InputMaybe<Array<CTextLinkWhereUniqueInput>>;
  /** Update multiple CTextLink documents */
  update?: InputMaybe<Array<CTextLinkUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple CTextLink documents */
  upsert?: InputMaybe<Array<CTextLinkUpsertWithNestedWhereUniqueInput>>;
};

export type CTextLinkUpdateManyInput = {
  icon?: InputMaybe<Icon>;
  iconPosition?: InputMaybe<IconPosition>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<CTextLinkUpdateManyLocalizationsInput>;
  skipDefaultLocale?: InputMaybe<Scalars['Boolean']>;
  /** text input for default locale (default) */
  text?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<CTextWeight>;
};

export type CTextLinkUpdateManyLocalizationDataInput = {
  text?: InputMaybe<Scalars['String']>;
};

export type CTextLinkUpdateManyLocalizationInput = {
  data: CTextLinkUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type CTextLinkUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<CTextLinkUpdateManyLocalizationInput>>;
};

export type CTextLinkUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CTextLinkUpdateManyInput;
  /** Document search */
  where: CTextLinkWhereInput;
};

export type CTextLinkUpdateOneInlineInput = {
  /** Connect existing CTextLink document */
  connect?: InputMaybe<CTextLinkWhereUniqueInput>;
  /** Create and connect one CTextLink document */
  create?: InputMaybe<CTextLinkCreateInput>;
  /** Delete currently connected CTextLink document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected CTextLink document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single CTextLink document */
  update?: InputMaybe<CTextLinkUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CTextLink document */
  upsert?: InputMaybe<CTextLinkUpsertWithNestedWhereUniqueInput>;
};

export type CTextLinkUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CTextLinkUpdateInput;
  /** Unique document search */
  where: CTextLinkWhereUniqueInput;
};

export type CTextLinkUpsertInput = {
  /** Create document if it didn't exist */
  create: CTextLinkCreateInput;
  /** Update document if it exists */
  update: CTextLinkUpdateInput;
};

export type CTextLinkUpsertLocalizationInput = {
  create: CTextLinkCreateLocalizationDataInput;
  locale: Locale;
  update: CTextLinkUpdateLocalizationDataInput;
};

export type CTextLinkUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CTextLinkUpsertInput;
  /** Unique document search */
  where: CTextLinkWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type CTextLinkWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type CTextLinkWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CTextLinkWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CTextLinkWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CTextLinkWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<CTextLinkWhereStageInput>;
  documentInStages_none?: InputMaybe<CTextLinkWhereStageInput>;
  documentInStages_some?: InputMaybe<CTextLinkWhereStageInput>;
  icon?: InputMaybe<Icon>;
  iconPosition?: InputMaybe<IconPosition>;
  /** All values that are contained in given list. */
  iconPosition_in?: InputMaybe<Array<InputMaybe<IconPosition>>>;
  /** Any other value that exists and is not equal to the given value. */
  iconPosition_not?: InputMaybe<IconPosition>;
  /** All values that are not contained in given list. */
  iconPosition_not_in?: InputMaybe<Array<InputMaybe<IconPosition>>>;
  /** All values that are contained in given list. */
  icon_in?: InputMaybe<Array<InputMaybe<Icon>>>;
  /** Any other value that exists and is not equal to the given value. */
  icon_not?: InputMaybe<Icon>;
  /** All values that are not contained in given list. */
  icon_not_in?: InputMaybe<Array<InputMaybe<Icon>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  link?: InputMaybe<LinkWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  skipDefaultLocale?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  skipDefaultLocale_not?: InputMaybe<Scalars['Boolean']>;
  text?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  text_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  text_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  text_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  text_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  text_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  text_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  text_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  text_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  text_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  weight?: InputMaybe<CTextWeight>;
  /** All values that are contained in given list. */
  weight_in?: InputMaybe<Array<InputMaybe<CTextWeight>>>;
  /** Any other value that exists and is not equal to the given value. */
  weight_not?: InputMaybe<CTextWeight>;
  /** All values that are not contained in given list. */
  weight_not_in?: InputMaybe<Array<InputMaybe<CTextWeight>>>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type CTextLinkWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CTextLinkWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CTextLinkWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CTextLinkWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<CTextLinkWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References CTextLink record uniquely */
export type CTextLinkWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export enum CTextSize {
  Large = 'large',
  Small = 'small',
  Standard = 'standard',
  Xsmall = 'xsmall',
}

export enum CTextTone {
  BrandAccent = 'brandAccent',
  Caution = 'caution',
  Critical = 'critical',
  Info = 'info',
  Link = 'link',
  Neutral = 'neutral',
  Positive = 'positive',
  Promote = 'promote',
  Secondary = 'secondary',
}

export enum CTextWeight {
  Regular = 'regular',
  Weak = 'weak',
}

export type CTile = Entity & {
  __typename?: 'CTile';
  background?: Maybe<BackgroundColor>;
  columns: Scalars['Int'];
  dividers?: Maybe<Scalars['Boolean']>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** Overrides the "Columns" value when used. */
  responsiveColumnValue?: Maybe<ResponsiveColumnValue>;
  space?: Maybe<BraidSizes>;
  /** System stage field */
  stage: Stage;
};

export type CTileResponsiveColumnValueArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CTileConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CTileWhereUniqueInput;
};

/** A connection to a list of items. */
export type CTileConnection = {
  __typename?: 'CTileConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CTileEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CTileCreateInput = {
  background?: InputMaybe<BackgroundColor>;
  columns: Scalars['Int'];
  dividers?: InputMaybe<Scalars['Boolean']>;
  responsiveColumnValue?: InputMaybe<ResponsiveColumnValueCreateOneInlineInput>;
  space?: InputMaybe<BraidSizes>;
};

export type CTileCreateManyInlineInput = {
  /** Create and connect multiple existing CTile documents */
  create?: InputMaybe<Array<CTileCreateInput>>;
};

export type CTileCreateOneInlineInput = {
  /** Create and connect one CTile document */
  create?: InputMaybe<CTileCreateInput>;
};

export type CTileCreateWithPositionInput = {
  /** Document to create */
  data: CTileCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type CTileEdge = {
  __typename?: 'CTileEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CTile;
};

/** Identifies documents */
export type CTileManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CTileWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CTileWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CTileWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  background?: InputMaybe<BackgroundColor>;
  /** All values that are contained in given list. */
  background_in?: InputMaybe<Array<InputMaybe<BackgroundColor>>>;
  /** Any other value that exists and is not equal to the given value. */
  background_not?: InputMaybe<BackgroundColor>;
  /** All values that are not contained in given list. */
  background_not_in?: InputMaybe<Array<InputMaybe<BackgroundColor>>>;
  columns?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  columns_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  columns_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  columns_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** All values less than the given value. */
  columns_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  columns_lte?: InputMaybe<Scalars['Int']>;
  /** Any other value that exists and is not equal to the given value. */
  columns_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  columns_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  dividers?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  dividers_not?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  responsiveColumnValue?: InputMaybe<ResponsiveColumnValueWhereInput>;
  space?: InputMaybe<BraidSizes>;
  /** All values that are contained in given list. */
  space_in?: InputMaybe<Array<InputMaybe<BraidSizes>>>;
  /** Any other value that exists and is not equal to the given value. */
  space_not?: InputMaybe<BraidSizes>;
  /** All values that are not contained in given list. */
  space_not_in?: InputMaybe<Array<InputMaybe<BraidSizes>>>;
};

export enum CTileOrderByInput {
  BackgroundAsc = 'background_ASC',
  BackgroundDesc = 'background_DESC',
  ColumnsAsc = 'columns_ASC',
  ColumnsDesc = 'columns_DESC',
  DividersAsc = 'dividers_ASC',
  DividersDesc = 'dividers_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SpaceAsc = 'space_ASC',
  SpaceDesc = 'space_DESC',
}

export type CTileParent =
  | ArticleAdditionalContent
  | CBlock
  | CCardGroup
  | CContainer
  | CGallery
  | CIconBlockGroup
  | CImageBlockGroup
  | CStepBlockGroup
  | VideoDialogGroup;

export type CTileParentConnectInput = {
  ArticleAdditionalContent?: InputMaybe<ArticleAdditionalContentConnectInput>;
  CBlock?: InputMaybe<CBlockConnectInput>;
  CCardGroup?: InputMaybe<CCardGroupConnectInput>;
  CContainer?: InputMaybe<CContainerConnectInput>;
  CGallery?: InputMaybe<CGalleryConnectInput>;
  CIconBlockGroup?: InputMaybe<CIconBlockGroupConnectInput>;
  CImageBlockGroup?: InputMaybe<CImageBlockGroupConnectInput>;
  CStepBlockGroup?: InputMaybe<CStepBlockGroupConnectInput>;
  VideoDialogGroup?: InputMaybe<VideoDialogGroupConnectInput>;
};

export type CTileParentCreateInput = {
  ArticleAdditionalContent?: InputMaybe<ArticleAdditionalContentCreateInput>;
  CBlock?: InputMaybe<CBlockCreateInput>;
  CCardGroup?: InputMaybe<CCardGroupCreateInput>;
  CContainer?: InputMaybe<CContainerCreateInput>;
  CGallery?: InputMaybe<CGalleryCreateInput>;
  CIconBlockGroup?: InputMaybe<CIconBlockGroupCreateInput>;
  CImageBlockGroup?: InputMaybe<CImageBlockGroupCreateInput>;
  CStepBlockGroup?: InputMaybe<CStepBlockGroupCreateInput>;
  VideoDialogGroup?: InputMaybe<VideoDialogGroupCreateInput>;
};

export type CTileParentCreateManyInlineInput = {
  /** Connect multiple existing CTileParent documents */
  connect?: InputMaybe<Array<CTileParentWhereUniqueInput>>;
  /** Create and connect multiple existing CTileParent documents */
  create?: InputMaybe<Array<CTileParentCreateInput>>;
};

export type CTileParentCreateOneInlineInput = {
  /** Connect one existing CTileParent document */
  connect?: InputMaybe<CTileParentWhereUniqueInput>;
  /** Create and connect one CTileParent document */
  create?: InputMaybe<CTileParentCreateInput>;
};

export type CTileParentUpdateInput = {
  ArticleAdditionalContent?: InputMaybe<ArticleAdditionalContentUpdateInput>;
  CBlock?: InputMaybe<CBlockUpdateInput>;
  CCardGroup?: InputMaybe<CCardGroupUpdateInput>;
  CContainer?: InputMaybe<CContainerUpdateInput>;
  CGallery?: InputMaybe<CGalleryUpdateInput>;
  CIconBlockGroup?: InputMaybe<CIconBlockGroupUpdateInput>;
  CImageBlockGroup?: InputMaybe<CImageBlockGroupUpdateInput>;
  CStepBlockGroup?: InputMaybe<CStepBlockGroupUpdateInput>;
  VideoDialogGroup?: InputMaybe<VideoDialogGroupUpdateInput>;
};

export type CTileParentUpdateManyInlineInput = {
  /** Connect multiple existing CTileParent documents */
  connect?: InputMaybe<Array<CTileParentConnectInput>>;
  /** Create and connect multiple CTileParent documents */
  create?: InputMaybe<Array<CTileParentCreateInput>>;
  /** Delete multiple CTileParent documents */
  delete?: InputMaybe<Array<CTileParentWhereUniqueInput>>;
  /** Disconnect multiple CTileParent documents */
  disconnect?: InputMaybe<Array<CTileParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing CTileParent documents */
  set?: InputMaybe<Array<CTileParentWhereUniqueInput>>;
  /** Update multiple CTileParent documents */
  update?: InputMaybe<Array<CTileParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple CTileParent documents */
  upsert?: InputMaybe<Array<CTileParentUpsertWithNestedWhereUniqueInput>>;
};

export type CTileParentUpdateManyWithNestedWhereInput = {
  ArticleAdditionalContent?: InputMaybe<ArticleAdditionalContentUpdateManyWithNestedWhereInput>;
  CBlock?: InputMaybe<CBlockUpdateManyWithNestedWhereInput>;
  CCardGroup?: InputMaybe<CCardGroupUpdateManyWithNestedWhereInput>;
  CContainer?: InputMaybe<CContainerUpdateManyWithNestedWhereInput>;
  CGallery?: InputMaybe<CGalleryUpdateManyWithNestedWhereInput>;
  CIconBlockGroup?: InputMaybe<CIconBlockGroupUpdateManyWithNestedWhereInput>;
  CImageBlockGroup?: InputMaybe<CImageBlockGroupUpdateManyWithNestedWhereInput>;
  CStepBlockGroup?: InputMaybe<CStepBlockGroupUpdateManyWithNestedWhereInput>;
  VideoDialogGroup?: InputMaybe<VideoDialogGroupUpdateManyWithNestedWhereInput>;
};

export type CTileParentUpdateOneInlineInput = {
  /** Connect existing CTileParent document */
  connect?: InputMaybe<CTileParentWhereUniqueInput>;
  /** Create and connect one CTileParent document */
  create?: InputMaybe<CTileParentCreateInput>;
  /** Delete currently connected CTileParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected CTileParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single CTileParent document */
  update?: InputMaybe<CTileParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CTileParent document */
  upsert?: InputMaybe<CTileParentUpsertWithNestedWhereUniqueInput>;
};

export type CTileParentUpdateWithNestedWhereUniqueInput = {
  ArticleAdditionalContent?: InputMaybe<ArticleAdditionalContentUpdateWithNestedWhereUniqueInput>;
  CBlock?: InputMaybe<CBlockUpdateWithNestedWhereUniqueInput>;
  CCardGroup?: InputMaybe<CCardGroupUpdateWithNestedWhereUniqueInput>;
  CContainer?: InputMaybe<CContainerUpdateWithNestedWhereUniqueInput>;
  CGallery?: InputMaybe<CGalleryUpdateWithNestedWhereUniqueInput>;
  CIconBlockGroup?: InputMaybe<CIconBlockGroupUpdateWithNestedWhereUniqueInput>;
  CImageBlockGroup?: InputMaybe<CImageBlockGroupUpdateWithNestedWhereUniqueInput>;
  CStepBlockGroup?: InputMaybe<CStepBlockGroupUpdateWithNestedWhereUniqueInput>;
  VideoDialogGroup?: InputMaybe<VideoDialogGroupUpdateWithNestedWhereUniqueInput>;
};

export type CTileParentUpsertWithNestedWhereUniqueInput = {
  ArticleAdditionalContent?: InputMaybe<ArticleAdditionalContentUpsertWithNestedWhereUniqueInput>;
  CBlock?: InputMaybe<CBlockUpsertWithNestedWhereUniqueInput>;
  CCardGroup?: InputMaybe<CCardGroupUpsertWithNestedWhereUniqueInput>;
  CContainer?: InputMaybe<CContainerUpsertWithNestedWhereUniqueInput>;
  CGallery?: InputMaybe<CGalleryUpsertWithNestedWhereUniqueInput>;
  CIconBlockGroup?: InputMaybe<CIconBlockGroupUpsertWithNestedWhereUniqueInput>;
  CImageBlockGroup?: InputMaybe<CImageBlockGroupUpsertWithNestedWhereUniqueInput>;
  CStepBlockGroup?: InputMaybe<CStepBlockGroupUpsertWithNestedWhereUniqueInput>;
  VideoDialogGroup?: InputMaybe<VideoDialogGroupUpsertWithNestedWhereUniqueInput>;
};

export type CTileParentWhereInput = {
  ArticleAdditionalContent?: InputMaybe<ArticleAdditionalContentWhereInput>;
  CBlock?: InputMaybe<CBlockWhereInput>;
  CCardGroup?: InputMaybe<CCardGroupWhereInput>;
  CContainer?: InputMaybe<CContainerWhereInput>;
  CGallery?: InputMaybe<CGalleryWhereInput>;
  CIconBlockGroup?: InputMaybe<CIconBlockGroupWhereInput>;
  CImageBlockGroup?: InputMaybe<CImageBlockGroupWhereInput>;
  CStepBlockGroup?: InputMaybe<CStepBlockGroupWhereInput>;
  VideoDialogGroup?: InputMaybe<VideoDialogGroupWhereInput>;
};

export type CTileParentWhereUniqueInput = {
  ArticleAdditionalContent?: InputMaybe<ArticleAdditionalContentWhereUniqueInput>;
  CBlock?: InputMaybe<CBlockWhereUniqueInput>;
  CCardGroup?: InputMaybe<CCardGroupWhereUniqueInput>;
  CContainer?: InputMaybe<CContainerWhereUniqueInput>;
  CGallery?: InputMaybe<CGalleryWhereUniqueInput>;
  CIconBlockGroup?: InputMaybe<CIconBlockGroupWhereUniqueInput>;
  CImageBlockGroup?: InputMaybe<CImageBlockGroupWhereUniqueInput>;
  CStepBlockGroup?: InputMaybe<CStepBlockGroupWhereUniqueInput>;
  VideoDialogGroup?: InputMaybe<VideoDialogGroupWhereUniqueInput>;
};

export enum CTileSpace {
  Gutter = 'gutter',
  Large = 'large',
  Medium = 'medium',
  None = 'none',
  Small = 'small',
  Xlarge = 'xlarge',
  Xsmall = 'xsmall',
  Xxlarge = 'xxlarge',
  Xxsmall = 'xxsmall',
}

export type CTileUpdateInput = {
  background?: InputMaybe<BackgroundColor>;
  columns?: InputMaybe<Scalars['Int']>;
  dividers?: InputMaybe<Scalars['Boolean']>;
  responsiveColumnValue?: InputMaybe<ResponsiveColumnValueUpdateOneInlineInput>;
  space?: InputMaybe<BraidSizes>;
};

export type CTileUpdateManyInlineInput = {
  /** Create and connect multiple CTile component instances */
  create?: InputMaybe<Array<CTileCreateWithPositionInput>>;
  /** Delete multiple CTile documents */
  delete?: InputMaybe<Array<CTileWhereUniqueInput>>;
  /** Update multiple CTile component instances */
  update?: InputMaybe<Array<CTileUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple CTile component instances */
  upsert?: InputMaybe<Array<CTileUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type CTileUpdateManyInput = {
  background?: InputMaybe<BackgroundColor>;
  columns?: InputMaybe<Scalars['Int']>;
  dividers?: InputMaybe<Scalars['Boolean']>;
  space?: InputMaybe<BraidSizes>;
};

export type CTileUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CTileUpdateManyInput;
  /** Document search */
  where: CTileWhereInput;
};

export type CTileUpdateOneInlineInput = {
  /** Create and connect one CTile document */
  create?: InputMaybe<CTileCreateInput>;
  /** Delete currently connected CTile document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CTile document */
  update?: InputMaybe<CTileUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CTile document */
  upsert?: InputMaybe<CTileUpsertWithNestedWhereUniqueInput>;
};

export type CTileUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<CTileUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CTileWhereUniqueInput;
};

export type CTileUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CTileUpdateInput;
  /** Unique document search */
  where: CTileWhereUniqueInput;
};

export type CTileUpsertInput = {
  /** Create document if it didn't exist */
  create: CTileCreateInput;
  /** Update document if it exists */
  update: CTileUpdateInput;
};

export type CTileUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<CTileUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CTileWhereUniqueInput;
};

export type CTileUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CTileUpsertInput;
  /** Unique document search */
  where: CTileWhereUniqueInput;
};

/** Identifies documents */
export type CTileWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CTileWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CTileWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CTileWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  background?: InputMaybe<BackgroundColor>;
  /** All values that are contained in given list. */
  background_in?: InputMaybe<Array<InputMaybe<BackgroundColor>>>;
  /** Any other value that exists and is not equal to the given value. */
  background_not?: InputMaybe<BackgroundColor>;
  /** All values that are not contained in given list. */
  background_not_in?: InputMaybe<Array<InputMaybe<BackgroundColor>>>;
  columns?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  columns_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  columns_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  columns_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** All values less than the given value. */
  columns_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  columns_lte?: InputMaybe<Scalars['Int']>;
  /** Any other value that exists and is not equal to the given value. */
  columns_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  columns_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  dividers?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  dividers_not?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  responsiveColumnValue?: InputMaybe<ResponsiveColumnValueWhereInput>;
  space?: InputMaybe<BraidSizes>;
  /** All values that are contained in given list. */
  space_in?: InputMaybe<Array<InputMaybe<BraidSizes>>>;
  /** Any other value that exists and is not equal to the given value. */
  space_not?: InputMaybe<BraidSizes>;
  /** All values that are not contained in given list. */
  space_not_in?: InputMaybe<Array<InputMaybe<BraidSizes>>>;
};

/** References CTile record uniquely */
export type CTileWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Represents Card styles */
export enum CardStyle {
  Primary = 'Primary',
  Secondary = 'Secondary',
}

export type Category = Entity &
  Node & {
    __typename?: 'Category';
    /** Add additional content to follow after the main Article content. This will display for all Articles in this Category. */
    additionalContent?: Maybe<ArticleAdditionalContent>;
    children: Array<Category>;
    /** The time the document was created */
    createdAt: Scalars['DateTime'];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** category description. */
    description?: Maybe<RichText>;
    /** Get the document in other stages */
    documentInStages: Array<Category>;
    /** List of Category versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars['ID'];
    /** System Locale field */
    locale: Locale;
    /** Get the other localizations for this document */
    localizations: Array<Category>;
    /** category name. */
    name: Scalars['String'];
    parent?: Maybe<Category>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    relatedArticlesInCategory: Array<Article>;
    relatedCategorySection?: Maybe<Section>;
    scheduledIn: Array<ScheduledOperation>;
    seo?: Maybe<Seo>;
    sites: Array<Site>;
    /** category slug. */
    slug: Scalars['String'];
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars['DateTime'];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type CategoryAdditionalContentArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CategoryChildrenArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<CategoryOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CategoryWhereInput>;
};

export type CategoryCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type CategoryCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CategoryDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type CategoryHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};

export type CategoryLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type CategoryParentArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CategoryPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type CategoryPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CategoryRelatedArticlesInCategoryArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ArticleOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ArticleWhereInput>;
};

export type CategoryRelatedCategorySectionArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CategoryScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type CategorySeoArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CategorySitesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<SiteOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SiteWhereInput>;
};

export type CategoryUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type CategoryUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CategoryConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CategoryWhereUniqueInput;
};

/** A connection to a list of items. */
export type CategoryConnection = {
  __typename?: 'CategoryConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CategoryEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CategoryCreateInput = {
  additionalContent?: InputMaybe<ArticleAdditionalContentCreateOneInlineInput>;
  children?: InputMaybe<CategoryCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** description input for default locale (default) */
  description?: InputMaybe<Scalars['RichTextAST']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<CategoryCreateLocalizationsInput>;
  /** name input for default locale (default) */
  name: Scalars['String'];
  parent?: InputMaybe<CategoryCreateOneInlineInput>;
  relatedArticlesCategory?: InputMaybe<LatestArticlesByCategoryCreateManyInlineInput>;
  relatedArticlesInCategory?: InputMaybe<ArticleCreateManyInlineInput>;
  relatedCategorySection?: InputMaybe<SectionCreateOneInlineInput>;
  seo?: InputMaybe<SeoCreateOneInlineInput>;
  sites?: InputMaybe<SiteCreateManyInlineInput>;
  /** slug input for default locale (default) */
  slug: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CategoryCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['RichTextAST']>;
  name: Scalars['String'];
  slug: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CategoryCreateLocalizationInput = {
  /** Localization input */
  data: CategoryCreateLocalizationDataInput;
  locale: Locale;
};

export type CategoryCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<CategoryCreateLocalizationInput>>;
};

export type CategoryCreateManyInlineInput = {
  /** Connect multiple existing Category documents */
  connect?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  /** Create and connect multiple existing Category documents */
  create?: InputMaybe<Array<CategoryCreateInput>>;
};

export type CategoryCreateOneInlineInput = {
  /** Connect one existing Category document */
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  /** Create and connect one Category document */
  create?: InputMaybe<CategoryCreateInput>;
};

/** An edge in a connection. */
export type CategoryEdge = {
  __typename?: 'CategoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Category;
};

/** Identifies documents */
export type CategoryManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CategoryWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CategoryWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CategoryWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  additionalContent?: InputMaybe<ArticleAdditionalContentWhereInput>;
  children_every?: InputMaybe<CategoryWhereInput>;
  children_none?: InputMaybe<CategoryWhereInput>;
  children_some?: InputMaybe<CategoryWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<CategoryWhereStageInput>;
  documentInStages_none?: InputMaybe<CategoryWhereStageInput>;
  documentInStages_some?: InputMaybe<CategoryWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  parent?: InputMaybe<CategoryWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  relatedArticlesInCategory_every?: InputMaybe<ArticleWhereInput>;
  relatedArticlesInCategory_none?: InputMaybe<ArticleWhereInput>;
  relatedArticlesInCategory_some?: InputMaybe<ArticleWhereInput>;
  relatedCategorySection?: InputMaybe<SectionWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  seo?: InputMaybe<SeoWhereInput>;
  sites_every?: InputMaybe<SiteWhereInput>;
  sites_none?: InputMaybe<SiteWhereInput>;
  sites_some?: InputMaybe<SiteWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum CategoryOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type CategoryUpdateInput = {
  additionalContent?: InputMaybe<ArticleAdditionalContentUpdateOneInlineInput>;
  children?: InputMaybe<CategoryUpdateManyInlineInput>;
  /** description input for default locale (default) */
  description?: InputMaybe<Scalars['RichTextAST']>;
  /** Manage document localizations */
  localizations?: InputMaybe<CategoryUpdateLocalizationsInput>;
  /** name input for default locale (default) */
  name?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<CategoryUpdateOneInlineInput>;
  relatedArticlesCategory?: InputMaybe<LatestArticlesByCategoryUpdateManyInlineInput>;
  relatedArticlesInCategory?: InputMaybe<ArticleUpdateManyInlineInput>;
  relatedCategorySection?: InputMaybe<SectionUpdateOneInlineInput>;
  seo?: InputMaybe<SeoUpdateOneInlineInput>;
  sites?: InputMaybe<SiteUpdateManyInlineInput>;
  /** slug input for default locale (default) */
  slug?: InputMaybe<Scalars['String']>;
};

export type CategoryUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['RichTextAST']>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type CategoryUpdateLocalizationInput = {
  data: CategoryUpdateLocalizationDataInput;
  locale: Locale;
};

export type CategoryUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<CategoryCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<CategoryUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<CategoryUpsertLocalizationInput>>;
};

export type CategoryUpdateManyInlineInput = {
  /** Connect multiple existing Category documents */
  connect?: InputMaybe<Array<CategoryConnectInput>>;
  /** Create and connect multiple Category documents */
  create?: InputMaybe<Array<CategoryCreateInput>>;
  /** Delete multiple Category documents */
  delete?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  /** Disconnect multiple Category documents */
  disconnect?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Category documents */
  set?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  /** Update multiple Category documents */
  update?: InputMaybe<Array<CategoryUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Category documents */
  upsert?: InputMaybe<Array<CategoryUpsertWithNestedWhereUniqueInput>>;
};

export type CategoryUpdateManyInput = {
  /** description input for default locale (default) */
  description?: InputMaybe<Scalars['RichTextAST']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<CategoryUpdateManyLocalizationsInput>;
  /** name input for default locale (default) */
  name?: InputMaybe<Scalars['String']>;
};

export type CategoryUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['RichTextAST']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CategoryUpdateManyLocalizationInput = {
  data: CategoryUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type CategoryUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<CategoryUpdateManyLocalizationInput>>;
};

export type CategoryUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CategoryUpdateManyInput;
  /** Document search */
  where: CategoryWhereInput;
};

export type CategoryUpdateOneInlineInput = {
  /** Connect existing Category document */
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  /** Create and connect one Category document */
  create?: InputMaybe<CategoryCreateInput>;
  /** Delete currently connected Category document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected Category document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single Category document */
  update?: InputMaybe<CategoryUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Category document */
  upsert?: InputMaybe<CategoryUpsertWithNestedWhereUniqueInput>;
};

export type CategoryUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CategoryUpdateInput;
  /** Unique document search */
  where: CategoryWhereUniqueInput;
};

export type CategoryUpsertInput = {
  /** Create document if it didn't exist */
  create: CategoryCreateInput;
  /** Update document if it exists */
  update: CategoryUpdateInput;
};

export type CategoryUpsertLocalizationInput = {
  create: CategoryCreateLocalizationDataInput;
  locale: Locale;
  update: CategoryUpdateLocalizationDataInput;
};

export type CategoryUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CategoryUpsertInput;
  /** Unique document search */
  where: CategoryWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type CategoryWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type CategoryWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CategoryWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CategoryWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CategoryWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  additionalContent?: InputMaybe<ArticleAdditionalContentWhereInput>;
  children_every?: InputMaybe<CategoryWhereInput>;
  children_none?: InputMaybe<CategoryWhereInput>;
  children_some?: InputMaybe<CategoryWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<CategoryWhereStageInput>;
  documentInStages_none?: InputMaybe<CategoryWhereStageInput>;
  documentInStages_some?: InputMaybe<CategoryWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<CategoryWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  relatedArticlesInCategory_every?: InputMaybe<ArticleWhereInput>;
  relatedArticlesInCategory_none?: InputMaybe<ArticleWhereInput>;
  relatedArticlesInCategory_some?: InputMaybe<ArticleWhereInput>;
  relatedCategorySection?: InputMaybe<SectionWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  seo?: InputMaybe<SeoWhereInput>;
  sites_every?: InputMaybe<SiteWhereInput>;
  sites_none?: InputMaybe<SiteWhereInput>;
  sites_some?: InputMaybe<SiteWhereInput>;
  slug?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type CategoryWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CategoryWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CategoryWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CategoryWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<CategoryWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Category record uniquely */
export type CategoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Representing a color value comprising of HEX, RGBA and css color values */
export type Color = {
  __typename?: 'Color';
  css: Scalars['String'];
  hex: Scalars['Hex'];
  rgba: Rgba;
};

/** Accepts either HEX or RGBA color value. At least one of hex or rgba value should be passed. If both are passed RGBA is used. */
export type ColorInput = {
  hex?: InputMaybe<Scalars['Hex']>;
  rgba?: InputMaybe<RgbaInput>;
};

/** Represents Button Colour based on Braid Design System. */
export enum Colour {
  BrandAccent = 'brandAccent',
  Critical = 'critical',
  Default = 'default',
  Neutral = 'neutral',
}

/** Represents the card listing column values. */
export enum Columns {
  Three = 'Three',
  Two = 'Two',
}

export type ConnectPositionInput = {
  /** Connect document after specified document */
  after?: InputMaybe<Scalars['ID']>;
  /** Connect document before specified document */
  before?: InputMaybe<Scalars['ID']>;
  /** Connect document at last position */
  end?: InputMaybe<Scalars['Boolean']>;
  /** Connect document at first position */
  start?: InputMaybe<Scalars['Boolean']>;
};

/** Use for any block or parent component to add in custom component renderer */
export enum CustomComponent {
  TalLandingQueryFilterForm = 'TAL_LANDING_QUERY_FILTER_FORM',
}

/** List of custom decoration to apply in components */
export enum CustomDecoration {
  ArchesBlueBottomLeft = 'arches_blue_bottom_left',
  ArchesBlueLeft = 'arches_blue_left',
  ArchesBlueRight = 'arches_blue_right',
  ArchesBlueTopLeft = 'arches_blue_top_left',
  ArchesWhiteBottomRight = 'arches_white_bottom_right',
  ArchesWhiteTopRight = 'arches_white_top_right',
  CareersRibbons_1 = 'careers_ribbons_1',
  CareersRibbons_2 = 'careers_ribbons_2',
  CareersRibbons_3 = 'careers_ribbons_3',
  CareersRibbons_4 = 'careers_ribbons_4',
  CareersRibbonsAnimated = 'careers_ribbons_animated',
  ContactUsBannerDeco = 'contact_us_banner_deco',
  SaraBokeh_01 = 'sara_bokeh_01',
  SaraBokeh_02 = 'sara_bokeh_02',
  StarPinkBottomLeft = 'star_pink_bottom_left',
  StarPinkTopRight = 'star_pink_top_right',
  TalBannerDeco = 'tal_banner_deco',
  TalBannerDeco_2 = 'tal_banner_deco_2',
}

/** A custom font size that is map according to toCustomFontSize */
export enum CustomFontSize {
  Large = 'large',
  Small = 'small',
  Standard = 'standard',
  Xlarge = 'xlarge',
  Xsmall = 'xsmall',
  Xxlarge = 'xxlarge',
  Xxsmall = 'xxsmall',
  Xxxlarge = 'xxxlarge',
  Xxxsmall = 'xxxsmall',
}

export type CustomFooter = Entity & {
  __typename?: 'CustomFooter';
  backgroundColor?: Maybe<CBoxBackground>;
  /** The unique identifier */
  id: Scalars['ID'];
  links: Array<CTextLink>;
  /** Small logo image to display, defaults to relevant SEEK brand logo */
  logo?: Maybe<Asset>;
  /** System stage field */
  stage: Stage;
};

export type CustomFooterLinksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<CTextLinkOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CTextLinkWhereInput>;
};

export type CustomFooterLogoArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
  where?: InputMaybe<AssetSingleRelationWhereInput>;
};

export type CustomFooterConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CustomFooterWhereUniqueInput;
};

/** A connection to a list of items. */
export type CustomFooterConnection = {
  __typename?: 'CustomFooterConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CustomFooterEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CustomFooterCreateInput = {
  backgroundColor?: InputMaybe<CBoxBackground>;
  links?: InputMaybe<CTextLinkCreateManyInlineInput>;
  logo?: InputMaybe<AssetCreateOneInlineInput>;
};

export type CustomFooterCreateManyInlineInput = {
  /** Create and connect multiple existing CustomFooter documents */
  create?: InputMaybe<Array<CustomFooterCreateInput>>;
};

export type CustomFooterCreateOneInlineInput = {
  /** Create and connect one CustomFooter document */
  create?: InputMaybe<CustomFooterCreateInput>;
};

export type CustomFooterCreateWithPositionInput = {
  /** Document to create */
  data: CustomFooterCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type CustomFooterEdge = {
  __typename?: 'CustomFooterEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CustomFooter;
};

/** Identifies documents */
export type CustomFooterManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CustomFooterWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CustomFooterWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CustomFooterWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  backgroundColor?: InputMaybe<CBoxBackground>;
  /** All values that are contained in given list. */
  backgroundColor_in?: InputMaybe<Array<InputMaybe<CBoxBackground>>>;
  /** Any other value that exists and is not equal to the given value. */
  backgroundColor_not?: InputMaybe<CBoxBackground>;
  /** All values that are not contained in given list. */
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<CBoxBackground>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  links_every?: InputMaybe<CTextLinkWhereInput>;
  links_none?: InputMaybe<CTextLinkWhereInput>;
  links_some?: InputMaybe<CTextLinkWhereInput>;
  logo?: InputMaybe<AssetWhereInput>;
};

export enum CustomFooterOrderByInput {
  BackgroundColorAsc = 'backgroundColor_ASC',
  BackgroundColorDesc = 'backgroundColor_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type CustomFooterParent = SectionFooter;

export type CustomFooterParentConnectInput = {
  SectionFooter?: InputMaybe<SectionFooterConnectInput>;
};

export type CustomFooterParentCreateInput = {
  SectionFooter?: InputMaybe<SectionFooterCreateInput>;
};

export type CustomFooterParentCreateManyInlineInput = {
  /** Create and connect multiple existing CustomFooterParent documents */
  create?: InputMaybe<Array<CustomFooterParentCreateInput>>;
};

export type CustomFooterParentCreateOneInlineInput = {
  /** Create and connect one CustomFooterParent document */
  create?: InputMaybe<CustomFooterParentCreateInput>;
};

export type CustomFooterParentCreateWithPositionInput = {
  SectionFooter?: InputMaybe<SectionFooterCreateWithPositionInput>;
};

export type CustomFooterParentUpdateInput = {
  SectionFooter?: InputMaybe<SectionFooterUpdateInput>;
};

export type CustomFooterParentUpdateManyInlineInput = {
  /** Create and connect multiple CustomFooterParent component instances */
  create?: InputMaybe<Array<CustomFooterParentCreateWithPositionInput>>;
  /** Delete multiple CustomFooterParent documents */
  delete?: InputMaybe<Array<CustomFooterParentWhereUniqueInput>>;
  /** Update multiple CustomFooterParent component instances */
  update?: InputMaybe<
    Array<CustomFooterParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CustomFooterParent component instances */
  upsert?: InputMaybe<
    Array<CustomFooterParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CustomFooterParentUpdateManyWithNestedWhereInput = {
  SectionFooter?: InputMaybe<SectionFooterUpdateManyWithNestedWhereInput>;
};

export type CustomFooterParentUpdateOneInlineInput = {
  /** Create and connect one CustomFooterParent document */
  create?: InputMaybe<CustomFooterParentCreateInput>;
  /** Delete currently connected CustomFooterParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CustomFooterParent document */
  update?: InputMaybe<CustomFooterParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CustomFooterParent document */
  upsert?: InputMaybe<CustomFooterParentUpsertWithNestedWhereUniqueInput>;
};

export type CustomFooterParentUpdateWithNestedWhereUniqueAndPositionInput = {
  SectionFooter?: InputMaybe<SectionFooterUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type CustomFooterParentUpdateWithNestedWhereUniqueInput = {
  SectionFooter?: InputMaybe<SectionFooterUpdateWithNestedWhereUniqueInput>;
};

export type CustomFooterParentUpsertWithNestedWhereUniqueAndPositionInput = {
  SectionFooter?: InputMaybe<SectionFooterUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type CustomFooterParentUpsertWithNestedWhereUniqueInput = {
  SectionFooter?: InputMaybe<SectionFooterUpsertWithNestedWhereUniqueInput>;
};

export type CustomFooterParentWhereInput = {
  SectionFooter?: InputMaybe<SectionFooterWhereInput>;
};

export type CustomFooterParentWhereUniqueInput = {
  SectionFooter?: InputMaybe<SectionFooterWhereUniqueInput>;
};

export type CustomFooterUpdateInput = {
  backgroundColor?: InputMaybe<CBoxBackground>;
  links?: InputMaybe<CTextLinkUpdateManyInlineInput>;
  logo?: InputMaybe<AssetUpdateOneInlineInput>;
};

export type CustomFooterUpdateManyInlineInput = {
  /** Create and connect multiple CustomFooter component instances */
  create?: InputMaybe<Array<CustomFooterCreateWithPositionInput>>;
  /** Delete multiple CustomFooter documents */
  delete?: InputMaybe<Array<CustomFooterWhereUniqueInput>>;
  /** Update multiple CustomFooter component instances */
  update?: InputMaybe<
    Array<CustomFooterUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CustomFooter component instances */
  upsert?: InputMaybe<
    Array<CustomFooterUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CustomFooterUpdateManyInput = {
  backgroundColor?: InputMaybe<CBoxBackground>;
};

export type CustomFooterUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CustomFooterUpdateManyInput;
  /** Document search */
  where: CustomFooterWhereInput;
};

export type CustomFooterUpdateOneInlineInput = {
  /** Create and connect one CustomFooter document */
  create?: InputMaybe<CustomFooterCreateInput>;
  /** Delete currently connected CustomFooter document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CustomFooter document */
  update?: InputMaybe<CustomFooterUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CustomFooter document */
  upsert?: InputMaybe<CustomFooterUpsertWithNestedWhereUniqueInput>;
};

export type CustomFooterUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<CustomFooterUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CustomFooterWhereUniqueInput;
};

export type CustomFooterUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CustomFooterUpdateInput;
  /** Unique document search */
  where: CustomFooterWhereUniqueInput;
};

export type CustomFooterUpsertInput = {
  /** Create document if it didn't exist */
  create: CustomFooterCreateInput;
  /** Update document if it exists */
  update: CustomFooterUpdateInput;
};

export type CustomFooterUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<CustomFooterUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CustomFooterWhereUniqueInput;
};

export type CustomFooterUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CustomFooterUpsertInput;
  /** Unique document search */
  where: CustomFooterWhereUniqueInput;
};

/** Identifies documents */
export type CustomFooterWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CustomFooterWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CustomFooterWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CustomFooterWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  backgroundColor?: InputMaybe<CBoxBackground>;
  /** All values that are contained in given list. */
  backgroundColor_in?: InputMaybe<Array<InputMaybe<CBoxBackground>>>;
  /** Any other value that exists and is not equal to the given value. */
  backgroundColor_not?: InputMaybe<CBoxBackground>;
  /** All values that are not contained in given list. */
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<CBoxBackground>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  links_every?: InputMaybe<CTextLinkWhereInput>;
  links_none?: InputMaybe<CTextLinkWhereInput>;
  links_some?: InputMaybe<CTextLinkWhereInput>;
  logo?: InputMaybe<AssetWhereInput>;
};

/** References CustomFooter record uniquely */
export type CustomFooterWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type CustomHeader = Entity & {
  __typename?: 'CustomHeader';
  /** Display a thin border divider under the header */
  divider?: Maybe<Scalars['Boolean']>;
  /** The unique identifier */
  id: Scalars['ID'];
  links: Array<TextLinkGroup>;
  /** Defaults to 'left' */
  linksAlignment?: Maybe<CTextAlign>;
  /** Display an imageLink as a logo. Defaults to the SEEK logo if nothing selected. */
  logo?: Maybe<CImageLink>;
  /** System stage field */
  stage: Stage;
};

export type CustomHeaderLinksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<TextLinkGroupOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TextLinkGroupWhereInput>;
};

export type CustomHeaderLogoArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CustomHeaderConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CustomHeaderWhereUniqueInput;
};

/** A connection to a list of items. */
export type CustomHeaderConnection = {
  __typename?: 'CustomHeaderConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CustomHeaderEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CustomHeaderCreateInput = {
  divider?: InputMaybe<Scalars['Boolean']>;
  links?: InputMaybe<TextLinkGroupCreateManyInlineInput>;
  linksAlignment?: InputMaybe<CTextAlign>;
  logo?: InputMaybe<CImageLinkCreateOneInlineInput>;
};

export type CustomHeaderCreateManyInlineInput = {
  /** Create and connect multiple existing CustomHeader documents */
  create?: InputMaybe<Array<CustomHeaderCreateInput>>;
};

export type CustomHeaderCreateOneInlineInput = {
  /** Create and connect one CustomHeader document */
  create?: InputMaybe<CustomHeaderCreateInput>;
};

export type CustomHeaderCreateWithPositionInput = {
  /** Document to create */
  data: CustomHeaderCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type CustomHeaderEdge = {
  __typename?: 'CustomHeaderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CustomHeader;
};

/** Identifies documents */
export type CustomHeaderManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CustomHeaderWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CustomHeaderWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CustomHeaderWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  divider?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  divider_not?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  linksAlignment?: InputMaybe<CTextAlign>;
  /** All values that are contained in given list. */
  linksAlignment_in?: InputMaybe<Array<InputMaybe<CTextAlign>>>;
  /** Any other value that exists and is not equal to the given value. */
  linksAlignment_not?: InputMaybe<CTextAlign>;
  /** All values that are not contained in given list. */
  linksAlignment_not_in?: InputMaybe<Array<InputMaybe<CTextAlign>>>;
  links_every?: InputMaybe<TextLinkGroupWhereInput>;
  links_none?: InputMaybe<TextLinkGroupWhereInput>;
  links_some?: InputMaybe<TextLinkGroupWhereInput>;
  logo?: InputMaybe<CImageLinkWhereInput>;
};

export enum CustomHeaderOrderByInput {
  DividerAsc = 'divider_ASC',
  DividerDesc = 'divider_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LinksAlignmentAsc = 'linksAlignment_ASC',
  LinksAlignmentDesc = 'linksAlignment_DESC',
}

export type CustomHeaderParent = SectionHeader;

export type CustomHeaderParentConnectInput = {
  SectionHeader?: InputMaybe<SectionHeaderConnectInput>;
};

export type CustomHeaderParentCreateInput = {
  SectionHeader?: InputMaybe<SectionHeaderCreateInput>;
};

export type CustomHeaderParentCreateManyInlineInput = {
  /** Create and connect multiple existing CustomHeaderParent documents */
  create?: InputMaybe<Array<CustomHeaderParentCreateInput>>;
};

export type CustomHeaderParentCreateOneInlineInput = {
  /** Create and connect one CustomHeaderParent document */
  create?: InputMaybe<CustomHeaderParentCreateInput>;
};

export type CustomHeaderParentCreateWithPositionInput = {
  SectionHeader?: InputMaybe<SectionHeaderCreateWithPositionInput>;
};

export type CustomHeaderParentUpdateInput = {
  SectionHeader?: InputMaybe<SectionHeaderUpdateInput>;
};

export type CustomHeaderParentUpdateManyInlineInput = {
  /** Create and connect multiple CustomHeaderParent component instances */
  create?: InputMaybe<Array<CustomHeaderParentCreateWithPositionInput>>;
  /** Delete multiple CustomHeaderParent documents */
  delete?: InputMaybe<Array<CustomHeaderParentWhereUniqueInput>>;
  /** Update multiple CustomHeaderParent component instances */
  update?: InputMaybe<
    Array<CustomHeaderParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CustomHeaderParent component instances */
  upsert?: InputMaybe<
    Array<CustomHeaderParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CustomHeaderParentUpdateManyWithNestedWhereInput = {
  SectionHeader?: InputMaybe<SectionHeaderUpdateManyWithNestedWhereInput>;
};

export type CustomHeaderParentUpdateOneInlineInput = {
  /** Create and connect one CustomHeaderParent document */
  create?: InputMaybe<CustomHeaderParentCreateInput>;
  /** Delete currently connected CustomHeaderParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CustomHeaderParent document */
  update?: InputMaybe<CustomHeaderParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CustomHeaderParent document */
  upsert?: InputMaybe<CustomHeaderParentUpsertWithNestedWhereUniqueInput>;
};

export type CustomHeaderParentUpdateWithNestedWhereUniqueAndPositionInput = {
  SectionHeader?: InputMaybe<SectionHeaderUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type CustomHeaderParentUpdateWithNestedWhereUniqueInput = {
  SectionHeader?: InputMaybe<SectionHeaderUpdateWithNestedWhereUniqueInput>;
};

export type CustomHeaderParentUpsertWithNestedWhereUniqueAndPositionInput = {
  SectionHeader?: InputMaybe<SectionHeaderUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type CustomHeaderParentUpsertWithNestedWhereUniqueInput = {
  SectionHeader?: InputMaybe<SectionHeaderUpsertWithNestedWhereUniqueInput>;
};

export type CustomHeaderParentWhereInput = {
  SectionHeader?: InputMaybe<SectionHeaderWhereInput>;
};

export type CustomHeaderParentWhereUniqueInput = {
  SectionHeader?: InputMaybe<SectionHeaderWhereUniqueInput>;
};

export type CustomHeaderUpdateInput = {
  divider?: InputMaybe<Scalars['Boolean']>;
  links?: InputMaybe<TextLinkGroupUpdateManyInlineInput>;
  linksAlignment?: InputMaybe<CTextAlign>;
  logo?: InputMaybe<CImageLinkUpdateOneInlineInput>;
};

export type CustomHeaderUpdateManyInlineInput = {
  /** Create and connect multiple CustomHeader component instances */
  create?: InputMaybe<Array<CustomHeaderCreateWithPositionInput>>;
  /** Delete multiple CustomHeader documents */
  delete?: InputMaybe<Array<CustomHeaderWhereUniqueInput>>;
  /** Update multiple CustomHeader component instances */
  update?: InputMaybe<
    Array<CustomHeaderUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CustomHeader component instances */
  upsert?: InputMaybe<
    Array<CustomHeaderUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CustomHeaderUpdateManyInput = {
  divider?: InputMaybe<Scalars['Boolean']>;
  linksAlignment?: InputMaybe<CTextAlign>;
};

export type CustomHeaderUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CustomHeaderUpdateManyInput;
  /** Document search */
  where: CustomHeaderWhereInput;
};

export type CustomHeaderUpdateOneInlineInput = {
  /** Create and connect one CustomHeader document */
  create?: InputMaybe<CustomHeaderCreateInput>;
  /** Delete currently connected CustomHeader document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single CustomHeader document */
  update?: InputMaybe<CustomHeaderUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CustomHeader document */
  upsert?: InputMaybe<CustomHeaderUpsertWithNestedWhereUniqueInput>;
};

export type CustomHeaderUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<CustomHeaderUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CustomHeaderWhereUniqueInput;
};

export type CustomHeaderUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CustomHeaderUpdateInput;
  /** Unique document search */
  where: CustomHeaderWhereUniqueInput;
};

export type CustomHeaderUpsertInput = {
  /** Create document if it didn't exist */
  create: CustomHeaderCreateInput;
  /** Update document if it exists */
  update: CustomHeaderUpdateInput;
};

export type CustomHeaderUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<CustomHeaderUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CustomHeaderWhereUniqueInput;
};

export type CustomHeaderUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CustomHeaderUpsertInput;
  /** Unique document search */
  where: CustomHeaderWhereUniqueInput;
};

/** Identifies documents */
export type CustomHeaderWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CustomHeaderWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CustomHeaderWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CustomHeaderWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  divider?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  divider_not?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  linksAlignment?: InputMaybe<CTextAlign>;
  /** All values that are contained in given list. */
  linksAlignment_in?: InputMaybe<Array<InputMaybe<CTextAlign>>>;
  /** Any other value that exists and is not equal to the given value. */
  linksAlignment_not?: InputMaybe<CTextAlign>;
  /** All values that are not contained in given list. */
  linksAlignment_not_in?: InputMaybe<Array<InputMaybe<CTextAlign>>>;
  links_every?: InputMaybe<TextLinkGroupWhereInput>;
  links_none?: InputMaybe<TextLinkGroupWhereInput>;
  links_some?: InputMaybe<TextLinkGroupWhereInput>;
  logo?: InputMaybe<CImageLinkWhereInput>;
};

/** References CustomHeader record uniquely */
export type CustomHeaderWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Datalab = Entity & {
  __typename?: 'Datalab';
  /** on click link for `{number} candidates surveyed` */
  aboutResearchLink?: Maybe<Link>;
  /** The unique identifier */
  id: Scalars['ID'];
  name: Scalars['String'];
  /** System stage field */
  stage: Stage;
  tipsCardGroup?: Maybe<TipsCardGroup>;
};

export type DatalabAboutResearchLinkArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type DatalabTipsCardGroupArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type DatalabConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: DatalabWhereUniqueInput;
};

/** A connection to a list of items. */
export type DatalabConnection = {
  __typename?: 'DatalabConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<DatalabEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type DatalabCreateInput = {
  aboutResearchLink?: InputMaybe<LinkCreateOneInlineInput>;
  name: Scalars['String'];
  tipsCardGroup?: InputMaybe<TipsCardGroupCreateOneInlineInput>;
};

export type DatalabCreateManyInlineInput = {
  /** Create and connect multiple existing Datalab documents */
  create?: InputMaybe<Array<DatalabCreateInput>>;
};

export type DatalabCreateOneInlineInput = {
  /** Create and connect one Datalab document */
  create?: InputMaybe<DatalabCreateInput>;
};

export type DatalabCreateWithPositionInput = {
  /** Document to create */
  data: DatalabCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type DatalabEdge = {
  __typename?: 'DatalabEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Datalab;
};

/** Identifies documents */
export type DatalabManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<DatalabWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<DatalabWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<DatalabWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  aboutResearchLink?: InputMaybe<LinkWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']>;
  tipsCardGroup?: InputMaybe<TipsCardGroupWhereInput>;
};

export enum DatalabOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
}

export type DatalabParent = CBlock;

export type DatalabParentConnectInput = {
  CBlock?: InputMaybe<CBlockConnectInput>;
};

export type DatalabParentCreateInput = {
  CBlock?: InputMaybe<CBlockCreateInput>;
};

export type DatalabParentCreateManyInlineInput = {
  /** Connect multiple existing DatalabParent documents */
  connect?: InputMaybe<Array<DatalabParentWhereUniqueInput>>;
  /** Create and connect multiple existing DatalabParent documents */
  create?: InputMaybe<Array<DatalabParentCreateInput>>;
};

export type DatalabParentCreateOneInlineInput = {
  /** Connect one existing DatalabParent document */
  connect?: InputMaybe<DatalabParentWhereUniqueInput>;
  /** Create and connect one DatalabParent document */
  create?: InputMaybe<DatalabParentCreateInput>;
};

export type DatalabParentUpdateInput = {
  CBlock?: InputMaybe<CBlockUpdateInput>;
};

export type DatalabParentUpdateManyInlineInput = {
  /** Connect multiple existing DatalabParent documents */
  connect?: InputMaybe<Array<DatalabParentConnectInput>>;
  /** Create and connect multiple DatalabParent documents */
  create?: InputMaybe<Array<DatalabParentCreateInput>>;
  /** Delete multiple DatalabParent documents */
  delete?: InputMaybe<Array<DatalabParentWhereUniqueInput>>;
  /** Disconnect multiple DatalabParent documents */
  disconnect?: InputMaybe<Array<DatalabParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing DatalabParent documents */
  set?: InputMaybe<Array<DatalabParentWhereUniqueInput>>;
  /** Update multiple DatalabParent documents */
  update?: InputMaybe<Array<DatalabParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple DatalabParent documents */
  upsert?: InputMaybe<Array<DatalabParentUpsertWithNestedWhereUniqueInput>>;
};

export type DatalabParentUpdateManyWithNestedWhereInput = {
  CBlock?: InputMaybe<CBlockUpdateManyWithNestedWhereInput>;
};

export type DatalabParentUpdateOneInlineInput = {
  /** Connect existing DatalabParent document */
  connect?: InputMaybe<DatalabParentWhereUniqueInput>;
  /** Create and connect one DatalabParent document */
  create?: InputMaybe<DatalabParentCreateInput>;
  /** Delete currently connected DatalabParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected DatalabParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single DatalabParent document */
  update?: InputMaybe<DatalabParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single DatalabParent document */
  upsert?: InputMaybe<DatalabParentUpsertWithNestedWhereUniqueInput>;
};

export type DatalabParentUpdateWithNestedWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockUpdateWithNestedWhereUniqueInput>;
};

export type DatalabParentUpsertWithNestedWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockUpsertWithNestedWhereUniqueInput>;
};

export type DatalabParentWhereInput = {
  CBlock?: InputMaybe<CBlockWhereInput>;
};

export type DatalabParentWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockWhereUniqueInput>;
};

export type DatalabUpdateInput = {
  aboutResearchLink?: InputMaybe<LinkUpdateOneInlineInput>;
  name?: InputMaybe<Scalars['String']>;
  tipsCardGroup?: InputMaybe<TipsCardGroupUpdateOneInlineInput>;
};

export type DatalabUpdateManyInlineInput = {
  /** Create and connect multiple Datalab component instances */
  create?: InputMaybe<Array<DatalabCreateWithPositionInput>>;
  /** Delete multiple Datalab documents */
  delete?: InputMaybe<Array<DatalabWhereUniqueInput>>;
  /** Update multiple Datalab component instances */
  update?: InputMaybe<
    Array<DatalabUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple Datalab component instances */
  upsert?: InputMaybe<
    Array<DatalabUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type DatalabUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']>;
};

export type DatalabUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: DatalabUpdateManyInput;
  /** Document search */
  where: DatalabWhereInput;
};

export type DatalabUpdateOneInlineInput = {
  /** Create and connect one Datalab document */
  create?: InputMaybe<DatalabCreateInput>;
  /** Delete currently connected Datalab document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single Datalab document */
  update?: InputMaybe<DatalabUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Datalab document */
  upsert?: InputMaybe<DatalabUpsertWithNestedWhereUniqueInput>;
};

export type DatalabUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<DatalabUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: DatalabWhereUniqueInput;
};

export type DatalabUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: DatalabUpdateInput;
  /** Unique document search */
  where: DatalabWhereUniqueInput;
};

export type DatalabUpsertInput = {
  /** Create document if it didn't exist */
  create: DatalabCreateInput;
  /** Update document if it exists */
  update: DatalabUpdateInput;
};

export type DatalabUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<DatalabUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: DatalabWhereUniqueInput;
};

export type DatalabUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: DatalabUpsertInput;
  /** Unique document search */
  where: DatalabWhereUniqueInput;
};

/** Identifies documents */
export type DatalabWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<DatalabWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<DatalabWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<DatalabWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  aboutResearchLink?: InputMaybe<LinkWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']>;
  tipsCardGroup?: InputMaybe<TipsCardGroupWhereInput>;
};

/** References Datalab record uniquely */
export type DatalabWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Button for launching content in a modal/Dialogue window */
export type DialogButton = Entity &
  Node & {
    __typename?: 'DialogButton';
    /** The time the document was created */
    createdAt: Scalars['DateTime'];
    /** User that created this document */
    createdBy?: Maybe<User>;
    dialogItem?: Maybe<DialogItem>;
    /** Get the document in other stages */
    documentInStages: Array<DialogButton>;
    /** List of DialogButton versions */
    history: Array<Version>;
    icon?: Maybe<Icon>;
    iconPosition?: Maybe<IconPosition>;
    /** The unique identifier */
    id: Scalars['ID'];
    /** System Locale field */
    locale: Locale;
    /** Get the other localizations for this document */
    localizations: Array<DialogButton>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    size?: Maybe<CButtonLinkSize>;
    /** System stage field */
    stage: Stage;
    /** Text to display on the button */
    text: Scalars['String'];
    tone?: Maybe<CButtonLinkTone>;
    /** The time the document was updated */
    updatedAt: Scalars['DateTime'];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
    variant?: Maybe<CButtonVariant>;
  };

/** Button for launching content in a modal/Dialogue window */
export type DialogButtonCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

/** Button for launching content in a modal/Dialogue window */
export type DialogButtonCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Button for launching content in a modal/Dialogue window */
export type DialogButtonDialogItemArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Button for launching content in a modal/Dialogue window */
export type DialogButtonDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

/** Button for launching content in a modal/Dialogue window */
export type DialogButtonHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};

/** Button for launching content in a modal/Dialogue window */
export type DialogButtonLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

/** Button for launching content in a modal/Dialogue window */
export type DialogButtonPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

/** Button for launching content in a modal/Dialogue window */
export type DialogButtonPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Button for launching content in a modal/Dialogue window */
export type DialogButtonScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

/** Button for launching content in a modal/Dialogue window */
export type DialogButtonUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

/** Button for launching content in a modal/Dialogue window */
export type DialogButtonUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type DialogButtonConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: DialogButtonWhereUniqueInput;
};

/** A connection to a list of items. */
export type DialogButtonConnection = {
  __typename?: 'DialogButtonConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<DialogButtonEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type DialogButtonCreateInput = {
  cm1qaxyle01vo06t6c7g1fgmb?: InputMaybe<CImageBlockCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dialogItem?: InputMaybe<DialogItemCreateOneInlineInput>;
  icon?: InputMaybe<Icon>;
  iconPosition?: InputMaybe<IconPosition>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<DialogButtonCreateLocalizationsInput>;
  size?: InputMaybe<CButtonLinkSize>;
  /** text input for default locale (default) */
  text: Scalars['String'];
  tone?: InputMaybe<CButtonLinkTone>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  variant?: InputMaybe<CButtonVariant>;
};

export type DialogButtonCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  text: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DialogButtonCreateLocalizationInput = {
  /** Localization input */
  data: DialogButtonCreateLocalizationDataInput;
  locale: Locale;
};

export type DialogButtonCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<DialogButtonCreateLocalizationInput>>;
};

export type DialogButtonCreateManyInlineInput = {
  /** Connect multiple existing DialogButton documents */
  connect?: InputMaybe<Array<DialogButtonWhereUniqueInput>>;
  /** Create and connect multiple existing DialogButton documents */
  create?: InputMaybe<Array<DialogButtonCreateInput>>;
};

export type DialogButtonCreateOneInlineInput = {
  /** Connect one existing DialogButton document */
  connect?: InputMaybe<DialogButtonWhereUniqueInput>;
  /** Create and connect one DialogButton document */
  create?: InputMaybe<DialogButtonCreateInput>;
};

/** An edge in a connection. */
export type DialogButtonEdge = {
  __typename?: 'DialogButtonEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: DialogButton;
};

/** Identifies documents */
export type DialogButtonManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<DialogButtonWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<DialogButtonWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<DialogButtonWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  dialogItem?: InputMaybe<DialogItemWhereInput>;
  documentInStages_every?: InputMaybe<DialogButtonWhereStageInput>;
  documentInStages_none?: InputMaybe<DialogButtonWhereStageInput>;
  documentInStages_some?: InputMaybe<DialogButtonWhereStageInput>;
  icon?: InputMaybe<Icon>;
  iconPosition?: InputMaybe<IconPosition>;
  /** All values that are contained in given list. */
  iconPosition_in?: InputMaybe<Array<InputMaybe<IconPosition>>>;
  /** Any other value that exists and is not equal to the given value. */
  iconPosition_not?: InputMaybe<IconPosition>;
  /** All values that are not contained in given list. */
  iconPosition_not_in?: InputMaybe<Array<InputMaybe<IconPosition>>>;
  /** All values that are contained in given list. */
  icon_in?: InputMaybe<Array<InputMaybe<Icon>>>;
  /** Any other value that exists and is not equal to the given value. */
  icon_not?: InputMaybe<Icon>;
  /** All values that are not contained in given list. */
  icon_not_in?: InputMaybe<Array<InputMaybe<Icon>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  size?: InputMaybe<CButtonLinkSize>;
  /** All values that are contained in given list. */
  size_in?: InputMaybe<Array<InputMaybe<CButtonLinkSize>>>;
  /** Any other value that exists and is not equal to the given value. */
  size_not?: InputMaybe<CButtonLinkSize>;
  /** All values that are not contained in given list. */
  size_not_in?: InputMaybe<Array<InputMaybe<CButtonLinkSize>>>;
  tone?: InputMaybe<CButtonLinkTone>;
  /** All values that are contained in given list. */
  tone_in?: InputMaybe<Array<InputMaybe<CButtonLinkTone>>>;
  /** Any other value that exists and is not equal to the given value. */
  tone_not?: InputMaybe<CButtonLinkTone>;
  /** All values that are not contained in given list. */
  tone_not_in?: InputMaybe<Array<InputMaybe<CButtonLinkTone>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  variant?: InputMaybe<CButtonVariant>;
  /** All values that are contained in given list. */
  variant_in?: InputMaybe<Array<InputMaybe<CButtonVariant>>>;
  /** Any other value that exists and is not equal to the given value. */
  variant_not?: InputMaybe<CButtonVariant>;
  /** All values that are not contained in given list. */
  variant_not_in?: InputMaybe<Array<InputMaybe<CButtonVariant>>>;
};

export enum DialogButtonOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IconPositionAsc = 'iconPosition_ASC',
  IconPositionDesc = 'iconPosition_DESC',
  IconAsc = 'icon_ASC',
  IconDesc = 'icon_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  ToneAsc = 'tone_ASC',
  ToneDesc = 'tone_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  VariantAsc = 'variant_ASC',
  VariantDesc = 'variant_DESC',
}

export type DialogButtonUpdateInput = {
  cm1qaxyle01vo06t6c7g1fgmb?: InputMaybe<CImageBlockUpdateManyInlineInput>;
  dialogItem?: InputMaybe<DialogItemUpdateOneInlineInput>;
  icon?: InputMaybe<Icon>;
  iconPosition?: InputMaybe<IconPosition>;
  /** Manage document localizations */
  localizations?: InputMaybe<DialogButtonUpdateLocalizationsInput>;
  size?: InputMaybe<CButtonLinkSize>;
  /** text input for default locale (default) */
  text?: InputMaybe<Scalars['String']>;
  tone?: InputMaybe<CButtonLinkTone>;
  variant?: InputMaybe<CButtonVariant>;
};

export type DialogButtonUpdateLocalizationDataInput = {
  text?: InputMaybe<Scalars['String']>;
};

export type DialogButtonUpdateLocalizationInput = {
  data: DialogButtonUpdateLocalizationDataInput;
  locale: Locale;
};

export type DialogButtonUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<DialogButtonCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<DialogButtonUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<DialogButtonUpsertLocalizationInput>>;
};

export type DialogButtonUpdateManyInlineInput = {
  /** Connect multiple existing DialogButton documents */
  connect?: InputMaybe<Array<DialogButtonConnectInput>>;
  /** Create and connect multiple DialogButton documents */
  create?: InputMaybe<Array<DialogButtonCreateInput>>;
  /** Delete multiple DialogButton documents */
  delete?: InputMaybe<Array<DialogButtonWhereUniqueInput>>;
  /** Disconnect multiple DialogButton documents */
  disconnect?: InputMaybe<Array<DialogButtonWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing DialogButton documents */
  set?: InputMaybe<Array<DialogButtonWhereUniqueInput>>;
  /** Update multiple DialogButton documents */
  update?: InputMaybe<Array<DialogButtonUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple DialogButton documents */
  upsert?: InputMaybe<Array<DialogButtonUpsertWithNestedWhereUniqueInput>>;
};

export type DialogButtonUpdateManyInput = {
  icon?: InputMaybe<Icon>;
  iconPosition?: InputMaybe<IconPosition>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<DialogButtonUpdateManyLocalizationsInput>;
  size?: InputMaybe<CButtonLinkSize>;
  /** text input for default locale (default) */
  text?: InputMaybe<Scalars['String']>;
  tone?: InputMaybe<CButtonLinkTone>;
  variant?: InputMaybe<CButtonVariant>;
};

export type DialogButtonUpdateManyLocalizationDataInput = {
  text?: InputMaybe<Scalars['String']>;
};

export type DialogButtonUpdateManyLocalizationInput = {
  data: DialogButtonUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type DialogButtonUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<DialogButtonUpdateManyLocalizationInput>>;
};

export type DialogButtonUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: DialogButtonUpdateManyInput;
  /** Document search */
  where: DialogButtonWhereInput;
};

export type DialogButtonUpdateOneInlineInput = {
  /** Connect existing DialogButton document */
  connect?: InputMaybe<DialogButtonWhereUniqueInput>;
  /** Create and connect one DialogButton document */
  create?: InputMaybe<DialogButtonCreateInput>;
  /** Delete currently connected DialogButton document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected DialogButton document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single DialogButton document */
  update?: InputMaybe<DialogButtonUpdateWithNestedWhereUniqueInput>;
  /** Upsert single DialogButton document */
  upsert?: InputMaybe<DialogButtonUpsertWithNestedWhereUniqueInput>;
};

export type DialogButtonUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: DialogButtonUpdateInput;
  /** Unique document search */
  where: DialogButtonWhereUniqueInput;
};

export type DialogButtonUpsertInput = {
  /** Create document if it didn't exist */
  create: DialogButtonCreateInput;
  /** Update document if it exists */
  update: DialogButtonUpdateInput;
};

export type DialogButtonUpsertLocalizationInput = {
  create: DialogButtonCreateLocalizationDataInput;
  locale: Locale;
  update: DialogButtonUpdateLocalizationDataInput;
};

export type DialogButtonUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: DialogButtonUpsertInput;
  /** Unique document search */
  where: DialogButtonWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type DialogButtonWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type DialogButtonWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<DialogButtonWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<DialogButtonWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<DialogButtonWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  dialogItem?: InputMaybe<DialogItemWhereInput>;
  documentInStages_every?: InputMaybe<DialogButtonWhereStageInput>;
  documentInStages_none?: InputMaybe<DialogButtonWhereStageInput>;
  documentInStages_some?: InputMaybe<DialogButtonWhereStageInput>;
  icon?: InputMaybe<Icon>;
  iconPosition?: InputMaybe<IconPosition>;
  /** All values that are contained in given list. */
  iconPosition_in?: InputMaybe<Array<InputMaybe<IconPosition>>>;
  /** Any other value that exists and is not equal to the given value. */
  iconPosition_not?: InputMaybe<IconPosition>;
  /** All values that are not contained in given list. */
  iconPosition_not_in?: InputMaybe<Array<InputMaybe<IconPosition>>>;
  /** All values that are contained in given list. */
  icon_in?: InputMaybe<Array<InputMaybe<Icon>>>;
  /** Any other value that exists and is not equal to the given value. */
  icon_not?: InputMaybe<Icon>;
  /** All values that are not contained in given list. */
  icon_not_in?: InputMaybe<Array<InputMaybe<Icon>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  size?: InputMaybe<CButtonLinkSize>;
  /** All values that are contained in given list. */
  size_in?: InputMaybe<Array<InputMaybe<CButtonLinkSize>>>;
  /** Any other value that exists and is not equal to the given value. */
  size_not?: InputMaybe<CButtonLinkSize>;
  /** All values that are not contained in given list. */
  size_not_in?: InputMaybe<Array<InputMaybe<CButtonLinkSize>>>;
  text?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  text_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  text_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  text_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  text_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  text_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  text_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  text_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  text_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  text_starts_with?: InputMaybe<Scalars['String']>;
  tone?: InputMaybe<CButtonLinkTone>;
  /** All values that are contained in given list. */
  tone_in?: InputMaybe<Array<InputMaybe<CButtonLinkTone>>>;
  /** Any other value that exists and is not equal to the given value. */
  tone_not?: InputMaybe<CButtonLinkTone>;
  /** All values that are not contained in given list. */
  tone_not_in?: InputMaybe<Array<InputMaybe<CButtonLinkTone>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  variant?: InputMaybe<CButtonVariant>;
  /** All values that are contained in given list. */
  variant_in?: InputMaybe<Array<InputMaybe<CButtonVariant>>>;
  /** Any other value that exists and is not equal to the given value. */
  variant_not?: InputMaybe<CButtonVariant>;
  /** All values that are not contained in given list. */
  variant_not_in?: InputMaybe<Array<InputMaybe<CButtonVariant>>>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type DialogButtonWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<DialogButtonWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<DialogButtonWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<DialogButtonWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<DialogButtonWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References DialogButton record uniquely */
export type DialogButtonWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type DialogItem = Entity & {
  __typename?: 'DialogItem';
  content: Array<DialogItemcontentUnion>;
  description?: Maybe<Scalars['String']>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<DialogItem>;
  /** System stage field */
  stage: Stage;
  title: Scalars['String'];
};

export type DialogItemContentArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type DialogItemLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type DialogItemConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: DialogItemWhereUniqueInput;
};

/** A connection to a list of items. */
export type DialogItemConnection = {
  __typename?: 'DialogItemConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<DialogItemEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type DialogItemCreateInput = {
  content?: InputMaybe<DialogItemcontentUnionCreateManyInlineInput>;
  /** description input for default locale (default) */
  description?: InputMaybe<Scalars['String']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<DialogItemCreateLocalizationsInput>;
  /** title input for default locale (default) */
  title: Scalars['String'];
};

export type DialogItemCreateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type DialogItemCreateLocalizationInput = {
  /** Localization input */
  data: DialogItemCreateLocalizationDataInput;
  locale: Locale;
};

export type DialogItemCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<DialogItemCreateLocalizationInput>>;
};

export type DialogItemCreateManyInlineInput = {
  /** Create and connect multiple existing DialogItem documents */
  create?: InputMaybe<Array<DialogItemCreateInput>>;
};

export type DialogItemCreateOneInlineInput = {
  /** Create and connect one DialogItem document */
  create?: InputMaybe<DialogItemCreateInput>;
};

export type DialogItemCreateWithPositionInput = {
  /** Document to create */
  data: DialogItemCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type DialogItemEdge = {
  __typename?: 'DialogItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: DialogItem;
};

/** Identifies documents */
export type DialogItemManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<DialogItemWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<DialogItemWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<DialogItemWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  /** All values in which the union is empty. */
  content_empty?: InputMaybe<Scalars['Boolean']>;
  /** Matches if the modular component contains at least one connection to the item provided to the filter */
  content_some?: InputMaybe<DialogItemcontentUnionWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
};

export enum DialogItemOrderByInput {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type DialogItemParent = DialogButton;

export type DialogItemParentConnectInput = {
  DialogButton?: InputMaybe<DialogButtonConnectInput>;
};

export type DialogItemParentCreateInput = {
  DialogButton?: InputMaybe<DialogButtonCreateInput>;
};

export type DialogItemParentCreateManyInlineInput = {
  /** Connect multiple existing DialogItemParent documents */
  connect?: InputMaybe<Array<DialogItemParentWhereUniqueInput>>;
  /** Create and connect multiple existing DialogItemParent documents */
  create?: InputMaybe<Array<DialogItemParentCreateInput>>;
};

export type DialogItemParentCreateOneInlineInput = {
  /** Connect one existing DialogItemParent document */
  connect?: InputMaybe<DialogItemParentWhereUniqueInput>;
  /** Create and connect one DialogItemParent document */
  create?: InputMaybe<DialogItemParentCreateInput>;
};

export type DialogItemParentUpdateInput = {
  DialogButton?: InputMaybe<DialogButtonUpdateInput>;
};

export type DialogItemParentUpdateManyInlineInput = {
  /** Connect multiple existing DialogItemParent documents */
  connect?: InputMaybe<Array<DialogItemParentConnectInput>>;
  /** Create and connect multiple DialogItemParent documents */
  create?: InputMaybe<Array<DialogItemParentCreateInput>>;
  /** Delete multiple DialogItemParent documents */
  delete?: InputMaybe<Array<DialogItemParentWhereUniqueInput>>;
  /** Disconnect multiple DialogItemParent documents */
  disconnect?: InputMaybe<Array<DialogItemParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing DialogItemParent documents */
  set?: InputMaybe<Array<DialogItemParentWhereUniqueInput>>;
  /** Update multiple DialogItemParent documents */
  update?: InputMaybe<Array<DialogItemParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple DialogItemParent documents */
  upsert?: InputMaybe<Array<DialogItemParentUpsertWithNestedWhereUniqueInput>>;
};

export type DialogItemParentUpdateManyWithNestedWhereInput = {
  DialogButton?: InputMaybe<DialogButtonUpdateManyWithNestedWhereInput>;
};

export type DialogItemParentUpdateOneInlineInput = {
  /** Connect existing DialogItemParent document */
  connect?: InputMaybe<DialogItemParentWhereUniqueInput>;
  /** Create and connect one DialogItemParent document */
  create?: InputMaybe<DialogItemParentCreateInput>;
  /** Delete currently connected DialogItemParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected DialogItemParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single DialogItemParent document */
  update?: InputMaybe<DialogItemParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single DialogItemParent document */
  upsert?: InputMaybe<DialogItemParentUpsertWithNestedWhereUniqueInput>;
};

export type DialogItemParentUpdateWithNestedWhereUniqueInput = {
  DialogButton?: InputMaybe<DialogButtonUpdateWithNestedWhereUniqueInput>;
};

export type DialogItemParentUpsertWithNestedWhereUniqueInput = {
  DialogButton?: InputMaybe<DialogButtonUpsertWithNestedWhereUniqueInput>;
};

export type DialogItemParentWhereInput = {
  DialogButton?: InputMaybe<DialogButtonWhereInput>;
};

export type DialogItemParentWhereUniqueInput = {
  DialogButton?: InputMaybe<DialogButtonWhereUniqueInput>;
};

export type DialogItemUpdateInput = {
  content?: InputMaybe<DialogItemcontentUnionUpdateManyInlineInput>;
  /** description input for default locale (default) */
  description?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<DialogItemUpdateLocalizationsInput>;
  /** title input for default locale (default) */
  title?: InputMaybe<Scalars['String']>;
};

export type DialogItemUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type DialogItemUpdateLocalizationInput = {
  data: DialogItemUpdateLocalizationDataInput;
  locale: Locale;
};

export type DialogItemUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<DialogItemCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<DialogItemUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<DialogItemUpsertLocalizationInput>>;
};

export type DialogItemUpdateManyInlineInput = {
  /** Create and connect multiple DialogItem component instances */
  create?: InputMaybe<Array<DialogItemCreateWithPositionInput>>;
  /** Delete multiple DialogItem documents */
  delete?: InputMaybe<Array<DialogItemWhereUniqueInput>>;
  /** Update multiple DialogItem component instances */
  update?: InputMaybe<
    Array<DialogItemUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple DialogItem component instances */
  upsert?: InputMaybe<
    Array<DialogItemUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type DialogItemUpdateManyInput = {
  /** description input for default locale (default) */
  description?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<DialogItemUpdateManyLocalizationsInput>;
  /** title input for default locale (default) */
  title?: InputMaybe<Scalars['String']>;
};

export type DialogItemUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type DialogItemUpdateManyLocalizationInput = {
  data: DialogItemUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type DialogItemUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<DialogItemUpdateManyLocalizationInput>>;
};

export type DialogItemUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: DialogItemUpdateManyInput;
  /** Document search */
  where: DialogItemWhereInput;
};

export type DialogItemUpdateOneInlineInput = {
  /** Create and connect one DialogItem document */
  create?: InputMaybe<DialogItemCreateInput>;
  /** Delete currently connected DialogItem document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single DialogItem document */
  update?: InputMaybe<DialogItemUpdateWithNestedWhereUniqueInput>;
  /** Upsert single DialogItem document */
  upsert?: InputMaybe<DialogItemUpsertWithNestedWhereUniqueInput>;
};

export type DialogItemUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<DialogItemUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: DialogItemWhereUniqueInput;
};

export type DialogItemUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: DialogItemUpdateInput;
  /** Unique document search */
  where: DialogItemWhereUniqueInput;
};

export type DialogItemUpsertInput = {
  /** Create document if it didn't exist */
  create: DialogItemCreateInput;
  /** Update document if it exists */
  update: DialogItemUpdateInput;
};

export type DialogItemUpsertLocalizationInput = {
  create: DialogItemCreateLocalizationDataInput;
  locale: Locale;
  update: DialogItemUpdateLocalizationDataInput;
};

export type DialogItemUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<DialogItemUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: DialogItemWhereUniqueInput;
};

export type DialogItemUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: DialogItemUpsertInput;
  /** Unique document search */
  where: DialogItemWhereUniqueInput;
};

/** Identifies documents */
export type DialogItemWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<DialogItemWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<DialogItemWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<DialogItemWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  /** All values in which the union is empty. */
  content_empty?: InputMaybe<Scalars['Boolean']>;
  /** Matches if the modular component contains at least one connection to the item provided to the filter */
  content_some?: InputMaybe<DialogItemcontentUnionWhereInput>;
  description?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']>;
};

/** References DialogItem record uniquely */
export type DialogItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type DialogItemcontentUnion = CHeading | CParagraph | Video;

export type DialogItemcontentUnionConnectInput = {
  CHeading?: InputMaybe<CHeadingConnectInput>;
  CParagraph?: InputMaybe<CParagraphConnectInput>;
  Video?: InputMaybe<VideoConnectInput>;
};

export type DialogItemcontentUnionCreateInput = {
  CHeading?: InputMaybe<CHeadingCreateInput>;
  CParagraph?: InputMaybe<CParagraphCreateInput>;
  Video?: InputMaybe<VideoCreateInput>;
};

export type DialogItemcontentUnionCreateManyInlineInput = {
  /** Create and connect multiple existing DialogItemcontentUnion documents */
  create?: InputMaybe<Array<DialogItemcontentUnionCreateInput>>;
};

export type DialogItemcontentUnionCreateOneInlineInput = {
  /** Create and connect one DialogItemcontentUnion document */
  create?: InputMaybe<DialogItemcontentUnionCreateInput>;
};

export type DialogItemcontentUnionCreateWithPositionInput = {
  CHeading?: InputMaybe<CHeadingCreateWithPositionInput>;
  CParagraph?: InputMaybe<CParagraphCreateWithPositionInput>;
  Video?: InputMaybe<VideoCreateWithPositionInput>;
};

export type DialogItemcontentUnionUpdateInput = {
  CHeading?: InputMaybe<CHeadingUpdateInput>;
  CParagraph?: InputMaybe<CParagraphUpdateInput>;
  Video?: InputMaybe<VideoUpdateInput>;
};

export type DialogItemcontentUnionUpdateManyInlineInput = {
  /** Create and connect multiple DialogItemcontentUnion component instances */
  create?: InputMaybe<Array<DialogItemcontentUnionCreateWithPositionInput>>;
  /** Delete multiple DialogItemcontentUnion documents */
  delete?: InputMaybe<Array<DialogItemcontentUnionWhereUniqueInput>>;
  /** Update multiple DialogItemcontentUnion component instances */
  update?: InputMaybe<
    Array<DialogItemcontentUnionUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple DialogItemcontentUnion component instances */
  upsert?: InputMaybe<
    Array<DialogItemcontentUnionUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type DialogItemcontentUnionUpdateManyWithNestedWhereInput = {
  CHeading?: InputMaybe<CHeadingUpdateManyWithNestedWhereInput>;
  CParagraph?: InputMaybe<CParagraphUpdateManyWithNestedWhereInput>;
  Video?: InputMaybe<VideoUpdateManyWithNestedWhereInput>;
};

export type DialogItemcontentUnionUpdateOneInlineInput = {
  /** Create and connect one DialogItemcontentUnion document */
  create?: InputMaybe<DialogItemcontentUnionCreateInput>;
  /** Delete currently connected DialogItemcontentUnion document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single DialogItemcontentUnion document */
  update?: InputMaybe<DialogItemcontentUnionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single DialogItemcontentUnion document */
  upsert?: InputMaybe<DialogItemcontentUnionUpsertWithNestedWhereUniqueInput>;
};

export type DialogItemcontentUnionUpdateWithNestedWhereUniqueAndPositionInput =
  {
    CHeading?: InputMaybe<CHeadingUpdateWithNestedWhereUniqueAndPositionInput>;
    CParagraph?: InputMaybe<CParagraphUpdateWithNestedWhereUniqueAndPositionInput>;
    Video?: InputMaybe<VideoUpdateWithNestedWhereUniqueAndPositionInput>;
  };

export type DialogItemcontentUnionUpdateWithNestedWhereUniqueInput = {
  CHeading?: InputMaybe<CHeadingUpdateWithNestedWhereUniqueInput>;
  CParagraph?: InputMaybe<CParagraphUpdateWithNestedWhereUniqueInput>;
  Video?: InputMaybe<VideoUpdateWithNestedWhereUniqueInput>;
};

export type DialogItemcontentUnionUpsertWithNestedWhereUniqueAndPositionInput =
  {
    CHeading?: InputMaybe<CHeadingUpsertWithNestedWhereUniqueAndPositionInput>;
    CParagraph?: InputMaybe<CParagraphUpsertWithNestedWhereUniqueAndPositionInput>;
    Video?: InputMaybe<VideoUpsertWithNestedWhereUniqueAndPositionInput>;
  };

export type DialogItemcontentUnionUpsertWithNestedWhereUniqueInput = {
  CHeading?: InputMaybe<CHeadingUpsertWithNestedWhereUniqueInput>;
  CParagraph?: InputMaybe<CParagraphUpsertWithNestedWhereUniqueInput>;
  Video?: InputMaybe<VideoUpsertWithNestedWhereUniqueInput>;
};

export type DialogItemcontentUnionWhereInput = {
  CHeading?: InputMaybe<CHeadingWhereInput>;
  CParagraph?: InputMaybe<CParagraphWhereInput>;
  Video?: InputMaybe<VideoWhereInput>;
};

export type DialogItemcontentUnionWhereUniqueInput = {
  CHeading?: InputMaybe<CHeadingWhereUniqueInput>;
  CParagraph?: InputMaybe<CParagraphWhereUniqueInput>;
  Video?: InputMaybe<VideoWhereUniqueInput>;
};

/** Display a video thumbnail that opens in a Dialog when clicked */
export type DialogVideo = Entity & {
  __typename?: 'DialogVideo';
  description?: Maybe<Scalars['String']>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<DialogVideo>;
  /** System stage field */
  stage: Stage;
  title?: Maybe<Scalars['String']>;
  video?: Maybe<DialogVideovideoUnion>;
};

/** Display a video thumbnail that opens in a Dialog when clicked */
export type DialogVideoLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

/** Display a video thumbnail that opens in a Dialog when clicked */
export type DialogVideoVideoArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type DialogVideoConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: DialogVideoWhereUniqueInput;
};

/** A connection to a list of items. */
export type DialogVideoConnection = {
  __typename?: 'DialogVideoConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<DialogVideoEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type DialogVideoCreateInput = {
  /** description input for default locale (default) */
  description?: InputMaybe<Scalars['String']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<DialogVideoCreateLocalizationsInput>;
  /** title input for default locale (default) */
  title?: InputMaybe<Scalars['String']>;
  video?: InputMaybe<DialogVideovideoUnionCreateOneInlineInput>;
};

export type DialogVideoCreateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type DialogVideoCreateLocalizationInput = {
  /** Localization input */
  data: DialogVideoCreateLocalizationDataInput;
  locale: Locale;
};

export type DialogVideoCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<DialogVideoCreateLocalizationInput>>;
};

export type DialogVideoCreateManyInlineInput = {
  /** Create and connect multiple existing DialogVideo documents */
  create?: InputMaybe<Array<DialogVideoCreateInput>>;
};

export type DialogVideoCreateOneInlineInput = {
  /** Create and connect one DialogVideo document */
  create?: InputMaybe<DialogVideoCreateInput>;
};

export type DialogVideoCreateWithPositionInput = {
  /** Document to create */
  data: DialogVideoCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type DialogVideoEdge = {
  __typename?: 'DialogVideoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: DialogVideo;
};

/** Identifies documents */
export type DialogVideoManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<DialogVideoWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<DialogVideoWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<DialogVideoWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values in which the modular component is connected to the given models */
  video?: InputMaybe<DialogVideovideoUnionWhereInput>;
  /** All values in which the union is empty. */
  video_empty?: InputMaybe<Scalars['Boolean']>;
};

export enum DialogVideoOrderByInput {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type DialogVideoParent = VideoDialogGroup;

export type DialogVideoParentConnectInput = {
  VideoDialogGroup?: InputMaybe<VideoDialogGroupConnectInput>;
};

export type DialogVideoParentCreateInput = {
  VideoDialogGroup?: InputMaybe<VideoDialogGroupCreateInput>;
};

export type DialogVideoParentCreateManyInlineInput = {
  /** Create and connect multiple existing DialogVideoParent documents */
  create?: InputMaybe<Array<DialogVideoParentCreateInput>>;
};

export type DialogVideoParentCreateOneInlineInput = {
  /** Create and connect one DialogVideoParent document */
  create?: InputMaybe<DialogVideoParentCreateInput>;
};

export type DialogVideoParentCreateWithPositionInput = {
  VideoDialogGroup?: InputMaybe<VideoDialogGroupCreateWithPositionInput>;
};

export type DialogVideoParentUpdateInput = {
  VideoDialogGroup?: InputMaybe<VideoDialogGroupUpdateInput>;
};

export type DialogVideoParentUpdateManyInlineInput = {
  /** Create and connect multiple DialogVideoParent component instances */
  create?: InputMaybe<Array<DialogVideoParentCreateWithPositionInput>>;
  /** Delete multiple DialogVideoParent documents */
  delete?: InputMaybe<Array<DialogVideoParentWhereUniqueInput>>;
  /** Update multiple DialogVideoParent component instances */
  update?: InputMaybe<
    Array<DialogVideoParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple DialogVideoParent component instances */
  upsert?: InputMaybe<
    Array<DialogVideoParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type DialogVideoParentUpdateManyWithNestedWhereInput = {
  VideoDialogGroup?: InputMaybe<VideoDialogGroupUpdateManyWithNestedWhereInput>;
};

export type DialogVideoParentUpdateOneInlineInput = {
  /** Create and connect one DialogVideoParent document */
  create?: InputMaybe<DialogVideoParentCreateInput>;
  /** Delete currently connected DialogVideoParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single DialogVideoParent document */
  update?: InputMaybe<DialogVideoParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single DialogVideoParent document */
  upsert?: InputMaybe<DialogVideoParentUpsertWithNestedWhereUniqueInput>;
};

export type DialogVideoParentUpdateWithNestedWhereUniqueAndPositionInput = {
  VideoDialogGroup?: InputMaybe<VideoDialogGroupUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type DialogVideoParentUpdateWithNestedWhereUniqueInput = {
  VideoDialogGroup?: InputMaybe<VideoDialogGroupUpdateWithNestedWhereUniqueInput>;
};

export type DialogVideoParentUpsertWithNestedWhereUniqueAndPositionInput = {
  VideoDialogGroup?: InputMaybe<VideoDialogGroupUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type DialogVideoParentUpsertWithNestedWhereUniqueInput = {
  VideoDialogGroup?: InputMaybe<VideoDialogGroupUpsertWithNestedWhereUniqueInput>;
};

export type DialogVideoParentWhereInput = {
  VideoDialogGroup?: InputMaybe<VideoDialogGroupWhereInput>;
};

export type DialogVideoParentWhereUniqueInput = {
  VideoDialogGroup?: InputMaybe<VideoDialogGroupWhereUniqueInput>;
};

export type DialogVideoUpdateInput = {
  /** description input for default locale (default) */
  description?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<DialogVideoUpdateLocalizationsInput>;
  /** title input for default locale (default) */
  title?: InputMaybe<Scalars['String']>;
  video?: InputMaybe<DialogVideovideoUnionUpdateOneInlineInput>;
};

export type DialogVideoUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type DialogVideoUpdateLocalizationInput = {
  data: DialogVideoUpdateLocalizationDataInput;
  locale: Locale;
};

export type DialogVideoUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<DialogVideoCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<DialogVideoUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<DialogVideoUpsertLocalizationInput>>;
};

export type DialogVideoUpdateManyInlineInput = {
  /** Create and connect multiple DialogVideo component instances */
  create?: InputMaybe<Array<DialogVideoCreateWithPositionInput>>;
  /** Delete multiple DialogVideo documents */
  delete?: InputMaybe<Array<DialogVideoWhereUniqueInput>>;
  /** Update multiple DialogVideo component instances */
  update?: InputMaybe<
    Array<DialogVideoUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple DialogVideo component instances */
  upsert?: InputMaybe<
    Array<DialogVideoUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type DialogVideoUpdateManyInput = {
  /** description input for default locale (default) */
  description?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<DialogVideoUpdateManyLocalizationsInput>;
  /** title input for default locale (default) */
  title?: InputMaybe<Scalars['String']>;
};

export type DialogVideoUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type DialogVideoUpdateManyLocalizationInput = {
  data: DialogVideoUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type DialogVideoUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<DialogVideoUpdateManyLocalizationInput>>;
};

export type DialogVideoUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: DialogVideoUpdateManyInput;
  /** Document search */
  where: DialogVideoWhereInput;
};

export type DialogVideoUpdateOneInlineInput = {
  /** Create and connect one DialogVideo document */
  create?: InputMaybe<DialogVideoCreateInput>;
  /** Delete currently connected DialogVideo document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single DialogVideo document */
  update?: InputMaybe<DialogVideoUpdateWithNestedWhereUniqueInput>;
  /** Upsert single DialogVideo document */
  upsert?: InputMaybe<DialogVideoUpsertWithNestedWhereUniqueInput>;
};

export type DialogVideoUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<DialogVideoUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: DialogVideoWhereUniqueInput;
};

export type DialogVideoUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: DialogVideoUpdateInput;
  /** Unique document search */
  where: DialogVideoWhereUniqueInput;
};

export type DialogVideoUpsertInput = {
  /** Create document if it didn't exist */
  create: DialogVideoCreateInput;
  /** Update document if it exists */
  update: DialogVideoUpdateInput;
};

export type DialogVideoUpsertLocalizationInput = {
  create: DialogVideoCreateLocalizationDataInput;
  locale: Locale;
  update: DialogVideoUpdateLocalizationDataInput;
};

export type DialogVideoUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<DialogVideoUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: DialogVideoWhereUniqueInput;
};

export type DialogVideoUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: DialogVideoUpsertInput;
  /** Unique document search */
  where: DialogVideoWhereUniqueInput;
};

/** Identifies documents */
export type DialogVideoWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<DialogVideoWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<DialogVideoWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<DialogVideoWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']>;
  /** All values in which the modular component is connected to the given models */
  video?: InputMaybe<DialogVideovideoUnionWhereInput>;
  /** All values in which the union is empty. */
  video_empty?: InputMaybe<Scalars['Boolean']>;
};

/** References DialogVideo record uniquely */
export type DialogVideoWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type DialogVideovideoUnion = BrightcoveVideo;

export type DialogVideovideoUnionConnectInput = {
  BrightcoveVideo?: InputMaybe<BrightcoveVideoConnectInput>;
};

export type DialogVideovideoUnionCreateInput = {
  BrightcoveVideo?: InputMaybe<BrightcoveVideoCreateInput>;
};

export type DialogVideovideoUnionCreateManyInlineInput = {
  /** Create and connect multiple existing DialogVideovideoUnion documents */
  create?: InputMaybe<Array<DialogVideovideoUnionCreateInput>>;
};

export type DialogVideovideoUnionCreateOneInlineInput = {
  /** Create and connect one DialogVideovideoUnion document */
  create?: InputMaybe<DialogVideovideoUnionCreateInput>;
};

export type DialogVideovideoUnionCreateWithPositionInput = {
  BrightcoveVideo?: InputMaybe<BrightcoveVideoCreateWithPositionInput>;
};

export type DialogVideovideoUnionUpdateInput = {
  BrightcoveVideo?: InputMaybe<BrightcoveVideoUpdateInput>;
};

export type DialogVideovideoUnionUpdateManyInlineInput = {
  /** Create and connect multiple DialogVideovideoUnion component instances */
  create?: InputMaybe<Array<DialogVideovideoUnionCreateWithPositionInput>>;
  /** Delete multiple DialogVideovideoUnion documents */
  delete?: InputMaybe<Array<DialogVideovideoUnionWhereUniqueInput>>;
  /** Update multiple DialogVideovideoUnion component instances */
  update?: InputMaybe<
    Array<DialogVideovideoUnionUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple DialogVideovideoUnion component instances */
  upsert?: InputMaybe<
    Array<DialogVideovideoUnionUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type DialogVideovideoUnionUpdateManyWithNestedWhereInput = {
  BrightcoveVideo?: InputMaybe<BrightcoveVideoUpdateManyWithNestedWhereInput>;
};

export type DialogVideovideoUnionUpdateOneInlineInput = {
  /** Create and connect one DialogVideovideoUnion document */
  create?: InputMaybe<DialogVideovideoUnionCreateInput>;
  /** Delete currently connected DialogVideovideoUnion document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single DialogVideovideoUnion document */
  update?: InputMaybe<DialogVideovideoUnionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single DialogVideovideoUnion document */
  upsert?: InputMaybe<DialogVideovideoUnionUpsertWithNestedWhereUniqueInput>;
};

export type DialogVideovideoUnionUpdateWithNestedWhereUniqueAndPositionInput = {
  BrightcoveVideo?: InputMaybe<BrightcoveVideoUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type DialogVideovideoUnionUpdateWithNestedWhereUniqueInput = {
  BrightcoveVideo?: InputMaybe<BrightcoveVideoUpdateWithNestedWhereUniqueInput>;
};

export type DialogVideovideoUnionUpsertWithNestedWhereUniqueAndPositionInput = {
  BrightcoveVideo?: InputMaybe<BrightcoveVideoUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type DialogVideovideoUnionUpsertWithNestedWhereUniqueInput = {
  BrightcoveVideo?: InputMaybe<BrightcoveVideoUpsertWithNestedWhereUniqueInput>;
};

export type DialogVideovideoUnionWhereInput = {
  BrightcoveVideo?: InputMaybe<BrightcoveVideoWhereInput>;
};

export type DialogVideovideoUnionWhereUniqueInput = {
  BrightcoveVideo?: InputMaybe<BrightcoveVideoWhereUniqueInput>;
};

/** Represent disclosure content. */
export type Disclosure = Entity & {
  __typename?: 'Disclosure';
  content: RichText;
  /** The unique identifier */
  id: Scalars['ID'];
  label: Scalars['String'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Disclosure>;
  /** System stage field */
  stage: Stage;
};

/** Represent disclosure content. */
export type DisclosureLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type DisclosureConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: DisclosureWhereUniqueInput;
};

/** A connection to a list of items. */
export type DisclosureConnection = {
  __typename?: 'DisclosureConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<DisclosureEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type DisclosureCreateInput = {
  /** content input for default locale (default) */
  content: Scalars['RichTextAST'];
  /** label input for default locale (default) */
  label: Scalars['String'];
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<DisclosureCreateLocalizationsInput>;
};

export type DisclosureCreateLocalizationDataInput = {
  content: Scalars['RichTextAST'];
  label: Scalars['String'];
};

export type DisclosureCreateLocalizationInput = {
  /** Localization input */
  data: DisclosureCreateLocalizationDataInput;
  locale: Locale;
};

export type DisclosureCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<DisclosureCreateLocalizationInput>>;
};

export type DisclosureCreateManyInlineInput = {
  /** Create and connect multiple existing Disclosure documents */
  create?: InputMaybe<Array<DisclosureCreateInput>>;
};

export type DisclosureCreateOneInlineInput = {
  /** Create and connect one Disclosure document */
  create?: InputMaybe<DisclosureCreateInput>;
};

export type DisclosureCreateWithPositionInput = {
  /** Document to create */
  data: DisclosureCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type DisclosureEdge = {
  __typename?: 'DisclosureEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Disclosure;
};

/** Identifies documents */
export type DisclosureManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<DisclosureWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<DisclosureWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<DisclosureWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
};

export enum DisclosureOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
}

export type DisclosureParent = CBlock;

export type DisclosureParentConnectInput = {
  CBlock?: InputMaybe<CBlockConnectInput>;
};

export type DisclosureParentCreateInput = {
  CBlock?: InputMaybe<CBlockCreateInput>;
};

export type DisclosureParentCreateManyInlineInput = {
  /** Connect multiple existing DisclosureParent documents */
  connect?: InputMaybe<Array<DisclosureParentWhereUniqueInput>>;
  /** Create and connect multiple existing DisclosureParent documents */
  create?: InputMaybe<Array<DisclosureParentCreateInput>>;
};

export type DisclosureParentCreateOneInlineInput = {
  /** Connect one existing DisclosureParent document */
  connect?: InputMaybe<DisclosureParentWhereUniqueInput>;
  /** Create and connect one DisclosureParent document */
  create?: InputMaybe<DisclosureParentCreateInput>;
};

export type DisclosureParentUpdateInput = {
  CBlock?: InputMaybe<CBlockUpdateInput>;
};

export type DisclosureParentUpdateManyInlineInput = {
  /** Connect multiple existing DisclosureParent documents */
  connect?: InputMaybe<Array<DisclosureParentConnectInput>>;
  /** Create and connect multiple DisclosureParent documents */
  create?: InputMaybe<Array<DisclosureParentCreateInput>>;
  /** Delete multiple DisclosureParent documents */
  delete?: InputMaybe<Array<DisclosureParentWhereUniqueInput>>;
  /** Disconnect multiple DisclosureParent documents */
  disconnect?: InputMaybe<Array<DisclosureParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing DisclosureParent documents */
  set?: InputMaybe<Array<DisclosureParentWhereUniqueInput>>;
  /** Update multiple DisclosureParent documents */
  update?: InputMaybe<Array<DisclosureParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple DisclosureParent documents */
  upsert?: InputMaybe<Array<DisclosureParentUpsertWithNestedWhereUniqueInput>>;
};

export type DisclosureParentUpdateManyWithNestedWhereInput = {
  CBlock?: InputMaybe<CBlockUpdateManyWithNestedWhereInput>;
};

export type DisclosureParentUpdateOneInlineInput = {
  /** Connect existing DisclosureParent document */
  connect?: InputMaybe<DisclosureParentWhereUniqueInput>;
  /** Create and connect one DisclosureParent document */
  create?: InputMaybe<DisclosureParentCreateInput>;
  /** Delete currently connected DisclosureParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected DisclosureParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single DisclosureParent document */
  update?: InputMaybe<DisclosureParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single DisclosureParent document */
  upsert?: InputMaybe<DisclosureParentUpsertWithNestedWhereUniqueInput>;
};

export type DisclosureParentUpdateWithNestedWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockUpdateWithNestedWhereUniqueInput>;
};

export type DisclosureParentUpsertWithNestedWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockUpsertWithNestedWhereUniqueInput>;
};

export type DisclosureParentWhereInput = {
  CBlock?: InputMaybe<CBlockWhereInput>;
};

export type DisclosureParentWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockWhereUniqueInput>;
};

export type DisclosureUpdateInput = {
  /** content input for default locale (default) */
  content?: InputMaybe<Scalars['RichTextAST']>;
  /** label input for default locale (default) */
  label?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<DisclosureUpdateLocalizationsInput>;
};

export type DisclosureUpdateLocalizationDataInput = {
  content?: InputMaybe<Scalars['RichTextAST']>;
  label?: InputMaybe<Scalars['String']>;
};

export type DisclosureUpdateLocalizationInput = {
  data: DisclosureUpdateLocalizationDataInput;
  locale: Locale;
};

export type DisclosureUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<DisclosureCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<DisclosureUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<DisclosureUpsertLocalizationInput>>;
};

export type DisclosureUpdateManyInlineInput = {
  /** Create and connect multiple Disclosure component instances */
  create?: InputMaybe<Array<DisclosureCreateWithPositionInput>>;
  /** Delete multiple Disclosure documents */
  delete?: InputMaybe<Array<DisclosureWhereUniqueInput>>;
  /** Update multiple Disclosure component instances */
  update?: InputMaybe<
    Array<DisclosureUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple Disclosure component instances */
  upsert?: InputMaybe<
    Array<DisclosureUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type DisclosureUpdateManyInput = {
  /** content input for default locale (default) */
  content?: InputMaybe<Scalars['RichTextAST']>;
  /** label input for default locale (default) */
  label?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<DisclosureUpdateManyLocalizationsInput>;
};

export type DisclosureUpdateManyLocalizationDataInput = {
  content?: InputMaybe<Scalars['RichTextAST']>;
  label?: InputMaybe<Scalars['String']>;
};

export type DisclosureUpdateManyLocalizationInput = {
  data: DisclosureUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type DisclosureUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<DisclosureUpdateManyLocalizationInput>>;
};

export type DisclosureUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: DisclosureUpdateManyInput;
  /** Document search */
  where: DisclosureWhereInput;
};

export type DisclosureUpdateOneInlineInput = {
  /** Create and connect one Disclosure document */
  create?: InputMaybe<DisclosureCreateInput>;
  /** Delete currently connected Disclosure document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single Disclosure document */
  update?: InputMaybe<DisclosureUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Disclosure document */
  upsert?: InputMaybe<DisclosureUpsertWithNestedWhereUniqueInput>;
};

export type DisclosureUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<DisclosureUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: DisclosureWhereUniqueInput;
};

export type DisclosureUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: DisclosureUpdateInput;
  /** Unique document search */
  where: DisclosureWhereUniqueInput;
};

export type DisclosureUpsertInput = {
  /** Create document if it didn't exist */
  create: DisclosureCreateInput;
  /** Update document if it exists */
  update: DisclosureUpdateInput;
};

export type DisclosureUpsertLocalizationInput = {
  create: DisclosureCreateLocalizationDataInput;
  locale: Locale;
  update: DisclosureUpdateLocalizationDataInput;
};

export type DisclosureUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<DisclosureUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: DisclosureWhereUniqueInput;
};

export type DisclosureUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: DisclosureUpsertInput;
  /** Unique document search */
  where: DisclosureWhereUniqueInput;
};

/** Identifies documents */
export type DisclosureWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<DisclosureWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<DisclosureWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<DisclosureWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  label?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  label_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  label_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  label_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  label_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  label_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  label_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  label_starts_with?: InputMaybe<Scalars['String']>;
};

/** References Disclosure record uniquely */
export type DisclosureWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Divider = Entity & {
  __typename?: 'Divider';
  actionGroup: CActionGroup;
  /** The unique identifier */
  id: Scalars['ID'];
  image: Asset;
  /** System stage field */
  stage: Stage;
};

export type DividerActionGroupArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type DividerImageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
  where?: InputMaybe<AssetSingleRelationWhereInput>;
};

export type DividerConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: DividerWhereUniqueInput;
};

/** A connection to a list of items. */
export type DividerConnection = {
  __typename?: 'DividerConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<DividerEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type DividerCreateInput = {
  actionGroup: CActionGroupCreateOneInlineInput;
  image: AssetCreateOneInlineInput;
};

export type DividerCreateManyInlineInput = {
  /** Create and connect multiple existing Divider documents */
  create?: InputMaybe<Array<DividerCreateInput>>;
};

export type DividerCreateOneInlineInput = {
  /** Create and connect one Divider document */
  create?: InputMaybe<DividerCreateInput>;
};

export type DividerCreateWithPositionInput = {
  /** Document to create */
  data: DividerCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type DividerEdge = {
  __typename?: 'DividerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Divider;
};

/** Identifies documents */
export type DividerManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<DividerWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<DividerWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<DividerWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  actionGroup?: InputMaybe<CActionGroupWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AssetWhereInput>;
};

export enum DividerOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type DividerParent = CBannerPrimary;

export type DividerParentConnectInput = {
  CBannerPrimary?: InputMaybe<CBannerPrimaryConnectInput>;
};

export type DividerParentCreateInput = {
  CBannerPrimary?: InputMaybe<CBannerPrimaryCreateInput>;
};

export type DividerParentCreateManyInlineInput = {
  /** Create and connect multiple existing DividerParent documents */
  create?: InputMaybe<Array<DividerParentCreateInput>>;
};

export type DividerParentCreateOneInlineInput = {
  /** Create and connect one DividerParent document */
  create?: InputMaybe<DividerParentCreateInput>;
};

export type DividerParentCreateWithPositionInput = {
  CBannerPrimary?: InputMaybe<CBannerPrimaryCreateWithPositionInput>;
};

export type DividerParentUpdateInput = {
  CBannerPrimary?: InputMaybe<CBannerPrimaryUpdateInput>;
};

export type DividerParentUpdateManyInlineInput = {
  /** Create and connect multiple DividerParent component instances */
  create?: InputMaybe<Array<DividerParentCreateWithPositionInput>>;
  /** Delete multiple DividerParent documents */
  delete?: InputMaybe<Array<DividerParentWhereUniqueInput>>;
  /** Update multiple DividerParent component instances */
  update?: InputMaybe<
    Array<DividerParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple DividerParent component instances */
  upsert?: InputMaybe<
    Array<DividerParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type DividerParentUpdateManyWithNestedWhereInput = {
  CBannerPrimary?: InputMaybe<CBannerPrimaryUpdateManyWithNestedWhereInput>;
};

export type DividerParentUpdateOneInlineInput = {
  /** Create and connect one DividerParent document */
  create?: InputMaybe<DividerParentCreateInput>;
  /** Delete currently connected DividerParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single DividerParent document */
  update?: InputMaybe<DividerParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single DividerParent document */
  upsert?: InputMaybe<DividerParentUpsertWithNestedWhereUniqueInput>;
};

export type DividerParentUpdateWithNestedWhereUniqueAndPositionInput = {
  CBannerPrimary?: InputMaybe<CBannerPrimaryUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type DividerParentUpdateWithNestedWhereUniqueInput = {
  CBannerPrimary?: InputMaybe<CBannerPrimaryUpdateWithNestedWhereUniqueInput>;
};

export type DividerParentUpsertWithNestedWhereUniqueAndPositionInput = {
  CBannerPrimary?: InputMaybe<CBannerPrimaryUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type DividerParentUpsertWithNestedWhereUniqueInput = {
  CBannerPrimary?: InputMaybe<CBannerPrimaryUpsertWithNestedWhereUniqueInput>;
};

export type DividerParentWhereInput = {
  CBannerPrimary?: InputMaybe<CBannerPrimaryWhereInput>;
};

export type DividerParentWhereUniqueInput = {
  CBannerPrimary?: InputMaybe<CBannerPrimaryWhereUniqueInput>;
};

export type DividerUpdateInput = {
  actionGroup?: InputMaybe<CActionGroupUpdateOneInlineInput>;
  image?: InputMaybe<AssetUpdateOneInlineInput>;
};

export type DividerUpdateManyInlineInput = {
  /** Create and connect multiple Divider component instances */
  create?: InputMaybe<Array<DividerCreateWithPositionInput>>;
  /** Delete multiple Divider documents */
  delete?: InputMaybe<Array<DividerWhereUniqueInput>>;
  /** Update multiple Divider component instances */
  update?: InputMaybe<
    Array<DividerUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple Divider component instances */
  upsert?: InputMaybe<
    Array<DividerUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type DividerUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']>;
};

export type DividerUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: DividerUpdateManyInput;
  /** Document search */
  where: DividerWhereInput;
};

export type DividerUpdateOneInlineInput = {
  /** Create and connect one Divider document */
  create?: InputMaybe<DividerCreateInput>;
  /** Delete currently connected Divider document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single Divider document */
  update?: InputMaybe<DividerUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Divider document */
  upsert?: InputMaybe<DividerUpsertWithNestedWhereUniqueInput>;
};

export type DividerUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<DividerUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: DividerWhereUniqueInput;
};

export type DividerUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: DividerUpdateInput;
  /** Unique document search */
  where: DividerWhereUniqueInput;
};

export type DividerUpsertInput = {
  /** Create document if it didn't exist */
  create: DividerCreateInput;
  /** Update document if it exists */
  update: DividerUpdateInput;
};

export type DividerUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<DividerUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: DividerWhereUniqueInput;
};

export type DividerUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: DividerUpsertInput;
  /** Unique document search */
  where: DividerWhereUniqueInput;
};

/** Identifies documents */
export type DividerWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<DividerWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<DividerWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<DividerWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  actionGroup?: InputMaybe<CActionGroupWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AssetWhereInput>;
};

/** References Divider record uniquely */
export type DividerWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export enum DocumentFileTypes {
  /** Automatically selects the best format for the image based on the browser's capabilities. */
  AutoImage = 'autoImage',
  Avif = 'avif',
  Bmp = 'bmp',
  Gif = 'gif',
  Heic = 'heic',
  Jpg = 'jpg',
  Png = 'png',
  Svg = 'svg',
  Tiff = 'tiff',
  Webp = 'webp',
}

export type DocumentItem = Entity &
  Node & {
    __typename?: 'DocumentItem';
    /** The time the document was created */
    createdAt: Scalars['DateTime'];
    /** User that created this document */
    createdBy?: Maybe<User>;
    date?: Maybe<Scalars['Date']>;
    /** Get the document in other stages */
    documentInStages: Array<DocumentItem>;
    /** Link to PDF */
    externalUrl?: Maybe<ExternalUrl>;
    /** List of DocumentItem versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars['ID'];
    /** Icon for an item. Can be overridden. */
    itemIcon?: Maybe<Icon>;
    /** System Locale field */
    locale: Locale;
    /** Get the other localizations for this document */
    localizations: Array<DocumentItem>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    slug: Scalars['String'];
    /** System stage field */
    stage: Stage;
    title: Scalars['String'];
    type?: Maybe<DocumentTypeForReportAndPresentation>;
    /** The time the document was updated */
    updatedAt: Scalars['DateTime'];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type DocumentItemCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type DocumentItemCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type DocumentItemDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type DocumentItemExternalUrlArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type DocumentItemHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};

export type DocumentItemLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type DocumentItemPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type DocumentItemPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type DocumentItemScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type DocumentItemUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type DocumentItemUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type DocumentItemConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: DocumentItemWhereUniqueInput;
};

/** A connection to a list of items. */
export type DocumentItemConnection = {
  __typename?: 'DocumentItemConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<DocumentItemEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type DocumentItemCreateInput = {
  cm0dol9093n3406uo6i7fhj6j?: InputMaybe<DocumentListCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** date input for default locale (default) */
  date?: InputMaybe<Scalars['Date']>;
  externalUrl?: InputMaybe<ExternalUrlCreateOneInlineInput>;
  itemIcon?: InputMaybe<Icon>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<DocumentItemCreateLocalizationsInput>;
  slug: Scalars['String'];
  /** title input for default locale (default) */
  title: Scalars['String'];
  type?: InputMaybe<DocumentTypeForReportAndPresentation>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DocumentItemCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  date?: InputMaybe<Scalars['Date']>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DocumentItemCreateLocalizationInput = {
  /** Localization input */
  data: DocumentItemCreateLocalizationDataInput;
  locale: Locale;
};

export type DocumentItemCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<DocumentItemCreateLocalizationInput>>;
};

export type DocumentItemCreateManyInlineInput = {
  /** Connect multiple existing DocumentItem documents */
  connect?: InputMaybe<Array<DocumentItemWhereUniqueInput>>;
  /** Create and connect multiple existing DocumentItem documents */
  create?: InputMaybe<Array<DocumentItemCreateInput>>;
};

export type DocumentItemCreateOneInlineInput = {
  /** Connect one existing DocumentItem document */
  connect?: InputMaybe<DocumentItemWhereUniqueInput>;
  /** Create and connect one DocumentItem document */
  create?: InputMaybe<DocumentItemCreateInput>;
};

/** An edge in a connection. */
export type DocumentItemEdge = {
  __typename?: 'DocumentItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: DocumentItem;
};

/** Identifies documents */
export type DocumentItemManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<DocumentItemWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<DocumentItemWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<DocumentItemWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<DocumentItemWhereStageInput>;
  documentInStages_none?: InputMaybe<DocumentItemWhereStageInput>;
  documentInStages_some?: InputMaybe<DocumentItemWhereStageInput>;
  externalUrl?: InputMaybe<ExternalUrlWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  itemIcon?: InputMaybe<Icon>;
  /** All values that are contained in given list. */
  itemIcon_in?: InputMaybe<Array<InputMaybe<Icon>>>;
  /** Any other value that exists and is not equal to the given value. */
  itemIcon_not?: InputMaybe<Icon>;
  /** All values that are not contained in given list. */
  itemIcon_not_in?: InputMaybe<Array<InputMaybe<Icon>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  slug?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<DocumentTypeForReportAndPresentation>;
  /** All values that are contained in given list. */
  type_in?: InputMaybe<Array<InputMaybe<DocumentTypeForReportAndPresentation>>>;
  /** Any other value that exists and is not equal to the given value. */
  type_not?: InputMaybe<DocumentTypeForReportAndPresentation>;
  /** All values that are not contained in given list. */
  type_not_in?: InputMaybe<
    Array<InputMaybe<DocumentTypeForReportAndPresentation>>
  >;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum DocumentItemOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ItemIconAsc = 'itemIcon_ASC',
  ItemIconDesc = 'itemIcon_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TypeAsc = 'type_ASC',
  TypeDesc = 'type_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type DocumentItemUpdateInput = {
  cm0dol9093n3406uo6i7fhj6j?: InputMaybe<DocumentListUpdateManyInlineInput>;
  /** date input for default locale (default) */
  date?: InputMaybe<Scalars['Date']>;
  externalUrl?: InputMaybe<ExternalUrlUpdateOneInlineInput>;
  itemIcon?: InputMaybe<Icon>;
  /** Manage document localizations */
  localizations?: InputMaybe<DocumentItemUpdateLocalizationsInput>;
  slug?: InputMaybe<Scalars['String']>;
  /** title input for default locale (default) */
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<DocumentTypeForReportAndPresentation>;
};

export type DocumentItemUpdateLocalizationDataInput = {
  date?: InputMaybe<Scalars['Date']>;
  title?: InputMaybe<Scalars['String']>;
};

export type DocumentItemUpdateLocalizationInput = {
  data: DocumentItemUpdateLocalizationDataInput;
  locale: Locale;
};

export type DocumentItemUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<DocumentItemCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<DocumentItemUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<DocumentItemUpsertLocalizationInput>>;
};

export type DocumentItemUpdateManyInlineInput = {
  /** Connect multiple existing DocumentItem documents */
  connect?: InputMaybe<Array<DocumentItemConnectInput>>;
  /** Create and connect multiple DocumentItem documents */
  create?: InputMaybe<Array<DocumentItemCreateInput>>;
  /** Delete multiple DocumentItem documents */
  delete?: InputMaybe<Array<DocumentItemWhereUniqueInput>>;
  /** Disconnect multiple DocumentItem documents */
  disconnect?: InputMaybe<Array<DocumentItemWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing DocumentItem documents */
  set?: InputMaybe<Array<DocumentItemWhereUniqueInput>>;
  /** Update multiple DocumentItem documents */
  update?: InputMaybe<Array<DocumentItemUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple DocumentItem documents */
  upsert?: InputMaybe<Array<DocumentItemUpsertWithNestedWhereUniqueInput>>;
};

export type DocumentItemUpdateManyInput = {
  /** date input for default locale (default) */
  date?: InputMaybe<Scalars['Date']>;
  itemIcon?: InputMaybe<Icon>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<DocumentItemUpdateManyLocalizationsInput>;
  /** title input for default locale (default) */
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<DocumentTypeForReportAndPresentation>;
};

export type DocumentItemUpdateManyLocalizationDataInput = {
  date?: InputMaybe<Scalars['Date']>;
  title?: InputMaybe<Scalars['String']>;
};

export type DocumentItemUpdateManyLocalizationInput = {
  data: DocumentItemUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type DocumentItemUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<DocumentItemUpdateManyLocalizationInput>>;
};

export type DocumentItemUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: DocumentItemUpdateManyInput;
  /** Document search */
  where: DocumentItemWhereInput;
};

export type DocumentItemUpdateOneInlineInput = {
  /** Connect existing DocumentItem document */
  connect?: InputMaybe<DocumentItemWhereUniqueInput>;
  /** Create and connect one DocumentItem document */
  create?: InputMaybe<DocumentItemCreateInput>;
  /** Delete currently connected DocumentItem document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected DocumentItem document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single DocumentItem document */
  update?: InputMaybe<DocumentItemUpdateWithNestedWhereUniqueInput>;
  /** Upsert single DocumentItem document */
  upsert?: InputMaybe<DocumentItemUpsertWithNestedWhereUniqueInput>;
};

export type DocumentItemUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: DocumentItemUpdateInput;
  /** Unique document search */
  where: DocumentItemWhereUniqueInput;
};

export type DocumentItemUpsertInput = {
  /** Create document if it didn't exist */
  create: DocumentItemCreateInput;
  /** Update document if it exists */
  update: DocumentItemUpdateInput;
};

export type DocumentItemUpsertLocalizationInput = {
  create: DocumentItemCreateLocalizationDataInput;
  locale: Locale;
  update: DocumentItemUpdateLocalizationDataInput;
};

export type DocumentItemUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: DocumentItemUpsertInput;
  /** Unique document search */
  where: DocumentItemWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type DocumentItemWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type DocumentItemWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<DocumentItemWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<DocumentItemWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<DocumentItemWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  date?: InputMaybe<Scalars['Date']>;
  /** All values greater than the given value. */
  date_gt?: InputMaybe<Scalars['Date']>;
  /** All values greater than or equal the given value. */
  date_gte?: InputMaybe<Scalars['Date']>;
  /** All values that are contained in given list. */
  date_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  /** All values less than the given value. */
  date_lt?: InputMaybe<Scalars['Date']>;
  /** All values less than or equal the given value. */
  date_lte?: InputMaybe<Scalars['Date']>;
  /** Any other value that exists and is not equal to the given value. */
  date_not?: InputMaybe<Scalars['Date']>;
  /** All values that are not contained in given list. */
  date_not_in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  documentInStages_every?: InputMaybe<DocumentItemWhereStageInput>;
  documentInStages_none?: InputMaybe<DocumentItemWhereStageInput>;
  documentInStages_some?: InputMaybe<DocumentItemWhereStageInput>;
  externalUrl?: InputMaybe<ExternalUrlWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  itemIcon?: InputMaybe<Icon>;
  /** All values that are contained in given list. */
  itemIcon_in?: InputMaybe<Array<InputMaybe<Icon>>>;
  /** Any other value that exists and is not equal to the given value. */
  itemIcon_not?: InputMaybe<Icon>;
  /** All values that are not contained in given list. */
  itemIcon_not_in?: InputMaybe<Array<InputMaybe<Icon>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  slug?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<DocumentTypeForReportAndPresentation>;
  /** All values that are contained in given list. */
  type_in?: InputMaybe<Array<InputMaybe<DocumentTypeForReportAndPresentation>>>;
  /** Any other value that exists and is not equal to the given value. */
  type_not?: InputMaybe<DocumentTypeForReportAndPresentation>;
  /** All values that are not contained in given list. */
  type_not_in?: InputMaybe<
    Array<InputMaybe<DocumentTypeForReportAndPresentation>>
  >;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type DocumentItemWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<DocumentItemWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<DocumentItemWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<DocumentItemWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<DocumentItemWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References DocumentItem record uniquely */
export type DocumentItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
};

/** Primarily used as a list of documents. */
export type DocumentList = Entity & {
  __typename?: 'DocumentList';
  /** Allow filter for Documents */
  documentFilterOption?: Maybe<DocumentListFilter>;
  documents: Array<DocumentItem>;
  /** Group Name to combine multiple Document List of the same group */
  groupName?: Maybe<Scalars['String']>;
  /** Overrides the icons of the list items in the Document List. */
  icon?: Maybe<Icon>;
  /** The unique identifier */
  id: Scalars['ID'];
  /**
   * Maximum documents to display per page.
   * Applicable only when "Use Pagination" is enabled.
   */
  maxPageSize?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  /** System stage field */
  stage: Stage;
  /** Enable usage of Pagination */
  usePagination?: Maybe<Scalars['Boolean']>;
};

/** Primarily used as a list of documents. */
export type DocumentListDocumentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<DocumentItemOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DocumentItemWhereInput>;
};

export type DocumentListConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: DocumentListWhereUniqueInput;
};

/** A connection to a list of items. */
export type DocumentListConnection = {
  __typename?: 'DocumentListConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<DocumentListEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type DocumentListCreateInput = {
  documentFilterOption?: InputMaybe<DocumentListFilter>;
  documents?: InputMaybe<DocumentItemCreateManyInlineInput>;
  groupName?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Icon>;
  maxPageSize?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  usePagination?: InputMaybe<Scalars['Boolean']>;
};

export type DocumentListCreateManyInlineInput = {
  /** Create and connect multiple existing DocumentList documents */
  create?: InputMaybe<Array<DocumentListCreateInput>>;
};

export type DocumentListCreateOneInlineInput = {
  /** Create and connect one DocumentList document */
  create?: InputMaybe<DocumentListCreateInput>;
};

export type DocumentListCreateWithPositionInput = {
  /** Document to create */
  data: DocumentListCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type DocumentListEdge = {
  __typename?: 'DocumentListEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: DocumentList;
};

export enum DocumentListFilter {
  NoFilter = 'NoFilter',
  Year = 'Year',
}

/** Identifies documents */
export type DocumentListManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<DocumentListWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<DocumentListWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<DocumentListWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  documentFilterOption?: InputMaybe<DocumentListFilter>;
  /** All values that are contained in given list. */
  documentFilterOption_in?: InputMaybe<Array<InputMaybe<DocumentListFilter>>>;
  /** Any other value that exists and is not equal to the given value. */
  documentFilterOption_not?: InputMaybe<DocumentListFilter>;
  /** All values that are not contained in given list. */
  documentFilterOption_not_in?: InputMaybe<
    Array<InputMaybe<DocumentListFilter>>
  >;
  documents_every?: InputMaybe<DocumentItemWhereInput>;
  documents_none?: InputMaybe<DocumentItemWhereInput>;
  documents_some?: InputMaybe<DocumentItemWhereInput>;
  groupName?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  groupName_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  groupName_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  groupName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  groupName_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  groupName_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  groupName_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  groupName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  groupName_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  groupName_starts_with?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Icon>;
  /** All values that are contained in given list. */
  icon_in?: InputMaybe<Array<InputMaybe<Icon>>>;
  /** Any other value that exists and is not equal to the given value. */
  icon_not?: InputMaybe<Icon>;
  /** All values that are not contained in given list. */
  icon_not_in?: InputMaybe<Array<InputMaybe<Icon>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  maxPageSize?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  maxPageSize_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  maxPageSize_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  maxPageSize_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** All values less than the given value. */
  maxPageSize_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  maxPageSize_lte?: InputMaybe<Scalars['Int']>;
  /** Any other value that exists and is not equal to the given value. */
  maxPageSize_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  maxPageSize_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  name?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']>;
  usePagination?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  usePagination_not?: InputMaybe<Scalars['Boolean']>;
};

export enum DocumentListOrderByInput {
  DocumentFilterOptionAsc = 'documentFilterOption_ASC',
  DocumentFilterOptionDesc = 'documentFilterOption_DESC',
  GroupNameAsc = 'groupName_ASC',
  GroupNameDesc = 'groupName_DESC',
  IconAsc = 'icon_ASC',
  IconDesc = 'icon_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MaxPageSizeAsc = 'maxPageSize_ASC',
  MaxPageSizeDesc = 'maxPageSize_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  UsePaginationAsc = 'usePagination_ASC',
  UsePaginationDesc = 'usePagination_DESC',
}

export type DocumentListParent = CBlock;

export type DocumentListParentConnectInput = {
  CBlock?: InputMaybe<CBlockConnectInput>;
};

export type DocumentListParentCreateInput = {
  CBlock?: InputMaybe<CBlockCreateInput>;
};

export type DocumentListParentCreateManyInlineInput = {
  /** Connect multiple existing DocumentListParent documents */
  connect?: InputMaybe<Array<DocumentListParentWhereUniqueInput>>;
  /** Create and connect multiple existing DocumentListParent documents */
  create?: InputMaybe<Array<DocumentListParentCreateInput>>;
};

export type DocumentListParentCreateOneInlineInput = {
  /** Connect one existing DocumentListParent document */
  connect?: InputMaybe<DocumentListParentWhereUniqueInput>;
  /** Create and connect one DocumentListParent document */
  create?: InputMaybe<DocumentListParentCreateInput>;
};

export type DocumentListParentUpdateInput = {
  CBlock?: InputMaybe<CBlockUpdateInput>;
};

export type DocumentListParentUpdateManyInlineInput = {
  /** Connect multiple existing DocumentListParent documents */
  connect?: InputMaybe<Array<DocumentListParentConnectInput>>;
  /** Create and connect multiple DocumentListParent documents */
  create?: InputMaybe<Array<DocumentListParentCreateInput>>;
  /** Delete multiple DocumentListParent documents */
  delete?: InputMaybe<Array<DocumentListParentWhereUniqueInput>>;
  /** Disconnect multiple DocumentListParent documents */
  disconnect?: InputMaybe<Array<DocumentListParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing DocumentListParent documents */
  set?: InputMaybe<Array<DocumentListParentWhereUniqueInput>>;
  /** Update multiple DocumentListParent documents */
  update?: InputMaybe<
    Array<DocumentListParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple DocumentListParent documents */
  upsert?: InputMaybe<
    Array<DocumentListParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type DocumentListParentUpdateManyWithNestedWhereInput = {
  CBlock?: InputMaybe<CBlockUpdateManyWithNestedWhereInput>;
};

export type DocumentListParentUpdateOneInlineInput = {
  /** Connect existing DocumentListParent document */
  connect?: InputMaybe<DocumentListParentWhereUniqueInput>;
  /** Create and connect one DocumentListParent document */
  create?: InputMaybe<DocumentListParentCreateInput>;
  /** Delete currently connected DocumentListParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected DocumentListParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single DocumentListParent document */
  update?: InputMaybe<DocumentListParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single DocumentListParent document */
  upsert?: InputMaybe<DocumentListParentUpsertWithNestedWhereUniqueInput>;
};

export type DocumentListParentUpdateWithNestedWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockUpdateWithNestedWhereUniqueInput>;
};

export type DocumentListParentUpsertWithNestedWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockUpsertWithNestedWhereUniqueInput>;
};

export type DocumentListParentWhereInput = {
  CBlock?: InputMaybe<CBlockWhereInput>;
};

export type DocumentListParentWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockWhereUniqueInput>;
};

export type DocumentListUpdateInput = {
  documentFilterOption?: InputMaybe<DocumentListFilter>;
  documents?: InputMaybe<DocumentItemUpdateManyInlineInput>;
  groupName?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Icon>;
  maxPageSize?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  usePagination?: InputMaybe<Scalars['Boolean']>;
};

export type DocumentListUpdateManyInlineInput = {
  /** Create and connect multiple DocumentList component instances */
  create?: InputMaybe<Array<DocumentListCreateWithPositionInput>>;
  /** Delete multiple DocumentList documents */
  delete?: InputMaybe<Array<DocumentListWhereUniqueInput>>;
  /** Update multiple DocumentList component instances */
  update?: InputMaybe<
    Array<DocumentListUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple DocumentList component instances */
  upsert?: InputMaybe<
    Array<DocumentListUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type DocumentListUpdateManyInput = {
  documentFilterOption?: InputMaybe<DocumentListFilter>;
  groupName?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Icon>;
  maxPageSize?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  usePagination?: InputMaybe<Scalars['Boolean']>;
};

export type DocumentListUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: DocumentListUpdateManyInput;
  /** Document search */
  where: DocumentListWhereInput;
};

export type DocumentListUpdateOneInlineInput = {
  /** Create and connect one DocumentList document */
  create?: InputMaybe<DocumentListCreateInput>;
  /** Delete currently connected DocumentList document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single DocumentList document */
  update?: InputMaybe<DocumentListUpdateWithNestedWhereUniqueInput>;
  /** Upsert single DocumentList document */
  upsert?: InputMaybe<DocumentListUpsertWithNestedWhereUniqueInput>;
};

export type DocumentListUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<DocumentListUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: DocumentListWhereUniqueInput;
};

export type DocumentListUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: DocumentListUpdateInput;
  /** Unique document search */
  where: DocumentListWhereUniqueInput;
};

export type DocumentListUpsertInput = {
  /** Create document if it didn't exist */
  create: DocumentListCreateInput;
  /** Update document if it exists */
  update: DocumentListUpdateInput;
};

export type DocumentListUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<DocumentListUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: DocumentListWhereUniqueInput;
};

export type DocumentListUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: DocumentListUpsertInput;
  /** Unique document search */
  where: DocumentListWhereUniqueInput;
};

/** Identifies documents */
export type DocumentListWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<DocumentListWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<DocumentListWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<DocumentListWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  documentFilterOption?: InputMaybe<DocumentListFilter>;
  /** All values that are contained in given list. */
  documentFilterOption_in?: InputMaybe<Array<InputMaybe<DocumentListFilter>>>;
  /** Any other value that exists and is not equal to the given value. */
  documentFilterOption_not?: InputMaybe<DocumentListFilter>;
  /** All values that are not contained in given list. */
  documentFilterOption_not_in?: InputMaybe<
    Array<InputMaybe<DocumentListFilter>>
  >;
  documents_every?: InputMaybe<DocumentItemWhereInput>;
  documents_none?: InputMaybe<DocumentItemWhereInput>;
  documents_some?: InputMaybe<DocumentItemWhereInput>;
  groupName?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  groupName_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  groupName_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  groupName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  groupName_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  groupName_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  groupName_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  groupName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  groupName_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  groupName_starts_with?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Icon>;
  /** All values that are contained in given list. */
  icon_in?: InputMaybe<Array<InputMaybe<Icon>>>;
  /** Any other value that exists and is not equal to the given value. */
  icon_not?: InputMaybe<Icon>;
  /** All values that are not contained in given list. */
  icon_not_in?: InputMaybe<Array<InputMaybe<Icon>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  maxPageSize?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  maxPageSize_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  maxPageSize_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  maxPageSize_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** All values less than the given value. */
  maxPageSize_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  maxPageSize_lte?: InputMaybe<Scalars['Int']>;
  /** Any other value that exists and is not equal to the given value. */
  maxPageSize_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  maxPageSize_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  name?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']>;
  usePagination?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  usePagination_not?: InputMaybe<Scalars['Boolean']>;
};

/** References DocumentList record uniquely */
export type DocumentListWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type DocumentOutputInput = {
  /**
   * Transforms a document into a desired file type.
   * See this matrix for format support:
   *
   * JPG:	autoImage, bmp, gif, jpg, png, webp, tiff
   * PNG:	autoImage, bmp, gif, jpg, png, webp, tiff, svg
   * SVG:	autoImage, bmp, gif, jpg, png, webp, tiff
   * WEBP:	autoImage, bmp, gif, jpg, png, webp, tiff, svg
   * GIF:	autoImage, bmp, gif, jpg, png, webp, tiff, svg
   * TIFF:	autoImage, bmp, gif, jpg, png, webp, tiff, svg
   * AVIF:	autoImage, bmp, gif, jpg, png, webp, tiff, svg
   * PDF: 	autoImage, gif, jpg, png, webp, tiff
   *
   */
  format?: InputMaybe<DocumentFileTypes>;
};

/** Transformations for Documents */
export type DocumentTransformationInput = {
  /** Changes the output for the file. */
  output?: InputMaybe<DocumentOutputInput>;
};

export enum DocumentTypeForReportAndPresentation {
  AnnualReports = 'AnnualReports',
  FinancialReports = 'FinancialReports',
  Presentations = 'Presentations',
}

export type DocumentVersion = {
  __typename?: 'DocumentVersion';
  createdAt: Scalars['DateTime'];
  data?: Maybe<Scalars['Json']>;
  id: Scalars['ID'];
  revision: Scalars['Int'];
  stage: Stage;
};

/** Validator used for FormInputEmail to validate email input */
export type EmailValidator = Entity & {
  __typename?: 'EmailValidator';
  errorMessage?: Maybe<Scalars['String']>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<EmailValidator>;
  /** System stage field */
  stage: Stage;
};

/** Validator used for FormInputEmail to validate email input */
export type EmailValidatorLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type EmailValidatorConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: EmailValidatorWhereUniqueInput;
};

/** A connection to a list of items. */
export type EmailValidatorConnection = {
  __typename?: 'EmailValidatorConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<EmailValidatorEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type EmailValidatorCreateInput = {
  /** errorMessage input for default locale (default) */
  errorMessage?: InputMaybe<Scalars['String']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<EmailValidatorCreateLocalizationsInput>;
};

export type EmailValidatorCreateLocalizationDataInput = {
  errorMessage?: InputMaybe<Scalars['String']>;
};

export type EmailValidatorCreateLocalizationInput = {
  /** Localization input */
  data: EmailValidatorCreateLocalizationDataInput;
  locale: Locale;
};

export type EmailValidatorCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<EmailValidatorCreateLocalizationInput>>;
};

export type EmailValidatorCreateManyInlineInput = {
  /** Create and connect multiple existing EmailValidator documents */
  create?: InputMaybe<Array<EmailValidatorCreateInput>>;
};

export type EmailValidatorCreateOneInlineInput = {
  /** Create and connect one EmailValidator document */
  create?: InputMaybe<EmailValidatorCreateInput>;
};

export type EmailValidatorCreateWithPositionInput = {
  /** Document to create */
  data: EmailValidatorCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type EmailValidatorEdge = {
  __typename?: 'EmailValidatorEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: EmailValidator;
};

/** Identifies documents */
export type EmailValidatorManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EmailValidatorWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EmailValidatorWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EmailValidatorWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
};

export enum EmailValidatorOrderByInput {
  ErrorMessageAsc = 'errorMessage_ASC',
  ErrorMessageDesc = 'errorMessage_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type EmailValidatorParent = FormInputEmail;

export type EmailValidatorParentConnectInput = {
  FormInputEmail?: InputMaybe<FormInputEmailConnectInput>;
};

export type EmailValidatorParentCreateInput = {
  FormInputEmail?: InputMaybe<FormInputEmailCreateInput>;
};

export type EmailValidatorParentCreateManyInlineInput = {
  /** Create and connect multiple existing EmailValidatorParent documents */
  create?: InputMaybe<Array<EmailValidatorParentCreateInput>>;
};

export type EmailValidatorParentCreateOneInlineInput = {
  /** Create and connect one EmailValidatorParent document */
  create?: InputMaybe<EmailValidatorParentCreateInput>;
};

export type EmailValidatorParentCreateWithPositionInput = {
  FormInputEmail?: InputMaybe<FormInputEmailCreateWithPositionInput>;
};

export type EmailValidatorParentUpdateInput = {
  FormInputEmail?: InputMaybe<FormInputEmailUpdateInput>;
};

export type EmailValidatorParentUpdateManyInlineInput = {
  /** Create and connect multiple EmailValidatorParent component instances */
  create?: InputMaybe<Array<EmailValidatorParentCreateWithPositionInput>>;
  /** Delete multiple EmailValidatorParent documents */
  delete?: InputMaybe<Array<EmailValidatorParentWhereUniqueInput>>;
  /** Update multiple EmailValidatorParent component instances */
  update?: InputMaybe<
    Array<EmailValidatorParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple EmailValidatorParent component instances */
  upsert?: InputMaybe<
    Array<EmailValidatorParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type EmailValidatorParentUpdateManyWithNestedWhereInput = {
  FormInputEmail?: InputMaybe<FormInputEmailUpdateManyWithNestedWhereInput>;
};

export type EmailValidatorParentUpdateOneInlineInput = {
  /** Create and connect one EmailValidatorParent document */
  create?: InputMaybe<EmailValidatorParentCreateInput>;
  /** Delete currently connected EmailValidatorParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single EmailValidatorParent document */
  update?: InputMaybe<EmailValidatorParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single EmailValidatorParent document */
  upsert?: InputMaybe<EmailValidatorParentUpsertWithNestedWhereUniqueInput>;
};

export type EmailValidatorParentUpdateWithNestedWhereUniqueAndPositionInput = {
  FormInputEmail?: InputMaybe<FormInputEmailUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type EmailValidatorParentUpdateWithNestedWhereUniqueInput = {
  FormInputEmail?: InputMaybe<FormInputEmailUpdateWithNestedWhereUniqueInput>;
};

export type EmailValidatorParentUpsertWithNestedWhereUniqueAndPositionInput = {
  FormInputEmail?: InputMaybe<FormInputEmailUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type EmailValidatorParentUpsertWithNestedWhereUniqueInput = {
  FormInputEmail?: InputMaybe<FormInputEmailUpsertWithNestedWhereUniqueInput>;
};

export type EmailValidatorParentWhereInput = {
  FormInputEmail?: InputMaybe<FormInputEmailWhereInput>;
};

export type EmailValidatorParentWhereUniqueInput = {
  FormInputEmail?: InputMaybe<FormInputEmailWhereUniqueInput>;
};

export type EmailValidatorUpdateInput = {
  /** errorMessage input for default locale (default) */
  errorMessage?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<EmailValidatorUpdateLocalizationsInput>;
};

export type EmailValidatorUpdateLocalizationDataInput = {
  errorMessage?: InputMaybe<Scalars['String']>;
};

export type EmailValidatorUpdateLocalizationInput = {
  data: EmailValidatorUpdateLocalizationDataInput;
  locale: Locale;
};

export type EmailValidatorUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<EmailValidatorCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<EmailValidatorUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<EmailValidatorUpsertLocalizationInput>>;
};

export type EmailValidatorUpdateManyInlineInput = {
  /** Create and connect multiple EmailValidator component instances */
  create?: InputMaybe<Array<EmailValidatorCreateWithPositionInput>>;
  /** Delete multiple EmailValidator documents */
  delete?: InputMaybe<Array<EmailValidatorWhereUniqueInput>>;
  /** Update multiple EmailValidator component instances */
  update?: InputMaybe<
    Array<EmailValidatorUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple EmailValidator component instances */
  upsert?: InputMaybe<
    Array<EmailValidatorUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type EmailValidatorUpdateManyInput = {
  /** errorMessage input for default locale (default) */
  errorMessage?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<EmailValidatorUpdateManyLocalizationsInput>;
};

export type EmailValidatorUpdateManyLocalizationDataInput = {
  errorMessage?: InputMaybe<Scalars['String']>;
};

export type EmailValidatorUpdateManyLocalizationInput = {
  data: EmailValidatorUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type EmailValidatorUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<EmailValidatorUpdateManyLocalizationInput>>;
};

export type EmailValidatorUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: EmailValidatorUpdateManyInput;
  /** Document search */
  where: EmailValidatorWhereInput;
};

export type EmailValidatorUpdateOneInlineInput = {
  /** Create and connect one EmailValidator document */
  create?: InputMaybe<EmailValidatorCreateInput>;
  /** Delete currently connected EmailValidator document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single EmailValidator document */
  update?: InputMaybe<EmailValidatorUpdateWithNestedWhereUniqueInput>;
  /** Upsert single EmailValidator document */
  upsert?: InputMaybe<EmailValidatorUpsertWithNestedWhereUniqueInput>;
};

export type EmailValidatorUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<EmailValidatorUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: EmailValidatorWhereUniqueInput;
};

export type EmailValidatorUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: EmailValidatorUpdateInput;
  /** Unique document search */
  where: EmailValidatorWhereUniqueInput;
};

export type EmailValidatorUpsertInput = {
  /** Create document if it didn't exist */
  create: EmailValidatorCreateInput;
  /** Update document if it exists */
  update: EmailValidatorUpdateInput;
};

export type EmailValidatorUpsertLocalizationInput = {
  create: EmailValidatorCreateLocalizationDataInput;
  locale: Locale;
  update: EmailValidatorUpdateLocalizationDataInput;
};

export type EmailValidatorUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<EmailValidatorUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: EmailValidatorWhereUniqueInput;
};

export type EmailValidatorUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: EmailValidatorUpsertInput;
  /** Unique document search */
  where: EmailValidatorWhereUniqueInput;
};

/** Identifies documents */
export type EmailValidatorWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EmailValidatorWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EmailValidatorWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EmailValidatorWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  errorMessage_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  errorMessage_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  errorMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  errorMessage_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  errorMessage_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  errorMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  errorMessage_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
};

/** References EmailValidator record uniquely */
export type EmailValidatorWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** RichText embeddable AccordionGroup */
export type EmbedAccordionGroup = Entity &
  Node & {
    __typename?: 'EmbedAccordionGroup';
    accordion: Array<Accordion>;
    /** The time the document was created */
    createdAt: Scalars['DateTime'];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<EmbedAccordionGroup>;
    /** List of EmbedAccordionGroup versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars['ID'];
    /** System Locale field */
    locale: Locale;
    /** Get the other localizations for this document */
    localizations: Array<EmbedAccordionGroup>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    title: Scalars['String'];
    /** The time the document was updated */
    updatedAt: Scalars['DateTime'];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

/** RichText embeddable AccordionGroup */
export type EmbedAccordionGroupAccordionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AccordionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AccordionWhereInput>;
};

/** RichText embeddable AccordionGroup */
export type EmbedAccordionGroupCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

/** RichText embeddable AccordionGroup */
export type EmbedAccordionGroupCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** RichText embeddable AccordionGroup */
export type EmbedAccordionGroupDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

/** RichText embeddable AccordionGroup */
export type EmbedAccordionGroupHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};

/** RichText embeddable AccordionGroup */
export type EmbedAccordionGroupLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

/** RichText embeddable AccordionGroup */
export type EmbedAccordionGroupPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

/** RichText embeddable AccordionGroup */
export type EmbedAccordionGroupPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** RichText embeddable AccordionGroup */
export type EmbedAccordionGroupScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

/** RichText embeddable AccordionGroup */
export type EmbedAccordionGroupUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

/** RichText embeddable AccordionGroup */
export type EmbedAccordionGroupUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type EmbedAccordionGroupConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: EmbedAccordionGroupWhereUniqueInput;
};

/** A connection to a list of items. */
export type EmbedAccordionGroupConnection = {
  __typename?: 'EmbedAccordionGroupConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<EmbedAccordionGroupEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type EmbedAccordionGroupCreateInput = {
  accordion?: InputMaybe<AccordionCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<EmbedAccordionGroupCreateLocalizationsInput>;
  /** title input for default locale (default) */
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type EmbedAccordionGroupCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type EmbedAccordionGroupCreateLocalizationInput = {
  /** Localization input */
  data: EmbedAccordionGroupCreateLocalizationDataInput;
  locale: Locale;
};

export type EmbedAccordionGroupCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<EmbedAccordionGroupCreateLocalizationInput>>;
};

export type EmbedAccordionGroupCreateManyInlineInput = {
  /** Connect multiple existing EmbedAccordionGroup documents */
  connect?: InputMaybe<Array<EmbedAccordionGroupWhereUniqueInput>>;
  /** Create and connect multiple existing EmbedAccordionGroup documents */
  create?: InputMaybe<Array<EmbedAccordionGroupCreateInput>>;
};

export type EmbedAccordionGroupCreateOneInlineInput = {
  /** Connect one existing EmbedAccordionGroup document */
  connect?: InputMaybe<EmbedAccordionGroupWhereUniqueInput>;
  /** Create and connect one EmbedAccordionGroup document */
  create?: InputMaybe<EmbedAccordionGroupCreateInput>;
};

/** An edge in a connection. */
export type EmbedAccordionGroupEdge = {
  __typename?: 'EmbedAccordionGroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: EmbedAccordionGroup;
};

/** Identifies documents */
export type EmbedAccordionGroupManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EmbedAccordionGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EmbedAccordionGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EmbedAccordionGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  accordion_every?: InputMaybe<AccordionWhereInput>;
  accordion_none?: InputMaybe<AccordionWhereInput>;
  accordion_some?: InputMaybe<AccordionWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<EmbedAccordionGroupWhereStageInput>;
  documentInStages_none?: InputMaybe<EmbedAccordionGroupWhereStageInput>;
  documentInStages_some?: InputMaybe<EmbedAccordionGroupWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum EmbedAccordionGroupOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type EmbedAccordionGroupUpdateInput = {
  accordion?: InputMaybe<AccordionUpdateManyInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<EmbedAccordionGroupUpdateLocalizationsInput>;
  /** title input for default locale (default) */
  title?: InputMaybe<Scalars['String']>;
};

export type EmbedAccordionGroupUpdateLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']>;
};

export type EmbedAccordionGroupUpdateLocalizationInput = {
  data: EmbedAccordionGroupUpdateLocalizationDataInput;
  locale: Locale;
};

export type EmbedAccordionGroupUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<EmbedAccordionGroupCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<EmbedAccordionGroupUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<EmbedAccordionGroupUpsertLocalizationInput>>;
};

export type EmbedAccordionGroupUpdateManyInlineInput = {
  /** Connect multiple existing EmbedAccordionGroup documents */
  connect?: InputMaybe<Array<EmbedAccordionGroupConnectInput>>;
  /** Create and connect multiple EmbedAccordionGroup documents */
  create?: InputMaybe<Array<EmbedAccordionGroupCreateInput>>;
  /** Delete multiple EmbedAccordionGroup documents */
  delete?: InputMaybe<Array<EmbedAccordionGroupWhereUniqueInput>>;
  /** Disconnect multiple EmbedAccordionGroup documents */
  disconnect?: InputMaybe<Array<EmbedAccordionGroupWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing EmbedAccordionGroup documents */
  set?: InputMaybe<Array<EmbedAccordionGroupWhereUniqueInput>>;
  /** Update multiple EmbedAccordionGroup documents */
  update?: InputMaybe<
    Array<EmbedAccordionGroupUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple EmbedAccordionGroup documents */
  upsert?: InputMaybe<
    Array<EmbedAccordionGroupUpsertWithNestedWhereUniqueInput>
  >;
};

export type EmbedAccordionGroupUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<EmbedAccordionGroupUpdateManyLocalizationsInput>;
  /** title input for default locale (default) */
  title?: InputMaybe<Scalars['String']>;
};

export type EmbedAccordionGroupUpdateManyLocalizationDataInput = {
  title?: InputMaybe<Scalars['String']>;
};

export type EmbedAccordionGroupUpdateManyLocalizationInput = {
  data: EmbedAccordionGroupUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type EmbedAccordionGroupUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<EmbedAccordionGroupUpdateManyLocalizationInput>>;
};

export type EmbedAccordionGroupUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: EmbedAccordionGroupUpdateManyInput;
  /** Document search */
  where: EmbedAccordionGroupWhereInput;
};

export type EmbedAccordionGroupUpdateOneInlineInput = {
  /** Connect existing EmbedAccordionGroup document */
  connect?: InputMaybe<EmbedAccordionGroupWhereUniqueInput>;
  /** Create and connect one EmbedAccordionGroup document */
  create?: InputMaybe<EmbedAccordionGroupCreateInput>;
  /** Delete currently connected EmbedAccordionGroup document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected EmbedAccordionGroup document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single EmbedAccordionGroup document */
  update?: InputMaybe<EmbedAccordionGroupUpdateWithNestedWhereUniqueInput>;
  /** Upsert single EmbedAccordionGroup document */
  upsert?: InputMaybe<EmbedAccordionGroupUpsertWithNestedWhereUniqueInput>;
};

export type EmbedAccordionGroupUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: EmbedAccordionGroupUpdateInput;
  /** Unique document search */
  where: EmbedAccordionGroupWhereUniqueInput;
};

export type EmbedAccordionGroupUpsertInput = {
  /** Create document if it didn't exist */
  create: EmbedAccordionGroupCreateInput;
  /** Update document if it exists */
  update: EmbedAccordionGroupUpdateInput;
};

export type EmbedAccordionGroupUpsertLocalizationInput = {
  create: EmbedAccordionGroupCreateLocalizationDataInput;
  locale: Locale;
  update: EmbedAccordionGroupUpdateLocalizationDataInput;
};

export type EmbedAccordionGroupUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: EmbedAccordionGroupUpsertInput;
  /** Unique document search */
  where: EmbedAccordionGroupWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type EmbedAccordionGroupWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type EmbedAccordionGroupWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EmbedAccordionGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EmbedAccordionGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EmbedAccordionGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  accordion_every?: InputMaybe<AccordionWhereInput>;
  accordion_none?: InputMaybe<AccordionWhereInput>;
  accordion_some?: InputMaybe<AccordionWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<EmbedAccordionGroupWhereStageInput>;
  documentInStages_none?: InputMaybe<EmbedAccordionGroupWhereStageInput>;
  documentInStages_some?: InputMaybe<EmbedAccordionGroupWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  title?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type EmbedAccordionGroupWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EmbedAccordionGroupWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EmbedAccordionGroupWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EmbedAccordionGroupWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<EmbedAccordionGroupWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References EmbedAccordionGroup record uniquely */
export type EmbedAccordionGroupWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Used to embed custom content inside "Rich Text Editor" */
export type EmbedCustomContent = Entity &
  Node & {
    __typename?: 'EmbedCustomContent';
    /** The time the document was created */
    createdAt: Scalars['DateTime'];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<EmbedCustomContent>;
    /** List of EmbedCustomContent versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars['ID'];
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    title: Scalars['String'];
    /** The time the document was updated */
    updatedAt: Scalars['DateTime'];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

/** Used to embed custom content inside "Rich Text Editor" */
export type EmbedCustomContentCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Used to embed custom content inside "Rich Text Editor" */
export type EmbedCustomContentDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

/** Used to embed custom content inside "Rich Text Editor" */
export type EmbedCustomContentHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};

/** Used to embed custom content inside "Rich Text Editor" */
export type EmbedCustomContentPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Used to embed custom content inside "Rich Text Editor" */
export type EmbedCustomContentScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

/** Used to embed custom content inside "Rich Text Editor" */
export type EmbedCustomContentUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type EmbedCustomContentConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: EmbedCustomContentWhereUniqueInput;
};

/** A connection to a list of items. */
export type EmbedCustomContentConnection = {
  __typename?: 'EmbedCustomContentConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<EmbedCustomContentEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type EmbedCustomContentCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type EmbedCustomContentCreateManyInlineInput = {
  /** Connect multiple existing EmbedCustomContent documents */
  connect?: InputMaybe<Array<EmbedCustomContentWhereUniqueInput>>;
  /** Create and connect multiple existing EmbedCustomContent documents */
  create?: InputMaybe<Array<EmbedCustomContentCreateInput>>;
};

export type EmbedCustomContentCreateOneInlineInput = {
  /** Connect one existing EmbedCustomContent document */
  connect?: InputMaybe<EmbedCustomContentWhereUniqueInput>;
  /** Create and connect one EmbedCustomContent document */
  create?: InputMaybe<EmbedCustomContentCreateInput>;
};

/** An edge in a connection. */
export type EmbedCustomContentEdge = {
  __typename?: 'EmbedCustomContentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: EmbedCustomContent;
};

/** Identifies documents */
export type EmbedCustomContentManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EmbedCustomContentWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EmbedCustomContentWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EmbedCustomContentWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<EmbedCustomContentWhereStageInput>;
  documentInStages_none?: InputMaybe<EmbedCustomContentWhereStageInput>;
  documentInStages_some?: InputMaybe<EmbedCustomContentWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  title?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum EmbedCustomContentOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type EmbedCustomContentUpdateInput = {
  title?: InputMaybe<Scalars['String']>;
};

export type EmbedCustomContentUpdateManyInlineInput = {
  /** Connect multiple existing EmbedCustomContent documents */
  connect?: InputMaybe<Array<EmbedCustomContentConnectInput>>;
  /** Create and connect multiple EmbedCustomContent documents */
  create?: InputMaybe<Array<EmbedCustomContentCreateInput>>;
  /** Delete multiple EmbedCustomContent documents */
  delete?: InputMaybe<Array<EmbedCustomContentWhereUniqueInput>>;
  /** Disconnect multiple EmbedCustomContent documents */
  disconnect?: InputMaybe<Array<EmbedCustomContentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing EmbedCustomContent documents */
  set?: InputMaybe<Array<EmbedCustomContentWhereUniqueInput>>;
  /** Update multiple EmbedCustomContent documents */
  update?: InputMaybe<
    Array<EmbedCustomContentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple EmbedCustomContent documents */
  upsert?: InputMaybe<
    Array<EmbedCustomContentUpsertWithNestedWhereUniqueInput>
  >;
};

export type EmbedCustomContentUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']>;
};

export type EmbedCustomContentUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: EmbedCustomContentUpdateManyInput;
  /** Document search */
  where: EmbedCustomContentWhereInput;
};

export type EmbedCustomContentUpdateOneInlineInput = {
  /** Connect existing EmbedCustomContent document */
  connect?: InputMaybe<EmbedCustomContentWhereUniqueInput>;
  /** Create and connect one EmbedCustomContent document */
  create?: InputMaybe<EmbedCustomContentCreateInput>;
  /** Delete currently connected EmbedCustomContent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected EmbedCustomContent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single EmbedCustomContent document */
  update?: InputMaybe<EmbedCustomContentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single EmbedCustomContent document */
  upsert?: InputMaybe<EmbedCustomContentUpsertWithNestedWhereUniqueInput>;
};

export type EmbedCustomContentUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: EmbedCustomContentUpdateInput;
  /** Unique document search */
  where: EmbedCustomContentWhereUniqueInput;
};

export type EmbedCustomContentUpsertInput = {
  /** Create document if it didn't exist */
  create: EmbedCustomContentCreateInput;
  /** Update document if it exists */
  update: EmbedCustomContentUpdateInput;
};

export type EmbedCustomContentUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: EmbedCustomContentUpsertInput;
  /** Unique document search */
  where: EmbedCustomContentWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type EmbedCustomContentWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type EmbedCustomContentWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EmbedCustomContentWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EmbedCustomContentWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EmbedCustomContentWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<EmbedCustomContentWhereStageInput>;
  documentInStages_none?: InputMaybe<EmbedCustomContentWhereStageInput>;
  documentInStages_some?: InputMaybe<EmbedCustomContentWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  title?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type EmbedCustomContentWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EmbedCustomContentWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EmbedCustomContentWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EmbedCustomContentWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<EmbedCustomContentWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References EmbedCustomContent record uniquely */
export type EmbedCustomContentWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Scalars['String']>;
};

/** Used to embed single-line content inside "Rich Text Editor" */
export type EmbedSingleLineText = Entity &
  Node & {
    __typename?: 'EmbedSingleLineText';
    /** If embedded text should be bold or not */
    bold?: Maybe<Scalars['Boolean']>;
    /** The time the document was created */
    createdAt: Scalars['DateTime'];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<EmbedSingleLineText>;
    /** List of EmbedSingleLineText versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars['ID'];
    /** System Locale field */
    locale: Locale;
    /** Get the other localizations for this document */
    localizations: Array<EmbedSingleLineText>;
    name?: Maybe<Scalars['String']>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    slug?: Maybe<Scalars['String']>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars['DateTime'];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
    value?: Maybe<Scalars['String']>;
  };

/** Used to embed single-line content inside "Rich Text Editor" */
export type EmbedSingleLineTextCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

/** Used to embed single-line content inside "Rich Text Editor" */
export type EmbedSingleLineTextCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Used to embed single-line content inside "Rich Text Editor" */
export type EmbedSingleLineTextDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

/** Used to embed single-line content inside "Rich Text Editor" */
export type EmbedSingleLineTextHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};

/** Used to embed single-line content inside "Rich Text Editor" */
export type EmbedSingleLineTextLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

/** Used to embed single-line content inside "Rich Text Editor" */
export type EmbedSingleLineTextPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

/** Used to embed single-line content inside "Rich Text Editor" */
export type EmbedSingleLineTextPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Used to embed single-line content inside "Rich Text Editor" */
export type EmbedSingleLineTextScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

/** Used to embed single-line content inside "Rich Text Editor" */
export type EmbedSingleLineTextUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

/** Used to embed single-line content inside "Rich Text Editor" */
export type EmbedSingleLineTextUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type EmbedSingleLineTextConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: EmbedSingleLineTextWhereUniqueInput;
};

/** A connection to a list of items. */
export type EmbedSingleLineTextConnection = {
  __typename?: 'EmbedSingleLineTextConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<EmbedSingleLineTextEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type EmbedSingleLineTextCreateInput = {
  bold?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<EmbedSingleLineTextCreateLocalizationsInput>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** value input for default locale (default) */
  value?: InputMaybe<Scalars['String']>;
};

export type EmbedSingleLineTextCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  value?: InputMaybe<Scalars['String']>;
};

export type EmbedSingleLineTextCreateLocalizationInput = {
  /** Localization input */
  data: EmbedSingleLineTextCreateLocalizationDataInput;
  locale: Locale;
};

export type EmbedSingleLineTextCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<EmbedSingleLineTextCreateLocalizationInput>>;
};

export type EmbedSingleLineTextCreateManyInlineInput = {
  /** Connect multiple existing EmbedSingleLineText documents */
  connect?: InputMaybe<Array<EmbedSingleLineTextWhereUniqueInput>>;
  /** Create and connect multiple existing EmbedSingleLineText documents */
  create?: InputMaybe<Array<EmbedSingleLineTextCreateInput>>;
};

export type EmbedSingleLineTextCreateOneInlineInput = {
  /** Connect one existing EmbedSingleLineText document */
  connect?: InputMaybe<EmbedSingleLineTextWhereUniqueInput>;
  /** Create and connect one EmbedSingleLineText document */
  create?: InputMaybe<EmbedSingleLineTextCreateInput>;
};

/** An edge in a connection. */
export type EmbedSingleLineTextEdge = {
  __typename?: 'EmbedSingleLineTextEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: EmbedSingleLineText;
};

/** Identifies documents */
export type EmbedSingleLineTextManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EmbedSingleLineTextWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EmbedSingleLineTextWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EmbedSingleLineTextWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  bold?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  bold_not?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<EmbedSingleLineTextWhereStageInput>;
  documentInStages_none?: InputMaybe<EmbedSingleLineTextWhereStageInput>;
  documentInStages_some?: InputMaybe<EmbedSingleLineTextWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  slug?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum EmbedSingleLineTextOrderByInput {
  BoldAsc = 'bold_ASC',
  BoldDesc = 'bold_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
}

export type EmbedSingleLineTextUpdateInput = {
  bold?: InputMaybe<Scalars['Boolean']>;
  /** Manage document localizations */
  localizations?: InputMaybe<EmbedSingleLineTextUpdateLocalizationsInput>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  /** value input for default locale (default) */
  value?: InputMaybe<Scalars['String']>;
};

export type EmbedSingleLineTextUpdateLocalizationDataInput = {
  value?: InputMaybe<Scalars['String']>;
};

export type EmbedSingleLineTextUpdateLocalizationInput = {
  data: EmbedSingleLineTextUpdateLocalizationDataInput;
  locale: Locale;
};

export type EmbedSingleLineTextUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<EmbedSingleLineTextCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<EmbedSingleLineTextUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<EmbedSingleLineTextUpsertLocalizationInput>>;
};

export type EmbedSingleLineTextUpdateManyInlineInput = {
  /** Connect multiple existing EmbedSingleLineText documents */
  connect?: InputMaybe<Array<EmbedSingleLineTextConnectInput>>;
  /** Create and connect multiple EmbedSingleLineText documents */
  create?: InputMaybe<Array<EmbedSingleLineTextCreateInput>>;
  /** Delete multiple EmbedSingleLineText documents */
  delete?: InputMaybe<Array<EmbedSingleLineTextWhereUniqueInput>>;
  /** Disconnect multiple EmbedSingleLineText documents */
  disconnect?: InputMaybe<Array<EmbedSingleLineTextWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing EmbedSingleLineText documents */
  set?: InputMaybe<Array<EmbedSingleLineTextWhereUniqueInput>>;
  /** Update multiple EmbedSingleLineText documents */
  update?: InputMaybe<
    Array<EmbedSingleLineTextUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple EmbedSingleLineText documents */
  upsert?: InputMaybe<
    Array<EmbedSingleLineTextUpsertWithNestedWhereUniqueInput>
  >;
};

export type EmbedSingleLineTextUpdateManyInput = {
  bold?: InputMaybe<Scalars['Boolean']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<EmbedSingleLineTextUpdateManyLocalizationsInput>;
  name?: InputMaybe<Scalars['String']>;
  /** value input for default locale (default) */
  value?: InputMaybe<Scalars['String']>;
};

export type EmbedSingleLineTextUpdateManyLocalizationDataInput = {
  value?: InputMaybe<Scalars['String']>;
};

export type EmbedSingleLineTextUpdateManyLocalizationInput = {
  data: EmbedSingleLineTextUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type EmbedSingleLineTextUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<EmbedSingleLineTextUpdateManyLocalizationInput>>;
};

export type EmbedSingleLineTextUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: EmbedSingleLineTextUpdateManyInput;
  /** Document search */
  where: EmbedSingleLineTextWhereInput;
};

export type EmbedSingleLineTextUpdateOneInlineInput = {
  /** Connect existing EmbedSingleLineText document */
  connect?: InputMaybe<EmbedSingleLineTextWhereUniqueInput>;
  /** Create and connect one EmbedSingleLineText document */
  create?: InputMaybe<EmbedSingleLineTextCreateInput>;
  /** Delete currently connected EmbedSingleLineText document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected EmbedSingleLineText document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single EmbedSingleLineText document */
  update?: InputMaybe<EmbedSingleLineTextUpdateWithNestedWhereUniqueInput>;
  /** Upsert single EmbedSingleLineText document */
  upsert?: InputMaybe<EmbedSingleLineTextUpsertWithNestedWhereUniqueInput>;
};

export type EmbedSingleLineTextUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: EmbedSingleLineTextUpdateInput;
  /** Unique document search */
  where: EmbedSingleLineTextWhereUniqueInput;
};

export type EmbedSingleLineTextUpsertInput = {
  /** Create document if it didn't exist */
  create: EmbedSingleLineTextCreateInput;
  /** Update document if it exists */
  update: EmbedSingleLineTextUpdateInput;
};

export type EmbedSingleLineTextUpsertLocalizationInput = {
  create: EmbedSingleLineTextCreateLocalizationDataInput;
  locale: Locale;
  update: EmbedSingleLineTextUpdateLocalizationDataInput;
};

export type EmbedSingleLineTextUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: EmbedSingleLineTextUpsertInput;
  /** Unique document search */
  where: EmbedSingleLineTextWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type EmbedSingleLineTextWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type EmbedSingleLineTextWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EmbedSingleLineTextWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EmbedSingleLineTextWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EmbedSingleLineTextWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  bold?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  bold_not?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<EmbedSingleLineTextWhereStageInput>;
  documentInStages_none?: InputMaybe<EmbedSingleLineTextWhereStageInput>;
  documentInStages_some?: InputMaybe<EmbedSingleLineTextWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  slug?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  value?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  value_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  value_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  value_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  value_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  value_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  value_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  value_starts_with?: InputMaybe<Scalars['String']>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type EmbedSingleLineTextWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<EmbedSingleLineTextWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<EmbedSingleLineTextWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<EmbedSingleLineTextWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<EmbedSingleLineTextWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References EmbedSingleLineText record uniquely */
export type EmbedSingleLineTextWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
};

/** An object with an ID */
export type Entity = {
  /** The id of the object. */
  id: Scalars['ID'];
  /** The Stage of an object */
  stage: Stage;
};

/** This enumeration holds all typenames that implement the Entity interface. Components and models implement the Entity interface. */
export enum EntityTypeName {
  Accordion = 'Accordion',
  AccordionGroup = 'AccordionGroup',
  Article = 'Article',
  /** A minimal container component used to add additional content to an Article. */
  ArticleAdditionalContent = 'ArticleAdditionalContent',
  /** Asset system model */
  Asset = 'Asset',
  Author = 'Author',
  /** This component is used for badge */
  Badge = 'Badge',
  BannerItem = 'BannerItem',
  BannerItemList = 'BannerItemList',
  BraidComponent = 'BraidComponent',
  /** Used to embed Brightcove videos. */
  BrightcoveVideo = 'BrightcoveVideo',
  /** Bug bounty form. */
  BugcrowdForm = 'BugcrowdForm',
  CActionGroup = 'CActionGroup',
  /** Alert component */
  CAlert = 'CAlert',
  CBanner = 'CBanner',
  CBannerArticle = 'CBannerArticle',
  /** Banner for the Award sites (SARA & STAR) */
  CBannerAward = 'CBannerAward',
  CBannerInline = 'CBannerInline',
  CBannerPrimary = 'CBannerPrimary',
  /** Hero banner for Hirer shopfront homepage */
  CBannerShopfront = 'CBannerShopfront',
  CBlock = 'CBlock',
  CBox = 'CBox',
  CButtonLink = 'CButtonLink',
  CCardGroup = 'CCardGroup',
  /** Job Ad Card component */
  CCardJobAd = 'CCardJobAd',
  CCardPrimary = 'CCardPrimary',
  CContainer = 'CContainer',
  CCustomComponent = 'CCustomComponent',
  /** A wrapper for Form model */
  CForm = 'CForm',
  CGallery = 'CGallery',
  CHeading = 'CHeading',
  CIconBlock = 'CIconBlock',
  CIconBlockGroup = 'CIconBlockGroup',
  CImageBlock = 'CImageBlock',
  CImageBlockGroup = 'CImageBlockGroup',
  CImageLink = 'CImageLink',
  CInline = 'CInline',
  CParagraph = 'CParagraph',
  CStepBlock = 'CStepBlock',
  CStepBlockGroup = 'CStepBlockGroup',
  CTestimonial = 'CTestimonial',
  CTestimonialGroup = 'CTestimonialGroup',
  CTextLink = 'CTextLink',
  CTile = 'CTile',
  Category = 'Category',
  CustomFooter = 'CustomFooter',
  CustomHeader = 'CustomHeader',
  Datalab = 'Datalab',
  /** Button for launching content in a modal/Dialogue window */
  DialogButton = 'DialogButton',
  DialogItem = 'DialogItem',
  /** Display a video thumbnail that opens in a Dialog when clicked */
  DialogVideo = 'DialogVideo',
  /** Represent disclosure content. */
  Disclosure = 'Disclosure',
  Divider = 'Divider',
  DocumentItem = 'DocumentItem',
  /** Primarily used as a list of documents. */
  DocumentList = 'DocumentList',
  /** Validator used for FormInputEmail to validate email input */
  EmailValidator = 'EmailValidator',
  /** RichText embeddable AccordionGroup */
  EmbedAccordionGroup = 'EmbedAccordionGroup',
  /** Used to embed custom content inside "Rich Text Editor" */
  EmbedCustomContent = 'EmbedCustomContent',
  /** Used to embed single-line content inside "Rich Text Editor" */
  EmbedSingleLineText = 'EmbedSingleLineText',
  ExternalUrl = 'ExternalUrl',
  /** Reusable component for options field in FormSelect and FormCheckboxGroup */
  FieldOption = 'FieldOption',
  /** Reusable model for C_Form component */
  Form = 'Form',
  /** Component for checkbox */
  FormCheckbox = 'FormCheckbox',
  /** Component for checkbox group */
  FormCheckboxGroup = 'FormCheckboxGroup',
  /** Component for textfield with email type */
  FormInputEmail = 'FormInputEmail',
  /** Component for textfield with number type */
  FormInputNumber = 'FormInputNumber',
  /** Component for textfield with text type */
  FormInputText = 'FormInputText',
  /** Component for dropdown */
  FormSelect = 'FormSelect',
  /** Component for textarea */
  FormTextarea = 'FormTextarea',
  /** This component is used for single image in brand product page. */
  Image = 'Image',
  /** Divider component for the Inline Banner */
  InlineDivider = 'InlineDivider',
  InterviewBuilder = 'InterviewBuilder',
  /** component to select the type of investor information */
  InvestorInformation = 'InvestorInformation',
  /** Custom Component for displaying Job Ad features in a chart format */
  JobAdFeatureChart = 'JobAdFeatureChart',
  /** Display a vertical 'stepper' list of short text content */
  KeyStepsBlock = 'KeyStepsBlock',
  /** Shows the three latest articles with a link to show more. */
  LatestArticlesByCategory = 'LatestArticlesByCategory',
  Link = 'Link',
  LoaDataLab = 'LoaDataLab',
  /** Model for controlling banners, alerts, and notices across hirer and candidate platforms. */
  ManagedContentBanner = 'ManagedContentBanner',
  /** Validator used for FormCheckboxGroup to validate max item allowed to be checked */
  MaxCheckedItemValidator = 'MaxCheckedItemValidator',
  /** Validator used for FormInputText & FormTextarea to validate max characters allowed */
  MaxLengthValidator = 'MaxLengthValidator',
  /** Validator used for FormInputNumber to validate max number allowed */
  MaxNumberValidator = 'MaxNumberValidator',
  MigrationHistory = 'MigrationHistory',
  MigrationState = 'MigrationState',
  /** Validator used for FormCheckboxGroup to validate min item allowed to be checked */
  MinCheckedItemValidator = 'MinCheckedItemValidator',
  /** Validator used for FormInputText & FormTextarea to validate min characters allowed */
  MinLengthValidator = 'MinLengthValidator',
  /** Validator used for FormInputNumber to validate min number allowed */
  MinNumberValidator = 'MinNumberValidator',
  /** A number component with numerical text and unit text display with dynamic size */
  NumericalBlock = 'NumericalBlock',
  /** Describes page content layout. */
  Page = 'Page',
  /** Podcast information and links */
  Podcast = 'Podcast',
  ProfileBio = 'ProfileBio',
  /** Custom Component for /profile-privacy */
  ProfileVisibilityCardGroup = 'ProfileVisibilityCardGroup',
  /** Promo banner for displaying promotional content */
  PromotionalBanner = 'PromotionalBanner',
  ResponsiveColumnValue = 'ResponsiveColumnValue',
  ResponsiveSpace = 'ResponsiveSpace',
  RichTextEditor = 'RichTextEditor',
  /** A wrapper for the "Rich Text Editor" model so we can use it inside "Blocks". */
  RichTextEditorWrapper = 'RichTextEditorWrapper',
  /** Scheduled Operation system model */
  ScheduledOperation = 'ScheduledOperation',
  /** Scheduled Release system model */
  ScheduledRelease = 'ScheduledRelease',
  /** This component is used for searching articles or pages. */
  SearchBox = 'SearchBox',
  Section = 'Section',
  SectionFooter = 'SectionFooter',
  /** Options for when a Section has chosen to customise the Header */
  SectionHeader = 'SectionHeader',
  /** The standard SEEK Group header */
  SeekHeader = 'SeekHeader',
  Seo = 'Seo',
  Site = 'Site',
  SiteUsersConnection = 'SiteUsersConnection',
  SocialMediaShare = 'SocialMediaShare',
  Space = 'Space',
  Tab = 'Tab',
  TabGroup = 'TabGroup',
  Testimonial = 'Testimonial',
  TestimonialGroup = 'TestimonialGroup',
  /** A parent link with a selection of children links. Used for displaying a dropdown menu in navigation components. */
  TextLinkGroup = 'TextLinkGroup',
  TipsCard = 'TipsCard',
  TipsCardGroup = 'TipsCardGroup',
  Topic = 'Topic',
  TopicList = 'TopicList',
  /** User system model */
  User = 'User',
  Video = 'Video',
  /** This component is used for video block */
  VideoBlock = 'VideoBlock',
  VideoDialogGroup = 'VideoDialogGroup',
  /** WebForm */
  WebForm = 'WebForm',
  /** Used to embed YouTube videos. */
  YouTubeVideo = 'YouTubeVideo',
}

/** Allows to specify input to query models and components directly */
export type EntityWhereInput = {
  /** The ID of an object */
  id: Scalars['ID'];
  locale?: InputMaybe<Locale>;
  stage: Stage;
  /** The Type name of an object */
  typename: EntityTypeName;
};

export type ExternalUrl = Entity &
  Node & {
    __typename?: 'ExternalUrl';
    /** The time the document was created */
    createdAt: Scalars['DateTime'];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<ExternalUrl>;
    /** List of ExternalUrl versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars['ID'];
    /** System Locale field */
    locale: Locale;
    /** Get the other localizations for this document */
    localizations: Array<ExternalUrl>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars['DateTime'];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
    url?: Maybe<Scalars['String']>;
  };

export type ExternalUrlCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type ExternalUrlCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ExternalUrlDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type ExternalUrlHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};

export type ExternalUrlLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type ExternalUrlPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type ExternalUrlPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ExternalUrlScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type ExternalUrlUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type ExternalUrlUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ExternalUrlConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ExternalUrlWhereUniqueInput;
};

/** A connection to a list of items. */
export type ExternalUrlConnection = {
  __typename?: 'ExternalUrlConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ExternalUrlEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ExternalUrlCreateInput = {
  cm0do2z0j3mi406uo5vt70v79?: InputMaybe<DocumentItemCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<ExternalUrlCreateLocalizationsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** url input for default locale (default) */
  url?: InputMaybe<Scalars['String']>;
};

export type ExternalUrlCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  url?: InputMaybe<Scalars['String']>;
};

export type ExternalUrlCreateLocalizationInput = {
  /** Localization input */
  data: ExternalUrlCreateLocalizationDataInput;
  locale: Locale;
};

export type ExternalUrlCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<ExternalUrlCreateLocalizationInput>>;
};

export type ExternalUrlCreateManyInlineInput = {
  /** Connect multiple existing ExternalUrl documents */
  connect?: InputMaybe<Array<ExternalUrlWhereUniqueInput>>;
  /** Create and connect multiple existing ExternalUrl documents */
  create?: InputMaybe<Array<ExternalUrlCreateInput>>;
};

export type ExternalUrlCreateOneInlineInput = {
  /** Connect one existing ExternalUrl document */
  connect?: InputMaybe<ExternalUrlWhereUniqueInput>;
  /** Create and connect one ExternalUrl document */
  create?: InputMaybe<ExternalUrlCreateInput>;
};

/** An edge in a connection. */
export type ExternalUrlEdge = {
  __typename?: 'ExternalUrlEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ExternalUrl;
};

/** Identifies documents */
export type ExternalUrlManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ExternalUrlWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ExternalUrlWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ExternalUrlWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<ExternalUrlWhereStageInput>;
  documentInStages_none?: InputMaybe<ExternalUrlWhereStageInput>;
  documentInStages_some?: InputMaybe<ExternalUrlWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum ExternalUrlOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
}

export type ExternalUrlUpdateInput = {
  cm0do2z0j3mi406uo5vt70v79?: InputMaybe<DocumentItemUpdateManyInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<ExternalUrlUpdateLocalizationsInput>;
  /** url input for default locale (default) */
  url?: InputMaybe<Scalars['String']>;
};

export type ExternalUrlUpdateLocalizationDataInput = {
  url?: InputMaybe<Scalars['String']>;
};

export type ExternalUrlUpdateLocalizationInput = {
  data: ExternalUrlUpdateLocalizationDataInput;
  locale: Locale;
};

export type ExternalUrlUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<ExternalUrlCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<ExternalUrlUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<ExternalUrlUpsertLocalizationInput>>;
};

export type ExternalUrlUpdateManyInlineInput = {
  /** Connect multiple existing ExternalUrl documents */
  connect?: InputMaybe<Array<ExternalUrlConnectInput>>;
  /** Create and connect multiple ExternalUrl documents */
  create?: InputMaybe<Array<ExternalUrlCreateInput>>;
  /** Delete multiple ExternalUrl documents */
  delete?: InputMaybe<Array<ExternalUrlWhereUniqueInput>>;
  /** Disconnect multiple ExternalUrl documents */
  disconnect?: InputMaybe<Array<ExternalUrlWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing ExternalUrl documents */
  set?: InputMaybe<Array<ExternalUrlWhereUniqueInput>>;
  /** Update multiple ExternalUrl documents */
  update?: InputMaybe<Array<ExternalUrlUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple ExternalUrl documents */
  upsert?: InputMaybe<Array<ExternalUrlUpsertWithNestedWhereUniqueInput>>;
};

export type ExternalUrlUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']>;
};

export type ExternalUrlUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ExternalUrlUpdateManyInput;
  /** Document search */
  where: ExternalUrlWhereInput;
};

export type ExternalUrlUpdateOneInlineInput = {
  /** Connect existing ExternalUrl document */
  connect?: InputMaybe<ExternalUrlWhereUniqueInput>;
  /** Create and connect one ExternalUrl document */
  create?: InputMaybe<ExternalUrlCreateInput>;
  /** Delete currently connected ExternalUrl document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected ExternalUrl document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single ExternalUrl document */
  update?: InputMaybe<ExternalUrlUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ExternalUrl document */
  upsert?: InputMaybe<ExternalUrlUpsertWithNestedWhereUniqueInput>;
};

export type ExternalUrlUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ExternalUrlUpdateInput;
  /** Unique document search */
  where: ExternalUrlWhereUniqueInput;
};

export type ExternalUrlUpsertInput = {
  /** Create document if it didn't exist */
  create: ExternalUrlCreateInput;
  /** Update document if it exists */
  update: ExternalUrlUpdateInput;
};

export type ExternalUrlUpsertLocalizationInput = {
  create: ExternalUrlCreateLocalizationDataInput;
  locale: Locale;
  update: ExternalUrlUpdateLocalizationDataInput;
};

export type ExternalUrlUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ExternalUrlUpsertInput;
  /** Unique document search */
  where: ExternalUrlWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type ExternalUrlWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type ExternalUrlWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ExternalUrlWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ExternalUrlWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ExternalUrlWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<ExternalUrlWhereStageInput>;
  documentInStages_none?: InputMaybe<ExternalUrlWhereStageInput>;
  documentInStages_some?: InputMaybe<ExternalUrlWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  url?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  url_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  url_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  url_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  url_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  url_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  url_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  url_starts_with?: InputMaybe<Scalars['String']>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type ExternalUrlWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ExternalUrlWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ExternalUrlWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ExternalUrlWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<ExternalUrlWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References ExternalUrl record uniquely */
export type ExternalUrlWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Reusable component for options field in FormSelect and FormCheckboxGroup */
export type FieldOption = Entity & {
  __typename?: 'FieldOption';
  /** The unique identifier */
  id: Scalars['ID'];
  /** Field label that will appear to the user */
  label: Scalars['String'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<FieldOption>;
  /** System stage field */
  stage: Stage;
  /** This is the value that will be attached to the JSON payload.  */
  value: Scalars['String'];
};

/** Reusable component for options field in FormSelect and FormCheckboxGroup */
export type FieldOptionLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type FieldOptionConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: FieldOptionWhereUniqueInput;
};

/** A connection to a list of items. */
export type FieldOptionConnection = {
  __typename?: 'FieldOptionConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<FieldOptionEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type FieldOptionCreateInput = {
  /** label input for default locale (default) */
  label: Scalars['String'];
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<FieldOptionCreateLocalizationsInput>;
  value: Scalars['String'];
};

export type FieldOptionCreateLocalizationDataInput = {
  label: Scalars['String'];
};

export type FieldOptionCreateLocalizationInput = {
  /** Localization input */
  data: FieldOptionCreateLocalizationDataInput;
  locale: Locale;
};

export type FieldOptionCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<FieldOptionCreateLocalizationInput>>;
};

export type FieldOptionCreateManyInlineInput = {
  /** Create and connect multiple existing FieldOption documents */
  create?: InputMaybe<Array<FieldOptionCreateInput>>;
};

export type FieldOptionCreateOneInlineInput = {
  /** Create and connect one FieldOption document */
  create?: InputMaybe<FieldOptionCreateInput>;
};

export type FieldOptionCreateWithPositionInput = {
  /** Document to create */
  data: FieldOptionCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type FieldOptionEdge = {
  __typename?: 'FieldOptionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: FieldOption;
};

/** Identifies documents */
export type FieldOptionManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FieldOptionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FieldOptionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FieldOptionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  value?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  value_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  value_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  value_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  value_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  value_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  value_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  value_starts_with?: InputMaybe<Scalars['String']>;
};

export enum FieldOptionOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
}

export type FieldOptionParent = FormCheckboxGroup | FormSelect;

export type FieldOptionParentConnectInput = {
  FormCheckboxGroup?: InputMaybe<FormCheckboxGroupConnectInput>;
  FormSelect?: InputMaybe<FormSelectConnectInput>;
};

export type FieldOptionParentCreateInput = {
  FormCheckboxGroup?: InputMaybe<FormCheckboxGroupCreateInput>;
  FormSelect?: InputMaybe<FormSelectCreateInput>;
};

export type FieldOptionParentCreateManyInlineInput = {
  /** Create and connect multiple existing FieldOptionParent documents */
  create?: InputMaybe<Array<FieldOptionParentCreateInput>>;
};

export type FieldOptionParentCreateOneInlineInput = {
  /** Create and connect one FieldOptionParent document */
  create?: InputMaybe<FieldOptionParentCreateInput>;
};

export type FieldOptionParentCreateWithPositionInput = {
  FormCheckboxGroup?: InputMaybe<FormCheckboxGroupCreateWithPositionInput>;
  FormSelect?: InputMaybe<FormSelectCreateWithPositionInput>;
};

export type FieldOptionParentUpdateInput = {
  FormCheckboxGroup?: InputMaybe<FormCheckboxGroupUpdateInput>;
  FormSelect?: InputMaybe<FormSelectUpdateInput>;
};

export type FieldOptionParentUpdateManyInlineInput = {
  /** Create and connect multiple FieldOptionParent component instances */
  create?: InputMaybe<Array<FieldOptionParentCreateWithPositionInput>>;
  /** Delete multiple FieldOptionParent documents */
  delete?: InputMaybe<Array<FieldOptionParentWhereUniqueInput>>;
  /** Update multiple FieldOptionParent component instances */
  update?: InputMaybe<
    Array<FieldOptionParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple FieldOptionParent component instances */
  upsert?: InputMaybe<
    Array<FieldOptionParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type FieldOptionParentUpdateManyWithNestedWhereInput = {
  FormCheckboxGroup?: InputMaybe<FormCheckboxGroupUpdateManyWithNestedWhereInput>;
  FormSelect?: InputMaybe<FormSelectUpdateManyWithNestedWhereInput>;
};

export type FieldOptionParentUpdateOneInlineInput = {
  /** Create and connect one FieldOptionParent document */
  create?: InputMaybe<FieldOptionParentCreateInput>;
  /** Delete currently connected FieldOptionParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single FieldOptionParent document */
  update?: InputMaybe<FieldOptionParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single FieldOptionParent document */
  upsert?: InputMaybe<FieldOptionParentUpsertWithNestedWhereUniqueInput>;
};

export type FieldOptionParentUpdateWithNestedWhereUniqueAndPositionInput = {
  FormCheckboxGroup?: InputMaybe<FormCheckboxGroupUpdateWithNestedWhereUniqueAndPositionInput>;
  FormSelect?: InputMaybe<FormSelectUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type FieldOptionParentUpdateWithNestedWhereUniqueInput = {
  FormCheckboxGroup?: InputMaybe<FormCheckboxGroupUpdateWithNestedWhereUniqueInput>;
  FormSelect?: InputMaybe<FormSelectUpdateWithNestedWhereUniqueInput>;
};

export type FieldOptionParentUpsertWithNestedWhereUniqueAndPositionInput = {
  FormCheckboxGroup?: InputMaybe<FormCheckboxGroupUpsertWithNestedWhereUniqueAndPositionInput>;
  FormSelect?: InputMaybe<FormSelectUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type FieldOptionParentUpsertWithNestedWhereUniqueInput = {
  FormCheckboxGroup?: InputMaybe<FormCheckboxGroupUpsertWithNestedWhereUniqueInput>;
  FormSelect?: InputMaybe<FormSelectUpsertWithNestedWhereUniqueInput>;
};

export type FieldOptionParentWhereInput = {
  FormCheckboxGroup?: InputMaybe<FormCheckboxGroupWhereInput>;
  FormSelect?: InputMaybe<FormSelectWhereInput>;
};

export type FieldOptionParentWhereUniqueInput = {
  FormCheckboxGroup?: InputMaybe<FormCheckboxGroupWhereUniqueInput>;
  FormSelect?: InputMaybe<FormSelectWhereUniqueInput>;
};

export type FieldOptionUpdateInput = {
  /** label input for default locale (default) */
  label?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<FieldOptionUpdateLocalizationsInput>;
  value?: InputMaybe<Scalars['String']>;
};

export type FieldOptionUpdateLocalizationDataInput = {
  label?: InputMaybe<Scalars['String']>;
};

export type FieldOptionUpdateLocalizationInput = {
  data: FieldOptionUpdateLocalizationDataInput;
  locale: Locale;
};

export type FieldOptionUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<FieldOptionCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<FieldOptionUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<FieldOptionUpsertLocalizationInput>>;
};

export type FieldOptionUpdateManyInlineInput = {
  /** Create and connect multiple FieldOption component instances */
  create?: InputMaybe<Array<FieldOptionCreateWithPositionInput>>;
  /** Delete multiple FieldOption documents */
  delete?: InputMaybe<Array<FieldOptionWhereUniqueInput>>;
  /** Update multiple FieldOption component instances */
  update?: InputMaybe<
    Array<FieldOptionUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple FieldOption component instances */
  upsert?: InputMaybe<
    Array<FieldOptionUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type FieldOptionUpdateManyInput = {
  /** label input for default locale (default) */
  label?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<FieldOptionUpdateManyLocalizationsInput>;
  value?: InputMaybe<Scalars['String']>;
};

export type FieldOptionUpdateManyLocalizationDataInput = {
  label?: InputMaybe<Scalars['String']>;
};

export type FieldOptionUpdateManyLocalizationInput = {
  data: FieldOptionUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type FieldOptionUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<FieldOptionUpdateManyLocalizationInput>>;
};

export type FieldOptionUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: FieldOptionUpdateManyInput;
  /** Document search */
  where: FieldOptionWhereInput;
};

export type FieldOptionUpdateOneInlineInput = {
  /** Create and connect one FieldOption document */
  create?: InputMaybe<FieldOptionCreateInput>;
  /** Delete currently connected FieldOption document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single FieldOption document */
  update?: InputMaybe<FieldOptionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single FieldOption document */
  upsert?: InputMaybe<FieldOptionUpsertWithNestedWhereUniqueInput>;
};

export type FieldOptionUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<FieldOptionUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: FieldOptionWhereUniqueInput;
};

export type FieldOptionUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: FieldOptionUpdateInput;
  /** Unique document search */
  where: FieldOptionWhereUniqueInput;
};

export type FieldOptionUpsertInput = {
  /** Create document if it didn't exist */
  create: FieldOptionCreateInput;
  /** Update document if it exists */
  update: FieldOptionUpdateInput;
};

export type FieldOptionUpsertLocalizationInput = {
  create: FieldOptionCreateLocalizationDataInput;
  locale: Locale;
  update: FieldOptionUpdateLocalizationDataInput;
};

export type FieldOptionUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<FieldOptionUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: FieldOptionWhereUniqueInput;
};

export type FieldOptionUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: FieldOptionUpsertInput;
  /** Unique document search */
  where: FieldOptionWhereUniqueInput;
};

/** Identifies documents */
export type FieldOptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FieldOptionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FieldOptionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FieldOptionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  label?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  label_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  label_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  label_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  label_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  label_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  label_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  label_starts_with?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  value_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  value_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  value_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  value_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  value_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  value_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  value_starts_with?: InputMaybe<Scalars['String']>;
};

/** References FieldOption record uniquely */
export type FieldOptionWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Reusable model for C_Form component */
export type Form = Entity &
  Node & {
    __typename?: 'Form';
    /**
     * The endpoint in WPT Salesforce API, where the data needs to be sent.
     * eg. 'contact-us'
     */
    apiEndpoint: Scalars['String'];
    buttonColour?: Maybe<Colour>;
    columns: Scalars['Int'];
    confirmationMessage?: Maybe<RichText>;
    consentText?: Maybe<RichText>;
    /** The time the document was created */
    createdAt: Scalars['DateTime'];
    /** User that created this document */
    createdBy?: Maybe<User>;
    ctaText?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    disclaimerText?: Maybe<Scalars['String']>;
    /** Get the document in other stages */
    documentInStages: Array<Form>;
    fields: Array<FormfieldsUnion>;
    /** integration form type */
    formType?: Maybe<CFormType>;
    /** List of Form versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars['ID'];
    /** System Locale field */
    locale: Locale;
    /** Get the other localizations for this document */
    localizations: Array<Form>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    sites: Array<Site>;
    skipDefaultLocale?: Maybe<Scalars['Boolean']>;
    slug?: Maybe<Scalars['String']>;
    /** System stage field */
    stage: Stage;
    title?: Maybe<Scalars['String']>;
    /** The time the document was updated */
    updatedAt: Scalars['DateTime'];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

/** Reusable model for C_Form component */
export type FormCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

/** Reusable model for C_Form component */
export type FormCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Reusable model for C_Form component */
export type FormDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

/** Reusable model for C_Form component */
export type FormFieldsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
};

/** Reusable model for C_Form component */
export type FormHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};

/** Reusable model for C_Form component */
export type FormLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

/** Reusable model for C_Form component */
export type FormPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

/** Reusable model for C_Form component */
export type FormPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Reusable model for C_Form component */
export type FormScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

/** Reusable model for C_Form component */
export type FormSitesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<SiteOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SiteWhereInput>;
};

/** Reusable model for C_Form component */
export type FormUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

/** Reusable model for C_Form component */
export type FormUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Component for checkbox */
export type FormCheckbox = Entity & {
  __typename?: 'FormCheckbox';
  /** The unique identifier */
  id: Scalars['ID'];
  /** This is the key in the JSON payload, and it should match the key in the configured payload for Salesforce. */
  key: Scalars['String'];
  label: Scalars['String'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<FormCheckbox>;
  required: Scalars['Boolean'];
  /** System stage field */
  stage: Stage;
};

/** Component for checkbox */
export type FormCheckboxLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type FormCheckboxConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: FormCheckboxWhereUniqueInput;
};

/** A connection to a list of items. */
export type FormCheckboxConnection = {
  __typename?: 'FormCheckboxConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<FormCheckboxEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type FormCheckboxCreateInput = {
  key: Scalars['String'];
  /** label input for default locale (default) */
  label: Scalars['String'];
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<FormCheckboxCreateLocalizationsInput>;
  required: Scalars['Boolean'];
};

export type FormCheckboxCreateLocalizationDataInput = {
  label: Scalars['String'];
};

export type FormCheckboxCreateLocalizationInput = {
  /** Localization input */
  data: FormCheckboxCreateLocalizationDataInput;
  locale: Locale;
};

export type FormCheckboxCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<FormCheckboxCreateLocalizationInput>>;
};

export type FormCheckboxCreateManyInlineInput = {
  /** Create and connect multiple existing FormCheckbox documents */
  create?: InputMaybe<Array<FormCheckboxCreateInput>>;
};

export type FormCheckboxCreateOneInlineInput = {
  /** Create and connect one FormCheckbox document */
  create?: InputMaybe<FormCheckboxCreateInput>;
};

export type FormCheckboxCreateWithPositionInput = {
  /** Document to create */
  data: FormCheckboxCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type FormCheckboxEdge = {
  __typename?: 'FormCheckboxEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: FormCheckbox;
};

/** Component for checkbox group */
export type FormCheckboxGroup = Entity & {
  __typename?: 'FormCheckboxGroup';
  /** The unique identifier */
  id: Scalars['ID'];
  /** This is the key in the JSON payload, and it should match the key in the configured payload for Salesforce. */
  key: Scalars['String'];
  /** Field label that will appear to the user */
  label: Scalars['String'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<FormCheckboxGroup>;
  options: Array<FieldOption>;
  required: Scalars['Boolean'];
  /** Allow the field to span the entire width of the row, applicable only when the number of form columns exceeds "1" */
  rowSpan: Scalars['Boolean'];
  /** System stage field */
  stage: Stage;
  validators: Array<FormCheckboxGroupvalidatorsUnion>;
};

/** Component for checkbox group */
export type FormCheckboxGroupLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

/** Component for checkbox group */
export type FormCheckboxGroupOptionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<FieldOptionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FieldOptionWhereInput>;
};

/** Component for checkbox group */
export type FormCheckboxGroupValidatorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type FormCheckboxGroupConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: FormCheckboxGroupWhereUniqueInput;
};

/** A connection to a list of items. */
export type FormCheckboxGroupConnection = {
  __typename?: 'FormCheckboxGroupConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<FormCheckboxGroupEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type FormCheckboxGroupCreateInput = {
  key: Scalars['String'];
  /** label input for default locale (default) */
  label: Scalars['String'];
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<FormCheckboxGroupCreateLocalizationsInput>;
  options?: InputMaybe<FieldOptionCreateManyInlineInput>;
  required: Scalars['Boolean'];
  rowSpan: Scalars['Boolean'];
  validators?: InputMaybe<FormCheckboxGroupvalidatorsUnionCreateManyInlineInput>;
};

export type FormCheckboxGroupCreateLocalizationDataInput = {
  label: Scalars['String'];
};

export type FormCheckboxGroupCreateLocalizationInput = {
  /** Localization input */
  data: FormCheckboxGroupCreateLocalizationDataInput;
  locale: Locale;
};

export type FormCheckboxGroupCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<FormCheckboxGroupCreateLocalizationInput>>;
};

export type FormCheckboxGroupCreateManyInlineInput = {
  /** Create and connect multiple existing FormCheckboxGroup documents */
  create?: InputMaybe<Array<FormCheckboxGroupCreateInput>>;
};

export type FormCheckboxGroupCreateOneInlineInput = {
  /** Create and connect one FormCheckboxGroup document */
  create?: InputMaybe<FormCheckboxGroupCreateInput>;
};

export type FormCheckboxGroupCreateWithPositionInput = {
  /** Document to create */
  data: FormCheckboxGroupCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type FormCheckboxGroupEdge = {
  __typename?: 'FormCheckboxGroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: FormCheckboxGroup;
};

/** Identifies documents */
export type FormCheckboxGroupManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FormCheckboxGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FormCheckboxGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FormCheckboxGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  options_every?: InputMaybe<FieldOptionWhereInput>;
  options_none?: InputMaybe<FieldOptionWhereInput>;
  options_some?: InputMaybe<FieldOptionWhereInput>;
  required?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  required_not?: InputMaybe<Scalars['Boolean']>;
  rowSpan?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  rowSpan_not?: InputMaybe<Scalars['Boolean']>;
  /** All values in which the union is empty. */
  validators_empty?: InputMaybe<Scalars['Boolean']>;
  /** Matches if the modular component contains at least one connection to the item provided to the filter */
  validators_some?: InputMaybe<FormCheckboxGroupvalidatorsUnionWhereInput>;
};

export enum FormCheckboxGroupOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  RequiredAsc = 'required_ASC',
  RequiredDesc = 'required_DESC',
  RowSpanAsc = 'rowSpan_ASC',
  RowSpanDesc = 'rowSpan_DESC',
}

export type FormCheckboxGroupParent = Form;

export type FormCheckboxGroupParentConnectInput = {
  Form?: InputMaybe<FormConnectInput>;
};

export type FormCheckboxGroupParentCreateInput = {
  Form?: InputMaybe<FormCreateInput>;
};

export type FormCheckboxGroupParentCreateManyInlineInput = {
  /** Connect multiple existing FormCheckboxGroupParent documents */
  connect?: InputMaybe<Array<FormCheckboxGroupParentWhereUniqueInput>>;
  /** Create and connect multiple existing FormCheckboxGroupParent documents */
  create?: InputMaybe<Array<FormCheckboxGroupParentCreateInput>>;
};

export type FormCheckboxGroupParentCreateOneInlineInput = {
  /** Connect one existing FormCheckboxGroupParent document */
  connect?: InputMaybe<FormCheckboxGroupParentWhereUniqueInput>;
  /** Create and connect one FormCheckboxGroupParent document */
  create?: InputMaybe<FormCheckboxGroupParentCreateInput>;
};

export type FormCheckboxGroupParentUpdateInput = {
  Form?: InputMaybe<FormUpdateInput>;
};

export type FormCheckboxGroupParentUpdateManyInlineInput = {
  /** Connect multiple existing FormCheckboxGroupParent documents */
  connect?: InputMaybe<Array<FormCheckboxGroupParentConnectInput>>;
  /** Create and connect multiple FormCheckboxGroupParent documents */
  create?: InputMaybe<Array<FormCheckboxGroupParentCreateInput>>;
  /** Delete multiple FormCheckboxGroupParent documents */
  delete?: InputMaybe<Array<FormCheckboxGroupParentWhereUniqueInput>>;
  /** Disconnect multiple FormCheckboxGroupParent documents */
  disconnect?: InputMaybe<Array<FormCheckboxGroupParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing FormCheckboxGroupParent documents */
  set?: InputMaybe<Array<FormCheckboxGroupParentWhereUniqueInput>>;
  /** Update multiple FormCheckboxGroupParent documents */
  update?: InputMaybe<
    Array<FormCheckboxGroupParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple FormCheckboxGroupParent documents */
  upsert?: InputMaybe<
    Array<FormCheckboxGroupParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type FormCheckboxGroupParentUpdateManyWithNestedWhereInput = {
  Form?: InputMaybe<FormUpdateManyWithNestedWhereInput>;
};

export type FormCheckboxGroupParentUpdateOneInlineInput = {
  /** Connect existing FormCheckboxGroupParent document */
  connect?: InputMaybe<FormCheckboxGroupParentWhereUniqueInput>;
  /** Create and connect one FormCheckboxGroupParent document */
  create?: InputMaybe<FormCheckboxGroupParentCreateInput>;
  /** Delete currently connected FormCheckboxGroupParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected FormCheckboxGroupParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single FormCheckboxGroupParent document */
  update?: InputMaybe<FormCheckboxGroupParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single FormCheckboxGroupParent document */
  upsert?: InputMaybe<FormCheckboxGroupParentUpsertWithNestedWhereUniqueInput>;
};

export type FormCheckboxGroupParentUpdateWithNestedWhereUniqueInput = {
  Form?: InputMaybe<FormUpdateWithNestedWhereUniqueInput>;
};

export type FormCheckboxGroupParentUpsertWithNestedWhereUniqueInput = {
  Form?: InputMaybe<FormUpsertWithNestedWhereUniqueInput>;
};

export type FormCheckboxGroupParentWhereInput = {
  Form?: InputMaybe<FormWhereInput>;
};

export type FormCheckboxGroupParentWhereUniqueInput = {
  Form?: InputMaybe<FormWhereUniqueInput>;
};

export type FormCheckboxGroupUpdateInput = {
  key?: InputMaybe<Scalars['String']>;
  /** label input for default locale (default) */
  label?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<FormCheckboxGroupUpdateLocalizationsInput>;
  options?: InputMaybe<FieldOptionUpdateManyInlineInput>;
  required?: InputMaybe<Scalars['Boolean']>;
  rowSpan?: InputMaybe<Scalars['Boolean']>;
  validators?: InputMaybe<FormCheckboxGroupvalidatorsUnionUpdateManyInlineInput>;
};

export type FormCheckboxGroupUpdateLocalizationDataInput = {
  label?: InputMaybe<Scalars['String']>;
};

export type FormCheckboxGroupUpdateLocalizationInput = {
  data: FormCheckboxGroupUpdateLocalizationDataInput;
  locale: Locale;
};

export type FormCheckboxGroupUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<FormCheckboxGroupCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<FormCheckboxGroupUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<FormCheckboxGroupUpsertLocalizationInput>>;
};

export type FormCheckboxGroupUpdateManyInlineInput = {
  /** Create and connect multiple FormCheckboxGroup component instances */
  create?: InputMaybe<Array<FormCheckboxGroupCreateWithPositionInput>>;
  /** Delete multiple FormCheckboxGroup documents */
  delete?: InputMaybe<Array<FormCheckboxGroupWhereUniqueInput>>;
  /** Update multiple FormCheckboxGroup component instances */
  update?: InputMaybe<
    Array<FormCheckboxGroupUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple FormCheckboxGroup component instances */
  upsert?: InputMaybe<
    Array<FormCheckboxGroupUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type FormCheckboxGroupUpdateManyInput = {
  key?: InputMaybe<Scalars['String']>;
  /** label input for default locale (default) */
  label?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<FormCheckboxGroupUpdateManyLocalizationsInput>;
  required?: InputMaybe<Scalars['Boolean']>;
  rowSpan?: InputMaybe<Scalars['Boolean']>;
};

export type FormCheckboxGroupUpdateManyLocalizationDataInput = {
  label?: InputMaybe<Scalars['String']>;
};

export type FormCheckboxGroupUpdateManyLocalizationInput = {
  data: FormCheckboxGroupUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type FormCheckboxGroupUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<FormCheckboxGroupUpdateManyLocalizationInput>>;
};

export type FormCheckboxGroupUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: FormCheckboxGroupUpdateManyInput;
  /** Document search */
  where: FormCheckboxGroupWhereInput;
};

export type FormCheckboxGroupUpdateOneInlineInput = {
  /** Create and connect one FormCheckboxGroup document */
  create?: InputMaybe<FormCheckboxGroupCreateInput>;
  /** Delete currently connected FormCheckboxGroup document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single FormCheckboxGroup document */
  update?: InputMaybe<FormCheckboxGroupUpdateWithNestedWhereUniqueInput>;
  /** Upsert single FormCheckboxGroup document */
  upsert?: InputMaybe<FormCheckboxGroupUpsertWithNestedWhereUniqueInput>;
};

export type FormCheckboxGroupUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<FormCheckboxGroupUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: FormCheckboxGroupWhereUniqueInput;
};

export type FormCheckboxGroupUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: FormCheckboxGroupUpdateInput;
  /** Unique document search */
  where: FormCheckboxGroupWhereUniqueInput;
};

export type FormCheckboxGroupUpsertInput = {
  /** Create document if it didn't exist */
  create: FormCheckboxGroupCreateInput;
  /** Update document if it exists */
  update: FormCheckboxGroupUpdateInput;
};

export type FormCheckboxGroupUpsertLocalizationInput = {
  create: FormCheckboxGroupCreateLocalizationDataInput;
  locale: Locale;
  update: FormCheckboxGroupUpdateLocalizationDataInput;
};

export type FormCheckboxGroupUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<FormCheckboxGroupUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: FormCheckboxGroupWhereUniqueInput;
};

export type FormCheckboxGroupUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: FormCheckboxGroupUpsertInput;
  /** Unique document search */
  where: FormCheckboxGroupWhereUniqueInput;
};

/** Identifies documents */
export type FormCheckboxGroupWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FormCheckboxGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FormCheckboxGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FormCheckboxGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  label_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  label_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  label_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  label_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  label_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  label_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  label_starts_with?: InputMaybe<Scalars['String']>;
  options_every?: InputMaybe<FieldOptionWhereInput>;
  options_none?: InputMaybe<FieldOptionWhereInput>;
  options_some?: InputMaybe<FieldOptionWhereInput>;
  required?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  required_not?: InputMaybe<Scalars['Boolean']>;
  rowSpan?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  rowSpan_not?: InputMaybe<Scalars['Boolean']>;
  /** All values in which the union is empty. */
  validators_empty?: InputMaybe<Scalars['Boolean']>;
  /** Matches if the modular component contains at least one connection to the item provided to the filter */
  validators_some?: InputMaybe<FormCheckboxGroupvalidatorsUnionWhereInput>;
};

/** References FormCheckboxGroup record uniquely */
export type FormCheckboxGroupWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type FormCheckboxGroupvalidatorsUnion =
  | MaxCheckedItemValidator
  | MinCheckedItemValidator;

export type FormCheckboxGroupvalidatorsUnionConnectInput = {
  MaxCheckedItemValidator?: InputMaybe<MaxCheckedItemValidatorConnectInput>;
  MinCheckedItemValidator?: InputMaybe<MinCheckedItemValidatorConnectInput>;
};

export type FormCheckboxGroupvalidatorsUnionCreateInput = {
  MaxCheckedItemValidator?: InputMaybe<MaxCheckedItemValidatorCreateInput>;
  MinCheckedItemValidator?: InputMaybe<MinCheckedItemValidatorCreateInput>;
};

export type FormCheckboxGroupvalidatorsUnionCreateManyInlineInput = {
  /** Create and connect multiple existing FormCheckboxGroupvalidatorsUnion documents */
  create?: InputMaybe<Array<FormCheckboxGroupvalidatorsUnionCreateInput>>;
};

export type FormCheckboxGroupvalidatorsUnionCreateOneInlineInput = {
  /** Create and connect one FormCheckboxGroupvalidatorsUnion document */
  create?: InputMaybe<FormCheckboxGroupvalidatorsUnionCreateInput>;
};

export type FormCheckboxGroupvalidatorsUnionCreateWithPositionInput = {
  MaxCheckedItemValidator?: InputMaybe<MaxCheckedItemValidatorCreateWithPositionInput>;
  MinCheckedItemValidator?: InputMaybe<MinCheckedItemValidatorCreateWithPositionInput>;
};

export type FormCheckboxGroupvalidatorsUnionUpdateInput = {
  MaxCheckedItemValidator?: InputMaybe<MaxCheckedItemValidatorUpdateInput>;
  MinCheckedItemValidator?: InputMaybe<MinCheckedItemValidatorUpdateInput>;
};

export type FormCheckboxGroupvalidatorsUnionUpdateManyInlineInput = {
  /** Create and connect multiple FormCheckboxGroupvalidatorsUnion component instances */
  create?: InputMaybe<
    Array<FormCheckboxGroupvalidatorsUnionCreateWithPositionInput>
  >;
  /** Delete multiple FormCheckboxGroupvalidatorsUnion documents */
  delete?: InputMaybe<Array<FormCheckboxGroupvalidatorsUnionWhereUniqueInput>>;
  /** Update multiple FormCheckboxGroupvalidatorsUnion component instances */
  update?: InputMaybe<
    Array<FormCheckboxGroupvalidatorsUnionUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple FormCheckboxGroupvalidatorsUnion component instances */
  upsert?: InputMaybe<
    Array<FormCheckboxGroupvalidatorsUnionUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type FormCheckboxGroupvalidatorsUnionUpdateManyWithNestedWhereInput = {
  MaxCheckedItemValidator?: InputMaybe<MaxCheckedItemValidatorUpdateManyWithNestedWhereInput>;
  MinCheckedItemValidator?: InputMaybe<MinCheckedItemValidatorUpdateManyWithNestedWhereInput>;
};

export type FormCheckboxGroupvalidatorsUnionUpdateOneInlineInput = {
  /** Create and connect one FormCheckboxGroupvalidatorsUnion document */
  create?: InputMaybe<FormCheckboxGroupvalidatorsUnionCreateInput>;
  /** Delete currently connected FormCheckboxGroupvalidatorsUnion document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single FormCheckboxGroupvalidatorsUnion document */
  update?: InputMaybe<FormCheckboxGroupvalidatorsUnionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single FormCheckboxGroupvalidatorsUnion document */
  upsert?: InputMaybe<FormCheckboxGroupvalidatorsUnionUpsertWithNestedWhereUniqueInput>;
};

export type FormCheckboxGroupvalidatorsUnionUpdateWithNestedWhereUniqueAndPositionInput =
  {
    MaxCheckedItemValidator?: InputMaybe<MaxCheckedItemValidatorUpdateWithNestedWhereUniqueAndPositionInput>;
    MinCheckedItemValidator?: InputMaybe<MinCheckedItemValidatorUpdateWithNestedWhereUniqueAndPositionInput>;
  };

export type FormCheckboxGroupvalidatorsUnionUpdateWithNestedWhereUniqueInput = {
  MaxCheckedItemValidator?: InputMaybe<MaxCheckedItemValidatorUpdateWithNestedWhereUniqueInput>;
  MinCheckedItemValidator?: InputMaybe<MinCheckedItemValidatorUpdateWithNestedWhereUniqueInput>;
};

export type FormCheckboxGroupvalidatorsUnionUpsertWithNestedWhereUniqueAndPositionInput =
  {
    MaxCheckedItemValidator?: InputMaybe<MaxCheckedItemValidatorUpsertWithNestedWhereUniqueAndPositionInput>;
    MinCheckedItemValidator?: InputMaybe<MinCheckedItemValidatorUpsertWithNestedWhereUniqueAndPositionInput>;
  };

export type FormCheckboxGroupvalidatorsUnionUpsertWithNestedWhereUniqueInput = {
  MaxCheckedItemValidator?: InputMaybe<MaxCheckedItemValidatorUpsertWithNestedWhereUniqueInput>;
  MinCheckedItemValidator?: InputMaybe<MinCheckedItemValidatorUpsertWithNestedWhereUniqueInput>;
};

export type FormCheckboxGroupvalidatorsUnionWhereInput = {
  MaxCheckedItemValidator?: InputMaybe<MaxCheckedItemValidatorWhereInput>;
  MinCheckedItemValidator?: InputMaybe<MinCheckedItemValidatorWhereInput>;
};

export type FormCheckboxGroupvalidatorsUnionWhereUniqueInput = {
  MaxCheckedItemValidator?: InputMaybe<MaxCheckedItemValidatorWhereUniqueInput>;
  MinCheckedItemValidator?: InputMaybe<MinCheckedItemValidatorWhereUniqueInput>;
};

/** Identifies documents */
export type FormCheckboxManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FormCheckboxWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FormCheckboxWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FormCheckboxWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  required?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  required_not?: InputMaybe<Scalars['Boolean']>;
};

export enum FormCheckboxOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  RequiredAsc = 'required_ASC',
  RequiredDesc = 'required_DESC',
}

export type FormCheckboxParent = Form;

export type FormCheckboxParentConnectInput = {
  Form?: InputMaybe<FormConnectInput>;
};

export type FormCheckboxParentCreateInput = {
  Form?: InputMaybe<FormCreateInput>;
};

export type FormCheckboxParentCreateManyInlineInput = {
  /** Connect multiple existing FormCheckboxParent documents */
  connect?: InputMaybe<Array<FormCheckboxParentWhereUniqueInput>>;
  /** Create and connect multiple existing FormCheckboxParent documents */
  create?: InputMaybe<Array<FormCheckboxParentCreateInput>>;
};

export type FormCheckboxParentCreateOneInlineInput = {
  /** Connect one existing FormCheckboxParent document */
  connect?: InputMaybe<FormCheckboxParentWhereUniqueInput>;
  /** Create and connect one FormCheckboxParent document */
  create?: InputMaybe<FormCheckboxParentCreateInput>;
};

export type FormCheckboxParentUpdateInput = {
  Form?: InputMaybe<FormUpdateInput>;
};

export type FormCheckboxParentUpdateManyInlineInput = {
  /** Connect multiple existing FormCheckboxParent documents */
  connect?: InputMaybe<Array<FormCheckboxParentConnectInput>>;
  /** Create and connect multiple FormCheckboxParent documents */
  create?: InputMaybe<Array<FormCheckboxParentCreateInput>>;
  /** Delete multiple FormCheckboxParent documents */
  delete?: InputMaybe<Array<FormCheckboxParentWhereUniqueInput>>;
  /** Disconnect multiple FormCheckboxParent documents */
  disconnect?: InputMaybe<Array<FormCheckboxParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing FormCheckboxParent documents */
  set?: InputMaybe<Array<FormCheckboxParentWhereUniqueInput>>;
  /** Update multiple FormCheckboxParent documents */
  update?: InputMaybe<
    Array<FormCheckboxParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple FormCheckboxParent documents */
  upsert?: InputMaybe<
    Array<FormCheckboxParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type FormCheckboxParentUpdateManyWithNestedWhereInput = {
  Form?: InputMaybe<FormUpdateManyWithNestedWhereInput>;
};

export type FormCheckboxParentUpdateOneInlineInput = {
  /** Connect existing FormCheckboxParent document */
  connect?: InputMaybe<FormCheckboxParentWhereUniqueInput>;
  /** Create and connect one FormCheckboxParent document */
  create?: InputMaybe<FormCheckboxParentCreateInput>;
  /** Delete currently connected FormCheckboxParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected FormCheckboxParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single FormCheckboxParent document */
  update?: InputMaybe<FormCheckboxParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single FormCheckboxParent document */
  upsert?: InputMaybe<FormCheckboxParentUpsertWithNestedWhereUniqueInput>;
};

export type FormCheckboxParentUpdateWithNestedWhereUniqueInput = {
  Form?: InputMaybe<FormUpdateWithNestedWhereUniqueInput>;
};

export type FormCheckboxParentUpsertWithNestedWhereUniqueInput = {
  Form?: InputMaybe<FormUpsertWithNestedWhereUniqueInput>;
};

export type FormCheckboxParentWhereInput = {
  Form?: InputMaybe<FormWhereInput>;
};

export type FormCheckboxParentWhereUniqueInput = {
  Form?: InputMaybe<FormWhereUniqueInput>;
};

export type FormCheckboxUpdateInput = {
  key?: InputMaybe<Scalars['String']>;
  /** label input for default locale (default) */
  label?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<FormCheckboxUpdateLocalizationsInput>;
  required?: InputMaybe<Scalars['Boolean']>;
};

export type FormCheckboxUpdateLocalizationDataInput = {
  label?: InputMaybe<Scalars['String']>;
};

export type FormCheckboxUpdateLocalizationInput = {
  data: FormCheckboxUpdateLocalizationDataInput;
  locale: Locale;
};

export type FormCheckboxUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<FormCheckboxCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<FormCheckboxUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<FormCheckboxUpsertLocalizationInput>>;
};

export type FormCheckboxUpdateManyInlineInput = {
  /** Create and connect multiple FormCheckbox component instances */
  create?: InputMaybe<Array<FormCheckboxCreateWithPositionInput>>;
  /** Delete multiple FormCheckbox documents */
  delete?: InputMaybe<Array<FormCheckboxWhereUniqueInput>>;
  /** Update multiple FormCheckbox component instances */
  update?: InputMaybe<
    Array<FormCheckboxUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple FormCheckbox component instances */
  upsert?: InputMaybe<
    Array<FormCheckboxUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type FormCheckboxUpdateManyInput = {
  key?: InputMaybe<Scalars['String']>;
  /** label input for default locale (default) */
  label?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<FormCheckboxUpdateManyLocalizationsInput>;
  required?: InputMaybe<Scalars['Boolean']>;
};

export type FormCheckboxUpdateManyLocalizationDataInput = {
  label?: InputMaybe<Scalars['String']>;
};

export type FormCheckboxUpdateManyLocalizationInput = {
  data: FormCheckboxUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type FormCheckboxUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<FormCheckboxUpdateManyLocalizationInput>>;
};

export type FormCheckboxUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: FormCheckboxUpdateManyInput;
  /** Document search */
  where: FormCheckboxWhereInput;
};

export type FormCheckboxUpdateOneInlineInput = {
  /** Create and connect one FormCheckbox document */
  create?: InputMaybe<FormCheckboxCreateInput>;
  /** Delete currently connected FormCheckbox document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single FormCheckbox document */
  update?: InputMaybe<FormCheckboxUpdateWithNestedWhereUniqueInput>;
  /** Upsert single FormCheckbox document */
  upsert?: InputMaybe<FormCheckboxUpsertWithNestedWhereUniqueInput>;
};

export type FormCheckboxUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<FormCheckboxUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: FormCheckboxWhereUniqueInput;
};

export type FormCheckboxUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: FormCheckboxUpdateInput;
  /** Unique document search */
  where: FormCheckboxWhereUniqueInput;
};

export type FormCheckboxUpsertInput = {
  /** Create document if it didn't exist */
  create: FormCheckboxCreateInput;
  /** Update document if it exists */
  update: FormCheckboxUpdateInput;
};

export type FormCheckboxUpsertLocalizationInput = {
  create: FormCheckboxCreateLocalizationDataInput;
  locale: Locale;
  update: FormCheckboxUpdateLocalizationDataInput;
};

export type FormCheckboxUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<FormCheckboxUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: FormCheckboxWhereUniqueInput;
};

export type FormCheckboxUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: FormCheckboxUpsertInput;
  /** Unique document search */
  where: FormCheckboxWhereUniqueInput;
};

/** Identifies documents */
export type FormCheckboxWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FormCheckboxWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FormCheckboxWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FormCheckboxWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  label_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  label_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  label_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  label_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  label_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  label_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  label_starts_with?: InputMaybe<Scalars['String']>;
  required?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  required_not?: InputMaybe<Scalars['Boolean']>;
};

/** References FormCheckbox record uniquely */
export type FormCheckboxWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type FormConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: FormWhereUniqueInput;
};

/** A connection to a list of items. */
export type FormConnection = {
  __typename?: 'FormConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<FormEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type FormCreateInput = {
  apiEndpoint: Scalars['String'];
  buttonColour?: InputMaybe<Colour>;
  clrilhfje0f6c01ti2q79cuje?: InputMaybe<CFormCreateManyInlineInput>;
  columns: Scalars['Int'];
  /** confirmationMessage input for default locale (default) */
  confirmationMessage?: InputMaybe<Scalars['RichTextAST']>;
  /** consentText input for default locale (default) */
  consentText?: InputMaybe<Scalars['RichTextAST']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** ctaText input for default locale (default) */
  ctaText?: InputMaybe<Scalars['String']>;
  /** description input for default locale (default) */
  description?: InputMaybe<Scalars['String']>;
  /** disclaimerText input for default locale (default) */
  disclaimerText?: InputMaybe<Scalars['String']>;
  fields?: InputMaybe<FormfieldsUnionCreateManyInlineInput>;
  formType?: InputMaybe<CFormType>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<FormCreateLocalizationsInput>;
  sites?: InputMaybe<SiteCreateManyInlineInput>;
  skipDefaultLocale?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
  /** title input for default locale (default) */
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FormCreateLocalizationDataInput = {
  confirmationMessage?: InputMaybe<Scalars['RichTextAST']>;
  consentText?: InputMaybe<Scalars['RichTextAST']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  ctaText?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  disclaimerText?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FormCreateLocalizationInput = {
  /** Localization input */
  data: FormCreateLocalizationDataInput;
  locale: Locale;
};

export type FormCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<FormCreateLocalizationInput>>;
};

export type FormCreateManyInlineInput = {
  /** Connect multiple existing Form documents */
  connect?: InputMaybe<Array<FormWhereUniqueInput>>;
  /** Create and connect multiple existing Form documents */
  create?: InputMaybe<Array<FormCreateInput>>;
};

export type FormCreateOneInlineInput = {
  /** Connect one existing Form document */
  connect?: InputMaybe<FormWhereUniqueInput>;
  /** Create and connect one Form document */
  create?: InputMaybe<FormCreateInput>;
};

/** An edge in a connection. */
export type FormEdge = {
  __typename?: 'FormEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Form;
};

/** Component for textfield with email type */
export type FormInputEmail = Entity & {
  __typename?: 'FormInputEmail';
  /** The unique identifier */
  id: Scalars['ID'];
  /** This is the key in the JSON payload, and it should match Salesforce in the JSON payload. */
  key: Scalars['String'];
  label: Scalars['String'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<FormInputEmail>;
  placeholder?: Maybe<Scalars['String']>;
  required: Scalars['Boolean'];
  /** Allow the field to span the entire width of the row, applicable only when the number of form columns exceeds "1" */
  rowSpan: Scalars['Boolean'];
  /** System stage field */
  stage: Stage;
  validators: Array<FormInputEmailvalidatorsUnion>;
};

/** Component for textfield with email type */
export type FormInputEmailLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

/** Component for textfield with email type */
export type FormInputEmailValidatorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type FormInputEmailConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: FormInputEmailWhereUniqueInput;
};

/** A connection to a list of items. */
export type FormInputEmailConnection = {
  __typename?: 'FormInputEmailConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<FormInputEmailEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type FormInputEmailCreateInput = {
  key: Scalars['String'];
  /** label input for default locale (default) */
  label: Scalars['String'];
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<FormInputEmailCreateLocalizationsInput>;
  /** placeholder input for default locale (default) */
  placeholder?: InputMaybe<Scalars['String']>;
  required: Scalars['Boolean'];
  rowSpan: Scalars['Boolean'];
  validators?: InputMaybe<FormInputEmailvalidatorsUnionCreateManyInlineInput>;
};

export type FormInputEmailCreateLocalizationDataInput = {
  label: Scalars['String'];
  placeholder?: InputMaybe<Scalars['String']>;
};

export type FormInputEmailCreateLocalizationInput = {
  /** Localization input */
  data: FormInputEmailCreateLocalizationDataInput;
  locale: Locale;
};

export type FormInputEmailCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<FormInputEmailCreateLocalizationInput>>;
};

export type FormInputEmailCreateManyInlineInput = {
  /** Create and connect multiple existing FormInputEmail documents */
  create?: InputMaybe<Array<FormInputEmailCreateInput>>;
};

export type FormInputEmailCreateOneInlineInput = {
  /** Create and connect one FormInputEmail document */
  create?: InputMaybe<FormInputEmailCreateInput>;
};

export type FormInputEmailCreateWithPositionInput = {
  /** Document to create */
  data: FormInputEmailCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type FormInputEmailEdge = {
  __typename?: 'FormInputEmailEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: FormInputEmail;
};

/** Identifies documents */
export type FormInputEmailManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FormInputEmailWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FormInputEmailWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FormInputEmailWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  required?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  required_not?: InputMaybe<Scalars['Boolean']>;
  rowSpan?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  rowSpan_not?: InputMaybe<Scalars['Boolean']>;
  /** All values in which the union is empty. */
  validators_empty?: InputMaybe<Scalars['Boolean']>;
  /** Matches if the modular component contains at least one connection to the item provided to the filter */
  validators_some?: InputMaybe<FormInputEmailvalidatorsUnionWhereInput>;
};

export enum FormInputEmailOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  PlaceholderAsc = 'placeholder_ASC',
  PlaceholderDesc = 'placeholder_DESC',
  RequiredAsc = 'required_ASC',
  RequiredDesc = 'required_DESC',
  RowSpanAsc = 'rowSpan_ASC',
  RowSpanDesc = 'rowSpan_DESC',
}

export type FormInputEmailParent = Form;

export type FormInputEmailParentConnectInput = {
  Form?: InputMaybe<FormConnectInput>;
};

export type FormInputEmailParentCreateInput = {
  Form?: InputMaybe<FormCreateInput>;
};

export type FormInputEmailParentCreateManyInlineInput = {
  /** Connect multiple existing FormInputEmailParent documents */
  connect?: InputMaybe<Array<FormInputEmailParentWhereUniqueInput>>;
  /** Create and connect multiple existing FormInputEmailParent documents */
  create?: InputMaybe<Array<FormInputEmailParentCreateInput>>;
};

export type FormInputEmailParentCreateOneInlineInput = {
  /** Connect one existing FormInputEmailParent document */
  connect?: InputMaybe<FormInputEmailParentWhereUniqueInput>;
  /** Create and connect one FormInputEmailParent document */
  create?: InputMaybe<FormInputEmailParentCreateInput>;
};

export type FormInputEmailParentUpdateInput = {
  Form?: InputMaybe<FormUpdateInput>;
};

export type FormInputEmailParentUpdateManyInlineInput = {
  /** Connect multiple existing FormInputEmailParent documents */
  connect?: InputMaybe<Array<FormInputEmailParentConnectInput>>;
  /** Create and connect multiple FormInputEmailParent documents */
  create?: InputMaybe<Array<FormInputEmailParentCreateInput>>;
  /** Delete multiple FormInputEmailParent documents */
  delete?: InputMaybe<Array<FormInputEmailParentWhereUniqueInput>>;
  /** Disconnect multiple FormInputEmailParent documents */
  disconnect?: InputMaybe<Array<FormInputEmailParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing FormInputEmailParent documents */
  set?: InputMaybe<Array<FormInputEmailParentWhereUniqueInput>>;
  /** Update multiple FormInputEmailParent documents */
  update?: InputMaybe<
    Array<FormInputEmailParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple FormInputEmailParent documents */
  upsert?: InputMaybe<
    Array<FormInputEmailParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type FormInputEmailParentUpdateManyWithNestedWhereInput = {
  Form?: InputMaybe<FormUpdateManyWithNestedWhereInput>;
};

export type FormInputEmailParentUpdateOneInlineInput = {
  /** Connect existing FormInputEmailParent document */
  connect?: InputMaybe<FormInputEmailParentWhereUniqueInput>;
  /** Create and connect one FormInputEmailParent document */
  create?: InputMaybe<FormInputEmailParentCreateInput>;
  /** Delete currently connected FormInputEmailParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected FormInputEmailParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single FormInputEmailParent document */
  update?: InputMaybe<FormInputEmailParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single FormInputEmailParent document */
  upsert?: InputMaybe<FormInputEmailParentUpsertWithNestedWhereUniqueInput>;
};

export type FormInputEmailParentUpdateWithNestedWhereUniqueInput = {
  Form?: InputMaybe<FormUpdateWithNestedWhereUniqueInput>;
};

export type FormInputEmailParentUpsertWithNestedWhereUniqueInput = {
  Form?: InputMaybe<FormUpsertWithNestedWhereUniqueInput>;
};

export type FormInputEmailParentWhereInput = {
  Form?: InputMaybe<FormWhereInput>;
};

export type FormInputEmailParentWhereUniqueInput = {
  Form?: InputMaybe<FormWhereUniqueInput>;
};

export type FormInputEmailUpdateInput = {
  key?: InputMaybe<Scalars['String']>;
  /** label input for default locale (default) */
  label?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<FormInputEmailUpdateLocalizationsInput>;
  /** placeholder input for default locale (default) */
  placeholder?: InputMaybe<Scalars['String']>;
  required?: InputMaybe<Scalars['Boolean']>;
  rowSpan?: InputMaybe<Scalars['Boolean']>;
  validators?: InputMaybe<FormInputEmailvalidatorsUnionUpdateManyInlineInput>;
};

export type FormInputEmailUpdateLocalizationDataInput = {
  label?: InputMaybe<Scalars['String']>;
  placeholder?: InputMaybe<Scalars['String']>;
};

export type FormInputEmailUpdateLocalizationInput = {
  data: FormInputEmailUpdateLocalizationDataInput;
  locale: Locale;
};

export type FormInputEmailUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<FormInputEmailCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<FormInputEmailUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<FormInputEmailUpsertLocalizationInput>>;
};

export type FormInputEmailUpdateManyInlineInput = {
  /** Create and connect multiple FormInputEmail component instances */
  create?: InputMaybe<Array<FormInputEmailCreateWithPositionInput>>;
  /** Delete multiple FormInputEmail documents */
  delete?: InputMaybe<Array<FormInputEmailWhereUniqueInput>>;
  /** Update multiple FormInputEmail component instances */
  update?: InputMaybe<
    Array<FormInputEmailUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple FormInputEmail component instances */
  upsert?: InputMaybe<
    Array<FormInputEmailUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type FormInputEmailUpdateManyInput = {
  key?: InputMaybe<Scalars['String']>;
  /** label input for default locale (default) */
  label?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<FormInputEmailUpdateManyLocalizationsInput>;
  /** placeholder input for default locale (default) */
  placeholder?: InputMaybe<Scalars['String']>;
  required?: InputMaybe<Scalars['Boolean']>;
  rowSpan?: InputMaybe<Scalars['Boolean']>;
};

export type FormInputEmailUpdateManyLocalizationDataInput = {
  label?: InputMaybe<Scalars['String']>;
  placeholder?: InputMaybe<Scalars['String']>;
};

export type FormInputEmailUpdateManyLocalizationInput = {
  data: FormInputEmailUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type FormInputEmailUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<FormInputEmailUpdateManyLocalizationInput>>;
};

export type FormInputEmailUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: FormInputEmailUpdateManyInput;
  /** Document search */
  where: FormInputEmailWhereInput;
};

export type FormInputEmailUpdateOneInlineInput = {
  /** Create and connect one FormInputEmail document */
  create?: InputMaybe<FormInputEmailCreateInput>;
  /** Delete currently connected FormInputEmail document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single FormInputEmail document */
  update?: InputMaybe<FormInputEmailUpdateWithNestedWhereUniqueInput>;
  /** Upsert single FormInputEmail document */
  upsert?: InputMaybe<FormInputEmailUpsertWithNestedWhereUniqueInput>;
};

export type FormInputEmailUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<FormInputEmailUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: FormInputEmailWhereUniqueInput;
};

export type FormInputEmailUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: FormInputEmailUpdateInput;
  /** Unique document search */
  where: FormInputEmailWhereUniqueInput;
};

export type FormInputEmailUpsertInput = {
  /** Create document if it didn't exist */
  create: FormInputEmailCreateInput;
  /** Update document if it exists */
  update: FormInputEmailUpdateInput;
};

export type FormInputEmailUpsertLocalizationInput = {
  create: FormInputEmailCreateLocalizationDataInput;
  locale: Locale;
  update: FormInputEmailUpdateLocalizationDataInput;
};

export type FormInputEmailUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<FormInputEmailUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: FormInputEmailWhereUniqueInput;
};

export type FormInputEmailUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: FormInputEmailUpsertInput;
  /** Unique document search */
  where: FormInputEmailWhereUniqueInput;
};

/** Identifies documents */
export type FormInputEmailWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FormInputEmailWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FormInputEmailWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FormInputEmailWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  label_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  label_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  label_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  label_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  label_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  label_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  label_starts_with?: InputMaybe<Scalars['String']>;
  placeholder?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  placeholder_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  placeholder_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  placeholder_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  placeholder_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  placeholder_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  placeholder_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  placeholder_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  placeholder_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  placeholder_starts_with?: InputMaybe<Scalars['String']>;
  required?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  required_not?: InputMaybe<Scalars['Boolean']>;
  rowSpan?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  rowSpan_not?: InputMaybe<Scalars['Boolean']>;
  /** All values in which the union is empty. */
  validators_empty?: InputMaybe<Scalars['Boolean']>;
  /** Matches if the modular component contains at least one connection to the item provided to the filter */
  validators_some?: InputMaybe<FormInputEmailvalidatorsUnionWhereInput>;
};

/** References FormInputEmail record uniquely */
export type FormInputEmailWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type FormInputEmailvalidatorsUnion = EmailValidator;

export type FormInputEmailvalidatorsUnionConnectInput = {
  EmailValidator?: InputMaybe<EmailValidatorConnectInput>;
};

export type FormInputEmailvalidatorsUnionCreateInput = {
  EmailValidator?: InputMaybe<EmailValidatorCreateInput>;
};

export type FormInputEmailvalidatorsUnionCreateManyInlineInput = {
  /** Create and connect multiple existing FormInputEmailvalidatorsUnion documents */
  create?: InputMaybe<Array<FormInputEmailvalidatorsUnionCreateInput>>;
};

export type FormInputEmailvalidatorsUnionCreateOneInlineInput = {
  /** Create and connect one FormInputEmailvalidatorsUnion document */
  create?: InputMaybe<FormInputEmailvalidatorsUnionCreateInput>;
};

export type FormInputEmailvalidatorsUnionCreateWithPositionInput = {
  EmailValidator?: InputMaybe<EmailValidatorCreateWithPositionInput>;
};

export type FormInputEmailvalidatorsUnionUpdateInput = {
  EmailValidator?: InputMaybe<EmailValidatorUpdateInput>;
};

export type FormInputEmailvalidatorsUnionUpdateManyInlineInput = {
  /** Create and connect multiple FormInputEmailvalidatorsUnion component instances */
  create?: InputMaybe<
    Array<FormInputEmailvalidatorsUnionCreateWithPositionInput>
  >;
  /** Delete multiple FormInputEmailvalidatorsUnion documents */
  delete?: InputMaybe<Array<FormInputEmailvalidatorsUnionWhereUniqueInput>>;
  /** Update multiple FormInputEmailvalidatorsUnion component instances */
  update?: InputMaybe<
    Array<FormInputEmailvalidatorsUnionUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple FormInputEmailvalidatorsUnion component instances */
  upsert?: InputMaybe<
    Array<FormInputEmailvalidatorsUnionUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type FormInputEmailvalidatorsUnionUpdateManyWithNestedWhereInput = {
  EmailValidator?: InputMaybe<EmailValidatorUpdateManyWithNestedWhereInput>;
};

export type FormInputEmailvalidatorsUnionUpdateOneInlineInput = {
  /** Create and connect one FormInputEmailvalidatorsUnion document */
  create?: InputMaybe<FormInputEmailvalidatorsUnionCreateInput>;
  /** Delete currently connected FormInputEmailvalidatorsUnion document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single FormInputEmailvalidatorsUnion document */
  update?: InputMaybe<FormInputEmailvalidatorsUnionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single FormInputEmailvalidatorsUnion document */
  upsert?: InputMaybe<FormInputEmailvalidatorsUnionUpsertWithNestedWhereUniqueInput>;
};

export type FormInputEmailvalidatorsUnionUpdateWithNestedWhereUniqueAndPositionInput =
  {
    EmailValidator?: InputMaybe<EmailValidatorUpdateWithNestedWhereUniqueAndPositionInput>;
  };

export type FormInputEmailvalidatorsUnionUpdateWithNestedWhereUniqueInput = {
  EmailValidator?: InputMaybe<EmailValidatorUpdateWithNestedWhereUniqueInput>;
};

export type FormInputEmailvalidatorsUnionUpsertWithNestedWhereUniqueAndPositionInput =
  {
    EmailValidator?: InputMaybe<EmailValidatorUpsertWithNestedWhereUniqueAndPositionInput>;
  };

export type FormInputEmailvalidatorsUnionUpsertWithNestedWhereUniqueInput = {
  EmailValidator?: InputMaybe<EmailValidatorUpsertWithNestedWhereUniqueInput>;
};

export type FormInputEmailvalidatorsUnionWhereInput = {
  EmailValidator?: InputMaybe<EmailValidatorWhereInput>;
};

export type FormInputEmailvalidatorsUnionWhereUniqueInput = {
  EmailValidator?: InputMaybe<EmailValidatorWhereUniqueInput>;
};

/** Component for textfield with number type */
export type FormInputNumber = Entity & {
  __typename?: 'FormInputNumber';
  /** The unique identifier */
  id: Scalars['ID'];
  /** This is the key in the JSON payload, and it should match Salesforce in the JSON payload. */
  key: Scalars['String'];
  /** Field label that will appear to the user */
  label: Scalars['String'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<FormInputNumber>;
  placeholder?: Maybe<Scalars['String']>;
  required: Scalars['Boolean'];
  /** Allow the field to span the entire width of the row, applicable only when the number of form columns exceeds "1" */
  rowSpan: Scalars['Boolean'];
  /** System stage field */
  stage: Stage;
  validators: Array<FormInputNumbervalidatorsUnion>;
};

/** Component for textfield with number type */
export type FormInputNumberLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

/** Component for textfield with number type */
export type FormInputNumberValidatorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type FormInputNumberConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: FormInputNumberWhereUniqueInput;
};

/** A connection to a list of items. */
export type FormInputNumberConnection = {
  __typename?: 'FormInputNumberConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<FormInputNumberEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type FormInputNumberCreateInput = {
  key: Scalars['String'];
  /** label input for default locale (default) */
  label: Scalars['String'];
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<FormInputNumberCreateLocalizationsInput>;
  /** placeholder input for default locale (default) */
  placeholder?: InputMaybe<Scalars['String']>;
  required: Scalars['Boolean'];
  rowSpan: Scalars['Boolean'];
  validators?: InputMaybe<FormInputNumbervalidatorsUnionCreateManyInlineInput>;
};

export type FormInputNumberCreateLocalizationDataInput = {
  label: Scalars['String'];
  placeholder?: InputMaybe<Scalars['String']>;
};

export type FormInputNumberCreateLocalizationInput = {
  /** Localization input */
  data: FormInputNumberCreateLocalizationDataInput;
  locale: Locale;
};

export type FormInputNumberCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<FormInputNumberCreateLocalizationInput>>;
};

export type FormInputNumberCreateManyInlineInput = {
  /** Create and connect multiple existing FormInputNumber documents */
  create?: InputMaybe<Array<FormInputNumberCreateInput>>;
};

export type FormInputNumberCreateOneInlineInput = {
  /** Create and connect one FormInputNumber document */
  create?: InputMaybe<FormInputNumberCreateInput>;
};

export type FormInputNumberCreateWithPositionInput = {
  /** Document to create */
  data: FormInputNumberCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type FormInputNumberEdge = {
  __typename?: 'FormInputNumberEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: FormInputNumber;
};

/** Identifies documents */
export type FormInputNumberManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FormInputNumberWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FormInputNumberWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FormInputNumberWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  required?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  required_not?: InputMaybe<Scalars['Boolean']>;
  rowSpan?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  rowSpan_not?: InputMaybe<Scalars['Boolean']>;
  /** All values in which the union is empty. */
  validators_empty?: InputMaybe<Scalars['Boolean']>;
  /** Matches if the modular component contains at least one connection to the item provided to the filter */
  validators_some?: InputMaybe<FormInputNumbervalidatorsUnionWhereInput>;
};

export enum FormInputNumberOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  PlaceholderAsc = 'placeholder_ASC',
  PlaceholderDesc = 'placeholder_DESC',
  RequiredAsc = 'required_ASC',
  RequiredDesc = 'required_DESC',
  RowSpanAsc = 'rowSpan_ASC',
  RowSpanDesc = 'rowSpan_DESC',
}

export type FormInputNumberParent = Form;

export type FormInputNumberParentConnectInput = {
  Form?: InputMaybe<FormConnectInput>;
};

export type FormInputNumberParentCreateInput = {
  Form?: InputMaybe<FormCreateInput>;
};

export type FormInputNumberParentCreateManyInlineInput = {
  /** Connect multiple existing FormInputNumberParent documents */
  connect?: InputMaybe<Array<FormInputNumberParentWhereUniqueInput>>;
  /** Create and connect multiple existing FormInputNumberParent documents */
  create?: InputMaybe<Array<FormInputNumberParentCreateInput>>;
};

export type FormInputNumberParentCreateOneInlineInput = {
  /** Connect one existing FormInputNumberParent document */
  connect?: InputMaybe<FormInputNumberParentWhereUniqueInput>;
  /** Create and connect one FormInputNumberParent document */
  create?: InputMaybe<FormInputNumberParentCreateInput>;
};

export type FormInputNumberParentUpdateInput = {
  Form?: InputMaybe<FormUpdateInput>;
};

export type FormInputNumberParentUpdateManyInlineInput = {
  /** Connect multiple existing FormInputNumberParent documents */
  connect?: InputMaybe<Array<FormInputNumberParentConnectInput>>;
  /** Create and connect multiple FormInputNumberParent documents */
  create?: InputMaybe<Array<FormInputNumberParentCreateInput>>;
  /** Delete multiple FormInputNumberParent documents */
  delete?: InputMaybe<Array<FormInputNumberParentWhereUniqueInput>>;
  /** Disconnect multiple FormInputNumberParent documents */
  disconnect?: InputMaybe<Array<FormInputNumberParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing FormInputNumberParent documents */
  set?: InputMaybe<Array<FormInputNumberParentWhereUniqueInput>>;
  /** Update multiple FormInputNumberParent documents */
  update?: InputMaybe<
    Array<FormInputNumberParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple FormInputNumberParent documents */
  upsert?: InputMaybe<
    Array<FormInputNumberParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type FormInputNumberParentUpdateManyWithNestedWhereInput = {
  Form?: InputMaybe<FormUpdateManyWithNestedWhereInput>;
};

export type FormInputNumberParentUpdateOneInlineInput = {
  /** Connect existing FormInputNumberParent document */
  connect?: InputMaybe<FormInputNumberParentWhereUniqueInput>;
  /** Create and connect one FormInputNumberParent document */
  create?: InputMaybe<FormInputNumberParentCreateInput>;
  /** Delete currently connected FormInputNumberParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected FormInputNumberParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single FormInputNumberParent document */
  update?: InputMaybe<FormInputNumberParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single FormInputNumberParent document */
  upsert?: InputMaybe<FormInputNumberParentUpsertWithNestedWhereUniqueInput>;
};

export type FormInputNumberParentUpdateWithNestedWhereUniqueInput = {
  Form?: InputMaybe<FormUpdateWithNestedWhereUniqueInput>;
};

export type FormInputNumberParentUpsertWithNestedWhereUniqueInput = {
  Form?: InputMaybe<FormUpsertWithNestedWhereUniqueInput>;
};

export type FormInputNumberParentWhereInput = {
  Form?: InputMaybe<FormWhereInput>;
};

export type FormInputNumberParentWhereUniqueInput = {
  Form?: InputMaybe<FormWhereUniqueInput>;
};

export type FormInputNumberUpdateInput = {
  key?: InputMaybe<Scalars['String']>;
  /** label input for default locale (default) */
  label?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<FormInputNumberUpdateLocalizationsInput>;
  /** placeholder input for default locale (default) */
  placeholder?: InputMaybe<Scalars['String']>;
  required?: InputMaybe<Scalars['Boolean']>;
  rowSpan?: InputMaybe<Scalars['Boolean']>;
  validators?: InputMaybe<FormInputNumbervalidatorsUnionUpdateManyInlineInput>;
};

export type FormInputNumberUpdateLocalizationDataInput = {
  label?: InputMaybe<Scalars['String']>;
  placeholder?: InputMaybe<Scalars['String']>;
};

export type FormInputNumberUpdateLocalizationInput = {
  data: FormInputNumberUpdateLocalizationDataInput;
  locale: Locale;
};

export type FormInputNumberUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<FormInputNumberCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<FormInputNumberUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<FormInputNumberUpsertLocalizationInput>>;
};

export type FormInputNumberUpdateManyInlineInput = {
  /** Create and connect multiple FormInputNumber component instances */
  create?: InputMaybe<Array<FormInputNumberCreateWithPositionInput>>;
  /** Delete multiple FormInputNumber documents */
  delete?: InputMaybe<Array<FormInputNumberWhereUniqueInput>>;
  /** Update multiple FormInputNumber component instances */
  update?: InputMaybe<
    Array<FormInputNumberUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple FormInputNumber component instances */
  upsert?: InputMaybe<
    Array<FormInputNumberUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type FormInputNumberUpdateManyInput = {
  key?: InputMaybe<Scalars['String']>;
  /** label input for default locale (default) */
  label?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<FormInputNumberUpdateManyLocalizationsInput>;
  /** placeholder input for default locale (default) */
  placeholder?: InputMaybe<Scalars['String']>;
  required?: InputMaybe<Scalars['Boolean']>;
  rowSpan?: InputMaybe<Scalars['Boolean']>;
};

export type FormInputNumberUpdateManyLocalizationDataInput = {
  label?: InputMaybe<Scalars['String']>;
  placeholder?: InputMaybe<Scalars['String']>;
};

export type FormInputNumberUpdateManyLocalizationInput = {
  data: FormInputNumberUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type FormInputNumberUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<FormInputNumberUpdateManyLocalizationInput>>;
};

export type FormInputNumberUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: FormInputNumberUpdateManyInput;
  /** Document search */
  where: FormInputNumberWhereInput;
};

export type FormInputNumberUpdateOneInlineInput = {
  /** Create and connect one FormInputNumber document */
  create?: InputMaybe<FormInputNumberCreateInput>;
  /** Delete currently connected FormInputNumber document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single FormInputNumber document */
  update?: InputMaybe<FormInputNumberUpdateWithNestedWhereUniqueInput>;
  /** Upsert single FormInputNumber document */
  upsert?: InputMaybe<FormInputNumberUpsertWithNestedWhereUniqueInput>;
};

export type FormInputNumberUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<FormInputNumberUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: FormInputNumberWhereUniqueInput;
};

export type FormInputNumberUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: FormInputNumberUpdateInput;
  /** Unique document search */
  where: FormInputNumberWhereUniqueInput;
};

export type FormInputNumberUpsertInput = {
  /** Create document if it didn't exist */
  create: FormInputNumberCreateInput;
  /** Update document if it exists */
  update: FormInputNumberUpdateInput;
};

export type FormInputNumberUpsertLocalizationInput = {
  create: FormInputNumberCreateLocalizationDataInput;
  locale: Locale;
  update: FormInputNumberUpdateLocalizationDataInput;
};

export type FormInputNumberUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<FormInputNumberUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: FormInputNumberWhereUniqueInput;
};

export type FormInputNumberUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: FormInputNumberUpsertInput;
  /** Unique document search */
  where: FormInputNumberWhereUniqueInput;
};

/** Identifies documents */
export type FormInputNumberWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FormInputNumberWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FormInputNumberWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FormInputNumberWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  label_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  label_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  label_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  label_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  label_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  label_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  label_starts_with?: InputMaybe<Scalars['String']>;
  placeholder?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  placeholder_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  placeholder_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  placeholder_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  placeholder_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  placeholder_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  placeholder_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  placeholder_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  placeholder_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  placeholder_starts_with?: InputMaybe<Scalars['String']>;
  required?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  required_not?: InputMaybe<Scalars['Boolean']>;
  rowSpan?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  rowSpan_not?: InputMaybe<Scalars['Boolean']>;
  /** All values in which the union is empty. */
  validators_empty?: InputMaybe<Scalars['Boolean']>;
  /** Matches if the modular component contains at least one connection to the item provided to the filter */
  validators_some?: InputMaybe<FormInputNumbervalidatorsUnionWhereInput>;
};

/** References FormInputNumber record uniquely */
export type FormInputNumberWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type FormInputNumbervalidatorsUnion =
  | MaxNumberValidator
  | MinNumberValidator;

export type FormInputNumbervalidatorsUnionConnectInput = {
  MaxNumberValidator?: InputMaybe<MaxNumberValidatorConnectInput>;
  MinNumberValidator?: InputMaybe<MinNumberValidatorConnectInput>;
};

export type FormInputNumbervalidatorsUnionCreateInput = {
  MaxNumberValidator?: InputMaybe<MaxNumberValidatorCreateInput>;
  MinNumberValidator?: InputMaybe<MinNumberValidatorCreateInput>;
};

export type FormInputNumbervalidatorsUnionCreateManyInlineInput = {
  /** Create and connect multiple existing FormInputNumbervalidatorsUnion documents */
  create?: InputMaybe<Array<FormInputNumbervalidatorsUnionCreateInput>>;
};

export type FormInputNumbervalidatorsUnionCreateOneInlineInput = {
  /** Create and connect one FormInputNumbervalidatorsUnion document */
  create?: InputMaybe<FormInputNumbervalidatorsUnionCreateInput>;
};

export type FormInputNumbervalidatorsUnionCreateWithPositionInput = {
  MaxNumberValidator?: InputMaybe<MaxNumberValidatorCreateWithPositionInput>;
  MinNumberValidator?: InputMaybe<MinNumberValidatorCreateWithPositionInput>;
};

export type FormInputNumbervalidatorsUnionUpdateInput = {
  MaxNumberValidator?: InputMaybe<MaxNumberValidatorUpdateInput>;
  MinNumberValidator?: InputMaybe<MinNumberValidatorUpdateInput>;
};

export type FormInputNumbervalidatorsUnionUpdateManyInlineInput = {
  /** Create and connect multiple FormInputNumbervalidatorsUnion component instances */
  create?: InputMaybe<
    Array<FormInputNumbervalidatorsUnionCreateWithPositionInput>
  >;
  /** Delete multiple FormInputNumbervalidatorsUnion documents */
  delete?: InputMaybe<Array<FormInputNumbervalidatorsUnionWhereUniqueInput>>;
  /** Update multiple FormInputNumbervalidatorsUnion component instances */
  update?: InputMaybe<
    Array<FormInputNumbervalidatorsUnionUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple FormInputNumbervalidatorsUnion component instances */
  upsert?: InputMaybe<
    Array<FormInputNumbervalidatorsUnionUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type FormInputNumbervalidatorsUnionUpdateManyWithNestedWhereInput = {
  MaxNumberValidator?: InputMaybe<MaxNumberValidatorUpdateManyWithNestedWhereInput>;
  MinNumberValidator?: InputMaybe<MinNumberValidatorUpdateManyWithNestedWhereInput>;
};

export type FormInputNumbervalidatorsUnionUpdateOneInlineInput = {
  /** Create and connect one FormInputNumbervalidatorsUnion document */
  create?: InputMaybe<FormInputNumbervalidatorsUnionCreateInput>;
  /** Delete currently connected FormInputNumbervalidatorsUnion document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single FormInputNumbervalidatorsUnion document */
  update?: InputMaybe<FormInputNumbervalidatorsUnionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single FormInputNumbervalidatorsUnion document */
  upsert?: InputMaybe<FormInputNumbervalidatorsUnionUpsertWithNestedWhereUniqueInput>;
};

export type FormInputNumbervalidatorsUnionUpdateWithNestedWhereUniqueAndPositionInput =
  {
    MaxNumberValidator?: InputMaybe<MaxNumberValidatorUpdateWithNestedWhereUniqueAndPositionInput>;
    MinNumberValidator?: InputMaybe<MinNumberValidatorUpdateWithNestedWhereUniqueAndPositionInput>;
  };

export type FormInputNumbervalidatorsUnionUpdateWithNestedWhereUniqueInput = {
  MaxNumberValidator?: InputMaybe<MaxNumberValidatorUpdateWithNestedWhereUniqueInput>;
  MinNumberValidator?: InputMaybe<MinNumberValidatorUpdateWithNestedWhereUniqueInput>;
};

export type FormInputNumbervalidatorsUnionUpsertWithNestedWhereUniqueAndPositionInput =
  {
    MaxNumberValidator?: InputMaybe<MaxNumberValidatorUpsertWithNestedWhereUniqueAndPositionInput>;
    MinNumberValidator?: InputMaybe<MinNumberValidatorUpsertWithNestedWhereUniqueAndPositionInput>;
  };

export type FormInputNumbervalidatorsUnionUpsertWithNestedWhereUniqueInput = {
  MaxNumberValidator?: InputMaybe<MaxNumberValidatorUpsertWithNestedWhereUniqueInput>;
  MinNumberValidator?: InputMaybe<MinNumberValidatorUpsertWithNestedWhereUniqueInput>;
};

export type FormInputNumbervalidatorsUnionWhereInput = {
  MaxNumberValidator?: InputMaybe<MaxNumberValidatorWhereInput>;
  MinNumberValidator?: InputMaybe<MinNumberValidatorWhereInput>;
};

export type FormInputNumbervalidatorsUnionWhereUniqueInput = {
  MaxNumberValidator?: InputMaybe<MaxNumberValidatorWhereUniqueInput>;
  MinNumberValidator?: InputMaybe<MinNumberValidatorWhereUniqueInput>;
};

/** Component for textfield with text type */
export type FormInputText = Entity & {
  __typename?: 'FormInputText';
  /** The unique identifier */
  id: Scalars['ID'];
  /** This is the key in the JSON payload, and it should match Salesforce in the JSON payload. */
  key: Scalars['String'];
  /** Field label that will appear to the user */
  label: Scalars['String'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<FormInputText>;
  placeholder?: Maybe<Scalars['String']>;
  required: Scalars['Boolean'];
  /** Allow the field to span the entire width of the row, applicable only when the number of form columns exceeds "1" */
  rowSpan: Scalars['Boolean'];
  /** System stage field */
  stage: Stage;
  validators: Array<FormInputTextvalidatorsUnion>;
};

/** Component for textfield with text type */
export type FormInputTextLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

/** Component for textfield with text type */
export type FormInputTextValidatorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type FormInputTextConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: FormInputTextWhereUniqueInput;
};

/** A connection to a list of items. */
export type FormInputTextConnection = {
  __typename?: 'FormInputTextConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<FormInputTextEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type FormInputTextCreateInput = {
  key: Scalars['String'];
  /** label input for default locale (default) */
  label: Scalars['String'];
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<FormInputTextCreateLocalizationsInput>;
  /** placeholder input for default locale (default) */
  placeholder?: InputMaybe<Scalars['String']>;
  required: Scalars['Boolean'];
  rowSpan: Scalars['Boolean'];
  validators?: InputMaybe<FormInputTextvalidatorsUnionCreateManyInlineInput>;
};

export type FormInputTextCreateLocalizationDataInput = {
  label: Scalars['String'];
  placeholder?: InputMaybe<Scalars['String']>;
};

export type FormInputTextCreateLocalizationInput = {
  /** Localization input */
  data: FormInputTextCreateLocalizationDataInput;
  locale: Locale;
};

export type FormInputTextCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<FormInputTextCreateLocalizationInput>>;
};

export type FormInputTextCreateManyInlineInput = {
  /** Create and connect multiple existing FormInputText documents */
  create?: InputMaybe<Array<FormInputTextCreateInput>>;
};

export type FormInputTextCreateOneInlineInput = {
  /** Create and connect one FormInputText document */
  create?: InputMaybe<FormInputTextCreateInput>;
};

export type FormInputTextCreateWithPositionInput = {
  /** Document to create */
  data: FormInputTextCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type FormInputTextEdge = {
  __typename?: 'FormInputTextEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: FormInputText;
};

/** Identifies documents */
export type FormInputTextManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FormInputTextWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FormInputTextWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FormInputTextWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  required?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  required_not?: InputMaybe<Scalars['Boolean']>;
  rowSpan?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  rowSpan_not?: InputMaybe<Scalars['Boolean']>;
  /** All values in which the union is empty. */
  validators_empty?: InputMaybe<Scalars['Boolean']>;
  /** Matches if the modular component contains at least one connection to the item provided to the filter */
  validators_some?: InputMaybe<FormInputTextvalidatorsUnionWhereInput>;
};

export enum FormInputTextOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  PlaceholderAsc = 'placeholder_ASC',
  PlaceholderDesc = 'placeholder_DESC',
  RequiredAsc = 'required_ASC',
  RequiredDesc = 'required_DESC',
  RowSpanAsc = 'rowSpan_ASC',
  RowSpanDesc = 'rowSpan_DESC',
}

export type FormInputTextParent = Form;

export type FormInputTextParentConnectInput = {
  Form?: InputMaybe<FormConnectInput>;
};

export type FormInputTextParentCreateInput = {
  Form?: InputMaybe<FormCreateInput>;
};

export type FormInputTextParentCreateManyInlineInput = {
  /** Connect multiple existing FormInputTextParent documents */
  connect?: InputMaybe<Array<FormInputTextParentWhereUniqueInput>>;
  /** Create and connect multiple existing FormInputTextParent documents */
  create?: InputMaybe<Array<FormInputTextParentCreateInput>>;
};

export type FormInputTextParentCreateOneInlineInput = {
  /** Connect one existing FormInputTextParent document */
  connect?: InputMaybe<FormInputTextParentWhereUniqueInput>;
  /** Create and connect one FormInputTextParent document */
  create?: InputMaybe<FormInputTextParentCreateInput>;
};

export type FormInputTextParentUpdateInput = {
  Form?: InputMaybe<FormUpdateInput>;
};

export type FormInputTextParentUpdateManyInlineInput = {
  /** Connect multiple existing FormInputTextParent documents */
  connect?: InputMaybe<Array<FormInputTextParentConnectInput>>;
  /** Create and connect multiple FormInputTextParent documents */
  create?: InputMaybe<Array<FormInputTextParentCreateInput>>;
  /** Delete multiple FormInputTextParent documents */
  delete?: InputMaybe<Array<FormInputTextParentWhereUniqueInput>>;
  /** Disconnect multiple FormInputTextParent documents */
  disconnect?: InputMaybe<Array<FormInputTextParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing FormInputTextParent documents */
  set?: InputMaybe<Array<FormInputTextParentWhereUniqueInput>>;
  /** Update multiple FormInputTextParent documents */
  update?: InputMaybe<
    Array<FormInputTextParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple FormInputTextParent documents */
  upsert?: InputMaybe<
    Array<FormInputTextParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type FormInputTextParentUpdateManyWithNestedWhereInput = {
  Form?: InputMaybe<FormUpdateManyWithNestedWhereInput>;
};

export type FormInputTextParentUpdateOneInlineInput = {
  /** Connect existing FormInputTextParent document */
  connect?: InputMaybe<FormInputTextParentWhereUniqueInput>;
  /** Create and connect one FormInputTextParent document */
  create?: InputMaybe<FormInputTextParentCreateInput>;
  /** Delete currently connected FormInputTextParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected FormInputTextParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single FormInputTextParent document */
  update?: InputMaybe<FormInputTextParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single FormInputTextParent document */
  upsert?: InputMaybe<FormInputTextParentUpsertWithNestedWhereUniqueInput>;
};

export type FormInputTextParentUpdateWithNestedWhereUniqueInput = {
  Form?: InputMaybe<FormUpdateWithNestedWhereUniqueInput>;
};

export type FormInputTextParentUpsertWithNestedWhereUniqueInput = {
  Form?: InputMaybe<FormUpsertWithNestedWhereUniqueInput>;
};

export type FormInputTextParentWhereInput = {
  Form?: InputMaybe<FormWhereInput>;
};

export type FormInputTextParentWhereUniqueInput = {
  Form?: InputMaybe<FormWhereUniqueInput>;
};

export type FormInputTextUpdateInput = {
  key?: InputMaybe<Scalars['String']>;
  /** label input for default locale (default) */
  label?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<FormInputTextUpdateLocalizationsInput>;
  /** placeholder input for default locale (default) */
  placeholder?: InputMaybe<Scalars['String']>;
  required?: InputMaybe<Scalars['Boolean']>;
  rowSpan?: InputMaybe<Scalars['Boolean']>;
  validators?: InputMaybe<FormInputTextvalidatorsUnionUpdateManyInlineInput>;
};

export type FormInputTextUpdateLocalizationDataInput = {
  label?: InputMaybe<Scalars['String']>;
  placeholder?: InputMaybe<Scalars['String']>;
};

export type FormInputTextUpdateLocalizationInput = {
  data: FormInputTextUpdateLocalizationDataInput;
  locale: Locale;
};

export type FormInputTextUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<FormInputTextCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<FormInputTextUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<FormInputTextUpsertLocalizationInput>>;
};

export type FormInputTextUpdateManyInlineInput = {
  /** Create and connect multiple FormInputText component instances */
  create?: InputMaybe<Array<FormInputTextCreateWithPositionInput>>;
  /** Delete multiple FormInputText documents */
  delete?: InputMaybe<Array<FormInputTextWhereUniqueInput>>;
  /** Update multiple FormInputText component instances */
  update?: InputMaybe<
    Array<FormInputTextUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple FormInputText component instances */
  upsert?: InputMaybe<
    Array<FormInputTextUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type FormInputTextUpdateManyInput = {
  key?: InputMaybe<Scalars['String']>;
  /** label input for default locale (default) */
  label?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<FormInputTextUpdateManyLocalizationsInput>;
  /** placeholder input for default locale (default) */
  placeholder?: InputMaybe<Scalars['String']>;
  required?: InputMaybe<Scalars['Boolean']>;
  rowSpan?: InputMaybe<Scalars['Boolean']>;
};

export type FormInputTextUpdateManyLocalizationDataInput = {
  label?: InputMaybe<Scalars['String']>;
  placeholder?: InputMaybe<Scalars['String']>;
};

export type FormInputTextUpdateManyLocalizationInput = {
  data: FormInputTextUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type FormInputTextUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<FormInputTextUpdateManyLocalizationInput>>;
};

export type FormInputTextUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: FormInputTextUpdateManyInput;
  /** Document search */
  where: FormInputTextWhereInput;
};

export type FormInputTextUpdateOneInlineInput = {
  /** Create and connect one FormInputText document */
  create?: InputMaybe<FormInputTextCreateInput>;
  /** Delete currently connected FormInputText document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single FormInputText document */
  update?: InputMaybe<FormInputTextUpdateWithNestedWhereUniqueInput>;
  /** Upsert single FormInputText document */
  upsert?: InputMaybe<FormInputTextUpsertWithNestedWhereUniqueInput>;
};

export type FormInputTextUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<FormInputTextUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: FormInputTextWhereUniqueInput;
};

export type FormInputTextUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: FormInputTextUpdateInput;
  /** Unique document search */
  where: FormInputTextWhereUniqueInput;
};

export type FormInputTextUpsertInput = {
  /** Create document if it didn't exist */
  create: FormInputTextCreateInput;
  /** Update document if it exists */
  update: FormInputTextUpdateInput;
};

export type FormInputTextUpsertLocalizationInput = {
  create: FormInputTextCreateLocalizationDataInput;
  locale: Locale;
  update: FormInputTextUpdateLocalizationDataInput;
};

export type FormInputTextUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<FormInputTextUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: FormInputTextWhereUniqueInput;
};

export type FormInputTextUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: FormInputTextUpsertInput;
  /** Unique document search */
  where: FormInputTextWhereUniqueInput;
};

/** Identifies documents */
export type FormInputTextWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FormInputTextWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FormInputTextWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FormInputTextWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  label_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  label_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  label_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  label_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  label_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  label_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  label_starts_with?: InputMaybe<Scalars['String']>;
  placeholder?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  placeholder_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  placeholder_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  placeholder_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  placeholder_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  placeholder_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  placeholder_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  placeholder_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  placeholder_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  placeholder_starts_with?: InputMaybe<Scalars['String']>;
  required?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  required_not?: InputMaybe<Scalars['Boolean']>;
  rowSpan?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  rowSpan_not?: InputMaybe<Scalars['Boolean']>;
  /** All values in which the union is empty. */
  validators_empty?: InputMaybe<Scalars['Boolean']>;
  /** Matches if the modular component contains at least one connection to the item provided to the filter */
  validators_some?: InputMaybe<FormInputTextvalidatorsUnionWhereInput>;
};

/** References FormInputText record uniquely */
export type FormInputTextWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type FormInputTextvalidatorsUnion =
  | MaxLengthValidator
  | MinLengthValidator;

export type FormInputTextvalidatorsUnionConnectInput = {
  MaxLengthValidator?: InputMaybe<MaxLengthValidatorConnectInput>;
  MinLengthValidator?: InputMaybe<MinLengthValidatorConnectInput>;
};

export type FormInputTextvalidatorsUnionCreateInput = {
  MaxLengthValidator?: InputMaybe<MaxLengthValidatorCreateInput>;
  MinLengthValidator?: InputMaybe<MinLengthValidatorCreateInput>;
};

export type FormInputTextvalidatorsUnionCreateManyInlineInput = {
  /** Create and connect multiple existing FormInputTextvalidatorsUnion documents */
  create?: InputMaybe<Array<FormInputTextvalidatorsUnionCreateInput>>;
};

export type FormInputTextvalidatorsUnionCreateOneInlineInput = {
  /** Create and connect one FormInputTextvalidatorsUnion document */
  create?: InputMaybe<FormInputTextvalidatorsUnionCreateInput>;
};

export type FormInputTextvalidatorsUnionCreateWithPositionInput = {
  MaxLengthValidator?: InputMaybe<MaxLengthValidatorCreateWithPositionInput>;
  MinLengthValidator?: InputMaybe<MinLengthValidatorCreateWithPositionInput>;
};

export type FormInputTextvalidatorsUnionUpdateInput = {
  MaxLengthValidator?: InputMaybe<MaxLengthValidatorUpdateInput>;
  MinLengthValidator?: InputMaybe<MinLengthValidatorUpdateInput>;
};

export type FormInputTextvalidatorsUnionUpdateManyInlineInput = {
  /** Create and connect multiple FormInputTextvalidatorsUnion component instances */
  create?: InputMaybe<
    Array<FormInputTextvalidatorsUnionCreateWithPositionInput>
  >;
  /** Delete multiple FormInputTextvalidatorsUnion documents */
  delete?: InputMaybe<Array<FormInputTextvalidatorsUnionWhereUniqueInput>>;
  /** Update multiple FormInputTextvalidatorsUnion component instances */
  update?: InputMaybe<
    Array<FormInputTextvalidatorsUnionUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple FormInputTextvalidatorsUnion component instances */
  upsert?: InputMaybe<
    Array<FormInputTextvalidatorsUnionUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type FormInputTextvalidatorsUnionUpdateManyWithNestedWhereInput = {
  MaxLengthValidator?: InputMaybe<MaxLengthValidatorUpdateManyWithNestedWhereInput>;
  MinLengthValidator?: InputMaybe<MinLengthValidatorUpdateManyWithNestedWhereInput>;
};

export type FormInputTextvalidatorsUnionUpdateOneInlineInput = {
  /** Create and connect one FormInputTextvalidatorsUnion document */
  create?: InputMaybe<FormInputTextvalidatorsUnionCreateInput>;
  /** Delete currently connected FormInputTextvalidatorsUnion document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single FormInputTextvalidatorsUnion document */
  update?: InputMaybe<FormInputTextvalidatorsUnionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single FormInputTextvalidatorsUnion document */
  upsert?: InputMaybe<FormInputTextvalidatorsUnionUpsertWithNestedWhereUniqueInput>;
};

export type FormInputTextvalidatorsUnionUpdateWithNestedWhereUniqueAndPositionInput =
  {
    MaxLengthValidator?: InputMaybe<MaxLengthValidatorUpdateWithNestedWhereUniqueAndPositionInput>;
    MinLengthValidator?: InputMaybe<MinLengthValidatorUpdateWithNestedWhereUniqueAndPositionInput>;
  };

export type FormInputTextvalidatorsUnionUpdateWithNestedWhereUniqueInput = {
  MaxLengthValidator?: InputMaybe<MaxLengthValidatorUpdateWithNestedWhereUniqueInput>;
  MinLengthValidator?: InputMaybe<MinLengthValidatorUpdateWithNestedWhereUniqueInput>;
};

export type FormInputTextvalidatorsUnionUpsertWithNestedWhereUniqueAndPositionInput =
  {
    MaxLengthValidator?: InputMaybe<MaxLengthValidatorUpsertWithNestedWhereUniqueAndPositionInput>;
    MinLengthValidator?: InputMaybe<MinLengthValidatorUpsertWithNestedWhereUniqueAndPositionInput>;
  };

export type FormInputTextvalidatorsUnionUpsertWithNestedWhereUniqueInput = {
  MaxLengthValidator?: InputMaybe<MaxLengthValidatorUpsertWithNestedWhereUniqueInput>;
  MinLengthValidator?: InputMaybe<MinLengthValidatorUpsertWithNestedWhereUniqueInput>;
};

export type FormInputTextvalidatorsUnionWhereInput = {
  MaxLengthValidator?: InputMaybe<MaxLengthValidatorWhereInput>;
  MinLengthValidator?: InputMaybe<MinLengthValidatorWhereInput>;
};

export type FormInputTextvalidatorsUnionWhereUniqueInput = {
  MaxLengthValidator?: InputMaybe<MaxLengthValidatorWhereUniqueInput>;
  MinLengthValidator?: InputMaybe<MinLengthValidatorWhereUniqueInput>;
};

/** Identifies documents */
export type FormManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FormWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FormWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FormWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  apiEndpoint?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  apiEndpoint_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  apiEndpoint_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  apiEndpoint_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  apiEndpoint_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  apiEndpoint_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  apiEndpoint_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  apiEndpoint_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  apiEndpoint_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  apiEndpoint_starts_with?: InputMaybe<Scalars['String']>;
  buttonColour?: InputMaybe<Colour>;
  /** All values that are contained in given list. */
  buttonColour_in?: InputMaybe<Array<InputMaybe<Colour>>>;
  /** Any other value that exists and is not equal to the given value. */
  buttonColour_not?: InputMaybe<Colour>;
  /** All values that are not contained in given list. */
  buttonColour_not_in?: InputMaybe<Array<InputMaybe<Colour>>>;
  columns?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  columns_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  columns_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  columns_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** All values less than the given value. */
  columns_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  columns_lte?: InputMaybe<Scalars['Int']>;
  /** Any other value that exists and is not equal to the given value. */
  columns_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  columns_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<FormWhereStageInput>;
  documentInStages_none?: InputMaybe<FormWhereStageInput>;
  documentInStages_some?: InputMaybe<FormWhereStageInput>;
  /** All values in which the union is empty. */
  fields_empty?: InputMaybe<Scalars['Boolean']>;
  /** Matches if the modular component contains at least one connection to the item provided to the filter */
  fields_some?: InputMaybe<FormfieldsUnionWhereInput>;
  formType?: InputMaybe<CFormType>;
  /** All values that are contained in given list. */
  formType_in?: InputMaybe<Array<InputMaybe<CFormType>>>;
  /** Any other value that exists and is not equal to the given value. */
  formType_not?: InputMaybe<CFormType>;
  /** All values that are not contained in given list. */
  formType_not_in?: InputMaybe<Array<InputMaybe<CFormType>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  sites_every?: InputMaybe<SiteWhereInput>;
  sites_none?: InputMaybe<SiteWhereInput>;
  sites_some?: InputMaybe<SiteWhereInput>;
  skipDefaultLocale?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  skipDefaultLocale_not?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum FormOrderByInput {
  ApiEndpointAsc = 'apiEndpoint_ASC',
  ApiEndpointDesc = 'apiEndpoint_DESC',
  ButtonColourAsc = 'buttonColour_ASC',
  ButtonColourDesc = 'buttonColour_DESC',
  ColumnsAsc = 'columns_ASC',
  ColumnsDesc = 'columns_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  DisclaimerTextAsc = 'disclaimerText_ASC',
  DisclaimerTextDesc = 'disclaimerText_DESC',
  FormTypeAsc = 'formType_ASC',
  FormTypeDesc = 'formType_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SkipDefaultLocaleAsc = 'skipDefaultLocale_ASC',
  SkipDefaultLocaleDesc = 'skipDefaultLocale_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** Component for dropdown */
export type FormSelect = Entity & {
  __typename?: 'FormSelect';
  /** The unique identifier */
  id: Scalars['ID'];
  /** This is the key in the JSON payload, and it should match Salesforce in the JSON payload. */
  key: Scalars['String'];
  /** Field label that will appear to the user */
  label: Scalars['String'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<FormSelect>;
  options: Array<FieldOption>;
  placeholder?: Maybe<Scalars['String']>;
  required: Scalars['Boolean'];
  /** Allow the field to span the entire width of the row, applicable only when the number of form columns exceeds "1" */
  rowSpan: Scalars['Boolean'];
  /** System stage field */
  stage: Stage;
};

/** Component for dropdown */
export type FormSelectLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

/** Component for dropdown */
export type FormSelectOptionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<FieldOptionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FieldOptionWhereInput>;
};

export type FormSelectConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: FormSelectWhereUniqueInput;
};

/** A connection to a list of items. */
export type FormSelectConnection = {
  __typename?: 'FormSelectConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<FormSelectEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type FormSelectCreateInput = {
  key: Scalars['String'];
  /** label input for default locale (default) */
  label: Scalars['String'];
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<FormSelectCreateLocalizationsInput>;
  options?: InputMaybe<FieldOptionCreateManyInlineInput>;
  /** placeholder input for default locale (default) */
  placeholder?: InputMaybe<Scalars['String']>;
  required: Scalars['Boolean'];
  rowSpan: Scalars['Boolean'];
};

export type FormSelectCreateLocalizationDataInput = {
  label: Scalars['String'];
  placeholder?: InputMaybe<Scalars['String']>;
};

export type FormSelectCreateLocalizationInput = {
  /** Localization input */
  data: FormSelectCreateLocalizationDataInput;
  locale: Locale;
};

export type FormSelectCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<FormSelectCreateLocalizationInput>>;
};

export type FormSelectCreateManyInlineInput = {
  /** Create and connect multiple existing FormSelect documents */
  create?: InputMaybe<Array<FormSelectCreateInput>>;
};

export type FormSelectCreateOneInlineInput = {
  /** Create and connect one FormSelect document */
  create?: InputMaybe<FormSelectCreateInput>;
};

export type FormSelectCreateWithPositionInput = {
  /** Document to create */
  data: FormSelectCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type FormSelectEdge = {
  __typename?: 'FormSelectEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: FormSelect;
};

/** Identifies documents */
export type FormSelectManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FormSelectWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FormSelectWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FormSelectWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  options_every?: InputMaybe<FieldOptionWhereInput>;
  options_none?: InputMaybe<FieldOptionWhereInput>;
  options_some?: InputMaybe<FieldOptionWhereInput>;
  required?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  required_not?: InputMaybe<Scalars['Boolean']>;
  rowSpan?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  rowSpan_not?: InputMaybe<Scalars['Boolean']>;
};

export enum FormSelectOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  PlaceholderAsc = 'placeholder_ASC',
  PlaceholderDesc = 'placeholder_DESC',
  RequiredAsc = 'required_ASC',
  RequiredDesc = 'required_DESC',
  RowSpanAsc = 'rowSpan_ASC',
  RowSpanDesc = 'rowSpan_DESC',
}

export type FormSelectParent = Form;

export type FormSelectParentConnectInput = {
  Form?: InputMaybe<FormConnectInput>;
};

export type FormSelectParentCreateInput = {
  Form?: InputMaybe<FormCreateInput>;
};

export type FormSelectParentCreateManyInlineInput = {
  /** Connect multiple existing FormSelectParent documents */
  connect?: InputMaybe<Array<FormSelectParentWhereUniqueInput>>;
  /** Create and connect multiple existing FormSelectParent documents */
  create?: InputMaybe<Array<FormSelectParentCreateInput>>;
};

export type FormSelectParentCreateOneInlineInput = {
  /** Connect one existing FormSelectParent document */
  connect?: InputMaybe<FormSelectParentWhereUniqueInput>;
  /** Create and connect one FormSelectParent document */
  create?: InputMaybe<FormSelectParentCreateInput>;
};

export type FormSelectParentUpdateInput = {
  Form?: InputMaybe<FormUpdateInput>;
};

export type FormSelectParentUpdateManyInlineInput = {
  /** Connect multiple existing FormSelectParent documents */
  connect?: InputMaybe<Array<FormSelectParentConnectInput>>;
  /** Create and connect multiple FormSelectParent documents */
  create?: InputMaybe<Array<FormSelectParentCreateInput>>;
  /** Delete multiple FormSelectParent documents */
  delete?: InputMaybe<Array<FormSelectParentWhereUniqueInput>>;
  /** Disconnect multiple FormSelectParent documents */
  disconnect?: InputMaybe<Array<FormSelectParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing FormSelectParent documents */
  set?: InputMaybe<Array<FormSelectParentWhereUniqueInput>>;
  /** Update multiple FormSelectParent documents */
  update?: InputMaybe<Array<FormSelectParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple FormSelectParent documents */
  upsert?: InputMaybe<Array<FormSelectParentUpsertWithNestedWhereUniqueInput>>;
};

export type FormSelectParentUpdateManyWithNestedWhereInput = {
  Form?: InputMaybe<FormUpdateManyWithNestedWhereInput>;
};

export type FormSelectParentUpdateOneInlineInput = {
  /** Connect existing FormSelectParent document */
  connect?: InputMaybe<FormSelectParentWhereUniqueInput>;
  /** Create and connect one FormSelectParent document */
  create?: InputMaybe<FormSelectParentCreateInput>;
  /** Delete currently connected FormSelectParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected FormSelectParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single FormSelectParent document */
  update?: InputMaybe<FormSelectParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single FormSelectParent document */
  upsert?: InputMaybe<FormSelectParentUpsertWithNestedWhereUniqueInput>;
};

export type FormSelectParentUpdateWithNestedWhereUniqueInput = {
  Form?: InputMaybe<FormUpdateWithNestedWhereUniqueInput>;
};

export type FormSelectParentUpsertWithNestedWhereUniqueInput = {
  Form?: InputMaybe<FormUpsertWithNestedWhereUniqueInput>;
};

export type FormSelectParentWhereInput = {
  Form?: InputMaybe<FormWhereInput>;
};

export type FormSelectParentWhereUniqueInput = {
  Form?: InputMaybe<FormWhereUniqueInput>;
};

export type FormSelectUpdateInput = {
  key?: InputMaybe<Scalars['String']>;
  /** label input for default locale (default) */
  label?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<FormSelectUpdateLocalizationsInput>;
  options?: InputMaybe<FieldOptionUpdateManyInlineInput>;
  /** placeholder input for default locale (default) */
  placeholder?: InputMaybe<Scalars['String']>;
  required?: InputMaybe<Scalars['Boolean']>;
  rowSpan?: InputMaybe<Scalars['Boolean']>;
};

export type FormSelectUpdateLocalizationDataInput = {
  label?: InputMaybe<Scalars['String']>;
  placeholder?: InputMaybe<Scalars['String']>;
};

export type FormSelectUpdateLocalizationInput = {
  data: FormSelectUpdateLocalizationDataInput;
  locale: Locale;
};

export type FormSelectUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<FormSelectCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<FormSelectUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<FormSelectUpsertLocalizationInput>>;
};

export type FormSelectUpdateManyInlineInput = {
  /** Create and connect multiple FormSelect component instances */
  create?: InputMaybe<Array<FormSelectCreateWithPositionInput>>;
  /** Delete multiple FormSelect documents */
  delete?: InputMaybe<Array<FormSelectWhereUniqueInput>>;
  /** Update multiple FormSelect component instances */
  update?: InputMaybe<
    Array<FormSelectUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple FormSelect component instances */
  upsert?: InputMaybe<
    Array<FormSelectUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type FormSelectUpdateManyInput = {
  key?: InputMaybe<Scalars['String']>;
  /** label input for default locale (default) */
  label?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<FormSelectUpdateManyLocalizationsInput>;
  /** placeholder input for default locale (default) */
  placeholder?: InputMaybe<Scalars['String']>;
  required?: InputMaybe<Scalars['Boolean']>;
  rowSpan?: InputMaybe<Scalars['Boolean']>;
};

export type FormSelectUpdateManyLocalizationDataInput = {
  label?: InputMaybe<Scalars['String']>;
  placeholder?: InputMaybe<Scalars['String']>;
};

export type FormSelectUpdateManyLocalizationInput = {
  data: FormSelectUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type FormSelectUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<FormSelectUpdateManyLocalizationInput>>;
};

export type FormSelectUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: FormSelectUpdateManyInput;
  /** Document search */
  where: FormSelectWhereInput;
};

export type FormSelectUpdateOneInlineInput = {
  /** Create and connect one FormSelect document */
  create?: InputMaybe<FormSelectCreateInput>;
  /** Delete currently connected FormSelect document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single FormSelect document */
  update?: InputMaybe<FormSelectUpdateWithNestedWhereUniqueInput>;
  /** Upsert single FormSelect document */
  upsert?: InputMaybe<FormSelectUpsertWithNestedWhereUniqueInput>;
};

export type FormSelectUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<FormSelectUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: FormSelectWhereUniqueInput;
};

export type FormSelectUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: FormSelectUpdateInput;
  /** Unique document search */
  where: FormSelectWhereUniqueInput;
};

export type FormSelectUpsertInput = {
  /** Create document if it didn't exist */
  create: FormSelectCreateInput;
  /** Update document if it exists */
  update: FormSelectUpdateInput;
};

export type FormSelectUpsertLocalizationInput = {
  create: FormSelectCreateLocalizationDataInput;
  locale: Locale;
  update: FormSelectUpdateLocalizationDataInput;
};

export type FormSelectUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<FormSelectUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: FormSelectWhereUniqueInput;
};

export type FormSelectUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: FormSelectUpsertInput;
  /** Unique document search */
  where: FormSelectWhereUniqueInput;
};

/** Identifies documents */
export type FormSelectWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FormSelectWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FormSelectWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FormSelectWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  label_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  label_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  label_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  label_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  label_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  label_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  label_starts_with?: InputMaybe<Scalars['String']>;
  options_every?: InputMaybe<FieldOptionWhereInput>;
  options_none?: InputMaybe<FieldOptionWhereInput>;
  options_some?: InputMaybe<FieldOptionWhereInput>;
  placeholder?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  placeholder_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  placeholder_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  placeholder_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  placeholder_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  placeholder_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  placeholder_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  placeholder_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  placeholder_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  placeholder_starts_with?: InputMaybe<Scalars['String']>;
  required?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  required_not?: InputMaybe<Scalars['Boolean']>;
  rowSpan?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  rowSpan_not?: InputMaybe<Scalars['Boolean']>;
};

/** References FormSelect record uniquely */
export type FormSelectWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Component for textarea */
export type FormTextarea = Entity & {
  __typename?: 'FormTextarea';
  /** The unique identifier */
  id: Scalars['ID'];
  /** This is the key in the JSON payload, and it should match the key in the configured payload for Salesforce. */
  key: Scalars['String'];
  /** Field label that will appear to the user */
  label: Scalars['String'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<FormTextarea>;
  placeholder?: Maybe<Scalars['String']>;
  required: Scalars['Boolean'];
  /** System stage field */
  stage: Stage;
  validators: Array<FormTextareavalidatorsUnion>;
};

/** Component for textarea */
export type FormTextareaLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

/** Component for textarea */
export type FormTextareaValidatorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type FormTextareaConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: FormTextareaWhereUniqueInput;
};

/** A connection to a list of items. */
export type FormTextareaConnection = {
  __typename?: 'FormTextareaConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<FormTextareaEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type FormTextareaCreateInput = {
  key: Scalars['String'];
  /** label input for default locale (default) */
  label: Scalars['String'];
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<FormTextareaCreateLocalizationsInput>;
  /** placeholder input for default locale (default) */
  placeholder?: InputMaybe<Scalars['String']>;
  required: Scalars['Boolean'];
  validators?: InputMaybe<FormTextareavalidatorsUnionCreateManyInlineInput>;
};

export type FormTextareaCreateLocalizationDataInput = {
  label: Scalars['String'];
  placeholder?: InputMaybe<Scalars['String']>;
};

export type FormTextareaCreateLocalizationInput = {
  /** Localization input */
  data: FormTextareaCreateLocalizationDataInput;
  locale: Locale;
};

export type FormTextareaCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<FormTextareaCreateLocalizationInput>>;
};

export type FormTextareaCreateManyInlineInput = {
  /** Create and connect multiple existing FormTextarea documents */
  create?: InputMaybe<Array<FormTextareaCreateInput>>;
};

export type FormTextareaCreateOneInlineInput = {
  /** Create and connect one FormTextarea document */
  create?: InputMaybe<FormTextareaCreateInput>;
};

export type FormTextareaCreateWithPositionInput = {
  /** Document to create */
  data: FormTextareaCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type FormTextareaEdge = {
  __typename?: 'FormTextareaEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: FormTextarea;
};

/** Identifies documents */
export type FormTextareaManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FormTextareaWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FormTextareaWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FormTextareaWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  required?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  required_not?: InputMaybe<Scalars['Boolean']>;
  /** All values in which the union is empty. */
  validators_empty?: InputMaybe<Scalars['Boolean']>;
  /** Matches if the modular component contains at least one connection to the item provided to the filter */
  validators_some?: InputMaybe<FormTextareavalidatorsUnionWhereInput>;
};

export enum FormTextareaOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
  PlaceholderAsc = 'placeholder_ASC',
  PlaceholderDesc = 'placeholder_DESC',
  RequiredAsc = 'required_ASC',
  RequiredDesc = 'required_DESC',
}

export type FormTextareaParent = Form;

export type FormTextareaParentConnectInput = {
  Form?: InputMaybe<FormConnectInput>;
};

export type FormTextareaParentCreateInput = {
  Form?: InputMaybe<FormCreateInput>;
};

export type FormTextareaParentCreateManyInlineInput = {
  /** Connect multiple existing FormTextareaParent documents */
  connect?: InputMaybe<Array<FormTextareaParentWhereUniqueInput>>;
  /** Create and connect multiple existing FormTextareaParent documents */
  create?: InputMaybe<Array<FormTextareaParentCreateInput>>;
};

export type FormTextareaParentCreateOneInlineInput = {
  /** Connect one existing FormTextareaParent document */
  connect?: InputMaybe<FormTextareaParentWhereUniqueInput>;
  /** Create and connect one FormTextareaParent document */
  create?: InputMaybe<FormTextareaParentCreateInput>;
};

export type FormTextareaParentUpdateInput = {
  Form?: InputMaybe<FormUpdateInput>;
};

export type FormTextareaParentUpdateManyInlineInput = {
  /** Connect multiple existing FormTextareaParent documents */
  connect?: InputMaybe<Array<FormTextareaParentConnectInput>>;
  /** Create and connect multiple FormTextareaParent documents */
  create?: InputMaybe<Array<FormTextareaParentCreateInput>>;
  /** Delete multiple FormTextareaParent documents */
  delete?: InputMaybe<Array<FormTextareaParentWhereUniqueInput>>;
  /** Disconnect multiple FormTextareaParent documents */
  disconnect?: InputMaybe<Array<FormTextareaParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing FormTextareaParent documents */
  set?: InputMaybe<Array<FormTextareaParentWhereUniqueInput>>;
  /** Update multiple FormTextareaParent documents */
  update?: InputMaybe<
    Array<FormTextareaParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple FormTextareaParent documents */
  upsert?: InputMaybe<
    Array<FormTextareaParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type FormTextareaParentUpdateManyWithNestedWhereInput = {
  Form?: InputMaybe<FormUpdateManyWithNestedWhereInput>;
};

export type FormTextareaParentUpdateOneInlineInput = {
  /** Connect existing FormTextareaParent document */
  connect?: InputMaybe<FormTextareaParentWhereUniqueInput>;
  /** Create and connect one FormTextareaParent document */
  create?: InputMaybe<FormTextareaParentCreateInput>;
  /** Delete currently connected FormTextareaParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected FormTextareaParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single FormTextareaParent document */
  update?: InputMaybe<FormTextareaParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single FormTextareaParent document */
  upsert?: InputMaybe<FormTextareaParentUpsertWithNestedWhereUniqueInput>;
};

export type FormTextareaParentUpdateWithNestedWhereUniqueInput = {
  Form?: InputMaybe<FormUpdateWithNestedWhereUniqueInput>;
};

export type FormTextareaParentUpsertWithNestedWhereUniqueInput = {
  Form?: InputMaybe<FormUpsertWithNestedWhereUniqueInput>;
};

export type FormTextareaParentWhereInput = {
  Form?: InputMaybe<FormWhereInput>;
};

export type FormTextareaParentWhereUniqueInput = {
  Form?: InputMaybe<FormWhereUniqueInput>;
};

export type FormTextareaUpdateInput = {
  key?: InputMaybe<Scalars['String']>;
  /** label input for default locale (default) */
  label?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<FormTextareaUpdateLocalizationsInput>;
  /** placeholder input for default locale (default) */
  placeholder?: InputMaybe<Scalars['String']>;
  required?: InputMaybe<Scalars['Boolean']>;
  validators?: InputMaybe<FormTextareavalidatorsUnionUpdateManyInlineInput>;
};

export type FormTextareaUpdateLocalizationDataInput = {
  label?: InputMaybe<Scalars['String']>;
  placeholder?: InputMaybe<Scalars['String']>;
};

export type FormTextareaUpdateLocalizationInput = {
  data: FormTextareaUpdateLocalizationDataInput;
  locale: Locale;
};

export type FormTextareaUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<FormTextareaCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<FormTextareaUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<FormTextareaUpsertLocalizationInput>>;
};

export type FormTextareaUpdateManyInlineInput = {
  /** Create and connect multiple FormTextarea component instances */
  create?: InputMaybe<Array<FormTextareaCreateWithPositionInput>>;
  /** Delete multiple FormTextarea documents */
  delete?: InputMaybe<Array<FormTextareaWhereUniqueInput>>;
  /** Update multiple FormTextarea component instances */
  update?: InputMaybe<
    Array<FormTextareaUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple FormTextarea component instances */
  upsert?: InputMaybe<
    Array<FormTextareaUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type FormTextareaUpdateManyInput = {
  key?: InputMaybe<Scalars['String']>;
  /** label input for default locale (default) */
  label?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<FormTextareaUpdateManyLocalizationsInput>;
  /** placeholder input for default locale (default) */
  placeholder?: InputMaybe<Scalars['String']>;
  required?: InputMaybe<Scalars['Boolean']>;
};

export type FormTextareaUpdateManyLocalizationDataInput = {
  label?: InputMaybe<Scalars['String']>;
  placeholder?: InputMaybe<Scalars['String']>;
};

export type FormTextareaUpdateManyLocalizationInput = {
  data: FormTextareaUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type FormTextareaUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<FormTextareaUpdateManyLocalizationInput>>;
};

export type FormTextareaUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: FormTextareaUpdateManyInput;
  /** Document search */
  where: FormTextareaWhereInput;
};

export type FormTextareaUpdateOneInlineInput = {
  /** Create and connect one FormTextarea document */
  create?: InputMaybe<FormTextareaCreateInput>;
  /** Delete currently connected FormTextarea document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single FormTextarea document */
  update?: InputMaybe<FormTextareaUpdateWithNestedWhereUniqueInput>;
  /** Upsert single FormTextarea document */
  upsert?: InputMaybe<FormTextareaUpsertWithNestedWhereUniqueInput>;
};

export type FormTextareaUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<FormTextareaUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: FormTextareaWhereUniqueInput;
};

export type FormTextareaUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: FormTextareaUpdateInput;
  /** Unique document search */
  where: FormTextareaWhereUniqueInput;
};

export type FormTextareaUpsertInput = {
  /** Create document if it didn't exist */
  create: FormTextareaCreateInput;
  /** Update document if it exists */
  update: FormTextareaUpdateInput;
};

export type FormTextareaUpsertLocalizationInput = {
  create: FormTextareaCreateLocalizationDataInput;
  locale: Locale;
  update: FormTextareaUpdateLocalizationDataInput;
};

export type FormTextareaUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<FormTextareaUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: FormTextareaWhereUniqueInput;
};

export type FormTextareaUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: FormTextareaUpsertInput;
  /** Unique document search */
  where: FormTextareaWhereUniqueInput;
};

/** Identifies documents */
export type FormTextareaWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FormTextareaWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FormTextareaWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FormTextareaWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  key_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  key_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  key_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  key_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  key_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  key_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  key_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  key_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  key_starts_with?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  label_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  label_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  label_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  label_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  label_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  label_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  label_starts_with?: InputMaybe<Scalars['String']>;
  placeholder?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  placeholder_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  placeholder_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  placeholder_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  placeholder_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  placeholder_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  placeholder_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  placeholder_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  placeholder_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  placeholder_starts_with?: InputMaybe<Scalars['String']>;
  required?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  required_not?: InputMaybe<Scalars['Boolean']>;
  /** All values in which the union is empty. */
  validators_empty?: InputMaybe<Scalars['Boolean']>;
  /** Matches if the modular component contains at least one connection to the item provided to the filter */
  validators_some?: InputMaybe<FormTextareavalidatorsUnionWhereInput>;
};

/** References FormTextarea record uniquely */
export type FormTextareaWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type FormTextareavalidatorsUnion =
  | MaxLengthValidator
  | MinLengthValidator;

export type FormTextareavalidatorsUnionConnectInput = {
  MaxLengthValidator?: InputMaybe<MaxLengthValidatorConnectInput>;
  MinLengthValidator?: InputMaybe<MinLengthValidatorConnectInput>;
};

export type FormTextareavalidatorsUnionCreateInput = {
  MaxLengthValidator?: InputMaybe<MaxLengthValidatorCreateInput>;
  MinLengthValidator?: InputMaybe<MinLengthValidatorCreateInput>;
};

export type FormTextareavalidatorsUnionCreateManyInlineInput = {
  /** Create and connect multiple existing FormTextareavalidatorsUnion documents */
  create?: InputMaybe<Array<FormTextareavalidatorsUnionCreateInput>>;
};

export type FormTextareavalidatorsUnionCreateOneInlineInput = {
  /** Create and connect one FormTextareavalidatorsUnion document */
  create?: InputMaybe<FormTextareavalidatorsUnionCreateInput>;
};

export type FormTextareavalidatorsUnionCreateWithPositionInput = {
  MaxLengthValidator?: InputMaybe<MaxLengthValidatorCreateWithPositionInput>;
  MinLengthValidator?: InputMaybe<MinLengthValidatorCreateWithPositionInput>;
};

export type FormTextareavalidatorsUnionUpdateInput = {
  MaxLengthValidator?: InputMaybe<MaxLengthValidatorUpdateInput>;
  MinLengthValidator?: InputMaybe<MinLengthValidatorUpdateInput>;
};

export type FormTextareavalidatorsUnionUpdateManyInlineInput = {
  /** Create and connect multiple FormTextareavalidatorsUnion component instances */
  create?: InputMaybe<
    Array<FormTextareavalidatorsUnionCreateWithPositionInput>
  >;
  /** Delete multiple FormTextareavalidatorsUnion documents */
  delete?: InputMaybe<Array<FormTextareavalidatorsUnionWhereUniqueInput>>;
  /** Update multiple FormTextareavalidatorsUnion component instances */
  update?: InputMaybe<
    Array<FormTextareavalidatorsUnionUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple FormTextareavalidatorsUnion component instances */
  upsert?: InputMaybe<
    Array<FormTextareavalidatorsUnionUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type FormTextareavalidatorsUnionUpdateManyWithNestedWhereInput = {
  MaxLengthValidator?: InputMaybe<MaxLengthValidatorUpdateManyWithNestedWhereInput>;
  MinLengthValidator?: InputMaybe<MinLengthValidatorUpdateManyWithNestedWhereInput>;
};

export type FormTextareavalidatorsUnionUpdateOneInlineInput = {
  /** Create and connect one FormTextareavalidatorsUnion document */
  create?: InputMaybe<FormTextareavalidatorsUnionCreateInput>;
  /** Delete currently connected FormTextareavalidatorsUnion document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single FormTextareavalidatorsUnion document */
  update?: InputMaybe<FormTextareavalidatorsUnionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single FormTextareavalidatorsUnion document */
  upsert?: InputMaybe<FormTextareavalidatorsUnionUpsertWithNestedWhereUniqueInput>;
};

export type FormTextareavalidatorsUnionUpdateWithNestedWhereUniqueAndPositionInput =
  {
    MaxLengthValidator?: InputMaybe<MaxLengthValidatorUpdateWithNestedWhereUniqueAndPositionInput>;
    MinLengthValidator?: InputMaybe<MinLengthValidatorUpdateWithNestedWhereUniqueAndPositionInput>;
  };

export type FormTextareavalidatorsUnionUpdateWithNestedWhereUniqueInput = {
  MaxLengthValidator?: InputMaybe<MaxLengthValidatorUpdateWithNestedWhereUniqueInput>;
  MinLengthValidator?: InputMaybe<MinLengthValidatorUpdateWithNestedWhereUniqueInput>;
};

export type FormTextareavalidatorsUnionUpsertWithNestedWhereUniqueAndPositionInput =
  {
    MaxLengthValidator?: InputMaybe<MaxLengthValidatorUpsertWithNestedWhereUniqueAndPositionInput>;
    MinLengthValidator?: InputMaybe<MinLengthValidatorUpsertWithNestedWhereUniqueAndPositionInput>;
  };

export type FormTextareavalidatorsUnionUpsertWithNestedWhereUniqueInput = {
  MaxLengthValidator?: InputMaybe<MaxLengthValidatorUpsertWithNestedWhereUniqueInput>;
  MinLengthValidator?: InputMaybe<MinLengthValidatorUpsertWithNestedWhereUniqueInput>;
};

export type FormTextareavalidatorsUnionWhereInput = {
  MaxLengthValidator?: InputMaybe<MaxLengthValidatorWhereInput>;
  MinLengthValidator?: InputMaybe<MinLengthValidatorWhereInput>;
};

export type FormTextareavalidatorsUnionWhereUniqueInput = {
  MaxLengthValidator?: InputMaybe<MaxLengthValidatorWhereUniqueInput>;
  MinLengthValidator?: InputMaybe<MinLengthValidatorWhereUniqueInput>;
};

/** FormType Enumeration */
export enum FormType {
  DownloadLeadGenForm = 'DownloadLeadGenForm',
  LeadGenForm = 'LeadGenForm',
}

export type FormUpdateInput = {
  apiEndpoint?: InputMaybe<Scalars['String']>;
  buttonColour?: InputMaybe<Colour>;
  clrilhfje0f6c01ti2q79cuje?: InputMaybe<CFormUpdateManyInlineInput>;
  columns?: InputMaybe<Scalars['Int']>;
  /** confirmationMessage input for default locale (default) */
  confirmationMessage?: InputMaybe<Scalars['RichTextAST']>;
  /** consentText input for default locale (default) */
  consentText?: InputMaybe<Scalars['RichTextAST']>;
  /** ctaText input for default locale (default) */
  ctaText?: InputMaybe<Scalars['String']>;
  /** description input for default locale (default) */
  description?: InputMaybe<Scalars['String']>;
  /** disclaimerText input for default locale (default) */
  disclaimerText?: InputMaybe<Scalars['String']>;
  fields?: InputMaybe<FormfieldsUnionUpdateManyInlineInput>;
  formType?: InputMaybe<CFormType>;
  /** Manage document localizations */
  localizations?: InputMaybe<FormUpdateLocalizationsInput>;
  sites?: InputMaybe<SiteUpdateManyInlineInput>;
  skipDefaultLocale?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
  /** title input for default locale (default) */
  title?: InputMaybe<Scalars['String']>;
};

export type FormUpdateLocalizationDataInput = {
  confirmationMessage?: InputMaybe<Scalars['RichTextAST']>;
  consentText?: InputMaybe<Scalars['RichTextAST']>;
  ctaText?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  disclaimerText?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type FormUpdateLocalizationInput = {
  data: FormUpdateLocalizationDataInput;
  locale: Locale;
};

export type FormUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<FormCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<FormUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<FormUpsertLocalizationInput>>;
};

export type FormUpdateManyInlineInput = {
  /** Connect multiple existing Form documents */
  connect?: InputMaybe<Array<FormConnectInput>>;
  /** Create and connect multiple Form documents */
  create?: InputMaybe<Array<FormCreateInput>>;
  /** Delete multiple Form documents */
  delete?: InputMaybe<Array<FormWhereUniqueInput>>;
  /** Disconnect multiple Form documents */
  disconnect?: InputMaybe<Array<FormWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Form documents */
  set?: InputMaybe<Array<FormWhereUniqueInput>>;
  /** Update multiple Form documents */
  update?: InputMaybe<Array<FormUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Form documents */
  upsert?: InputMaybe<Array<FormUpsertWithNestedWhereUniqueInput>>;
};

export type FormUpdateManyInput = {
  apiEndpoint?: InputMaybe<Scalars['String']>;
  buttonColour?: InputMaybe<Colour>;
  columns?: InputMaybe<Scalars['Int']>;
  /** confirmationMessage input for default locale (default) */
  confirmationMessage?: InputMaybe<Scalars['RichTextAST']>;
  /** consentText input for default locale (default) */
  consentText?: InputMaybe<Scalars['RichTextAST']>;
  /** ctaText input for default locale (default) */
  ctaText?: InputMaybe<Scalars['String']>;
  /** description input for default locale (default) */
  description?: InputMaybe<Scalars['String']>;
  /** disclaimerText input for default locale (default) */
  disclaimerText?: InputMaybe<Scalars['String']>;
  formType?: InputMaybe<CFormType>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<FormUpdateManyLocalizationsInput>;
  skipDefaultLocale?: InputMaybe<Scalars['Boolean']>;
  /** title input for default locale (default) */
  title?: InputMaybe<Scalars['String']>;
};

export type FormUpdateManyLocalizationDataInput = {
  confirmationMessage?: InputMaybe<Scalars['RichTextAST']>;
  consentText?: InputMaybe<Scalars['RichTextAST']>;
  ctaText?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  disclaimerText?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type FormUpdateManyLocalizationInput = {
  data: FormUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type FormUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<FormUpdateManyLocalizationInput>>;
};

export type FormUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: FormUpdateManyInput;
  /** Document search */
  where: FormWhereInput;
};

export type FormUpdateOneInlineInput = {
  /** Connect existing Form document */
  connect?: InputMaybe<FormWhereUniqueInput>;
  /** Create and connect one Form document */
  create?: InputMaybe<FormCreateInput>;
  /** Delete currently connected Form document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected Form document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single Form document */
  update?: InputMaybe<FormUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Form document */
  upsert?: InputMaybe<FormUpsertWithNestedWhereUniqueInput>;
};

export type FormUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: FormUpdateInput;
  /** Unique document search */
  where: FormWhereUniqueInput;
};

export type FormUpsertInput = {
  /** Create document if it didn't exist */
  create: FormCreateInput;
  /** Update document if it exists */
  update: FormUpdateInput;
};

export type FormUpsertLocalizationInput = {
  create: FormCreateLocalizationDataInput;
  locale: Locale;
  update: FormUpdateLocalizationDataInput;
};

export type FormUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: FormUpsertInput;
  /** Unique document search */
  where: FormWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type FormWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type FormWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FormWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FormWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FormWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  apiEndpoint?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  apiEndpoint_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  apiEndpoint_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  apiEndpoint_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  apiEndpoint_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  apiEndpoint_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  apiEndpoint_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  apiEndpoint_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  apiEndpoint_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  apiEndpoint_starts_with?: InputMaybe<Scalars['String']>;
  buttonColour?: InputMaybe<Colour>;
  /** All values that are contained in given list. */
  buttonColour_in?: InputMaybe<Array<InputMaybe<Colour>>>;
  /** Any other value that exists and is not equal to the given value. */
  buttonColour_not?: InputMaybe<Colour>;
  /** All values that are not contained in given list. */
  buttonColour_not_in?: InputMaybe<Array<InputMaybe<Colour>>>;
  columns?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  columns_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  columns_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  columns_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** All values less than the given value. */
  columns_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  columns_lte?: InputMaybe<Scalars['Int']>;
  /** Any other value that exists and is not equal to the given value. */
  columns_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  columns_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  ctaText?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  ctaText_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  ctaText_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  ctaText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  ctaText_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  ctaText_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  ctaText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  ctaText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  ctaText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  ctaText_starts_with?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']>;
  disclaimerText?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  disclaimerText_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  disclaimerText_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  disclaimerText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  disclaimerText_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  disclaimerText_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  disclaimerText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  disclaimerText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  disclaimerText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  disclaimerText_starts_with?: InputMaybe<Scalars['String']>;
  documentInStages_every?: InputMaybe<FormWhereStageInput>;
  documentInStages_none?: InputMaybe<FormWhereStageInput>;
  documentInStages_some?: InputMaybe<FormWhereStageInput>;
  /** All values in which the union is empty. */
  fields_empty?: InputMaybe<Scalars['Boolean']>;
  /** Matches if the modular component contains at least one connection to the item provided to the filter */
  fields_some?: InputMaybe<FormfieldsUnionWhereInput>;
  formType?: InputMaybe<CFormType>;
  /** All values that are contained in given list. */
  formType_in?: InputMaybe<Array<InputMaybe<CFormType>>>;
  /** Any other value that exists and is not equal to the given value. */
  formType_not?: InputMaybe<CFormType>;
  /** All values that are not contained in given list. */
  formType_not_in?: InputMaybe<Array<InputMaybe<CFormType>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  sites_every?: InputMaybe<SiteWhereInput>;
  sites_none?: InputMaybe<SiteWhereInput>;
  sites_some?: InputMaybe<SiteWhereInput>;
  skipDefaultLocale?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  skipDefaultLocale_not?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type FormWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FormWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FormWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FormWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<FormWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Form record uniquely */
export type FormWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type FormfieldsUnion =
  | FormCheckbox
  | FormCheckboxGroup
  | FormInputEmail
  | FormInputNumber
  | FormInputText
  | FormSelect
  | FormTextarea;

export type FormfieldsUnionConnectInput = {
  FormCheckbox?: InputMaybe<FormCheckboxConnectInput>;
  FormCheckboxGroup?: InputMaybe<FormCheckboxGroupConnectInput>;
  FormInputEmail?: InputMaybe<FormInputEmailConnectInput>;
  FormInputNumber?: InputMaybe<FormInputNumberConnectInput>;
  FormInputText?: InputMaybe<FormInputTextConnectInput>;
  FormSelect?: InputMaybe<FormSelectConnectInput>;
  FormTextarea?: InputMaybe<FormTextareaConnectInput>;
};

export type FormfieldsUnionCreateInput = {
  FormCheckbox?: InputMaybe<FormCheckboxCreateInput>;
  FormCheckboxGroup?: InputMaybe<FormCheckboxGroupCreateInput>;
  FormInputEmail?: InputMaybe<FormInputEmailCreateInput>;
  FormInputNumber?: InputMaybe<FormInputNumberCreateInput>;
  FormInputText?: InputMaybe<FormInputTextCreateInput>;
  FormSelect?: InputMaybe<FormSelectCreateInput>;
  FormTextarea?: InputMaybe<FormTextareaCreateInput>;
};

export type FormfieldsUnionCreateManyInlineInput = {
  /** Create and connect multiple existing FormfieldsUnion documents */
  create?: InputMaybe<Array<FormfieldsUnionCreateInput>>;
};

export type FormfieldsUnionCreateOneInlineInput = {
  /** Create and connect one FormfieldsUnion document */
  create?: InputMaybe<FormfieldsUnionCreateInput>;
};

export type FormfieldsUnionCreateWithPositionInput = {
  FormCheckbox?: InputMaybe<FormCheckboxCreateWithPositionInput>;
  FormCheckboxGroup?: InputMaybe<FormCheckboxGroupCreateWithPositionInput>;
  FormInputEmail?: InputMaybe<FormInputEmailCreateWithPositionInput>;
  FormInputNumber?: InputMaybe<FormInputNumberCreateWithPositionInput>;
  FormInputText?: InputMaybe<FormInputTextCreateWithPositionInput>;
  FormSelect?: InputMaybe<FormSelectCreateWithPositionInput>;
  FormTextarea?: InputMaybe<FormTextareaCreateWithPositionInput>;
};

export type FormfieldsUnionUpdateInput = {
  FormCheckbox?: InputMaybe<FormCheckboxUpdateInput>;
  FormCheckboxGroup?: InputMaybe<FormCheckboxGroupUpdateInput>;
  FormInputEmail?: InputMaybe<FormInputEmailUpdateInput>;
  FormInputNumber?: InputMaybe<FormInputNumberUpdateInput>;
  FormInputText?: InputMaybe<FormInputTextUpdateInput>;
  FormSelect?: InputMaybe<FormSelectUpdateInput>;
  FormTextarea?: InputMaybe<FormTextareaUpdateInput>;
};

export type FormfieldsUnionUpdateManyInlineInput = {
  /** Create and connect multiple FormfieldsUnion component instances */
  create?: InputMaybe<Array<FormfieldsUnionCreateWithPositionInput>>;
  /** Delete multiple FormfieldsUnion documents */
  delete?: InputMaybe<Array<FormfieldsUnionWhereUniqueInput>>;
  /** Update multiple FormfieldsUnion component instances */
  update?: InputMaybe<
    Array<FormfieldsUnionUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple FormfieldsUnion component instances */
  upsert?: InputMaybe<
    Array<FormfieldsUnionUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type FormfieldsUnionUpdateManyWithNestedWhereInput = {
  FormCheckbox?: InputMaybe<FormCheckboxUpdateManyWithNestedWhereInput>;
  FormCheckboxGroup?: InputMaybe<FormCheckboxGroupUpdateManyWithNestedWhereInput>;
  FormInputEmail?: InputMaybe<FormInputEmailUpdateManyWithNestedWhereInput>;
  FormInputNumber?: InputMaybe<FormInputNumberUpdateManyWithNestedWhereInput>;
  FormInputText?: InputMaybe<FormInputTextUpdateManyWithNestedWhereInput>;
  FormSelect?: InputMaybe<FormSelectUpdateManyWithNestedWhereInput>;
  FormTextarea?: InputMaybe<FormTextareaUpdateManyWithNestedWhereInput>;
};

export type FormfieldsUnionUpdateOneInlineInput = {
  /** Create and connect one FormfieldsUnion document */
  create?: InputMaybe<FormfieldsUnionCreateInput>;
  /** Delete currently connected FormfieldsUnion document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single FormfieldsUnion document */
  update?: InputMaybe<FormfieldsUnionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single FormfieldsUnion document */
  upsert?: InputMaybe<FormfieldsUnionUpsertWithNestedWhereUniqueInput>;
};

export type FormfieldsUnionUpdateWithNestedWhereUniqueAndPositionInput = {
  FormCheckbox?: InputMaybe<FormCheckboxUpdateWithNestedWhereUniqueAndPositionInput>;
  FormCheckboxGroup?: InputMaybe<FormCheckboxGroupUpdateWithNestedWhereUniqueAndPositionInput>;
  FormInputEmail?: InputMaybe<FormInputEmailUpdateWithNestedWhereUniqueAndPositionInput>;
  FormInputNumber?: InputMaybe<FormInputNumberUpdateWithNestedWhereUniqueAndPositionInput>;
  FormInputText?: InputMaybe<FormInputTextUpdateWithNestedWhereUniqueAndPositionInput>;
  FormSelect?: InputMaybe<FormSelectUpdateWithNestedWhereUniqueAndPositionInput>;
  FormTextarea?: InputMaybe<FormTextareaUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type FormfieldsUnionUpdateWithNestedWhereUniqueInput = {
  FormCheckbox?: InputMaybe<FormCheckboxUpdateWithNestedWhereUniqueInput>;
  FormCheckboxGroup?: InputMaybe<FormCheckboxGroupUpdateWithNestedWhereUniqueInput>;
  FormInputEmail?: InputMaybe<FormInputEmailUpdateWithNestedWhereUniqueInput>;
  FormInputNumber?: InputMaybe<FormInputNumberUpdateWithNestedWhereUniqueInput>;
  FormInputText?: InputMaybe<FormInputTextUpdateWithNestedWhereUniqueInput>;
  FormSelect?: InputMaybe<FormSelectUpdateWithNestedWhereUniqueInput>;
  FormTextarea?: InputMaybe<FormTextareaUpdateWithNestedWhereUniqueInput>;
};

export type FormfieldsUnionUpsertWithNestedWhereUniqueAndPositionInput = {
  FormCheckbox?: InputMaybe<FormCheckboxUpsertWithNestedWhereUniqueAndPositionInput>;
  FormCheckboxGroup?: InputMaybe<FormCheckboxGroupUpsertWithNestedWhereUniqueAndPositionInput>;
  FormInputEmail?: InputMaybe<FormInputEmailUpsertWithNestedWhereUniqueAndPositionInput>;
  FormInputNumber?: InputMaybe<FormInputNumberUpsertWithNestedWhereUniqueAndPositionInput>;
  FormInputText?: InputMaybe<FormInputTextUpsertWithNestedWhereUniqueAndPositionInput>;
  FormSelect?: InputMaybe<FormSelectUpsertWithNestedWhereUniqueAndPositionInput>;
  FormTextarea?: InputMaybe<FormTextareaUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type FormfieldsUnionUpsertWithNestedWhereUniqueInput = {
  FormCheckbox?: InputMaybe<FormCheckboxUpsertWithNestedWhereUniqueInput>;
  FormCheckboxGroup?: InputMaybe<FormCheckboxGroupUpsertWithNestedWhereUniqueInput>;
  FormInputEmail?: InputMaybe<FormInputEmailUpsertWithNestedWhereUniqueInput>;
  FormInputNumber?: InputMaybe<FormInputNumberUpsertWithNestedWhereUniqueInput>;
  FormInputText?: InputMaybe<FormInputTextUpsertWithNestedWhereUniqueInput>;
  FormSelect?: InputMaybe<FormSelectUpsertWithNestedWhereUniqueInput>;
  FormTextarea?: InputMaybe<FormTextareaUpsertWithNestedWhereUniqueInput>;
};

export type FormfieldsUnionWhereInput = {
  FormCheckbox?: InputMaybe<FormCheckboxWhereInput>;
  FormCheckboxGroup?: InputMaybe<FormCheckboxGroupWhereInput>;
  FormInputEmail?: InputMaybe<FormInputEmailWhereInput>;
  FormInputNumber?: InputMaybe<FormInputNumberWhereInput>;
  FormInputText?: InputMaybe<FormInputTextWhereInput>;
  FormSelect?: InputMaybe<FormSelectWhereInput>;
  FormTextarea?: InputMaybe<FormTextareaWhereInput>;
};

export type FormfieldsUnionWhereUniqueInput = {
  FormCheckbox?: InputMaybe<FormCheckboxWhereUniqueInput>;
  FormCheckboxGroup?: InputMaybe<FormCheckboxGroupWhereUniqueInput>;
  FormInputEmail?: InputMaybe<FormInputEmailWhereUniqueInput>;
  FormInputNumber?: InputMaybe<FormInputNumberWhereUniqueInput>;
  FormInputText?: InputMaybe<FormInputTextWhereUniqueInput>;
  FormSelect?: InputMaybe<FormSelectWhereUniqueInput>;
  FormTextarea?: InputMaybe<FormTextareaWhereUniqueInput>;
};

export enum HeaderLayout {
  Basic = 'basic',
  Full = 'full',
  None = 'none',
}

/** hidden below window size */
export enum HiddenBelow {
  Desktop = 'desktop',
  None = 'none',
  Tablet = 'tablet',
}

/** Represents Braid icon */
export enum Icon {
  Add = 'Add',
  Arrow = 'Arrow',
  Bookmark = 'Bookmark',
  Caution = 'Caution',
  Chevron = 'Chevron',
  Clear = 'Clear',
  Company = 'Company',
  Compose = 'Compose',
  Copy = 'Copy',
  CreditCard = 'CreditCard',
  Critical = 'Critical',
  Date = 'Date',
  Delete = 'Delete',
  Desktop = 'Desktop',
  Document = 'Document',
  DocumentBroken = 'DocumentBroken',
  Download = 'Download',
  Edit = 'Edit',
  Education = 'Education',
  Filter = 'Filter',
  Flag = 'Flag',
  Grid = 'Grid',
  Heart = 'Heart',
  Help = 'Help',
  History = 'History',
  Home = 'Home',
  Image = 'Image',
  Info = 'Info',
  Invoice = 'Invoice',
  Language = 'Language',
  Link = 'Link',
  LinkBroken = 'LinkBroken',
  List = 'List',
  Location = 'Location',
  Mail = 'Mail',
  Minus = 'Minus',
  Mobile = 'Mobile',
  Money = 'Money',
  NewWindow = 'NewWindow',
  Note = 'Note',
  Notification = 'Notification',
  Overflow = 'Overflow',
  People = 'People',
  PersonAdd = 'PersonAdd',
  PersonVerified = 'PersonVerified',
  Phone = 'Phone',
  PlatformAndroid = 'PlatformAndroid',
  PlatformApple = 'PlatformApple',
  Positive = 'Positive',
  Print = 'Print',
  Profile = 'Profile',
  Promote = 'Promote',
  Recommended = 'Recommended',
  Refresh = 'Refresh',
  Resume = 'Resume',
  Search = 'Search',
  Security = 'Security',
  Send = 'Send',
  Sent = 'Sent',
  Settings = 'Settings',
  Share = 'Share',
  SocialFacebook = 'SocialFacebook',
  SocialGitHub = 'SocialGitHub',
  SocialInstagram = 'SocialInstagram',
  SocialLinkedIn = 'SocialLinkedIn',
  SocialMedium = 'SocialMedium',
  SocialTwitter = 'SocialTwitter',
  SocialYouTube = 'SocialYouTube',
  Star = 'Star',
  Statistics = 'Statistics',
  SubCategory = 'SubCategory',
  Tag = 'Tag',
  Thumb = 'Thumb',
  Tick = 'Tick',
  Time = 'Time',
  Tip = 'Tip',
  Upload = 'Upload',
  Video = 'Video',
  Visibility = 'Visibility',
  WorkExperience = 'WorkExperience',
  ZoomIn = 'ZoomIn',
  ZoomOut = 'ZoomOut',
}

/** Icon Block Group Columns */
export enum IconBlockGroupColumns {
  Five = 'Five',
  Four = 'Four',
  One = 'One',
  Three = 'Three',
  Two = 'Two',
}

/** Represents Icon Block Group styles */
export enum IconBlockGroupStyle {
  Bordered = 'Bordered',
  Standard = 'Standard',
}

/** Represents Icon Block styles */
export enum IconBlockStyle {
  Inline = 'Inline',
  Stack = 'Stack',
}

/** Position icon to the left or right. */
export enum IconPosition {
  Leading = 'leading',
  Trailing = 'trailing',
}

/** This component is used for single image in brand product page. */
export type Image = Entity & {
  __typename?: 'Image';
  /** The unique identifier */
  id: Scalars['ID'];
  /** Represents an image content file for the Image component. */
  image?: Maybe<Asset>;
  /** System stage field */
  stage: Stage;
};

/** This component is used for single image in brand product page. */
export type ImageImageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
  where?: InputMaybe<AssetSingleRelationWhereInput>;
};

export type ImageBlurInput = {
  /** The amount of blurring to apply to the image. The value must be an integer from 1 to 20. */
  amount: Scalars['Int'];
};

/** Adds a border to the image. */
export type ImageBorderInput = {
  /** The background color of the border. The value must be a valid hex color code. Or one of the supported color names. */
  background: Scalars['String'];
  /** The color of the border. The value must be a valid hex color code. Or one of the supported color names. */
  color: Scalars['String'];
  /** The width of the border in pixels. The value must be an integer from 1 to 1000. */
  width: Scalars['Int'];
};

export type ImageCompressInput = {
  /** Preserves the metadata of the image. */
  metadata: Scalars['Boolean'];
};

export type ImageConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ImageWhereUniqueInput;
};

/** A connection to a list of items. */
export type ImageConnection = {
  __typename?: 'ImageConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ImageEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ImageCreateInput = {
  image?: InputMaybe<AssetCreateOneInlineInput>;
};

export type ImageCreateManyInlineInput = {
  /** Create and connect multiple existing Image documents */
  create?: InputMaybe<Array<ImageCreateInput>>;
};

export type ImageCreateOneInlineInput = {
  /** Create and connect one Image document */
  create?: InputMaybe<ImageCreateInput>;
};

export type ImageCreateWithPositionInput = {
  /** Document to create */
  data: ImageCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/**
 * Crops the image to the specified dimensions.
 * The starting points for X and Y coordinates are [0,0], aligning with the top-left corner of the image.
 * The width and height parameters determine the size in pixels of the cropping rectangle.
 * The output will include only the portion of the image within the designated crop area.
 */
export type ImageCropInput = {
  /** The height in pixels to resize the image to. The value must be an integer from 1 to 10000. */
  height: Scalars['Int'];
  /** The width in pixels to resize the image to. The value must be an integer from 1 to 10000. */
  width: Scalars['Int'];
  /** The x coordinate of the image. The value must be an integer from 0 to 10000. */
  x: Scalars['Int'];
  /** The y coordinate of the image. The value must be an integer from 0 to 10000. */
  y: Scalars['Int'];
};

/** An edge in a connection. */
export type ImageEdge = {
  __typename?: 'ImageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Image;
};

export enum ImageFit {
  /** Resizes the image to fit within the specified parameters without distorting, cropping, or changing the aspect ratio. */
  Clip = 'clip',
  /** Resizes the image to fit the specified parameters exactly by removing any parts of the image that don't fit within the boundaries. */
  Crop = 'crop',
  /** Resizes the image to fit within the parameters, but as opposed to 'fit:clip' will not scale the image if the image is smaller than the output size. */
  Max = 'max',
  /** Resizes the image to fit the specified parameters exactly by scaling the image to the desired size. The aspect ratio of the image is not respected and the image can be distorted using this method. */
  Scale = 'scale',
}

/** Identifies documents */
export type ImageManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ImageWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ImageWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ImageWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AssetWhereInput>;
};

export enum ImageOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type ImageQualityInput = {
  /** The quality of the image. The value must be an integer from 1 to 100. */
  value: Scalars['Int'];
};

export type ImageResizeInput = {
  /** The default value for the fit parameter is fit:clip. */
  fit?: InputMaybe<ImageFit>;
  /** The height in pixels to resize the image to. The value must be an integer from 1 to 10000. */
  height?: InputMaybe<Scalars['Int']>;
  /** The width in pixels to resize the image to. The value must be an integer from 1 to 10000. */
  width?: InputMaybe<Scalars['Int']>;
};

export type ImageSharpenInput = {
  /** The amount of sharpening to apply to the image. The value must be an integer from 1 to 20. */
  amount: Scalars['Int'];
};

/** Transformations for Images */
export type ImageTransformationInput = {
  /** Blurs the image. */
  blur?: InputMaybe<ImageBlurInput>;
  /** Adds a border to the image. */
  border?: InputMaybe<ImageBorderInput>;
  /** Compresses the image. */
  compress?: InputMaybe<ImageCompressInput>;
  /** Crops the image to the specified dimensions. */
  crop?: InputMaybe<ImageCropInput>;
  /**
   * Changes the quality of the image. The value must be an integer from 1 to 100.
   * Only supported for the following formats jpeg, jpg, webp, gif, heif, tiff, avif.
   */
  quality?: InputMaybe<ImageQualityInput>;
  /** Resizes the image */
  resize?: InputMaybe<ImageResizeInput>;
  /** Sharpens the image. */
  sharpen?: InputMaybe<ImageSharpenInput>;
};

export type ImageUpdateInput = {
  image?: InputMaybe<AssetUpdateOneInlineInput>;
};

export type ImageUpdateManyInlineInput = {
  /** Create and connect multiple Image component instances */
  create?: InputMaybe<Array<ImageCreateWithPositionInput>>;
  /** Delete multiple Image documents */
  delete?: InputMaybe<Array<ImageWhereUniqueInput>>;
  /** Update multiple Image component instances */
  update?: InputMaybe<Array<ImageUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple Image component instances */
  upsert?: InputMaybe<Array<ImageUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type ImageUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']>;
};

export type ImageUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ImageUpdateManyInput;
  /** Document search */
  where: ImageWhereInput;
};

export type ImageUpdateOneInlineInput = {
  /** Create and connect one Image document */
  create?: InputMaybe<ImageCreateInput>;
  /** Delete currently connected Image document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single Image document */
  update?: InputMaybe<ImageUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Image document */
  upsert?: InputMaybe<ImageUpsertWithNestedWhereUniqueInput>;
};

export type ImageUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<ImageUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: ImageWhereUniqueInput;
};

export type ImageUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ImageUpdateInput;
  /** Unique document search */
  where: ImageWhereUniqueInput;
};

export type ImageUpsertInput = {
  /** Create document if it didn't exist */
  create: ImageCreateInput;
  /** Update document if it exists */
  update: ImageUpdateInput;
};

export type ImageUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<ImageUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: ImageWhereUniqueInput;
};

export type ImageUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ImageUpsertInput;
  /** Unique document search */
  where: ImageWhereUniqueInput;
};

/** Identifies documents */
export type ImageWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ImageWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ImageWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ImageWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AssetWhereInput>;
};

/** References Image record uniquely */
export type ImageWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Divider component for the Inline Banner */
export type InlineDivider = Entity & {
  __typename?: 'InlineDivider';
  actions: Array<Union_Actions_Cljcktvum001101tbchtibrb0>;
  /** The unique identifier */
  id: Scalars['ID'];
  image: Asset;
  /** System stage field */
  stage: Stage;
};

/** Divider component for the Inline Banner */
export type InlineDividerActionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
};

/** Divider component for the Inline Banner */
export type InlineDividerImageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
  where?: InputMaybe<AssetSingleRelationWhereInput>;
};

export type InlineDividerConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: InlineDividerWhereUniqueInput;
};

/** A connection to a list of items. */
export type InlineDividerConnection = {
  __typename?: 'InlineDividerConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<InlineDividerEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type InlineDividerCreateInput = {
  actions?: InputMaybe<Union_Actions_Cljcktvum001101tbchtibrb0CreateManyInlineInput>;
  image: AssetCreateOneInlineInput;
};

export type InlineDividerCreateManyInlineInput = {
  /** Create and connect multiple existing InlineDivider documents */
  create?: InputMaybe<Array<InlineDividerCreateInput>>;
};

export type InlineDividerCreateOneInlineInput = {
  /** Create and connect one InlineDivider document */
  create?: InputMaybe<InlineDividerCreateInput>;
};

export type InlineDividerCreateWithPositionInput = {
  /** Document to create */
  data: InlineDividerCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type InlineDividerEdge = {
  __typename?: 'InlineDividerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: InlineDivider;
};

/** Identifies documents */
export type InlineDividerManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<InlineDividerWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<InlineDividerWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<InlineDividerWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  /** All values in which the union is empty */
  actions_empty?: InputMaybe<Scalars['Boolean']>;
  /** Matches if the union contains at least one connection to the provided item to the filter */
  actions_some?: InputMaybe<Union_Actions_Cljcktvum001101tbchtibrb0WhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AssetWhereInput>;
};

export enum InlineDividerOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type InlineDividerParent = CBannerInline;

export type InlineDividerParentConnectInput = {
  CBannerInline?: InputMaybe<CBannerInlineConnectInput>;
};

export type InlineDividerParentCreateInput = {
  CBannerInline?: InputMaybe<CBannerInlineCreateInput>;
};

export type InlineDividerParentCreateManyInlineInput = {
  /** Create and connect multiple existing InlineDividerParent documents */
  create?: InputMaybe<Array<InlineDividerParentCreateInput>>;
};

export type InlineDividerParentCreateOneInlineInput = {
  /** Create and connect one InlineDividerParent document */
  create?: InputMaybe<InlineDividerParentCreateInput>;
};

export type InlineDividerParentCreateWithPositionInput = {
  CBannerInline?: InputMaybe<CBannerInlineCreateWithPositionInput>;
};

export type InlineDividerParentUpdateInput = {
  CBannerInline?: InputMaybe<CBannerInlineUpdateInput>;
};

export type InlineDividerParentUpdateManyInlineInput = {
  /** Create and connect multiple InlineDividerParent component instances */
  create?: InputMaybe<Array<InlineDividerParentCreateWithPositionInput>>;
  /** Delete multiple InlineDividerParent documents */
  delete?: InputMaybe<Array<InlineDividerParentWhereUniqueInput>>;
  /** Update multiple InlineDividerParent component instances */
  update?: InputMaybe<
    Array<InlineDividerParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple InlineDividerParent component instances */
  upsert?: InputMaybe<
    Array<InlineDividerParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type InlineDividerParentUpdateManyWithNestedWhereInput = {
  CBannerInline?: InputMaybe<CBannerInlineUpdateManyWithNestedWhereInput>;
};

export type InlineDividerParentUpdateOneInlineInput = {
  /** Create and connect one InlineDividerParent document */
  create?: InputMaybe<InlineDividerParentCreateInput>;
  /** Delete currently connected InlineDividerParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single InlineDividerParent document */
  update?: InputMaybe<InlineDividerParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single InlineDividerParent document */
  upsert?: InputMaybe<InlineDividerParentUpsertWithNestedWhereUniqueInput>;
};

export type InlineDividerParentUpdateWithNestedWhereUniqueAndPositionInput = {
  CBannerInline?: InputMaybe<CBannerInlineUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type InlineDividerParentUpdateWithNestedWhereUniqueInput = {
  CBannerInline?: InputMaybe<CBannerInlineUpdateWithNestedWhereUniqueInput>;
};

export type InlineDividerParentUpsertWithNestedWhereUniqueAndPositionInput = {
  CBannerInline?: InputMaybe<CBannerInlineUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type InlineDividerParentUpsertWithNestedWhereUniqueInput = {
  CBannerInline?: InputMaybe<CBannerInlineUpsertWithNestedWhereUniqueInput>;
};

export type InlineDividerParentWhereInput = {
  CBannerInline?: InputMaybe<CBannerInlineWhereInput>;
};

export type InlineDividerParentWhereUniqueInput = {
  CBannerInline?: InputMaybe<CBannerInlineWhereUniqueInput>;
};

export type InlineDividerUpdateInput = {
  actions?: InputMaybe<Union_Actions_Cljcktvum001101tbchtibrb0UpdateManyInlineInput>;
  image?: InputMaybe<AssetUpdateOneInlineInput>;
};

export type InlineDividerUpdateManyInlineInput = {
  /** Create and connect multiple InlineDivider component instances */
  create?: InputMaybe<Array<InlineDividerCreateWithPositionInput>>;
  /** Delete multiple InlineDivider documents */
  delete?: InputMaybe<Array<InlineDividerWhereUniqueInput>>;
  /** Update multiple InlineDivider component instances */
  update?: InputMaybe<
    Array<InlineDividerUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple InlineDivider component instances */
  upsert?: InputMaybe<
    Array<InlineDividerUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type InlineDividerUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']>;
};

export type InlineDividerUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: InlineDividerUpdateManyInput;
  /** Document search */
  where: InlineDividerWhereInput;
};

export type InlineDividerUpdateOneInlineInput = {
  /** Create and connect one InlineDivider document */
  create?: InputMaybe<InlineDividerCreateInput>;
  /** Delete currently connected InlineDivider document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single InlineDivider document */
  update?: InputMaybe<InlineDividerUpdateWithNestedWhereUniqueInput>;
  /** Upsert single InlineDivider document */
  upsert?: InputMaybe<InlineDividerUpsertWithNestedWhereUniqueInput>;
};

export type InlineDividerUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<InlineDividerUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: InlineDividerWhereUniqueInput;
};

export type InlineDividerUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: InlineDividerUpdateInput;
  /** Unique document search */
  where: InlineDividerWhereUniqueInput;
};

export type InlineDividerUpsertInput = {
  /** Create document if it didn't exist */
  create: InlineDividerCreateInput;
  /** Update document if it exists */
  update: InlineDividerUpdateInput;
};

export type InlineDividerUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<InlineDividerUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: InlineDividerWhereUniqueInput;
};

export type InlineDividerUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: InlineDividerUpsertInput;
  /** Unique document search */
  where: InlineDividerWhereUniqueInput;
};

/** Identifies documents */
export type InlineDividerWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<InlineDividerWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<InlineDividerWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<InlineDividerWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  /** All values in which the union is empty */
  actions_empty?: InputMaybe<Scalars['Boolean']>;
  /** Matches if the union contains at least one connection to the provided item to the filter */
  actions_some?: InputMaybe<Union_Actions_Cljcktvum001101tbchtibrb0WhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AssetWhereInput>;
};

/** References InlineDivider record uniquely */
export type InlineDividerWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type InterviewBuilder = Entity & {
  __typename?: 'InterviewBuilder';
  /** The unique identifier */
  id: Scalars['ID'];
  /** System stage field */
  stage: Stage;
};

export type InterviewBuilderConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: InterviewBuilderWhereUniqueInput;
};

/** A connection to a list of items. */
export type InterviewBuilderConnection = {
  __typename?: 'InterviewBuilderConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<InterviewBuilderEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type InterviewBuilderCreateInput = {
  /** No fields in create input */
  _?: InputMaybe<Scalars['String']>;
};

export type InterviewBuilderCreateManyInlineInput = {
  /** Create and connect multiple existing InterviewBuilder documents */
  create?: InputMaybe<Array<InterviewBuilderCreateInput>>;
};

export type InterviewBuilderCreateOneInlineInput = {
  /** Create and connect one InterviewBuilder document */
  create?: InputMaybe<InterviewBuilderCreateInput>;
};

export type InterviewBuilderCreateWithPositionInput = {
  /** Document to create */
  data: InterviewBuilderCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type InterviewBuilderEdge = {
  __typename?: 'InterviewBuilderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: InterviewBuilder;
};

/** Identifies documents */
export type InterviewBuilderManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<InterviewBuilderWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<InterviewBuilderWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<InterviewBuilderWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
};

export enum InterviewBuilderOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type InterviewBuilderParent = CCustomComponent;

export type InterviewBuilderParentConnectInput = {
  CCustomComponent?: InputMaybe<CCustomComponentConnectInput>;
};

export type InterviewBuilderParentCreateInput = {
  CCustomComponent?: InputMaybe<CCustomComponentCreateInput>;
};

export type InterviewBuilderParentCreateManyInlineInput = {
  /** Create and connect multiple existing InterviewBuilderParent documents */
  create?: InputMaybe<Array<InterviewBuilderParentCreateInput>>;
};

export type InterviewBuilderParentCreateOneInlineInput = {
  /** Create and connect one InterviewBuilderParent document */
  create?: InputMaybe<InterviewBuilderParentCreateInput>;
};

export type InterviewBuilderParentCreateWithPositionInput = {
  CCustomComponent?: InputMaybe<CCustomComponentCreateWithPositionInput>;
};

export type InterviewBuilderParentUpdateInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpdateInput>;
};

export type InterviewBuilderParentUpdateManyInlineInput = {
  /** Create and connect multiple InterviewBuilderParent component instances */
  create?: InputMaybe<Array<InterviewBuilderParentCreateWithPositionInput>>;
  /** Delete multiple InterviewBuilderParent documents */
  delete?: InputMaybe<Array<InterviewBuilderParentWhereUniqueInput>>;
  /** Update multiple InterviewBuilderParent component instances */
  update?: InputMaybe<
    Array<InterviewBuilderParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple InterviewBuilderParent component instances */
  upsert?: InputMaybe<
    Array<InterviewBuilderParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type InterviewBuilderParentUpdateManyWithNestedWhereInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpdateManyWithNestedWhereInput>;
};

export type InterviewBuilderParentUpdateOneInlineInput = {
  /** Create and connect one InterviewBuilderParent document */
  create?: InputMaybe<InterviewBuilderParentCreateInput>;
  /** Delete currently connected InterviewBuilderParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single InterviewBuilderParent document */
  update?: InputMaybe<InterviewBuilderParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single InterviewBuilderParent document */
  upsert?: InputMaybe<InterviewBuilderParentUpsertWithNestedWhereUniqueInput>;
};

export type InterviewBuilderParentUpdateWithNestedWhereUniqueAndPositionInput =
  {
    CCustomComponent?: InputMaybe<CCustomComponentUpdateWithNestedWhereUniqueAndPositionInput>;
  };

export type InterviewBuilderParentUpdateWithNestedWhereUniqueInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpdateWithNestedWhereUniqueInput>;
};

export type InterviewBuilderParentUpsertWithNestedWhereUniqueAndPositionInput =
  {
    CCustomComponent?: InputMaybe<CCustomComponentUpsertWithNestedWhereUniqueAndPositionInput>;
  };

export type InterviewBuilderParentUpsertWithNestedWhereUniqueInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpsertWithNestedWhereUniqueInput>;
};

export type InterviewBuilderParentWhereInput = {
  CCustomComponent?: InputMaybe<CCustomComponentWhereInput>;
};

export type InterviewBuilderParentWhereUniqueInput = {
  CCustomComponent?: InputMaybe<CCustomComponentWhereUniqueInput>;
};

export type InterviewBuilderUpdateInput = {
  /** No fields in update input */
  _?: InputMaybe<Scalars['String']>;
};

export type InterviewBuilderUpdateManyInlineInput = {
  /** Create and connect multiple InterviewBuilder component instances */
  create?: InputMaybe<Array<InterviewBuilderCreateWithPositionInput>>;
  /** Delete multiple InterviewBuilder documents */
  delete?: InputMaybe<Array<InterviewBuilderWhereUniqueInput>>;
  /** Update multiple InterviewBuilder component instances */
  update?: InputMaybe<
    Array<InterviewBuilderUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple InterviewBuilder component instances */
  upsert?: InputMaybe<
    Array<InterviewBuilderUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type InterviewBuilderUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']>;
};

export type InterviewBuilderUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: InterviewBuilderUpdateManyInput;
  /** Document search */
  where: InterviewBuilderWhereInput;
};

export type InterviewBuilderUpdateOneInlineInput = {
  /** Create and connect one InterviewBuilder document */
  create?: InputMaybe<InterviewBuilderCreateInput>;
  /** Delete currently connected InterviewBuilder document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single InterviewBuilder document */
  update?: InputMaybe<InterviewBuilderUpdateWithNestedWhereUniqueInput>;
  /** Upsert single InterviewBuilder document */
  upsert?: InputMaybe<InterviewBuilderUpsertWithNestedWhereUniqueInput>;
};

export type InterviewBuilderUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<InterviewBuilderUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: InterviewBuilderWhereUniqueInput;
};

export type InterviewBuilderUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: InterviewBuilderUpdateInput;
  /** Unique document search */
  where: InterviewBuilderWhereUniqueInput;
};

export type InterviewBuilderUpsertInput = {
  /** Create document if it didn't exist */
  create: InterviewBuilderCreateInput;
  /** Update document if it exists */
  update: InterviewBuilderUpdateInput;
};

export type InterviewBuilderUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<InterviewBuilderUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: InterviewBuilderWhereUniqueInput;
};

export type InterviewBuilderUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: InterviewBuilderUpsertInput;
  /** Unique document search */
  where: InterviewBuilderWhereUniqueInput;
};

/** Identifies documents */
export type InterviewBuilderWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<InterviewBuilderWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<InterviewBuilderWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<InterviewBuilderWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
};

/** References InterviewBuilder record uniquely */
export type InterviewBuilderWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** component to select the type of investor information */
export type InvestorInformation = Entity & {
  __typename?: 'InvestorInformation';
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<InvestorInformation>;
  name?: Maybe<Scalars['String']>;
  options?: Maybe<InvestorOptions>;
  /** System stage field */
  stage: Stage;
};

/** component to select the type of investor information */
export type InvestorInformationLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type InvestorInformationConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: InvestorInformationWhereUniqueInput;
};

/** A connection to a list of items. */
export type InvestorInformationConnection = {
  __typename?: 'InvestorInformationConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<InvestorInformationEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type InvestorInformationCreateInput = {
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<InvestorInformationCreateLocalizationsInput>;
  /** name input for default locale (default) */
  name?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<InvestorOptions>;
};

export type InvestorInformationCreateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type InvestorInformationCreateLocalizationInput = {
  /** Localization input */
  data: InvestorInformationCreateLocalizationDataInput;
  locale: Locale;
};

export type InvestorInformationCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<InvestorInformationCreateLocalizationInput>>;
};

export type InvestorInformationCreateManyInlineInput = {
  /** Create and connect multiple existing InvestorInformation documents */
  create?: InputMaybe<Array<InvestorInformationCreateInput>>;
};

export type InvestorInformationCreateOneInlineInput = {
  /** Create and connect one InvestorInformation document */
  create?: InputMaybe<InvestorInformationCreateInput>;
};

export type InvestorInformationCreateWithPositionInput = {
  /** Document to create */
  data: InvestorInformationCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type InvestorInformationEdge = {
  __typename?: 'InvestorInformationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: InvestorInformation;
};

/** Identifies documents */
export type InvestorInformationManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<InvestorInformationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<InvestorInformationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<InvestorInformationWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  options?: InputMaybe<InvestorOptions>;
  /** All values that are contained in given list. */
  options_in?: InputMaybe<Array<InputMaybe<InvestorOptions>>>;
  /** Any other value that exists and is not equal to the given value. */
  options_not?: InputMaybe<InvestorOptions>;
  /** All values that are not contained in given list. */
  options_not_in?: InputMaybe<Array<InputMaybe<InvestorOptions>>>;
};

export enum InvestorInformationOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  OptionsAsc = 'options_ASC',
  OptionsDesc = 'options_DESC',
}

export type InvestorInformationParent = CCustomComponent;

export type InvestorInformationParentConnectInput = {
  CCustomComponent?: InputMaybe<CCustomComponentConnectInput>;
};

export type InvestorInformationParentCreateInput = {
  CCustomComponent?: InputMaybe<CCustomComponentCreateInput>;
};

export type InvestorInformationParentCreateManyInlineInput = {
  /** Create and connect multiple existing InvestorInformationParent documents */
  create?: InputMaybe<Array<InvestorInformationParentCreateInput>>;
};

export type InvestorInformationParentCreateOneInlineInput = {
  /** Create and connect one InvestorInformationParent document */
  create?: InputMaybe<InvestorInformationParentCreateInput>;
};

export type InvestorInformationParentCreateWithPositionInput = {
  CCustomComponent?: InputMaybe<CCustomComponentCreateWithPositionInput>;
};

export type InvestorInformationParentUpdateInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpdateInput>;
};

export type InvestorInformationParentUpdateManyInlineInput = {
  /** Create and connect multiple InvestorInformationParent component instances */
  create?: InputMaybe<Array<InvestorInformationParentCreateWithPositionInput>>;
  /** Delete multiple InvestorInformationParent documents */
  delete?: InputMaybe<Array<InvestorInformationParentWhereUniqueInput>>;
  /** Update multiple InvestorInformationParent component instances */
  update?: InputMaybe<
    Array<InvestorInformationParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple InvestorInformationParent component instances */
  upsert?: InputMaybe<
    Array<InvestorInformationParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type InvestorInformationParentUpdateManyWithNestedWhereInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpdateManyWithNestedWhereInput>;
};

export type InvestorInformationParentUpdateOneInlineInput = {
  /** Create and connect one InvestorInformationParent document */
  create?: InputMaybe<InvestorInformationParentCreateInput>;
  /** Delete currently connected InvestorInformationParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single InvestorInformationParent document */
  update?: InputMaybe<InvestorInformationParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single InvestorInformationParent document */
  upsert?: InputMaybe<InvestorInformationParentUpsertWithNestedWhereUniqueInput>;
};

export type InvestorInformationParentUpdateWithNestedWhereUniqueAndPositionInput =
  {
    CCustomComponent?: InputMaybe<CCustomComponentUpdateWithNestedWhereUniqueAndPositionInput>;
  };

export type InvestorInformationParentUpdateWithNestedWhereUniqueInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpdateWithNestedWhereUniqueInput>;
};

export type InvestorInformationParentUpsertWithNestedWhereUniqueAndPositionInput =
  {
    CCustomComponent?: InputMaybe<CCustomComponentUpsertWithNestedWhereUniqueAndPositionInput>;
  };

export type InvestorInformationParentUpsertWithNestedWhereUniqueInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpsertWithNestedWhereUniqueInput>;
};

export type InvestorInformationParentWhereInput = {
  CCustomComponent?: InputMaybe<CCustomComponentWhereInput>;
};

export type InvestorInformationParentWhereUniqueInput = {
  CCustomComponent?: InputMaybe<CCustomComponentWhereUniqueInput>;
};

export type InvestorInformationUpdateInput = {
  /** Manage document localizations */
  localizations?: InputMaybe<InvestorInformationUpdateLocalizationsInput>;
  /** name input for default locale (default) */
  name?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<InvestorOptions>;
};

export type InvestorInformationUpdateLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type InvestorInformationUpdateLocalizationInput = {
  data: InvestorInformationUpdateLocalizationDataInput;
  locale: Locale;
};

export type InvestorInformationUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<InvestorInformationCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<InvestorInformationUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<InvestorInformationUpsertLocalizationInput>>;
};

export type InvestorInformationUpdateManyInlineInput = {
  /** Create and connect multiple InvestorInformation component instances */
  create?: InputMaybe<Array<InvestorInformationCreateWithPositionInput>>;
  /** Delete multiple InvestorInformation documents */
  delete?: InputMaybe<Array<InvestorInformationWhereUniqueInput>>;
  /** Update multiple InvestorInformation component instances */
  update?: InputMaybe<
    Array<InvestorInformationUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple InvestorInformation component instances */
  upsert?: InputMaybe<
    Array<InvestorInformationUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type InvestorInformationUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<InvestorInformationUpdateManyLocalizationsInput>;
  /** name input for default locale (default) */
  name?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<InvestorOptions>;
};

export type InvestorInformationUpdateManyLocalizationDataInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type InvestorInformationUpdateManyLocalizationInput = {
  data: InvestorInformationUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type InvestorInformationUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<InvestorInformationUpdateManyLocalizationInput>>;
};

export type InvestorInformationUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: InvestorInformationUpdateManyInput;
  /** Document search */
  where: InvestorInformationWhereInput;
};

export type InvestorInformationUpdateOneInlineInput = {
  /** Create and connect one InvestorInformation document */
  create?: InputMaybe<InvestorInformationCreateInput>;
  /** Delete currently connected InvestorInformation document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single InvestorInformation document */
  update?: InputMaybe<InvestorInformationUpdateWithNestedWhereUniqueInput>;
  /** Upsert single InvestorInformation document */
  upsert?: InputMaybe<InvestorInformationUpsertWithNestedWhereUniqueInput>;
};

export type InvestorInformationUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<InvestorInformationUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: InvestorInformationWhereUniqueInput;
};

export type InvestorInformationUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: InvestorInformationUpdateInput;
  /** Unique document search */
  where: InvestorInformationWhereUniqueInput;
};

export type InvestorInformationUpsertInput = {
  /** Create document if it didn't exist */
  create: InvestorInformationCreateInput;
  /** Update document if it exists */
  update: InvestorInformationUpdateInput;
};

export type InvestorInformationUpsertLocalizationInput = {
  create: InvestorInformationCreateLocalizationDataInput;
  locale: Locale;
  update: InvestorInformationUpdateLocalizationDataInput;
};

export type InvestorInformationUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<InvestorInformationUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: InvestorInformationWhereUniqueInput;
};

export type InvestorInformationUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: InvestorInformationUpsertInput;
  /** Unique document search */
  where: InvestorInformationWhereUniqueInput;
};

/** Identifies documents */
export type InvestorInformationWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<InvestorInformationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<InvestorInformationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<InvestorInformationWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']>;
  options?: InputMaybe<InvestorOptions>;
  /** All values that are contained in given list. */
  options_in?: InputMaybe<Array<InputMaybe<InvestorOptions>>>;
  /** Any other value that exists and is not equal to the given value. */
  options_not?: InputMaybe<InvestorOptions>;
  /** All values that are not contained in given list. */
  options_not_in?: InputMaybe<Array<InputMaybe<InvestorOptions>>>;
};

/** References InvestorInformation record uniquely */
export type InvestorInformationWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** investor options */
export enum InvestorOptions {
  AsxAnnouncements = 'ASXAnnouncements',
  Dividends = 'Dividends',
  LatestAsxAnnouncements = 'LatestASXAnnouncements',
  SharePrice = 'SharePrice',
}

/** Custom Component for displaying Job Ad features in a chart format */
export type JobAdFeatureChart = Entity & {
  __typename?: 'JobAdFeatureChart';
  /** The unique identifier */
  id: Scalars['ID'];
  /** System stage field */
  stage: Stage;
};

export type JobAdFeatureChartConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: JobAdFeatureChartWhereUniqueInput;
};

/** A connection to a list of items. */
export type JobAdFeatureChartConnection = {
  __typename?: 'JobAdFeatureChartConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<JobAdFeatureChartEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type JobAdFeatureChartCreateInput = {
  /** No fields in create input */
  _?: InputMaybe<Scalars['String']>;
};

export type JobAdFeatureChartCreateManyInlineInput = {
  /** Create and connect multiple existing JobAdFeatureChart documents */
  create?: InputMaybe<Array<JobAdFeatureChartCreateInput>>;
};

export type JobAdFeatureChartCreateOneInlineInput = {
  /** Create and connect one JobAdFeatureChart document */
  create?: InputMaybe<JobAdFeatureChartCreateInput>;
};

export type JobAdFeatureChartCreateWithPositionInput = {
  /** Document to create */
  data: JobAdFeatureChartCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type JobAdFeatureChartEdge = {
  __typename?: 'JobAdFeatureChartEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: JobAdFeatureChart;
};

/** Identifies documents */
export type JobAdFeatureChartManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<JobAdFeatureChartWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<JobAdFeatureChartWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<JobAdFeatureChartWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
};

export enum JobAdFeatureChartOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type JobAdFeatureChartParent = CCustomComponent;

export type JobAdFeatureChartParentConnectInput = {
  CCustomComponent?: InputMaybe<CCustomComponentConnectInput>;
};

export type JobAdFeatureChartParentCreateInput = {
  CCustomComponent?: InputMaybe<CCustomComponentCreateInput>;
};

export type JobAdFeatureChartParentCreateManyInlineInput = {
  /** Create and connect multiple existing JobAdFeatureChartParent documents */
  create?: InputMaybe<Array<JobAdFeatureChartParentCreateInput>>;
};

export type JobAdFeatureChartParentCreateOneInlineInput = {
  /** Create and connect one JobAdFeatureChartParent document */
  create?: InputMaybe<JobAdFeatureChartParentCreateInput>;
};

export type JobAdFeatureChartParentCreateWithPositionInput = {
  CCustomComponent?: InputMaybe<CCustomComponentCreateWithPositionInput>;
};

export type JobAdFeatureChartParentUpdateInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpdateInput>;
};

export type JobAdFeatureChartParentUpdateManyInlineInput = {
  /** Create and connect multiple JobAdFeatureChartParent component instances */
  create?: InputMaybe<Array<JobAdFeatureChartParentCreateWithPositionInput>>;
  /** Delete multiple JobAdFeatureChartParent documents */
  delete?: InputMaybe<Array<JobAdFeatureChartParentWhereUniqueInput>>;
  /** Update multiple JobAdFeatureChartParent component instances */
  update?: InputMaybe<
    Array<JobAdFeatureChartParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple JobAdFeatureChartParent component instances */
  upsert?: InputMaybe<
    Array<JobAdFeatureChartParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type JobAdFeatureChartParentUpdateManyWithNestedWhereInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpdateManyWithNestedWhereInput>;
};

export type JobAdFeatureChartParentUpdateOneInlineInput = {
  /** Create and connect one JobAdFeatureChartParent document */
  create?: InputMaybe<JobAdFeatureChartParentCreateInput>;
  /** Delete currently connected JobAdFeatureChartParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single JobAdFeatureChartParent document */
  update?: InputMaybe<JobAdFeatureChartParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single JobAdFeatureChartParent document */
  upsert?: InputMaybe<JobAdFeatureChartParentUpsertWithNestedWhereUniqueInput>;
};

export type JobAdFeatureChartParentUpdateWithNestedWhereUniqueAndPositionInput =
  {
    CCustomComponent?: InputMaybe<CCustomComponentUpdateWithNestedWhereUniqueAndPositionInput>;
  };

export type JobAdFeatureChartParentUpdateWithNestedWhereUniqueInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpdateWithNestedWhereUniqueInput>;
};

export type JobAdFeatureChartParentUpsertWithNestedWhereUniqueAndPositionInput =
  {
    CCustomComponent?: InputMaybe<CCustomComponentUpsertWithNestedWhereUniqueAndPositionInput>;
  };

export type JobAdFeatureChartParentUpsertWithNestedWhereUniqueInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpsertWithNestedWhereUniqueInput>;
};

export type JobAdFeatureChartParentWhereInput = {
  CCustomComponent?: InputMaybe<CCustomComponentWhereInput>;
};

export type JobAdFeatureChartParentWhereUniqueInput = {
  CCustomComponent?: InputMaybe<CCustomComponentWhereUniqueInput>;
};

export type JobAdFeatureChartUpdateInput = {
  /** No fields in update input */
  _?: InputMaybe<Scalars['String']>;
};

export type JobAdFeatureChartUpdateManyInlineInput = {
  /** Create and connect multiple JobAdFeatureChart component instances */
  create?: InputMaybe<Array<JobAdFeatureChartCreateWithPositionInput>>;
  /** Delete multiple JobAdFeatureChart documents */
  delete?: InputMaybe<Array<JobAdFeatureChartWhereUniqueInput>>;
  /** Update multiple JobAdFeatureChart component instances */
  update?: InputMaybe<
    Array<JobAdFeatureChartUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple JobAdFeatureChart component instances */
  upsert?: InputMaybe<
    Array<JobAdFeatureChartUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type JobAdFeatureChartUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']>;
};

export type JobAdFeatureChartUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: JobAdFeatureChartUpdateManyInput;
  /** Document search */
  where: JobAdFeatureChartWhereInput;
};

export type JobAdFeatureChartUpdateOneInlineInput = {
  /** Create and connect one JobAdFeatureChart document */
  create?: InputMaybe<JobAdFeatureChartCreateInput>;
  /** Delete currently connected JobAdFeatureChart document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single JobAdFeatureChart document */
  update?: InputMaybe<JobAdFeatureChartUpdateWithNestedWhereUniqueInput>;
  /** Upsert single JobAdFeatureChart document */
  upsert?: InputMaybe<JobAdFeatureChartUpsertWithNestedWhereUniqueInput>;
};

export type JobAdFeatureChartUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<JobAdFeatureChartUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: JobAdFeatureChartWhereUniqueInput;
};

export type JobAdFeatureChartUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: JobAdFeatureChartUpdateInput;
  /** Unique document search */
  where: JobAdFeatureChartWhereUniqueInput;
};

export type JobAdFeatureChartUpsertInput = {
  /** Create document if it didn't exist */
  create: JobAdFeatureChartCreateInput;
  /** Update document if it exists */
  update: JobAdFeatureChartUpdateInput;
};

export type JobAdFeatureChartUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<JobAdFeatureChartUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: JobAdFeatureChartWhereUniqueInput;
};

export type JobAdFeatureChartUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: JobAdFeatureChartUpsertInput;
  /** Unique document search */
  where: JobAdFeatureChartWhereUniqueInput;
};

/** Identifies documents */
export type JobAdFeatureChartWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<JobAdFeatureChartWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<JobAdFeatureChartWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<JobAdFeatureChartWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
};

/** References JobAdFeatureChart record uniquely */
export type JobAdFeatureChartWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Display a vertical 'stepper' list of short text content */
export type KeyStepsBlock = Entity & {
  __typename?: 'KeyStepsBlock';
  heading?: Maybe<CHeading>;
  /** The unique identifier */
  id: Scalars['ID'];
  image?: Maybe<Asset>;
  /** System stage field */
  stage: Stage;
  steps: Array<CStepBlock>;
  tone?: Maybe<BackgroundColor>;
};

/** Display a vertical 'stepper' list of short text content */
export type KeyStepsBlockHeadingArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Display a vertical 'stepper' list of short text content */
export type KeyStepsBlockImageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
  where?: InputMaybe<AssetSingleRelationWhereInput>;
};

/** Display a vertical 'stepper' list of short text content */
export type KeyStepsBlockStepsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<CStepBlockOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CStepBlockWhereInput>;
};

export type KeyStepsBlockConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: KeyStepsBlockWhereUniqueInput;
};

/** A connection to a list of items. */
export type KeyStepsBlockConnection = {
  __typename?: 'KeyStepsBlockConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<KeyStepsBlockEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type KeyStepsBlockCreateInput = {
  heading?: InputMaybe<CHeadingCreateOneInlineInput>;
  image?: InputMaybe<AssetCreateOneInlineInput>;
  steps?: InputMaybe<CStepBlockCreateManyInlineInput>;
  tone?: InputMaybe<BackgroundColor>;
};

export type KeyStepsBlockCreateManyInlineInput = {
  /** Create and connect multiple existing KeyStepsBlock documents */
  create?: InputMaybe<Array<KeyStepsBlockCreateInput>>;
};

export type KeyStepsBlockCreateOneInlineInput = {
  /** Create and connect one KeyStepsBlock document */
  create?: InputMaybe<KeyStepsBlockCreateInput>;
};

export type KeyStepsBlockCreateWithPositionInput = {
  /** Document to create */
  data: KeyStepsBlockCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type KeyStepsBlockEdge = {
  __typename?: 'KeyStepsBlockEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: KeyStepsBlock;
};

/** Identifies documents */
export type KeyStepsBlockManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<KeyStepsBlockWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<KeyStepsBlockWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<KeyStepsBlockWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  heading?: InputMaybe<CHeadingWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AssetWhereInput>;
  steps_every?: InputMaybe<CStepBlockWhereInput>;
  steps_none?: InputMaybe<CStepBlockWhereInput>;
  steps_some?: InputMaybe<CStepBlockWhereInput>;
  tone?: InputMaybe<BackgroundColor>;
  /** All values that are contained in given list. */
  tone_in?: InputMaybe<Array<InputMaybe<BackgroundColor>>>;
  /** Any other value that exists and is not equal to the given value. */
  tone_not?: InputMaybe<BackgroundColor>;
  /** All values that are not contained in given list. */
  tone_not_in?: InputMaybe<Array<InputMaybe<BackgroundColor>>>;
};

export enum KeyStepsBlockOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ToneAsc = 'tone_ASC',
  ToneDesc = 'tone_DESC',
}

export type KeyStepsBlockParent = CBlock;

export type KeyStepsBlockParentConnectInput = {
  CBlock?: InputMaybe<CBlockConnectInput>;
};

export type KeyStepsBlockParentCreateInput = {
  CBlock?: InputMaybe<CBlockCreateInput>;
};

export type KeyStepsBlockParentCreateManyInlineInput = {
  /** Connect multiple existing KeyStepsBlockParent documents */
  connect?: InputMaybe<Array<KeyStepsBlockParentWhereUniqueInput>>;
  /** Create and connect multiple existing KeyStepsBlockParent documents */
  create?: InputMaybe<Array<KeyStepsBlockParentCreateInput>>;
};

export type KeyStepsBlockParentCreateOneInlineInput = {
  /** Connect one existing KeyStepsBlockParent document */
  connect?: InputMaybe<KeyStepsBlockParentWhereUniqueInput>;
  /** Create and connect one KeyStepsBlockParent document */
  create?: InputMaybe<KeyStepsBlockParentCreateInput>;
};

export type KeyStepsBlockParentUpdateInput = {
  CBlock?: InputMaybe<CBlockUpdateInput>;
};

export type KeyStepsBlockParentUpdateManyInlineInput = {
  /** Connect multiple existing KeyStepsBlockParent documents */
  connect?: InputMaybe<Array<KeyStepsBlockParentConnectInput>>;
  /** Create and connect multiple KeyStepsBlockParent documents */
  create?: InputMaybe<Array<KeyStepsBlockParentCreateInput>>;
  /** Delete multiple KeyStepsBlockParent documents */
  delete?: InputMaybe<Array<KeyStepsBlockParentWhereUniqueInput>>;
  /** Disconnect multiple KeyStepsBlockParent documents */
  disconnect?: InputMaybe<Array<KeyStepsBlockParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing KeyStepsBlockParent documents */
  set?: InputMaybe<Array<KeyStepsBlockParentWhereUniqueInput>>;
  /** Update multiple KeyStepsBlockParent documents */
  update?: InputMaybe<
    Array<KeyStepsBlockParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple KeyStepsBlockParent documents */
  upsert?: InputMaybe<
    Array<KeyStepsBlockParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type KeyStepsBlockParentUpdateManyWithNestedWhereInput = {
  CBlock?: InputMaybe<CBlockUpdateManyWithNestedWhereInput>;
};

export type KeyStepsBlockParentUpdateOneInlineInput = {
  /** Connect existing KeyStepsBlockParent document */
  connect?: InputMaybe<KeyStepsBlockParentWhereUniqueInput>;
  /** Create and connect one KeyStepsBlockParent document */
  create?: InputMaybe<KeyStepsBlockParentCreateInput>;
  /** Delete currently connected KeyStepsBlockParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected KeyStepsBlockParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single KeyStepsBlockParent document */
  update?: InputMaybe<KeyStepsBlockParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single KeyStepsBlockParent document */
  upsert?: InputMaybe<KeyStepsBlockParentUpsertWithNestedWhereUniqueInput>;
};

export type KeyStepsBlockParentUpdateWithNestedWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockUpdateWithNestedWhereUniqueInput>;
};

export type KeyStepsBlockParentUpsertWithNestedWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockUpsertWithNestedWhereUniqueInput>;
};

export type KeyStepsBlockParentWhereInput = {
  CBlock?: InputMaybe<CBlockWhereInput>;
};

export type KeyStepsBlockParentWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockWhereUniqueInput>;
};

export type KeyStepsBlockUpdateInput = {
  heading?: InputMaybe<CHeadingUpdateOneInlineInput>;
  image?: InputMaybe<AssetUpdateOneInlineInput>;
  steps?: InputMaybe<CStepBlockUpdateManyInlineInput>;
  tone?: InputMaybe<BackgroundColor>;
};

export type KeyStepsBlockUpdateManyInlineInput = {
  /** Create and connect multiple KeyStepsBlock component instances */
  create?: InputMaybe<Array<KeyStepsBlockCreateWithPositionInput>>;
  /** Delete multiple KeyStepsBlock documents */
  delete?: InputMaybe<Array<KeyStepsBlockWhereUniqueInput>>;
  /** Update multiple KeyStepsBlock component instances */
  update?: InputMaybe<
    Array<KeyStepsBlockUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple KeyStepsBlock component instances */
  upsert?: InputMaybe<
    Array<KeyStepsBlockUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type KeyStepsBlockUpdateManyInput = {
  tone?: InputMaybe<BackgroundColor>;
};

export type KeyStepsBlockUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: KeyStepsBlockUpdateManyInput;
  /** Document search */
  where: KeyStepsBlockWhereInput;
};

export type KeyStepsBlockUpdateOneInlineInput = {
  /** Create and connect one KeyStepsBlock document */
  create?: InputMaybe<KeyStepsBlockCreateInput>;
  /** Delete currently connected KeyStepsBlock document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single KeyStepsBlock document */
  update?: InputMaybe<KeyStepsBlockUpdateWithNestedWhereUniqueInput>;
  /** Upsert single KeyStepsBlock document */
  upsert?: InputMaybe<KeyStepsBlockUpsertWithNestedWhereUniqueInput>;
};

export type KeyStepsBlockUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<KeyStepsBlockUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: KeyStepsBlockWhereUniqueInput;
};

export type KeyStepsBlockUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: KeyStepsBlockUpdateInput;
  /** Unique document search */
  where: KeyStepsBlockWhereUniqueInput;
};

export type KeyStepsBlockUpsertInput = {
  /** Create document if it didn't exist */
  create: KeyStepsBlockCreateInput;
  /** Update document if it exists */
  update: KeyStepsBlockUpdateInput;
};

export type KeyStepsBlockUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<KeyStepsBlockUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: KeyStepsBlockWhereUniqueInput;
};

export type KeyStepsBlockUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: KeyStepsBlockUpsertInput;
  /** Unique document search */
  where: KeyStepsBlockWhereUniqueInput;
};

/** Identifies documents */
export type KeyStepsBlockWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<KeyStepsBlockWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<KeyStepsBlockWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<KeyStepsBlockWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  heading?: InputMaybe<CHeadingWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AssetWhereInput>;
  steps_every?: InputMaybe<CStepBlockWhereInput>;
  steps_none?: InputMaybe<CStepBlockWhereInput>;
  steps_some?: InputMaybe<CStepBlockWhereInput>;
  tone?: InputMaybe<BackgroundColor>;
  /** All values that are contained in given list. */
  tone_in?: InputMaybe<Array<InputMaybe<BackgroundColor>>>;
  /** Any other value that exists and is not equal to the given value. */
  tone_not?: InputMaybe<BackgroundColor>;
  /** All values that are not contained in given list. */
  tone_not_in?: InputMaybe<Array<InputMaybe<BackgroundColor>>>;
};

/** References KeyStepsBlock record uniquely */
export type KeyStepsBlockWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Shows the three latest articles with a link to show more. */
export type LatestArticlesByCategory = Entity & {
  __typename?: 'LatestArticlesByCategory';
  /** Choose fields of information to display, if available on the Article */
  cardFields: Array<ArticleFeature>;
  category: Category;
  /** Optional description text underneath the heading. Default empty. */
  description?: Maybe<Scalars['String']>;
  /**
   * Optional display heading; otherwise, the category name will be used instead.
   *
   */
  heading?: Maybe<Scalars['String']>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<LatestArticlesByCategory>;
  /** This will limit the number of articles to display based on screen size before the browse all button is displayed. (NOTE: If not set, a default value will be use. (mobile: 3, tablet: 2, desktop & wide: 3)) */
  maximumArticlesToDisplay?: Maybe<ResponsiveColumnValue>;
  /** Latest articles category */
  name?: Maybe<Scalars['String']>;
  /** System stage field */
  stage: Stage;
};

/** Shows the three latest articles with a link to show more. */
export type LatestArticlesByCategoryCategoryArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Shows the three latest articles with a link to show more. */
export type LatestArticlesByCategoryLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

/** Shows the three latest articles with a link to show more. */
export type LatestArticlesByCategoryMaximumArticlesToDisplayArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type LatestArticlesByCategoryConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: LatestArticlesByCategoryWhereUniqueInput;
};

/** A connection to a list of items. */
export type LatestArticlesByCategoryConnection = {
  __typename?: 'LatestArticlesByCategoryConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<LatestArticlesByCategoryEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type LatestArticlesByCategoryCreateInput = {
  cardFields?: InputMaybe<Array<ArticleFeature>>;
  category: CategoryCreateOneInlineInput;
  /** description input for default locale (default) */
  description?: InputMaybe<Scalars['String']>;
  /** heading input for default locale (default) */
  heading?: InputMaybe<Scalars['String']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<LatestArticlesByCategoryCreateLocalizationsInput>;
  maximumArticlesToDisplay?: InputMaybe<ResponsiveColumnValueCreateOneInlineInput>;
  /** name input for default locale (default) */
  name?: InputMaybe<Scalars['String']>;
};

export type LatestArticlesByCategoryCreateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']>;
  heading?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type LatestArticlesByCategoryCreateLocalizationInput = {
  /** Localization input */
  data: LatestArticlesByCategoryCreateLocalizationDataInput;
  locale: Locale;
};

export type LatestArticlesByCategoryCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<LatestArticlesByCategoryCreateLocalizationInput>>;
};

export type LatestArticlesByCategoryCreateManyInlineInput = {
  /** Create and connect multiple existing LatestArticlesByCategory documents */
  create?: InputMaybe<Array<LatestArticlesByCategoryCreateInput>>;
};

export type LatestArticlesByCategoryCreateOneInlineInput = {
  /** Create and connect one LatestArticlesByCategory document */
  create?: InputMaybe<LatestArticlesByCategoryCreateInput>;
};

export type LatestArticlesByCategoryCreateWithPositionInput = {
  /** Document to create */
  data: LatestArticlesByCategoryCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type LatestArticlesByCategoryEdge = {
  __typename?: 'LatestArticlesByCategoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: LatestArticlesByCategory;
};

/** Identifies documents */
export type LatestArticlesByCategoryManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<LatestArticlesByCategoryWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<LatestArticlesByCategoryWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<LatestArticlesByCategoryWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  cardFields?: InputMaybe<Array<ArticleFeature>>;
  /** Matches if the field array contains *all* items provided to the filter */
  cardFields_contains_all?: InputMaybe<Array<ArticleFeature>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  cardFields_contains_none?: InputMaybe<Array<ArticleFeature>>;
  /** Matches if the field array contains at least one item provided to the filter */
  cardFields_contains_some?: InputMaybe<Array<ArticleFeature>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  cardFields_not?: InputMaybe<Array<ArticleFeature>>;
  category?: InputMaybe<CategoryWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  maximumArticlesToDisplay?: InputMaybe<ResponsiveColumnValueWhereInput>;
};

export enum LatestArticlesByCategoryOrderByInput {
  CardFieldsAsc = 'cardFields_ASC',
  CardFieldsDesc = 'cardFields_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
}

export type LatestArticlesByCategoryParent = ArticleAdditionalContent | CBlock;

export type LatestArticlesByCategoryParentConnectInput = {
  ArticleAdditionalContent?: InputMaybe<ArticleAdditionalContentConnectInput>;
  CBlock?: InputMaybe<CBlockConnectInput>;
};

export type LatestArticlesByCategoryParentCreateInput = {
  ArticleAdditionalContent?: InputMaybe<ArticleAdditionalContentCreateInput>;
  CBlock?: InputMaybe<CBlockCreateInput>;
};

export type LatestArticlesByCategoryParentCreateManyInlineInput = {
  /** Connect multiple existing LatestArticlesByCategoryParent documents */
  connect?: InputMaybe<Array<LatestArticlesByCategoryParentWhereUniqueInput>>;
  /** Create and connect multiple existing LatestArticlesByCategoryParent documents */
  create?: InputMaybe<Array<LatestArticlesByCategoryParentCreateInput>>;
};

export type LatestArticlesByCategoryParentCreateOneInlineInput = {
  /** Connect one existing LatestArticlesByCategoryParent document */
  connect?: InputMaybe<LatestArticlesByCategoryParentWhereUniqueInput>;
  /** Create and connect one LatestArticlesByCategoryParent document */
  create?: InputMaybe<LatestArticlesByCategoryParentCreateInput>;
};

export type LatestArticlesByCategoryParentUpdateInput = {
  ArticleAdditionalContent?: InputMaybe<ArticleAdditionalContentUpdateInput>;
  CBlock?: InputMaybe<CBlockUpdateInput>;
};

export type LatestArticlesByCategoryParentUpdateManyInlineInput = {
  /** Connect multiple existing LatestArticlesByCategoryParent documents */
  connect?: InputMaybe<Array<LatestArticlesByCategoryParentConnectInput>>;
  /** Create and connect multiple LatestArticlesByCategoryParent documents */
  create?: InputMaybe<Array<LatestArticlesByCategoryParentCreateInput>>;
  /** Delete multiple LatestArticlesByCategoryParent documents */
  delete?: InputMaybe<Array<LatestArticlesByCategoryParentWhereUniqueInput>>;
  /** Disconnect multiple LatestArticlesByCategoryParent documents */
  disconnect?: InputMaybe<
    Array<LatestArticlesByCategoryParentWhereUniqueInput>
  >;
  /** Override currently-connected documents with multiple existing LatestArticlesByCategoryParent documents */
  set?: InputMaybe<Array<LatestArticlesByCategoryParentWhereUniqueInput>>;
  /** Update multiple LatestArticlesByCategoryParent documents */
  update?: InputMaybe<
    Array<LatestArticlesByCategoryParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple LatestArticlesByCategoryParent documents */
  upsert?: InputMaybe<
    Array<LatestArticlesByCategoryParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type LatestArticlesByCategoryParentUpdateManyWithNestedWhereInput = {
  ArticleAdditionalContent?: InputMaybe<ArticleAdditionalContentUpdateManyWithNestedWhereInput>;
  CBlock?: InputMaybe<CBlockUpdateManyWithNestedWhereInput>;
};

export type LatestArticlesByCategoryParentUpdateOneInlineInput = {
  /** Connect existing LatestArticlesByCategoryParent document */
  connect?: InputMaybe<LatestArticlesByCategoryParentWhereUniqueInput>;
  /** Create and connect one LatestArticlesByCategoryParent document */
  create?: InputMaybe<LatestArticlesByCategoryParentCreateInput>;
  /** Delete currently connected LatestArticlesByCategoryParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected LatestArticlesByCategoryParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single LatestArticlesByCategoryParent document */
  update?: InputMaybe<LatestArticlesByCategoryParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single LatestArticlesByCategoryParent document */
  upsert?: InputMaybe<LatestArticlesByCategoryParentUpsertWithNestedWhereUniqueInput>;
};

export type LatestArticlesByCategoryParentUpdateWithNestedWhereUniqueInput = {
  ArticleAdditionalContent?: InputMaybe<ArticleAdditionalContentUpdateWithNestedWhereUniqueInput>;
  CBlock?: InputMaybe<CBlockUpdateWithNestedWhereUniqueInput>;
};

export type LatestArticlesByCategoryParentUpsertWithNestedWhereUniqueInput = {
  ArticleAdditionalContent?: InputMaybe<ArticleAdditionalContentUpsertWithNestedWhereUniqueInput>;
  CBlock?: InputMaybe<CBlockUpsertWithNestedWhereUniqueInput>;
};

export type LatestArticlesByCategoryParentWhereInput = {
  ArticleAdditionalContent?: InputMaybe<ArticleAdditionalContentWhereInput>;
  CBlock?: InputMaybe<CBlockWhereInput>;
};

export type LatestArticlesByCategoryParentWhereUniqueInput = {
  ArticleAdditionalContent?: InputMaybe<ArticleAdditionalContentWhereUniqueInput>;
  CBlock?: InputMaybe<CBlockWhereUniqueInput>;
};

export type LatestArticlesByCategoryUpdateInput = {
  cardFields?: InputMaybe<Array<ArticleFeature>>;
  category?: InputMaybe<CategoryUpdateOneInlineInput>;
  /** description input for default locale (default) */
  description?: InputMaybe<Scalars['String']>;
  /** heading input for default locale (default) */
  heading?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<LatestArticlesByCategoryUpdateLocalizationsInput>;
  maximumArticlesToDisplay?: InputMaybe<ResponsiveColumnValueUpdateOneInlineInput>;
  /** name input for default locale (default) */
  name?: InputMaybe<Scalars['String']>;
};

export type LatestArticlesByCategoryUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']>;
  heading?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type LatestArticlesByCategoryUpdateLocalizationInput = {
  data: LatestArticlesByCategoryUpdateLocalizationDataInput;
  locale: Locale;
};

export type LatestArticlesByCategoryUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<LatestArticlesByCategoryCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<LatestArticlesByCategoryUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<LatestArticlesByCategoryUpsertLocalizationInput>>;
};

export type LatestArticlesByCategoryUpdateManyInlineInput = {
  /** Create and connect multiple LatestArticlesByCategory component instances */
  create?: InputMaybe<Array<LatestArticlesByCategoryCreateWithPositionInput>>;
  /** Delete multiple LatestArticlesByCategory documents */
  delete?: InputMaybe<Array<LatestArticlesByCategoryWhereUniqueInput>>;
  /** Update multiple LatestArticlesByCategory component instances */
  update?: InputMaybe<
    Array<LatestArticlesByCategoryUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple LatestArticlesByCategory component instances */
  upsert?: InputMaybe<
    Array<LatestArticlesByCategoryUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type LatestArticlesByCategoryUpdateManyInput = {
  cardFields?: InputMaybe<Array<ArticleFeature>>;
  /** description input for default locale (default) */
  description?: InputMaybe<Scalars['String']>;
  /** heading input for default locale (default) */
  heading?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<LatestArticlesByCategoryUpdateManyLocalizationsInput>;
  /** name input for default locale (default) */
  name?: InputMaybe<Scalars['String']>;
};

export type LatestArticlesByCategoryUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']>;
  heading?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type LatestArticlesByCategoryUpdateManyLocalizationInput = {
  data: LatestArticlesByCategoryUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type LatestArticlesByCategoryUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<
    Array<LatestArticlesByCategoryUpdateManyLocalizationInput>
  >;
};

export type LatestArticlesByCategoryUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: LatestArticlesByCategoryUpdateManyInput;
  /** Document search */
  where: LatestArticlesByCategoryWhereInput;
};

export type LatestArticlesByCategoryUpdateOneInlineInput = {
  /** Create and connect one LatestArticlesByCategory document */
  create?: InputMaybe<LatestArticlesByCategoryCreateInput>;
  /** Delete currently connected LatestArticlesByCategory document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single LatestArticlesByCategory document */
  update?: InputMaybe<LatestArticlesByCategoryUpdateWithNestedWhereUniqueInput>;
  /** Upsert single LatestArticlesByCategory document */
  upsert?: InputMaybe<LatestArticlesByCategoryUpsertWithNestedWhereUniqueInput>;
};

export type LatestArticlesByCategoryUpdateWithNestedWhereUniqueAndPositionInput =
  {
    /** Document to update */
    data?: InputMaybe<LatestArticlesByCategoryUpdateInput>;
    /** Position in the list of existing component instances, will default to appending at the end of list */
    position?: InputMaybe<ConnectPositionInput>;
    /** Unique component instance search */
    where: LatestArticlesByCategoryWhereUniqueInput;
  };

export type LatestArticlesByCategoryUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: LatestArticlesByCategoryUpdateInput;
  /** Unique document search */
  where: LatestArticlesByCategoryWhereUniqueInput;
};

export type LatestArticlesByCategoryUpsertInput = {
  /** Create document if it didn't exist */
  create: LatestArticlesByCategoryCreateInput;
  /** Update document if it exists */
  update: LatestArticlesByCategoryUpdateInput;
};

export type LatestArticlesByCategoryUpsertLocalizationInput = {
  create: LatestArticlesByCategoryCreateLocalizationDataInput;
  locale: Locale;
  update: LatestArticlesByCategoryUpdateLocalizationDataInput;
};

export type LatestArticlesByCategoryUpsertWithNestedWhereUniqueAndPositionInput =
  {
    /** Document to upsert */
    data?: InputMaybe<LatestArticlesByCategoryUpsertInput>;
    /** Position in the list of existing component instances, will default to appending at the end of list */
    position?: InputMaybe<ConnectPositionInput>;
    /** Unique component instance search */
    where: LatestArticlesByCategoryWhereUniqueInput;
  };

export type LatestArticlesByCategoryUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: LatestArticlesByCategoryUpsertInput;
  /** Unique document search */
  where: LatestArticlesByCategoryWhereUniqueInput;
};

/** Identifies documents */
export type LatestArticlesByCategoryWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<LatestArticlesByCategoryWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<LatestArticlesByCategoryWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<LatestArticlesByCategoryWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  cardFields?: InputMaybe<Array<ArticleFeature>>;
  /** Matches if the field array contains *all* items provided to the filter */
  cardFields_contains_all?: InputMaybe<Array<ArticleFeature>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  cardFields_contains_none?: InputMaybe<Array<ArticleFeature>>;
  /** Matches if the field array contains at least one item provided to the filter */
  cardFields_contains_some?: InputMaybe<Array<ArticleFeature>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  cardFields_not?: InputMaybe<Array<ArticleFeature>>;
  category?: InputMaybe<CategoryWhereInput>;
  description?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']>;
  heading?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  heading_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  heading_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  heading_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  heading_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  heading_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  heading_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  heading_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  maximumArticlesToDisplay?: InputMaybe<ResponsiveColumnValueWhereInput>;
  name?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']>;
};

/** References LatestArticlesByCategory record uniquely */
export type LatestArticlesByCategoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Link = Entity & {
  __typename?: 'Link';
  /** The unique identifier */
  id: Scalars['ID'];
  /** opens link in new tab by setting "target=_blank" attribute */
  openLinkInNewTab?: Maybe<Scalars['Boolean']>;
  /** System stage field */
  stage: Stage;
  /** Where the link goes to. */
  to?: Maybe<Union_To_Cl74c8n5m0e4w01un15kvc319>;
};

export type LinkToArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type LinkConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: LinkWhereUniqueInput;
};

/** A connection to a list of items. */
export type LinkConnection = {
  __typename?: 'LinkConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<LinkEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type LinkCreateInput = {
  openLinkInNewTab?: InputMaybe<Scalars['Boolean']>;
  to?: InputMaybe<Union_To_Cl74c8n5m0e4w01un15kvc319CreateOneInlineInput>;
};

export type LinkCreateManyInlineInput = {
  /** Create and connect multiple existing Link documents */
  create?: InputMaybe<Array<LinkCreateInput>>;
};

export type LinkCreateOneInlineInput = {
  /** Create and connect one Link document */
  create?: InputMaybe<LinkCreateInput>;
};

export type LinkCreateWithPositionInput = {
  /** Document to create */
  data: LinkCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type LinkEdge = {
  __typename?: 'LinkEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Link;
};

/** Identifies documents */
export type LinkManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<LinkWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<LinkWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<LinkWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  openLinkInNewTab?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  openLinkInNewTab_not?: InputMaybe<Scalars['Boolean']>;
  /** All values in which the union is connected to the given models */
  to?: InputMaybe<Union_To_Cl74c8n5m0e4w01un15kvc319WhereInput>;
  /** All values in which the union is empty */
  to_empty?: InputMaybe<Scalars['Boolean']>;
};

export enum LinkOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  OpenLinkInNewTabAsc = 'openLinkInNewTab_ASC',
  OpenLinkInNewTabDesc = 'openLinkInNewTab_DESC',
}

export type LinkParent = CButtonLink | CImageLink | CTextLink | Datalab;

export type LinkParentConnectInput = {
  CButtonLink?: InputMaybe<CButtonLinkConnectInput>;
  CImageLink?: InputMaybe<CImageLinkConnectInput>;
  CTextLink?: InputMaybe<CTextLinkConnectInput>;
  Datalab?: InputMaybe<DatalabConnectInput>;
};

export type LinkParentCreateInput = {
  CButtonLink?: InputMaybe<CButtonLinkCreateInput>;
  CImageLink?: InputMaybe<CImageLinkCreateInput>;
  CTextLink?: InputMaybe<CTextLinkCreateInput>;
  Datalab?: InputMaybe<DatalabCreateInput>;
};

export type LinkParentCreateManyInlineInput = {
  /** Connect multiple existing LinkParent documents */
  connect?: InputMaybe<Array<LinkParentWhereUniqueInput>>;
  /** Create and connect multiple existing LinkParent documents */
  create?: InputMaybe<Array<LinkParentCreateInput>>;
};

export type LinkParentCreateOneInlineInput = {
  /** Connect one existing LinkParent document */
  connect?: InputMaybe<LinkParentWhereUniqueInput>;
  /** Create and connect one LinkParent document */
  create?: InputMaybe<LinkParentCreateInput>;
};

export type LinkParentUpdateInput = {
  CButtonLink?: InputMaybe<CButtonLinkUpdateInput>;
  CImageLink?: InputMaybe<CImageLinkUpdateInput>;
  CTextLink?: InputMaybe<CTextLinkUpdateInput>;
  Datalab?: InputMaybe<DatalabUpdateInput>;
};

export type LinkParentUpdateManyInlineInput = {
  /** Connect multiple existing LinkParent documents */
  connect?: InputMaybe<Array<LinkParentConnectInput>>;
  /** Create and connect multiple LinkParent documents */
  create?: InputMaybe<Array<LinkParentCreateInput>>;
  /** Delete multiple LinkParent documents */
  delete?: InputMaybe<Array<LinkParentWhereUniqueInput>>;
  /** Disconnect multiple LinkParent documents */
  disconnect?: InputMaybe<Array<LinkParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing LinkParent documents */
  set?: InputMaybe<Array<LinkParentWhereUniqueInput>>;
  /** Update multiple LinkParent documents */
  update?: InputMaybe<Array<LinkParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple LinkParent documents */
  upsert?: InputMaybe<Array<LinkParentUpsertWithNestedWhereUniqueInput>>;
};

export type LinkParentUpdateManyWithNestedWhereInput = {
  CButtonLink?: InputMaybe<CButtonLinkUpdateManyWithNestedWhereInput>;
  CImageLink?: InputMaybe<CImageLinkUpdateManyWithNestedWhereInput>;
  CTextLink?: InputMaybe<CTextLinkUpdateManyWithNestedWhereInput>;
  Datalab?: InputMaybe<DatalabUpdateManyWithNestedWhereInput>;
};

export type LinkParentUpdateOneInlineInput = {
  /** Connect existing LinkParent document */
  connect?: InputMaybe<LinkParentWhereUniqueInput>;
  /** Create and connect one LinkParent document */
  create?: InputMaybe<LinkParentCreateInput>;
  /** Delete currently connected LinkParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected LinkParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single LinkParent document */
  update?: InputMaybe<LinkParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single LinkParent document */
  upsert?: InputMaybe<LinkParentUpsertWithNestedWhereUniqueInput>;
};

export type LinkParentUpdateWithNestedWhereUniqueInput = {
  CButtonLink?: InputMaybe<CButtonLinkUpdateWithNestedWhereUniqueInput>;
  CImageLink?: InputMaybe<CImageLinkUpdateWithNestedWhereUniqueInput>;
  CTextLink?: InputMaybe<CTextLinkUpdateWithNestedWhereUniqueInput>;
  Datalab?: InputMaybe<DatalabUpdateWithNestedWhereUniqueInput>;
};

export type LinkParentUpsertWithNestedWhereUniqueInput = {
  CButtonLink?: InputMaybe<CButtonLinkUpsertWithNestedWhereUniqueInput>;
  CImageLink?: InputMaybe<CImageLinkUpsertWithNestedWhereUniqueInput>;
  CTextLink?: InputMaybe<CTextLinkUpsertWithNestedWhereUniqueInput>;
  Datalab?: InputMaybe<DatalabUpsertWithNestedWhereUniqueInput>;
};

export type LinkParentWhereInput = {
  CButtonLink?: InputMaybe<CButtonLinkWhereInput>;
  CImageLink?: InputMaybe<CImageLinkWhereInput>;
  CTextLink?: InputMaybe<CTextLinkWhereInput>;
  Datalab?: InputMaybe<DatalabWhereInput>;
};

export type LinkParentWhereUniqueInput = {
  CButtonLink?: InputMaybe<CButtonLinkWhereUniqueInput>;
  CImageLink?: InputMaybe<CImageLinkWhereUniqueInput>;
  CTextLink?: InputMaybe<CTextLinkWhereUniqueInput>;
  Datalab?: InputMaybe<DatalabWhereUniqueInput>;
};

export type LinkUpdateInput = {
  openLinkInNewTab?: InputMaybe<Scalars['Boolean']>;
  to?: InputMaybe<Union_To_Cl74c8n5m0e4w01un15kvc319UpdateOneInlineInput>;
};

export type LinkUpdateManyInlineInput = {
  /** Create and connect multiple Link component instances */
  create?: InputMaybe<Array<LinkCreateWithPositionInput>>;
  /** Delete multiple Link documents */
  delete?: InputMaybe<Array<LinkWhereUniqueInput>>;
  /** Update multiple Link component instances */
  update?: InputMaybe<Array<LinkUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple Link component instances */
  upsert?: InputMaybe<Array<LinkUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type LinkUpdateManyInput = {
  openLinkInNewTab?: InputMaybe<Scalars['Boolean']>;
};

export type LinkUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: LinkUpdateManyInput;
  /** Document search */
  where: LinkWhereInput;
};

export type LinkUpdateOneInlineInput = {
  /** Create and connect one Link document */
  create?: InputMaybe<LinkCreateInput>;
  /** Delete currently connected Link document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single Link document */
  update?: InputMaybe<LinkUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Link document */
  upsert?: InputMaybe<LinkUpsertWithNestedWhereUniqueInput>;
};

export type LinkUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<LinkUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: LinkWhereUniqueInput;
};

export type LinkUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: LinkUpdateInput;
  /** Unique document search */
  where: LinkWhereUniqueInput;
};

export type LinkUpsertInput = {
  /** Create document if it didn't exist */
  create: LinkCreateInput;
  /** Update document if it exists */
  update: LinkUpdateInput;
};

export type LinkUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<LinkUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: LinkWhereUniqueInput;
};

export type LinkUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: LinkUpsertInput;
  /** Unique document search */
  where: LinkWhereUniqueInput;
};

/** Identifies documents */
export type LinkWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<LinkWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<LinkWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<LinkWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  openLinkInNewTab?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  openLinkInNewTab_not?: InputMaybe<Scalars['Boolean']>;
  /** All values in which the union is connected to the given models */
  to?: InputMaybe<Union_To_Cl74c8n5m0e4w01un15kvc319WhereInput>;
  /** All values in which the union is empty */
  to_empty?: InputMaybe<Scalars['Boolean']>;
};

/** References Link record uniquely */
export type LinkWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type LoaDataLab = Entity & {
  __typename?: 'LoaDataLab';
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<LoaDataLab>;
  /** System stage field */
  stage: Stage;
  url: Scalars['String'];
};

export type LoaDataLabLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type LoaDataLabConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: LoaDataLabWhereUniqueInput;
};

/** A connection to a list of items. */
export type LoaDataLabConnection = {
  __typename?: 'LoaDataLabConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<LoaDataLabEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type LoaDataLabCreateInput = {
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<LoaDataLabCreateLocalizationsInput>;
  /** url input for default locale (default) */
  url: Scalars['String'];
};

export type LoaDataLabCreateLocalizationDataInput = {
  url: Scalars['String'];
};

export type LoaDataLabCreateLocalizationInput = {
  /** Localization input */
  data: LoaDataLabCreateLocalizationDataInput;
  locale: Locale;
};

export type LoaDataLabCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<LoaDataLabCreateLocalizationInput>>;
};

export type LoaDataLabCreateManyInlineInput = {
  /** Create and connect multiple existing LoaDataLab documents */
  create?: InputMaybe<Array<LoaDataLabCreateInput>>;
};

export type LoaDataLabCreateOneInlineInput = {
  /** Create and connect one LoaDataLab document */
  create?: InputMaybe<LoaDataLabCreateInput>;
};

export type LoaDataLabCreateWithPositionInput = {
  /** Document to create */
  data: LoaDataLabCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type LoaDataLabEdge = {
  __typename?: 'LoaDataLabEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: LoaDataLab;
};

/** Identifies documents */
export type LoaDataLabManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<LoaDataLabWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<LoaDataLabWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<LoaDataLabWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
};

export enum LoaDataLabOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
}

export type LoaDataLabParent = CCustomComponent;

export type LoaDataLabParentConnectInput = {
  CCustomComponent?: InputMaybe<CCustomComponentConnectInput>;
};

export type LoaDataLabParentCreateInput = {
  CCustomComponent?: InputMaybe<CCustomComponentCreateInput>;
};

export type LoaDataLabParentCreateManyInlineInput = {
  /** Create and connect multiple existing LoaDataLabParent documents */
  create?: InputMaybe<Array<LoaDataLabParentCreateInput>>;
};

export type LoaDataLabParentCreateOneInlineInput = {
  /** Create and connect one LoaDataLabParent document */
  create?: InputMaybe<LoaDataLabParentCreateInput>;
};

export type LoaDataLabParentCreateWithPositionInput = {
  CCustomComponent?: InputMaybe<CCustomComponentCreateWithPositionInput>;
};

export type LoaDataLabParentUpdateInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpdateInput>;
};

export type LoaDataLabParentUpdateManyInlineInput = {
  /** Create and connect multiple LoaDataLabParent component instances */
  create?: InputMaybe<Array<LoaDataLabParentCreateWithPositionInput>>;
  /** Delete multiple LoaDataLabParent documents */
  delete?: InputMaybe<Array<LoaDataLabParentWhereUniqueInput>>;
  /** Update multiple LoaDataLabParent component instances */
  update?: InputMaybe<
    Array<LoaDataLabParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple LoaDataLabParent component instances */
  upsert?: InputMaybe<
    Array<LoaDataLabParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type LoaDataLabParentUpdateManyWithNestedWhereInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpdateManyWithNestedWhereInput>;
};

export type LoaDataLabParentUpdateOneInlineInput = {
  /** Create and connect one LoaDataLabParent document */
  create?: InputMaybe<LoaDataLabParentCreateInput>;
  /** Delete currently connected LoaDataLabParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single LoaDataLabParent document */
  update?: InputMaybe<LoaDataLabParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single LoaDataLabParent document */
  upsert?: InputMaybe<LoaDataLabParentUpsertWithNestedWhereUniqueInput>;
};

export type LoaDataLabParentUpdateWithNestedWhereUniqueAndPositionInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type LoaDataLabParentUpdateWithNestedWhereUniqueInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpdateWithNestedWhereUniqueInput>;
};

export type LoaDataLabParentUpsertWithNestedWhereUniqueAndPositionInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type LoaDataLabParentUpsertWithNestedWhereUniqueInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpsertWithNestedWhereUniqueInput>;
};

export type LoaDataLabParentWhereInput = {
  CCustomComponent?: InputMaybe<CCustomComponentWhereInput>;
};

export type LoaDataLabParentWhereUniqueInput = {
  CCustomComponent?: InputMaybe<CCustomComponentWhereUniqueInput>;
};

export type LoaDataLabUpdateInput = {
  /** Manage document localizations */
  localizations?: InputMaybe<LoaDataLabUpdateLocalizationsInput>;
  /** url input for default locale (default) */
  url?: InputMaybe<Scalars['String']>;
};

export type LoaDataLabUpdateLocalizationDataInput = {
  url?: InputMaybe<Scalars['String']>;
};

export type LoaDataLabUpdateLocalizationInput = {
  data: LoaDataLabUpdateLocalizationDataInput;
  locale: Locale;
};

export type LoaDataLabUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<LoaDataLabCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<LoaDataLabUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<LoaDataLabUpsertLocalizationInput>>;
};

export type LoaDataLabUpdateManyInlineInput = {
  /** Create and connect multiple LoaDataLab component instances */
  create?: InputMaybe<Array<LoaDataLabCreateWithPositionInput>>;
  /** Delete multiple LoaDataLab documents */
  delete?: InputMaybe<Array<LoaDataLabWhereUniqueInput>>;
  /** Update multiple LoaDataLab component instances */
  update?: InputMaybe<
    Array<LoaDataLabUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple LoaDataLab component instances */
  upsert?: InputMaybe<
    Array<LoaDataLabUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type LoaDataLabUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<LoaDataLabUpdateManyLocalizationsInput>;
  /** url input for default locale (default) */
  url?: InputMaybe<Scalars['String']>;
};

export type LoaDataLabUpdateManyLocalizationDataInput = {
  url?: InputMaybe<Scalars['String']>;
};

export type LoaDataLabUpdateManyLocalizationInput = {
  data: LoaDataLabUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type LoaDataLabUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<LoaDataLabUpdateManyLocalizationInput>>;
};

export type LoaDataLabUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: LoaDataLabUpdateManyInput;
  /** Document search */
  where: LoaDataLabWhereInput;
};

export type LoaDataLabUpdateOneInlineInput = {
  /** Create and connect one LoaDataLab document */
  create?: InputMaybe<LoaDataLabCreateInput>;
  /** Delete currently connected LoaDataLab document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single LoaDataLab document */
  update?: InputMaybe<LoaDataLabUpdateWithNestedWhereUniqueInput>;
  /** Upsert single LoaDataLab document */
  upsert?: InputMaybe<LoaDataLabUpsertWithNestedWhereUniqueInput>;
};

export type LoaDataLabUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<LoaDataLabUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: LoaDataLabWhereUniqueInput;
};

export type LoaDataLabUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: LoaDataLabUpdateInput;
  /** Unique document search */
  where: LoaDataLabWhereUniqueInput;
};

export type LoaDataLabUpsertInput = {
  /** Create document if it didn't exist */
  create: LoaDataLabCreateInput;
  /** Update document if it exists */
  update: LoaDataLabUpdateInput;
};

export type LoaDataLabUpsertLocalizationInput = {
  create: LoaDataLabCreateLocalizationDataInput;
  locale: Locale;
  update: LoaDataLabUpdateLocalizationDataInput;
};

export type LoaDataLabUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<LoaDataLabUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: LoaDataLabWhereUniqueInput;
};

export type LoaDataLabUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: LoaDataLabUpsertInput;
  /** Unique document search */
  where: LoaDataLabWhereUniqueInput;
};

/** Identifies documents */
export type LoaDataLabWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<LoaDataLabWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<LoaDataLabWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<LoaDataLabWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  url?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  url_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  url_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  url_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  url_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  url_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  url_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  url_starts_with?: InputMaybe<Scalars['String']>;
};

/** References LoaDataLab record uniquely */
export type LoaDataLabWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Locale system enumeration */
export enum Locale {
  /** System locale */
  Default = 'default',
  EnAu = 'en_AU',
  EnHk = 'en_HK',
  EnId = 'en_ID',
  EnMy = 'en_MY',
  EnNz = 'en_NZ',
  EnPh = 'en_PH',
  EnSg = 'en_SG',
  EnTh = 'en_TH',
  IdId = 'id_ID',
  ThTh = 'th_TH',
}

/** Representing a geolocation point with latitude and longitude */
export type Location = {
  __typename?: 'Location';
  distance: Scalars['Float'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

/** Representing a geolocation point with latitude and longitude */
export type LocationDistanceArgs = {
  from: LocationInput;
};

/** Input for a geolocation point with latitude and longitude */
export type LocationInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

/** Model for controlling banners, alerts, and notices across hirer and candidate platforms. */
export type ManagedContentBanner = Entity &
  Node & {
    __typename?: 'ManagedContentBanner';
    /** Set a date time when you would like the banner to stop being visible, will overwrite the display field. */
    bannerEnd?: Maybe<Scalars['DateTime']>;
    /** Set a date time when you would like the banner to be visible, display field must also be true. */
    bannerStart?: Maybe<Scalars['DateTime']>;
    /** Determine the style of banner to display */
    bannerType?: Maybe<ManagedContentBannerType>;
    /** Determines if the banner should be displayed on in specific context eg. hirer_shopfront, hirer_dashboard */
    context: Array<ManagedContentBannerContext>;
    /** The time the document was created */
    createdAt: Scalars['DateTime'];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Determines whether or not the banner should be shown on the UI */
    display?: Maybe<Scalars['Boolean']>;
    /** Get the document in other stages */
    documentInStages: Array<ManagedContentBanner>;
    /** List of ManagedContentBanner versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars['ID'];
    /** System Locale field */
    locale: Locale;
    /** Get the other localizations for this document */
    localizations: Array<ManagedContentBanner>;
    /** The message displayed inside the banner, supports embeded singleLineText such as the contact us links */
    message?: Maybe<ManagedContentBannerMessageRichText>;
    /** Field for storing extra information on the banner which will be shown in a pop out modal. */
    moreInfoContent?: Maybe<ManagedContentBannerMoreInfoContentRichText>;
    /** Text to display for the cta which opens the more info modal */
    moreInfoText?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    /** Choose which platforms to display the banner */
    platform: Array<ManagedContentBannerPlatform>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    slug?: Maybe<Scalars['String']>;
    /** System stage field */
    stage: Stage;
    /** Controls the tone of the banner, this will determine the icon and the colour of the banner. */
    tone?: Maybe<Tone>;
    /** The time the document was updated */
    updatedAt: Scalars['DateTime'];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

/** Model for controlling banners, alerts, and notices across hirer and candidate platforms. */
export type ManagedContentBannerCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

/** Model for controlling banners, alerts, and notices across hirer and candidate platforms. */
export type ManagedContentBannerCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Model for controlling banners, alerts, and notices across hirer and candidate platforms. */
export type ManagedContentBannerDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

/** Model for controlling banners, alerts, and notices across hirer and candidate platforms. */
export type ManagedContentBannerHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};

/** Model for controlling banners, alerts, and notices across hirer and candidate platforms. */
export type ManagedContentBannerLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

/** Model for controlling banners, alerts, and notices across hirer and candidate platforms. */
export type ManagedContentBannerPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

/** Model for controlling banners, alerts, and notices across hirer and candidate platforms. */
export type ManagedContentBannerPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Model for controlling banners, alerts, and notices across hirer and candidate platforms. */
export type ManagedContentBannerScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

/** Model for controlling banners, alerts, and notices across hirer and candidate platforms. */
export type ManagedContentBannerUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

/** Model for controlling banners, alerts, and notices across hirer and candidate platforms. */
export type ManagedContentBannerUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ManagedContentBannerConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ManagedContentBannerWhereUniqueInput;
};

/** A connection to a list of items. */
export type ManagedContentBannerConnection = {
  __typename?: 'ManagedContentBannerConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ManagedContentBannerEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Used to determine the context/page that the banner will be displayed on */
export enum ManagedContentBannerContext {
  AdBudgetCheckoutPage = 'ad_budget_checkout_page',
  AdBudgetProductPage = 'ad_budget_product_page',
  HirerAccountDetails = 'hirer_account_details',
  HirerAdBudgets = 'hirer_ad_budgets',
  HirerAnalyticsGlobal = 'hirer_analytics_global',
  HirerCheckout = 'hirer_checkout',
  HirerDashboard = 'hirer_dashboard',
  HirerInvoices = 'hirer_invoices',
  HirerProductUpgradeGlobal = 'hirer_product_upgrade_global',
  HirerShopfront = 'hirer_shopfront',
  HirerUserManagement = 'hirer_user_management',
  JobListingGlobal = 'job_listing_global',
  JobPostingGlobal = 'job_posting_global',
  JobPostingPayAndPost = 'job_posting_pay_and_post',
}

export type ManagedContentBannerCreateInput = {
  bannerEnd?: InputMaybe<Scalars['DateTime']>;
  bannerStart?: InputMaybe<Scalars['DateTime']>;
  bannerType?: InputMaybe<ManagedContentBannerType>;
  context?: InputMaybe<Array<ManagedContentBannerContext>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  display?: InputMaybe<Scalars['Boolean']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<ManagedContentBannerCreateLocalizationsInput>;
  /** message input for default locale (default) */
  message?: InputMaybe<Scalars['RichTextAST']>;
  /** moreInfoContent input for default locale (default) */
  moreInfoContent?: InputMaybe<Scalars['RichTextAST']>;
  /** moreInfoText input for default locale (default) */
  moreInfoText?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  platform?: InputMaybe<Array<ManagedContentBannerPlatform>>;
  slug?: InputMaybe<Scalars['String']>;
  tone?: InputMaybe<Tone>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ManagedContentBannerCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  message?: InputMaybe<Scalars['RichTextAST']>;
  moreInfoContent?: InputMaybe<Scalars['RichTextAST']>;
  moreInfoText?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ManagedContentBannerCreateLocalizationInput = {
  /** Localization input */
  data: ManagedContentBannerCreateLocalizationDataInput;
  locale: Locale;
};

export type ManagedContentBannerCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<ManagedContentBannerCreateLocalizationInput>>;
};

export type ManagedContentBannerCreateManyInlineInput = {
  /** Connect multiple existing ManagedContentBanner documents */
  connect?: InputMaybe<Array<ManagedContentBannerWhereUniqueInput>>;
  /** Create and connect multiple existing ManagedContentBanner documents */
  create?: InputMaybe<Array<ManagedContentBannerCreateInput>>;
};

export type ManagedContentBannerCreateOneInlineInput = {
  /** Connect one existing ManagedContentBanner document */
  connect?: InputMaybe<ManagedContentBannerWhereUniqueInput>;
  /** Create and connect one ManagedContentBanner document */
  create?: InputMaybe<ManagedContentBannerCreateInput>;
};

/** An edge in a connection. */
export type ManagedContentBannerEdge = {
  __typename?: 'ManagedContentBannerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ManagedContentBanner;
};

/** Identifies documents */
export type ManagedContentBannerManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ManagedContentBannerWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ManagedContentBannerWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ManagedContentBannerWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  bannerEnd?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  bannerEnd_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  bannerEnd_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  bannerEnd_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  bannerEnd_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  bannerEnd_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  bannerEnd_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  bannerEnd_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  bannerStart?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  bannerStart_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  bannerStart_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  bannerStart_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  bannerStart_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  bannerStart_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  bannerStart_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  bannerStart_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  bannerType?: InputMaybe<ManagedContentBannerType>;
  /** All values that are contained in given list. */
  bannerType_in?: InputMaybe<Array<InputMaybe<ManagedContentBannerType>>>;
  /** Any other value that exists and is not equal to the given value. */
  bannerType_not?: InputMaybe<ManagedContentBannerType>;
  /** All values that are not contained in given list. */
  bannerType_not_in?: InputMaybe<Array<InputMaybe<ManagedContentBannerType>>>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  context?: InputMaybe<Array<ManagedContentBannerContext>>;
  /** Matches if the field array contains *all* items provided to the filter */
  context_contains_all?: InputMaybe<Array<ManagedContentBannerContext>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  context_contains_none?: InputMaybe<Array<ManagedContentBannerContext>>;
  /** Matches if the field array contains at least one item provided to the filter */
  context_contains_some?: InputMaybe<Array<ManagedContentBannerContext>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  context_not?: InputMaybe<Array<ManagedContentBannerContext>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  display?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  display_not?: InputMaybe<Scalars['Boolean']>;
  documentInStages_every?: InputMaybe<ManagedContentBannerWhereStageInput>;
  documentInStages_none?: InputMaybe<ManagedContentBannerWhereStageInput>;
  documentInStages_some?: InputMaybe<ManagedContentBannerWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  platform?: InputMaybe<Array<ManagedContentBannerPlatform>>;
  /** Matches if the field array contains *all* items provided to the filter */
  platform_contains_all?: InputMaybe<Array<ManagedContentBannerPlatform>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  platform_contains_none?: InputMaybe<Array<ManagedContentBannerPlatform>>;
  /** Matches if the field array contains at least one item provided to the filter */
  platform_contains_some?: InputMaybe<Array<ManagedContentBannerPlatform>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  platform_not?: InputMaybe<Array<ManagedContentBannerPlatform>>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  slug?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']>;
  tone?: InputMaybe<Tone>;
  /** All values that are contained in given list. */
  tone_in?: InputMaybe<Array<InputMaybe<Tone>>>;
  /** Any other value that exists and is not equal to the given value. */
  tone_not?: InputMaybe<Tone>;
  /** All values that are not contained in given list. */
  tone_not_in?: InputMaybe<Array<InputMaybe<Tone>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export type ManagedContentBannerMessageRichText = {
  __typename?: 'ManagedContentBannerMessageRichText';
  /** Returns HTMl representation */
  html: Scalars['String'];
  json: Scalars['RichTextAST'];
  /** Returns Markdown representation */
  markdown: Scalars['String'];
  /** @deprecated Please use the 'json' field */
  raw: Scalars['RichTextAST'];
  references: Array<ManagedContentBannerMessageRichTextEmbeddedTypes>;
  /** Returns plain-text contents of RichText */
  text: Scalars['String'];
};

export type ManagedContentBannerMessageRichTextReferencesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type ManagedContentBannerMessageRichTextEmbeddedTypes =
  | EmbedCustomContent
  | EmbedSingleLineText;

export type ManagedContentBannerMoreInfoContentRichText = {
  __typename?: 'ManagedContentBannerMoreInfoContentRichText';
  /** Returns HTMl representation */
  html: Scalars['String'];
  json: Scalars['RichTextAST'];
  /** Returns Markdown representation */
  markdown: Scalars['String'];
  /** @deprecated Please use the 'json' field */
  raw: Scalars['RichTextAST'];
  references: Array<ManagedContentBannerMoreInfoContentRichTextEmbeddedTypes>;
  /** Returns plain-text contents of RichText */
  text: Scalars['String'];
};

export type ManagedContentBannerMoreInfoContentRichTextReferencesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type ManagedContentBannerMoreInfoContentRichTextEmbeddedTypes =
  | EmbedCustomContent
  | EmbedSingleLineText;

export enum ManagedContentBannerOrderByInput {
  BannerEndAsc = 'bannerEnd_ASC',
  BannerEndDesc = 'bannerEnd_DESC',
  BannerStartAsc = 'bannerStart_ASC',
  BannerStartDesc = 'bannerStart_DESC',
  BannerTypeAsc = 'bannerType_ASC',
  BannerTypeDesc = 'bannerType_DESC',
  ContextAsc = 'context_ASC',
  ContextDesc = 'context_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DisplayAsc = 'display_ASC',
  DisplayDesc = 'display_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MoreInfoTextAsc = 'moreInfoText_ASC',
  MoreInfoTextDesc = 'moreInfoText_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PlatformAsc = 'platform_ASC',
  PlatformDesc = 'platform_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  ToneAsc = 'tone_ASC',
  ToneDesc = 'tone_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** Used in the ManagedContentBanner component to determine which platforms to display the banner */
export enum ManagedContentBannerPlatform {
  Candidate = 'candidate',
  Employer = 'employer',
}

/** Determines the banner type which can either be an alert, notice, or banner */
export enum ManagedContentBannerType {
  Alert = 'alert',
  Banner = 'banner',
  Notice = 'notice',
}

export type ManagedContentBannerUpdateInput = {
  bannerEnd?: InputMaybe<Scalars['DateTime']>;
  bannerStart?: InputMaybe<Scalars['DateTime']>;
  bannerType?: InputMaybe<ManagedContentBannerType>;
  context?: InputMaybe<Array<ManagedContentBannerContext>>;
  display?: InputMaybe<Scalars['Boolean']>;
  /** Manage document localizations */
  localizations?: InputMaybe<ManagedContentBannerUpdateLocalizationsInput>;
  /** message input for default locale (default) */
  message?: InputMaybe<Scalars['RichTextAST']>;
  /** moreInfoContent input for default locale (default) */
  moreInfoContent?: InputMaybe<Scalars['RichTextAST']>;
  /** moreInfoText input for default locale (default) */
  moreInfoText?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  platform?: InputMaybe<Array<ManagedContentBannerPlatform>>;
  slug?: InputMaybe<Scalars['String']>;
  tone?: InputMaybe<Tone>;
};

export type ManagedContentBannerUpdateLocalizationDataInput = {
  message?: InputMaybe<Scalars['RichTextAST']>;
  moreInfoContent?: InputMaybe<Scalars['RichTextAST']>;
  moreInfoText?: InputMaybe<Scalars['String']>;
};

export type ManagedContentBannerUpdateLocalizationInput = {
  data: ManagedContentBannerUpdateLocalizationDataInput;
  locale: Locale;
};

export type ManagedContentBannerUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<ManagedContentBannerCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<ManagedContentBannerUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<ManagedContentBannerUpsertLocalizationInput>>;
};

export type ManagedContentBannerUpdateManyInlineInput = {
  /** Connect multiple existing ManagedContentBanner documents */
  connect?: InputMaybe<Array<ManagedContentBannerConnectInput>>;
  /** Create and connect multiple ManagedContentBanner documents */
  create?: InputMaybe<Array<ManagedContentBannerCreateInput>>;
  /** Delete multiple ManagedContentBanner documents */
  delete?: InputMaybe<Array<ManagedContentBannerWhereUniqueInput>>;
  /** Disconnect multiple ManagedContentBanner documents */
  disconnect?: InputMaybe<Array<ManagedContentBannerWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing ManagedContentBanner documents */
  set?: InputMaybe<Array<ManagedContentBannerWhereUniqueInput>>;
  /** Update multiple ManagedContentBanner documents */
  update?: InputMaybe<
    Array<ManagedContentBannerUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple ManagedContentBanner documents */
  upsert?: InputMaybe<
    Array<ManagedContentBannerUpsertWithNestedWhereUniqueInput>
  >;
};

export type ManagedContentBannerUpdateManyInput = {
  bannerEnd?: InputMaybe<Scalars['DateTime']>;
  bannerStart?: InputMaybe<Scalars['DateTime']>;
  bannerType?: InputMaybe<ManagedContentBannerType>;
  context?: InputMaybe<Array<ManagedContentBannerContext>>;
  display?: InputMaybe<Scalars['Boolean']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<ManagedContentBannerUpdateManyLocalizationsInput>;
  /** message input for default locale (default) */
  message?: InputMaybe<Scalars['RichTextAST']>;
  /** moreInfoContent input for default locale (default) */
  moreInfoContent?: InputMaybe<Scalars['RichTextAST']>;
  /** moreInfoText input for default locale (default) */
  moreInfoText?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  platform?: InputMaybe<Array<ManagedContentBannerPlatform>>;
  tone?: InputMaybe<Tone>;
};

export type ManagedContentBannerUpdateManyLocalizationDataInput = {
  message?: InputMaybe<Scalars['RichTextAST']>;
  moreInfoContent?: InputMaybe<Scalars['RichTextAST']>;
  moreInfoText?: InputMaybe<Scalars['String']>;
};

export type ManagedContentBannerUpdateManyLocalizationInput = {
  data: ManagedContentBannerUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type ManagedContentBannerUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<ManagedContentBannerUpdateManyLocalizationInput>>;
};

export type ManagedContentBannerUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ManagedContentBannerUpdateManyInput;
  /** Document search */
  where: ManagedContentBannerWhereInput;
};

export type ManagedContentBannerUpdateOneInlineInput = {
  /** Connect existing ManagedContentBanner document */
  connect?: InputMaybe<ManagedContentBannerWhereUniqueInput>;
  /** Create and connect one ManagedContentBanner document */
  create?: InputMaybe<ManagedContentBannerCreateInput>;
  /** Delete currently connected ManagedContentBanner document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected ManagedContentBanner document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single ManagedContentBanner document */
  update?: InputMaybe<ManagedContentBannerUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ManagedContentBanner document */
  upsert?: InputMaybe<ManagedContentBannerUpsertWithNestedWhereUniqueInput>;
};

export type ManagedContentBannerUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ManagedContentBannerUpdateInput;
  /** Unique document search */
  where: ManagedContentBannerWhereUniqueInput;
};

export type ManagedContentBannerUpsertInput = {
  /** Create document if it didn't exist */
  create: ManagedContentBannerCreateInput;
  /** Update document if it exists */
  update: ManagedContentBannerUpdateInput;
};

export type ManagedContentBannerUpsertLocalizationInput = {
  create: ManagedContentBannerCreateLocalizationDataInput;
  locale: Locale;
  update: ManagedContentBannerUpdateLocalizationDataInput;
};

export type ManagedContentBannerUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ManagedContentBannerUpsertInput;
  /** Unique document search */
  where: ManagedContentBannerWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type ManagedContentBannerWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type ManagedContentBannerWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ManagedContentBannerWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ManagedContentBannerWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ManagedContentBannerWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  bannerEnd?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  bannerEnd_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  bannerEnd_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  bannerEnd_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  bannerEnd_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  bannerEnd_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  bannerEnd_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  bannerEnd_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  bannerStart?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  bannerStart_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  bannerStart_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  bannerStart_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  bannerStart_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  bannerStart_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  bannerStart_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  bannerStart_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  bannerType?: InputMaybe<ManagedContentBannerType>;
  /** All values that are contained in given list. */
  bannerType_in?: InputMaybe<Array<InputMaybe<ManagedContentBannerType>>>;
  /** Any other value that exists and is not equal to the given value. */
  bannerType_not?: InputMaybe<ManagedContentBannerType>;
  /** All values that are not contained in given list. */
  bannerType_not_in?: InputMaybe<Array<InputMaybe<ManagedContentBannerType>>>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  context?: InputMaybe<Array<ManagedContentBannerContext>>;
  /** Matches if the field array contains *all* items provided to the filter */
  context_contains_all?: InputMaybe<Array<ManagedContentBannerContext>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  context_contains_none?: InputMaybe<Array<ManagedContentBannerContext>>;
  /** Matches if the field array contains at least one item provided to the filter */
  context_contains_some?: InputMaybe<Array<ManagedContentBannerContext>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  context_not?: InputMaybe<Array<ManagedContentBannerContext>>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  display?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  display_not?: InputMaybe<Scalars['Boolean']>;
  documentInStages_every?: InputMaybe<ManagedContentBannerWhereStageInput>;
  documentInStages_none?: InputMaybe<ManagedContentBannerWhereStageInput>;
  documentInStages_some?: InputMaybe<ManagedContentBannerWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  moreInfoText?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  moreInfoText_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  moreInfoText_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  moreInfoText_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  moreInfoText_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  moreInfoText_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  moreInfoText_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  moreInfoText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  moreInfoText_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  moreInfoText_starts_with?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  platform?: InputMaybe<Array<ManagedContentBannerPlatform>>;
  /** Matches if the field array contains *all* items provided to the filter */
  platform_contains_all?: InputMaybe<Array<ManagedContentBannerPlatform>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  platform_contains_none?: InputMaybe<Array<ManagedContentBannerPlatform>>;
  /** Matches if the field array contains at least one item provided to the filter */
  platform_contains_some?: InputMaybe<Array<ManagedContentBannerPlatform>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  platform_not?: InputMaybe<Array<ManagedContentBannerPlatform>>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  slug?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']>;
  tone?: InputMaybe<Tone>;
  /** All values that are contained in given list. */
  tone_in?: InputMaybe<Array<InputMaybe<Tone>>>;
  /** Any other value that exists and is not equal to the given value. */
  tone_not?: InputMaybe<Tone>;
  /** All values that are not contained in given list. */
  tone_not_in?: InputMaybe<Array<InputMaybe<Tone>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type ManagedContentBannerWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ManagedContentBannerWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ManagedContentBannerWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ManagedContentBannerWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<ManagedContentBannerWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References ManagedContentBanner record uniquely */
export type ManagedContentBannerWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
};

/** Validator used for FormCheckboxGroup to validate max item allowed to be checked */
export type MaxCheckedItemValidator = Entity & {
  __typename?: 'MaxCheckedItemValidator';
  errorMessage?: Maybe<Scalars['String']>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<MaxCheckedItemValidator>;
  /** System stage field */
  stage: Stage;
  value: Scalars['Int'];
};

/** Validator used for FormCheckboxGroup to validate max item allowed to be checked */
export type MaxCheckedItemValidatorLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type MaxCheckedItemValidatorConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: MaxCheckedItemValidatorWhereUniqueInput;
};

/** A connection to a list of items. */
export type MaxCheckedItemValidatorConnection = {
  __typename?: 'MaxCheckedItemValidatorConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<MaxCheckedItemValidatorEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MaxCheckedItemValidatorCreateInput = {
  /** errorMessage input for default locale (default) */
  errorMessage?: InputMaybe<Scalars['String']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<MaxCheckedItemValidatorCreateLocalizationsInput>;
  value: Scalars['Int'];
};

export type MaxCheckedItemValidatorCreateLocalizationDataInput = {
  errorMessage?: InputMaybe<Scalars['String']>;
};

export type MaxCheckedItemValidatorCreateLocalizationInput = {
  /** Localization input */
  data: MaxCheckedItemValidatorCreateLocalizationDataInput;
  locale: Locale;
};

export type MaxCheckedItemValidatorCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<MaxCheckedItemValidatorCreateLocalizationInput>>;
};

export type MaxCheckedItemValidatorCreateManyInlineInput = {
  /** Create and connect multiple existing MaxCheckedItemValidator documents */
  create?: InputMaybe<Array<MaxCheckedItemValidatorCreateInput>>;
};

export type MaxCheckedItemValidatorCreateOneInlineInput = {
  /** Create and connect one MaxCheckedItemValidator document */
  create?: InputMaybe<MaxCheckedItemValidatorCreateInput>;
};

export type MaxCheckedItemValidatorCreateWithPositionInput = {
  /** Document to create */
  data: MaxCheckedItemValidatorCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type MaxCheckedItemValidatorEdge = {
  __typename?: 'MaxCheckedItemValidatorEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: MaxCheckedItemValidator;
};

/** Identifies documents */
export type MaxCheckedItemValidatorManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MaxCheckedItemValidatorWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MaxCheckedItemValidatorWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MaxCheckedItemValidatorWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  value?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  value_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  value_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  value_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** All values less than the given value. */
  value_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  value_lte?: InputMaybe<Scalars['Int']>;
  /** Any other value that exists and is not equal to the given value. */
  value_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export enum MaxCheckedItemValidatorOrderByInput {
  ErrorMessageAsc = 'errorMessage_ASC',
  ErrorMessageDesc = 'errorMessage_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
}

export type MaxCheckedItemValidatorParent = FormCheckboxGroup;

export type MaxCheckedItemValidatorParentConnectInput = {
  FormCheckboxGroup?: InputMaybe<FormCheckboxGroupConnectInput>;
};

export type MaxCheckedItemValidatorParentCreateInput = {
  FormCheckboxGroup?: InputMaybe<FormCheckboxGroupCreateInput>;
};

export type MaxCheckedItemValidatorParentCreateManyInlineInput = {
  /** Create and connect multiple existing MaxCheckedItemValidatorParent documents */
  create?: InputMaybe<Array<MaxCheckedItemValidatorParentCreateInput>>;
};

export type MaxCheckedItemValidatorParentCreateOneInlineInput = {
  /** Create and connect one MaxCheckedItemValidatorParent document */
  create?: InputMaybe<MaxCheckedItemValidatorParentCreateInput>;
};

export type MaxCheckedItemValidatorParentCreateWithPositionInput = {
  FormCheckboxGroup?: InputMaybe<FormCheckboxGroupCreateWithPositionInput>;
};

export type MaxCheckedItemValidatorParentUpdateInput = {
  FormCheckboxGroup?: InputMaybe<FormCheckboxGroupUpdateInput>;
};

export type MaxCheckedItemValidatorParentUpdateManyInlineInput = {
  /** Create and connect multiple MaxCheckedItemValidatorParent component instances */
  create?: InputMaybe<
    Array<MaxCheckedItemValidatorParentCreateWithPositionInput>
  >;
  /** Delete multiple MaxCheckedItemValidatorParent documents */
  delete?: InputMaybe<Array<MaxCheckedItemValidatorParentWhereUniqueInput>>;
  /** Update multiple MaxCheckedItemValidatorParent component instances */
  update?: InputMaybe<
    Array<MaxCheckedItemValidatorParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple MaxCheckedItemValidatorParent component instances */
  upsert?: InputMaybe<
    Array<MaxCheckedItemValidatorParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type MaxCheckedItemValidatorParentUpdateManyWithNestedWhereInput = {
  FormCheckboxGroup?: InputMaybe<FormCheckboxGroupUpdateManyWithNestedWhereInput>;
};

export type MaxCheckedItemValidatorParentUpdateOneInlineInput = {
  /** Create and connect one MaxCheckedItemValidatorParent document */
  create?: InputMaybe<MaxCheckedItemValidatorParentCreateInput>;
  /** Delete currently connected MaxCheckedItemValidatorParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single MaxCheckedItemValidatorParent document */
  update?: InputMaybe<MaxCheckedItemValidatorParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MaxCheckedItemValidatorParent document */
  upsert?: InputMaybe<MaxCheckedItemValidatorParentUpsertWithNestedWhereUniqueInput>;
};

export type MaxCheckedItemValidatorParentUpdateWithNestedWhereUniqueAndPositionInput =
  {
    FormCheckboxGroup?: InputMaybe<FormCheckboxGroupUpdateWithNestedWhereUniqueAndPositionInput>;
  };

export type MaxCheckedItemValidatorParentUpdateWithNestedWhereUniqueInput = {
  FormCheckboxGroup?: InputMaybe<FormCheckboxGroupUpdateWithNestedWhereUniqueInput>;
};

export type MaxCheckedItemValidatorParentUpsertWithNestedWhereUniqueAndPositionInput =
  {
    FormCheckboxGroup?: InputMaybe<FormCheckboxGroupUpsertWithNestedWhereUniqueAndPositionInput>;
  };

export type MaxCheckedItemValidatorParentUpsertWithNestedWhereUniqueInput = {
  FormCheckboxGroup?: InputMaybe<FormCheckboxGroupUpsertWithNestedWhereUniqueInput>;
};

export type MaxCheckedItemValidatorParentWhereInput = {
  FormCheckboxGroup?: InputMaybe<FormCheckboxGroupWhereInput>;
};

export type MaxCheckedItemValidatorParentWhereUniqueInput = {
  FormCheckboxGroup?: InputMaybe<FormCheckboxGroupWhereUniqueInput>;
};

export type MaxCheckedItemValidatorUpdateInput = {
  /** errorMessage input for default locale (default) */
  errorMessage?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<MaxCheckedItemValidatorUpdateLocalizationsInput>;
  value?: InputMaybe<Scalars['Int']>;
};

export type MaxCheckedItemValidatorUpdateLocalizationDataInput = {
  errorMessage?: InputMaybe<Scalars['String']>;
};

export type MaxCheckedItemValidatorUpdateLocalizationInput = {
  data: MaxCheckedItemValidatorUpdateLocalizationDataInput;
  locale: Locale;
};

export type MaxCheckedItemValidatorUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<MaxCheckedItemValidatorCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<MaxCheckedItemValidatorUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<MaxCheckedItemValidatorUpsertLocalizationInput>>;
};

export type MaxCheckedItemValidatorUpdateManyInlineInput = {
  /** Create and connect multiple MaxCheckedItemValidator component instances */
  create?: InputMaybe<Array<MaxCheckedItemValidatorCreateWithPositionInput>>;
  /** Delete multiple MaxCheckedItemValidator documents */
  delete?: InputMaybe<Array<MaxCheckedItemValidatorWhereUniqueInput>>;
  /** Update multiple MaxCheckedItemValidator component instances */
  update?: InputMaybe<
    Array<MaxCheckedItemValidatorUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple MaxCheckedItemValidator component instances */
  upsert?: InputMaybe<
    Array<MaxCheckedItemValidatorUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type MaxCheckedItemValidatorUpdateManyInput = {
  /** errorMessage input for default locale (default) */
  errorMessage?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<MaxCheckedItemValidatorUpdateManyLocalizationsInput>;
  value?: InputMaybe<Scalars['Int']>;
};

export type MaxCheckedItemValidatorUpdateManyLocalizationDataInput = {
  errorMessage?: InputMaybe<Scalars['String']>;
};

export type MaxCheckedItemValidatorUpdateManyLocalizationInput = {
  data: MaxCheckedItemValidatorUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type MaxCheckedItemValidatorUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<
    Array<MaxCheckedItemValidatorUpdateManyLocalizationInput>
  >;
};

export type MaxCheckedItemValidatorUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MaxCheckedItemValidatorUpdateManyInput;
  /** Document search */
  where: MaxCheckedItemValidatorWhereInput;
};

export type MaxCheckedItemValidatorUpdateOneInlineInput = {
  /** Create and connect one MaxCheckedItemValidator document */
  create?: InputMaybe<MaxCheckedItemValidatorCreateInput>;
  /** Delete currently connected MaxCheckedItemValidator document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single MaxCheckedItemValidator document */
  update?: InputMaybe<MaxCheckedItemValidatorUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MaxCheckedItemValidator document */
  upsert?: InputMaybe<MaxCheckedItemValidatorUpsertWithNestedWhereUniqueInput>;
};

export type MaxCheckedItemValidatorUpdateWithNestedWhereUniqueAndPositionInput =
  {
    /** Document to update */
    data?: InputMaybe<MaxCheckedItemValidatorUpdateInput>;
    /** Position in the list of existing component instances, will default to appending at the end of list */
    position?: InputMaybe<ConnectPositionInput>;
    /** Unique component instance search */
    where: MaxCheckedItemValidatorWhereUniqueInput;
  };

export type MaxCheckedItemValidatorUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: MaxCheckedItemValidatorUpdateInput;
  /** Unique document search */
  where: MaxCheckedItemValidatorWhereUniqueInput;
};

export type MaxCheckedItemValidatorUpsertInput = {
  /** Create document if it didn't exist */
  create: MaxCheckedItemValidatorCreateInput;
  /** Update document if it exists */
  update: MaxCheckedItemValidatorUpdateInput;
};

export type MaxCheckedItemValidatorUpsertLocalizationInput = {
  create: MaxCheckedItemValidatorCreateLocalizationDataInput;
  locale: Locale;
  update: MaxCheckedItemValidatorUpdateLocalizationDataInput;
};

export type MaxCheckedItemValidatorUpsertWithNestedWhereUniqueAndPositionInput =
  {
    /** Document to upsert */
    data?: InputMaybe<MaxCheckedItemValidatorUpsertInput>;
    /** Position in the list of existing component instances, will default to appending at the end of list */
    position?: InputMaybe<ConnectPositionInput>;
    /** Unique component instance search */
    where: MaxCheckedItemValidatorWhereUniqueInput;
  };

export type MaxCheckedItemValidatorUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: MaxCheckedItemValidatorUpsertInput;
  /** Unique document search */
  where: MaxCheckedItemValidatorWhereUniqueInput;
};

/** Identifies documents */
export type MaxCheckedItemValidatorWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MaxCheckedItemValidatorWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MaxCheckedItemValidatorWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MaxCheckedItemValidatorWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  errorMessage_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  errorMessage_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  errorMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  errorMessage_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  errorMessage_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  errorMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  errorMessage_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  value?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  value_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  value_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  value_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** All values less than the given value. */
  value_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  value_lte?: InputMaybe<Scalars['Int']>;
  /** Any other value that exists and is not equal to the given value. */
  value_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

/** References MaxCheckedItemValidator record uniquely */
export type MaxCheckedItemValidatorWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Validator used for FormInputText & FormTextarea to validate max characters allowed */
export type MaxLengthValidator = Entity & {
  __typename?: 'MaxLengthValidator';
  errorMessage?: Maybe<Scalars['String']>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<MaxLengthValidator>;
  /** System stage field */
  stage: Stage;
  value: Scalars['Int'];
};

/** Validator used for FormInputText & FormTextarea to validate max characters allowed */
export type MaxLengthValidatorLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type MaxLengthValidatorConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: MaxLengthValidatorWhereUniqueInput;
};

/** A connection to a list of items. */
export type MaxLengthValidatorConnection = {
  __typename?: 'MaxLengthValidatorConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<MaxLengthValidatorEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MaxLengthValidatorCreateInput = {
  /** errorMessage input for default locale (default) */
  errorMessage?: InputMaybe<Scalars['String']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<MaxLengthValidatorCreateLocalizationsInput>;
  value: Scalars['Int'];
};

export type MaxLengthValidatorCreateLocalizationDataInput = {
  errorMessage?: InputMaybe<Scalars['String']>;
};

export type MaxLengthValidatorCreateLocalizationInput = {
  /** Localization input */
  data: MaxLengthValidatorCreateLocalizationDataInput;
  locale: Locale;
};

export type MaxLengthValidatorCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<MaxLengthValidatorCreateLocalizationInput>>;
};

export type MaxLengthValidatorCreateManyInlineInput = {
  /** Create and connect multiple existing MaxLengthValidator documents */
  create?: InputMaybe<Array<MaxLengthValidatorCreateInput>>;
};

export type MaxLengthValidatorCreateOneInlineInput = {
  /** Create and connect one MaxLengthValidator document */
  create?: InputMaybe<MaxLengthValidatorCreateInput>;
};

export type MaxLengthValidatorCreateWithPositionInput = {
  /** Document to create */
  data: MaxLengthValidatorCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type MaxLengthValidatorEdge = {
  __typename?: 'MaxLengthValidatorEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: MaxLengthValidator;
};

/** Identifies documents */
export type MaxLengthValidatorManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MaxLengthValidatorWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MaxLengthValidatorWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MaxLengthValidatorWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  value?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  value_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  value_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  value_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** All values less than the given value. */
  value_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  value_lte?: InputMaybe<Scalars['Int']>;
  /** Any other value that exists and is not equal to the given value. */
  value_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export enum MaxLengthValidatorOrderByInput {
  ErrorMessageAsc = 'errorMessage_ASC',
  ErrorMessageDesc = 'errorMessage_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
}

export type MaxLengthValidatorParent = FormInputText | FormTextarea;

export type MaxLengthValidatorParentConnectInput = {
  FormInputText?: InputMaybe<FormInputTextConnectInput>;
  FormTextarea?: InputMaybe<FormTextareaConnectInput>;
};

export type MaxLengthValidatorParentCreateInput = {
  FormInputText?: InputMaybe<FormInputTextCreateInput>;
  FormTextarea?: InputMaybe<FormTextareaCreateInput>;
};

export type MaxLengthValidatorParentCreateManyInlineInput = {
  /** Create and connect multiple existing MaxLengthValidatorParent documents */
  create?: InputMaybe<Array<MaxLengthValidatorParentCreateInput>>;
};

export type MaxLengthValidatorParentCreateOneInlineInput = {
  /** Create and connect one MaxLengthValidatorParent document */
  create?: InputMaybe<MaxLengthValidatorParentCreateInput>;
};

export type MaxLengthValidatorParentCreateWithPositionInput = {
  FormInputText?: InputMaybe<FormInputTextCreateWithPositionInput>;
  FormTextarea?: InputMaybe<FormTextareaCreateWithPositionInput>;
};

export type MaxLengthValidatorParentUpdateInput = {
  FormInputText?: InputMaybe<FormInputTextUpdateInput>;
  FormTextarea?: InputMaybe<FormTextareaUpdateInput>;
};

export type MaxLengthValidatorParentUpdateManyInlineInput = {
  /** Create and connect multiple MaxLengthValidatorParent component instances */
  create?: InputMaybe<Array<MaxLengthValidatorParentCreateWithPositionInput>>;
  /** Delete multiple MaxLengthValidatorParent documents */
  delete?: InputMaybe<Array<MaxLengthValidatorParentWhereUniqueInput>>;
  /** Update multiple MaxLengthValidatorParent component instances */
  update?: InputMaybe<
    Array<MaxLengthValidatorParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple MaxLengthValidatorParent component instances */
  upsert?: InputMaybe<
    Array<MaxLengthValidatorParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type MaxLengthValidatorParentUpdateManyWithNestedWhereInput = {
  FormInputText?: InputMaybe<FormInputTextUpdateManyWithNestedWhereInput>;
  FormTextarea?: InputMaybe<FormTextareaUpdateManyWithNestedWhereInput>;
};

export type MaxLengthValidatorParentUpdateOneInlineInput = {
  /** Create and connect one MaxLengthValidatorParent document */
  create?: InputMaybe<MaxLengthValidatorParentCreateInput>;
  /** Delete currently connected MaxLengthValidatorParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single MaxLengthValidatorParent document */
  update?: InputMaybe<MaxLengthValidatorParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MaxLengthValidatorParent document */
  upsert?: InputMaybe<MaxLengthValidatorParentUpsertWithNestedWhereUniqueInput>;
};

export type MaxLengthValidatorParentUpdateWithNestedWhereUniqueAndPositionInput =
  {
    FormInputText?: InputMaybe<FormInputTextUpdateWithNestedWhereUniqueAndPositionInput>;
    FormTextarea?: InputMaybe<FormTextareaUpdateWithNestedWhereUniqueAndPositionInput>;
  };

export type MaxLengthValidatorParentUpdateWithNestedWhereUniqueInput = {
  FormInputText?: InputMaybe<FormInputTextUpdateWithNestedWhereUniqueInput>;
  FormTextarea?: InputMaybe<FormTextareaUpdateWithNestedWhereUniqueInput>;
};

export type MaxLengthValidatorParentUpsertWithNestedWhereUniqueAndPositionInput =
  {
    FormInputText?: InputMaybe<FormInputTextUpsertWithNestedWhereUniqueAndPositionInput>;
    FormTextarea?: InputMaybe<FormTextareaUpsertWithNestedWhereUniqueAndPositionInput>;
  };

export type MaxLengthValidatorParentUpsertWithNestedWhereUniqueInput = {
  FormInputText?: InputMaybe<FormInputTextUpsertWithNestedWhereUniqueInput>;
  FormTextarea?: InputMaybe<FormTextareaUpsertWithNestedWhereUniqueInput>;
};

export type MaxLengthValidatorParentWhereInput = {
  FormInputText?: InputMaybe<FormInputTextWhereInput>;
  FormTextarea?: InputMaybe<FormTextareaWhereInput>;
};

export type MaxLengthValidatorParentWhereUniqueInput = {
  FormInputText?: InputMaybe<FormInputTextWhereUniqueInput>;
  FormTextarea?: InputMaybe<FormTextareaWhereUniqueInput>;
};

export type MaxLengthValidatorUpdateInput = {
  /** errorMessage input for default locale (default) */
  errorMessage?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<MaxLengthValidatorUpdateLocalizationsInput>;
  value?: InputMaybe<Scalars['Int']>;
};

export type MaxLengthValidatorUpdateLocalizationDataInput = {
  errorMessage?: InputMaybe<Scalars['String']>;
};

export type MaxLengthValidatorUpdateLocalizationInput = {
  data: MaxLengthValidatorUpdateLocalizationDataInput;
  locale: Locale;
};

export type MaxLengthValidatorUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<MaxLengthValidatorCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<MaxLengthValidatorUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<MaxLengthValidatorUpsertLocalizationInput>>;
};

export type MaxLengthValidatorUpdateManyInlineInput = {
  /** Create and connect multiple MaxLengthValidator component instances */
  create?: InputMaybe<Array<MaxLengthValidatorCreateWithPositionInput>>;
  /** Delete multiple MaxLengthValidator documents */
  delete?: InputMaybe<Array<MaxLengthValidatorWhereUniqueInput>>;
  /** Update multiple MaxLengthValidator component instances */
  update?: InputMaybe<
    Array<MaxLengthValidatorUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple MaxLengthValidator component instances */
  upsert?: InputMaybe<
    Array<MaxLengthValidatorUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type MaxLengthValidatorUpdateManyInput = {
  /** errorMessage input for default locale (default) */
  errorMessage?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<MaxLengthValidatorUpdateManyLocalizationsInput>;
  value?: InputMaybe<Scalars['Int']>;
};

export type MaxLengthValidatorUpdateManyLocalizationDataInput = {
  errorMessage?: InputMaybe<Scalars['String']>;
};

export type MaxLengthValidatorUpdateManyLocalizationInput = {
  data: MaxLengthValidatorUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type MaxLengthValidatorUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<MaxLengthValidatorUpdateManyLocalizationInput>>;
};

export type MaxLengthValidatorUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MaxLengthValidatorUpdateManyInput;
  /** Document search */
  where: MaxLengthValidatorWhereInput;
};

export type MaxLengthValidatorUpdateOneInlineInput = {
  /** Create and connect one MaxLengthValidator document */
  create?: InputMaybe<MaxLengthValidatorCreateInput>;
  /** Delete currently connected MaxLengthValidator document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single MaxLengthValidator document */
  update?: InputMaybe<MaxLengthValidatorUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MaxLengthValidator document */
  upsert?: InputMaybe<MaxLengthValidatorUpsertWithNestedWhereUniqueInput>;
};

export type MaxLengthValidatorUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<MaxLengthValidatorUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: MaxLengthValidatorWhereUniqueInput;
};

export type MaxLengthValidatorUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: MaxLengthValidatorUpdateInput;
  /** Unique document search */
  where: MaxLengthValidatorWhereUniqueInput;
};

export type MaxLengthValidatorUpsertInput = {
  /** Create document if it didn't exist */
  create: MaxLengthValidatorCreateInput;
  /** Update document if it exists */
  update: MaxLengthValidatorUpdateInput;
};

export type MaxLengthValidatorUpsertLocalizationInput = {
  create: MaxLengthValidatorCreateLocalizationDataInput;
  locale: Locale;
  update: MaxLengthValidatorUpdateLocalizationDataInput;
};

export type MaxLengthValidatorUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<MaxLengthValidatorUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: MaxLengthValidatorWhereUniqueInput;
};

export type MaxLengthValidatorUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: MaxLengthValidatorUpsertInput;
  /** Unique document search */
  where: MaxLengthValidatorWhereUniqueInput;
};

/** Identifies documents */
export type MaxLengthValidatorWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MaxLengthValidatorWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MaxLengthValidatorWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MaxLengthValidatorWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  errorMessage_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  errorMessage_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  errorMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  errorMessage_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  errorMessage_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  errorMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  errorMessage_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  value?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  value_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  value_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  value_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** All values less than the given value. */
  value_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  value_lte?: InputMaybe<Scalars['Int']>;
  /** Any other value that exists and is not equal to the given value. */
  value_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

/** References MaxLengthValidator record uniquely */
export type MaxLengthValidatorWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Validator used for FormInputNumber to validate max number allowed */
export type MaxNumberValidator = Entity & {
  __typename?: 'MaxNumberValidator';
  errorMessage?: Maybe<Scalars['String']>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<MaxNumberValidator>;
  /** System stage field */
  stage: Stage;
  value: Scalars['Int'];
};

/** Validator used for FormInputNumber to validate max number allowed */
export type MaxNumberValidatorLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type MaxNumberValidatorConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: MaxNumberValidatorWhereUniqueInput;
};

/** A connection to a list of items. */
export type MaxNumberValidatorConnection = {
  __typename?: 'MaxNumberValidatorConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<MaxNumberValidatorEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MaxNumberValidatorCreateInput = {
  /** errorMessage input for default locale (default) */
  errorMessage?: InputMaybe<Scalars['String']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<MaxNumberValidatorCreateLocalizationsInput>;
  value: Scalars['Int'];
};

export type MaxNumberValidatorCreateLocalizationDataInput = {
  errorMessage?: InputMaybe<Scalars['String']>;
};

export type MaxNumberValidatorCreateLocalizationInput = {
  /** Localization input */
  data: MaxNumberValidatorCreateLocalizationDataInput;
  locale: Locale;
};

export type MaxNumberValidatorCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<MaxNumberValidatorCreateLocalizationInput>>;
};

export type MaxNumberValidatorCreateManyInlineInput = {
  /** Create and connect multiple existing MaxNumberValidator documents */
  create?: InputMaybe<Array<MaxNumberValidatorCreateInput>>;
};

export type MaxNumberValidatorCreateOneInlineInput = {
  /** Create and connect one MaxNumberValidator document */
  create?: InputMaybe<MaxNumberValidatorCreateInput>;
};

export type MaxNumberValidatorCreateWithPositionInput = {
  /** Document to create */
  data: MaxNumberValidatorCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type MaxNumberValidatorEdge = {
  __typename?: 'MaxNumberValidatorEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: MaxNumberValidator;
};

/** Identifies documents */
export type MaxNumberValidatorManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MaxNumberValidatorWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MaxNumberValidatorWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MaxNumberValidatorWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  value?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  value_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  value_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  value_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** All values less than the given value. */
  value_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  value_lte?: InputMaybe<Scalars['Int']>;
  /** Any other value that exists and is not equal to the given value. */
  value_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export enum MaxNumberValidatorOrderByInput {
  ErrorMessageAsc = 'errorMessage_ASC',
  ErrorMessageDesc = 'errorMessage_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
}

export type MaxNumberValidatorParent = FormInputNumber;

export type MaxNumberValidatorParentConnectInput = {
  FormInputNumber?: InputMaybe<FormInputNumberConnectInput>;
};

export type MaxNumberValidatorParentCreateInput = {
  FormInputNumber?: InputMaybe<FormInputNumberCreateInput>;
};

export type MaxNumberValidatorParentCreateManyInlineInput = {
  /** Create and connect multiple existing MaxNumberValidatorParent documents */
  create?: InputMaybe<Array<MaxNumberValidatorParentCreateInput>>;
};

export type MaxNumberValidatorParentCreateOneInlineInput = {
  /** Create and connect one MaxNumberValidatorParent document */
  create?: InputMaybe<MaxNumberValidatorParentCreateInput>;
};

export type MaxNumberValidatorParentCreateWithPositionInput = {
  FormInputNumber?: InputMaybe<FormInputNumberCreateWithPositionInput>;
};

export type MaxNumberValidatorParentUpdateInput = {
  FormInputNumber?: InputMaybe<FormInputNumberUpdateInput>;
};

export type MaxNumberValidatorParentUpdateManyInlineInput = {
  /** Create and connect multiple MaxNumberValidatorParent component instances */
  create?: InputMaybe<Array<MaxNumberValidatorParentCreateWithPositionInput>>;
  /** Delete multiple MaxNumberValidatorParent documents */
  delete?: InputMaybe<Array<MaxNumberValidatorParentWhereUniqueInput>>;
  /** Update multiple MaxNumberValidatorParent component instances */
  update?: InputMaybe<
    Array<MaxNumberValidatorParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple MaxNumberValidatorParent component instances */
  upsert?: InputMaybe<
    Array<MaxNumberValidatorParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type MaxNumberValidatorParentUpdateManyWithNestedWhereInput = {
  FormInputNumber?: InputMaybe<FormInputNumberUpdateManyWithNestedWhereInput>;
};

export type MaxNumberValidatorParentUpdateOneInlineInput = {
  /** Create and connect one MaxNumberValidatorParent document */
  create?: InputMaybe<MaxNumberValidatorParentCreateInput>;
  /** Delete currently connected MaxNumberValidatorParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single MaxNumberValidatorParent document */
  update?: InputMaybe<MaxNumberValidatorParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MaxNumberValidatorParent document */
  upsert?: InputMaybe<MaxNumberValidatorParentUpsertWithNestedWhereUniqueInput>;
};

export type MaxNumberValidatorParentUpdateWithNestedWhereUniqueAndPositionInput =
  {
    FormInputNumber?: InputMaybe<FormInputNumberUpdateWithNestedWhereUniqueAndPositionInput>;
  };

export type MaxNumberValidatorParentUpdateWithNestedWhereUniqueInput = {
  FormInputNumber?: InputMaybe<FormInputNumberUpdateWithNestedWhereUniqueInput>;
};

export type MaxNumberValidatorParentUpsertWithNestedWhereUniqueAndPositionInput =
  {
    FormInputNumber?: InputMaybe<FormInputNumberUpsertWithNestedWhereUniqueAndPositionInput>;
  };

export type MaxNumberValidatorParentUpsertWithNestedWhereUniqueInput = {
  FormInputNumber?: InputMaybe<FormInputNumberUpsertWithNestedWhereUniqueInput>;
};

export type MaxNumberValidatorParentWhereInput = {
  FormInputNumber?: InputMaybe<FormInputNumberWhereInput>;
};

export type MaxNumberValidatorParentWhereUniqueInput = {
  FormInputNumber?: InputMaybe<FormInputNumberWhereUniqueInput>;
};

export type MaxNumberValidatorUpdateInput = {
  /** errorMessage input for default locale (default) */
  errorMessage?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<MaxNumberValidatorUpdateLocalizationsInput>;
  value?: InputMaybe<Scalars['Int']>;
};

export type MaxNumberValidatorUpdateLocalizationDataInput = {
  errorMessage?: InputMaybe<Scalars['String']>;
};

export type MaxNumberValidatorUpdateLocalizationInput = {
  data: MaxNumberValidatorUpdateLocalizationDataInput;
  locale: Locale;
};

export type MaxNumberValidatorUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<MaxNumberValidatorCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<MaxNumberValidatorUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<MaxNumberValidatorUpsertLocalizationInput>>;
};

export type MaxNumberValidatorUpdateManyInlineInput = {
  /** Create and connect multiple MaxNumberValidator component instances */
  create?: InputMaybe<Array<MaxNumberValidatorCreateWithPositionInput>>;
  /** Delete multiple MaxNumberValidator documents */
  delete?: InputMaybe<Array<MaxNumberValidatorWhereUniqueInput>>;
  /** Update multiple MaxNumberValidator component instances */
  update?: InputMaybe<
    Array<MaxNumberValidatorUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple MaxNumberValidator component instances */
  upsert?: InputMaybe<
    Array<MaxNumberValidatorUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type MaxNumberValidatorUpdateManyInput = {
  /** errorMessage input for default locale (default) */
  errorMessage?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<MaxNumberValidatorUpdateManyLocalizationsInput>;
  value?: InputMaybe<Scalars['Int']>;
};

export type MaxNumberValidatorUpdateManyLocalizationDataInput = {
  errorMessage?: InputMaybe<Scalars['String']>;
};

export type MaxNumberValidatorUpdateManyLocalizationInput = {
  data: MaxNumberValidatorUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type MaxNumberValidatorUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<MaxNumberValidatorUpdateManyLocalizationInput>>;
};

export type MaxNumberValidatorUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MaxNumberValidatorUpdateManyInput;
  /** Document search */
  where: MaxNumberValidatorWhereInput;
};

export type MaxNumberValidatorUpdateOneInlineInput = {
  /** Create and connect one MaxNumberValidator document */
  create?: InputMaybe<MaxNumberValidatorCreateInput>;
  /** Delete currently connected MaxNumberValidator document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single MaxNumberValidator document */
  update?: InputMaybe<MaxNumberValidatorUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MaxNumberValidator document */
  upsert?: InputMaybe<MaxNumberValidatorUpsertWithNestedWhereUniqueInput>;
};

export type MaxNumberValidatorUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<MaxNumberValidatorUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: MaxNumberValidatorWhereUniqueInput;
};

export type MaxNumberValidatorUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: MaxNumberValidatorUpdateInput;
  /** Unique document search */
  where: MaxNumberValidatorWhereUniqueInput;
};

export type MaxNumberValidatorUpsertInput = {
  /** Create document if it didn't exist */
  create: MaxNumberValidatorCreateInput;
  /** Update document if it exists */
  update: MaxNumberValidatorUpdateInput;
};

export type MaxNumberValidatorUpsertLocalizationInput = {
  create: MaxNumberValidatorCreateLocalizationDataInput;
  locale: Locale;
  update: MaxNumberValidatorUpdateLocalizationDataInput;
};

export type MaxNumberValidatorUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<MaxNumberValidatorUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: MaxNumberValidatorWhereUniqueInput;
};

export type MaxNumberValidatorUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: MaxNumberValidatorUpsertInput;
  /** Unique document search */
  where: MaxNumberValidatorWhereUniqueInput;
};

/** Identifies documents */
export type MaxNumberValidatorWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MaxNumberValidatorWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MaxNumberValidatorWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MaxNumberValidatorWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  errorMessage_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  errorMessage_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  errorMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  errorMessage_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  errorMessage_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  errorMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  errorMessage_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  value?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  value_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  value_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  value_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** All values less than the given value. */
  value_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  value_lte?: InputMaybe<Scalars['Int']>;
  /** Any other value that exists and is not equal to the given value. */
  value_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

/** References MaxNumberValidator record uniquely */
export type MaxNumberValidatorWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MigrationHistory = Entity &
  Node & {
    __typename?: 'MigrationHistory';
    actionContent?: Maybe<Scalars['Json']>;
    author?: Maybe<Scalars['String']>;
    /** The time the document was created */
    createdAt: Scalars['DateTime'];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<MigrationHistory>;
    /** List of MigrationHistory versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars['ID'];
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars['DateTime'];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type MigrationHistoryCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MigrationHistoryDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type MigrationHistoryHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};

export type MigrationHistoryPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MigrationHistoryScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type MigrationHistoryUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MigrationHistoryConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: MigrationHistoryWhereUniqueInput;
};

/** A connection to a list of items. */
export type MigrationHistoryConnection = {
  __typename?: 'MigrationHistoryConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<MigrationHistoryEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MigrationHistoryCreateInput = {
  actionContent?: InputMaybe<Scalars['Json']>;
  author?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MigrationHistoryCreateManyInlineInput = {
  /** Connect multiple existing MigrationHistory documents */
  connect?: InputMaybe<Array<MigrationHistoryWhereUniqueInput>>;
  /** Create and connect multiple existing MigrationHistory documents */
  create?: InputMaybe<Array<MigrationHistoryCreateInput>>;
};

export type MigrationHistoryCreateOneInlineInput = {
  /** Connect one existing MigrationHistory document */
  connect?: InputMaybe<MigrationHistoryWhereUniqueInput>;
  /** Create and connect one MigrationHistory document */
  create?: InputMaybe<MigrationHistoryCreateInput>;
};

/** An edge in a connection. */
export type MigrationHistoryEdge = {
  __typename?: 'MigrationHistoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: MigrationHistory;
};

/** Identifies documents */
export type MigrationHistoryManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MigrationHistoryWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MigrationHistoryWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MigrationHistoryWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  /** All values containing the given json path. */
  actionContent_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  actionContent_value_recursive?: InputMaybe<Scalars['Json']>;
  author?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  author_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  author_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  author_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MigrationHistoryWhereStageInput>;
  documentInStages_none?: InputMaybe<MigrationHistoryWhereStageInput>;
  documentInStages_some?: InputMaybe<MigrationHistoryWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum MigrationHistoryOrderByInput {
  AuthorAsc = 'author_ASC',
  AuthorDesc = 'author_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type MigrationHistoryUpdateInput = {
  actionContent?: InputMaybe<Scalars['Json']>;
  author?: InputMaybe<Scalars['String']>;
};

export type MigrationHistoryUpdateManyInlineInput = {
  /** Connect multiple existing MigrationHistory documents */
  connect?: InputMaybe<Array<MigrationHistoryConnectInput>>;
  /** Create and connect multiple MigrationHistory documents */
  create?: InputMaybe<Array<MigrationHistoryCreateInput>>;
  /** Delete multiple MigrationHistory documents */
  delete?: InputMaybe<Array<MigrationHistoryWhereUniqueInput>>;
  /** Disconnect multiple MigrationHistory documents */
  disconnect?: InputMaybe<Array<MigrationHistoryWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing MigrationHistory documents */
  set?: InputMaybe<Array<MigrationHistoryWhereUniqueInput>>;
  /** Update multiple MigrationHistory documents */
  update?: InputMaybe<Array<MigrationHistoryUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple MigrationHistory documents */
  upsert?: InputMaybe<Array<MigrationHistoryUpsertWithNestedWhereUniqueInput>>;
};

export type MigrationHistoryUpdateManyInput = {
  actionContent?: InputMaybe<Scalars['Json']>;
  author?: InputMaybe<Scalars['String']>;
};

export type MigrationHistoryUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MigrationHistoryUpdateManyInput;
  /** Document search */
  where: MigrationHistoryWhereInput;
};

export type MigrationHistoryUpdateOneInlineInput = {
  /** Connect existing MigrationHistory document */
  connect?: InputMaybe<MigrationHistoryWhereUniqueInput>;
  /** Create and connect one MigrationHistory document */
  create?: InputMaybe<MigrationHistoryCreateInput>;
  /** Delete currently connected MigrationHistory document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected MigrationHistory document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single MigrationHistory document */
  update?: InputMaybe<MigrationHistoryUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MigrationHistory document */
  upsert?: InputMaybe<MigrationHistoryUpsertWithNestedWhereUniqueInput>;
};

export type MigrationHistoryUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: MigrationHistoryUpdateInput;
  /** Unique document search */
  where: MigrationHistoryWhereUniqueInput;
};

export type MigrationHistoryUpsertInput = {
  /** Create document if it didn't exist */
  create: MigrationHistoryCreateInput;
  /** Update document if it exists */
  update: MigrationHistoryUpdateInput;
};

export type MigrationHistoryUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: MigrationHistoryUpsertInput;
  /** Unique document search */
  where: MigrationHistoryWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type MigrationHistoryWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type MigrationHistoryWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MigrationHistoryWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MigrationHistoryWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MigrationHistoryWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  /** All values containing the given json path. */
  actionContent_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  actionContent_value_recursive?: InputMaybe<Scalars['Json']>;
  author?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  author_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  author_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  author_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MigrationHistoryWhereStageInput>;
  documentInStages_none?: InputMaybe<MigrationHistoryWhereStageInput>;
  documentInStages_some?: InputMaybe<MigrationHistoryWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type MigrationHistoryWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MigrationHistoryWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MigrationHistoryWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MigrationHistoryWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<MigrationHistoryWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References MigrationHistory record uniquely */
export type MigrationHistoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MigrationState = Entity &
  Node & {
    __typename?: 'MigrationState';
    author: Scalars['String'];
    /** The time the document was created */
    createdAt: Scalars['DateTime'];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<MigrationState>;
    /** List of MigrationState versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars['ID'];
    name: Scalars['String'];
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars['DateTime'];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type MigrationStateCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MigrationStateDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type MigrationStateHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};

export type MigrationStatePublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MigrationStateScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type MigrationStateUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MigrationStateConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: MigrationStateWhereUniqueInput;
};

/** A connection to a list of items. */
export type MigrationStateConnection = {
  __typename?: 'MigrationStateConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<MigrationStateEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MigrationStateCreateInput = {
  author: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MigrationStateCreateManyInlineInput = {
  /** Connect multiple existing MigrationState documents */
  connect?: InputMaybe<Array<MigrationStateWhereUniqueInput>>;
  /** Create and connect multiple existing MigrationState documents */
  create?: InputMaybe<Array<MigrationStateCreateInput>>;
};

export type MigrationStateCreateOneInlineInput = {
  /** Connect one existing MigrationState document */
  connect?: InputMaybe<MigrationStateWhereUniqueInput>;
  /** Create and connect one MigrationState document */
  create?: InputMaybe<MigrationStateCreateInput>;
};

/** An edge in a connection. */
export type MigrationStateEdge = {
  __typename?: 'MigrationStateEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: MigrationState;
};

/** Identifies documents */
export type MigrationStateManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MigrationStateWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MigrationStateWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MigrationStateWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  author?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  author_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  author_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  author_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MigrationStateWhereStageInput>;
  documentInStages_none?: InputMaybe<MigrationStateWhereStageInput>;
  documentInStages_some?: InputMaybe<MigrationStateWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum MigrationStateOrderByInput {
  AuthorAsc = 'author_ASC',
  AuthorDesc = 'author_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type MigrationStateUpdateInput = {
  author?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type MigrationStateUpdateManyInlineInput = {
  /** Connect multiple existing MigrationState documents */
  connect?: InputMaybe<Array<MigrationStateConnectInput>>;
  /** Create and connect multiple MigrationState documents */
  create?: InputMaybe<Array<MigrationStateCreateInput>>;
  /** Delete multiple MigrationState documents */
  delete?: InputMaybe<Array<MigrationStateWhereUniqueInput>>;
  /** Disconnect multiple MigrationState documents */
  disconnect?: InputMaybe<Array<MigrationStateWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing MigrationState documents */
  set?: InputMaybe<Array<MigrationStateWhereUniqueInput>>;
  /** Update multiple MigrationState documents */
  update?: InputMaybe<Array<MigrationStateUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple MigrationState documents */
  upsert?: InputMaybe<Array<MigrationStateUpsertWithNestedWhereUniqueInput>>;
};

export type MigrationStateUpdateManyInput = {
  author?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type MigrationStateUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MigrationStateUpdateManyInput;
  /** Document search */
  where: MigrationStateWhereInput;
};

export type MigrationStateUpdateOneInlineInput = {
  /** Connect existing MigrationState document */
  connect?: InputMaybe<MigrationStateWhereUniqueInput>;
  /** Create and connect one MigrationState document */
  create?: InputMaybe<MigrationStateCreateInput>;
  /** Delete currently connected MigrationState document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected MigrationState document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single MigrationState document */
  update?: InputMaybe<MigrationStateUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MigrationState document */
  upsert?: InputMaybe<MigrationStateUpsertWithNestedWhereUniqueInput>;
};

export type MigrationStateUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: MigrationStateUpdateInput;
  /** Unique document search */
  where: MigrationStateWhereUniqueInput;
};

export type MigrationStateUpsertInput = {
  /** Create document if it didn't exist */
  create: MigrationStateCreateInput;
  /** Update document if it exists */
  update: MigrationStateUpdateInput;
};

export type MigrationStateUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: MigrationStateUpsertInput;
  /** Unique document search */
  where: MigrationStateWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type MigrationStateWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type MigrationStateWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MigrationStateWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MigrationStateWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MigrationStateWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  author?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  author_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  author_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  author_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  author_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  author_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  author_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  author_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  author_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  author_starts_with?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MigrationStateWhereStageInput>;
  documentInStages_none?: InputMaybe<MigrationStateWhereStageInput>;
  documentInStages_some?: InputMaybe<MigrationStateWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type MigrationStateWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MigrationStateWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MigrationStateWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MigrationStateWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<MigrationStateWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References MigrationState record uniquely */
export type MigrationStateWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Validator used for FormCheckboxGroup to validate min item allowed to be checked */
export type MinCheckedItemValidator = Entity & {
  __typename?: 'MinCheckedItemValidator';
  errorMessage?: Maybe<Scalars['String']>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<MinCheckedItemValidator>;
  /** System stage field */
  stage: Stage;
  value: Scalars['Int'];
};

/** Validator used for FormCheckboxGroup to validate min item allowed to be checked */
export type MinCheckedItemValidatorLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type MinCheckedItemValidatorConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: MinCheckedItemValidatorWhereUniqueInput;
};

/** A connection to a list of items. */
export type MinCheckedItemValidatorConnection = {
  __typename?: 'MinCheckedItemValidatorConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<MinCheckedItemValidatorEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MinCheckedItemValidatorCreateInput = {
  /** errorMessage input for default locale (default) */
  errorMessage?: InputMaybe<Scalars['String']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<MinCheckedItemValidatorCreateLocalizationsInput>;
  value: Scalars['Int'];
};

export type MinCheckedItemValidatorCreateLocalizationDataInput = {
  errorMessage?: InputMaybe<Scalars['String']>;
};

export type MinCheckedItemValidatorCreateLocalizationInput = {
  /** Localization input */
  data: MinCheckedItemValidatorCreateLocalizationDataInput;
  locale: Locale;
};

export type MinCheckedItemValidatorCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<MinCheckedItemValidatorCreateLocalizationInput>>;
};

export type MinCheckedItemValidatorCreateManyInlineInput = {
  /** Create and connect multiple existing MinCheckedItemValidator documents */
  create?: InputMaybe<Array<MinCheckedItemValidatorCreateInput>>;
};

export type MinCheckedItemValidatorCreateOneInlineInput = {
  /** Create and connect one MinCheckedItemValidator document */
  create?: InputMaybe<MinCheckedItemValidatorCreateInput>;
};

export type MinCheckedItemValidatorCreateWithPositionInput = {
  /** Document to create */
  data: MinCheckedItemValidatorCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type MinCheckedItemValidatorEdge = {
  __typename?: 'MinCheckedItemValidatorEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: MinCheckedItemValidator;
};

/** Identifies documents */
export type MinCheckedItemValidatorManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MinCheckedItemValidatorWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MinCheckedItemValidatorWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MinCheckedItemValidatorWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  value?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  value_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  value_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  value_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** All values less than the given value. */
  value_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  value_lte?: InputMaybe<Scalars['Int']>;
  /** Any other value that exists and is not equal to the given value. */
  value_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export enum MinCheckedItemValidatorOrderByInput {
  ErrorMessageAsc = 'errorMessage_ASC',
  ErrorMessageDesc = 'errorMessage_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
}

export type MinCheckedItemValidatorParent = FormCheckboxGroup;

export type MinCheckedItemValidatorParentConnectInput = {
  FormCheckboxGroup?: InputMaybe<FormCheckboxGroupConnectInput>;
};

export type MinCheckedItemValidatorParentCreateInput = {
  FormCheckboxGroup?: InputMaybe<FormCheckboxGroupCreateInput>;
};

export type MinCheckedItemValidatorParentCreateManyInlineInput = {
  /** Create and connect multiple existing MinCheckedItemValidatorParent documents */
  create?: InputMaybe<Array<MinCheckedItemValidatorParentCreateInput>>;
};

export type MinCheckedItemValidatorParentCreateOneInlineInput = {
  /** Create and connect one MinCheckedItemValidatorParent document */
  create?: InputMaybe<MinCheckedItemValidatorParentCreateInput>;
};

export type MinCheckedItemValidatorParentCreateWithPositionInput = {
  FormCheckboxGroup?: InputMaybe<FormCheckboxGroupCreateWithPositionInput>;
};

export type MinCheckedItemValidatorParentUpdateInput = {
  FormCheckboxGroup?: InputMaybe<FormCheckboxGroupUpdateInput>;
};

export type MinCheckedItemValidatorParentUpdateManyInlineInput = {
  /** Create and connect multiple MinCheckedItemValidatorParent component instances */
  create?: InputMaybe<
    Array<MinCheckedItemValidatorParentCreateWithPositionInput>
  >;
  /** Delete multiple MinCheckedItemValidatorParent documents */
  delete?: InputMaybe<Array<MinCheckedItemValidatorParentWhereUniqueInput>>;
  /** Update multiple MinCheckedItemValidatorParent component instances */
  update?: InputMaybe<
    Array<MinCheckedItemValidatorParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple MinCheckedItemValidatorParent component instances */
  upsert?: InputMaybe<
    Array<MinCheckedItemValidatorParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type MinCheckedItemValidatorParentUpdateManyWithNestedWhereInput = {
  FormCheckboxGroup?: InputMaybe<FormCheckboxGroupUpdateManyWithNestedWhereInput>;
};

export type MinCheckedItemValidatorParentUpdateOneInlineInput = {
  /** Create and connect one MinCheckedItemValidatorParent document */
  create?: InputMaybe<MinCheckedItemValidatorParentCreateInput>;
  /** Delete currently connected MinCheckedItemValidatorParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single MinCheckedItemValidatorParent document */
  update?: InputMaybe<MinCheckedItemValidatorParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MinCheckedItemValidatorParent document */
  upsert?: InputMaybe<MinCheckedItemValidatorParentUpsertWithNestedWhereUniqueInput>;
};

export type MinCheckedItemValidatorParentUpdateWithNestedWhereUniqueAndPositionInput =
  {
    FormCheckboxGroup?: InputMaybe<FormCheckboxGroupUpdateWithNestedWhereUniqueAndPositionInput>;
  };

export type MinCheckedItemValidatorParentUpdateWithNestedWhereUniqueInput = {
  FormCheckboxGroup?: InputMaybe<FormCheckboxGroupUpdateWithNestedWhereUniqueInput>;
};

export type MinCheckedItemValidatorParentUpsertWithNestedWhereUniqueAndPositionInput =
  {
    FormCheckboxGroup?: InputMaybe<FormCheckboxGroupUpsertWithNestedWhereUniqueAndPositionInput>;
  };

export type MinCheckedItemValidatorParentUpsertWithNestedWhereUniqueInput = {
  FormCheckboxGroup?: InputMaybe<FormCheckboxGroupUpsertWithNestedWhereUniqueInput>;
};

export type MinCheckedItemValidatorParentWhereInput = {
  FormCheckboxGroup?: InputMaybe<FormCheckboxGroupWhereInput>;
};

export type MinCheckedItemValidatorParentWhereUniqueInput = {
  FormCheckboxGroup?: InputMaybe<FormCheckboxGroupWhereUniqueInput>;
};

export type MinCheckedItemValidatorUpdateInput = {
  /** errorMessage input for default locale (default) */
  errorMessage?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<MinCheckedItemValidatorUpdateLocalizationsInput>;
  value?: InputMaybe<Scalars['Int']>;
};

export type MinCheckedItemValidatorUpdateLocalizationDataInput = {
  errorMessage?: InputMaybe<Scalars['String']>;
};

export type MinCheckedItemValidatorUpdateLocalizationInput = {
  data: MinCheckedItemValidatorUpdateLocalizationDataInput;
  locale: Locale;
};

export type MinCheckedItemValidatorUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<MinCheckedItemValidatorCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<MinCheckedItemValidatorUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<MinCheckedItemValidatorUpsertLocalizationInput>>;
};

export type MinCheckedItemValidatorUpdateManyInlineInput = {
  /** Create and connect multiple MinCheckedItemValidator component instances */
  create?: InputMaybe<Array<MinCheckedItemValidatorCreateWithPositionInput>>;
  /** Delete multiple MinCheckedItemValidator documents */
  delete?: InputMaybe<Array<MinCheckedItemValidatorWhereUniqueInput>>;
  /** Update multiple MinCheckedItemValidator component instances */
  update?: InputMaybe<
    Array<MinCheckedItemValidatorUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple MinCheckedItemValidator component instances */
  upsert?: InputMaybe<
    Array<MinCheckedItemValidatorUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type MinCheckedItemValidatorUpdateManyInput = {
  /** errorMessage input for default locale (default) */
  errorMessage?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<MinCheckedItemValidatorUpdateManyLocalizationsInput>;
  value?: InputMaybe<Scalars['Int']>;
};

export type MinCheckedItemValidatorUpdateManyLocalizationDataInput = {
  errorMessage?: InputMaybe<Scalars['String']>;
};

export type MinCheckedItemValidatorUpdateManyLocalizationInput = {
  data: MinCheckedItemValidatorUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type MinCheckedItemValidatorUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<
    Array<MinCheckedItemValidatorUpdateManyLocalizationInput>
  >;
};

export type MinCheckedItemValidatorUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MinCheckedItemValidatorUpdateManyInput;
  /** Document search */
  where: MinCheckedItemValidatorWhereInput;
};

export type MinCheckedItemValidatorUpdateOneInlineInput = {
  /** Create and connect one MinCheckedItemValidator document */
  create?: InputMaybe<MinCheckedItemValidatorCreateInput>;
  /** Delete currently connected MinCheckedItemValidator document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single MinCheckedItemValidator document */
  update?: InputMaybe<MinCheckedItemValidatorUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MinCheckedItemValidator document */
  upsert?: InputMaybe<MinCheckedItemValidatorUpsertWithNestedWhereUniqueInput>;
};

export type MinCheckedItemValidatorUpdateWithNestedWhereUniqueAndPositionInput =
  {
    /** Document to update */
    data?: InputMaybe<MinCheckedItemValidatorUpdateInput>;
    /** Position in the list of existing component instances, will default to appending at the end of list */
    position?: InputMaybe<ConnectPositionInput>;
    /** Unique component instance search */
    where: MinCheckedItemValidatorWhereUniqueInput;
  };

export type MinCheckedItemValidatorUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: MinCheckedItemValidatorUpdateInput;
  /** Unique document search */
  where: MinCheckedItemValidatorWhereUniqueInput;
};

export type MinCheckedItemValidatorUpsertInput = {
  /** Create document if it didn't exist */
  create: MinCheckedItemValidatorCreateInput;
  /** Update document if it exists */
  update: MinCheckedItemValidatorUpdateInput;
};

export type MinCheckedItemValidatorUpsertLocalizationInput = {
  create: MinCheckedItemValidatorCreateLocalizationDataInput;
  locale: Locale;
  update: MinCheckedItemValidatorUpdateLocalizationDataInput;
};

export type MinCheckedItemValidatorUpsertWithNestedWhereUniqueAndPositionInput =
  {
    /** Document to upsert */
    data?: InputMaybe<MinCheckedItemValidatorUpsertInput>;
    /** Position in the list of existing component instances, will default to appending at the end of list */
    position?: InputMaybe<ConnectPositionInput>;
    /** Unique component instance search */
    where: MinCheckedItemValidatorWhereUniqueInput;
  };

export type MinCheckedItemValidatorUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: MinCheckedItemValidatorUpsertInput;
  /** Unique document search */
  where: MinCheckedItemValidatorWhereUniqueInput;
};

/** Identifies documents */
export type MinCheckedItemValidatorWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MinCheckedItemValidatorWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MinCheckedItemValidatorWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MinCheckedItemValidatorWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  errorMessage_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  errorMessage_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  errorMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  errorMessage_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  errorMessage_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  errorMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  errorMessage_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  value?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  value_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  value_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  value_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** All values less than the given value. */
  value_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  value_lte?: InputMaybe<Scalars['Int']>;
  /** Any other value that exists and is not equal to the given value. */
  value_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

/** References MinCheckedItemValidator record uniquely */
export type MinCheckedItemValidatorWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Validator used for FormInputText & FormTextarea to validate min characters allowed */
export type MinLengthValidator = Entity & {
  __typename?: 'MinLengthValidator';
  errorMessage?: Maybe<Scalars['String']>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<MinLengthValidator>;
  /** System stage field */
  stage: Stage;
  value: Scalars['Int'];
};

/** Validator used for FormInputText & FormTextarea to validate min characters allowed */
export type MinLengthValidatorLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type MinLengthValidatorConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: MinLengthValidatorWhereUniqueInput;
};

/** A connection to a list of items. */
export type MinLengthValidatorConnection = {
  __typename?: 'MinLengthValidatorConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<MinLengthValidatorEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MinLengthValidatorCreateInput = {
  /** errorMessage input for default locale (default) */
  errorMessage?: InputMaybe<Scalars['String']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<MinLengthValidatorCreateLocalizationsInput>;
  value: Scalars['Int'];
};

export type MinLengthValidatorCreateLocalizationDataInput = {
  errorMessage?: InputMaybe<Scalars['String']>;
};

export type MinLengthValidatorCreateLocalizationInput = {
  /** Localization input */
  data: MinLengthValidatorCreateLocalizationDataInput;
  locale: Locale;
};

export type MinLengthValidatorCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<MinLengthValidatorCreateLocalizationInput>>;
};

export type MinLengthValidatorCreateManyInlineInput = {
  /** Create and connect multiple existing MinLengthValidator documents */
  create?: InputMaybe<Array<MinLengthValidatorCreateInput>>;
};

export type MinLengthValidatorCreateOneInlineInput = {
  /** Create and connect one MinLengthValidator document */
  create?: InputMaybe<MinLengthValidatorCreateInput>;
};

export type MinLengthValidatorCreateWithPositionInput = {
  /** Document to create */
  data: MinLengthValidatorCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type MinLengthValidatorEdge = {
  __typename?: 'MinLengthValidatorEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: MinLengthValidator;
};

/** Identifies documents */
export type MinLengthValidatorManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MinLengthValidatorWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MinLengthValidatorWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MinLengthValidatorWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  value?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  value_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  value_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  value_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** All values less than the given value. */
  value_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  value_lte?: InputMaybe<Scalars['Int']>;
  /** Any other value that exists and is not equal to the given value. */
  value_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export enum MinLengthValidatorOrderByInput {
  ErrorMessageAsc = 'errorMessage_ASC',
  ErrorMessageDesc = 'errorMessage_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
}

export type MinLengthValidatorParent = FormInputText | FormTextarea;

export type MinLengthValidatorParentConnectInput = {
  FormInputText?: InputMaybe<FormInputTextConnectInput>;
  FormTextarea?: InputMaybe<FormTextareaConnectInput>;
};

export type MinLengthValidatorParentCreateInput = {
  FormInputText?: InputMaybe<FormInputTextCreateInput>;
  FormTextarea?: InputMaybe<FormTextareaCreateInput>;
};

export type MinLengthValidatorParentCreateManyInlineInput = {
  /** Create and connect multiple existing MinLengthValidatorParent documents */
  create?: InputMaybe<Array<MinLengthValidatorParentCreateInput>>;
};

export type MinLengthValidatorParentCreateOneInlineInput = {
  /** Create and connect one MinLengthValidatorParent document */
  create?: InputMaybe<MinLengthValidatorParentCreateInput>;
};

export type MinLengthValidatorParentCreateWithPositionInput = {
  FormInputText?: InputMaybe<FormInputTextCreateWithPositionInput>;
  FormTextarea?: InputMaybe<FormTextareaCreateWithPositionInput>;
};

export type MinLengthValidatorParentUpdateInput = {
  FormInputText?: InputMaybe<FormInputTextUpdateInput>;
  FormTextarea?: InputMaybe<FormTextareaUpdateInput>;
};

export type MinLengthValidatorParentUpdateManyInlineInput = {
  /** Create and connect multiple MinLengthValidatorParent component instances */
  create?: InputMaybe<Array<MinLengthValidatorParentCreateWithPositionInput>>;
  /** Delete multiple MinLengthValidatorParent documents */
  delete?: InputMaybe<Array<MinLengthValidatorParentWhereUniqueInput>>;
  /** Update multiple MinLengthValidatorParent component instances */
  update?: InputMaybe<
    Array<MinLengthValidatorParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple MinLengthValidatorParent component instances */
  upsert?: InputMaybe<
    Array<MinLengthValidatorParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type MinLengthValidatorParentUpdateManyWithNestedWhereInput = {
  FormInputText?: InputMaybe<FormInputTextUpdateManyWithNestedWhereInput>;
  FormTextarea?: InputMaybe<FormTextareaUpdateManyWithNestedWhereInput>;
};

export type MinLengthValidatorParentUpdateOneInlineInput = {
  /** Create and connect one MinLengthValidatorParent document */
  create?: InputMaybe<MinLengthValidatorParentCreateInput>;
  /** Delete currently connected MinLengthValidatorParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single MinLengthValidatorParent document */
  update?: InputMaybe<MinLengthValidatorParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MinLengthValidatorParent document */
  upsert?: InputMaybe<MinLengthValidatorParentUpsertWithNestedWhereUniqueInput>;
};

export type MinLengthValidatorParentUpdateWithNestedWhereUniqueAndPositionInput =
  {
    FormInputText?: InputMaybe<FormInputTextUpdateWithNestedWhereUniqueAndPositionInput>;
    FormTextarea?: InputMaybe<FormTextareaUpdateWithNestedWhereUniqueAndPositionInput>;
  };

export type MinLengthValidatorParentUpdateWithNestedWhereUniqueInput = {
  FormInputText?: InputMaybe<FormInputTextUpdateWithNestedWhereUniqueInput>;
  FormTextarea?: InputMaybe<FormTextareaUpdateWithNestedWhereUniqueInput>;
};

export type MinLengthValidatorParentUpsertWithNestedWhereUniqueAndPositionInput =
  {
    FormInputText?: InputMaybe<FormInputTextUpsertWithNestedWhereUniqueAndPositionInput>;
    FormTextarea?: InputMaybe<FormTextareaUpsertWithNestedWhereUniqueAndPositionInput>;
  };

export type MinLengthValidatorParentUpsertWithNestedWhereUniqueInput = {
  FormInputText?: InputMaybe<FormInputTextUpsertWithNestedWhereUniqueInput>;
  FormTextarea?: InputMaybe<FormTextareaUpsertWithNestedWhereUniqueInput>;
};

export type MinLengthValidatorParentWhereInput = {
  FormInputText?: InputMaybe<FormInputTextWhereInput>;
  FormTextarea?: InputMaybe<FormTextareaWhereInput>;
};

export type MinLengthValidatorParentWhereUniqueInput = {
  FormInputText?: InputMaybe<FormInputTextWhereUniqueInput>;
  FormTextarea?: InputMaybe<FormTextareaWhereUniqueInput>;
};

export type MinLengthValidatorUpdateInput = {
  /** errorMessage input for default locale (default) */
  errorMessage?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<MinLengthValidatorUpdateLocalizationsInput>;
  value?: InputMaybe<Scalars['Int']>;
};

export type MinLengthValidatorUpdateLocalizationDataInput = {
  errorMessage?: InputMaybe<Scalars['String']>;
};

export type MinLengthValidatorUpdateLocalizationInput = {
  data: MinLengthValidatorUpdateLocalizationDataInput;
  locale: Locale;
};

export type MinLengthValidatorUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<MinLengthValidatorCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<MinLengthValidatorUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<MinLengthValidatorUpsertLocalizationInput>>;
};

export type MinLengthValidatorUpdateManyInlineInput = {
  /** Create and connect multiple MinLengthValidator component instances */
  create?: InputMaybe<Array<MinLengthValidatorCreateWithPositionInput>>;
  /** Delete multiple MinLengthValidator documents */
  delete?: InputMaybe<Array<MinLengthValidatorWhereUniqueInput>>;
  /** Update multiple MinLengthValidator component instances */
  update?: InputMaybe<
    Array<MinLengthValidatorUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple MinLengthValidator component instances */
  upsert?: InputMaybe<
    Array<MinLengthValidatorUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type MinLengthValidatorUpdateManyInput = {
  /** errorMessage input for default locale (default) */
  errorMessage?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<MinLengthValidatorUpdateManyLocalizationsInput>;
  value?: InputMaybe<Scalars['Int']>;
};

export type MinLengthValidatorUpdateManyLocalizationDataInput = {
  errorMessage?: InputMaybe<Scalars['String']>;
};

export type MinLengthValidatorUpdateManyLocalizationInput = {
  data: MinLengthValidatorUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type MinLengthValidatorUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<MinLengthValidatorUpdateManyLocalizationInput>>;
};

export type MinLengthValidatorUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MinLengthValidatorUpdateManyInput;
  /** Document search */
  where: MinLengthValidatorWhereInput;
};

export type MinLengthValidatorUpdateOneInlineInput = {
  /** Create and connect one MinLengthValidator document */
  create?: InputMaybe<MinLengthValidatorCreateInput>;
  /** Delete currently connected MinLengthValidator document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single MinLengthValidator document */
  update?: InputMaybe<MinLengthValidatorUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MinLengthValidator document */
  upsert?: InputMaybe<MinLengthValidatorUpsertWithNestedWhereUniqueInput>;
};

export type MinLengthValidatorUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<MinLengthValidatorUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: MinLengthValidatorWhereUniqueInput;
};

export type MinLengthValidatorUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: MinLengthValidatorUpdateInput;
  /** Unique document search */
  where: MinLengthValidatorWhereUniqueInput;
};

export type MinLengthValidatorUpsertInput = {
  /** Create document if it didn't exist */
  create: MinLengthValidatorCreateInput;
  /** Update document if it exists */
  update: MinLengthValidatorUpdateInput;
};

export type MinLengthValidatorUpsertLocalizationInput = {
  create: MinLengthValidatorCreateLocalizationDataInput;
  locale: Locale;
  update: MinLengthValidatorUpdateLocalizationDataInput;
};

export type MinLengthValidatorUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<MinLengthValidatorUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: MinLengthValidatorWhereUniqueInput;
};

export type MinLengthValidatorUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: MinLengthValidatorUpsertInput;
  /** Unique document search */
  where: MinLengthValidatorWhereUniqueInput;
};

/** Identifies documents */
export type MinLengthValidatorWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MinLengthValidatorWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MinLengthValidatorWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MinLengthValidatorWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  errorMessage_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  errorMessage_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  errorMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  errorMessage_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  errorMessage_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  errorMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  errorMessage_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  value?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  value_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  value_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  value_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** All values less than the given value. */
  value_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  value_lte?: InputMaybe<Scalars['Int']>;
  /** Any other value that exists and is not equal to the given value. */
  value_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

/** References MinLengthValidator record uniquely */
export type MinLengthValidatorWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Validator used for FormInputNumber to validate min number allowed */
export type MinNumberValidator = Entity & {
  __typename?: 'MinNumberValidator';
  errorMessage?: Maybe<Scalars['String']>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<MinNumberValidator>;
  /** System stage field */
  stage: Stage;
  value: Scalars['Int'];
};

/** Validator used for FormInputNumber to validate min number allowed */
export type MinNumberValidatorLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type MinNumberValidatorConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: MinNumberValidatorWhereUniqueInput;
};

/** A connection to a list of items. */
export type MinNumberValidatorConnection = {
  __typename?: 'MinNumberValidatorConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<MinNumberValidatorEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MinNumberValidatorCreateInput = {
  /** errorMessage input for default locale (default) */
  errorMessage?: InputMaybe<Scalars['String']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<MinNumberValidatorCreateLocalizationsInput>;
  value: Scalars['Int'];
};

export type MinNumberValidatorCreateLocalizationDataInput = {
  errorMessage?: InputMaybe<Scalars['String']>;
};

export type MinNumberValidatorCreateLocalizationInput = {
  /** Localization input */
  data: MinNumberValidatorCreateLocalizationDataInput;
  locale: Locale;
};

export type MinNumberValidatorCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<MinNumberValidatorCreateLocalizationInput>>;
};

export type MinNumberValidatorCreateManyInlineInput = {
  /** Create and connect multiple existing MinNumberValidator documents */
  create?: InputMaybe<Array<MinNumberValidatorCreateInput>>;
};

export type MinNumberValidatorCreateOneInlineInput = {
  /** Create and connect one MinNumberValidator document */
  create?: InputMaybe<MinNumberValidatorCreateInput>;
};

export type MinNumberValidatorCreateWithPositionInput = {
  /** Document to create */
  data: MinNumberValidatorCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type MinNumberValidatorEdge = {
  __typename?: 'MinNumberValidatorEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: MinNumberValidator;
};

/** Identifies documents */
export type MinNumberValidatorManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MinNumberValidatorWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MinNumberValidatorWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MinNumberValidatorWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  value?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  value_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  value_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  value_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** All values less than the given value. */
  value_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  value_lte?: InputMaybe<Scalars['Int']>;
  /** Any other value that exists and is not equal to the given value. */
  value_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export enum MinNumberValidatorOrderByInput {
  ErrorMessageAsc = 'errorMessage_ASC',
  ErrorMessageDesc = 'errorMessage_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC',
}

export type MinNumberValidatorParent = FormInputNumber;

export type MinNumberValidatorParentConnectInput = {
  FormInputNumber?: InputMaybe<FormInputNumberConnectInput>;
};

export type MinNumberValidatorParentCreateInput = {
  FormInputNumber?: InputMaybe<FormInputNumberCreateInput>;
};

export type MinNumberValidatorParentCreateManyInlineInput = {
  /** Create and connect multiple existing MinNumberValidatorParent documents */
  create?: InputMaybe<Array<MinNumberValidatorParentCreateInput>>;
};

export type MinNumberValidatorParentCreateOneInlineInput = {
  /** Create and connect one MinNumberValidatorParent document */
  create?: InputMaybe<MinNumberValidatorParentCreateInput>;
};

export type MinNumberValidatorParentCreateWithPositionInput = {
  FormInputNumber?: InputMaybe<FormInputNumberCreateWithPositionInput>;
};

export type MinNumberValidatorParentUpdateInput = {
  FormInputNumber?: InputMaybe<FormInputNumberUpdateInput>;
};

export type MinNumberValidatorParentUpdateManyInlineInput = {
  /** Create and connect multiple MinNumberValidatorParent component instances */
  create?: InputMaybe<Array<MinNumberValidatorParentCreateWithPositionInput>>;
  /** Delete multiple MinNumberValidatorParent documents */
  delete?: InputMaybe<Array<MinNumberValidatorParentWhereUniqueInput>>;
  /** Update multiple MinNumberValidatorParent component instances */
  update?: InputMaybe<
    Array<MinNumberValidatorParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple MinNumberValidatorParent component instances */
  upsert?: InputMaybe<
    Array<MinNumberValidatorParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type MinNumberValidatorParentUpdateManyWithNestedWhereInput = {
  FormInputNumber?: InputMaybe<FormInputNumberUpdateManyWithNestedWhereInput>;
};

export type MinNumberValidatorParentUpdateOneInlineInput = {
  /** Create and connect one MinNumberValidatorParent document */
  create?: InputMaybe<MinNumberValidatorParentCreateInput>;
  /** Delete currently connected MinNumberValidatorParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single MinNumberValidatorParent document */
  update?: InputMaybe<MinNumberValidatorParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MinNumberValidatorParent document */
  upsert?: InputMaybe<MinNumberValidatorParentUpsertWithNestedWhereUniqueInput>;
};

export type MinNumberValidatorParentUpdateWithNestedWhereUniqueAndPositionInput =
  {
    FormInputNumber?: InputMaybe<FormInputNumberUpdateWithNestedWhereUniqueAndPositionInput>;
  };

export type MinNumberValidatorParentUpdateWithNestedWhereUniqueInput = {
  FormInputNumber?: InputMaybe<FormInputNumberUpdateWithNestedWhereUniqueInput>;
};

export type MinNumberValidatorParentUpsertWithNestedWhereUniqueAndPositionInput =
  {
    FormInputNumber?: InputMaybe<FormInputNumberUpsertWithNestedWhereUniqueAndPositionInput>;
  };

export type MinNumberValidatorParentUpsertWithNestedWhereUniqueInput = {
  FormInputNumber?: InputMaybe<FormInputNumberUpsertWithNestedWhereUniqueInput>;
};

export type MinNumberValidatorParentWhereInput = {
  FormInputNumber?: InputMaybe<FormInputNumberWhereInput>;
};

export type MinNumberValidatorParentWhereUniqueInput = {
  FormInputNumber?: InputMaybe<FormInputNumberWhereUniqueInput>;
};

export type MinNumberValidatorUpdateInput = {
  /** errorMessage input for default locale (default) */
  errorMessage?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<MinNumberValidatorUpdateLocalizationsInput>;
  value?: InputMaybe<Scalars['Int']>;
};

export type MinNumberValidatorUpdateLocalizationDataInput = {
  errorMessage?: InputMaybe<Scalars['String']>;
};

export type MinNumberValidatorUpdateLocalizationInput = {
  data: MinNumberValidatorUpdateLocalizationDataInput;
  locale: Locale;
};

export type MinNumberValidatorUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<MinNumberValidatorCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<MinNumberValidatorUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<MinNumberValidatorUpsertLocalizationInput>>;
};

export type MinNumberValidatorUpdateManyInlineInput = {
  /** Create and connect multiple MinNumberValidator component instances */
  create?: InputMaybe<Array<MinNumberValidatorCreateWithPositionInput>>;
  /** Delete multiple MinNumberValidator documents */
  delete?: InputMaybe<Array<MinNumberValidatorWhereUniqueInput>>;
  /** Update multiple MinNumberValidator component instances */
  update?: InputMaybe<
    Array<MinNumberValidatorUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple MinNumberValidator component instances */
  upsert?: InputMaybe<
    Array<MinNumberValidatorUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type MinNumberValidatorUpdateManyInput = {
  /** errorMessage input for default locale (default) */
  errorMessage?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<MinNumberValidatorUpdateManyLocalizationsInput>;
  value?: InputMaybe<Scalars['Int']>;
};

export type MinNumberValidatorUpdateManyLocalizationDataInput = {
  errorMessage?: InputMaybe<Scalars['String']>;
};

export type MinNumberValidatorUpdateManyLocalizationInput = {
  data: MinNumberValidatorUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type MinNumberValidatorUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<MinNumberValidatorUpdateManyLocalizationInput>>;
};

export type MinNumberValidatorUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MinNumberValidatorUpdateManyInput;
  /** Document search */
  where: MinNumberValidatorWhereInput;
};

export type MinNumberValidatorUpdateOneInlineInput = {
  /** Create and connect one MinNumberValidator document */
  create?: InputMaybe<MinNumberValidatorCreateInput>;
  /** Delete currently connected MinNumberValidator document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single MinNumberValidator document */
  update?: InputMaybe<MinNumberValidatorUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MinNumberValidator document */
  upsert?: InputMaybe<MinNumberValidatorUpsertWithNestedWhereUniqueInput>;
};

export type MinNumberValidatorUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<MinNumberValidatorUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: MinNumberValidatorWhereUniqueInput;
};

export type MinNumberValidatorUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: MinNumberValidatorUpdateInput;
  /** Unique document search */
  where: MinNumberValidatorWhereUniqueInput;
};

export type MinNumberValidatorUpsertInput = {
  /** Create document if it didn't exist */
  create: MinNumberValidatorCreateInput;
  /** Update document if it exists */
  update: MinNumberValidatorUpdateInput;
};

export type MinNumberValidatorUpsertLocalizationInput = {
  create: MinNumberValidatorCreateLocalizationDataInput;
  locale: Locale;
  update: MinNumberValidatorUpdateLocalizationDataInput;
};

export type MinNumberValidatorUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<MinNumberValidatorUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: MinNumberValidatorWhereUniqueInput;
};

export type MinNumberValidatorUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: MinNumberValidatorUpsertInput;
  /** Unique document search */
  where: MinNumberValidatorWhereUniqueInput;
};

/** Identifies documents */
export type MinNumberValidatorWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MinNumberValidatorWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MinNumberValidatorWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MinNumberValidatorWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  errorMessage_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  errorMessage_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  errorMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  errorMessage_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  errorMessage_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  errorMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  errorMessage_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  value?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  value_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  value_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  value_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** All values less than the given value. */
  value_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  value_lte?: InputMaybe<Scalars['Int']>;
  /** Any other value that exists and is not equal to the given value. */
  value_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

/** References MinNumberValidator record uniquely */
export type MinNumberValidatorWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Create one article */
  createArticle?: Maybe<Article>;
  /** Create an asset. Use the returned info to finish the creation process by uploading the asset. */
  createAsset?: Maybe<Asset>;
  /** Create one author */
  createAuthor?: Maybe<Author>;
  /** Create one bannerItem */
  createBannerItem?: Maybe<BannerItem>;
  /** Create one cBlock */
  createCBlock?: Maybe<CBlock>;
  /** Create one cButtonLink */
  createCButtonLink?: Maybe<CButtonLink>;
  /** Create one cImageLink */
  createCImageLink?: Maybe<CImageLink>;
  /** Create one cTextLink */
  createCTextLink?: Maybe<CTextLink>;
  /** Create one category */
  createCategory?: Maybe<Category>;
  /** Create one dialogButton */
  createDialogButton?: Maybe<DialogButton>;
  /** Create one documentItem */
  createDocumentItem?: Maybe<DocumentItem>;
  /** Create one embedAccordionGroup */
  createEmbedAccordionGroup?: Maybe<EmbedAccordionGroup>;
  /** Create one embedCustomContent */
  createEmbedCustomContent?: Maybe<EmbedCustomContent>;
  /** Create one embedSingleLineText */
  createEmbedSingleLineText?: Maybe<EmbedSingleLineText>;
  /** Create one externalUrl */
  createExternalUrl?: Maybe<ExternalUrl>;
  /** Create one form */
  createForm?: Maybe<Form>;
  /** Create one managedContentBanner */
  createManagedContentBanner?: Maybe<ManagedContentBanner>;
  /** Create one migrationHistory */
  createMigrationHistory?: Maybe<MigrationHistory>;
  /** Create one migrationState */
  createMigrationState?: Maybe<MigrationState>;
  /** Create one page */
  createPage?: Maybe<Page>;
  /** Create one richTextEditor */
  createRichTextEditor?: Maybe<RichTextEditor>;
  /** Create one scheduledRelease */
  createScheduledRelease?: Maybe<ScheduledRelease>;
  /** Create one section */
  createSection?: Maybe<Section>;
  /** Create one site */
  createSite?: Maybe<Site>;
  /** Create one siteUsersConnection */
  createSiteUsersConnection?: Maybe<SiteUsersConnection>;
  /** Create one topic */
  createTopic?: Maybe<Topic>;
  /** Delete one article from _all_ existing stages. Returns deleted document. */
  deleteArticle?: Maybe<Article>;
  /** Delete one asset from _all_ existing stages. Returns deleted document. */
  deleteAsset?: Maybe<Asset>;
  /** Delete one author from _all_ existing stages. Returns deleted document. */
  deleteAuthor?: Maybe<Author>;
  /** Delete one bannerItem from _all_ existing stages. Returns deleted document. */
  deleteBannerItem?: Maybe<BannerItem>;
  /** Delete one cBlock from _all_ existing stages. Returns deleted document. */
  deleteCBlock?: Maybe<CBlock>;
  /** Delete one cButtonLink from _all_ existing stages. Returns deleted document. */
  deleteCButtonLink?: Maybe<CButtonLink>;
  /** Delete one cImageLink from _all_ existing stages. Returns deleted document. */
  deleteCImageLink?: Maybe<CImageLink>;
  /** Delete one cTextLink from _all_ existing stages. Returns deleted document. */
  deleteCTextLink?: Maybe<CTextLink>;
  /** Delete one category from _all_ existing stages. Returns deleted document. */
  deleteCategory?: Maybe<Category>;
  /** Delete one dialogButton from _all_ existing stages. Returns deleted document. */
  deleteDialogButton?: Maybe<DialogButton>;
  /** Delete one documentItem from _all_ existing stages. Returns deleted document. */
  deleteDocumentItem?: Maybe<DocumentItem>;
  /** Delete one embedAccordionGroup from _all_ existing stages. Returns deleted document. */
  deleteEmbedAccordionGroup?: Maybe<EmbedAccordionGroup>;
  /** Delete one embedCustomContent from _all_ existing stages. Returns deleted document. */
  deleteEmbedCustomContent?: Maybe<EmbedCustomContent>;
  /** Delete one embedSingleLineText from _all_ existing stages. Returns deleted document. */
  deleteEmbedSingleLineText?: Maybe<EmbedSingleLineText>;
  /** Delete one externalUrl from _all_ existing stages. Returns deleted document. */
  deleteExternalUrl?: Maybe<ExternalUrl>;
  /** Delete one form from _all_ existing stages. Returns deleted document. */
  deleteForm?: Maybe<Form>;
  /** Delete one managedContentBanner from _all_ existing stages. Returns deleted document. */
  deleteManagedContentBanner?: Maybe<ManagedContentBanner>;
  /**
   * Delete many Article documents
   * @deprecated Please use the new paginated many mutation (deleteManyArticlesConnection)
   */
  deleteManyArticles: BatchPayload;
  /** Delete many Article documents, return deleted documents */
  deleteManyArticlesConnection: ArticleConnection;
  /**
   * Delete many Asset documents
   * @deprecated Please use the new paginated many mutation (deleteManyAssetsConnection)
   */
  deleteManyAssets: BatchPayload;
  /** Delete many Asset documents, return deleted documents */
  deleteManyAssetsConnection: AssetConnection;
  /**
   * Delete many Author documents
   * @deprecated Please use the new paginated many mutation (deleteManyAuthorsConnection)
   */
  deleteManyAuthors: BatchPayload;
  /** Delete many Author documents, return deleted documents */
  deleteManyAuthorsConnection: AuthorConnection;
  /**
   * Delete many BannerItem documents
   * @deprecated Please use the new paginated many mutation (deleteManyBannerItemsConnection)
   */
  deleteManyBannerItems: BatchPayload;
  /** Delete many BannerItem documents, return deleted documents */
  deleteManyBannerItemsConnection: BannerItemConnection;
  /**
   * Delete many CBlock documents
   * @deprecated Please use the new paginated many mutation (deleteManyCBlocksConnection)
   */
  deleteManyCBlocks: BatchPayload;
  /** Delete many CBlock documents, return deleted documents */
  deleteManyCBlocksConnection: CBlockConnection;
  /**
   * Delete many CButtonLink documents
   * @deprecated Please use the new paginated many mutation (deleteManyCButtonLinksConnection)
   */
  deleteManyCButtonLinks: BatchPayload;
  /** Delete many CButtonLink documents, return deleted documents */
  deleteManyCButtonLinksConnection: CButtonLinkConnection;
  /**
   * Delete many CImageLink documents
   * @deprecated Please use the new paginated many mutation (deleteManyCImageLinksConnection)
   */
  deleteManyCImageLinks: BatchPayload;
  /** Delete many CImageLink documents, return deleted documents */
  deleteManyCImageLinksConnection: CImageLinkConnection;
  /**
   * Delete many CTextLink documents
   * @deprecated Please use the new paginated many mutation (deleteManyCTextLinksConnection)
   */
  deleteManyCTextLinks: BatchPayload;
  /** Delete many CTextLink documents, return deleted documents */
  deleteManyCTextLinksConnection: CTextLinkConnection;
  /**
   * Delete many Category documents
   * @deprecated Please use the new paginated many mutation (deleteManyCategoriesConnection)
   */
  deleteManyCategories: BatchPayload;
  /** Delete many Category documents, return deleted documents */
  deleteManyCategoriesConnection: CategoryConnection;
  /**
   * Delete many DialogButton documents
   * @deprecated Please use the new paginated many mutation (deleteManyDialogButtonsConnection)
   */
  deleteManyDialogButtons: BatchPayload;
  /** Delete many DialogButton documents, return deleted documents */
  deleteManyDialogButtonsConnection: DialogButtonConnection;
  /**
   * Delete many DocumentItem documents
   * @deprecated Please use the new paginated many mutation (deleteManyDocumentItemsConnection)
   */
  deleteManyDocumentItems: BatchPayload;
  /** Delete many DocumentItem documents, return deleted documents */
  deleteManyDocumentItemsConnection: DocumentItemConnection;
  /**
   * Delete many EmbedAccordionGroup documents
   * @deprecated Please use the new paginated many mutation (deleteManyEmbedAccordionGroupsConnection)
   */
  deleteManyEmbedAccordionGroups: BatchPayload;
  /** Delete many EmbedAccordionGroup documents, return deleted documents */
  deleteManyEmbedAccordionGroupsConnection: EmbedAccordionGroupConnection;
  /**
   * Delete many EmbedCustomContent documents
   * @deprecated Please use the new paginated many mutation (deleteManyEmbedCustomContentsConnection)
   */
  deleteManyEmbedCustomContents: BatchPayload;
  /** Delete many EmbedCustomContent documents, return deleted documents */
  deleteManyEmbedCustomContentsConnection: EmbedCustomContentConnection;
  /**
   * Delete many EmbedSingleLineText documents
   * @deprecated Please use the new paginated many mutation (deleteManyEmbedSingleLineTextsConnection)
   */
  deleteManyEmbedSingleLineTexts: BatchPayload;
  /** Delete many EmbedSingleLineText documents, return deleted documents */
  deleteManyEmbedSingleLineTextsConnection: EmbedSingleLineTextConnection;
  /**
   * Delete many ExternalUrl documents
   * @deprecated Please use the new paginated many mutation (deleteManyExternalUrlsConnection)
   */
  deleteManyExternalUrls: BatchPayload;
  /** Delete many ExternalUrl documents, return deleted documents */
  deleteManyExternalUrlsConnection: ExternalUrlConnection;
  /**
   * Delete many Form documents
   * @deprecated Please use the new paginated many mutation (deleteManyFormsConnection)
   */
  deleteManyForms: BatchPayload;
  /** Delete many Form documents, return deleted documents */
  deleteManyFormsConnection: FormConnection;
  /**
   * Delete many ManagedContentBanner documents
   * @deprecated Please use the new paginated many mutation (deleteManyManagedContentBannersConnection)
   */
  deleteManyManagedContentBanners: BatchPayload;
  /** Delete many ManagedContentBanner documents, return deleted documents */
  deleteManyManagedContentBannersConnection: ManagedContentBannerConnection;
  /**
   * Delete many MigrationHistory documents
   * @deprecated Please use the new paginated many mutation (deleteManyMigrationHistoriesConnection)
   */
  deleteManyMigrationHistories: BatchPayload;
  /** Delete many MigrationHistory documents, return deleted documents */
  deleteManyMigrationHistoriesConnection: MigrationHistoryConnection;
  /**
   * Delete many MigrationState documents
   * @deprecated Please use the new paginated many mutation (deleteManyMigrationStatesConnection)
   */
  deleteManyMigrationStates: BatchPayload;
  /** Delete many MigrationState documents, return deleted documents */
  deleteManyMigrationStatesConnection: MigrationStateConnection;
  /**
   * Delete many Page documents
   * @deprecated Please use the new paginated many mutation (deleteManyPagesConnection)
   */
  deleteManyPages: BatchPayload;
  /** Delete many Page documents, return deleted documents */
  deleteManyPagesConnection: PageConnection;
  /**
   * Delete many RichTextEditor documents
   * @deprecated Please use the new paginated many mutation (deleteManyRichTextEditorsConnection)
   */
  deleteManyRichTextEditors: BatchPayload;
  /** Delete many RichTextEditor documents, return deleted documents */
  deleteManyRichTextEditorsConnection: RichTextEditorConnection;
  /**
   * Delete many Section documents
   * @deprecated Please use the new paginated many mutation (deleteManySectionsConnection)
   */
  deleteManySections: BatchPayload;
  /** Delete many Section documents, return deleted documents */
  deleteManySectionsConnection: SectionConnection;
  /**
   * Delete many SiteUsersConnection documents
   * @deprecated Please use the new paginated many mutation (deleteManySiteUsersConnectionsConnection)
   */
  deleteManySiteUsersConnections: BatchPayload;
  /** Delete many SiteUsersConnection documents, return deleted documents */
  deleteManySiteUsersConnectionsConnection: SiteUsersConnectionConnection;
  /**
   * Delete many Site documents
   * @deprecated Please use the new paginated many mutation (deleteManySitesConnection)
   */
  deleteManySites: BatchPayload;
  /** Delete many Site documents, return deleted documents */
  deleteManySitesConnection: SiteConnection;
  /**
   * Delete many Topic documents
   * @deprecated Please use the new paginated many mutation (deleteManyTopicsConnection)
   */
  deleteManyTopics: BatchPayload;
  /** Delete many Topic documents, return deleted documents */
  deleteManyTopicsConnection: TopicConnection;
  /** Delete one migrationHistory from _all_ existing stages. Returns deleted document. */
  deleteMigrationHistory?: Maybe<MigrationHistory>;
  /** Delete one migrationState from _all_ existing stages. Returns deleted document. */
  deleteMigrationState?: Maybe<MigrationState>;
  /** Delete one page from _all_ existing stages. Returns deleted document. */
  deletePage?: Maybe<Page>;
  /** Delete one richTextEditor from _all_ existing stages. Returns deleted document. */
  deleteRichTextEditor?: Maybe<RichTextEditor>;
  /** Delete and return scheduled operation */
  deleteScheduledOperation?: Maybe<ScheduledOperation>;
  /** Delete one scheduledRelease from _all_ existing stages. Returns deleted document. */
  deleteScheduledRelease?: Maybe<ScheduledRelease>;
  /** Delete one section from _all_ existing stages. Returns deleted document. */
  deleteSection?: Maybe<Section>;
  /** Delete one site from _all_ existing stages. Returns deleted document. */
  deleteSite?: Maybe<Site>;
  /** Delete one siteUsersConnection from _all_ existing stages. Returns deleted document. */
  deleteSiteUsersConnection?: Maybe<SiteUsersConnection>;
  /** Delete one topic from _all_ existing stages. Returns deleted document. */
  deleteTopic?: Maybe<Topic>;
  /** Publish one article */
  publishArticle?: Maybe<Article>;
  /** Publish one asset */
  publishAsset?: Maybe<Asset>;
  /** Publish one author */
  publishAuthor?: Maybe<Author>;
  /** Publish one bannerItem */
  publishBannerItem?: Maybe<BannerItem>;
  /** Publish one cBlock */
  publishCBlock?: Maybe<CBlock>;
  /** Publish one cButtonLink */
  publishCButtonLink?: Maybe<CButtonLink>;
  /** Publish one cImageLink */
  publishCImageLink?: Maybe<CImageLink>;
  /** Publish one cTextLink */
  publishCTextLink?: Maybe<CTextLink>;
  /** Publish one category */
  publishCategory?: Maybe<Category>;
  /** Publish one dialogButton */
  publishDialogButton?: Maybe<DialogButton>;
  /** Publish one documentItem */
  publishDocumentItem?: Maybe<DocumentItem>;
  /** Publish one embedAccordionGroup */
  publishEmbedAccordionGroup?: Maybe<EmbedAccordionGroup>;
  /** Publish one embedCustomContent */
  publishEmbedCustomContent?: Maybe<EmbedCustomContent>;
  /** Publish one embedSingleLineText */
  publishEmbedSingleLineText?: Maybe<EmbedSingleLineText>;
  /** Publish one externalUrl */
  publishExternalUrl?: Maybe<ExternalUrl>;
  /** Publish one form */
  publishForm?: Maybe<Form>;
  /** Publish one managedContentBanner */
  publishManagedContentBanner?: Maybe<ManagedContentBanner>;
  /**
   * Publish many Article documents
   * @deprecated Please use the new paginated many mutation (publishManyArticlesConnection)
   */
  publishManyArticles: BatchPayload;
  /** Publish many Article documents */
  publishManyArticlesConnection: ArticleConnection;
  /**
   * Publish many Asset documents
   * @deprecated Please use the new paginated many mutation (publishManyAssetsConnection)
   */
  publishManyAssets: BatchPayload;
  /** Publish many Asset documents */
  publishManyAssetsConnection: AssetConnection;
  /**
   * Publish many Author documents
   * @deprecated Please use the new paginated many mutation (publishManyAuthorsConnection)
   */
  publishManyAuthors: BatchPayload;
  /** Publish many Author documents */
  publishManyAuthorsConnection: AuthorConnection;
  /**
   * Publish many BannerItem documents
   * @deprecated Please use the new paginated many mutation (publishManyBannerItemsConnection)
   */
  publishManyBannerItems: BatchPayload;
  /** Publish many BannerItem documents */
  publishManyBannerItemsConnection: BannerItemConnection;
  /**
   * Publish many CBlock documents
   * @deprecated Please use the new paginated many mutation (publishManyCBlocksConnection)
   */
  publishManyCBlocks: BatchPayload;
  /** Publish many CBlock documents */
  publishManyCBlocksConnection: CBlockConnection;
  /**
   * Publish many CButtonLink documents
   * @deprecated Please use the new paginated many mutation (publishManyCButtonLinksConnection)
   */
  publishManyCButtonLinks: BatchPayload;
  /** Publish many CButtonLink documents */
  publishManyCButtonLinksConnection: CButtonLinkConnection;
  /**
   * Publish many CImageLink documents
   * @deprecated Please use the new paginated many mutation (publishManyCImageLinksConnection)
   */
  publishManyCImageLinks: BatchPayload;
  /** Publish many CImageLink documents */
  publishManyCImageLinksConnection: CImageLinkConnection;
  /**
   * Publish many CTextLink documents
   * @deprecated Please use the new paginated many mutation (publishManyCTextLinksConnection)
   */
  publishManyCTextLinks: BatchPayload;
  /** Publish many CTextLink documents */
  publishManyCTextLinksConnection: CTextLinkConnection;
  /**
   * Publish many Category documents
   * @deprecated Please use the new paginated many mutation (publishManyCategoriesConnection)
   */
  publishManyCategories: BatchPayload;
  /** Publish many Category documents */
  publishManyCategoriesConnection: CategoryConnection;
  /**
   * Publish many DialogButton documents
   * @deprecated Please use the new paginated many mutation (publishManyDialogButtonsConnection)
   */
  publishManyDialogButtons: BatchPayload;
  /** Publish many DialogButton documents */
  publishManyDialogButtonsConnection: DialogButtonConnection;
  /**
   * Publish many DocumentItem documents
   * @deprecated Please use the new paginated many mutation (publishManyDocumentItemsConnection)
   */
  publishManyDocumentItems: BatchPayload;
  /** Publish many DocumentItem documents */
  publishManyDocumentItemsConnection: DocumentItemConnection;
  /**
   * Publish many EmbedAccordionGroup documents
   * @deprecated Please use the new paginated many mutation (publishManyEmbedAccordionGroupsConnection)
   */
  publishManyEmbedAccordionGroups: BatchPayload;
  /** Publish many EmbedAccordionGroup documents */
  publishManyEmbedAccordionGroupsConnection: EmbedAccordionGroupConnection;
  /**
   * Publish many EmbedCustomContent documents
   * @deprecated Please use the new paginated many mutation (publishManyEmbedCustomContentsConnection)
   */
  publishManyEmbedCustomContents: BatchPayload;
  /** Publish many EmbedCustomContent documents */
  publishManyEmbedCustomContentsConnection: EmbedCustomContentConnection;
  /**
   * Publish many EmbedSingleLineText documents
   * @deprecated Please use the new paginated many mutation (publishManyEmbedSingleLineTextsConnection)
   */
  publishManyEmbedSingleLineTexts: BatchPayload;
  /** Publish many EmbedSingleLineText documents */
  publishManyEmbedSingleLineTextsConnection: EmbedSingleLineTextConnection;
  /**
   * Publish many ExternalUrl documents
   * @deprecated Please use the new paginated many mutation (publishManyExternalUrlsConnection)
   */
  publishManyExternalUrls: BatchPayload;
  /** Publish many ExternalUrl documents */
  publishManyExternalUrlsConnection: ExternalUrlConnection;
  /**
   * Publish many Form documents
   * @deprecated Please use the new paginated many mutation (publishManyFormsConnection)
   */
  publishManyForms: BatchPayload;
  /** Publish many Form documents */
  publishManyFormsConnection: FormConnection;
  /**
   * Publish many ManagedContentBanner documents
   * @deprecated Please use the new paginated many mutation (publishManyManagedContentBannersConnection)
   */
  publishManyManagedContentBanners: BatchPayload;
  /** Publish many ManagedContentBanner documents */
  publishManyManagedContentBannersConnection: ManagedContentBannerConnection;
  /**
   * Publish many MigrationHistory documents
   * @deprecated Please use the new paginated many mutation (publishManyMigrationHistoriesConnection)
   */
  publishManyMigrationHistories: BatchPayload;
  /** Publish many MigrationHistory documents */
  publishManyMigrationHistoriesConnection: MigrationHistoryConnection;
  /**
   * Publish many MigrationState documents
   * @deprecated Please use the new paginated many mutation (publishManyMigrationStatesConnection)
   */
  publishManyMigrationStates: BatchPayload;
  /** Publish many MigrationState documents */
  publishManyMigrationStatesConnection: MigrationStateConnection;
  /**
   * Publish many Page documents
   * @deprecated Please use the new paginated many mutation (publishManyPagesConnection)
   */
  publishManyPages: BatchPayload;
  /** Publish many Page documents */
  publishManyPagesConnection: PageConnection;
  /**
   * Publish many RichTextEditor documents
   * @deprecated Please use the new paginated many mutation (publishManyRichTextEditorsConnection)
   */
  publishManyRichTextEditors: BatchPayload;
  /** Publish many RichTextEditor documents */
  publishManyRichTextEditorsConnection: RichTextEditorConnection;
  /**
   * Publish many Section documents
   * @deprecated Please use the new paginated many mutation (publishManySectionsConnection)
   */
  publishManySections: BatchPayload;
  /** Publish many Section documents */
  publishManySectionsConnection: SectionConnection;
  /**
   * Publish many SiteUsersConnection documents
   * @deprecated Please use the new paginated many mutation (publishManySiteUsersConnectionsConnection)
   */
  publishManySiteUsersConnections: BatchPayload;
  /** Publish many SiteUsersConnection documents */
  publishManySiteUsersConnectionsConnection: SiteUsersConnectionConnection;
  /**
   * Publish many Site documents
   * @deprecated Please use the new paginated many mutation (publishManySitesConnection)
   */
  publishManySites: BatchPayload;
  /** Publish many Site documents */
  publishManySitesConnection: SiteConnection;
  /**
   * Publish many Topic documents
   * @deprecated Please use the new paginated many mutation (publishManyTopicsConnection)
   */
  publishManyTopics: BatchPayload;
  /** Publish many Topic documents */
  publishManyTopicsConnection: TopicConnection;
  /** Publish one migrationHistory */
  publishMigrationHistory?: Maybe<MigrationHistory>;
  /** Publish one migrationState */
  publishMigrationState?: Maybe<MigrationState>;
  /** Publish one page */
  publishPage?: Maybe<Page>;
  /** Publish one richTextEditor */
  publishRichTextEditor?: Maybe<RichTextEditor>;
  /** Publish one section */
  publishSection?: Maybe<Section>;
  /** Publish one site */
  publishSite?: Maybe<Site>;
  /** Publish one siteUsersConnection */
  publishSiteUsersConnection?: Maybe<SiteUsersConnection>;
  /** Publish one topic */
  publishTopic?: Maybe<Topic>;
  /** Schedule to publish one article */
  schedulePublishArticle?: Maybe<Article>;
  /** Schedule to publish one asset */
  schedulePublishAsset?: Maybe<Asset>;
  /** Schedule to publish one author */
  schedulePublishAuthor?: Maybe<Author>;
  /** Schedule to publish one bannerItem */
  schedulePublishBannerItem?: Maybe<BannerItem>;
  /** Schedule to publish one cBlock */
  schedulePublishCBlock?: Maybe<CBlock>;
  /** Schedule to publish one cButtonLink */
  schedulePublishCButtonLink?: Maybe<CButtonLink>;
  /** Schedule to publish one cImageLink */
  schedulePublishCImageLink?: Maybe<CImageLink>;
  /** Schedule to publish one cTextLink */
  schedulePublishCTextLink?: Maybe<CTextLink>;
  /** Schedule to publish one category */
  schedulePublishCategory?: Maybe<Category>;
  /** Schedule to publish one dialogButton */
  schedulePublishDialogButton?: Maybe<DialogButton>;
  /** Schedule to publish one documentItem */
  schedulePublishDocumentItem?: Maybe<DocumentItem>;
  /** Schedule to publish one embedAccordionGroup */
  schedulePublishEmbedAccordionGroup?: Maybe<EmbedAccordionGroup>;
  /** Schedule to publish one embedCustomContent */
  schedulePublishEmbedCustomContent?: Maybe<EmbedCustomContent>;
  /** Schedule to publish one embedSingleLineText */
  schedulePublishEmbedSingleLineText?: Maybe<EmbedSingleLineText>;
  /** Schedule to publish one externalUrl */
  schedulePublishExternalUrl?: Maybe<ExternalUrl>;
  /** Schedule to publish one form */
  schedulePublishForm?: Maybe<Form>;
  /** Schedule to publish one managedContentBanner */
  schedulePublishManagedContentBanner?: Maybe<ManagedContentBanner>;
  /** Schedule to publish one migrationHistory */
  schedulePublishMigrationHistory?: Maybe<MigrationHistory>;
  /** Schedule to publish one migrationState */
  schedulePublishMigrationState?: Maybe<MigrationState>;
  /** Schedule to publish one page */
  schedulePublishPage?: Maybe<Page>;
  /** Schedule to publish one richTextEditor */
  schedulePublishRichTextEditor?: Maybe<RichTextEditor>;
  /** Schedule to publish one section */
  schedulePublishSection?: Maybe<Section>;
  /** Schedule to publish one site */
  schedulePublishSite?: Maybe<Site>;
  /** Schedule to publish one siteUsersConnection */
  schedulePublishSiteUsersConnection?: Maybe<SiteUsersConnection>;
  /** Schedule to publish one topic */
  schedulePublishTopic?: Maybe<Topic>;
  /** Unpublish one article from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishArticle?: Maybe<Article>;
  /** Unpublish one asset from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishAsset?: Maybe<Asset>;
  /** Unpublish one author from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishAuthor?: Maybe<Author>;
  /** Unpublish one bannerItem from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishBannerItem?: Maybe<BannerItem>;
  /** Unpublish one cBlock from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishCBlock?: Maybe<CBlock>;
  /** Unpublish one cButtonLink from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishCButtonLink?: Maybe<CButtonLink>;
  /** Unpublish one cImageLink from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishCImageLink?: Maybe<CImageLink>;
  /** Unpublish one cTextLink from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishCTextLink?: Maybe<CTextLink>;
  /** Unpublish one category from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishCategory?: Maybe<Category>;
  /** Unpublish one dialogButton from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishDialogButton?: Maybe<DialogButton>;
  /** Unpublish one documentItem from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishDocumentItem?: Maybe<DocumentItem>;
  /** Unpublish one embedAccordionGroup from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishEmbedAccordionGroup?: Maybe<EmbedAccordionGroup>;
  /** Unpublish one embedCustomContent from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishEmbedCustomContent?: Maybe<EmbedCustomContent>;
  /** Unpublish one embedSingleLineText from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishEmbedSingleLineText?: Maybe<EmbedSingleLineText>;
  /** Unpublish one externalUrl from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishExternalUrl?: Maybe<ExternalUrl>;
  /** Unpublish one form from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishForm?: Maybe<Form>;
  /** Unpublish one managedContentBanner from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishManagedContentBanner?: Maybe<ManagedContentBanner>;
  /** Unpublish one migrationHistory from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishMigrationHistory?: Maybe<MigrationHistory>;
  /** Unpublish one migrationState from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishMigrationState?: Maybe<MigrationState>;
  /** Unpublish one page from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishPage?: Maybe<Page>;
  /** Unpublish one richTextEditor from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishRichTextEditor?: Maybe<RichTextEditor>;
  /** Unpublish one section from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishSection?: Maybe<Section>;
  /** Unpublish one site from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishSite?: Maybe<Site>;
  /** Unpublish one siteUsersConnection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishSiteUsersConnection?: Maybe<SiteUsersConnection>;
  /** Unpublish one topic from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishTopic?: Maybe<Topic>;
  /** Unpublish one article from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishArticle?: Maybe<Article>;
  /** Unpublish one asset from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishAsset?: Maybe<Asset>;
  /** Unpublish one author from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishAuthor?: Maybe<Author>;
  /** Unpublish one bannerItem from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishBannerItem?: Maybe<BannerItem>;
  /** Unpublish one cBlock from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishCBlock?: Maybe<CBlock>;
  /** Unpublish one cButtonLink from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishCButtonLink?: Maybe<CButtonLink>;
  /** Unpublish one cImageLink from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishCImageLink?: Maybe<CImageLink>;
  /** Unpublish one cTextLink from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishCTextLink?: Maybe<CTextLink>;
  /** Unpublish one category from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishCategory?: Maybe<Category>;
  /** Unpublish one dialogButton from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishDialogButton?: Maybe<DialogButton>;
  /** Unpublish one documentItem from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishDocumentItem?: Maybe<DocumentItem>;
  /** Unpublish one embedAccordionGroup from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishEmbedAccordionGroup?: Maybe<EmbedAccordionGroup>;
  /** Unpublish one embedCustomContent from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishEmbedCustomContent?: Maybe<EmbedCustomContent>;
  /** Unpublish one embedSingleLineText from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishEmbedSingleLineText?: Maybe<EmbedSingleLineText>;
  /** Unpublish one externalUrl from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishExternalUrl?: Maybe<ExternalUrl>;
  /** Unpublish one form from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishForm?: Maybe<Form>;
  /** Unpublish one managedContentBanner from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishManagedContentBanner?: Maybe<ManagedContentBanner>;
  /**
   * Unpublish many Article documents
   * @deprecated Please use the new paginated many mutation (unpublishManyArticlesConnection)
   */
  unpublishManyArticles: BatchPayload;
  /** Find many Article documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyArticlesConnection: ArticleConnection;
  /**
   * Unpublish many Asset documents
   * @deprecated Please use the new paginated many mutation (unpublishManyAssetsConnection)
   */
  unpublishManyAssets: BatchPayload;
  /** Find many Asset documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyAssetsConnection: AssetConnection;
  /**
   * Unpublish many Author documents
   * @deprecated Please use the new paginated many mutation (unpublishManyAuthorsConnection)
   */
  unpublishManyAuthors: BatchPayload;
  /** Find many Author documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyAuthorsConnection: AuthorConnection;
  /**
   * Unpublish many BannerItem documents
   * @deprecated Please use the new paginated many mutation (unpublishManyBannerItemsConnection)
   */
  unpublishManyBannerItems: BatchPayload;
  /** Find many BannerItem documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyBannerItemsConnection: BannerItemConnection;
  /**
   * Unpublish many CBlock documents
   * @deprecated Please use the new paginated many mutation (unpublishManyCBlocksConnection)
   */
  unpublishManyCBlocks: BatchPayload;
  /** Find many CBlock documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyCBlocksConnection: CBlockConnection;
  /**
   * Unpublish many CButtonLink documents
   * @deprecated Please use the new paginated many mutation (unpublishManyCButtonLinksConnection)
   */
  unpublishManyCButtonLinks: BatchPayload;
  /** Find many CButtonLink documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyCButtonLinksConnection: CButtonLinkConnection;
  /**
   * Unpublish many CImageLink documents
   * @deprecated Please use the new paginated many mutation (unpublishManyCImageLinksConnection)
   */
  unpublishManyCImageLinks: BatchPayload;
  /** Find many CImageLink documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyCImageLinksConnection: CImageLinkConnection;
  /**
   * Unpublish many CTextLink documents
   * @deprecated Please use the new paginated many mutation (unpublishManyCTextLinksConnection)
   */
  unpublishManyCTextLinks: BatchPayload;
  /** Find many CTextLink documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyCTextLinksConnection: CTextLinkConnection;
  /**
   * Unpublish many Category documents
   * @deprecated Please use the new paginated many mutation (unpublishManyCategoriesConnection)
   */
  unpublishManyCategories: BatchPayload;
  /** Find many Category documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyCategoriesConnection: CategoryConnection;
  /**
   * Unpublish many DialogButton documents
   * @deprecated Please use the new paginated many mutation (unpublishManyDialogButtonsConnection)
   */
  unpublishManyDialogButtons: BatchPayload;
  /** Find many DialogButton documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyDialogButtonsConnection: DialogButtonConnection;
  /**
   * Unpublish many DocumentItem documents
   * @deprecated Please use the new paginated many mutation (unpublishManyDocumentItemsConnection)
   */
  unpublishManyDocumentItems: BatchPayload;
  /** Find many DocumentItem documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyDocumentItemsConnection: DocumentItemConnection;
  /**
   * Unpublish many EmbedAccordionGroup documents
   * @deprecated Please use the new paginated many mutation (unpublishManyEmbedAccordionGroupsConnection)
   */
  unpublishManyEmbedAccordionGroups: BatchPayload;
  /** Find many EmbedAccordionGroup documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyEmbedAccordionGroupsConnection: EmbedAccordionGroupConnection;
  /**
   * Unpublish many EmbedCustomContent documents
   * @deprecated Please use the new paginated many mutation (unpublishManyEmbedCustomContentsConnection)
   */
  unpublishManyEmbedCustomContents: BatchPayload;
  /** Find many EmbedCustomContent documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyEmbedCustomContentsConnection: EmbedCustomContentConnection;
  /**
   * Unpublish many EmbedSingleLineText documents
   * @deprecated Please use the new paginated many mutation (unpublishManyEmbedSingleLineTextsConnection)
   */
  unpublishManyEmbedSingleLineTexts: BatchPayload;
  /** Find many EmbedSingleLineText documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyEmbedSingleLineTextsConnection: EmbedSingleLineTextConnection;
  /**
   * Unpublish many ExternalUrl documents
   * @deprecated Please use the new paginated many mutation (unpublishManyExternalUrlsConnection)
   */
  unpublishManyExternalUrls: BatchPayload;
  /** Find many ExternalUrl documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyExternalUrlsConnection: ExternalUrlConnection;
  /**
   * Unpublish many Form documents
   * @deprecated Please use the new paginated many mutation (unpublishManyFormsConnection)
   */
  unpublishManyForms: BatchPayload;
  /** Find many Form documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyFormsConnection: FormConnection;
  /**
   * Unpublish many ManagedContentBanner documents
   * @deprecated Please use the new paginated many mutation (unpublishManyManagedContentBannersConnection)
   */
  unpublishManyManagedContentBanners: BatchPayload;
  /** Find many ManagedContentBanner documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyManagedContentBannersConnection: ManagedContentBannerConnection;
  /**
   * Unpublish many MigrationHistory documents
   * @deprecated Please use the new paginated many mutation (unpublishManyMigrationHistoriesConnection)
   */
  unpublishManyMigrationHistories: BatchPayload;
  /** Find many MigrationHistory documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyMigrationHistoriesConnection: MigrationHistoryConnection;
  /**
   * Unpublish many MigrationState documents
   * @deprecated Please use the new paginated many mutation (unpublishManyMigrationStatesConnection)
   */
  unpublishManyMigrationStates: BatchPayload;
  /** Find many MigrationState documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyMigrationStatesConnection: MigrationStateConnection;
  /**
   * Unpublish many Page documents
   * @deprecated Please use the new paginated many mutation (unpublishManyPagesConnection)
   */
  unpublishManyPages: BatchPayload;
  /** Find many Page documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyPagesConnection: PageConnection;
  /**
   * Unpublish many RichTextEditor documents
   * @deprecated Please use the new paginated many mutation (unpublishManyRichTextEditorsConnection)
   */
  unpublishManyRichTextEditors: BatchPayload;
  /** Find many RichTextEditor documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyRichTextEditorsConnection: RichTextEditorConnection;
  /**
   * Unpublish many Section documents
   * @deprecated Please use the new paginated many mutation (unpublishManySectionsConnection)
   */
  unpublishManySections: BatchPayload;
  /** Find many Section documents that match criteria in specified stage and unpublish from target stages */
  unpublishManySectionsConnection: SectionConnection;
  /**
   * Unpublish many SiteUsersConnection documents
   * @deprecated Please use the new paginated many mutation (unpublishManySiteUsersConnectionsConnection)
   */
  unpublishManySiteUsersConnections: BatchPayload;
  /** Find many SiteUsersConnection documents that match criteria in specified stage and unpublish from target stages */
  unpublishManySiteUsersConnectionsConnection: SiteUsersConnectionConnection;
  /**
   * Unpublish many Site documents
   * @deprecated Please use the new paginated many mutation (unpublishManySitesConnection)
   */
  unpublishManySites: BatchPayload;
  /** Find many Site documents that match criteria in specified stage and unpublish from target stages */
  unpublishManySitesConnection: SiteConnection;
  /**
   * Unpublish many Topic documents
   * @deprecated Please use the new paginated many mutation (unpublishManyTopicsConnection)
   */
  unpublishManyTopics: BatchPayload;
  /** Find many Topic documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyTopicsConnection: TopicConnection;
  /** Unpublish one migrationHistory from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishMigrationHistory?: Maybe<MigrationHistory>;
  /** Unpublish one migrationState from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishMigrationState?: Maybe<MigrationState>;
  /** Unpublish one page from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishPage?: Maybe<Page>;
  /** Unpublish one richTextEditor from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishRichTextEditor?: Maybe<RichTextEditor>;
  /** Unpublish one section from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishSection?: Maybe<Section>;
  /** Unpublish one site from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishSite?: Maybe<Site>;
  /** Unpublish one siteUsersConnection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishSiteUsersConnection?: Maybe<SiteUsersConnection>;
  /** Unpublish one topic from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishTopic?: Maybe<Topic>;
  /** Update one article */
  updateArticle?: Maybe<Article>;
  /** Update one asset */
  updateAsset?: Maybe<Asset>;
  /** Update one author */
  updateAuthor?: Maybe<Author>;
  /** Update one bannerItem */
  updateBannerItem?: Maybe<BannerItem>;
  /** Update one cBlock */
  updateCBlock?: Maybe<CBlock>;
  /** Update one cButtonLink */
  updateCButtonLink?: Maybe<CButtonLink>;
  /** Update one cImageLink */
  updateCImageLink?: Maybe<CImageLink>;
  /** Update one cTextLink */
  updateCTextLink?: Maybe<CTextLink>;
  /** Update one category */
  updateCategory?: Maybe<Category>;
  /** Update one dialogButton */
  updateDialogButton?: Maybe<DialogButton>;
  /** Update one documentItem */
  updateDocumentItem?: Maybe<DocumentItem>;
  /** Update one embedAccordionGroup */
  updateEmbedAccordionGroup?: Maybe<EmbedAccordionGroup>;
  /** Update one embedCustomContent */
  updateEmbedCustomContent?: Maybe<EmbedCustomContent>;
  /** Update one embedSingleLineText */
  updateEmbedSingleLineText?: Maybe<EmbedSingleLineText>;
  /** Update one externalUrl */
  updateExternalUrl?: Maybe<ExternalUrl>;
  /** Update one form */
  updateForm?: Maybe<Form>;
  /** Update one managedContentBanner */
  updateManagedContentBanner?: Maybe<ManagedContentBanner>;
  /**
   * Update many articles
   * @deprecated Please use the new paginated many mutation (updateManyArticlesConnection)
   */
  updateManyArticles: BatchPayload;
  /** Update many Article documents */
  updateManyArticlesConnection: ArticleConnection;
  /**
   * Update many assets
   * @deprecated Please use the new paginated many mutation (updateManyAssetsConnection)
   */
  updateManyAssets: BatchPayload;
  /** Update many Asset documents */
  updateManyAssetsConnection: AssetConnection;
  /**
   * Update many authors
   * @deprecated Please use the new paginated many mutation (updateManyAuthorsConnection)
   */
  updateManyAuthors: BatchPayload;
  /** Update many Author documents */
  updateManyAuthorsConnection: AuthorConnection;
  /**
   * Update many bannerItems
   * @deprecated Please use the new paginated many mutation (updateManyBannerItemsConnection)
   */
  updateManyBannerItems: BatchPayload;
  /** Update many BannerItem documents */
  updateManyBannerItemsConnection: BannerItemConnection;
  /**
   * Update many cBlocks
   * @deprecated Please use the new paginated many mutation (updateManyCBlocksConnection)
   */
  updateManyCBlocks: BatchPayload;
  /** Update many CBlock documents */
  updateManyCBlocksConnection: CBlockConnection;
  /**
   * Update many cButtonLinks
   * @deprecated Please use the new paginated many mutation (updateManyCButtonLinksConnection)
   */
  updateManyCButtonLinks: BatchPayload;
  /** Update many CButtonLink documents */
  updateManyCButtonLinksConnection: CButtonLinkConnection;
  /**
   * Update many cImageLinks
   * @deprecated Please use the new paginated many mutation (updateManyCImageLinksConnection)
   */
  updateManyCImageLinks: BatchPayload;
  /** Update many CImageLink documents */
  updateManyCImageLinksConnection: CImageLinkConnection;
  /**
   * Update many cTextLinks
   * @deprecated Please use the new paginated many mutation (updateManyCTextLinksConnection)
   */
  updateManyCTextLinks: BatchPayload;
  /** Update many CTextLink documents */
  updateManyCTextLinksConnection: CTextLinkConnection;
  /**
   * Update many categories
   * @deprecated Please use the new paginated many mutation (updateManyCategoriesConnection)
   */
  updateManyCategories: BatchPayload;
  /** Update many Category documents */
  updateManyCategoriesConnection: CategoryConnection;
  /**
   * Update many dialogButtons
   * @deprecated Please use the new paginated many mutation (updateManyDialogButtonsConnection)
   */
  updateManyDialogButtons: BatchPayload;
  /** Update many DialogButton documents */
  updateManyDialogButtonsConnection: DialogButtonConnection;
  /**
   * Update many documentItems
   * @deprecated Please use the new paginated many mutation (updateManyDocumentItemsConnection)
   */
  updateManyDocumentItems: BatchPayload;
  /** Update many DocumentItem documents */
  updateManyDocumentItemsConnection: DocumentItemConnection;
  /**
   * Update many embedAccordionGroups
   * @deprecated Please use the new paginated many mutation (updateManyEmbedAccordionGroupsConnection)
   */
  updateManyEmbedAccordionGroups: BatchPayload;
  /** Update many EmbedAccordionGroup documents */
  updateManyEmbedAccordionGroupsConnection: EmbedAccordionGroupConnection;
  /**
   * Update many embedCustomContents
   * @deprecated Please use the new paginated many mutation (updateManyEmbedCustomContentsConnection)
   */
  updateManyEmbedCustomContents: BatchPayload;
  /** Update many EmbedCustomContent documents */
  updateManyEmbedCustomContentsConnection: EmbedCustomContentConnection;
  /**
   * Update many embedSingleLineTexts
   * @deprecated Please use the new paginated many mutation (updateManyEmbedSingleLineTextsConnection)
   */
  updateManyEmbedSingleLineTexts: BatchPayload;
  /** Update many EmbedSingleLineText documents */
  updateManyEmbedSingleLineTextsConnection: EmbedSingleLineTextConnection;
  /**
   * Update many externalUrls
   * @deprecated Please use the new paginated many mutation (updateManyExternalUrlsConnection)
   */
  updateManyExternalUrls: BatchPayload;
  /** Update many ExternalUrl documents */
  updateManyExternalUrlsConnection: ExternalUrlConnection;
  /**
   * Update many forms
   * @deprecated Please use the new paginated many mutation (updateManyFormsConnection)
   */
  updateManyForms: BatchPayload;
  /** Update many Form documents */
  updateManyFormsConnection: FormConnection;
  /**
   * Update many managedContentBanners
   * @deprecated Please use the new paginated many mutation (updateManyManagedContentBannersConnection)
   */
  updateManyManagedContentBanners: BatchPayload;
  /** Update many ManagedContentBanner documents */
  updateManyManagedContentBannersConnection: ManagedContentBannerConnection;
  /**
   * Update many migrationHistories
   * @deprecated Please use the new paginated many mutation (updateManyMigrationHistoriesConnection)
   */
  updateManyMigrationHistories: BatchPayload;
  /** Update many MigrationHistory documents */
  updateManyMigrationHistoriesConnection: MigrationHistoryConnection;
  /**
   * Update many migrationStates
   * @deprecated Please use the new paginated many mutation (updateManyMigrationStatesConnection)
   */
  updateManyMigrationStates: BatchPayload;
  /** Update many MigrationState documents */
  updateManyMigrationStatesConnection: MigrationStateConnection;
  /**
   * Update many pages
   * @deprecated Please use the new paginated many mutation (updateManyPagesConnection)
   */
  updateManyPages: BatchPayload;
  /** Update many Page documents */
  updateManyPagesConnection: PageConnection;
  /**
   * Update many richTextEditors
   * @deprecated Please use the new paginated many mutation (updateManyRichTextEditorsConnection)
   */
  updateManyRichTextEditors: BatchPayload;
  /** Update many RichTextEditor documents */
  updateManyRichTextEditorsConnection: RichTextEditorConnection;
  /**
   * Update many sections
   * @deprecated Please use the new paginated many mutation (updateManySectionsConnection)
   */
  updateManySections: BatchPayload;
  /** Update many Section documents */
  updateManySectionsConnection: SectionConnection;
  /**
   * Update many siteUsersConnections
   * @deprecated Please use the new paginated many mutation (updateManySiteUsersConnectionsConnection)
   */
  updateManySiteUsersConnections: BatchPayload;
  /** Update many SiteUsersConnection documents */
  updateManySiteUsersConnectionsConnection: SiteUsersConnectionConnection;
  /**
   * Update many sites
   * @deprecated Please use the new paginated many mutation (updateManySitesConnection)
   */
  updateManySites: BatchPayload;
  /** Update many Site documents */
  updateManySitesConnection: SiteConnection;
  /**
   * Update many topics
   * @deprecated Please use the new paginated many mutation (updateManyTopicsConnection)
   */
  updateManyTopics: BatchPayload;
  /** Update many Topic documents */
  updateManyTopicsConnection: TopicConnection;
  /** Update one migrationHistory */
  updateMigrationHistory?: Maybe<MigrationHistory>;
  /** Update one migrationState */
  updateMigrationState?: Maybe<MigrationState>;
  /** Update one page */
  updatePage?: Maybe<Page>;
  /** Update one richTextEditor */
  updateRichTextEditor?: Maybe<RichTextEditor>;
  /** Update one scheduledRelease */
  updateScheduledRelease?: Maybe<ScheduledRelease>;
  /** Update one section */
  updateSection?: Maybe<Section>;
  /** Update one site */
  updateSite?: Maybe<Site>;
  /** Update one siteUsersConnection */
  updateSiteUsersConnection?: Maybe<SiteUsersConnection>;
  /** Update one topic */
  updateTopic?: Maybe<Topic>;
  /** Upsert one article */
  upsertArticle?: Maybe<Article>;
  /** Upsert one asset */
  upsertAsset?: Maybe<Asset>;
  /** Upsert one author */
  upsertAuthor?: Maybe<Author>;
  /** Upsert one bannerItem */
  upsertBannerItem?: Maybe<BannerItem>;
  /** Upsert one cBlock */
  upsertCBlock?: Maybe<CBlock>;
  /** Upsert one cButtonLink */
  upsertCButtonLink?: Maybe<CButtonLink>;
  /** Upsert one cImageLink */
  upsertCImageLink?: Maybe<CImageLink>;
  /** Upsert one cTextLink */
  upsertCTextLink?: Maybe<CTextLink>;
  /** Upsert one category */
  upsertCategory?: Maybe<Category>;
  /** Upsert one dialogButton */
  upsertDialogButton?: Maybe<DialogButton>;
  /** Upsert one documentItem */
  upsertDocumentItem?: Maybe<DocumentItem>;
  /** Upsert one embedAccordionGroup */
  upsertEmbedAccordionGroup?: Maybe<EmbedAccordionGroup>;
  /** Upsert one embedCustomContent */
  upsertEmbedCustomContent?: Maybe<EmbedCustomContent>;
  /** Upsert one embedSingleLineText */
  upsertEmbedSingleLineText?: Maybe<EmbedSingleLineText>;
  /** Upsert one externalUrl */
  upsertExternalUrl?: Maybe<ExternalUrl>;
  /** Upsert one form */
  upsertForm?: Maybe<Form>;
  /** Upsert one managedContentBanner */
  upsertManagedContentBanner?: Maybe<ManagedContentBanner>;
  /** Upsert one migrationHistory */
  upsertMigrationHistory?: Maybe<MigrationHistory>;
  /** Upsert one migrationState */
  upsertMigrationState?: Maybe<MigrationState>;
  /** Upsert one page */
  upsertPage?: Maybe<Page>;
  /** Upsert one richTextEditor */
  upsertRichTextEditor?: Maybe<RichTextEditor>;
  /** Upsert one section */
  upsertSection?: Maybe<Section>;
  /** Upsert one site */
  upsertSite?: Maybe<Site>;
  /** Upsert one siteUsersConnection */
  upsertSiteUsersConnection?: Maybe<SiteUsersConnection>;
  /** Upsert one topic */
  upsertTopic?: Maybe<Topic>;
};

export type MutationCreateArticleArgs = {
  data: ArticleCreateInput;
};

export type MutationCreateAssetArgs = {
  data: AssetCreateInput;
};

export type MutationCreateAuthorArgs = {
  data: AuthorCreateInput;
};

export type MutationCreateBannerItemArgs = {
  data: BannerItemCreateInput;
};

export type MutationCreateCBlockArgs = {
  data: CBlockCreateInput;
};

export type MutationCreateCButtonLinkArgs = {
  data: CButtonLinkCreateInput;
};

export type MutationCreateCImageLinkArgs = {
  data: CImageLinkCreateInput;
};

export type MutationCreateCTextLinkArgs = {
  data: CTextLinkCreateInput;
};

export type MutationCreateCategoryArgs = {
  data: CategoryCreateInput;
};

export type MutationCreateDialogButtonArgs = {
  data: DialogButtonCreateInput;
};

export type MutationCreateDocumentItemArgs = {
  data: DocumentItemCreateInput;
};

export type MutationCreateEmbedAccordionGroupArgs = {
  data: EmbedAccordionGroupCreateInput;
};

export type MutationCreateEmbedCustomContentArgs = {
  data: EmbedCustomContentCreateInput;
};

export type MutationCreateEmbedSingleLineTextArgs = {
  data: EmbedSingleLineTextCreateInput;
};

export type MutationCreateExternalUrlArgs = {
  data: ExternalUrlCreateInput;
};

export type MutationCreateFormArgs = {
  data: FormCreateInput;
};

export type MutationCreateManagedContentBannerArgs = {
  data: ManagedContentBannerCreateInput;
};

export type MutationCreateMigrationHistoryArgs = {
  data: MigrationHistoryCreateInput;
};

export type MutationCreateMigrationStateArgs = {
  data: MigrationStateCreateInput;
};

export type MutationCreatePageArgs = {
  data: PageCreateInput;
};

export type MutationCreateRichTextEditorArgs = {
  data: RichTextEditorCreateInput;
};

export type MutationCreateScheduledReleaseArgs = {
  data: ScheduledReleaseCreateInput;
};

export type MutationCreateSectionArgs = {
  data: SectionCreateInput;
};

export type MutationCreateSiteArgs = {
  data: SiteCreateInput;
};

export type MutationCreateSiteUsersConnectionArgs = {
  data: SiteUsersConnectionCreateInput;
};

export type MutationCreateTopicArgs = {
  data: TopicCreateInput;
};

export type MutationDeleteArticleArgs = {
  where: ArticleWhereUniqueInput;
};

export type MutationDeleteAssetArgs = {
  where: AssetWhereUniqueInput;
};

export type MutationDeleteAuthorArgs = {
  where: AuthorWhereUniqueInput;
};

export type MutationDeleteBannerItemArgs = {
  where: BannerItemWhereUniqueInput;
};

export type MutationDeleteCBlockArgs = {
  where: CBlockWhereUniqueInput;
};

export type MutationDeleteCButtonLinkArgs = {
  where: CButtonLinkWhereUniqueInput;
};

export type MutationDeleteCImageLinkArgs = {
  where: CImageLinkWhereUniqueInput;
};

export type MutationDeleteCTextLinkArgs = {
  where: CTextLinkWhereUniqueInput;
};

export type MutationDeleteCategoryArgs = {
  where: CategoryWhereUniqueInput;
};

export type MutationDeleteDialogButtonArgs = {
  where: DialogButtonWhereUniqueInput;
};

export type MutationDeleteDocumentItemArgs = {
  where: DocumentItemWhereUniqueInput;
};

export type MutationDeleteEmbedAccordionGroupArgs = {
  where: EmbedAccordionGroupWhereUniqueInput;
};

export type MutationDeleteEmbedCustomContentArgs = {
  where: EmbedCustomContentWhereUniqueInput;
};

export type MutationDeleteEmbedSingleLineTextArgs = {
  where: EmbedSingleLineTextWhereUniqueInput;
};

export type MutationDeleteExternalUrlArgs = {
  where: ExternalUrlWhereUniqueInput;
};

export type MutationDeleteFormArgs = {
  where: FormWhereUniqueInput;
};

export type MutationDeleteManagedContentBannerArgs = {
  where: ManagedContentBannerWhereUniqueInput;
};

export type MutationDeleteManyArticlesArgs = {
  where?: InputMaybe<ArticleManyWhereInput>;
};

export type MutationDeleteManyArticlesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ArticleManyWhereInput>;
};

export type MutationDeleteManyAssetsArgs = {
  where?: InputMaybe<AssetManyWhereInput>;
};

export type MutationDeleteManyAssetsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetManyWhereInput>;
};

export type MutationDeleteManyAuthorsArgs = {
  where?: InputMaybe<AuthorManyWhereInput>;
};

export type MutationDeleteManyAuthorsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AuthorManyWhereInput>;
};

export type MutationDeleteManyBannerItemsArgs = {
  where?: InputMaybe<BannerItemManyWhereInput>;
};

export type MutationDeleteManyBannerItemsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BannerItemManyWhereInput>;
};

export type MutationDeleteManyCBlocksArgs = {
  where?: InputMaybe<CBlockManyWhereInput>;
};

export type MutationDeleteManyCBlocksConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CBlockManyWhereInput>;
};

export type MutationDeleteManyCButtonLinksArgs = {
  where?: InputMaybe<CButtonLinkManyWhereInput>;
};

export type MutationDeleteManyCButtonLinksConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CButtonLinkManyWhereInput>;
};

export type MutationDeleteManyCImageLinksArgs = {
  where?: InputMaybe<CImageLinkManyWhereInput>;
};

export type MutationDeleteManyCImageLinksConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CImageLinkManyWhereInput>;
};

export type MutationDeleteManyCTextLinksArgs = {
  where?: InputMaybe<CTextLinkManyWhereInput>;
};

export type MutationDeleteManyCTextLinksConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CTextLinkManyWhereInput>;
};

export type MutationDeleteManyCategoriesArgs = {
  where?: InputMaybe<CategoryManyWhereInput>;
};

export type MutationDeleteManyCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CategoryManyWhereInput>;
};

export type MutationDeleteManyDialogButtonsArgs = {
  where?: InputMaybe<DialogButtonManyWhereInput>;
};

export type MutationDeleteManyDialogButtonsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DialogButtonManyWhereInput>;
};

export type MutationDeleteManyDocumentItemsArgs = {
  where?: InputMaybe<DocumentItemManyWhereInput>;
};

export type MutationDeleteManyDocumentItemsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DocumentItemManyWhereInput>;
};

export type MutationDeleteManyEmbedAccordionGroupsArgs = {
  where?: InputMaybe<EmbedAccordionGroupManyWhereInput>;
};

export type MutationDeleteManyEmbedAccordionGroupsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EmbedAccordionGroupManyWhereInput>;
};

export type MutationDeleteManyEmbedCustomContentsArgs = {
  where?: InputMaybe<EmbedCustomContentManyWhereInput>;
};

export type MutationDeleteManyEmbedCustomContentsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EmbedCustomContentManyWhereInput>;
};

export type MutationDeleteManyEmbedSingleLineTextsArgs = {
  where?: InputMaybe<EmbedSingleLineTextManyWhereInput>;
};

export type MutationDeleteManyEmbedSingleLineTextsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EmbedSingleLineTextManyWhereInput>;
};

export type MutationDeleteManyExternalUrlsArgs = {
  where?: InputMaybe<ExternalUrlManyWhereInput>;
};

export type MutationDeleteManyExternalUrlsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ExternalUrlManyWhereInput>;
};

export type MutationDeleteManyFormsArgs = {
  where?: InputMaybe<FormManyWhereInput>;
};

export type MutationDeleteManyFormsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FormManyWhereInput>;
};

export type MutationDeleteManyManagedContentBannersArgs = {
  where?: InputMaybe<ManagedContentBannerManyWhereInput>;
};

export type MutationDeleteManyManagedContentBannersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ManagedContentBannerManyWhereInput>;
};

export type MutationDeleteManyMigrationHistoriesArgs = {
  where?: InputMaybe<MigrationHistoryManyWhereInput>;
};

export type MutationDeleteManyMigrationHistoriesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MigrationHistoryManyWhereInput>;
};

export type MutationDeleteManyMigrationStatesArgs = {
  where?: InputMaybe<MigrationStateManyWhereInput>;
};

export type MutationDeleteManyMigrationStatesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MigrationStateManyWhereInput>;
};

export type MutationDeleteManyPagesArgs = {
  where?: InputMaybe<PageManyWhereInput>;
};

export type MutationDeleteManyPagesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PageManyWhereInput>;
};

export type MutationDeleteManyRichTextEditorsArgs = {
  where?: InputMaybe<RichTextEditorManyWhereInput>;
};

export type MutationDeleteManyRichTextEditorsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RichTextEditorManyWhereInput>;
};

export type MutationDeleteManySectionsArgs = {
  where?: InputMaybe<SectionManyWhereInput>;
};

export type MutationDeleteManySectionsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SectionManyWhereInput>;
};

export type MutationDeleteManySiteUsersConnectionsArgs = {
  where?: InputMaybe<SiteUsersConnectionManyWhereInput>;
};

export type MutationDeleteManySiteUsersConnectionsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SiteUsersConnectionManyWhereInput>;
};

export type MutationDeleteManySitesArgs = {
  where?: InputMaybe<SiteManyWhereInput>;
};

export type MutationDeleteManySitesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SiteManyWhereInput>;
};

export type MutationDeleteManyTopicsArgs = {
  where?: InputMaybe<TopicManyWhereInput>;
};

export type MutationDeleteManyTopicsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TopicManyWhereInput>;
};

export type MutationDeleteMigrationHistoryArgs = {
  where: MigrationHistoryWhereUniqueInput;
};

export type MutationDeleteMigrationStateArgs = {
  where: MigrationStateWhereUniqueInput;
};

export type MutationDeletePageArgs = {
  where: PageWhereUniqueInput;
};

export type MutationDeleteRichTextEditorArgs = {
  where: RichTextEditorWhereUniqueInput;
};

export type MutationDeleteScheduledOperationArgs = {
  where: ScheduledOperationWhereUniqueInput;
};

export type MutationDeleteScheduledReleaseArgs = {
  where: ScheduledReleaseWhereUniqueInput;
};

export type MutationDeleteSectionArgs = {
  where: SectionWhereUniqueInput;
};

export type MutationDeleteSiteArgs = {
  where: SiteWhereUniqueInput;
};

export type MutationDeleteSiteUsersConnectionArgs = {
  where: SiteUsersConnectionWhereUniqueInput;
};

export type MutationDeleteTopicArgs = {
  where: TopicWhereUniqueInput;
};

export type MutationPublishArticleArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: ArticleWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishAssetArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: AssetWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishAuthorArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: AuthorWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishBannerItemArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: BannerItemWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishCBlockArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: CBlockWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishCButtonLinkArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: CButtonLinkWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishCImageLinkArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: CImageLinkWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishCTextLinkArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: CTextLinkWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishCategoryArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: CategoryWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishDialogButtonArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: DialogButtonWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishDocumentItemArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: DocumentItemWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishEmbedAccordionGroupArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: EmbedAccordionGroupWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishEmbedCustomContentArgs = {
  to?: Array<Stage>;
  where: EmbedCustomContentWhereUniqueInput;
};

export type MutationPublishEmbedSingleLineTextArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: EmbedSingleLineTextWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishExternalUrlArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: ExternalUrlWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishFormArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: FormWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManagedContentBannerArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: ManagedContentBannerWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyArticlesArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<ArticleManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyArticlesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<ArticleManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyAssetsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<AssetManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyAssetsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<AssetManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyAuthorsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<AuthorManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyAuthorsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<AuthorManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyBannerItemsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<BannerItemManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyBannerItemsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<BannerItemManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyCBlocksArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<CBlockManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyCBlocksConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<CBlockManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyCButtonLinksArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<CButtonLinkManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyCButtonLinksConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<CButtonLinkManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyCImageLinksArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<CImageLinkManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyCImageLinksConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<CImageLinkManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyCTextLinksArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<CTextLinkManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyCTextLinksConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<CTextLinkManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyCategoriesArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<CategoryManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<CategoryManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyDialogButtonsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<DialogButtonManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyDialogButtonsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<DialogButtonManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyDocumentItemsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<DocumentItemManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyDocumentItemsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<DocumentItemManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyEmbedAccordionGroupsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<EmbedAccordionGroupManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyEmbedAccordionGroupsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<EmbedAccordionGroupManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyEmbedCustomContentsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<EmbedCustomContentManyWhereInput>;
};

export type MutationPublishManyEmbedCustomContentsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<EmbedCustomContentManyWhereInput>;
};

export type MutationPublishManyEmbedSingleLineTextsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<EmbedSingleLineTextManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyEmbedSingleLineTextsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<EmbedSingleLineTextManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyExternalUrlsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<ExternalUrlManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyExternalUrlsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<ExternalUrlManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyFormsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<FormManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyFormsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<FormManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyManagedContentBannersArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<ManagedContentBannerManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyManagedContentBannersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<ManagedContentBannerManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyMigrationHistoriesArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<MigrationHistoryManyWhereInput>;
};

export type MutationPublishManyMigrationHistoriesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<MigrationHistoryManyWhereInput>;
};

export type MutationPublishManyMigrationStatesArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<MigrationStateManyWhereInput>;
};

export type MutationPublishManyMigrationStatesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<MigrationStateManyWhereInput>;
};

export type MutationPublishManyPagesArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<PageManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyPagesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<PageManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyRichTextEditorsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<RichTextEditorManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyRichTextEditorsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<RichTextEditorManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManySectionsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<SectionManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManySectionsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<SectionManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManySiteUsersConnectionsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<SiteUsersConnectionManyWhereInput>;
};

export type MutationPublishManySiteUsersConnectionsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<SiteUsersConnectionManyWhereInput>;
};

export type MutationPublishManySitesArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<SiteManyWhereInput>;
};

export type MutationPublishManySitesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<SiteManyWhereInput>;
};

export type MutationPublishManyTopicsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where?: InputMaybe<TopicManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishManyTopicsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  skip?: InputMaybe<Scalars['Int']>;
  to?: Array<Stage>;
  where?: InputMaybe<TopicManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishMigrationHistoryArgs = {
  to?: Array<Stage>;
  where: MigrationHistoryWhereUniqueInput;
};

export type MutationPublishMigrationStateArgs = {
  to?: Array<Stage>;
  where: MigrationStateWhereUniqueInput;
};

export type MutationPublishPageArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: PageWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishRichTextEditorArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: RichTextEditorWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishSectionArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: SectionWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationPublishSiteArgs = {
  to?: Array<Stage>;
  where: SiteWhereUniqueInput;
};

export type MutationPublishSiteUsersConnectionArgs = {
  to?: Array<Stage>;
  where: SiteUsersConnectionWhereUniqueInput;
};

export type MutationPublishTopicArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  to?: Array<Stage>;
  where: TopicWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchedulePublishArticleArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: ArticleWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchedulePublishAssetArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: AssetWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchedulePublishAuthorArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: AuthorWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchedulePublishBannerItemArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: BannerItemWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchedulePublishCBlockArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: CBlockWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchedulePublishCButtonLinkArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: CButtonLinkWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchedulePublishCImageLinkArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: CImageLinkWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchedulePublishCTextLinkArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: CTextLinkWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchedulePublishCategoryArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: CategoryWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchedulePublishDialogButtonArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: DialogButtonWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchedulePublishDocumentItemArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: DocumentItemWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchedulePublishEmbedAccordionGroupArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: EmbedAccordionGroupWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchedulePublishEmbedCustomContentArgs = {
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: EmbedCustomContentWhereUniqueInput;
};

export type MutationSchedulePublishEmbedSingleLineTextArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: EmbedSingleLineTextWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchedulePublishExternalUrlArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: ExternalUrlWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchedulePublishFormArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: FormWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchedulePublishManagedContentBannerArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: ManagedContentBannerWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchedulePublishMigrationHistoryArgs = {
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: MigrationHistoryWhereUniqueInput;
};

export type MutationSchedulePublishMigrationStateArgs = {
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: MigrationStateWhereUniqueInput;
};

export type MutationSchedulePublishPageArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: PageWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchedulePublishRichTextEditorArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: RichTextEditorWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchedulePublishSectionArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: SectionWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationSchedulePublishSiteArgs = {
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: SiteWhereUniqueInput;
};

export type MutationSchedulePublishSiteUsersConnectionArgs = {
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: SiteUsersConnectionWhereUniqueInput;
};

export type MutationSchedulePublishTopicArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  to?: Array<Stage>;
  where: TopicWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars['Boolean']>;
};

export type MutationScheduleUnpublishArticleArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: ArticleWhereUniqueInput;
};

export type MutationScheduleUnpublishAssetArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: AssetWhereUniqueInput;
};

export type MutationScheduleUnpublishAuthorArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: AuthorWhereUniqueInput;
};

export type MutationScheduleUnpublishBannerItemArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: BannerItemWhereUniqueInput;
};

export type MutationScheduleUnpublishCBlockArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: CBlockWhereUniqueInput;
};

export type MutationScheduleUnpublishCButtonLinkArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: CButtonLinkWhereUniqueInput;
};

export type MutationScheduleUnpublishCImageLinkArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: CImageLinkWhereUniqueInput;
};

export type MutationScheduleUnpublishCTextLinkArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: CTextLinkWhereUniqueInput;
};

export type MutationScheduleUnpublishCategoryArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: CategoryWhereUniqueInput;
};

export type MutationScheduleUnpublishDialogButtonArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: DialogButtonWhereUniqueInput;
};

export type MutationScheduleUnpublishDocumentItemArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: DocumentItemWhereUniqueInput;
};

export type MutationScheduleUnpublishEmbedAccordionGroupArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: EmbedAccordionGroupWhereUniqueInput;
};

export type MutationScheduleUnpublishEmbedCustomContentArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  where: EmbedCustomContentWhereUniqueInput;
};

export type MutationScheduleUnpublishEmbedSingleLineTextArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: EmbedSingleLineTextWhereUniqueInput;
};

export type MutationScheduleUnpublishExternalUrlArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: ExternalUrlWhereUniqueInput;
};

export type MutationScheduleUnpublishFormArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: FormWhereUniqueInput;
};

export type MutationScheduleUnpublishManagedContentBannerArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: ManagedContentBannerWhereUniqueInput;
};

export type MutationScheduleUnpublishMigrationHistoryArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  where: MigrationHistoryWhereUniqueInput;
};

export type MutationScheduleUnpublishMigrationStateArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  where: MigrationStateWhereUniqueInput;
};

export type MutationScheduleUnpublishPageArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: PageWhereUniqueInput;
};

export type MutationScheduleUnpublishRichTextEditorArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: RichTextEditorWhereUniqueInput;
};

export type MutationScheduleUnpublishSectionArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: SectionWhereUniqueInput;
};

export type MutationScheduleUnpublishSiteArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  where: SiteWhereUniqueInput;
};

export type MutationScheduleUnpublishSiteUsersConnectionArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  where: SiteUsersConnectionWhereUniqueInput;
};

export type MutationScheduleUnpublishTopicArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  releaseId?: InputMaybe<Scalars['String']>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: TopicWhereUniqueInput;
};

export type MutationUnpublishArticleArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: ArticleWhereUniqueInput;
};

export type MutationUnpublishAssetArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: AssetWhereUniqueInput;
};

export type MutationUnpublishAuthorArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: AuthorWhereUniqueInput;
};

export type MutationUnpublishBannerItemArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: BannerItemWhereUniqueInput;
};

export type MutationUnpublishCBlockArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: CBlockWhereUniqueInput;
};

export type MutationUnpublishCButtonLinkArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: CButtonLinkWhereUniqueInput;
};

export type MutationUnpublishCImageLinkArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: CImageLinkWhereUniqueInput;
};

export type MutationUnpublishCTextLinkArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: CTextLinkWhereUniqueInput;
};

export type MutationUnpublishCategoryArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: CategoryWhereUniqueInput;
};

export type MutationUnpublishDialogButtonArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: DialogButtonWhereUniqueInput;
};

export type MutationUnpublishDocumentItemArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: DocumentItemWhereUniqueInput;
};

export type MutationUnpublishEmbedAccordionGroupArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: EmbedAccordionGroupWhereUniqueInput;
};

export type MutationUnpublishEmbedCustomContentArgs = {
  from?: Array<Stage>;
  where: EmbedCustomContentWhereUniqueInput;
};

export type MutationUnpublishEmbedSingleLineTextArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: EmbedSingleLineTextWhereUniqueInput;
};

export type MutationUnpublishExternalUrlArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: ExternalUrlWhereUniqueInput;
};

export type MutationUnpublishFormArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: FormWhereUniqueInput;
};

export type MutationUnpublishManagedContentBannerArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: ManagedContentBannerWhereUniqueInput;
};

export type MutationUnpublishManyArticlesArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<ArticleManyWhereInput>;
};

export type MutationUnpublishManyArticlesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<ArticleManyWhereInput>;
};

export type MutationUnpublishManyAssetsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<AssetManyWhereInput>;
};

export type MutationUnpublishManyAssetsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<AssetManyWhereInput>;
};

export type MutationUnpublishManyAuthorsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<AuthorManyWhereInput>;
};

export type MutationUnpublishManyAuthorsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<AuthorManyWhereInput>;
};

export type MutationUnpublishManyBannerItemsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<BannerItemManyWhereInput>;
};

export type MutationUnpublishManyBannerItemsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<BannerItemManyWhereInput>;
};

export type MutationUnpublishManyCBlocksArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<CBlockManyWhereInput>;
};

export type MutationUnpublishManyCBlocksConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<CBlockManyWhereInput>;
};

export type MutationUnpublishManyCButtonLinksArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<CButtonLinkManyWhereInput>;
};

export type MutationUnpublishManyCButtonLinksConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<CButtonLinkManyWhereInput>;
};

export type MutationUnpublishManyCImageLinksArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<CImageLinkManyWhereInput>;
};

export type MutationUnpublishManyCImageLinksConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<CImageLinkManyWhereInput>;
};

export type MutationUnpublishManyCTextLinksArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<CTextLinkManyWhereInput>;
};

export type MutationUnpublishManyCTextLinksConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<CTextLinkManyWhereInput>;
};

export type MutationUnpublishManyCategoriesArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<CategoryManyWhereInput>;
};

export type MutationUnpublishManyCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<CategoryManyWhereInput>;
};

export type MutationUnpublishManyDialogButtonsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<DialogButtonManyWhereInput>;
};

export type MutationUnpublishManyDialogButtonsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<DialogButtonManyWhereInput>;
};

export type MutationUnpublishManyDocumentItemsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<DocumentItemManyWhereInput>;
};

export type MutationUnpublishManyDocumentItemsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<DocumentItemManyWhereInput>;
};

export type MutationUnpublishManyEmbedAccordionGroupsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<EmbedAccordionGroupManyWhereInput>;
};

export type MutationUnpublishManyEmbedAccordionGroupsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<EmbedAccordionGroupManyWhereInput>;
};

export type MutationUnpublishManyEmbedCustomContentsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<EmbedCustomContentManyWhereInput>;
};

export type MutationUnpublishManyEmbedCustomContentsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<EmbedCustomContentManyWhereInput>;
};

export type MutationUnpublishManyEmbedSingleLineTextsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<EmbedSingleLineTextManyWhereInput>;
};

export type MutationUnpublishManyEmbedSingleLineTextsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<EmbedSingleLineTextManyWhereInput>;
};

export type MutationUnpublishManyExternalUrlsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<ExternalUrlManyWhereInput>;
};

export type MutationUnpublishManyExternalUrlsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<ExternalUrlManyWhereInput>;
};

export type MutationUnpublishManyFormsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<FormManyWhereInput>;
};

export type MutationUnpublishManyFormsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<FormManyWhereInput>;
};

export type MutationUnpublishManyManagedContentBannersArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<ManagedContentBannerManyWhereInput>;
};

export type MutationUnpublishManyManagedContentBannersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<ManagedContentBannerManyWhereInput>;
};

export type MutationUnpublishManyMigrationHistoriesArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<MigrationHistoryManyWhereInput>;
};

export type MutationUnpublishManyMigrationHistoriesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<MigrationHistoryManyWhereInput>;
};

export type MutationUnpublishManyMigrationStatesArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<MigrationStateManyWhereInput>;
};

export type MutationUnpublishManyMigrationStatesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<MigrationStateManyWhereInput>;
};

export type MutationUnpublishManyPagesArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<PageManyWhereInput>;
};

export type MutationUnpublishManyPagesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<PageManyWhereInput>;
};

export type MutationUnpublishManyRichTextEditorsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<RichTextEditorManyWhereInput>;
};

export type MutationUnpublishManyRichTextEditorsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<RichTextEditorManyWhereInput>;
};

export type MutationUnpublishManySectionsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<SectionManyWhereInput>;
};

export type MutationUnpublishManySectionsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<SectionManyWhereInput>;
};

export type MutationUnpublishManySiteUsersConnectionsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<SiteUsersConnectionManyWhereInput>;
};

export type MutationUnpublishManySiteUsersConnectionsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<SiteUsersConnectionManyWhereInput>;
};

export type MutationUnpublishManySitesArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<SiteManyWhereInput>;
};

export type MutationUnpublishManySitesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<SiteManyWhereInput>;
};

export type MutationUnpublishManyTopicsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<TopicManyWhereInput>;
};

export type MutationUnpublishManyTopicsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  first?: InputMaybe<Scalars['Int']>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where?: InputMaybe<TopicManyWhereInput>;
};

export type MutationUnpublishMigrationHistoryArgs = {
  from?: Array<Stage>;
  where: MigrationHistoryWhereUniqueInput;
};

export type MutationUnpublishMigrationStateArgs = {
  from?: Array<Stage>;
  where: MigrationStateWhereUniqueInput;
};

export type MutationUnpublishPageArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: PageWhereUniqueInput;
};

export type MutationUnpublishRichTextEditorArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: RichTextEditorWhereUniqueInput;
};

export type MutationUnpublishSectionArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: SectionWhereUniqueInput;
};

export type MutationUnpublishSiteArgs = {
  from?: Array<Stage>;
  where: SiteWhereUniqueInput;
};

export type MutationUnpublishSiteUsersConnectionArgs = {
  from?: Array<Stage>;
  where: SiteUsersConnectionWhereUniqueInput;
};

export type MutationUnpublishTopicArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars['Boolean']>;
  where: TopicWhereUniqueInput;
};

export type MutationUpdateArticleArgs = {
  data: ArticleUpdateInput;
  where: ArticleWhereUniqueInput;
};

export type MutationUpdateAssetArgs = {
  data: AssetUpdateInput;
  where: AssetWhereUniqueInput;
};

export type MutationUpdateAuthorArgs = {
  data: AuthorUpdateInput;
  where: AuthorWhereUniqueInput;
};

export type MutationUpdateBannerItemArgs = {
  data: BannerItemUpdateInput;
  where: BannerItemWhereUniqueInput;
};

export type MutationUpdateCBlockArgs = {
  data: CBlockUpdateInput;
  where: CBlockWhereUniqueInput;
};

export type MutationUpdateCButtonLinkArgs = {
  data: CButtonLinkUpdateInput;
  where: CButtonLinkWhereUniqueInput;
};

export type MutationUpdateCImageLinkArgs = {
  data: CImageLinkUpdateInput;
  where: CImageLinkWhereUniqueInput;
};

export type MutationUpdateCTextLinkArgs = {
  data: CTextLinkUpdateInput;
  where: CTextLinkWhereUniqueInput;
};

export type MutationUpdateCategoryArgs = {
  data: CategoryUpdateInput;
  where: CategoryWhereUniqueInput;
};

export type MutationUpdateDialogButtonArgs = {
  data: DialogButtonUpdateInput;
  where: DialogButtonWhereUniqueInput;
};

export type MutationUpdateDocumentItemArgs = {
  data: DocumentItemUpdateInput;
  where: DocumentItemWhereUniqueInput;
};

export type MutationUpdateEmbedAccordionGroupArgs = {
  data: EmbedAccordionGroupUpdateInput;
  where: EmbedAccordionGroupWhereUniqueInput;
};

export type MutationUpdateEmbedCustomContentArgs = {
  data: EmbedCustomContentUpdateInput;
  where: EmbedCustomContentWhereUniqueInput;
};

export type MutationUpdateEmbedSingleLineTextArgs = {
  data: EmbedSingleLineTextUpdateInput;
  where: EmbedSingleLineTextWhereUniqueInput;
};

export type MutationUpdateExternalUrlArgs = {
  data: ExternalUrlUpdateInput;
  where: ExternalUrlWhereUniqueInput;
};

export type MutationUpdateFormArgs = {
  data: FormUpdateInput;
  where: FormWhereUniqueInput;
};

export type MutationUpdateManagedContentBannerArgs = {
  data: ManagedContentBannerUpdateInput;
  where: ManagedContentBannerWhereUniqueInput;
};

export type MutationUpdateManyArticlesArgs = {
  data: ArticleUpdateManyInput;
  where?: InputMaybe<ArticleManyWhereInput>;
};

export type MutationUpdateManyArticlesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: ArticleUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ArticleManyWhereInput>;
};

export type MutationUpdateManyAssetsArgs = {
  data: AssetUpdateManyInput;
  where?: InputMaybe<AssetManyWhereInput>;
};

export type MutationUpdateManyAssetsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: AssetUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetManyWhereInput>;
};

export type MutationUpdateManyAuthorsArgs = {
  data: AuthorUpdateManyInput;
  where?: InputMaybe<AuthorManyWhereInput>;
};

export type MutationUpdateManyAuthorsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: AuthorUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AuthorManyWhereInput>;
};

export type MutationUpdateManyBannerItemsArgs = {
  data: BannerItemUpdateManyInput;
  where?: InputMaybe<BannerItemManyWhereInput>;
};

export type MutationUpdateManyBannerItemsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: BannerItemUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BannerItemManyWhereInput>;
};

export type MutationUpdateManyCBlocksArgs = {
  data: CBlockUpdateManyInput;
  where?: InputMaybe<CBlockManyWhereInput>;
};

export type MutationUpdateManyCBlocksConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: CBlockUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CBlockManyWhereInput>;
};

export type MutationUpdateManyCButtonLinksArgs = {
  data: CButtonLinkUpdateManyInput;
  where?: InputMaybe<CButtonLinkManyWhereInput>;
};

export type MutationUpdateManyCButtonLinksConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: CButtonLinkUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CButtonLinkManyWhereInput>;
};

export type MutationUpdateManyCImageLinksArgs = {
  data: CImageLinkUpdateManyInput;
  where?: InputMaybe<CImageLinkManyWhereInput>;
};

export type MutationUpdateManyCImageLinksConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: CImageLinkUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CImageLinkManyWhereInput>;
};

export type MutationUpdateManyCTextLinksArgs = {
  data: CTextLinkUpdateManyInput;
  where?: InputMaybe<CTextLinkManyWhereInput>;
};

export type MutationUpdateManyCTextLinksConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: CTextLinkUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CTextLinkManyWhereInput>;
};

export type MutationUpdateManyCategoriesArgs = {
  data: CategoryUpdateManyInput;
  where?: InputMaybe<CategoryManyWhereInput>;
};

export type MutationUpdateManyCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: CategoryUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CategoryManyWhereInput>;
};

export type MutationUpdateManyDialogButtonsArgs = {
  data: DialogButtonUpdateManyInput;
  where?: InputMaybe<DialogButtonManyWhereInput>;
};

export type MutationUpdateManyDialogButtonsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: DialogButtonUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DialogButtonManyWhereInput>;
};

export type MutationUpdateManyDocumentItemsArgs = {
  data: DocumentItemUpdateManyInput;
  where?: InputMaybe<DocumentItemManyWhereInput>;
};

export type MutationUpdateManyDocumentItemsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: DocumentItemUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DocumentItemManyWhereInput>;
};

export type MutationUpdateManyEmbedAccordionGroupsArgs = {
  data: EmbedAccordionGroupUpdateManyInput;
  where?: InputMaybe<EmbedAccordionGroupManyWhereInput>;
};

export type MutationUpdateManyEmbedAccordionGroupsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: EmbedAccordionGroupUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EmbedAccordionGroupManyWhereInput>;
};

export type MutationUpdateManyEmbedCustomContentsArgs = {
  data: EmbedCustomContentUpdateManyInput;
  where?: InputMaybe<EmbedCustomContentManyWhereInput>;
};

export type MutationUpdateManyEmbedCustomContentsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: EmbedCustomContentUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EmbedCustomContentManyWhereInput>;
};

export type MutationUpdateManyEmbedSingleLineTextsArgs = {
  data: EmbedSingleLineTextUpdateManyInput;
  where?: InputMaybe<EmbedSingleLineTextManyWhereInput>;
};

export type MutationUpdateManyEmbedSingleLineTextsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: EmbedSingleLineTextUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EmbedSingleLineTextManyWhereInput>;
};

export type MutationUpdateManyExternalUrlsArgs = {
  data: ExternalUrlUpdateManyInput;
  where?: InputMaybe<ExternalUrlManyWhereInput>;
};

export type MutationUpdateManyExternalUrlsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: ExternalUrlUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ExternalUrlManyWhereInput>;
};

export type MutationUpdateManyFormsArgs = {
  data: FormUpdateManyInput;
  where?: InputMaybe<FormManyWhereInput>;
};

export type MutationUpdateManyFormsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: FormUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FormManyWhereInput>;
};

export type MutationUpdateManyManagedContentBannersArgs = {
  data: ManagedContentBannerUpdateManyInput;
  where?: InputMaybe<ManagedContentBannerManyWhereInput>;
};

export type MutationUpdateManyManagedContentBannersConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: ManagedContentBannerUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ManagedContentBannerManyWhereInput>;
};

export type MutationUpdateManyMigrationHistoriesArgs = {
  data: MigrationHistoryUpdateManyInput;
  where?: InputMaybe<MigrationHistoryManyWhereInput>;
};

export type MutationUpdateManyMigrationHistoriesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: MigrationHistoryUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MigrationHistoryManyWhereInput>;
};

export type MutationUpdateManyMigrationStatesArgs = {
  data: MigrationStateUpdateManyInput;
  where?: InputMaybe<MigrationStateManyWhereInput>;
};

export type MutationUpdateManyMigrationStatesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: MigrationStateUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MigrationStateManyWhereInput>;
};

export type MutationUpdateManyPagesArgs = {
  data: PageUpdateManyInput;
  where?: InputMaybe<PageManyWhereInput>;
};

export type MutationUpdateManyPagesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: PageUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PageManyWhereInput>;
};

export type MutationUpdateManyRichTextEditorsArgs = {
  data: RichTextEditorUpdateManyInput;
  where?: InputMaybe<RichTextEditorManyWhereInput>;
};

export type MutationUpdateManyRichTextEditorsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: RichTextEditorUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RichTextEditorManyWhereInput>;
};

export type MutationUpdateManySectionsArgs = {
  data: SectionUpdateManyInput;
  where?: InputMaybe<SectionManyWhereInput>;
};

export type MutationUpdateManySectionsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: SectionUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SectionManyWhereInput>;
};

export type MutationUpdateManySiteUsersConnectionsArgs = {
  data: SiteUsersConnectionUpdateManyInput;
  where?: InputMaybe<SiteUsersConnectionManyWhereInput>;
};

export type MutationUpdateManySiteUsersConnectionsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: SiteUsersConnectionUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SiteUsersConnectionManyWhereInput>;
};

export type MutationUpdateManySitesArgs = {
  data: SiteUpdateManyInput;
  where?: InputMaybe<SiteManyWhereInput>;
};

export type MutationUpdateManySitesConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: SiteUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SiteManyWhereInput>;
};

export type MutationUpdateManyTopicsArgs = {
  data: TopicUpdateManyInput;
  where?: InputMaybe<TopicManyWhereInput>;
};

export type MutationUpdateManyTopicsConnectionArgs = {
  after?: InputMaybe<Scalars['ID']>;
  before?: InputMaybe<Scalars['ID']>;
  data: TopicUpdateManyInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TopicManyWhereInput>;
};

export type MutationUpdateMigrationHistoryArgs = {
  data: MigrationHistoryUpdateInput;
  where: MigrationHistoryWhereUniqueInput;
};

export type MutationUpdateMigrationStateArgs = {
  data: MigrationStateUpdateInput;
  where: MigrationStateWhereUniqueInput;
};

export type MutationUpdatePageArgs = {
  data: PageUpdateInput;
  where: PageWhereUniqueInput;
};

export type MutationUpdateRichTextEditorArgs = {
  data: RichTextEditorUpdateInput;
  where: RichTextEditorWhereUniqueInput;
};

export type MutationUpdateScheduledReleaseArgs = {
  data: ScheduledReleaseUpdateInput;
  where: ScheduledReleaseWhereUniqueInput;
};

export type MutationUpdateSectionArgs = {
  data: SectionUpdateInput;
  where: SectionWhereUniqueInput;
};

export type MutationUpdateSiteArgs = {
  data: SiteUpdateInput;
  where: SiteWhereUniqueInput;
};

export type MutationUpdateSiteUsersConnectionArgs = {
  data: SiteUsersConnectionUpdateInput;
  where: SiteUsersConnectionWhereUniqueInput;
};

export type MutationUpdateTopicArgs = {
  data: TopicUpdateInput;
  where: TopicWhereUniqueInput;
};

export type MutationUpsertArticleArgs = {
  upsert: ArticleUpsertInput;
  where: ArticleWhereUniqueInput;
};

export type MutationUpsertAssetArgs = {
  upsert: AssetUpsertInput;
  where: AssetWhereUniqueInput;
};

export type MutationUpsertAuthorArgs = {
  upsert: AuthorUpsertInput;
  where: AuthorWhereUniqueInput;
};

export type MutationUpsertBannerItemArgs = {
  upsert: BannerItemUpsertInput;
  where: BannerItemWhereUniqueInput;
};

export type MutationUpsertCBlockArgs = {
  upsert: CBlockUpsertInput;
  where: CBlockWhereUniqueInput;
};

export type MutationUpsertCButtonLinkArgs = {
  upsert: CButtonLinkUpsertInput;
  where: CButtonLinkWhereUniqueInput;
};

export type MutationUpsertCImageLinkArgs = {
  upsert: CImageLinkUpsertInput;
  where: CImageLinkWhereUniqueInput;
};

export type MutationUpsertCTextLinkArgs = {
  upsert: CTextLinkUpsertInput;
  where: CTextLinkWhereUniqueInput;
};

export type MutationUpsertCategoryArgs = {
  upsert: CategoryUpsertInput;
  where: CategoryWhereUniqueInput;
};

export type MutationUpsertDialogButtonArgs = {
  upsert: DialogButtonUpsertInput;
  where: DialogButtonWhereUniqueInput;
};

export type MutationUpsertDocumentItemArgs = {
  upsert: DocumentItemUpsertInput;
  where: DocumentItemWhereUniqueInput;
};

export type MutationUpsertEmbedAccordionGroupArgs = {
  upsert: EmbedAccordionGroupUpsertInput;
  where: EmbedAccordionGroupWhereUniqueInput;
};

export type MutationUpsertEmbedCustomContentArgs = {
  upsert: EmbedCustomContentUpsertInput;
  where: EmbedCustomContentWhereUniqueInput;
};

export type MutationUpsertEmbedSingleLineTextArgs = {
  upsert: EmbedSingleLineTextUpsertInput;
  where: EmbedSingleLineTextWhereUniqueInput;
};

export type MutationUpsertExternalUrlArgs = {
  upsert: ExternalUrlUpsertInput;
  where: ExternalUrlWhereUniqueInput;
};

export type MutationUpsertFormArgs = {
  upsert: FormUpsertInput;
  where: FormWhereUniqueInput;
};

export type MutationUpsertManagedContentBannerArgs = {
  upsert: ManagedContentBannerUpsertInput;
  where: ManagedContentBannerWhereUniqueInput;
};

export type MutationUpsertMigrationHistoryArgs = {
  upsert: MigrationHistoryUpsertInput;
  where: MigrationHistoryWhereUniqueInput;
};

export type MutationUpsertMigrationStateArgs = {
  upsert: MigrationStateUpsertInput;
  where: MigrationStateWhereUniqueInput;
};

export type MutationUpsertPageArgs = {
  upsert: PageUpsertInput;
  where: PageWhereUniqueInput;
};

export type MutationUpsertRichTextEditorArgs = {
  upsert: RichTextEditorUpsertInput;
  where: RichTextEditorWhereUniqueInput;
};

export type MutationUpsertSectionArgs = {
  upsert: SectionUpsertInput;
  where: SectionWhereUniqueInput;
};

export type MutationUpsertSiteArgs = {
  upsert: SiteUpsertInput;
  where: SiteWhereUniqueInput;
};

export type MutationUpsertSiteUsersConnectionArgs = {
  upsert: SiteUsersConnectionUpsertInput;
  where: SiteUsersConnectionWhereUniqueInput;
};

export type MutationUpsertTopicArgs = {
  upsert: TopicUpsertInput;
  where: TopicWhereUniqueInput;
};

/** An object with an ID */
export type Node = {
  /** The id of the object. */
  id: Scalars['ID'];
  /** The Stage of an object */
  stage: Stage;
};

/** A number component with numerical text and unit text display with dynamic size */
export type NumericalBlock = Entity & {
  __typename?: 'NumericalBlock';
  /** Animation speed modifier for the number. *Default to normal */
  animationSpeed?: Maybe<AnimationSpeed>;
  fontWeight?: Maybe<TextWeight>;
  /** Enable or disable the number animation. *Enabled by default */
  hasAnimation?: Maybe<Scalars['Boolean']>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<NumericalBlock>;
  number?: Maybe<Scalars['String']>;
  numberTone?: Maybe<CTextTone>;
  /** When this is enabled, the unit size will have same size as the number. *Default to false */
  sameFontSize?: Maybe<Scalars['Boolean']>;
  /** System stage field */
  stage: Stage;
  /** the unit size will be 1/3 of the display Size, default to standard '3rem' */
  textSize?: Maybe<CustomFontSize>;
  /** Unit for number */
  unit?: Maybe<Scalars['String']>;
  unitTone?: Maybe<CTextTone>;
};

/** A number component with numerical text and unit text display with dynamic size */
export type NumericalBlockLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type NumericalBlockConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: NumericalBlockWhereUniqueInput;
};

/** A connection to a list of items. */
export type NumericalBlockConnection = {
  __typename?: 'NumericalBlockConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<NumericalBlockEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type NumericalBlockCreateInput = {
  animationSpeed?: InputMaybe<AnimationSpeed>;
  fontWeight?: InputMaybe<TextWeight>;
  hasAnimation?: InputMaybe<Scalars['Boolean']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<NumericalBlockCreateLocalizationsInput>;
  /** number input for default locale (default) */
  number?: InputMaybe<Scalars['String']>;
  numberTone?: InputMaybe<CTextTone>;
  sameFontSize?: InputMaybe<Scalars['Boolean']>;
  textSize?: InputMaybe<CustomFontSize>;
  unit?: InputMaybe<Scalars['String']>;
  unitTone?: InputMaybe<CTextTone>;
};

export type NumericalBlockCreateLocalizationDataInput = {
  number?: InputMaybe<Scalars['String']>;
};

export type NumericalBlockCreateLocalizationInput = {
  /** Localization input */
  data: NumericalBlockCreateLocalizationDataInput;
  locale: Locale;
};

export type NumericalBlockCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<NumericalBlockCreateLocalizationInput>>;
};

export type NumericalBlockCreateManyInlineInput = {
  /** Create and connect multiple existing NumericalBlock documents */
  create?: InputMaybe<Array<NumericalBlockCreateInput>>;
};

export type NumericalBlockCreateOneInlineInput = {
  /** Create and connect one NumericalBlock document */
  create?: InputMaybe<NumericalBlockCreateInput>;
};

export type NumericalBlockCreateWithPositionInput = {
  /** Document to create */
  data: NumericalBlockCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type NumericalBlockEdge = {
  __typename?: 'NumericalBlockEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: NumericalBlock;
};

/** Identifies documents */
export type NumericalBlockManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<NumericalBlockWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<NumericalBlockWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<NumericalBlockWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  animationSpeed?: InputMaybe<AnimationSpeed>;
  /** All values that are contained in given list. */
  animationSpeed_in?: InputMaybe<Array<InputMaybe<AnimationSpeed>>>;
  /** Any other value that exists and is not equal to the given value. */
  animationSpeed_not?: InputMaybe<AnimationSpeed>;
  /** All values that are not contained in given list. */
  animationSpeed_not_in?: InputMaybe<Array<InputMaybe<AnimationSpeed>>>;
  fontWeight?: InputMaybe<TextWeight>;
  /** All values that are contained in given list. */
  fontWeight_in?: InputMaybe<Array<InputMaybe<TextWeight>>>;
  /** Any other value that exists and is not equal to the given value. */
  fontWeight_not?: InputMaybe<TextWeight>;
  /** All values that are not contained in given list. */
  fontWeight_not_in?: InputMaybe<Array<InputMaybe<TextWeight>>>;
  hasAnimation?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  hasAnimation_not?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  numberTone?: InputMaybe<CTextTone>;
  /** All values that are contained in given list. */
  numberTone_in?: InputMaybe<Array<InputMaybe<CTextTone>>>;
  /** Any other value that exists and is not equal to the given value. */
  numberTone_not?: InputMaybe<CTextTone>;
  /** All values that are not contained in given list. */
  numberTone_not_in?: InputMaybe<Array<InputMaybe<CTextTone>>>;
  sameFontSize?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  sameFontSize_not?: InputMaybe<Scalars['Boolean']>;
  textSize?: InputMaybe<CustomFontSize>;
  /** All values that are contained in given list. */
  textSize_in?: InputMaybe<Array<InputMaybe<CustomFontSize>>>;
  /** Any other value that exists and is not equal to the given value. */
  textSize_not?: InputMaybe<CustomFontSize>;
  /** All values that are not contained in given list. */
  textSize_not_in?: InputMaybe<Array<InputMaybe<CustomFontSize>>>;
  unit?: InputMaybe<Scalars['String']>;
  unitTone?: InputMaybe<CTextTone>;
  /** All values that are contained in given list. */
  unitTone_in?: InputMaybe<Array<InputMaybe<CTextTone>>>;
  /** Any other value that exists and is not equal to the given value. */
  unitTone_not?: InputMaybe<CTextTone>;
  /** All values that are not contained in given list. */
  unitTone_not_in?: InputMaybe<Array<InputMaybe<CTextTone>>>;
  /** All values containing the given string. */
  unit_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  unit_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  unit_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  unit_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  unit_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  unit_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  unit_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  unit_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  unit_starts_with?: InputMaybe<Scalars['String']>;
};

export enum NumericalBlockOrderByInput {
  AnimationSpeedAsc = 'animationSpeed_ASC',
  AnimationSpeedDesc = 'animationSpeed_DESC',
  FontWeightAsc = 'fontWeight_ASC',
  FontWeightDesc = 'fontWeight_DESC',
  HasAnimationAsc = 'hasAnimation_ASC',
  HasAnimationDesc = 'hasAnimation_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NumberToneAsc = 'numberTone_ASC',
  NumberToneDesc = 'numberTone_DESC',
  NumberAsc = 'number_ASC',
  NumberDesc = 'number_DESC',
  SameFontSizeAsc = 'sameFontSize_ASC',
  SameFontSizeDesc = 'sameFontSize_DESC',
  TextSizeAsc = 'textSize_ASC',
  TextSizeDesc = 'textSize_DESC',
  UnitToneAsc = 'unitTone_ASC',
  UnitToneDesc = 'unitTone_DESC',
  UnitAsc = 'unit_ASC',
  UnitDesc = 'unit_DESC',
}

export type NumericalBlockParent = CCardPrimary;

export type NumericalBlockParentConnectInput = {
  CCardPrimary?: InputMaybe<CCardPrimaryConnectInput>;
};

export type NumericalBlockParentCreateInput = {
  CCardPrimary?: InputMaybe<CCardPrimaryCreateInput>;
};

export type NumericalBlockParentCreateManyInlineInput = {
  /** Create and connect multiple existing NumericalBlockParent documents */
  create?: InputMaybe<Array<NumericalBlockParentCreateInput>>;
};

export type NumericalBlockParentCreateOneInlineInput = {
  /** Create and connect one NumericalBlockParent document */
  create?: InputMaybe<NumericalBlockParentCreateInput>;
};

export type NumericalBlockParentCreateWithPositionInput = {
  CCardPrimary?: InputMaybe<CCardPrimaryCreateWithPositionInput>;
};

export type NumericalBlockParentUpdateInput = {
  CCardPrimary?: InputMaybe<CCardPrimaryUpdateInput>;
};

export type NumericalBlockParentUpdateManyInlineInput = {
  /** Create and connect multiple NumericalBlockParent component instances */
  create?: InputMaybe<Array<NumericalBlockParentCreateWithPositionInput>>;
  /** Delete multiple NumericalBlockParent documents */
  delete?: InputMaybe<Array<NumericalBlockParentWhereUniqueInput>>;
  /** Update multiple NumericalBlockParent component instances */
  update?: InputMaybe<
    Array<NumericalBlockParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple NumericalBlockParent component instances */
  upsert?: InputMaybe<
    Array<NumericalBlockParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type NumericalBlockParentUpdateManyWithNestedWhereInput = {
  CCardPrimary?: InputMaybe<CCardPrimaryUpdateManyWithNestedWhereInput>;
};

export type NumericalBlockParentUpdateOneInlineInput = {
  /** Create and connect one NumericalBlockParent document */
  create?: InputMaybe<NumericalBlockParentCreateInput>;
  /** Delete currently connected NumericalBlockParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single NumericalBlockParent document */
  update?: InputMaybe<NumericalBlockParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single NumericalBlockParent document */
  upsert?: InputMaybe<NumericalBlockParentUpsertWithNestedWhereUniqueInput>;
};

export type NumericalBlockParentUpdateWithNestedWhereUniqueAndPositionInput = {
  CCardPrimary?: InputMaybe<CCardPrimaryUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type NumericalBlockParentUpdateWithNestedWhereUniqueInput = {
  CCardPrimary?: InputMaybe<CCardPrimaryUpdateWithNestedWhereUniqueInput>;
};

export type NumericalBlockParentUpsertWithNestedWhereUniqueAndPositionInput = {
  CCardPrimary?: InputMaybe<CCardPrimaryUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type NumericalBlockParentUpsertWithNestedWhereUniqueInput = {
  CCardPrimary?: InputMaybe<CCardPrimaryUpsertWithNestedWhereUniqueInput>;
};

export type NumericalBlockParentWhereInput = {
  CCardPrimary?: InputMaybe<CCardPrimaryWhereInput>;
};

export type NumericalBlockParentWhereUniqueInput = {
  CCardPrimary?: InputMaybe<CCardPrimaryWhereUniqueInput>;
};

export type NumericalBlockUpdateInput = {
  animationSpeed?: InputMaybe<AnimationSpeed>;
  fontWeight?: InputMaybe<TextWeight>;
  hasAnimation?: InputMaybe<Scalars['Boolean']>;
  /** Manage document localizations */
  localizations?: InputMaybe<NumericalBlockUpdateLocalizationsInput>;
  /** number input for default locale (default) */
  number?: InputMaybe<Scalars['String']>;
  numberTone?: InputMaybe<CTextTone>;
  sameFontSize?: InputMaybe<Scalars['Boolean']>;
  textSize?: InputMaybe<CustomFontSize>;
  unit?: InputMaybe<Scalars['String']>;
  unitTone?: InputMaybe<CTextTone>;
};

export type NumericalBlockUpdateLocalizationDataInput = {
  number?: InputMaybe<Scalars['String']>;
};

export type NumericalBlockUpdateLocalizationInput = {
  data: NumericalBlockUpdateLocalizationDataInput;
  locale: Locale;
};

export type NumericalBlockUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<NumericalBlockCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<NumericalBlockUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<NumericalBlockUpsertLocalizationInput>>;
};

export type NumericalBlockUpdateManyInlineInput = {
  /** Create and connect multiple NumericalBlock component instances */
  create?: InputMaybe<Array<NumericalBlockCreateWithPositionInput>>;
  /** Delete multiple NumericalBlock documents */
  delete?: InputMaybe<Array<NumericalBlockWhereUniqueInput>>;
  /** Update multiple NumericalBlock component instances */
  update?: InputMaybe<
    Array<NumericalBlockUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple NumericalBlock component instances */
  upsert?: InputMaybe<
    Array<NumericalBlockUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type NumericalBlockUpdateManyInput = {
  animationSpeed?: InputMaybe<AnimationSpeed>;
  fontWeight?: InputMaybe<TextWeight>;
  hasAnimation?: InputMaybe<Scalars['Boolean']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<NumericalBlockUpdateManyLocalizationsInput>;
  /** number input for default locale (default) */
  number?: InputMaybe<Scalars['String']>;
  numberTone?: InputMaybe<CTextTone>;
  sameFontSize?: InputMaybe<Scalars['Boolean']>;
  textSize?: InputMaybe<CustomFontSize>;
  unit?: InputMaybe<Scalars['String']>;
  unitTone?: InputMaybe<CTextTone>;
};

export type NumericalBlockUpdateManyLocalizationDataInput = {
  number?: InputMaybe<Scalars['String']>;
};

export type NumericalBlockUpdateManyLocalizationInput = {
  data: NumericalBlockUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type NumericalBlockUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<NumericalBlockUpdateManyLocalizationInput>>;
};

export type NumericalBlockUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: NumericalBlockUpdateManyInput;
  /** Document search */
  where: NumericalBlockWhereInput;
};

export type NumericalBlockUpdateOneInlineInput = {
  /** Create and connect one NumericalBlock document */
  create?: InputMaybe<NumericalBlockCreateInput>;
  /** Delete currently connected NumericalBlock document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single NumericalBlock document */
  update?: InputMaybe<NumericalBlockUpdateWithNestedWhereUniqueInput>;
  /** Upsert single NumericalBlock document */
  upsert?: InputMaybe<NumericalBlockUpsertWithNestedWhereUniqueInput>;
};

export type NumericalBlockUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<NumericalBlockUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: NumericalBlockWhereUniqueInput;
};

export type NumericalBlockUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: NumericalBlockUpdateInput;
  /** Unique document search */
  where: NumericalBlockWhereUniqueInput;
};

export type NumericalBlockUpsertInput = {
  /** Create document if it didn't exist */
  create: NumericalBlockCreateInput;
  /** Update document if it exists */
  update: NumericalBlockUpdateInput;
};

export type NumericalBlockUpsertLocalizationInput = {
  create: NumericalBlockCreateLocalizationDataInput;
  locale: Locale;
  update: NumericalBlockUpdateLocalizationDataInput;
};

export type NumericalBlockUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<NumericalBlockUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: NumericalBlockWhereUniqueInput;
};

export type NumericalBlockUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: NumericalBlockUpsertInput;
  /** Unique document search */
  where: NumericalBlockWhereUniqueInput;
};

/** Identifies documents */
export type NumericalBlockWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<NumericalBlockWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<NumericalBlockWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<NumericalBlockWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  animationSpeed?: InputMaybe<AnimationSpeed>;
  /** All values that are contained in given list. */
  animationSpeed_in?: InputMaybe<Array<InputMaybe<AnimationSpeed>>>;
  /** Any other value that exists and is not equal to the given value. */
  animationSpeed_not?: InputMaybe<AnimationSpeed>;
  /** All values that are not contained in given list. */
  animationSpeed_not_in?: InputMaybe<Array<InputMaybe<AnimationSpeed>>>;
  fontWeight?: InputMaybe<TextWeight>;
  /** All values that are contained in given list. */
  fontWeight_in?: InputMaybe<Array<InputMaybe<TextWeight>>>;
  /** Any other value that exists and is not equal to the given value. */
  fontWeight_not?: InputMaybe<TextWeight>;
  /** All values that are not contained in given list. */
  fontWeight_not_in?: InputMaybe<Array<InputMaybe<TextWeight>>>;
  hasAnimation?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  hasAnimation_not?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  number?: InputMaybe<Scalars['String']>;
  numberTone?: InputMaybe<CTextTone>;
  /** All values that are contained in given list. */
  numberTone_in?: InputMaybe<Array<InputMaybe<CTextTone>>>;
  /** Any other value that exists and is not equal to the given value. */
  numberTone_not?: InputMaybe<CTextTone>;
  /** All values that are not contained in given list. */
  numberTone_not_in?: InputMaybe<Array<InputMaybe<CTextTone>>>;
  /** All values containing the given string. */
  number_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  number_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  number_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  number_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  number_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  number_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  number_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  number_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  number_starts_with?: InputMaybe<Scalars['String']>;
  sameFontSize?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  sameFontSize_not?: InputMaybe<Scalars['Boolean']>;
  textSize?: InputMaybe<CustomFontSize>;
  /** All values that are contained in given list. */
  textSize_in?: InputMaybe<Array<InputMaybe<CustomFontSize>>>;
  /** Any other value that exists and is not equal to the given value. */
  textSize_not?: InputMaybe<CustomFontSize>;
  /** All values that are not contained in given list. */
  textSize_not_in?: InputMaybe<Array<InputMaybe<CustomFontSize>>>;
  unit?: InputMaybe<Scalars['String']>;
  unitTone?: InputMaybe<CTextTone>;
  /** All values that are contained in given list. */
  unitTone_in?: InputMaybe<Array<InputMaybe<CTextTone>>>;
  /** Any other value that exists and is not equal to the given value. */
  unitTone_not?: InputMaybe<CTextTone>;
  /** All values that are not contained in given list. */
  unitTone_not_in?: InputMaybe<Array<InputMaybe<CTextTone>>>;
  /** All values containing the given string. */
  unit_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  unit_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  unit_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  unit_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  unit_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  unit_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  unit_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  unit_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  unit_starts_with?: InputMaybe<Scalars['String']>;
};

/** References NumericalBlock record uniquely */
export type NumericalBlockWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Describes page content layout. */
export type Page = Entity &
  Node & {
    __typename?: 'Page';
    banner?: Maybe<PagebannerUnion>;
    containers: Array<CContainer>;
    /** The time the document was created */
    createdAt: Scalars['DateTime'];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<Page>;
    enableBreadcrumbs?: Maybe<Scalars['Boolean']>;
    hideEmbeddedFormInSection?: Maybe<Section>;
    /** List of Page versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars['ID'];
    /** System Locale field */
    locale: Locale;
    /** Get the other localizations for this document */
    localizations: Array<Page>;
    /** It identifies a post of URL that is migrated from the WordPress site. */
    migratedURL?: Maybe<Scalars['String']>;
    /** Promo banner at the top of the page for displaying promotional content, notification or alerts */
    promotionalBanner?: Maybe<PromotionalBanner>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    relatedPageSection?: Maybe<Section>;
    scheduledIn: Array<ScheduledOperation>;
    seo?: Maybe<Seo>;
    sites: Array<Site>;
    /** Represents the slug for the page. */
    slug: Scalars['String'];
    /** System stage field */
    stage: Stage;
    /** It describes title of the page. */
    title: Scalars['String'];
    /** The time the document was updated */
    updatedAt: Scalars['DateTime'];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

/** Describes page content layout. */
export type PageBannerArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Describes page content layout. */
export type PageContainersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<CContainerOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CContainerWhereInput>;
};

/** Describes page content layout. */
export type PageCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

/** Describes page content layout. */
export type PageCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Describes page content layout. */
export type PageDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

/** Describes page content layout. */
export type PageHideEmbeddedFormInSectionArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Describes page content layout. */
export type PageHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};

/** Describes page content layout. */
export type PageLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

/** Describes page content layout. */
export type PagePromotionalBannerArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Describes page content layout. */
export type PagePublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

/** Describes page content layout. */
export type PagePublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Describes page content layout. */
export type PageRelatedPageSectionArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Describes page content layout. */
export type PageScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

/** Describes page content layout. */
export type PageSeoArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Describes page content layout. */
export type PageSitesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<SiteOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SiteWhereInput>;
};

/** Describes page content layout. */
export type PageUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

/** Describes page content layout. */
export type PageUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PageConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: PageWhereUniqueInput;
};

/** A connection to a list of items. */
export type PageConnection = {
  __typename?: 'PageConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<PageEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type PageCreateInput = {
  banner?: InputMaybe<PagebannerUnionCreateOneInlineInput>;
  containers?: InputMaybe<CContainerCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  enableBreadcrumbs?: InputMaybe<Scalars['Boolean']>;
  hideEmbeddedFormInSection?: InputMaybe<SectionCreateOneInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<PageCreateLocalizationsInput>;
  /** migratedURL input for default locale (default) */
  migratedURL?: InputMaybe<Scalars['String']>;
  promotionalBanner?: InputMaybe<PromotionalBannerCreateOneInlineInput>;
  relatedPageSection?: InputMaybe<SectionCreateOneInlineInput>;
  seo?: InputMaybe<SeoCreateOneInlineInput>;
  sites?: InputMaybe<SiteCreateManyInlineInput>;
  /** slug input for default locale (default) */
  slug: Scalars['String'];
  /** title input for default locale (default) */
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PageCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  migratedURL?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PageCreateLocalizationInput = {
  /** Localization input */
  data: PageCreateLocalizationDataInput;
  locale: Locale;
};

export type PageCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<PageCreateLocalizationInput>>;
};

export type PageCreateManyInlineInput = {
  /** Connect multiple existing Page documents */
  connect?: InputMaybe<Array<PageWhereUniqueInput>>;
  /** Create and connect multiple existing Page documents */
  create?: InputMaybe<Array<PageCreateInput>>;
};

export type PageCreateOneInlineInput = {
  /** Connect one existing Page document */
  connect?: InputMaybe<PageWhereUniqueInput>;
  /** Create and connect one Page document */
  create?: InputMaybe<PageCreateInput>;
};

/** An edge in a connection. */
export type PageEdge = {
  __typename?: 'PageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Page;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** Number of items in the current page. */
  pageSize?: Maybe<Scalars['Int']>;
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

/** Identifies documents */
export type PageManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PageWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PageWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PageWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  /** All values in which the modular component is connected to the given models */
  banner?: InputMaybe<PagebannerUnionWhereInput>;
  /** All values in which the union is empty. */
  banner_empty?: InputMaybe<Scalars['Boolean']>;
  containers_every?: InputMaybe<CContainerWhereInput>;
  containers_none?: InputMaybe<CContainerWhereInput>;
  containers_some?: InputMaybe<CContainerWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<PageWhereStageInput>;
  documentInStages_none?: InputMaybe<PageWhereStageInput>;
  documentInStages_some?: InputMaybe<PageWhereStageInput>;
  enableBreadcrumbs?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  enableBreadcrumbs_not?: InputMaybe<Scalars['Boolean']>;
  hideEmbeddedFormInSection?: InputMaybe<SectionWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  promotionalBanner?: InputMaybe<PromotionalBannerWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  relatedPageSection?: InputMaybe<SectionWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  seo?: InputMaybe<SeoWhereInput>;
  sites_every?: InputMaybe<SiteWhereInput>;
  sites_none?: InputMaybe<SiteWhereInput>;
  sites_some?: InputMaybe<SiteWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum PageOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  EnableBreadcrumbsAsc = 'enableBreadcrumbs_ASC',
  EnableBreadcrumbsDesc = 'enableBreadcrumbs_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MigratedUrlAsc = 'migratedURL_ASC',
  MigratedUrlDesc = 'migratedURL_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type PageUpdateInput = {
  banner?: InputMaybe<PagebannerUnionUpdateOneInlineInput>;
  containers?: InputMaybe<CContainerUpdateManyInlineInput>;
  enableBreadcrumbs?: InputMaybe<Scalars['Boolean']>;
  hideEmbeddedFormInSection?: InputMaybe<SectionUpdateOneInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<PageUpdateLocalizationsInput>;
  /** migratedURL input for default locale (default) */
  migratedURL?: InputMaybe<Scalars['String']>;
  promotionalBanner?: InputMaybe<PromotionalBannerUpdateOneInlineInput>;
  relatedPageSection?: InputMaybe<SectionUpdateOneInlineInput>;
  seo?: InputMaybe<SeoUpdateOneInlineInput>;
  sites?: InputMaybe<SiteUpdateManyInlineInput>;
  /** slug input for default locale (default) */
  slug?: InputMaybe<Scalars['String']>;
  /** title input for default locale (default) */
  title?: InputMaybe<Scalars['String']>;
};

export type PageUpdateLocalizationDataInput = {
  migratedURL?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type PageUpdateLocalizationInput = {
  data: PageUpdateLocalizationDataInput;
  locale: Locale;
};

export type PageUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<PageCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<PageUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<PageUpsertLocalizationInput>>;
};

export type PageUpdateManyInlineInput = {
  /** Connect multiple existing Page documents */
  connect?: InputMaybe<Array<PageConnectInput>>;
  /** Create and connect multiple Page documents */
  create?: InputMaybe<Array<PageCreateInput>>;
  /** Delete multiple Page documents */
  delete?: InputMaybe<Array<PageWhereUniqueInput>>;
  /** Disconnect multiple Page documents */
  disconnect?: InputMaybe<Array<PageWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Page documents */
  set?: InputMaybe<Array<PageWhereUniqueInput>>;
  /** Update multiple Page documents */
  update?: InputMaybe<Array<PageUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Page documents */
  upsert?: InputMaybe<Array<PageUpsertWithNestedWhereUniqueInput>>;
};

export type PageUpdateManyInput = {
  enableBreadcrumbs?: InputMaybe<Scalars['Boolean']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<PageUpdateManyLocalizationsInput>;
  /** migratedURL input for default locale (default) */
  migratedURL?: InputMaybe<Scalars['String']>;
  /** title input for default locale (default) */
  title?: InputMaybe<Scalars['String']>;
};

export type PageUpdateManyLocalizationDataInput = {
  migratedURL?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type PageUpdateManyLocalizationInput = {
  data: PageUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type PageUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<PageUpdateManyLocalizationInput>>;
};

export type PageUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: PageUpdateManyInput;
  /** Document search */
  where: PageWhereInput;
};

export type PageUpdateOneInlineInput = {
  /** Connect existing Page document */
  connect?: InputMaybe<PageWhereUniqueInput>;
  /** Create and connect one Page document */
  create?: InputMaybe<PageCreateInput>;
  /** Delete currently connected Page document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected Page document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single Page document */
  update?: InputMaybe<PageUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Page document */
  upsert?: InputMaybe<PageUpsertWithNestedWhereUniqueInput>;
};

export type PageUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: PageUpdateInput;
  /** Unique document search */
  where: PageWhereUniqueInput;
};

export type PageUpsertInput = {
  /** Create document if it didn't exist */
  create: PageCreateInput;
  /** Update document if it exists */
  update: PageUpdateInput;
};

export type PageUpsertLocalizationInput = {
  create: PageCreateLocalizationDataInput;
  locale: Locale;
  update: PageUpdateLocalizationDataInput;
};

export type PageUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: PageUpsertInput;
  /** Unique document search */
  where: PageWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type PageWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type PageWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PageWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PageWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PageWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  /** All values in which the modular component is connected to the given models */
  banner?: InputMaybe<PagebannerUnionWhereInput>;
  /** All values in which the union is empty. */
  banner_empty?: InputMaybe<Scalars['Boolean']>;
  containers_every?: InputMaybe<CContainerWhereInput>;
  containers_none?: InputMaybe<CContainerWhereInput>;
  containers_some?: InputMaybe<CContainerWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<PageWhereStageInput>;
  documentInStages_none?: InputMaybe<PageWhereStageInput>;
  documentInStages_some?: InputMaybe<PageWhereStageInput>;
  enableBreadcrumbs?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  enableBreadcrumbs_not?: InputMaybe<Scalars['Boolean']>;
  hideEmbeddedFormInSection?: InputMaybe<SectionWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  migratedURL?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  migratedURL_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  migratedURL_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  migratedURL_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  migratedURL_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  migratedURL_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  migratedURL_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  migratedURL_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  migratedURL_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  migratedURL_starts_with?: InputMaybe<Scalars['String']>;
  promotionalBanner?: InputMaybe<PromotionalBannerWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  relatedPageSection?: InputMaybe<SectionWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  seo?: InputMaybe<SeoWhereInput>;
  sites_every?: InputMaybe<SiteWhereInput>;
  sites_none?: InputMaybe<SiteWhereInput>;
  sites_some?: InputMaybe<SiteWhereInput>;
  slug?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type PageWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PageWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PageWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PageWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<PageWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Page record uniquely */
export type PageWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type PagebannerUnion = CBannerAward | CBannerPrimary | CBannerShopfront;

export type PagebannerUnionConnectInput = {
  CBannerAward?: InputMaybe<CBannerAwardConnectInput>;
  CBannerPrimary?: InputMaybe<CBannerPrimaryConnectInput>;
  CBannerShopfront?: InputMaybe<CBannerShopfrontConnectInput>;
};

export type PagebannerUnionCreateInput = {
  CBannerAward?: InputMaybe<CBannerAwardCreateInput>;
  CBannerPrimary?: InputMaybe<CBannerPrimaryCreateInput>;
  CBannerShopfront?: InputMaybe<CBannerShopfrontCreateInput>;
};

export type PagebannerUnionCreateManyInlineInput = {
  /** Create and connect multiple existing PagebannerUnion documents */
  create?: InputMaybe<Array<PagebannerUnionCreateInput>>;
};

export type PagebannerUnionCreateOneInlineInput = {
  /** Create and connect one PagebannerUnion document */
  create?: InputMaybe<PagebannerUnionCreateInput>;
};

export type PagebannerUnionCreateWithPositionInput = {
  CBannerAward?: InputMaybe<CBannerAwardCreateWithPositionInput>;
  CBannerPrimary?: InputMaybe<CBannerPrimaryCreateWithPositionInput>;
  CBannerShopfront?: InputMaybe<CBannerShopfrontCreateWithPositionInput>;
};

export type PagebannerUnionUpdateInput = {
  CBannerAward?: InputMaybe<CBannerAwardUpdateInput>;
  CBannerPrimary?: InputMaybe<CBannerPrimaryUpdateInput>;
  CBannerShopfront?: InputMaybe<CBannerShopfrontUpdateInput>;
};

export type PagebannerUnionUpdateManyInlineInput = {
  /** Create and connect multiple PagebannerUnion component instances */
  create?: InputMaybe<Array<PagebannerUnionCreateWithPositionInput>>;
  /** Delete multiple PagebannerUnion documents */
  delete?: InputMaybe<Array<PagebannerUnionWhereUniqueInput>>;
  /** Update multiple PagebannerUnion component instances */
  update?: InputMaybe<
    Array<PagebannerUnionUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple PagebannerUnion component instances */
  upsert?: InputMaybe<
    Array<PagebannerUnionUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type PagebannerUnionUpdateManyWithNestedWhereInput = {
  CBannerAward?: InputMaybe<CBannerAwardUpdateManyWithNestedWhereInput>;
  CBannerPrimary?: InputMaybe<CBannerPrimaryUpdateManyWithNestedWhereInput>;
  CBannerShopfront?: InputMaybe<CBannerShopfrontUpdateManyWithNestedWhereInput>;
};

export type PagebannerUnionUpdateOneInlineInput = {
  /** Create and connect one PagebannerUnion document */
  create?: InputMaybe<PagebannerUnionCreateInput>;
  /** Delete currently connected PagebannerUnion document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single PagebannerUnion document */
  update?: InputMaybe<PagebannerUnionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single PagebannerUnion document */
  upsert?: InputMaybe<PagebannerUnionUpsertWithNestedWhereUniqueInput>;
};

export type PagebannerUnionUpdateWithNestedWhereUniqueAndPositionInput = {
  CBannerAward?: InputMaybe<CBannerAwardUpdateWithNestedWhereUniqueAndPositionInput>;
  CBannerPrimary?: InputMaybe<CBannerPrimaryUpdateWithNestedWhereUniqueAndPositionInput>;
  CBannerShopfront?: InputMaybe<CBannerShopfrontUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type PagebannerUnionUpdateWithNestedWhereUniqueInput = {
  CBannerAward?: InputMaybe<CBannerAwardUpdateWithNestedWhereUniqueInput>;
  CBannerPrimary?: InputMaybe<CBannerPrimaryUpdateWithNestedWhereUniqueInput>;
  CBannerShopfront?: InputMaybe<CBannerShopfrontUpdateWithNestedWhereUniqueInput>;
};

export type PagebannerUnionUpsertWithNestedWhereUniqueAndPositionInput = {
  CBannerAward?: InputMaybe<CBannerAwardUpsertWithNestedWhereUniqueAndPositionInput>;
  CBannerPrimary?: InputMaybe<CBannerPrimaryUpsertWithNestedWhereUniqueAndPositionInput>;
  CBannerShopfront?: InputMaybe<CBannerShopfrontUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type PagebannerUnionUpsertWithNestedWhereUniqueInput = {
  CBannerAward?: InputMaybe<CBannerAwardUpsertWithNestedWhereUniqueInput>;
  CBannerPrimary?: InputMaybe<CBannerPrimaryUpsertWithNestedWhereUniqueInput>;
  CBannerShopfront?: InputMaybe<CBannerShopfrontUpsertWithNestedWhereUniqueInput>;
};

export type PagebannerUnionWhereInput = {
  CBannerAward?: InputMaybe<CBannerAwardWhereInput>;
  CBannerPrimary?: InputMaybe<CBannerPrimaryWhereInput>;
  CBannerShopfront?: InputMaybe<CBannerShopfrontWhereInput>;
};

export type PagebannerUnionWhereUniqueInput = {
  CBannerAward?: InputMaybe<CBannerAwardWhereUniqueInput>;
  CBannerPrimary?: InputMaybe<CBannerPrimaryWhereUniqueInput>;
  CBannerShopfront?: InputMaybe<CBannerShopfrontWhereUniqueInput>;
};

/** Podcast information and links */
export type Podcast = Entity & {
  __typename?: 'Podcast';
  /** Podcast link url for Apple */
  appleUrl?: Maybe<Scalars['String']>;
  /** Podcast link url for Google */
  googleUrl?: Maybe<Scalars['String']>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Podcast>;
  /** Podcast link url for Spotify */
  spotifyUrl?: Maybe<Scalars['String']>;
  /** System stage field */
  stage: Stage;
};

/** Podcast information and links */
export type PodcastLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type PodcastConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: PodcastWhereUniqueInput;
};

/** A connection to a list of items. */
export type PodcastConnection = {
  __typename?: 'PodcastConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<PodcastEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type PodcastCreateInput = {
  /** appleUrl input for default locale (default) */
  appleUrl?: InputMaybe<Scalars['String']>;
  /** googleUrl input for default locale (default) */
  googleUrl?: InputMaybe<Scalars['String']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<PodcastCreateLocalizationsInput>;
  /** spotifyUrl input for default locale (default) */
  spotifyUrl?: InputMaybe<Scalars['String']>;
};

export type PodcastCreateLocalizationDataInput = {
  appleUrl?: InputMaybe<Scalars['String']>;
  googleUrl?: InputMaybe<Scalars['String']>;
  spotifyUrl?: InputMaybe<Scalars['String']>;
};

export type PodcastCreateLocalizationInput = {
  /** Localization input */
  data: PodcastCreateLocalizationDataInput;
  locale: Locale;
};

export type PodcastCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<PodcastCreateLocalizationInput>>;
};

export type PodcastCreateManyInlineInput = {
  /** Create and connect multiple existing Podcast documents */
  create?: InputMaybe<Array<PodcastCreateInput>>;
};

export type PodcastCreateOneInlineInput = {
  /** Create and connect one Podcast document */
  create?: InputMaybe<PodcastCreateInput>;
};

export type PodcastCreateWithPositionInput = {
  /** Document to create */
  data: PodcastCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type PodcastEdge = {
  __typename?: 'PodcastEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Podcast;
};

/** Identifies documents */
export type PodcastManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PodcastWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PodcastWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PodcastWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
};

export enum PodcastOrderByInput {
  AppleUrlAsc = 'appleUrl_ASC',
  AppleUrlDesc = 'appleUrl_DESC',
  GoogleUrlAsc = 'googleUrl_ASC',
  GoogleUrlDesc = 'googleUrl_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SpotifyUrlAsc = 'spotifyUrl_ASC',
  SpotifyUrlDesc = 'spotifyUrl_DESC',
}

export type PodcastParent = Article;

export type PodcastParentConnectInput = {
  Article?: InputMaybe<ArticleConnectInput>;
};

export type PodcastParentCreateInput = {
  Article?: InputMaybe<ArticleCreateInput>;
};

export type PodcastParentCreateManyInlineInput = {
  /** Connect multiple existing PodcastParent documents */
  connect?: InputMaybe<Array<PodcastParentWhereUniqueInput>>;
  /** Create and connect multiple existing PodcastParent documents */
  create?: InputMaybe<Array<PodcastParentCreateInput>>;
};

export type PodcastParentCreateOneInlineInput = {
  /** Connect one existing PodcastParent document */
  connect?: InputMaybe<PodcastParentWhereUniqueInput>;
  /** Create and connect one PodcastParent document */
  create?: InputMaybe<PodcastParentCreateInput>;
};

export type PodcastParentUpdateInput = {
  Article?: InputMaybe<ArticleUpdateInput>;
};

export type PodcastParentUpdateManyInlineInput = {
  /** Connect multiple existing PodcastParent documents */
  connect?: InputMaybe<Array<PodcastParentConnectInput>>;
  /** Create and connect multiple PodcastParent documents */
  create?: InputMaybe<Array<PodcastParentCreateInput>>;
  /** Delete multiple PodcastParent documents */
  delete?: InputMaybe<Array<PodcastParentWhereUniqueInput>>;
  /** Disconnect multiple PodcastParent documents */
  disconnect?: InputMaybe<Array<PodcastParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing PodcastParent documents */
  set?: InputMaybe<Array<PodcastParentWhereUniqueInput>>;
  /** Update multiple PodcastParent documents */
  update?: InputMaybe<Array<PodcastParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple PodcastParent documents */
  upsert?: InputMaybe<Array<PodcastParentUpsertWithNestedWhereUniqueInput>>;
};

export type PodcastParentUpdateManyWithNestedWhereInput = {
  Article?: InputMaybe<ArticleUpdateManyWithNestedWhereInput>;
};

export type PodcastParentUpdateOneInlineInput = {
  /** Connect existing PodcastParent document */
  connect?: InputMaybe<PodcastParentWhereUniqueInput>;
  /** Create and connect one PodcastParent document */
  create?: InputMaybe<PodcastParentCreateInput>;
  /** Delete currently connected PodcastParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected PodcastParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single PodcastParent document */
  update?: InputMaybe<PodcastParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single PodcastParent document */
  upsert?: InputMaybe<PodcastParentUpsertWithNestedWhereUniqueInput>;
};

export type PodcastParentUpdateWithNestedWhereUniqueInput = {
  Article?: InputMaybe<ArticleUpdateWithNestedWhereUniqueInput>;
};

export type PodcastParentUpsertWithNestedWhereUniqueInput = {
  Article?: InputMaybe<ArticleUpsertWithNestedWhereUniqueInput>;
};

export type PodcastParentWhereInput = {
  Article?: InputMaybe<ArticleWhereInput>;
};

export type PodcastParentWhereUniqueInput = {
  Article?: InputMaybe<ArticleWhereUniqueInput>;
};

export type PodcastUpdateInput = {
  /** appleUrl input for default locale (default) */
  appleUrl?: InputMaybe<Scalars['String']>;
  /** googleUrl input for default locale (default) */
  googleUrl?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<PodcastUpdateLocalizationsInput>;
  /** spotifyUrl input for default locale (default) */
  spotifyUrl?: InputMaybe<Scalars['String']>;
};

export type PodcastUpdateLocalizationDataInput = {
  appleUrl?: InputMaybe<Scalars['String']>;
  googleUrl?: InputMaybe<Scalars['String']>;
  spotifyUrl?: InputMaybe<Scalars['String']>;
};

export type PodcastUpdateLocalizationInput = {
  data: PodcastUpdateLocalizationDataInput;
  locale: Locale;
};

export type PodcastUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<PodcastCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<PodcastUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<PodcastUpsertLocalizationInput>>;
};

export type PodcastUpdateManyInlineInput = {
  /** Create and connect multiple Podcast component instances */
  create?: InputMaybe<Array<PodcastCreateWithPositionInput>>;
  /** Delete multiple Podcast documents */
  delete?: InputMaybe<Array<PodcastWhereUniqueInput>>;
  /** Update multiple Podcast component instances */
  update?: InputMaybe<
    Array<PodcastUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple Podcast component instances */
  upsert?: InputMaybe<
    Array<PodcastUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type PodcastUpdateManyInput = {
  /** appleUrl input for default locale (default) */
  appleUrl?: InputMaybe<Scalars['String']>;
  /** googleUrl input for default locale (default) */
  googleUrl?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<PodcastUpdateManyLocalizationsInput>;
  /** spotifyUrl input for default locale (default) */
  spotifyUrl?: InputMaybe<Scalars['String']>;
};

export type PodcastUpdateManyLocalizationDataInput = {
  appleUrl?: InputMaybe<Scalars['String']>;
  googleUrl?: InputMaybe<Scalars['String']>;
  spotifyUrl?: InputMaybe<Scalars['String']>;
};

export type PodcastUpdateManyLocalizationInput = {
  data: PodcastUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type PodcastUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<PodcastUpdateManyLocalizationInput>>;
};

export type PodcastUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: PodcastUpdateManyInput;
  /** Document search */
  where: PodcastWhereInput;
};

export type PodcastUpdateOneInlineInput = {
  /** Create and connect one Podcast document */
  create?: InputMaybe<PodcastCreateInput>;
  /** Delete currently connected Podcast document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single Podcast document */
  update?: InputMaybe<PodcastUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Podcast document */
  upsert?: InputMaybe<PodcastUpsertWithNestedWhereUniqueInput>;
};

export type PodcastUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<PodcastUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: PodcastWhereUniqueInput;
};

export type PodcastUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: PodcastUpdateInput;
  /** Unique document search */
  where: PodcastWhereUniqueInput;
};

export type PodcastUpsertInput = {
  /** Create document if it didn't exist */
  create: PodcastCreateInput;
  /** Update document if it exists */
  update: PodcastUpdateInput;
};

export type PodcastUpsertLocalizationInput = {
  create: PodcastCreateLocalizationDataInput;
  locale: Locale;
  update: PodcastUpdateLocalizationDataInput;
};

export type PodcastUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<PodcastUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: PodcastWhereUniqueInput;
};

export type PodcastUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: PodcastUpsertInput;
  /** Unique document search */
  where: PodcastWhereUniqueInput;
};

/** Identifies documents */
export type PodcastWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PodcastWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PodcastWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PodcastWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  appleUrl?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  appleUrl_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  appleUrl_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  appleUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  appleUrl_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  appleUrl_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  appleUrl_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  appleUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  appleUrl_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  appleUrl_starts_with?: InputMaybe<Scalars['String']>;
  googleUrl?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  googleUrl_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  googleUrl_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  googleUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  googleUrl_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  googleUrl_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  googleUrl_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  googleUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  googleUrl_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  googleUrl_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  spotifyUrl?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  spotifyUrl_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  spotifyUrl_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  spotifyUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  spotifyUrl_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  spotifyUrl_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  spotifyUrl_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  spotifyUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  spotifyUrl_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  spotifyUrl_starts_with?: InputMaybe<Scalars['String']>;
};

/** References Podcast record uniquely */
export type PodcastWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type ProfileBio = Entity & {
  __typename?: 'ProfileBio';
  /** Biography */
  description?: Maybe<RichText>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** Profile picture */
  image?: Maybe<Asset>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<ProfileBio>;
  name: Scalars['String'];
  roleTitle?: Maybe<Scalars['String']>;
  /** System stage field */
  stage: Stage;
};

export type ProfileBioImageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
  where?: InputMaybe<AssetSingleRelationWhereInput>;
};

export type ProfileBioLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type ProfileBioConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ProfileBioWhereUniqueInput;
};

/** A connection to a list of items. */
export type ProfileBioConnection = {
  __typename?: 'ProfileBioConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ProfileBioEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ProfileBioCreateInput = {
  /** description input for default locale (default) */
  description?: InputMaybe<Scalars['RichTextAST']>;
  image?: InputMaybe<AssetCreateOneInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<ProfileBioCreateLocalizationsInput>;
  /** name input for default locale (default) */
  name: Scalars['String'];
  /** roleTitle input for default locale (default) */
  roleTitle?: InputMaybe<Scalars['String']>;
};

export type ProfileBioCreateLocalizationDataInput = {
  description?: InputMaybe<Scalars['RichTextAST']>;
  name: Scalars['String'];
  roleTitle?: InputMaybe<Scalars['String']>;
};

export type ProfileBioCreateLocalizationInput = {
  /** Localization input */
  data: ProfileBioCreateLocalizationDataInput;
  locale: Locale;
};

export type ProfileBioCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<ProfileBioCreateLocalizationInput>>;
};

export type ProfileBioCreateManyInlineInput = {
  /** Create and connect multiple existing ProfileBio documents */
  create?: InputMaybe<Array<ProfileBioCreateInput>>;
};

export type ProfileBioCreateOneInlineInput = {
  /** Create and connect one ProfileBio document */
  create?: InputMaybe<ProfileBioCreateInput>;
};

export type ProfileBioCreateWithPositionInput = {
  /** Document to create */
  data: ProfileBioCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type ProfileBioEdge = {
  __typename?: 'ProfileBioEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ProfileBio;
};

/** Identifies documents */
export type ProfileBioManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ProfileBioWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ProfileBioWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ProfileBioWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AssetWhereInput>;
};

export enum ProfileBioOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  RoleTitleAsc = 'roleTitle_ASC',
  RoleTitleDesc = 'roleTitle_DESC',
}

export type ProfileBioParent = CBlock;

export type ProfileBioParentConnectInput = {
  CBlock?: InputMaybe<CBlockConnectInput>;
};

export type ProfileBioParentCreateInput = {
  CBlock?: InputMaybe<CBlockCreateInput>;
};

export type ProfileBioParentCreateManyInlineInput = {
  /** Connect multiple existing ProfileBioParent documents */
  connect?: InputMaybe<Array<ProfileBioParentWhereUniqueInput>>;
  /** Create and connect multiple existing ProfileBioParent documents */
  create?: InputMaybe<Array<ProfileBioParentCreateInput>>;
};

export type ProfileBioParentCreateOneInlineInput = {
  /** Connect one existing ProfileBioParent document */
  connect?: InputMaybe<ProfileBioParentWhereUniqueInput>;
  /** Create and connect one ProfileBioParent document */
  create?: InputMaybe<ProfileBioParentCreateInput>;
};

export type ProfileBioParentUpdateInput = {
  CBlock?: InputMaybe<CBlockUpdateInput>;
};

export type ProfileBioParentUpdateManyInlineInput = {
  /** Connect multiple existing ProfileBioParent documents */
  connect?: InputMaybe<Array<ProfileBioParentConnectInput>>;
  /** Create and connect multiple ProfileBioParent documents */
  create?: InputMaybe<Array<ProfileBioParentCreateInput>>;
  /** Delete multiple ProfileBioParent documents */
  delete?: InputMaybe<Array<ProfileBioParentWhereUniqueInput>>;
  /** Disconnect multiple ProfileBioParent documents */
  disconnect?: InputMaybe<Array<ProfileBioParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing ProfileBioParent documents */
  set?: InputMaybe<Array<ProfileBioParentWhereUniqueInput>>;
  /** Update multiple ProfileBioParent documents */
  update?: InputMaybe<Array<ProfileBioParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple ProfileBioParent documents */
  upsert?: InputMaybe<Array<ProfileBioParentUpsertWithNestedWhereUniqueInput>>;
};

export type ProfileBioParentUpdateManyWithNestedWhereInput = {
  CBlock?: InputMaybe<CBlockUpdateManyWithNestedWhereInput>;
};

export type ProfileBioParentUpdateOneInlineInput = {
  /** Connect existing ProfileBioParent document */
  connect?: InputMaybe<ProfileBioParentWhereUniqueInput>;
  /** Create and connect one ProfileBioParent document */
  create?: InputMaybe<ProfileBioParentCreateInput>;
  /** Delete currently connected ProfileBioParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected ProfileBioParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single ProfileBioParent document */
  update?: InputMaybe<ProfileBioParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ProfileBioParent document */
  upsert?: InputMaybe<ProfileBioParentUpsertWithNestedWhereUniqueInput>;
};

export type ProfileBioParentUpdateWithNestedWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockUpdateWithNestedWhereUniqueInput>;
};

export type ProfileBioParentUpsertWithNestedWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockUpsertWithNestedWhereUniqueInput>;
};

export type ProfileBioParentWhereInput = {
  CBlock?: InputMaybe<CBlockWhereInput>;
};

export type ProfileBioParentWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockWhereUniqueInput>;
};

export type ProfileBioUpdateInput = {
  /** description input for default locale (default) */
  description?: InputMaybe<Scalars['RichTextAST']>;
  image?: InputMaybe<AssetUpdateOneInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<ProfileBioUpdateLocalizationsInput>;
  /** name input for default locale (default) */
  name?: InputMaybe<Scalars['String']>;
  /** roleTitle input for default locale (default) */
  roleTitle?: InputMaybe<Scalars['String']>;
};

export type ProfileBioUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['RichTextAST']>;
  name?: InputMaybe<Scalars['String']>;
  roleTitle?: InputMaybe<Scalars['String']>;
};

export type ProfileBioUpdateLocalizationInput = {
  data: ProfileBioUpdateLocalizationDataInput;
  locale: Locale;
};

export type ProfileBioUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<ProfileBioCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<ProfileBioUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<ProfileBioUpsertLocalizationInput>>;
};

export type ProfileBioUpdateManyInlineInput = {
  /** Create and connect multiple ProfileBio component instances */
  create?: InputMaybe<Array<ProfileBioCreateWithPositionInput>>;
  /** Delete multiple ProfileBio documents */
  delete?: InputMaybe<Array<ProfileBioWhereUniqueInput>>;
  /** Update multiple ProfileBio component instances */
  update?: InputMaybe<
    Array<ProfileBioUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple ProfileBio component instances */
  upsert?: InputMaybe<
    Array<ProfileBioUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type ProfileBioUpdateManyInput = {
  /** description input for default locale (default) */
  description?: InputMaybe<Scalars['RichTextAST']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<ProfileBioUpdateManyLocalizationsInput>;
  /** name input for default locale (default) */
  name?: InputMaybe<Scalars['String']>;
  /** roleTitle input for default locale (default) */
  roleTitle?: InputMaybe<Scalars['String']>;
};

export type ProfileBioUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['RichTextAST']>;
  name?: InputMaybe<Scalars['String']>;
  roleTitle?: InputMaybe<Scalars['String']>;
};

export type ProfileBioUpdateManyLocalizationInput = {
  data: ProfileBioUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type ProfileBioUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<ProfileBioUpdateManyLocalizationInput>>;
};

export type ProfileBioUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ProfileBioUpdateManyInput;
  /** Document search */
  where: ProfileBioWhereInput;
};

export type ProfileBioUpdateOneInlineInput = {
  /** Create and connect one ProfileBio document */
  create?: InputMaybe<ProfileBioCreateInput>;
  /** Delete currently connected ProfileBio document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single ProfileBio document */
  update?: InputMaybe<ProfileBioUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ProfileBio document */
  upsert?: InputMaybe<ProfileBioUpsertWithNestedWhereUniqueInput>;
};

export type ProfileBioUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<ProfileBioUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: ProfileBioWhereUniqueInput;
};

export type ProfileBioUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ProfileBioUpdateInput;
  /** Unique document search */
  where: ProfileBioWhereUniqueInput;
};

export type ProfileBioUpsertInput = {
  /** Create document if it didn't exist */
  create: ProfileBioCreateInput;
  /** Update document if it exists */
  update: ProfileBioUpdateInput;
};

export type ProfileBioUpsertLocalizationInput = {
  create: ProfileBioCreateLocalizationDataInput;
  locale: Locale;
  update: ProfileBioUpdateLocalizationDataInput;
};

export type ProfileBioUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<ProfileBioUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: ProfileBioWhereUniqueInput;
};

export type ProfileBioUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ProfileBioUpsertInput;
  /** Unique document search */
  where: ProfileBioWhereUniqueInput;
};

/** Identifies documents */
export type ProfileBioWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ProfileBioWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ProfileBioWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ProfileBioWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AssetWhereInput>;
  name?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']>;
  roleTitle?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  roleTitle_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  roleTitle_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  roleTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  roleTitle_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  roleTitle_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  roleTitle_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  roleTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  roleTitle_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  roleTitle_starts_with?: InputMaybe<Scalars['String']>;
};

/** References ProfileBio record uniquely */
export type ProfileBioWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Custom Component for /profile-privacy */
export type ProfileVisibilityCardGroup = Entity & {
  __typename?: 'ProfileVisibilityCardGroup';
  /** The unique identifier */
  id: Scalars['ID'];
  /** System stage field */
  stage: Stage;
};

export type ProfileVisibilityCardGroupConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ProfileVisibilityCardGroupWhereUniqueInput;
};

/** A connection to a list of items. */
export type ProfileVisibilityCardGroupConnection = {
  __typename?: 'ProfileVisibilityCardGroupConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ProfileVisibilityCardGroupEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ProfileVisibilityCardGroupCreateInput = {
  /** No fields in create input */
  _?: InputMaybe<Scalars['String']>;
};

export type ProfileVisibilityCardGroupCreateManyInlineInput = {
  /** Create and connect multiple existing ProfileVisibilityCardGroup documents */
  create?: InputMaybe<Array<ProfileVisibilityCardGroupCreateInput>>;
};

export type ProfileVisibilityCardGroupCreateOneInlineInput = {
  /** Create and connect one ProfileVisibilityCardGroup document */
  create?: InputMaybe<ProfileVisibilityCardGroupCreateInput>;
};

export type ProfileVisibilityCardGroupCreateWithPositionInput = {
  /** Document to create */
  data: ProfileVisibilityCardGroupCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type ProfileVisibilityCardGroupEdge = {
  __typename?: 'ProfileVisibilityCardGroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ProfileVisibilityCardGroup;
};

/** Identifies documents */
export type ProfileVisibilityCardGroupManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ProfileVisibilityCardGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ProfileVisibilityCardGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ProfileVisibilityCardGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
};

export enum ProfileVisibilityCardGroupOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type ProfileVisibilityCardGroupParent = CCustomComponent;

export type ProfileVisibilityCardGroupParentConnectInput = {
  CCustomComponent?: InputMaybe<CCustomComponentConnectInput>;
};

export type ProfileVisibilityCardGroupParentCreateInput = {
  CCustomComponent?: InputMaybe<CCustomComponentCreateInput>;
};

export type ProfileVisibilityCardGroupParentCreateManyInlineInput = {
  /** Create and connect multiple existing ProfileVisibilityCardGroupParent documents */
  create?: InputMaybe<Array<ProfileVisibilityCardGroupParentCreateInput>>;
};

export type ProfileVisibilityCardGroupParentCreateOneInlineInput = {
  /** Create and connect one ProfileVisibilityCardGroupParent document */
  create?: InputMaybe<ProfileVisibilityCardGroupParentCreateInput>;
};

export type ProfileVisibilityCardGroupParentCreateWithPositionInput = {
  CCustomComponent?: InputMaybe<CCustomComponentCreateWithPositionInput>;
};

export type ProfileVisibilityCardGroupParentUpdateInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpdateInput>;
};

export type ProfileVisibilityCardGroupParentUpdateManyInlineInput = {
  /** Create and connect multiple ProfileVisibilityCardGroupParent component instances */
  create?: InputMaybe<
    Array<ProfileVisibilityCardGroupParentCreateWithPositionInput>
  >;
  /** Delete multiple ProfileVisibilityCardGroupParent documents */
  delete?: InputMaybe<Array<ProfileVisibilityCardGroupParentWhereUniqueInput>>;
  /** Update multiple ProfileVisibilityCardGroupParent component instances */
  update?: InputMaybe<
    Array<ProfileVisibilityCardGroupParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple ProfileVisibilityCardGroupParent component instances */
  upsert?: InputMaybe<
    Array<ProfileVisibilityCardGroupParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type ProfileVisibilityCardGroupParentUpdateManyWithNestedWhereInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpdateManyWithNestedWhereInput>;
};

export type ProfileVisibilityCardGroupParentUpdateOneInlineInput = {
  /** Create and connect one ProfileVisibilityCardGroupParent document */
  create?: InputMaybe<ProfileVisibilityCardGroupParentCreateInput>;
  /** Delete currently connected ProfileVisibilityCardGroupParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single ProfileVisibilityCardGroupParent document */
  update?: InputMaybe<ProfileVisibilityCardGroupParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ProfileVisibilityCardGroupParent document */
  upsert?: InputMaybe<ProfileVisibilityCardGroupParentUpsertWithNestedWhereUniqueInput>;
};

export type ProfileVisibilityCardGroupParentUpdateWithNestedWhereUniqueAndPositionInput =
  {
    CCustomComponent?: InputMaybe<CCustomComponentUpdateWithNestedWhereUniqueAndPositionInput>;
  };

export type ProfileVisibilityCardGroupParentUpdateWithNestedWhereUniqueInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpdateWithNestedWhereUniqueInput>;
};

export type ProfileVisibilityCardGroupParentUpsertWithNestedWhereUniqueAndPositionInput =
  {
    CCustomComponent?: InputMaybe<CCustomComponentUpsertWithNestedWhereUniqueAndPositionInput>;
  };

export type ProfileVisibilityCardGroupParentUpsertWithNestedWhereUniqueInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpsertWithNestedWhereUniqueInput>;
};

export type ProfileVisibilityCardGroupParentWhereInput = {
  CCustomComponent?: InputMaybe<CCustomComponentWhereInput>;
};

export type ProfileVisibilityCardGroupParentWhereUniqueInput = {
  CCustomComponent?: InputMaybe<CCustomComponentWhereUniqueInput>;
};

export type ProfileVisibilityCardGroupUpdateInput = {
  /** No fields in update input */
  _?: InputMaybe<Scalars['String']>;
};

export type ProfileVisibilityCardGroupUpdateManyInlineInput = {
  /** Create and connect multiple ProfileVisibilityCardGroup component instances */
  create?: InputMaybe<Array<ProfileVisibilityCardGroupCreateWithPositionInput>>;
  /** Delete multiple ProfileVisibilityCardGroup documents */
  delete?: InputMaybe<Array<ProfileVisibilityCardGroupWhereUniqueInput>>;
  /** Update multiple ProfileVisibilityCardGroup component instances */
  update?: InputMaybe<
    Array<ProfileVisibilityCardGroupUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple ProfileVisibilityCardGroup component instances */
  upsert?: InputMaybe<
    Array<ProfileVisibilityCardGroupUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type ProfileVisibilityCardGroupUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']>;
};

export type ProfileVisibilityCardGroupUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ProfileVisibilityCardGroupUpdateManyInput;
  /** Document search */
  where: ProfileVisibilityCardGroupWhereInput;
};

export type ProfileVisibilityCardGroupUpdateOneInlineInput = {
  /** Create and connect one ProfileVisibilityCardGroup document */
  create?: InputMaybe<ProfileVisibilityCardGroupCreateInput>;
  /** Delete currently connected ProfileVisibilityCardGroup document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single ProfileVisibilityCardGroup document */
  update?: InputMaybe<ProfileVisibilityCardGroupUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ProfileVisibilityCardGroup document */
  upsert?: InputMaybe<ProfileVisibilityCardGroupUpsertWithNestedWhereUniqueInput>;
};

export type ProfileVisibilityCardGroupUpdateWithNestedWhereUniqueAndPositionInput =
  {
    /** Document to update */
    data?: InputMaybe<ProfileVisibilityCardGroupUpdateInput>;
    /** Position in the list of existing component instances, will default to appending at the end of list */
    position?: InputMaybe<ConnectPositionInput>;
    /** Unique component instance search */
    where: ProfileVisibilityCardGroupWhereUniqueInput;
  };

export type ProfileVisibilityCardGroupUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ProfileVisibilityCardGroupUpdateInput;
  /** Unique document search */
  where: ProfileVisibilityCardGroupWhereUniqueInput;
};

export type ProfileVisibilityCardGroupUpsertInput = {
  /** Create document if it didn't exist */
  create: ProfileVisibilityCardGroupCreateInput;
  /** Update document if it exists */
  update: ProfileVisibilityCardGroupUpdateInput;
};

export type ProfileVisibilityCardGroupUpsertWithNestedWhereUniqueAndPositionInput =
  {
    /** Document to upsert */
    data?: InputMaybe<ProfileVisibilityCardGroupUpsertInput>;
    /** Position in the list of existing component instances, will default to appending at the end of list */
    position?: InputMaybe<ConnectPositionInput>;
    /** Unique component instance search */
    where: ProfileVisibilityCardGroupWhereUniqueInput;
  };

export type ProfileVisibilityCardGroupUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ProfileVisibilityCardGroupUpsertInput;
  /** Unique document search */
  where: ProfileVisibilityCardGroupWhereUniqueInput;
};

/** Identifies documents */
export type ProfileVisibilityCardGroupWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ProfileVisibilityCardGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ProfileVisibilityCardGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ProfileVisibilityCardGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
};

/** References ProfileVisibilityCardGroup record uniquely */
export type ProfileVisibilityCardGroupWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Promo banner for displaying promotional content */
export type PromotionalBanner = Entity & {
  __typename?: 'PromotionalBanner';
  background: BackgroundColor;
  /** Controls the logo that get displayed */
  brand: Brands;
  heading?: Maybe<CHeading>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<PromotionalBanner>;
  paragraph?: Maybe<CParagraph>;
  showLogo: Scalars['Boolean'];
  /** System stage field */
  stage: Stage;
};

/** Promo banner for displaying promotional content */
export type PromotionalBannerHeadingArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Promo banner for displaying promotional content */
export type PromotionalBannerLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

/** Promo banner for displaying promotional content */
export type PromotionalBannerParagraphArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PromotionalBannerConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: PromotionalBannerWhereUniqueInput;
};

/** A connection to a list of items. */
export type PromotionalBannerConnection = {
  __typename?: 'PromotionalBannerConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<PromotionalBannerEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type PromotionalBannerCreateInput = {
  /** background input for default locale (default) */
  background: BackgroundColor;
  /** brand input for default locale (default) */
  brand: Brands;
  heading?: InputMaybe<CHeadingCreateOneInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<PromotionalBannerCreateLocalizationsInput>;
  paragraph?: InputMaybe<CParagraphCreateOneInlineInput>;
  showLogo: Scalars['Boolean'];
};

export type PromotionalBannerCreateLocalizationDataInput = {
  background: BackgroundColor;
  brand: Brands;
};

export type PromotionalBannerCreateLocalizationInput = {
  /** Localization input */
  data: PromotionalBannerCreateLocalizationDataInput;
  locale: Locale;
};

export type PromotionalBannerCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<PromotionalBannerCreateLocalizationInput>>;
};

export type PromotionalBannerCreateManyInlineInput = {
  /** Create and connect multiple existing PromotionalBanner documents */
  create?: InputMaybe<Array<PromotionalBannerCreateInput>>;
};

export type PromotionalBannerCreateOneInlineInput = {
  /** Create and connect one PromotionalBanner document */
  create?: InputMaybe<PromotionalBannerCreateInput>;
};

export type PromotionalBannerCreateWithPositionInput = {
  /** Document to create */
  data: PromotionalBannerCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type PromotionalBannerEdge = {
  __typename?: 'PromotionalBannerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: PromotionalBanner;
};

/** Identifies documents */
export type PromotionalBannerManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PromotionalBannerWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PromotionalBannerWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PromotionalBannerWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  heading?: InputMaybe<CHeadingWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  paragraph?: InputMaybe<CParagraphWhereInput>;
  showLogo?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  showLogo_not?: InputMaybe<Scalars['Boolean']>;
};

export enum PromotionalBannerOrderByInput {
  BackgroundAsc = 'background_ASC',
  BackgroundDesc = 'background_DESC',
  BrandAsc = 'brand_ASC',
  BrandDesc = 'brand_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ShowLogoAsc = 'showLogo_ASC',
  ShowLogoDesc = 'showLogo_DESC',
}

export type PromotionalBannerParent = Page;

export type PromotionalBannerParentConnectInput = {
  Page?: InputMaybe<PageConnectInput>;
};

export type PromotionalBannerParentCreateInput = {
  Page?: InputMaybe<PageCreateInput>;
};

export type PromotionalBannerParentCreateManyInlineInput = {
  /** Connect multiple existing PromotionalBannerParent documents */
  connect?: InputMaybe<Array<PromotionalBannerParentWhereUniqueInput>>;
  /** Create and connect multiple existing PromotionalBannerParent documents */
  create?: InputMaybe<Array<PromotionalBannerParentCreateInput>>;
};

export type PromotionalBannerParentCreateOneInlineInput = {
  /** Connect one existing PromotionalBannerParent document */
  connect?: InputMaybe<PromotionalBannerParentWhereUniqueInput>;
  /** Create and connect one PromotionalBannerParent document */
  create?: InputMaybe<PromotionalBannerParentCreateInput>;
};

export type PromotionalBannerParentUpdateInput = {
  Page?: InputMaybe<PageUpdateInput>;
};

export type PromotionalBannerParentUpdateManyInlineInput = {
  /** Connect multiple existing PromotionalBannerParent documents */
  connect?: InputMaybe<Array<PromotionalBannerParentConnectInput>>;
  /** Create and connect multiple PromotionalBannerParent documents */
  create?: InputMaybe<Array<PromotionalBannerParentCreateInput>>;
  /** Delete multiple PromotionalBannerParent documents */
  delete?: InputMaybe<Array<PromotionalBannerParentWhereUniqueInput>>;
  /** Disconnect multiple PromotionalBannerParent documents */
  disconnect?: InputMaybe<Array<PromotionalBannerParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing PromotionalBannerParent documents */
  set?: InputMaybe<Array<PromotionalBannerParentWhereUniqueInput>>;
  /** Update multiple PromotionalBannerParent documents */
  update?: InputMaybe<
    Array<PromotionalBannerParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple PromotionalBannerParent documents */
  upsert?: InputMaybe<
    Array<PromotionalBannerParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type PromotionalBannerParentUpdateManyWithNestedWhereInput = {
  Page?: InputMaybe<PageUpdateManyWithNestedWhereInput>;
};

export type PromotionalBannerParentUpdateOneInlineInput = {
  /** Connect existing PromotionalBannerParent document */
  connect?: InputMaybe<PromotionalBannerParentWhereUniqueInput>;
  /** Create and connect one PromotionalBannerParent document */
  create?: InputMaybe<PromotionalBannerParentCreateInput>;
  /** Delete currently connected PromotionalBannerParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected PromotionalBannerParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single PromotionalBannerParent document */
  update?: InputMaybe<PromotionalBannerParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single PromotionalBannerParent document */
  upsert?: InputMaybe<PromotionalBannerParentUpsertWithNestedWhereUniqueInput>;
};

export type PromotionalBannerParentUpdateWithNestedWhereUniqueInput = {
  Page?: InputMaybe<PageUpdateWithNestedWhereUniqueInput>;
};

export type PromotionalBannerParentUpsertWithNestedWhereUniqueInput = {
  Page?: InputMaybe<PageUpsertWithNestedWhereUniqueInput>;
};

export type PromotionalBannerParentWhereInput = {
  Page?: InputMaybe<PageWhereInput>;
};

export type PromotionalBannerParentWhereUniqueInput = {
  Page?: InputMaybe<PageWhereUniqueInput>;
};

export type PromotionalBannerUpdateInput = {
  /** background input for default locale (default) */
  background?: InputMaybe<BackgroundColor>;
  /** brand input for default locale (default) */
  brand?: InputMaybe<Brands>;
  heading?: InputMaybe<CHeadingUpdateOneInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<PromotionalBannerUpdateLocalizationsInput>;
  paragraph?: InputMaybe<CParagraphUpdateOneInlineInput>;
  showLogo?: InputMaybe<Scalars['Boolean']>;
};

export type PromotionalBannerUpdateLocalizationDataInput = {
  background?: InputMaybe<BackgroundColor>;
  brand?: InputMaybe<Brands>;
};

export type PromotionalBannerUpdateLocalizationInput = {
  data: PromotionalBannerUpdateLocalizationDataInput;
  locale: Locale;
};

export type PromotionalBannerUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<PromotionalBannerCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<PromotionalBannerUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<PromotionalBannerUpsertLocalizationInput>>;
};

export type PromotionalBannerUpdateManyInlineInput = {
  /** Create and connect multiple PromotionalBanner component instances */
  create?: InputMaybe<Array<PromotionalBannerCreateWithPositionInput>>;
  /** Delete multiple PromotionalBanner documents */
  delete?: InputMaybe<Array<PromotionalBannerWhereUniqueInput>>;
  /** Update multiple PromotionalBanner component instances */
  update?: InputMaybe<
    Array<PromotionalBannerUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple PromotionalBanner component instances */
  upsert?: InputMaybe<
    Array<PromotionalBannerUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type PromotionalBannerUpdateManyInput = {
  /** background input for default locale (default) */
  background?: InputMaybe<BackgroundColor>;
  /** brand input for default locale (default) */
  brand?: InputMaybe<Brands>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<PromotionalBannerUpdateManyLocalizationsInput>;
  showLogo?: InputMaybe<Scalars['Boolean']>;
};

export type PromotionalBannerUpdateManyLocalizationDataInput = {
  background?: InputMaybe<BackgroundColor>;
  brand?: InputMaybe<Brands>;
};

export type PromotionalBannerUpdateManyLocalizationInput = {
  data: PromotionalBannerUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type PromotionalBannerUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<PromotionalBannerUpdateManyLocalizationInput>>;
};

export type PromotionalBannerUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: PromotionalBannerUpdateManyInput;
  /** Document search */
  where: PromotionalBannerWhereInput;
};

export type PromotionalBannerUpdateOneInlineInput = {
  /** Create and connect one PromotionalBanner document */
  create?: InputMaybe<PromotionalBannerCreateInput>;
  /** Delete currently connected PromotionalBanner document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single PromotionalBanner document */
  update?: InputMaybe<PromotionalBannerUpdateWithNestedWhereUniqueInput>;
  /** Upsert single PromotionalBanner document */
  upsert?: InputMaybe<PromotionalBannerUpsertWithNestedWhereUniqueInput>;
};

export type PromotionalBannerUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<PromotionalBannerUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: PromotionalBannerWhereUniqueInput;
};

export type PromotionalBannerUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: PromotionalBannerUpdateInput;
  /** Unique document search */
  where: PromotionalBannerWhereUniqueInput;
};

export type PromotionalBannerUpsertInput = {
  /** Create document if it didn't exist */
  create: PromotionalBannerCreateInput;
  /** Update document if it exists */
  update: PromotionalBannerUpdateInput;
};

export type PromotionalBannerUpsertLocalizationInput = {
  create: PromotionalBannerCreateLocalizationDataInput;
  locale: Locale;
  update: PromotionalBannerUpdateLocalizationDataInput;
};

export type PromotionalBannerUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<PromotionalBannerUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: PromotionalBannerWhereUniqueInput;
};

export type PromotionalBannerUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: PromotionalBannerUpsertInput;
  /** Unique document search */
  where: PromotionalBannerWhereUniqueInput;
};

/** Identifies documents */
export type PromotionalBannerWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PromotionalBannerWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PromotionalBannerWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PromotionalBannerWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  background?: InputMaybe<BackgroundColor>;
  /** All values that are contained in given list. */
  background_in?: InputMaybe<Array<InputMaybe<BackgroundColor>>>;
  /** Any other value that exists and is not equal to the given value. */
  background_not?: InputMaybe<BackgroundColor>;
  /** All values that are not contained in given list. */
  background_not_in?: InputMaybe<Array<InputMaybe<BackgroundColor>>>;
  brand?: InputMaybe<Brands>;
  /** All values that are contained in given list. */
  brand_in?: InputMaybe<Array<InputMaybe<Brands>>>;
  /** Any other value that exists and is not equal to the given value. */
  brand_not?: InputMaybe<Brands>;
  /** All values that are not contained in given list. */
  brand_not_in?: InputMaybe<Array<InputMaybe<Brands>>>;
  heading?: InputMaybe<CHeadingWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  paragraph?: InputMaybe<CParagraphWhereInput>;
  showLogo?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  showLogo_not?: InputMaybe<Scalars['Boolean']>;
};

/** References PromotionalBanner record uniquely */
export type PromotionalBannerWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type PublishLocaleInput = {
  /** Locales to publish */
  locale: Locale;
  /** Stages to publish selected locales to */
  stages: Array<Stage>;
};

export type Query = {
  __typename?: 'Query';
  /** Retrieve a single article */
  article?: Maybe<Article>;
  /** Retrieve document version */
  articleVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple articles */
  articles: Array<Article>;
  /** Retrieve multiple articles using the Relay connection interface */
  articlesConnection: ArticleConnection;
  /** Retrieve a single asset */
  asset?: Maybe<Asset>;
  /** Retrieve document version */
  assetVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple assets */
  assets: Array<Asset>;
  /** Retrieve multiple assets using the Relay connection interface */
  assetsConnection: AssetConnection;
  /** Retrieve a single author */
  author?: Maybe<Author>;
  /** Retrieve document version */
  authorVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple authors */
  authors: Array<Author>;
  /** Retrieve multiple authors using the Relay connection interface */
  authorsConnection: AuthorConnection;
  /** Retrieve a single bannerItem */
  bannerItem?: Maybe<BannerItem>;
  /** Retrieve document version */
  bannerItemVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple bannerItems */
  bannerItems: Array<BannerItem>;
  /** Retrieve multiple bannerItems using the Relay connection interface */
  bannerItemsConnection: BannerItemConnection;
  /** Retrieve a single cBlock */
  cBlock?: Maybe<CBlock>;
  /** Retrieve document version */
  cBlockVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple cBlocks */
  cBlocks: Array<CBlock>;
  /** Retrieve multiple cBlocks using the Relay connection interface */
  cBlocksConnection: CBlockConnection;
  /** Retrieve a single cButtonLink */
  cButtonLink?: Maybe<CButtonLink>;
  /** Retrieve document version */
  cButtonLinkVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple cButtonLinks */
  cButtonLinks: Array<CButtonLink>;
  /** Retrieve multiple cButtonLinks using the Relay connection interface */
  cButtonLinksConnection: CButtonLinkConnection;
  /** Retrieve a single cImageLink */
  cImageLink?: Maybe<CImageLink>;
  /** Retrieve document version */
  cImageLinkVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple cImageLinks */
  cImageLinks: Array<CImageLink>;
  /** Retrieve multiple cImageLinks using the Relay connection interface */
  cImageLinksConnection: CImageLinkConnection;
  /** Retrieve a single cTextLink */
  cTextLink?: Maybe<CTextLink>;
  /** Retrieve document version */
  cTextLinkVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple cTextLinks */
  cTextLinks: Array<CTextLink>;
  /** Retrieve multiple cTextLinks using the Relay connection interface */
  cTextLinksConnection: CTextLinkConnection;
  /** Retrieve multiple categories */
  categories: Array<Category>;
  /** Retrieve multiple categories using the Relay connection interface */
  categoriesConnection: CategoryConnection;
  /** Retrieve a single category */
  category?: Maybe<Category>;
  /** Retrieve document version */
  categoryVersion?: Maybe<DocumentVersion>;
  /** Retrieve a single dialogButton */
  dialogButton?: Maybe<DialogButton>;
  /** Retrieve document version */
  dialogButtonVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple dialogButtons */
  dialogButtons: Array<DialogButton>;
  /** Retrieve multiple dialogButtons using the Relay connection interface */
  dialogButtonsConnection: DialogButtonConnection;
  /** Retrieve a single documentItem */
  documentItem?: Maybe<DocumentItem>;
  /** Retrieve document version */
  documentItemVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple documentItems */
  documentItems: Array<DocumentItem>;
  /** Retrieve multiple documentItems using the Relay connection interface */
  documentItemsConnection: DocumentItemConnection;
  /** Retrieve a single embedAccordionGroup */
  embedAccordionGroup?: Maybe<EmbedAccordionGroup>;
  /** Retrieve document version */
  embedAccordionGroupVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple embedAccordionGroups */
  embedAccordionGroups: Array<EmbedAccordionGroup>;
  /** Retrieve multiple embedAccordionGroups using the Relay connection interface */
  embedAccordionGroupsConnection: EmbedAccordionGroupConnection;
  /** Retrieve a single embedCustomContent */
  embedCustomContent?: Maybe<EmbedCustomContent>;
  /** Retrieve document version */
  embedCustomContentVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple embedCustomContents */
  embedCustomContents: Array<EmbedCustomContent>;
  /** Retrieve multiple embedCustomContents using the Relay connection interface */
  embedCustomContentsConnection: EmbedCustomContentConnection;
  /** Retrieve a single embedSingleLineText */
  embedSingleLineText?: Maybe<EmbedSingleLineText>;
  /** Retrieve document version */
  embedSingleLineTextVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple embedSingleLineTexts */
  embedSingleLineTexts: Array<EmbedSingleLineText>;
  /** Retrieve multiple embedSingleLineTexts using the Relay connection interface */
  embedSingleLineTextsConnection: EmbedSingleLineTextConnection;
  /** Fetches an object given its ID */
  entities?: Maybe<Array<Entity>>;
  /** Retrieve a single externalUrl */
  externalUrl?: Maybe<ExternalUrl>;
  /** Retrieve document version */
  externalUrlVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple externalUrls */
  externalUrls: Array<ExternalUrl>;
  /** Retrieve multiple externalUrls using the Relay connection interface */
  externalUrlsConnection: ExternalUrlConnection;
  /** Retrieve a single form */
  form?: Maybe<Form>;
  /** Retrieve document version */
  formVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple forms */
  forms: Array<Form>;
  /** Retrieve multiple forms using the Relay connection interface */
  formsConnection: FormConnection;
  /** Retrieve a single managedContentBanner */
  managedContentBanner?: Maybe<ManagedContentBanner>;
  /** Retrieve document version */
  managedContentBannerVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple managedContentBanners */
  managedContentBanners: Array<ManagedContentBanner>;
  /** Retrieve multiple managedContentBanners using the Relay connection interface */
  managedContentBannersConnection: ManagedContentBannerConnection;
  /** Retrieve multiple migrationHistories */
  migrationHistories: Array<MigrationHistory>;
  /** Retrieve multiple migrationHistories using the Relay connection interface */
  migrationHistoriesConnection: MigrationHistoryConnection;
  /** Retrieve a single migrationHistory */
  migrationHistory?: Maybe<MigrationHistory>;
  /** Retrieve document version */
  migrationHistoryVersion?: Maybe<DocumentVersion>;
  /** Retrieve a single migrationState */
  migrationState?: Maybe<MigrationState>;
  /** Retrieve document version */
  migrationStateVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple migrationStates */
  migrationStates: Array<MigrationState>;
  /** Retrieve multiple migrationStates using the Relay connection interface */
  migrationStatesConnection: MigrationStateConnection;
  /** Fetches an object given its ID */
  node?: Maybe<Node>;
  /** Retrieve a single page */
  page?: Maybe<Page>;
  /** Retrieve document version */
  pageVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple pages */
  pages: Array<Page>;
  /** Retrieve multiple pages using the Relay connection interface */
  pagesConnection: PageConnection;
  /** Retrieve a single richTextEditor */
  richTextEditor?: Maybe<RichTextEditor>;
  /** Retrieve document version */
  richTextEditorVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple richTextEditors */
  richTextEditors: Array<RichTextEditor>;
  /** Retrieve multiple richTextEditors using the Relay connection interface */
  richTextEditorsConnection: RichTextEditorConnection;
  /** Retrieve a single scheduledOperation */
  scheduledOperation?: Maybe<ScheduledOperation>;
  /** Retrieve multiple scheduledOperations */
  scheduledOperations: Array<ScheduledOperation>;
  /** Retrieve multiple scheduledOperations using the Relay connection interface */
  scheduledOperationsConnection: ScheduledOperationConnection;
  /** Retrieve a single scheduledRelease */
  scheduledRelease?: Maybe<ScheduledRelease>;
  /** Retrieve multiple scheduledReleases */
  scheduledReleases: Array<ScheduledRelease>;
  /** Retrieve multiple scheduledReleases using the Relay connection interface */
  scheduledReleasesConnection: ScheduledReleaseConnection;
  /** Retrieve a single section */
  section?: Maybe<Section>;
  /** Retrieve document version */
  sectionVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple sections */
  sections: Array<Section>;
  /** Retrieve multiple sections using the Relay connection interface */
  sectionsConnection: SectionConnection;
  /** Retrieve a single site */
  site?: Maybe<Site>;
  /** Retrieve a single siteUsersConnection */
  siteUsersConnection?: Maybe<SiteUsersConnection>;
  /** Retrieve document version */
  siteUsersConnectionVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple siteUsersConnections */
  siteUsersConnections: Array<SiteUsersConnection>;
  /** Retrieve multiple siteUsersConnections using the Relay connection interface */
  siteUsersConnectionsConnection: SiteUsersConnectionConnection;
  /** Retrieve document version */
  siteVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple sites */
  sites: Array<Site>;
  /** Retrieve multiple sites using the Relay connection interface */
  sitesConnection: SiteConnection;
  /** Retrieve a single topic */
  topic?: Maybe<Topic>;
  /** Retrieve document version */
  topicVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple topics */
  topics: Array<Topic>;
  /** Retrieve multiple topics using the Relay connection interface */
  topicsConnection: TopicConnection;
  /** Retrieve a single user */
  user?: Maybe<User>;
  /** Retrieve multiple users */
  users: Array<User>;
  /** Retrieve multiple users using the Relay connection interface */
  usersConnection: UserConnection;
};

export type QueryArticleArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ArticleWhereUniqueInput;
};

export type QueryArticleVersionArgs = {
  where: VersionWhereInput;
};

export type QueryArticlesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ArticleOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<ArticleWhereInput>;
};

export type QueryArticlesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ArticleOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<ArticleWhereInput>;
};

export type QueryAssetArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: AssetWhereUniqueInput;
};

export type QueryAssetVersionArgs = {
  where: VersionWhereInput;
};

export type QueryAssetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<AssetWhereInput>;
};

export type QueryAssetsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<AssetWhereInput>;
};

export type QueryAuthorArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: AuthorWhereUniqueInput;
};

export type QueryAuthorVersionArgs = {
  where: VersionWhereInput;
};

export type QueryAuthorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<AuthorOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<AuthorWhereInput>;
};

export type QueryAuthorsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<AuthorOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<AuthorWhereInput>;
};

export type QueryBannerItemArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: BannerItemWhereUniqueInput;
};

export type QueryBannerItemVersionArgs = {
  where: VersionWhereInput;
};

export type QueryBannerItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<BannerItemOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<BannerItemWhereInput>;
};

export type QueryBannerItemsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<BannerItemOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<BannerItemWhereInput>;
};

export type QueryCBlockArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: CBlockWhereUniqueInput;
};

export type QueryCBlockVersionArgs = {
  where: VersionWhereInput;
};

export type QueryCBlocksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<CBlockOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<CBlockWhereInput>;
};

export type QueryCBlocksConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<CBlockOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<CBlockWhereInput>;
};

export type QueryCButtonLinkArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: CButtonLinkWhereUniqueInput;
};

export type QueryCButtonLinkVersionArgs = {
  where: VersionWhereInput;
};

export type QueryCButtonLinksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<CButtonLinkOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<CButtonLinkWhereInput>;
};

export type QueryCButtonLinksConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<CButtonLinkOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<CButtonLinkWhereInput>;
};

export type QueryCImageLinkArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: CImageLinkWhereUniqueInput;
};

export type QueryCImageLinkVersionArgs = {
  where: VersionWhereInput;
};

export type QueryCImageLinksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<CImageLinkOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<CImageLinkWhereInput>;
};

export type QueryCImageLinksConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<CImageLinkOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<CImageLinkWhereInput>;
};

export type QueryCTextLinkArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: CTextLinkWhereUniqueInput;
};

export type QueryCTextLinkVersionArgs = {
  where: VersionWhereInput;
};

export type QueryCTextLinksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<CTextLinkOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<CTextLinkWhereInput>;
};

export type QueryCTextLinksConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<CTextLinkOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<CTextLinkWhereInput>;
};

export type QueryCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<CategoryOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<CategoryWhereInput>;
};

export type QueryCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<CategoryOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<CategoryWhereInput>;
};

export type QueryCategoryArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: CategoryWhereUniqueInput;
};

export type QueryCategoryVersionArgs = {
  where: VersionWhereInput;
};

export type QueryDialogButtonArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: DialogButtonWhereUniqueInput;
};

export type QueryDialogButtonVersionArgs = {
  where: VersionWhereInput;
};

export type QueryDialogButtonsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<DialogButtonOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<DialogButtonWhereInput>;
};

export type QueryDialogButtonsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<DialogButtonOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<DialogButtonWhereInput>;
};

export type QueryDocumentItemArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: DocumentItemWhereUniqueInput;
};

export type QueryDocumentItemVersionArgs = {
  where: VersionWhereInput;
};

export type QueryDocumentItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<DocumentItemOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<DocumentItemWhereInput>;
};

export type QueryDocumentItemsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<DocumentItemOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<DocumentItemWhereInput>;
};

export type QueryEmbedAccordionGroupArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: EmbedAccordionGroupWhereUniqueInput;
};

export type QueryEmbedAccordionGroupVersionArgs = {
  where: VersionWhereInput;
};

export type QueryEmbedAccordionGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<EmbedAccordionGroupOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<EmbedAccordionGroupWhereInput>;
};

export type QueryEmbedAccordionGroupsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<EmbedAccordionGroupOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<EmbedAccordionGroupWhereInput>;
};

export type QueryEmbedCustomContentArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: EmbedCustomContentWhereUniqueInput;
};

export type QueryEmbedCustomContentVersionArgs = {
  where: VersionWhereInput;
};

export type QueryEmbedCustomContentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<EmbedCustomContentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<EmbedCustomContentWhereInput>;
};

export type QueryEmbedCustomContentsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<EmbedCustomContentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<EmbedCustomContentWhereInput>;
};

export type QueryEmbedSingleLineTextArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: EmbedSingleLineTextWhereUniqueInput;
};

export type QueryEmbedSingleLineTextVersionArgs = {
  where: VersionWhereInput;
};

export type QueryEmbedSingleLineTextsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<EmbedSingleLineTextOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<EmbedSingleLineTextWhereInput>;
};

export type QueryEmbedSingleLineTextsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<EmbedSingleLineTextOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<EmbedSingleLineTextWhereInput>;
};

export type QueryEntitiesArgs = {
  locales?: InputMaybe<Array<Locale>>;
  where: Array<EntityWhereInput>;
};

export type QueryExternalUrlArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ExternalUrlWhereUniqueInput;
};

export type QueryExternalUrlVersionArgs = {
  where: VersionWhereInput;
};

export type QueryExternalUrlsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ExternalUrlOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<ExternalUrlWhereInput>;
};

export type QueryExternalUrlsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ExternalUrlOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<ExternalUrlWhereInput>;
};

export type QueryFormArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: FormWhereUniqueInput;
};

export type QueryFormVersionArgs = {
  where: VersionWhereInput;
};

export type QueryFormsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<FormOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<FormWhereInput>;
};

export type QueryFormsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<FormOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<FormWhereInput>;
};

export type QueryManagedContentBannerArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ManagedContentBannerWhereUniqueInput;
};

export type QueryManagedContentBannerVersionArgs = {
  where: VersionWhereInput;
};

export type QueryManagedContentBannersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ManagedContentBannerOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<ManagedContentBannerWhereInput>;
};

export type QueryManagedContentBannersConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ManagedContentBannerOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<ManagedContentBannerWhereInput>;
};

export type QueryMigrationHistoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MigrationHistoryOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<MigrationHistoryWhereInput>;
};

export type QueryMigrationHistoriesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MigrationHistoryOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<MigrationHistoryWhereInput>;
};

export type QueryMigrationHistoryArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: MigrationHistoryWhereUniqueInput;
};

export type QueryMigrationHistoryVersionArgs = {
  where: VersionWhereInput;
};

export type QueryMigrationStateArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: MigrationStateWhereUniqueInput;
};

export type QueryMigrationStateVersionArgs = {
  where: VersionWhereInput;
};

export type QueryMigrationStatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MigrationStateOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<MigrationStateWhereInput>;
};

export type QueryMigrationStatesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MigrationStateOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<MigrationStateWhereInput>;
};

export type QueryNodeArgs = {
  id: Scalars['ID'];
  locales?: Array<Locale>;
  stage?: Stage;
};

export type QueryPageArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: PageWhereUniqueInput;
};

export type QueryPageVersionArgs = {
  where: VersionWhereInput;
};

export type QueryPagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<PageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<PageWhereInput>;
};

export type QueryPagesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<PageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<PageWhereInput>;
};

export type QueryRichTextEditorArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: RichTextEditorWhereUniqueInput;
};

export type QueryRichTextEditorVersionArgs = {
  where: VersionWhereInput;
};

export type QueryRichTextEditorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<RichTextEditorOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<RichTextEditorWhereInput>;
};

export type QueryRichTextEditorsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<RichTextEditorOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<RichTextEditorWhereInput>;
};

export type QueryScheduledOperationArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ScheduledOperationWhereUniqueInput;
};

export type QueryScheduledOperationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ScheduledOperationOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type QueryScheduledOperationsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ScheduledOperationOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type QueryScheduledReleaseArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ScheduledReleaseWhereUniqueInput;
};

export type QueryScheduledReleasesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ScheduledReleaseOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<ScheduledReleaseWhereInput>;
};

export type QueryScheduledReleasesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ScheduledReleaseOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<ScheduledReleaseWhereInput>;
};

export type QuerySectionArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: SectionWhereUniqueInput;
};

export type QuerySectionVersionArgs = {
  where: VersionWhereInput;
};

export type QuerySectionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<SectionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<SectionWhereInput>;
};

export type QuerySectionsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<SectionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<SectionWhereInput>;
};

export type QuerySiteArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: SiteWhereUniqueInput;
};

export type QuerySiteUsersConnectionArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: SiteUsersConnectionWhereUniqueInput;
};

export type QuerySiteUsersConnectionVersionArgs = {
  where: VersionWhereInput;
};

export type QuerySiteUsersConnectionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<SiteUsersConnectionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<SiteUsersConnectionWhereInput>;
};

export type QuerySiteUsersConnectionsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<SiteUsersConnectionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<SiteUsersConnectionWhereInput>;
};

export type QuerySiteVersionArgs = {
  where: VersionWhereInput;
};

export type QuerySitesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<SiteOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<SiteWhereInput>;
};

export type QuerySitesConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<SiteOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<SiteWhereInput>;
};

export type QueryTopicArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: TopicWhereUniqueInput;
};

export type QueryTopicVersionArgs = {
  where: VersionWhereInput;
};

export type QueryTopicsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<TopicOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<TopicWhereInput>;
};

export type QueryTopicsConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<TopicOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<TopicWhereInput>;
};

export type QueryUserArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: UserWhereUniqueInput;
};

export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<UserOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<UserWhereInput>;
};

export type QueryUsersConnectionArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<UserOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  stage?: Stage;
  where?: InputMaybe<UserWhereInput>;
};

/** Representing a RGBA color value: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba() */
export type Rgba = {
  __typename?: 'RGBA';
  a: Scalars['RGBATransparency'];
  b: Scalars['RGBAHue'];
  g: Scalars['RGBAHue'];
  r: Scalars['RGBAHue'];
};

/** Input type representing a RGBA color value: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba() */
export type RgbaInput = {
  a: Scalars['RGBATransparency'];
  b: Scalars['RGBAHue'];
  g: Scalars['RGBAHue'];
  r: Scalars['RGBAHue'];
};

export type ResponsiveColumnValue = Entity & {
  __typename?: 'ResponsiveColumnValue';
  desktop: Scalars['Int'];
  /** The unique identifier */
  id: Scalars['ID'];
  mobile: Scalars['Int'];
  /** System stage field */
  stage: Stage;
  tablet: Scalars['Int'];
  wide: Scalars['Int'];
};

export type ResponsiveColumnValueConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ResponsiveColumnValueWhereUniqueInput;
};

/** A connection to a list of items. */
export type ResponsiveColumnValueConnection = {
  __typename?: 'ResponsiveColumnValueConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ResponsiveColumnValueEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ResponsiveColumnValueCreateInput = {
  desktop: Scalars['Int'];
  mobile: Scalars['Int'];
  tablet: Scalars['Int'];
  wide: Scalars['Int'];
};

export type ResponsiveColumnValueCreateManyInlineInput = {
  /** Create and connect multiple existing ResponsiveColumnValue documents */
  create?: InputMaybe<Array<ResponsiveColumnValueCreateInput>>;
};

export type ResponsiveColumnValueCreateOneInlineInput = {
  /** Create and connect one ResponsiveColumnValue document */
  create?: InputMaybe<ResponsiveColumnValueCreateInput>;
};

export type ResponsiveColumnValueCreateWithPositionInput = {
  /** Document to create */
  data: ResponsiveColumnValueCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type ResponsiveColumnValueEdge = {
  __typename?: 'ResponsiveColumnValueEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ResponsiveColumnValue;
};

/** Identifies documents */
export type ResponsiveColumnValueManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ResponsiveColumnValueWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ResponsiveColumnValueWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ResponsiveColumnValueWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  desktop?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  desktop_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  desktop_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  desktop_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** All values less than the given value. */
  desktop_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  desktop_lte?: InputMaybe<Scalars['Int']>;
  /** Any other value that exists and is not equal to the given value. */
  desktop_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  desktop_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  mobile?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  mobile_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  mobile_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  mobile_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** All values less than the given value. */
  mobile_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  mobile_lte?: InputMaybe<Scalars['Int']>;
  /** Any other value that exists and is not equal to the given value. */
  mobile_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  mobile_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  tablet?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  tablet_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  tablet_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  tablet_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** All values less than the given value. */
  tablet_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  tablet_lte?: InputMaybe<Scalars['Int']>;
  /** Any other value that exists and is not equal to the given value. */
  tablet_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  tablet_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  wide?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  wide_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  wide_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  wide_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** All values less than the given value. */
  wide_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  wide_lte?: InputMaybe<Scalars['Int']>;
  /** Any other value that exists and is not equal to the given value. */
  wide_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  wide_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export enum ResponsiveColumnValueOrderByInput {
  DesktopAsc = 'desktop_ASC',
  DesktopDesc = 'desktop_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MobileAsc = 'mobile_ASC',
  MobileDesc = 'mobile_DESC',
  TabletAsc = 'tablet_ASC',
  TabletDesc = 'tablet_DESC',
  WideAsc = 'wide_ASC',
  WideDesc = 'wide_DESC',
}

export type ResponsiveColumnValueParent = CTile | LatestArticlesByCategory;

export type ResponsiveColumnValueParentConnectInput = {
  CTile?: InputMaybe<CTileConnectInput>;
  LatestArticlesByCategory?: InputMaybe<LatestArticlesByCategoryConnectInput>;
};

export type ResponsiveColumnValueParentCreateInput = {
  CTile?: InputMaybe<CTileCreateInput>;
  LatestArticlesByCategory?: InputMaybe<LatestArticlesByCategoryCreateInput>;
};

export type ResponsiveColumnValueParentCreateManyInlineInput = {
  /** Create and connect multiple existing ResponsiveColumnValueParent documents */
  create?: InputMaybe<Array<ResponsiveColumnValueParentCreateInput>>;
};

export type ResponsiveColumnValueParentCreateOneInlineInput = {
  /** Create and connect one ResponsiveColumnValueParent document */
  create?: InputMaybe<ResponsiveColumnValueParentCreateInput>;
};

export type ResponsiveColumnValueParentCreateWithPositionInput = {
  CTile?: InputMaybe<CTileCreateWithPositionInput>;
  LatestArticlesByCategory?: InputMaybe<LatestArticlesByCategoryCreateWithPositionInput>;
};

export type ResponsiveColumnValueParentUpdateInput = {
  CTile?: InputMaybe<CTileUpdateInput>;
  LatestArticlesByCategory?: InputMaybe<LatestArticlesByCategoryUpdateInput>;
};

export type ResponsiveColumnValueParentUpdateManyInlineInput = {
  /** Create and connect multiple ResponsiveColumnValueParent component instances */
  create?: InputMaybe<
    Array<ResponsiveColumnValueParentCreateWithPositionInput>
  >;
  /** Delete multiple ResponsiveColumnValueParent documents */
  delete?: InputMaybe<Array<ResponsiveColumnValueParentWhereUniqueInput>>;
  /** Update multiple ResponsiveColumnValueParent component instances */
  update?: InputMaybe<
    Array<ResponsiveColumnValueParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple ResponsiveColumnValueParent component instances */
  upsert?: InputMaybe<
    Array<ResponsiveColumnValueParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type ResponsiveColumnValueParentUpdateManyWithNestedWhereInput = {
  CTile?: InputMaybe<CTileUpdateManyWithNestedWhereInput>;
  LatestArticlesByCategory?: InputMaybe<LatestArticlesByCategoryUpdateManyWithNestedWhereInput>;
};

export type ResponsiveColumnValueParentUpdateOneInlineInput = {
  /** Create and connect one ResponsiveColumnValueParent document */
  create?: InputMaybe<ResponsiveColumnValueParentCreateInput>;
  /** Delete currently connected ResponsiveColumnValueParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single ResponsiveColumnValueParent document */
  update?: InputMaybe<ResponsiveColumnValueParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ResponsiveColumnValueParent document */
  upsert?: InputMaybe<ResponsiveColumnValueParentUpsertWithNestedWhereUniqueInput>;
};

export type ResponsiveColumnValueParentUpdateWithNestedWhereUniqueAndPositionInput =
  {
    CTile?: InputMaybe<CTileUpdateWithNestedWhereUniqueAndPositionInput>;
    LatestArticlesByCategory?: InputMaybe<LatestArticlesByCategoryUpdateWithNestedWhereUniqueAndPositionInput>;
  };

export type ResponsiveColumnValueParentUpdateWithNestedWhereUniqueInput = {
  CTile?: InputMaybe<CTileUpdateWithNestedWhereUniqueInput>;
  LatestArticlesByCategory?: InputMaybe<LatestArticlesByCategoryUpdateWithNestedWhereUniqueInput>;
};

export type ResponsiveColumnValueParentUpsertWithNestedWhereUniqueAndPositionInput =
  {
    CTile?: InputMaybe<CTileUpsertWithNestedWhereUniqueAndPositionInput>;
    LatestArticlesByCategory?: InputMaybe<LatestArticlesByCategoryUpsertWithNestedWhereUniqueAndPositionInput>;
  };

export type ResponsiveColumnValueParentUpsertWithNestedWhereUniqueInput = {
  CTile?: InputMaybe<CTileUpsertWithNestedWhereUniqueInput>;
  LatestArticlesByCategory?: InputMaybe<LatestArticlesByCategoryUpsertWithNestedWhereUniqueInput>;
};

export type ResponsiveColumnValueParentWhereInput = {
  CTile?: InputMaybe<CTileWhereInput>;
  LatestArticlesByCategory?: InputMaybe<LatestArticlesByCategoryWhereInput>;
};

export type ResponsiveColumnValueParentWhereUniqueInput = {
  CTile?: InputMaybe<CTileWhereUniqueInput>;
  LatestArticlesByCategory?: InputMaybe<LatestArticlesByCategoryWhereUniqueInput>;
};

export type ResponsiveColumnValueUpdateInput = {
  desktop?: InputMaybe<Scalars['Int']>;
  mobile?: InputMaybe<Scalars['Int']>;
  tablet?: InputMaybe<Scalars['Int']>;
  wide?: InputMaybe<Scalars['Int']>;
};

export type ResponsiveColumnValueUpdateManyInlineInput = {
  /** Create and connect multiple ResponsiveColumnValue component instances */
  create?: InputMaybe<Array<ResponsiveColumnValueCreateWithPositionInput>>;
  /** Delete multiple ResponsiveColumnValue documents */
  delete?: InputMaybe<Array<ResponsiveColumnValueWhereUniqueInput>>;
  /** Update multiple ResponsiveColumnValue component instances */
  update?: InputMaybe<
    Array<ResponsiveColumnValueUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple ResponsiveColumnValue component instances */
  upsert?: InputMaybe<
    Array<ResponsiveColumnValueUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type ResponsiveColumnValueUpdateManyInput = {
  desktop?: InputMaybe<Scalars['Int']>;
  mobile?: InputMaybe<Scalars['Int']>;
  tablet?: InputMaybe<Scalars['Int']>;
  wide?: InputMaybe<Scalars['Int']>;
};

export type ResponsiveColumnValueUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ResponsiveColumnValueUpdateManyInput;
  /** Document search */
  where: ResponsiveColumnValueWhereInput;
};

export type ResponsiveColumnValueUpdateOneInlineInput = {
  /** Create and connect one ResponsiveColumnValue document */
  create?: InputMaybe<ResponsiveColumnValueCreateInput>;
  /** Delete currently connected ResponsiveColumnValue document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single ResponsiveColumnValue document */
  update?: InputMaybe<ResponsiveColumnValueUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ResponsiveColumnValue document */
  upsert?: InputMaybe<ResponsiveColumnValueUpsertWithNestedWhereUniqueInput>;
};

export type ResponsiveColumnValueUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<ResponsiveColumnValueUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: ResponsiveColumnValueWhereUniqueInput;
};

export type ResponsiveColumnValueUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ResponsiveColumnValueUpdateInput;
  /** Unique document search */
  where: ResponsiveColumnValueWhereUniqueInput;
};

export type ResponsiveColumnValueUpsertInput = {
  /** Create document if it didn't exist */
  create: ResponsiveColumnValueCreateInput;
  /** Update document if it exists */
  update: ResponsiveColumnValueUpdateInput;
};

export type ResponsiveColumnValueUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<ResponsiveColumnValueUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: ResponsiveColumnValueWhereUniqueInput;
};

export type ResponsiveColumnValueUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ResponsiveColumnValueUpsertInput;
  /** Unique document search */
  where: ResponsiveColumnValueWhereUniqueInput;
};

/** Identifies documents */
export type ResponsiveColumnValueWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ResponsiveColumnValueWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ResponsiveColumnValueWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ResponsiveColumnValueWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  desktop?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  desktop_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  desktop_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  desktop_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** All values less than the given value. */
  desktop_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  desktop_lte?: InputMaybe<Scalars['Int']>;
  /** Any other value that exists and is not equal to the given value. */
  desktop_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  desktop_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  mobile?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  mobile_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  mobile_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  mobile_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** All values less than the given value. */
  mobile_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  mobile_lte?: InputMaybe<Scalars['Int']>;
  /** Any other value that exists and is not equal to the given value. */
  mobile_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  mobile_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  tablet?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  tablet_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  tablet_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  tablet_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** All values less than the given value. */
  tablet_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  tablet_lte?: InputMaybe<Scalars['Int']>;
  /** Any other value that exists and is not equal to the given value. */
  tablet_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  tablet_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  wide?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  wide_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  wide_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  wide_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** All values less than the given value. */
  wide_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  wide_lte?: InputMaybe<Scalars['Int']>;
  /** Any other value that exists and is not equal to the given value. */
  wide_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  wide_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

/** References ResponsiveColumnValue record uniquely */
export type ResponsiveColumnValueWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type ResponsiveSpace = Entity & {
  __typename?: 'ResponsiveSpace';
  desktop: BraidSizes;
  /** The unique identifier */
  id: Scalars['ID'];
  mobile: BraidSizes;
  /** System stage field */
  stage: Stage;
  tablet: BraidSizes;
  wide: BraidSizes;
};

export type ResponsiveSpaceConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ResponsiveSpaceWhereUniqueInput;
};

/** A connection to a list of items. */
export type ResponsiveSpaceConnection = {
  __typename?: 'ResponsiveSpaceConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ResponsiveSpaceEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ResponsiveSpaceCreateInput = {
  desktop: BraidSizes;
  mobile: BraidSizes;
  tablet: BraidSizes;
  wide: BraidSizes;
};

export type ResponsiveSpaceCreateManyInlineInput = {
  /** Create and connect multiple existing ResponsiveSpace documents */
  create?: InputMaybe<Array<ResponsiveSpaceCreateInput>>;
};

export type ResponsiveSpaceCreateOneInlineInput = {
  /** Create and connect one ResponsiveSpace document */
  create?: InputMaybe<ResponsiveSpaceCreateInput>;
};

export type ResponsiveSpaceCreateWithPositionInput = {
  /** Document to create */
  data: ResponsiveSpaceCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type ResponsiveSpaceEdge = {
  __typename?: 'ResponsiveSpaceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ResponsiveSpace;
};

/** Identifies documents */
export type ResponsiveSpaceManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ResponsiveSpaceWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ResponsiveSpaceWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ResponsiveSpaceWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  desktop?: InputMaybe<BraidSizes>;
  /** All values that are contained in given list. */
  desktop_in?: InputMaybe<Array<InputMaybe<BraidSizes>>>;
  /** Any other value that exists and is not equal to the given value. */
  desktop_not?: InputMaybe<BraidSizes>;
  /** All values that are not contained in given list. */
  desktop_not_in?: InputMaybe<Array<InputMaybe<BraidSizes>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  mobile?: InputMaybe<BraidSizes>;
  /** All values that are contained in given list. */
  mobile_in?: InputMaybe<Array<InputMaybe<BraidSizes>>>;
  /** Any other value that exists and is not equal to the given value. */
  mobile_not?: InputMaybe<BraidSizes>;
  /** All values that are not contained in given list. */
  mobile_not_in?: InputMaybe<Array<InputMaybe<BraidSizes>>>;
  tablet?: InputMaybe<BraidSizes>;
  /** All values that are contained in given list. */
  tablet_in?: InputMaybe<Array<InputMaybe<BraidSizes>>>;
  /** Any other value that exists and is not equal to the given value. */
  tablet_not?: InputMaybe<BraidSizes>;
  /** All values that are not contained in given list. */
  tablet_not_in?: InputMaybe<Array<InputMaybe<BraidSizes>>>;
  wide?: InputMaybe<BraidSizes>;
  /** All values that are contained in given list. */
  wide_in?: InputMaybe<Array<InputMaybe<BraidSizes>>>;
  /** Any other value that exists and is not equal to the given value. */
  wide_not?: InputMaybe<BraidSizes>;
  /** All values that are not contained in given list. */
  wide_not_in?: InputMaybe<Array<InputMaybe<BraidSizes>>>;
};

export enum ResponsiveSpaceOrderByInput {
  DesktopAsc = 'desktop_ASC',
  DesktopDesc = 'desktop_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MobileAsc = 'mobile_ASC',
  MobileDesc = 'mobile_DESC',
  TabletAsc = 'tablet_ASC',
  TabletDesc = 'tablet_DESC',
  WideAsc = 'wide_ASC',
  WideDesc = 'wide_DESC',
}

export type ResponsiveSpaceParent = CHeading;

export type ResponsiveSpaceParentConnectInput = {
  CHeading?: InputMaybe<CHeadingConnectInput>;
};

export type ResponsiveSpaceParentCreateInput = {
  CHeading?: InputMaybe<CHeadingCreateInput>;
};

export type ResponsiveSpaceParentCreateManyInlineInput = {
  /** Create and connect multiple existing ResponsiveSpaceParent documents */
  create?: InputMaybe<Array<ResponsiveSpaceParentCreateInput>>;
};

export type ResponsiveSpaceParentCreateOneInlineInput = {
  /** Create and connect one ResponsiveSpaceParent document */
  create?: InputMaybe<ResponsiveSpaceParentCreateInput>;
};

export type ResponsiveSpaceParentCreateWithPositionInput = {
  CHeading?: InputMaybe<CHeadingCreateWithPositionInput>;
};

export type ResponsiveSpaceParentUpdateInput = {
  CHeading?: InputMaybe<CHeadingUpdateInput>;
};

export type ResponsiveSpaceParentUpdateManyInlineInput = {
  /** Create and connect multiple ResponsiveSpaceParent component instances */
  create?: InputMaybe<Array<ResponsiveSpaceParentCreateWithPositionInput>>;
  /** Delete multiple ResponsiveSpaceParent documents */
  delete?: InputMaybe<Array<ResponsiveSpaceParentWhereUniqueInput>>;
  /** Update multiple ResponsiveSpaceParent component instances */
  update?: InputMaybe<
    Array<ResponsiveSpaceParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple ResponsiveSpaceParent component instances */
  upsert?: InputMaybe<
    Array<ResponsiveSpaceParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type ResponsiveSpaceParentUpdateManyWithNestedWhereInput = {
  CHeading?: InputMaybe<CHeadingUpdateManyWithNestedWhereInput>;
};

export type ResponsiveSpaceParentUpdateOneInlineInput = {
  /** Create and connect one ResponsiveSpaceParent document */
  create?: InputMaybe<ResponsiveSpaceParentCreateInput>;
  /** Delete currently connected ResponsiveSpaceParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single ResponsiveSpaceParent document */
  update?: InputMaybe<ResponsiveSpaceParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ResponsiveSpaceParent document */
  upsert?: InputMaybe<ResponsiveSpaceParentUpsertWithNestedWhereUniqueInput>;
};

export type ResponsiveSpaceParentUpdateWithNestedWhereUniqueAndPositionInput = {
  CHeading?: InputMaybe<CHeadingUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type ResponsiveSpaceParentUpdateWithNestedWhereUniqueInput = {
  CHeading?: InputMaybe<CHeadingUpdateWithNestedWhereUniqueInput>;
};

export type ResponsiveSpaceParentUpsertWithNestedWhereUniqueAndPositionInput = {
  CHeading?: InputMaybe<CHeadingUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type ResponsiveSpaceParentUpsertWithNestedWhereUniqueInput = {
  CHeading?: InputMaybe<CHeadingUpsertWithNestedWhereUniqueInput>;
};

export type ResponsiveSpaceParentWhereInput = {
  CHeading?: InputMaybe<CHeadingWhereInput>;
};

export type ResponsiveSpaceParentWhereUniqueInput = {
  CHeading?: InputMaybe<CHeadingWhereUniqueInput>;
};

export type ResponsiveSpaceUpdateInput = {
  desktop?: InputMaybe<BraidSizes>;
  mobile?: InputMaybe<BraidSizes>;
  tablet?: InputMaybe<BraidSizes>;
  wide?: InputMaybe<BraidSizes>;
};

export type ResponsiveSpaceUpdateManyInlineInput = {
  /** Create and connect multiple ResponsiveSpace component instances */
  create?: InputMaybe<Array<ResponsiveSpaceCreateWithPositionInput>>;
  /** Delete multiple ResponsiveSpace documents */
  delete?: InputMaybe<Array<ResponsiveSpaceWhereUniqueInput>>;
  /** Update multiple ResponsiveSpace component instances */
  update?: InputMaybe<
    Array<ResponsiveSpaceUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple ResponsiveSpace component instances */
  upsert?: InputMaybe<
    Array<ResponsiveSpaceUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type ResponsiveSpaceUpdateManyInput = {
  desktop?: InputMaybe<BraidSizes>;
  mobile?: InputMaybe<BraidSizes>;
  tablet?: InputMaybe<BraidSizes>;
  wide?: InputMaybe<BraidSizes>;
};

export type ResponsiveSpaceUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ResponsiveSpaceUpdateManyInput;
  /** Document search */
  where: ResponsiveSpaceWhereInput;
};

export type ResponsiveSpaceUpdateOneInlineInput = {
  /** Create and connect one ResponsiveSpace document */
  create?: InputMaybe<ResponsiveSpaceCreateInput>;
  /** Delete currently connected ResponsiveSpace document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single ResponsiveSpace document */
  update?: InputMaybe<ResponsiveSpaceUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ResponsiveSpace document */
  upsert?: InputMaybe<ResponsiveSpaceUpsertWithNestedWhereUniqueInput>;
};

export type ResponsiveSpaceUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<ResponsiveSpaceUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: ResponsiveSpaceWhereUniqueInput;
};

export type ResponsiveSpaceUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ResponsiveSpaceUpdateInput;
  /** Unique document search */
  where: ResponsiveSpaceWhereUniqueInput;
};

export type ResponsiveSpaceUpsertInput = {
  /** Create document if it didn't exist */
  create: ResponsiveSpaceCreateInput;
  /** Update document if it exists */
  update: ResponsiveSpaceUpdateInput;
};

export type ResponsiveSpaceUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<ResponsiveSpaceUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: ResponsiveSpaceWhereUniqueInput;
};

export type ResponsiveSpaceUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ResponsiveSpaceUpsertInput;
  /** Unique document search */
  where: ResponsiveSpaceWhereUniqueInput;
};

/** Identifies documents */
export type ResponsiveSpaceWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ResponsiveSpaceWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ResponsiveSpaceWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ResponsiveSpaceWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  desktop?: InputMaybe<BraidSizes>;
  /** All values that are contained in given list. */
  desktop_in?: InputMaybe<Array<InputMaybe<BraidSizes>>>;
  /** Any other value that exists and is not equal to the given value. */
  desktop_not?: InputMaybe<BraidSizes>;
  /** All values that are not contained in given list. */
  desktop_not_in?: InputMaybe<Array<InputMaybe<BraidSizes>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  mobile?: InputMaybe<BraidSizes>;
  /** All values that are contained in given list. */
  mobile_in?: InputMaybe<Array<InputMaybe<BraidSizes>>>;
  /** Any other value that exists and is not equal to the given value. */
  mobile_not?: InputMaybe<BraidSizes>;
  /** All values that are not contained in given list. */
  mobile_not_in?: InputMaybe<Array<InputMaybe<BraidSizes>>>;
  tablet?: InputMaybe<BraidSizes>;
  /** All values that are contained in given list. */
  tablet_in?: InputMaybe<Array<InputMaybe<BraidSizes>>>;
  /** Any other value that exists and is not equal to the given value. */
  tablet_not?: InputMaybe<BraidSizes>;
  /** All values that are not contained in given list. */
  tablet_not_in?: InputMaybe<Array<InputMaybe<BraidSizes>>>;
  wide?: InputMaybe<BraidSizes>;
  /** All values that are contained in given list. */
  wide_in?: InputMaybe<Array<InputMaybe<BraidSizes>>>;
  /** Any other value that exists and is not equal to the given value. */
  wide_not?: InputMaybe<BraidSizes>;
  /** All values that are not contained in given list. */
  wide_not_in?: InputMaybe<Array<InputMaybe<BraidSizes>>>;
};

/** References ResponsiveSpace record uniquely */
export type ResponsiveSpaceWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export enum ReverseContent {
  Both = 'both',
  Desktop = 'desktop',
  Mobile = 'mobile',
  None = 'none',
}

/** Custom type representing a rich text value comprising of raw rich text ast, html, markdown and text values */
export type RichText = {
  __typename?: 'RichText';
  /** Returns HTMl representation */
  html: Scalars['String'];
  /** Returns Markdown representation */
  markdown: Scalars['String'];
  /** Returns AST representation */
  raw: Scalars['RichTextAST'];
  /** Returns plain-text contents of RichText */
  text: Scalars['String'];
};

export type RichTextEditor = Entity &
  Node & {
    __typename?: 'RichTextEditor';
    content?: Maybe<RichTextEditorContentRichText>;
    /** The time the document was created */
    createdAt: Scalars['DateTime'];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<RichTextEditor>;
    /** List of RichTextEditor versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars['ID'];
    /** System Locale field */
    locale: Locale;
    /** Get the other localizations for this document */
    localizations: Array<RichTextEditor>;
    name?: Maybe<Scalars['String']>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    slug?: Maybe<Scalars['String']>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars['DateTime'];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type RichTextEditorCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type RichTextEditorCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type RichTextEditorDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type RichTextEditorHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};

export type RichTextEditorLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type RichTextEditorPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type RichTextEditorPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type RichTextEditorScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type RichTextEditorUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type RichTextEditorUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type RichTextEditorConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: RichTextEditorWhereUniqueInput;
};

/** A connection to a list of items. */
export type RichTextEditorConnection = {
  __typename?: 'RichTextEditorConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<RichTextEditorEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type RichTextEditorContentRichText = {
  __typename?: 'RichTextEditorContentRichText';
  /** Returns HTMl representation */
  html: Scalars['String'];
  json: Scalars['RichTextAST'];
  /** Returns Markdown representation */
  markdown: Scalars['String'];
  /** @deprecated Please use the 'json' field */
  raw: Scalars['RichTextAST'];
  references: Array<RichTextEditorContentRichTextEmbeddedTypes>;
  /** Returns plain-text contents of RichText */
  text: Scalars['String'];
};

export type RichTextEditorContentRichTextReferencesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type RichTextEditorContentRichTextEmbeddedTypes =
  | EmbedAccordionGroup
  | EmbedCustomContent
  | EmbedSingleLineText;

export type RichTextEditorCreateInput = {
  /** content input for default locale (default) */
  content?: InputMaybe<Scalars['RichTextAST']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<RichTextEditorCreateLocalizationsInput>;
  name?: InputMaybe<Scalars['String']>;
  relatedRichTextEditor?: InputMaybe<RichTextEditorWrapperCreateManyInlineInput>;
  slug?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RichTextEditorCreateLocalizationDataInput = {
  content?: InputMaybe<Scalars['RichTextAST']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type RichTextEditorCreateLocalizationInput = {
  /** Localization input */
  data: RichTextEditorCreateLocalizationDataInput;
  locale: Locale;
};

export type RichTextEditorCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<RichTextEditorCreateLocalizationInput>>;
};

export type RichTextEditorCreateManyInlineInput = {
  /** Connect multiple existing RichTextEditor documents */
  connect?: InputMaybe<Array<RichTextEditorWhereUniqueInput>>;
  /** Create and connect multiple existing RichTextEditor documents */
  create?: InputMaybe<Array<RichTextEditorCreateInput>>;
};

export type RichTextEditorCreateOneInlineInput = {
  /** Connect one existing RichTextEditor document */
  connect?: InputMaybe<RichTextEditorWhereUniqueInput>;
  /** Create and connect one RichTextEditor document */
  create?: InputMaybe<RichTextEditorCreateInput>;
};

/** An edge in a connection. */
export type RichTextEditorEdge = {
  __typename?: 'RichTextEditorEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: RichTextEditor;
};

/** Identifies documents */
export type RichTextEditorManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<RichTextEditorWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<RichTextEditorWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<RichTextEditorWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<RichTextEditorWhereStageInput>;
  documentInStages_none?: InputMaybe<RichTextEditorWhereStageInput>;
  documentInStages_some?: InputMaybe<RichTextEditorWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  slug?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum RichTextEditorOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type RichTextEditorUpdateInput = {
  /** content input for default locale (default) */
  content?: InputMaybe<Scalars['RichTextAST']>;
  /** Manage document localizations */
  localizations?: InputMaybe<RichTextEditorUpdateLocalizationsInput>;
  name?: InputMaybe<Scalars['String']>;
  relatedRichTextEditor?: InputMaybe<RichTextEditorWrapperUpdateManyInlineInput>;
  slug?: InputMaybe<Scalars['String']>;
};

export type RichTextEditorUpdateLocalizationDataInput = {
  content?: InputMaybe<Scalars['RichTextAST']>;
};

export type RichTextEditorUpdateLocalizationInput = {
  data: RichTextEditorUpdateLocalizationDataInput;
  locale: Locale;
};

export type RichTextEditorUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<RichTextEditorCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<RichTextEditorUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<RichTextEditorUpsertLocalizationInput>>;
};

export type RichTextEditorUpdateManyInlineInput = {
  /** Connect multiple existing RichTextEditor documents */
  connect?: InputMaybe<Array<RichTextEditorConnectInput>>;
  /** Create and connect multiple RichTextEditor documents */
  create?: InputMaybe<Array<RichTextEditorCreateInput>>;
  /** Delete multiple RichTextEditor documents */
  delete?: InputMaybe<Array<RichTextEditorWhereUniqueInput>>;
  /** Disconnect multiple RichTextEditor documents */
  disconnect?: InputMaybe<Array<RichTextEditorWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing RichTextEditor documents */
  set?: InputMaybe<Array<RichTextEditorWhereUniqueInput>>;
  /** Update multiple RichTextEditor documents */
  update?: InputMaybe<Array<RichTextEditorUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple RichTextEditor documents */
  upsert?: InputMaybe<Array<RichTextEditorUpsertWithNestedWhereUniqueInput>>;
};

export type RichTextEditorUpdateManyInput = {
  /** content input for default locale (default) */
  content?: InputMaybe<Scalars['RichTextAST']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<RichTextEditorUpdateManyLocalizationsInput>;
  name?: InputMaybe<Scalars['String']>;
};

export type RichTextEditorUpdateManyLocalizationDataInput = {
  content?: InputMaybe<Scalars['RichTextAST']>;
};

export type RichTextEditorUpdateManyLocalizationInput = {
  data: RichTextEditorUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type RichTextEditorUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<RichTextEditorUpdateManyLocalizationInput>>;
};

export type RichTextEditorUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: RichTextEditorUpdateManyInput;
  /** Document search */
  where: RichTextEditorWhereInput;
};

export type RichTextEditorUpdateOneInlineInput = {
  /** Connect existing RichTextEditor document */
  connect?: InputMaybe<RichTextEditorWhereUniqueInput>;
  /** Create and connect one RichTextEditor document */
  create?: InputMaybe<RichTextEditorCreateInput>;
  /** Delete currently connected RichTextEditor document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected RichTextEditor document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single RichTextEditor document */
  update?: InputMaybe<RichTextEditorUpdateWithNestedWhereUniqueInput>;
  /** Upsert single RichTextEditor document */
  upsert?: InputMaybe<RichTextEditorUpsertWithNestedWhereUniqueInput>;
};

export type RichTextEditorUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: RichTextEditorUpdateInput;
  /** Unique document search */
  where: RichTextEditorWhereUniqueInput;
};

export type RichTextEditorUpsertInput = {
  /** Create document if it didn't exist */
  create: RichTextEditorCreateInput;
  /** Update document if it exists */
  update: RichTextEditorUpdateInput;
};

export type RichTextEditorUpsertLocalizationInput = {
  create: RichTextEditorCreateLocalizationDataInput;
  locale: Locale;
  update: RichTextEditorUpdateLocalizationDataInput;
};

export type RichTextEditorUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: RichTextEditorUpsertInput;
  /** Unique document search */
  where: RichTextEditorWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type RichTextEditorWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type RichTextEditorWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<RichTextEditorWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<RichTextEditorWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<RichTextEditorWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<RichTextEditorWhereStageInput>;
  documentInStages_none?: InputMaybe<RichTextEditorWhereStageInput>;
  documentInStages_some?: InputMaybe<RichTextEditorWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  slug?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type RichTextEditorWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<RichTextEditorWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<RichTextEditorWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<RichTextEditorWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<RichTextEditorWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References RichTextEditor record uniquely */
export type RichTextEditorWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
};

/** A wrapper for the "Rich Text Editor" model so we can use it inside "Blocks". */
export type RichTextEditorWrapper = Entity & {
  __typename?: 'RichTextEditorWrapper';
  /** The unique identifier */
  id: Scalars['ID'];
  richTextEditorWrapper?: Maybe<RichTextEditor>;
  /** System stage field */
  stage: Stage;
};

/** A wrapper for the "Rich Text Editor" model so we can use it inside "Blocks". */
export type RichTextEditorWrapperRichTextEditorWrapperArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type RichTextEditorWrapperConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: RichTextEditorWrapperWhereUniqueInput;
};

/** A connection to a list of items. */
export type RichTextEditorWrapperConnection = {
  __typename?: 'RichTextEditorWrapperConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<RichTextEditorWrapperEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type RichTextEditorWrapperCreateInput = {
  richTextEditorWrapper?: InputMaybe<RichTextEditorCreateOneInlineInput>;
};

export type RichTextEditorWrapperCreateManyInlineInput = {
  /** Create and connect multiple existing RichTextEditorWrapper documents */
  create?: InputMaybe<Array<RichTextEditorWrapperCreateInput>>;
};

export type RichTextEditorWrapperCreateOneInlineInput = {
  /** Create and connect one RichTextEditorWrapper document */
  create?: InputMaybe<RichTextEditorWrapperCreateInput>;
};

export type RichTextEditorWrapperCreateWithPositionInput = {
  /** Document to create */
  data: RichTextEditorWrapperCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type RichTextEditorWrapperEdge = {
  __typename?: 'RichTextEditorWrapperEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: RichTextEditorWrapper;
};

/** Identifies documents */
export type RichTextEditorWrapperManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<RichTextEditorWrapperWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<RichTextEditorWrapperWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<RichTextEditorWrapperWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  richTextEditorWrapper?: InputMaybe<RichTextEditorWhereInput>;
};

export enum RichTextEditorWrapperOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type RichTextEditorWrapperParent = CBannerShopfront | CBlock;

export type RichTextEditorWrapperParentConnectInput = {
  CBannerShopfront?: InputMaybe<CBannerShopfrontConnectInput>;
  CBlock?: InputMaybe<CBlockConnectInput>;
};

export type RichTextEditorWrapperParentCreateInput = {
  CBannerShopfront?: InputMaybe<CBannerShopfrontCreateInput>;
  CBlock?: InputMaybe<CBlockCreateInput>;
};

export type RichTextEditorWrapperParentCreateManyInlineInput = {
  /** Connect multiple existing RichTextEditorWrapperParent documents */
  connect?: InputMaybe<Array<RichTextEditorWrapperParentWhereUniqueInput>>;
  /** Create and connect multiple existing RichTextEditorWrapperParent documents */
  create?: InputMaybe<Array<RichTextEditorWrapperParentCreateInput>>;
};

export type RichTextEditorWrapperParentCreateOneInlineInput = {
  /** Connect one existing RichTextEditorWrapperParent document */
  connect?: InputMaybe<RichTextEditorWrapperParentWhereUniqueInput>;
  /** Create and connect one RichTextEditorWrapperParent document */
  create?: InputMaybe<RichTextEditorWrapperParentCreateInput>;
};

export type RichTextEditorWrapperParentUpdateInput = {
  CBannerShopfront?: InputMaybe<CBannerShopfrontUpdateInput>;
  CBlock?: InputMaybe<CBlockUpdateInput>;
};

export type RichTextEditorWrapperParentUpdateManyInlineInput = {
  /** Connect multiple existing RichTextEditorWrapperParent documents */
  connect?: InputMaybe<Array<RichTextEditorWrapperParentConnectInput>>;
  /** Create and connect multiple RichTextEditorWrapperParent documents */
  create?: InputMaybe<Array<RichTextEditorWrapperParentCreateInput>>;
  /** Delete multiple RichTextEditorWrapperParent documents */
  delete?: InputMaybe<Array<RichTextEditorWrapperParentWhereUniqueInput>>;
  /** Disconnect multiple RichTextEditorWrapperParent documents */
  disconnect?: InputMaybe<Array<RichTextEditorWrapperParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing RichTextEditorWrapperParent documents */
  set?: InputMaybe<Array<RichTextEditorWrapperParentWhereUniqueInput>>;
  /** Update multiple RichTextEditorWrapperParent documents */
  update?: InputMaybe<
    Array<RichTextEditorWrapperParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple RichTextEditorWrapperParent documents */
  upsert?: InputMaybe<
    Array<RichTextEditorWrapperParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type RichTextEditorWrapperParentUpdateManyWithNestedWhereInput = {
  CBannerShopfront?: InputMaybe<CBannerShopfrontUpdateManyWithNestedWhereInput>;
  CBlock?: InputMaybe<CBlockUpdateManyWithNestedWhereInput>;
};

export type RichTextEditorWrapperParentUpdateOneInlineInput = {
  /** Connect existing RichTextEditorWrapperParent document */
  connect?: InputMaybe<RichTextEditorWrapperParentWhereUniqueInput>;
  /** Create and connect one RichTextEditorWrapperParent document */
  create?: InputMaybe<RichTextEditorWrapperParentCreateInput>;
  /** Delete currently connected RichTextEditorWrapperParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected RichTextEditorWrapperParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single RichTextEditorWrapperParent document */
  update?: InputMaybe<RichTextEditorWrapperParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single RichTextEditorWrapperParent document */
  upsert?: InputMaybe<RichTextEditorWrapperParentUpsertWithNestedWhereUniqueInput>;
};

export type RichTextEditorWrapperParentUpdateWithNestedWhereUniqueInput = {
  CBannerShopfront?: InputMaybe<CBannerShopfrontUpdateWithNestedWhereUniqueInput>;
  CBlock?: InputMaybe<CBlockUpdateWithNestedWhereUniqueInput>;
};

export type RichTextEditorWrapperParentUpsertWithNestedWhereUniqueInput = {
  CBannerShopfront?: InputMaybe<CBannerShopfrontUpsertWithNestedWhereUniqueInput>;
  CBlock?: InputMaybe<CBlockUpsertWithNestedWhereUniqueInput>;
};

export type RichTextEditorWrapperParentWhereInput = {
  CBannerShopfront?: InputMaybe<CBannerShopfrontWhereInput>;
  CBlock?: InputMaybe<CBlockWhereInput>;
};

export type RichTextEditorWrapperParentWhereUniqueInput = {
  CBannerShopfront?: InputMaybe<CBannerShopfrontWhereUniqueInput>;
  CBlock?: InputMaybe<CBlockWhereUniqueInput>;
};

export type RichTextEditorWrapperUpdateInput = {
  richTextEditorWrapper?: InputMaybe<RichTextEditorUpdateOneInlineInput>;
};

export type RichTextEditorWrapperUpdateManyInlineInput = {
  /** Create and connect multiple RichTextEditorWrapper component instances */
  create?: InputMaybe<Array<RichTextEditorWrapperCreateWithPositionInput>>;
  /** Delete multiple RichTextEditorWrapper documents */
  delete?: InputMaybe<Array<RichTextEditorWrapperWhereUniqueInput>>;
  /** Update multiple RichTextEditorWrapper component instances */
  update?: InputMaybe<
    Array<RichTextEditorWrapperUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple RichTextEditorWrapper component instances */
  upsert?: InputMaybe<
    Array<RichTextEditorWrapperUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type RichTextEditorWrapperUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']>;
};

export type RichTextEditorWrapperUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: RichTextEditorWrapperUpdateManyInput;
  /** Document search */
  where: RichTextEditorWrapperWhereInput;
};

export type RichTextEditorWrapperUpdateOneInlineInput = {
  /** Create and connect one RichTextEditorWrapper document */
  create?: InputMaybe<RichTextEditorWrapperCreateInput>;
  /** Delete currently connected RichTextEditorWrapper document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single RichTextEditorWrapper document */
  update?: InputMaybe<RichTextEditorWrapperUpdateWithNestedWhereUniqueInput>;
  /** Upsert single RichTextEditorWrapper document */
  upsert?: InputMaybe<RichTextEditorWrapperUpsertWithNestedWhereUniqueInput>;
};

export type RichTextEditorWrapperUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<RichTextEditorWrapperUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: RichTextEditorWrapperWhereUniqueInput;
};

export type RichTextEditorWrapperUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: RichTextEditorWrapperUpdateInput;
  /** Unique document search */
  where: RichTextEditorWrapperWhereUniqueInput;
};

export type RichTextEditorWrapperUpsertInput = {
  /** Create document if it didn't exist */
  create: RichTextEditorWrapperCreateInput;
  /** Update document if it exists */
  update: RichTextEditorWrapperUpdateInput;
};

export type RichTextEditorWrapperUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<RichTextEditorWrapperUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: RichTextEditorWrapperWhereUniqueInput;
};

export type RichTextEditorWrapperUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: RichTextEditorWrapperUpsertInput;
  /** Unique document search */
  where: RichTextEditorWrapperWhereUniqueInput;
};

/** Identifies documents */
export type RichTextEditorWrapperWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<RichTextEditorWrapperWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<RichTextEditorWrapperWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<RichTextEditorWrapperWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  richTextEditorWrapper?: InputMaybe<RichTextEditorWhereInput>;
};

/** References RichTextEditorWrapper record uniquely */
export type RichTextEditorWrapperWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Represents Social Media Share bleed top value. */
export enum SmsBleedTop {
  Gutter = 'gutter',
  Large = 'large',
  Medium = 'medium',
  None = 'none',
  Small = 'small',
  Xlarge = 'xlarge',
  Xsmall = 'xsmall',
  Xxlarge = 'xxlarge',
  Xxsmall = 'xxsmall',
}

/** Scheduled Operation system model */
export type ScheduledOperation = Entity &
  Node & {
    __typename?: 'ScheduledOperation';
    affectedDocuments: Array<ScheduledOperationAffectedDocument>;
    /** The time the document was created */
    createdAt: Scalars['DateTime'];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Operation description */
    description?: Maybe<Scalars['String']>;
    /** Get the document in other stages */
    documentInStages: Array<ScheduledOperation>;
    /** Operation error message */
    errorMessage?: Maybe<Scalars['String']>;
    /** The unique identifier */
    id: Scalars['ID'];
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    /** Raw operation payload including all details, this field is subject to change */
    rawPayload: Scalars['Json'];
    /** The release this operation is scheduled for */
    release?: Maybe<ScheduledRelease>;
    /** System stage field */
    stage: Stage;
    /** operation Status */
    status: ScheduledOperationStatus;
    /** The time the document was updated */
    updatedAt: Scalars['DateTime'];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

/** Scheduled Operation system model */
export type ScheduledOperationAffectedDocumentsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
};

/** Scheduled Operation system model */
export type ScheduledOperationCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Scheduled Operation system model */
export type ScheduledOperationDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

/** Scheduled Operation system model */
export type ScheduledOperationPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Scheduled Operation system model */
export type ScheduledOperationReleaseArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Scheduled Operation system model */
export type ScheduledOperationUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ScheduledOperationAffectedDocument =
  | Article
  | Asset
  | Author
  | BannerItem
  | CBlock
  | CButtonLink
  | CImageLink
  | CTextLink
  | Category
  | DialogButton
  | DocumentItem
  | EmbedAccordionGroup
  | EmbedCustomContent
  | EmbedSingleLineText
  | ExternalUrl
  | Form
  | ManagedContentBanner
  | MigrationHistory
  | MigrationState
  | Page
  | RichTextEditor
  | Section
  | Site
  | SiteUsersConnection
  | Topic;

export type ScheduledOperationConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ScheduledOperationWhereUniqueInput;
};

/** A connection to a list of items. */
export type ScheduledOperationConnection = {
  __typename?: 'ScheduledOperationConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ScheduledOperationEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ScheduledOperationCreateManyInlineInput = {
  /** Connect multiple existing ScheduledOperation documents */
  connect?: InputMaybe<Array<ScheduledOperationWhereUniqueInput>>;
};

export type ScheduledOperationCreateOneInlineInput = {
  /** Connect one existing ScheduledOperation document */
  connect?: InputMaybe<ScheduledOperationWhereUniqueInput>;
};

/** An edge in a connection. */
export type ScheduledOperationEdge = {
  __typename?: 'ScheduledOperationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ScheduledOperation;
};

/** Identifies documents */
export type ScheduledOperationManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ScheduledOperationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ScheduledOperationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ScheduledOperationWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  description?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  errorMessage_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  errorMessage_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  errorMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  errorMessage_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  errorMessage_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  errorMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  errorMessage_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  rawPayload_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  rawPayload_value_recursive?: InputMaybe<Scalars['Json']>;
  release?: InputMaybe<ScheduledReleaseWhereInput>;
  status?: InputMaybe<ScheduledOperationStatus>;
  /** All values that are contained in given list. */
  status_in?: InputMaybe<Array<InputMaybe<ScheduledOperationStatus>>>;
  /** Any other value that exists and is not equal to the given value. */
  status_not?: InputMaybe<ScheduledOperationStatus>;
  /** All values that are not contained in given list. */
  status_not_in?: InputMaybe<Array<InputMaybe<ScheduledOperationStatus>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum ScheduledOperationOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ErrorMessageAsc = 'errorMessage_ASC',
  ErrorMessageDesc = 'errorMessage_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** System Scheduled Operation Status */
export enum ScheduledOperationStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
}

export type ScheduledOperationUpdateManyInlineInput = {
  /** Connect multiple existing ScheduledOperation documents */
  connect?: InputMaybe<Array<ScheduledOperationConnectInput>>;
  /** Disconnect multiple ScheduledOperation documents */
  disconnect?: InputMaybe<Array<ScheduledOperationWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing ScheduledOperation documents */
  set?: InputMaybe<Array<ScheduledOperationWhereUniqueInput>>;
};

export type ScheduledOperationUpdateOneInlineInput = {
  /** Connect existing ScheduledOperation document */
  connect?: InputMaybe<ScheduledOperationWhereUniqueInput>;
  /** Disconnect currently connected ScheduledOperation document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type ScheduledOperationWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ScheduledOperationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ScheduledOperationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ScheduledOperationWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  description?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  errorMessage_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  errorMessage_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  errorMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  errorMessage_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  errorMessage_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  errorMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  errorMessage_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  rawPayload_json_path_exists?: InputMaybe<Scalars['String']>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  rawPayload_value_recursive?: InputMaybe<Scalars['Json']>;
  release?: InputMaybe<ScheduledReleaseWhereInput>;
  status?: InputMaybe<ScheduledOperationStatus>;
  /** All values that are contained in given list. */
  status_in?: InputMaybe<Array<InputMaybe<ScheduledOperationStatus>>>;
  /** Any other value that exists and is not equal to the given value. */
  status_not?: InputMaybe<ScheduledOperationStatus>;
  /** All values that are not contained in given list. */
  status_not_in?: InputMaybe<Array<InputMaybe<ScheduledOperationStatus>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** References ScheduledOperation record uniquely */
export type ScheduledOperationWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Scheduled Release system model */
export type ScheduledRelease = Entity &
  Node & {
    __typename?: 'ScheduledRelease';
    /** The time the document was created */
    createdAt: Scalars['DateTime'];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Release description */
    description?: Maybe<Scalars['String']>;
    /** Get the document in other stages */
    documentInStages: Array<ScheduledRelease>;
    /** Release error message */
    errorMessage?: Maybe<Scalars['String']>;
    /** The unique identifier */
    id: Scalars['ID'];
    /** Whether scheduled release should be run */
    isActive: Scalars['Boolean'];
    /** Whether scheduled release is implicit */
    isImplicit: Scalars['Boolean'];
    /** Operations to run with this release */
    operations: Array<ScheduledOperation>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    /** Release date and time */
    releaseAt?: Maybe<Scalars['DateTime']>;
    /** System stage field */
    stage: Stage;
    /** Release Status */
    status: ScheduledReleaseStatus;
    /** Release Title */
    title?: Maybe<Scalars['String']>;
    /** The time the document was updated */
    updatedAt: Scalars['DateTime'];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

/** Scheduled Release system model */
export type ScheduledReleaseCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Scheduled Release system model */
export type ScheduledReleaseDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

/** Scheduled Release system model */
export type ScheduledReleaseOperationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ScheduledOperationOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

/** Scheduled Release system model */
export type ScheduledReleasePublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Scheduled Release system model */
export type ScheduledReleaseUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ScheduledReleaseConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ScheduledReleaseWhereUniqueInput;
};

/** A connection to a list of items. */
export type ScheduledReleaseConnection = {
  __typename?: 'ScheduledReleaseConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ScheduledReleaseEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ScheduledReleaseCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ScheduledReleaseCreateManyInlineInput = {
  /** Connect multiple existing ScheduledRelease documents */
  connect?: InputMaybe<Array<ScheduledReleaseWhereUniqueInput>>;
  /** Create and connect multiple existing ScheduledRelease documents */
  create?: InputMaybe<Array<ScheduledReleaseCreateInput>>;
};

export type ScheduledReleaseCreateOneInlineInput = {
  /** Connect one existing ScheduledRelease document */
  connect?: InputMaybe<ScheduledReleaseWhereUniqueInput>;
  /** Create and connect one ScheduledRelease document */
  create?: InputMaybe<ScheduledReleaseCreateInput>;
};

/** An edge in a connection. */
export type ScheduledReleaseEdge = {
  __typename?: 'ScheduledReleaseEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ScheduledRelease;
};

/** Identifies documents */
export type ScheduledReleaseManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  description?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  errorMessage_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  errorMessage_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  errorMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  errorMessage_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  errorMessage_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  errorMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  errorMessage_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  isActive_not?: InputMaybe<Scalars['Boolean']>;
  isImplicit?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  isImplicit_not?: InputMaybe<Scalars['Boolean']>;
  operations_every?: InputMaybe<ScheduledOperationWhereInput>;
  operations_none?: InputMaybe<ScheduledOperationWhereInput>;
  operations_some?: InputMaybe<ScheduledOperationWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  releaseAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  releaseAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  releaseAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  releaseAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  releaseAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  releaseAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  releaseAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  status?: InputMaybe<ScheduledReleaseStatus>;
  /** All values that are contained in given list. */
  status_in?: InputMaybe<Array<InputMaybe<ScheduledReleaseStatus>>>;
  /** Any other value that exists and is not equal to the given value. */
  status_not?: InputMaybe<ScheduledReleaseStatus>;
  /** All values that are not contained in given list. */
  status_not_in?: InputMaybe<Array<InputMaybe<ScheduledReleaseStatus>>>;
  title?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum ScheduledReleaseOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ErrorMessageAsc = 'errorMessage_ASC',
  ErrorMessageDesc = 'errorMessage_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IsActiveAsc = 'isActive_ASC',
  IsActiveDesc = 'isActive_DESC',
  IsImplicitAsc = 'isImplicit_ASC',
  IsImplicitDesc = 'isImplicit_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  ReleaseAtAsc = 'releaseAt_ASC',
  ReleaseAtDesc = 'releaseAt_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

/** System Scheduled Release Status */
export enum ScheduledReleaseStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Pending = 'PENDING',
}

export type ScheduledReleaseUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export type ScheduledReleaseUpdateManyInlineInput = {
  /** Connect multiple existing ScheduledRelease documents */
  connect?: InputMaybe<Array<ScheduledReleaseConnectInput>>;
  /** Create and connect multiple ScheduledRelease documents */
  create?: InputMaybe<Array<ScheduledReleaseCreateInput>>;
  /** Delete multiple ScheduledRelease documents */
  delete?: InputMaybe<Array<ScheduledReleaseWhereUniqueInput>>;
  /** Disconnect multiple ScheduledRelease documents */
  disconnect?: InputMaybe<Array<ScheduledReleaseWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing ScheduledRelease documents */
  set?: InputMaybe<Array<ScheduledReleaseWhereUniqueInput>>;
  /** Update multiple ScheduledRelease documents */
  update?: InputMaybe<Array<ScheduledReleaseUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple ScheduledRelease documents */
  upsert?: InputMaybe<Array<ScheduledReleaseUpsertWithNestedWhereUniqueInput>>;
};

export type ScheduledReleaseUpdateManyInput = {
  description?: InputMaybe<Scalars['String']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export type ScheduledReleaseUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ScheduledReleaseUpdateManyInput;
  /** Document search */
  where: ScheduledReleaseWhereInput;
};

export type ScheduledReleaseUpdateOneInlineInput = {
  /** Connect existing ScheduledRelease document */
  connect?: InputMaybe<ScheduledReleaseWhereUniqueInput>;
  /** Create and connect one ScheduledRelease document */
  create?: InputMaybe<ScheduledReleaseCreateInput>;
  /** Delete currently connected ScheduledRelease document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected ScheduledRelease document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single ScheduledRelease document */
  update?: InputMaybe<ScheduledReleaseUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ScheduledRelease document */
  upsert?: InputMaybe<ScheduledReleaseUpsertWithNestedWhereUniqueInput>;
};

export type ScheduledReleaseUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ScheduledReleaseUpdateInput;
  /** Unique document search */
  where: ScheduledReleaseWhereUniqueInput;
};

export type ScheduledReleaseUpsertInput = {
  /** Create document if it didn't exist */
  create: ScheduledReleaseCreateInput;
  /** Update document if it exists */
  update: ScheduledReleaseUpdateInput;
};

export type ScheduledReleaseUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ScheduledReleaseUpsertInput;
  /** Unique document search */
  where: ScheduledReleaseWhereUniqueInput;
};

/** Identifies documents */
export type ScheduledReleaseWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  description?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']>;
  errorMessage?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  errorMessage_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  errorMessage_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  errorMessage_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  errorMessage_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  errorMessage_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  errorMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  errorMessage_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  isActive_not?: InputMaybe<Scalars['Boolean']>;
  isImplicit?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  isImplicit_not?: InputMaybe<Scalars['Boolean']>;
  operations_every?: InputMaybe<ScheduledOperationWhereInput>;
  operations_none?: InputMaybe<ScheduledOperationWhereInput>;
  operations_some?: InputMaybe<ScheduledOperationWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  releaseAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  releaseAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  releaseAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  releaseAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  releaseAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  releaseAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  releaseAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  releaseAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  status?: InputMaybe<ScheduledReleaseStatus>;
  /** All values that are contained in given list. */
  status_in?: InputMaybe<Array<InputMaybe<ScheduledReleaseStatus>>>;
  /** Any other value that exists and is not equal to the given value. */
  status_not?: InputMaybe<ScheduledReleaseStatus>;
  /** All values that are not contained in given list. */
  status_not_in?: InputMaybe<Array<InputMaybe<ScheduledReleaseStatus>>>;
  title?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** References ScheduledRelease record uniquely */
export type ScheduledReleaseWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** This component is used for searching articles or pages. */
export type SearchBox = Entity & {
  __typename?: 'SearchBox';
  /** The unique identifier */
  id: Scalars['ID'];
  /** System stage field */
  stage: Stage;
};

export type SearchBoxConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: SearchBoxWhereUniqueInput;
};

/** A connection to a list of items. */
export type SearchBoxConnection = {
  __typename?: 'SearchBoxConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<SearchBoxEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type SearchBoxCreateInput = {
  /** No fields in create input */
  _?: InputMaybe<Scalars['String']>;
};

export type SearchBoxCreateManyInlineInput = {
  /** Create and connect multiple existing SearchBox documents */
  create?: InputMaybe<Array<SearchBoxCreateInput>>;
};

export type SearchBoxCreateOneInlineInput = {
  /** Create and connect one SearchBox document */
  create?: InputMaybe<SearchBoxCreateInput>;
};

export type SearchBoxCreateWithPositionInput = {
  /** Document to create */
  data: SearchBoxCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type SearchBoxEdge = {
  __typename?: 'SearchBoxEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: SearchBox;
};

/** Identifies documents */
export type SearchBoxManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SearchBoxWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SearchBoxWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SearchBoxWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
};

export enum SearchBoxOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type SearchBoxParent = CCustomComponent;

export type SearchBoxParentConnectInput = {
  CCustomComponent?: InputMaybe<CCustomComponentConnectInput>;
};

export type SearchBoxParentCreateInput = {
  CCustomComponent?: InputMaybe<CCustomComponentCreateInput>;
};

export type SearchBoxParentCreateManyInlineInput = {
  /** Create and connect multiple existing SearchBoxParent documents */
  create?: InputMaybe<Array<SearchBoxParentCreateInput>>;
};

export type SearchBoxParentCreateOneInlineInput = {
  /** Create and connect one SearchBoxParent document */
  create?: InputMaybe<SearchBoxParentCreateInput>;
};

export type SearchBoxParentCreateWithPositionInput = {
  CCustomComponent?: InputMaybe<CCustomComponentCreateWithPositionInput>;
};

export type SearchBoxParentUpdateInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpdateInput>;
};

export type SearchBoxParentUpdateManyInlineInput = {
  /** Create and connect multiple SearchBoxParent component instances */
  create?: InputMaybe<Array<SearchBoxParentCreateWithPositionInput>>;
  /** Delete multiple SearchBoxParent documents */
  delete?: InputMaybe<Array<SearchBoxParentWhereUniqueInput>>;
  /** Update multiple SearchBoxParent component instances */
  update?: InputMaybe<
    Array<SearchBoxParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple SearchBoxParent component instances */
  upsert?: InputMaybe<
    Array<SearchBoxParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type SearchBoxParentUpdateManyWithNestedWhereInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpdateManyWithNestedWhereInput>;
};

export type SearchBoxParentUpdateOneInlineInput = {
  /** Create and connect one SearchBoxParent document */
  create?: InputMaybe<SearchBoxParentCreateInput>;
  /** Delete currently connected SearchBoxParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single SearchBoxParent document */
  update?: InputMaybe<SearchBoxParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single SearchBoxParent document */
  upsert?: InputMaybe<SearchBoxParentUpsertWithNestedWhereUniqueInput>;
};

export type SearchBoxParentUpdateWithNestedWhereUniqueAndPositionInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type SearchBoxParentUpdateWithNestedWhereUniqueInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpdateWithNestedWhereUniqueInput>;
};

export type SearchBoxParentUpsertWithNestedWhereUniqueAndPositionInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type SearchBoxParentUpsertWithNestedWhereUniqueInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpsertWithNestedWhereUniqueInput>;
};

export type SearchBoxParentWhereInput = {
  CCustomComponent?: InputMaybe<CCustomComponentWhereInput>;
};

export type SearchBoxParentWhereUniqueInput = {
  CCustomComponent?: InputMaybe<CCustomComponentWhereUniqueInput>;
};

export type SearchBoxUpdateInput = {
  /** No fields in update input */
  _?: InputMaybe<Scalars['String']>;
};

export type SearchBoxUpdateManyInlineInput = {
  /** Create and connect multiple SearchBox component instances */
  create?: InputMaybe<Array<SearchBoxCreateWithPositionInput>>;
  /** Delete multiple SearchBox documents */
  delete?: InputMaybe<Array<SearchBoxWhereUniqueInput>>;
  /** Update multiple SearchBox component instances */
  update?: InputMaybe<
    Array<SearchBoxUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple SearchBox component instances */
  upsert?: InputMaybe<
    Array<SearchBoxUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type SearchBoxUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']>;
};

export type SearchBoxUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SearchBoxUpdateManyInput;
  /** Document search */
  where: SearchBoxWhereInput;
};

export type SearchBoxUpdateOneInlineInput = {
  /** Create and connect one SearchBox document */
  create?: InputMaybe<SearchBoxCreateInput>;
  /** Delete currently connected SearchBox document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single SearchBox document */
  update?: InputMaybe<SearchBoxUpdateWithNestedWhereUniqueInput>;
  /** Upsert single SearchBox document */
  upsert?: InputMaybe<SearchBoxUpsertWithNestedWhereUniqueInput>;
};

export type SearchBoxUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SearchBoxUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: SearchBoxWhereUniqueInput;
};

export type SearchBoxUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SearchBoxUpdateInput;
  /** Unique document search */
  where: SearchBoxWhereUniqueInput;
};

export type SearchBoxUpsertInput = {
  /** Create document if it didn't exist */
  create: SearchBoxCreateInput;
  /** Update document if it exists */
  update: SearchBoxUpdateInput;
};

export type SearchBoxUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SearchBoxUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: SearchBoxWhereUniqueInput;
};

export type SearchBoxUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SearchBoxUpsertInput;
  /** Unique document search */
  where: SearchBoxWhereUniqueInput;
};

/** Identifies documents */
export type SearchBoxWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SearchBoxWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SearchBoxWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SearchBoxWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
};

/** References SearchBox record uniquely */
export type SearchBoxWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Section = Entity &
  Node & {
    __typename?: 'Section';
    childSections: Array<Section>;
    /** The time the document was created */
    createdAt: Scalars['DateTime'];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** localisable display name that's primarily for breadcrumb. Default to section name if display name is not provided. */
    displayName?: Maybe<Scalars['String']>;
    /** Get the document in other stages */
    documentInStages: Array<Section>;
    /** Configure the footer for all Pages in the Section */
    footerOptions?: Maybe<SectionFooter>;
    /** Configure the page header for the entire Section */
    headerOptions?: Maybe<SectionHeader>;
    /** Hide the subscription form on all pages and articles within the 'market-insight' section. Currently, the subscription form appears on all pages, but it should be excluded specifically for content under the 'market-insight' category. */
    hideEmbeddedForm: Array<Union_HideEmbeddedForm_HideEmbeddedFormInSection>;
    /** List of Section versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars['ID'];
    /** System Locale field */
    locale: Locale;
    /** Get the other localizations for this document */
    localizations: Array<Section>;
    parentSection?: Maybe<Section>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    sectionCategories: Array<Category>;
    sectionName: Scalars['String'];
    sectionPages: Array<Page>;
    /** System stage field */
    stage: Stage;
    /** Visual theme override for all content in the section. */
    theme?: Maybe<Theme>;
    /** The time the document was updated */
    updatedAt: Scalars['DateTime'];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type SectionChildSectionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<SectionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SectionWhereInput>;
};

export type SectionCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type SectionCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type SectionDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type SectionFooterOptionsArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type SectionHeaderOptionsArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type SectionHideEmbeddedFormArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type SectionHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};

export type SectionLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type SectionParentSectionArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type SectionPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type SectionPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type SectionScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type SectionSectionCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<CategoryOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CategoryWhereInput>;
};

export type SectionSectionPagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<PageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PageWhereInput>;
};

export type SectionUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type SectionUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type SectionConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: SectionWhereUniqueInput;
};

/** A connection to a list of items. */
export type SectionConnection = {
  __typename?: 'SectionConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<SectionEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type SectionCreateInput = {
  childSections?: InputMaybe<SectionCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** displayName input for default locale (default) */
  displayName?: InputMaybe<Scalars['String']>;
  footerOptions?: InputMaybe<SectionFooterCreateOneInlineInput>;
  headerOptions?: InputMaybe<SectionHeaderCreateOneInlineInput>;
  hideEmbeddedForm?: InputMaybe<Union_HideEmbeddedForm_HideEmbeddedFormInSectionCreateManyInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SectionCreateLocalizationsInput>;
  parentSection?: InputMaybe<SectionCreateOneInlineInput>;
  sectionCategories?: InputMaybe<CategoryCreateManyInlineInput>;
  /** sectionName input for default locale (default) */
  sectionName: Scalars['String'];
  sectionPages?: InputMaybe<PageCreateManyInlineInput>;
  theme?: InputMaybe<Theme>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SectionCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  displayName?: InputMaybe<Scalars['String']>;
  sectionName: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SectionCreateLocalizationInput = {
  /** Localization input */
  data: SectionCreateLocalizationDataInput;
  locale: Locale;
};

export type SectionCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SectionCreateLocalizationInput>>;
};

export type SectionCreateManyInlineInput = {
  /** Connect multiple existing Section documents */
  connect?: InputMaybe<Array<SectionWhereUniqueInput>>;
  /** Create and connect multiple existing Section documents */
  create?: InputMaybe<Array<SectionCreateInput>>;
};

export type SectionCreateOneInlineInput = {
  /** Connect one existing Section document */
  connect?: InputMaybe<SectionWhereUniqueInput>;
  /** Create and connect one Section document */
  create?: InputMaybe<SectionCreateInput>;
};

/** An edge in a connection. */
export type SectionEdge = {
  __typename?: 'SectionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Section;
};

export type SectionFooter = Entity & {
  __typename?: 'SectionFooter';
  footerType?: Maybe<SectionFooterfooterTypeUnion>;
  hideFooter?: Maybe<Scalars['Boolean']>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System stage field */
  stage: Stage;
};

export type SectionFooterFooterTypeArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type SectionFooterConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: SectionFooterWhereUniqueInput;
};

/** A connection to a list of items. */
export type SectionFooterConnection = {
  __typename?: 'SectionFooterConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<SectionFooterEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type SectionFooterCreateInput = {
  footerType?: InputMaybe<SectionFooterfooterTypeUnionCreateOneInlineInput>;
  hideFooter?: InputMaybe<Scalars['Boolean']>;
};

export type SectionFooterCreateManyInlineInput = {
  /** Create and connect multiple existing SectionFooter documents */
  create?: InputMaybe<Array<SectionFooterCreateInput>>;
};

export type SectionFooterCreateOneInlineInput = {
  /** Create and connect one SectionFooter document */
  create?: InputMaybe<SectionFooterCreateInput>;
};

export type SectionFooterCreateWithPositionInput = {
  /** Document to create */
  data: SectionFooterCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type SectionFooterEdge = {
  __typename?: 'SectionFooterEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: SectionFooter;
};

/** Identifies documents */
export type SectionFooterManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionFooterWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionFooterWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionFooterWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  /** All values in which the modular component is connected to the given models */
  footerType?: InputMaybe<SectionFooterfooterTypeUnionWhereInput>;
  /** All values in which the union is empty. */
  footerType_empty?: InputMaybe<Scalars['Boolean']>;
  hideFooter?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  hideFooter_not?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
};

export enum SectionFooterOrderByInput {
  HideFooterAsc = 'hideFooter_ASC',
  HideFooterDesc = 'hideFooter_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type SectionFooterParent = Section;

export type SectionFooterParentConnectInput = {
  Section?: InputMaybe<SectionConnectInput>;
};

export type SectionFooterParentCreateInput = {
  Section?: InputMaybe<SectionCreateInput>;
};

export type SectionFooterParentCreateManyInlineInput = {
  /** Connect multiple existing SectionFooterParent documents */
  connect?: InputMaybe<Array<SectionFooterParentWhereUniqueInput>>;
  /** Create and connect multiple existing SectionFooterParent documents */
  create?: InputMaybe<Array<SectionFooterParentCreateInput>>;
};

export type SectionFooterParentCreateOneInlineInput = {
  /** Connect one existing SectionFooterParent document */
  connect?: InputMaybe<SectionFooterParentWhereUniqueInput>;
  /** Create and connect one SectionFooterParent document */
  create?: InputMaybe<SectionFooterParentCreateInput>;
};

export type SectionFooterParentUpdateInput = {
  Section?: InputMaybe<SectionUpdateInput>;
};

export type SectionFooterParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionFooterParent documents */
  connect?: InputMaybe<Array<SectionFooterParentConnectInput>>;
  /** Create and connect multiple SectionFooterParent documents */
  create?: InputMaybe<Array<SectionFooterParentCreateInput>>;
  /** Delete multiple SectionFooterParent documents */
  delete?: InputMaybe<Array<SectionFooterParentWhereUniqueInput>>;
  /** Disconnect multiple SectionFooterParent documents */
  disconnect?: InputMaybe<Array<SectionFooterParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing SectionFooterParent documents */
  set?: InputMaybe<Array<SectionFooterParentWhereUniqueInput>>;
  /** Update multiple SectionFooterParent documents */
  update?: InputMaybe<
    Array<SectionFooterParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple SectionFooterParent documents */
  upsert?: InputMaybe<
    Array<SectionFooterParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type SectionFooterParentUpdateManyWithNestedWhereInput = {
  Section?: InputMaybe<SectionUpdateManyWithNestedWhereInput>;
};

export type SectionFooterParentUpdateOneInlineInput = {
  /** Connect existing SectionFooterParent document */
  connect?: InputMaybe<SectionFooterParentWhereUniqueInput>;
  /** Create and connect one SectionFooterParent document */
  create?: InputMaybe<SectionFooterParentCreateInput>;
  /** Delete currently connected SectionFooterParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected SectionFooterParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single SectionFooterParent document */
  update?: InputMaybe<SectionFooterParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single SectionFooterParent document */
  upsert?: InputMaybe<SectionFooterParentUpsertWithNestedWhereUniqueInput>;
};

export type SectionFooterParentUpdateWithNestedWhereUniqueInput = {
  Section?: InputMaybe<SectionUpdateWithNestedWhereUniqueInput>;
};

export type SectionFooterParentUpsertWithNestedWhereUniqueInput = {
  Section?: InputMaybe<SectionUpsertWithNestedWhereUniqueInput>;
};

export type SectionFooterParentWhereInput = {
  Section?: InputMaybe<SectionWhereInput>;
};

export type SectionFooterParentWhereUniqueInput = {
  Section?: InputMaybe<SectionWhereUniqueInput>;
};

export type SectionFooterUpdateInput = {
  footerType?: InputMaybe<SectionFooterfooterTypeUnionUpdateOneInlineInput>;
  hideFooter?: InputMaybe<Scalars['Boolean']>;
};

export type SectionFooterUpdateManyInlineInput = {
  /** Create and connect multiple SectionFooter component instances */
  create?: InputMaybe<Array<SectionFooterCreateWithPositionInput>>;
  /** Delete multiple SectionFooter documents */
  delete?: InputMaybe<Array<SectionFooterWhereUniqueInput>>;
  /** Update multiple SectionFooter component instances */
  update?: InputMaybe<
    Array<SectionFooterUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple SectionFooter component instances */
  upsert?: InputMaybe<
    Array<SectionFooterUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type SectionFooterUpdateManyInput = {
  hideFooter?: InputMaybe<Scalars['Boolean']>;
};

export type SectionFooterUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionFooterUpdateManyInput;
  /** Document search */
  where: SectionFooterWhereInput;
};

export type SectionFooterUpdateOneInlineInput = {
  /** Create and connect one SectionFooter document */
  create?: InputMaybe<SectionFooterCreateInput>;
  /** Delete currently connected SectionFooter document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single SectionFooter document */
  update?: InputMaybe<SectionFooterUpdateWithNestedWhereUniqueInput>;
  /** Upsert single SectionFooter document */
  upsert?: InputMaybe<SectionFooterUpsertWithNestedWhereUniqueInput>;
};

export type SectionFooterUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionFooterUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: SectionFooterWhereUniqueInput;
};

export type SectionFooterUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionFooterUpdateInput;
  /** Unique document search */
  where: SectionFooterWhereUniqueInput;
};

export type SectionFooterUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionFooterCreateInput;
  /** Update document if it exists */
  update: SectionFooterUpdateInput;
};

export type SectionFooterUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionFooterUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: SectionFooterWhereUniqueInput;
};

export type SectionFooterUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionFooterUpsertInput;
  /** Unique document search */
  where: SectionFooterWhereUniqueInput;
};

/** Identifies documents */
export type SectionFooterWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionFooterWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionFooterWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionFooterWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  /** All values in which the modular component is connected to the given models */
  footerType?: InputMaybe<SectionFooterfooterTypeUnionWhereInput>;
  /** All values in which the union is empty. */
  footerType_empty?: InputMaybe<Scalars['Boolean']>;
  hideFooter?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  hideFooter_not?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
};

/** References SectionFooter record uniquely */
export type SectionFooterWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type SectionFooterfooterTypeUnion = CustomFooter;

export type SectionFooterfooterTypeUnionConnectInput = {
  CustomFooter?: InputMaybe<CustomFooterConnectInput>;
};

export type SectionFooterfooterTypeUnionCreateInput = {
  CustomFooter?: InputMaybe<CustomFooterCreateInput>;
};

export type SectionFooterfooterTypeUnionCreateManyInlineInput = {
  /** Create and connect multiple existing SectionFooterfooterTypeUnion documents */
  create?: InputMaybe<Array<SectionFooterfooterTypeUnionCreateInput>>;
};

export type SectionFooterfooterTypeUnionCreateOneInlineInput = {
  /** Create and connect one SectionFooterfooterTypeUnion document */
  create?: InputMaybe<SectionFooterfooterTypeUnionCreateInput>;
};

export type SectionFooterfooterTypeUnionCreateWithPositionInput = {
  CustomFooter?: InputMaybe<CustomFooterCreateWithPositionInput>;
};

export type SectionFooterfooterTypeUnionUpdateInput = {
  CustomFooter?: InputMaybe<CustomFooterUpdateInput>;
};

export type SectionFooterfooterTypeUnionUpdateManyInlineInput = {
  /** Create and connect multiple SectionFooterfooterTypeUnion component instances */
  create?: InputMaybe<
    Array<SectionFooterfooterTypeUnionCreateWithPositionInput>
  >;
  /** Delete multiple SectionFooterfooterTypeUnion documents */
  delete?: InputMaybe<Array<SectionFooterfooterTypeUnionWhereUniqueInput>>;
  /** Update multiple SectionFooterfooterTypeUnion component instances */
  update?: InputMaybe<
    Array<SectionFooterfooterTypeUnionUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple SectionFooterfooterTypeUnion component instances */
  upsert?: InputMaybe<
    Array<SectionFooterfooterTypeUnionUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type SectionFooterfooterTypeUnionUpdateManyWithNestedWhereInput = {
  CustomFooter?: InputMaybe<CustomFooterUpdateManyWithNestedWhereInput>;
};

export type SectionFooterfooterTypeUnionUpdateOneInlineInput = {
  /** Create and connect one SectionFooterfooterTypeUnion document */
  create?: InputMaybe<SectionFooterfooterTypeUnionCreateInput>;
  /** Delete currently connected SectionFooterfooterTypeUnion document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single SectionFooterfooterTypeUnion document */
  update?: InputMaybe<SectionFooterfooterTypeUnionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single SectionFooterfooterTypeUnion document */
  upsert?: InputMaybe<SectionFooterfooterTypeUnionUpsertWithNestedWhereUniqueInput>;
};

export type SectionFooterfooterTypeUnionUpdateWithNestedWhereUniqueAndPositionInput =
  {
    CustomFooter?: InputMaybe<CustomFooterUpdateWithNestedWhereUniqueAndPositionInput>;
  };

export type SectionFooterfooterTypeUnionUpdateWithNestedWhereUniqueInput = {
  CustomFooter?: InputMaybe<CustomFooterUpdateWithNestedWhereUniqueInput>;
};

export type SectionFooterfooterTypeUnionUpsertWithNestedWhereUniqueAndPositionInput =
  {
    CustomFooter?: InputMaybe<CustomFooterUpsertWithNestedWhereUniqueAndPositionInput>;
  };

export type SectionFooterfooterTypeUnionUpsertWithNestedWhereUniqueInput = {
  CustomFooter?: InputMaybe<CustomFooterUpsertWithNestedWhereUniqueInput>;
};

export type SectionFooterfooterTypeUnionWhereInput = {
  CustomFooter?: InputMaybe<CustomFooterWhereInput>;
};

export type SectionFooterfooterTypeUnionWhereUniqueInput = {
  CustomFooter?: InputMaybe<CustomFooterWhereUniqueInput>;
};

/** Options for when a Section has chosen to customise the Header */
export type SectionHeader = Entity & {
  __typename?: 'SectionHeader';
  /** Choose a header type. */
  headerType?: Maybe<SectionHeaderheaderTypeUnion>;
  /** Remove any header from the page */
  hideHeader?: Maybe<Scalars['Boolean']>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System stage field */
  stage: Stage;
};

/** Options for when a Section has chosen to customise the Header */
export type SectionHeaderHeaderTypeArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type SectionHeaderConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: SectionHeaderWhereUniqueInput;
};

/** A connection to a list of items. */
export type SectionHeaderConnection = {
  __typename?: 'SectionHeaderConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<SectionHeaderEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type SectionHeaderCreateInput = {
  headerType?: InputMaybe<SectionHeaderheaderTypeUnionCreateOneInlineInput>;
  hideHeader?: InputMaybe<Scalars['Boolean']>;
};

export type SectionHeaderCreateManyInlineInput = {
  /** Create and connect multiple existing SectionHeader documents */
  create?: InputMaybe<Array<SectionHeaderCreateInput>>;
};

export type SectionHeaderCreateOneInlineInput = {
  /** Create and connect one SectionHeader document */
  create?: InputMaybe<SectionHeaderCreateInput>;
};

export type SectionHeaderCreateWithPositionInput = {
  /** Document to create */
  data: SectionHeaderCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type SectionHeaderEdge = {
  __typename?: 'SectionHeaderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: SectionHeader;
};

/** Identifies documents */
export type SectionHeaderManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionHeaderWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionHeaderWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionHeaderWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  /** All values in which the modular component is connected to the given models */
  headerType?: InputMaybe<SectionHeaderheaderTypeUnionWhereInput>;
  /** All values in which the union is empty. */
  headerType_empty?: InputMaybe<Scalars['Boolean']>;
  hideHeader?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  hideHeader_not?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
};

export enum SectionHeaderOrderByInput {
  HideHeaderAsc = 'hideHeader_ASC',
  HideHeaderDesc = 'hideHeader_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type SectionHeaderParent = Section;

export type SectionHeaderParentConnectInput = {
  Section?: InputMaybe<SectionConnectInput>;
};

export type SectionHeaderParentCreateInput = {
  Section?: InputMaybe<SectionCreateInput>;
};

export type SectionHeaderParentCreateManyInlineInput = {
  /** Connect multiple existing SectionHeaderParent documents */
  connect?: InputMaybe<Array<SectionHeaderParentWhereUniqueInput>>;
  /** Create and connect multiple existing SectionHeaderParent documents */
  create?: InputMaybe<Array<SectionHeaderParentCreateInput>>;
};

export type SectionHeaderParentCreateOneInlineInput = {
  /** Connect one existing SectionHeaderParent document */
  connect?: InputMaybe<SectionHeaderParentWhereUniqueInput>;
  /** Create and connect one SectionHeaderParent document */
  create?: InputMaybe<SectionHeaderParentCreateInput>;
};

export type SectionHeaderParentUpdateInput = {
  Section?: InputMaybe<SectionUpdateInput>;
};

export type SectionHeaderParentUpdateManyInlineInput = {
  /** Connect multiple existing SectionHeaderParent documents */
  connect?: InputMaybe<Array<SectionHeaderParentConnectInput>>;
  /** Create and connect multiple SectionHeaderParent documents */
  create?: InputMaybe<Array<SectionHeaderParentCreateInput>>;
  /** Delete multiple SectionHeaderParent documents */
  delete?: InputMaybe<Array<SectionHeaderParentWhereUniqueInput>>;
  /** Disconnect multiple SectionHeaderParent documents */
  disconnect?: InputMaybe<Array<SectionHeaderParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing SectionHeaderParent documents */
  set?: InputMaybe<Array<SectionHeaderParentWhereUniqueInput>>;
  /** Update multiple SectionHeaderParent documents */
  update?: InputMaybe<
    Array<SectionHeaderParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple SectionHeaderParent documents */
  upsert?: InputMaybe<
    Array<SectionHeaderParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type SectionHeaderParentUpdateManyWithNestedWhereInput = {
  Section?: InputMaybe<SectionUpdateManyWithNestedWhereInput>;
};

export type SectionHeaderParentUpdateOneInlineInput = {
  /** Connect existing SectionHeaderParent document */
  connect?: InputMaybe<SectionHeaderParentWhereUniqueInput>;
  /** Create and connect one SectionHeaderParent document */
  create?: InputMaybe<SectionHeaderParentCreateInput>;
  /** Delete currently connected SectionHeaderParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected SectionHeaderParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single SectionHeaderParent document */
  update?: InputMaybe<SectionHeaderParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single SectionHeaderParent document */
  upsert?: InputMaybe<SectionHeaderParentUpsertWithNestedWhereUniqueInput>;
};

export type SectionHeaderParentUpdateWithNestedWhereUniqueInput = {
  Section?: InputMaybe<SectionUpdateWithNestedWhereUniqueInput>;
};

export type SectionHeaderParentUpsertWithNestedWhereUniqueInput = {
  Section?: InputMaybe<SectionUpsertWithNestedWhereUniqueInput>;
};

export type SectionHeaderParentWhereInput = {
  Section?: InputMaybe<SectionWhereInput>;
};

export type SectionHeaderParentWhereUniqueInput = {
  Section?: InputMaybe<SectionWhereUniqueInput>;
};

export type SectionHeaderUpdateInput = {
  headerType?: InputMaybe<SectionHeaderheaderTypeUnionUpdateOneInlineInput>;
  hideHeader?: InputMaybe<Scalars['Boolean']>;
};

export type SectionHeaderUpdateManyInlineInput = {
  /** Create and connect multiple SectionHeader component instances */
  create?: InputMaybe<Array<SectionHeaderCreateWithPositionInput>>;
  /** Delete multiple SectionHeader documents */
  delete?: InputMaybe<Array<SectionHeaderWhereUniqueInput>>;
  /** Update multiple SectionHeader component instances */
  update?: InputMaybe<
    Array<SectionHeaderUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple SectionHeader component instances */
  upsert?: InputMaybe<
    Array<SectionHeaderUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type SectionHeaderUpdateManyInput = {
  hideHeader?: InputMaybe<Scalars['Boolean']>;
};

export type SectionHeaderUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionHeaderUpdateManyInput;
  /** Document search */
  where: SectionHeaderWhereInput;
};

export type SectionHeaderUpdateOneInlineInput = {
  /** Create and connect one SectionHeader document */
  create?: InputMaybe<SectionHeaderCreateInput>;
  /** Delete currently connected SectionHeader document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single SectionHeader document */
  update?: InputMaybe<SectionHeaderUpdateWithNestedWhereUniqueInput>;
  /** Upsert single SectionHeader document */
  upsert?: InputMaybe<SectionHeaderUpsertWithNestedWhereUniqueInput>;
};

export type SectionHeaderUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SectionHeaderUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: SectionHeaderWhereUniqueInput;
};

export type SectionHeaderUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionHeaderUpdateInput;
  /** Unique document search */
  where: SectionHeaderWhereUniqueInput;
};

export type SectionHeaderUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionHeaderCreateInput;
  /** Update document if it exists */
  update: SectionHeaderUpdateInput;
};

export type SectionHeaderUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SectionHeaderUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: SectionHeaderWhereUniqueInput;
};

export type SectionHeaderUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionHeaderUpsertInput;
  /** Unique document search */
  where: SectionHeaderWhereUniqueInput;
};

/** Identifies documents */
export type SectionHeaderWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionHeaderWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionHeaderWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionHeaderWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  /** All values in which the modular component is connected to the given models */
  headerType?: InputMaybe<SectionHeaderheaderTypeUnionWhereInput>;
  /** All values in which the union is empty. */
  headerType_empty?: InputMaybe<Scalars['Boolean']>;
  hideHeader?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  hideHeader_not?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
};

/** References SectionHeader record uniquely */
export type SectionHeaderWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type SectionHeaderheaderTypeUnion = CustomHeader | SeekHeader;

export type SectionHeaderheaderTypeUnionConnectInput = {
  CustomHeader?: InputMaybe<CustomHeaderConnectInput>;
  SeekHeader?: InputMaybe<SeekHeaderConnectInput>;
};

export type SectionHeaderheaderTypeUnionCreateInput = {
  CustomHeader?: InputMaybe<CustomHeaderCreateInput>;
  SeekHeader?: InputMaybe<SeekHeaderCreateInput>;
};

export type SectionHeaderheaderTypeUnionCreateManyInlineInput = {
  /** Create and connect multiple existing SectionHeaderheaderTypeUnion documents */
  create?: InputMaybe<Array<SectionHeaderheaderTypeUnionCreateInput>>;
};

export type SectionHeaderheaderTypeUnionCreateOneInlineInput = {
  /** Create and connect one SectionHeaderheaderTypeUnion document */
  create?: InputMaybe<SectionHeaderheaderTypeUnionCreateInput>;
};

export type SectionHeaderheaderTypeUnionCreateWithPositionInput = {
  CustomHeader?: InputMaybe<CustomHeaderCreateWithPositionInput>;
  SeekHeader?: InputMaybe<SeekHeaderCreateWithPositionInput>;
};

export type SectionHeaderheaderTypeUnionUpdateInput = {
  CustomHeader?: InputMaybe<CustomHeaderUpdateInput>;
  SeekHeader?: InputMaybe<SeekHeaderUpdateInput>;
};

export type SectionHeaderheaderTypeUnionUpdateManyInlineInput = {
  /** Create and connect multiple SectionHeaderheaderTypeUnion component instances */
  create?: InputMaybe<
    Array<SectionHeaderheaderTypeUnionCreateWithPositionInput>
  >;
  /** Delete multiple SectionHeaderheaderTypeUnion documents */
  delete?: InputMaybe<Array<SectionHeaderheaderTypeUnionWhereUniqueInput>>;
  /** Update multiple SectionHeaderheaderTypeUnion component instances */
  update?: InputMaybe<
    Array<SectionHeaderheaderTypeUnionUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple SectionHeaderheaderTypeUnion component instances */
  upsert?: InputMaybe<
    Array<SectionHeaderheaderTypeUnionUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type SectionHeaderheaderTypeUnionUpdateManyWithNestedWhereInput = {
  CustomHeader?: InputMaybe<CustomHeaderUpdateManyWithNestedWhereInput>;
  SeekHeader?: InputMaybe<SeekHeaderUpdateManyWithNestedWhereInput>;
};

export type SectionHeaderheaderTypeUnionUpdateOneInlineInput = {
  /** Create and connect one SectionHeaderheaderTypeUnion document */
  create?: InputMaybe<SectionHeaderheaderTypeUnionCreateInput>;
  /** Delete currently connected SectionHeaderheaderTypeUnion document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single SectionHeaderheaderTypeUnion document */
  update?: InputMaybe<SectionHeaderheaderTypeUnionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single SectionHeaderheaderTypeUnion document */
  upsert?: InputMaybe<SectionHeaderheaderTypeUnionUpsertWithNestedWhereUniqueInput>;
};

export type SectionHeaderheaderTypeUnionUpdateWithNestedWhereUniqueAndPositionInput =
  {
    CustomHeader?: InputMaybe<CustomHeaderUpdateWithNestedWhereUniqueAndPositionInput>;
    SeekHeader?: InputMaybe<SeekHeaderUpdateWithNestedWhereUniqueAndPositionInput>;
  };

export type SectionHeaderheaderTypeUnionUpdateWithNestedWhereUniqueInput = {
  CustomHeader?: InputMaybe<CustomHeaderUpdateWithNestedWhereUniqueInput>;
  SeekHeader?: InputMaybe<SeekHeaderUpdateWithNestedWhereUniqueInput>;
};

export type SectionHeaderheaderTypeUnionUpsertWithNestedWhereUniqueAndPositionInput =
  {
    CustomHeader?: InputMaybe<CustomHeaderUpsertWithNestedWhereUniqueAndPositionInput>;
    SeekHeader?: InputMaybe<SeekHeaderUpsertWithNestedWhereUniqueAndPositionInput>;
  };

export type SectionHeaderheaderTypeUnionUpsertWithNestedWhereUniqueInput = {
  CustomHeader?: InputMaybe<CustomHeaderUpsertWithNestedWhereUniqueInput>;
  SeekHeader?: InputMaybe<SeekHeaderUpsertWithNestedWhereUniqueInput>;
};

export type SectionHeaderheaderTypeUnionWhereInput = {
  CustomHeader?: InputMaybe<CustomHeaderWhereInput>;
  SeekHeader?: InputMaybe<SeekHeaderWhereInput>;
};

export type SectionHeaderheaderTypeUnionWhereUniqueInput = {
  CustomHeader?: InputMaybe<CustomHeaderWhereUniqueInput>;
  SeekHeader?: InputMaybe<SeekHeaderWhereUniqueInput>;
};

/** Identifies documents */
export type SectionManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  childSections_every?: InputMaybe<SectionWhereInput>;
  childSections_none?: InputMaybe<SectionWhereInput>;
  childSections_some?: InputMaybe<SectionWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<SectionWhereStageInput>;
  documentInStages_none?: InputMaybe<SectionWhereStageInput>;
  documentInStages_some?: InputMaybe<SectionWhereStageInput>;
  footerOptions?: InputMaybe<SectionFooterWhereInput>;
  headerOptions?: InputMaybe<SectionHeaderWhereInput>;
  /** All values in which the union is empty */
  hideEmbeddedForm_empty?: InputMaybe<Scalars['Boolean']>;
  /** Matches if the union contains at least one connection to the provided item to the filter */
  hideEmbeddedForm_some?: InputMaybe<Union_HideEmbeddedForm_HideEmbeddedFormInSectionWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  parentSection?: InputMaybe<SectionWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  sectionCategories_every?: InputMaybe<CategoryWhereInput>;
  sectionCategories_none?: InputMaybe<CategoryWhereInput>;
  sectionCategories_some?: InputMaybe<CategoryWhereInput>;
  sectionPages_every?: InputMaybe<PageWhereInput>;
  sectionPages_none?: InputMaybe<PageWhereInput>;
  sectionPages_some?: InputMaybe<PageWhereInput>;
  theme?: InputMaybe<Theme>;
  /** All values that are contained in given list. */
  theme_in?: InputMaybe<Array<InputMaybe<Theme>>>;
  /** Any other value that exists and is not equal to the given value. */
  theme_not?: InputMaybe<Theme>;
  /** All values that are not contained in given list. */
  theme_not_in?: InputMaybe<Array<InputMaybe<Theme>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum SectionOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  DisplayNameAsc = 'displayName_ASC',
  DisplayNameDesc = 'displayName_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SectionNameAsc = 'sectionName_ASC',
  SectionNameDesc = 'sectionName_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type SectionUpdateInput = {
  childSections?: InputMaybe<SectionUpdateManyInlineInput>;
  /** displayName input for default locale (default) */
  displayName?: InputMaybe<Scalars['String']>;
  footerOptions?: InputMaybe<SectionFooterUpdateOneInlineInput>;
  headerOptions?: InputMaybe<SectionHeaderUpdateOneInlineInput>;
  hideEmbeddedForm?: InputMaybe<Union_HideEmbeddedForm_HideEmbeddedFormInSectionUpdateManyInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<SectionUpdateLocalizationsInput>;
  parentSection?: InputMaybe<SectionUpdateOneInlineInput>;
  sectionCategories?: InputMaybe<CategoryUpdateManyInlineInput>;
  /** sectionName input for default locale (default) */
  sectionName?: InputMaybe<Scalars['String']>;
  sectionPages?: InputMaybe<PageUpdateManyInlineInput>;
  theme?: InputMaybe<Theme>;
};

export type SectionUpdateLocalizationDataInput = {
  displayName?: InputMaybe<Scalars['String']>;
  sectionName?: InputMaybe<Scalars['String']>;
};

export type SectionUpdateLocalizationInput = {
  data: SectionUpdateLocalizationDataInput;
  locale: Locale;
};

export type SectionUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SectionCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<SectionUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<SectionUpsertLocalizationInput>>;
};

export type SectionUpdateManyInlineInput = {
  /** Connect multiple existing Section documents */
  connect?: InputMaybe<Array<SectionConnectInput>>;
  /** Create and connect multiple Section documents */
  create?: InputMaybe<Array<SectionCreateInput>>;
  /** Delete multiple Section documents */
  delete?: InputMaybe<Array<SectionWhereUniqueInput>>;
  /** Disconnect multiple Section documents */
  disconnect?: InputMaybe<Array<SectionWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Section documents */
  set?: InputMaybe<Array<SectionWhereUniqueInput>>;
  /** Update multiple Section documents */
  update?: InputMaybe<Array<SectionUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Section documents */
  upsert?: InputMaybe<Array<SectionUpsertWithNestedWhereUniqueInput>>;
};

export type SectionUpdateManyInput = {
  /** displayName input for default locale (default) */
  displayName?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<SectionUpdateManyLocalizationsInput>;
  theme?: InputMaybe<Theme>;
};

export type SectionUpdateManyLocalizationDataInput = {
  displayName?: InputMaybe<Scalars['String']>;
};

export type SectionUpdateManyLocalizationInput = {
  data: SectionUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type SectionUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SectionUpdateManyLocalizationInput>>;
};

export type SectionUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SectionUpdateManyInput;
  /** Document search */
  where: SectionWhereInput;
};

export type SectionUpdateOneInlineInput = {
  /** Connect existing Section document */
  connect?: InputMaybe<SectionWhereUniqueInput>;
  /** Create and connect one Section document */
  create?: InputMaybe<SectionCreateInput>;
  /** Delete currently connected Section document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected Section document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single Section document */
  update?: InputMaybe<SectionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Section document */
  upsert?: InputMaybe<SectionUpsertWithNestedWhereUniqueInput>;
};

export type SectionUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SectionUpdateInput;
  /** Unique document search */
  where: SectionWhereUniqueInput;
};

export type SectionUpsertInput = {
  /** Create document if it didn't exist */
  create: SectionCreateInput;
  /** Update document if it exists */
  update: SectionUpdateInput;
};

export type SectionUpsertLocalizationInput = {
  create: SectionCreateLocalizationDataInput;
  locale: Locale;
  update: SectionUpdateLocalizationDataInput;
};

export type SectionUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SectionUpsertInput;
  /** Unique document search */
  where: SectionWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type SectionWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type SectionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  childSections_every?: InputMaybe<SectionWhereInput>;
  childSections_none?: InputMaybe<SectionWhereInput>;
  childSections_some?: InputMaybe<SectionWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  displayName?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  displayName_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  displayName_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  displayName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  displayName_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  displayName_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  displayName_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  displayName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  displayName_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  displayName_starts_with?: InputMaybe<Scalars['String']>;
  documentInStages_every?: InputMaybe<SectionWhereStageInput>;
  documentInStages_none?: InputMaybe<SectionWhereStageInput>;
  documentInStages_some?: InputMaybe<SectionWhereStageInput>;
  footerOptions?: InputMaybe<SectionFooterWhereInput>;
  headerOptions?: InputMaybe<SectionHeaderWhereInput>;
  /** All values in which the union is empty */
  hideEmbeddedForm_empty?: InputMaybe<Scalars['Boolean']>;
  /** Matches if the union contains at least one connection to the provided item to the filter */
  hideEmbeddedForm_some?: InputMaybe<Union_HideEmbeddedForm_HideEmbeddedFormInSectionWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  parentSection?: InputMaybe<SectionWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  sectionCategories_every?: InputMaybe<CategoryWhereInput>;
  sectionCategories_none?: InputMaybe<CategoryWhereInput>;
  sectionCategories_some?: InputMaybe<CategoryWhereInput>;
  sectionName?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  sectionName_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  sectionName_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  sectionName_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  sectionName_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  sectionName_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  sectionName_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  sectionName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  sectionName_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  sectionName_starts_with?: InputMaybe<Scalars['String']>;
  sectionPages_every?: InputMaybe<PageWhereInput>;
  sectionPages_none?: InputMaybe<PageWhereInput>;
  sectionPages_some?: InputMaybe<PageWhereInput>;
  theme?: InputMaybe<Theme>;
  /** All values that are contained in given list. */
  theme_in?: InputMaybe<Array<InputMaybe<Theme>>>;
  /** Any other value that exists and is not equal to the given value. */
  theme_not?: InputMaybe<Theme>;
  /** All values that are not contained in given list. */
  theme_not_in?: InputMaybe<Array<InputMaybe<Theme>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type SectionWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SectionWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SectionWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SectionWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<SectionWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Section record uniquely */
export type SectionWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** The standard SEEK Group header */
export type SeekHeader = Entity & {
  __typename?: 'SeekHeader';
  /** The unique identifier */
  id: Scalars['ID'];
  /** How the header displays */
  layout?: Maybe<HeaderLayout>;
  /** System stage field */
  stage: Stage;
  /** Links to appear in the sub navigation menu underneath the main header */
  subnavLinks: Array<CTextLink>;
};

/** The standard SEEK Group header */
export type SeekHeaderSubnavLinksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<CTextLinkOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CTextLinkWhereInput>;
};

export type SeekHeaderConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: SeekHeaderWhereUniqueInput;
};

/** A connection to a list of items. */
export type SeekHeaderConnection = {
  __typename?: 'SeekHeaderConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<SeekHeaderEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type SeekHeaderCreateInput = {
  layout?: InputMaybe<HeaderLayout>;
  subnavLinks?: InputMaybe<CTextLinkCreateManyInlineInput>;
};

export type SeekHeaderCreateManyInlineInput = {
  /** Create and connect multiple existing SeekHeader documents */
  create?: InputMaybe<Array<SeekHeaderCreateInput>>;
};

export type SeekHeaderCreateOneInlineInput = {
  /** Create and connect one SeekHeader document */
  create?: InputMaybe<SeekHeaderCreateInput>;
};

export type SeekHeaderCreateWithPositionInput = {
  /** Document to create */
  data: SeekHeaderCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type SeekHeaderEdge = {
  __typename?: 'SeekHeaderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: SeekHeader;
};

/** Identifies documents */
export type SeekHeaderManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SeekHeaderWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SeekHeaderWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SeekHeaderWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  layout?: InputMaybe<HeaderLayout>;
  /** All values that are contained in given list. */
  layout_in?: InputMaybe<Array<InputMaybe<HeaderLayout>>>;
  /** Any other value that exists and is not equal to the given value. */
  layout_not?: InputMaybe<HeaderLayout>;
  /** All values that are not contained in given list. */
  layout_not_in?: InputMaybe<Array<InputMaybe<HeaderLayout>>>;
  subnavLinks_every?: InputMaybe<CTextLinkWhereInput>;
  subnavLinks_none?: InputMaybe<CTextLinkWhereInput>;
  subnavLinks_some?: InputMaybe<CTextLinkWhereInput>;
};

export enum SeekHeaderOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LayoutAsc = 'layout_ASC',
  LayoutDesc = 'layout_DESC',
}

export type SeekHeaderParent = SectionHeader;

export type SeekHeaderParentConnectInput = {
  SectionHeader?: InputMaybe<SectionHeaderConnectInput>;
};

export type SeekHeaderParentCreateInput = {
  SectionHeader?: InputMaybe<SectionHeaderCreateInput>;
};

export type SeekHeaderParentCreateManyInlineInput = {
  /** Create and connect multiple existing SeekHeaderParent documents */
  create?: InputMaybe<Array<SeekHeaderParentCreateInput>>;
};

export type SeekHeaderParentCreateOneInlineInput = {
  /** Create and connect one SeekHeaderParent document */
  create?: InputMaybe<SeekHeaderParentCreateInput>;
};

export type SeekHeaderParentCreateWithPositionInput = {
  SectionHeader?: InputMaybe<SectionHeaderCreateWithPositionInput>;
};

export type SeekHeaderParentUpdateInput = {
  SectionHeader?: InputMaybe<SectionHeaderUpdateInput>;
};

export type SeekHeaderParentUpdateManyInlineInput = {
  /** Create and connect multiple SeekHeaderParent component instances */
  create?: InputMaybe<Array<SeekHeaderParentCreateWithPositionInput>>;
  /** Delete multiple SeekHeaderParent documents */
  delete?: InputMaybe<Array<SeekHeaderParentWhereUniqueInput>>;
  /** Update multiple SeekHeaderParent component instances */
  update?: InputMaybe<
    Array<SeekHeaderParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple SeekHeaderParent component instances */
  upsert?: InputMaybe<
    Array<SeekHeaderParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type SeekHeaderParentUpdateManyWithNestedWhereInput = {
  SectionHeader?: InputMaybe<SectionHeaderUpdateManyWithNestedWhereInput>;
};

export type SeekHeaderParentUpdateOneInlineInput = {
  /** Create and connect one SeekHeaderParent document */
  create?: InputMaybe<SeekHeaderParentCreateInput>;
  /** Delete currently connected SeekHeaderParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single SeekHeaderParent document */
  update?: InputMaybe<SeekHeaderParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single SeekHeaderParent document */
  upsert?: InputMaybe<SeekHeaderParentUpsertWithNestedWhereUniqueInput>;
};

export type SeekHeaderParentUpdateWithNestedWhereUniqueAndPositionInput = {
  SectionHeader?: InputMaybe<SectionHeaderUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type SeekHeaderParentUpdateWithNestedWhereUniqueInput = {
  SectionHeader?: InputMaybe<SectionHeaderUpdateWithNestedWhereUniqueInput>;
};

export type SeekHeaderParentUpsertWithNestedWhereUniqueAndPositionInput = {
  SectionHeader?: InputMaybe<SectionHeaderUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type SeekHeaderParentUpsertWithNestedWhereUniqueInput = {
  SectionHeader?: InputMaybe<SectionHeaderUpsertWithNestedWhereUniqueInput>;
};

export type SeekHeaderParentWhereInput = {
  SectionHeader?: InputMaybe<SectionHeaderWhereInput>;
};

export type SeekHeaderParentWhereUniqueInput = {
  SectionHeader?: InputMaybe<SectionHeaderWhereUniqueInput>;
};

export type SeekHeaderUpdateInput = {
  layout?: InputMaybe<HeaderLayout>;
  subnavLinks?: InputMaybe<CTextLinkUpdateManyInlineInput>;
};

export type SeekHeaderUpdateManyInlineInput = {
  /** Create and connect multiple SeekHeader component instances */
  create?: InputMaybe<Array<SeekHeaderCreateWithPositionInput>>;
  /** Delete multiple SeekHeader documents */
  delete?: InputMaybe<Array<SeekHeaderWhereUniqueInput>>;
  /** Update multiple SeekHeader component instances */
  update?: InputMaybe<
    Array<SeekHeaderUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple SeekHeader component instances */
  upsert?: InputMaybe<
    Array<SeekHeaderUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type SeekHeaderUpdateManyInput = {
  layout?: InputMaybe<HeaderLayout>;
};

export type SeekHeaderUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SeekHeaderUpdateManyInput;
  /** Document search */
  where: SeekHeaderWhereInput;
};

export type SeekHeaderUpdateOneInlineInput = {
  /** Create and connect one SeekHeader document */
  create?: InputMaybe<SeekHeaderCreateInput>;
  /** Delete currently connected SeekHeader document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single SeekHeader document */
  update?: InputMaybe<SeekHeaderUpdateWithNestedWhereUniqueInput>;
  /** Upsert single SeekHeader document */
  upsert?: InputMaybe<SeekHeaderUpsertWithNestedWhereUniqueInput>;
};

export type SeekHeaderUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SeekHeaderUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: SeekHeaderWhereUniqueInput;
};

export type SeekHeaderUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SeekHeaderUpdateInput;
  /** Unique document search */
  where: SeekHeaderWhereUniqueInput;
};

export type SeekHeaderUpsertInput = {
  /** Create document if it didn't exist */
  create: SeekHeaderCreateInput;
  /** Update document if it exists */
  update: SeekHeaderUpdateInput;
};

export type SeekHeaderUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SeekHeaderUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: SeekHeaderWhereUniqueInput;
};

export type SeekHeaderUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SeekHeaderUpsertInput;
  /** Unique document search */
  where: SeekHeaderWhereUniqueInput;
};

/** Identifies documents */
export type SeekHeaderWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SeekHeaderWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SeekHeaderWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SeekHeaderWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  layout?: InputMaybe<HeaderLayout>;
  /** All values that are contained in given list. */
  layout_in?: InputMaybe<Array<InputMaybe<HeaderLayout>>>;
  /** Any other value that exists and is not equal to the given value. */
  layout_not?: InputMaybe<HeaderLayout>;
  /** All values that are not contained in given list. */
  layout_not_in?: InputMaybe<Array<InputMaybe<HeaderLayout>>>;
  subnavLinks_every?: InputMaybe<CTextLinkWhereInput>;
  subnavLinks_none?: InputMaybe<CTextLinkWhereInput>;
  subnavLinks_some?: InputMaybe<CTextLinkWhereInput>;
};

/** References SeekHeader record uniquely */
export type SeekHeaderWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Seo = Entity & {
  __typename?: 'Seo';
  /** Description used on search engines. The recommended meta description length is max. 150 characters. */
  description?: Maybe<Scalars['String']>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** Open graph image content for the page. Facebook recommendations: Use images that are at least 1200 x 630 pixels for the best display on high resolution devices. At the minimum, you should use images that are 600 x 315 pixels to display link page posts with larger images. */
  image?: Maybe<Asset>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Seo>;
  /** Control search engines from caching the page. */
  noArchive?: Maybe<Scalars['Boolean']>;
  /** Control page from appearing on search engines. */
  noIndex?: Maybe<Scalars['Boolean']>;
  /** System stage field */
  stage: Stage;
  /** Title used on search engines. */
  title?: Maybe<Scalars['String']>;
};

export type SeoImageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
  where?: InputMaybe<AssetSingleRelationWhereInput>;
};

export type SeoLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type SeoConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: SeoWhereUniqueInput;
};

/** A connection to a list of items. */
export type SeoConnection = {
  __typename?: 'SeoConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<SeoEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type SeoCreateInput = {
  /** description input for default locale (default) */
  description?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<AssetCreateOneInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<SeoCreateLocalizationsInput>;
  noArchive?: InputMaybe<Scalars['Boolean']>;
  noIndex?: InputMaybe<Scalars['Boolean']>;
  /** title input for default locale (default) */
  title?: InputMaybe<Scalars['String']>;
};

export type SeoCreateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type SeoCreateLocalizationInput = {
  /** Localization input */
  data: SeoCreateLocalizationDataInput;
  locale: Locale;
};

export type SeoCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<SeoCreateLocalizationInput>>;
};

export type SeoCreateManyInlineInput = {
  /** Create and connect multiple existing Seo documents */
  create?: InputMaybe<Array<SeoCreateInput>>;
};

export type SeoCreateOneInlineInput = {
  /** Create and connect one Seo document */
  create?: InputMaybe<SeoCreateInput>;
};

export type SeoCreateWithPositionInput = {
  /** Document to create */
  data: SeoCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type SeoEdge = {
  __typename?: 'SeoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Seo;
};

/** Identifies documents */
export type SeoManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SeoWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SeoWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SeoWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AssetWhereInput>;
  noArchive?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  noArchive_not?: InputMaybe<Scalars['Boolean']>;
  noIndex?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  noIndex_not?: InputMaybe<Scalars['Boolean']>;
};

export enum SeoOrderByInput {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NoArchiveAsc = 'noArchive_ASC',
  NoArchiveDesc = 'noArchive_DESC',
  NoIndexAsc = 'noIndex_ASC',
  NoIndexDesc = 'noIndex_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type SeoParent = Article | Category | Page;

export type SeoParentConnectInput = {
  Article?: InputMaybe<ArticleConnectInput>;
  Category?: InputMaybe<CategoryConnectInput>;
  Page?: InputMaybe<PageConnectInput>;
};

export type SeoParentCreateInput = {
  Article?: InputMaybe<ArticleCreateInput>;
  Category?: InputMaybe<CategoryCreateInput>;
  Page?: InputMaybe<PageCreateInput>;
};

export type SeoParentCreateManyInlineInput = {
  /** Connect multiple existing SeoParent documents */
  connect?: InputMaybe<Array<SeoParentWhereUniqueInput>>;
  /** Create and connect multiple existing SeoParent documents */
  create?: InputMaybe<Array<SeoParentCreateInput>>;
};

export type SeoParentCreateOneInlineInput = {
  /** Connect one existing SeoParent document */
  connect?: InputMaybe<SeoParentWhereUniqueInput>;
  /** Create and connect one SeoParent document */
  create?: InputMaybe<SeoParentCreateInput>;
};

export type SeoParentUpdateInput = {
  Article?: InputMaybe<ArticleUpdateInput>;
  Category?: InputMaybe<CategoryUpdateInput>;
  Page?: InputMaybe<PageUpdateInput>;
};

export type SeoParentUpdateManyInlineInput = {
  /** Connect multiple existing SeoParent documents */
  connect?: InputMaybe<Array<SeoParentConnectInput>>;
  /** Create and connect multiple SeoParent documents */
  create?: InputMaybe<Array<SeoParentCreateInput>>;
  /** Delete multiple SeoParent documents */
  delete?: InputMaybe<Array<SeoParentWhereUniqueInput>>;
  /** Disconnect multiple SeoParent documents */
  disconnect?: InputMaybe<Array<SeoParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing SeoParent documents */
  set?: InputMaybe<Array<SeoParentWhereUniqueInput>>;
  /** Update multiple SeoParent documents */
  update?: InputMaybe<Array<SeoParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple SeoParent documents */
  upsert?: InputMaybe<Array<SeoParentUpsertWithNestedWhereUniqueInput>>;
};

export type SeoParentUpdateManyWithNestedWhereInput = {
  Article?: InputMaybe<ArticleUpdateManyWithNestedWhereInput>;
  Category?: InputMaybe<CategoryUpdateManyWithNestedWhereInput>;
  Page?: InputMaybe<PageUpdateManyWithNestedWhereInput>;
};

export type SeoParentUpdateOneInlineInput = {
  /** Connect existing SeoParent document */
  connect?: InputMaybe<SeoParentWhereUniqueInput>;
  /** Create and connect one SeoParent document */
  create?: InputMaybe<SeoParentCreateInput>;
  /** Delete currently connected SeoParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected SeoParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single SeoParent document */
  update?: InputMaybe<SeoParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single SeoParent document */
  upsert?: InputMaybe<SeoParentUpsertWithNestedWhereUniqueInput>;
};

export type SeoParentUpdateWithNestedWhereUniqueInput = {
  Article?: InputMaybe<ArticleUpdateWithNestedWhereUniqueInput>;
  Category?: InputMaybe<CategoryUpdateWithNestedWhereUniqueInput>;
  Page?: InputMaybe<PageUpdateWithNestedWhereUniqueInput>;
};

export type SeoParentUpsertWithNestedWhereUniqueInput = {
  Article?: InputMaybe<ArticleUpsertWithNestedWhereUniqueInput>;
  Category?: InputMaybe<CategoryUpsertWithNestedWhereUniqueInput>;
  Page?: InputMaybe<PageUpsertWithNestedWhereUniqueInput>;
};

export type SeoParentWhereInput = {
  Article?: InputMaybe<ArticleWhereInput>;
  Category?: InputMaybe<CategoryWhereInput>;
  Page?: InputMaybe<PageWhereInput>;
};

export type SeoParentWhereUniqueInput = {
  Article?: InputMaybe<ArticleWhereUniqueInput>;
  Category?: InputMaybe<CategoryWhereUniqueInput>;
  Page?: InputMaybe<PageWhereUniqueInput>;
};

export type SeoUpdateInput = {
  /** description input for default locale (default) */
  description?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<AssetUpdateOneInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<SeoUpdateLocalizationsInput>;
  noArchive?: InputMaybe<Scalars['Boolean']>;
  noIndex?: InputMaybe<Scalars['Boolean']>;
  /** title input for default locale (default) */
  title?: InputMaybe<Scalars['String']>;
};

export type SeoUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type SeoUpdateLocalizationInput = {
  data: SeoUpdateLocalizationDataInput;
  locale: Locale;
};

export type SeoUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<SeoCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<SeoUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<SeoUpsertLocalizationInput>>;
};

export type SeoUpdateManyInlineInput = {
  /** Create and connect multiple Seo component instances */
  create?: InputMaybe<Array<SeoCreateWithPositionInput>>;
  /** Delete multiple Seo documents */
  delete?: InputMaybe<Array<SeoWhereUniqueInput>>;
  /** Update multiple Seo component instances */
  update?: InputMaybe<Array<SeoUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple Seo component instances */
  upsert?: InputMaybe<Array<SeoUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type SeoUpdateManyInput = {
  /** description input for default locale (default) */
  description?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<SeoUpdateManyLocalizationsInput>;
  noArchive?: InputMaybe<Scalars['Boolean']>;
  noIndex?: InputMaybe<Scalars['Boolean']>;
  /** title input for default locale (default) */
  title?: InputMaybe<Scalars['String']>;
};

export type SeoUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type SeoUpdateManyLocalizationInput = {
  data: SeoUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type SeoUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<SeoUpdateManyLocalizationInput>>;
};

export type SeoUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SeoUpdateManyInput;
  /** Document search */
  where: SeoWhereInput;
};

export type SeoUpdateOneInlineInput = {
  /** Create and connect one Seo document */
  create?: InputMaybe<SeoCreateInput>;
  /** Delete currently connected Seo document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single Seo document */
  update?: InputMaybe<SeoUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Seo document */
  upsert?: InputMaybe<SeoUpsertWithNestedWhereUniqueInput>;
};

export type SeoUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SeoUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: SeoWhereUniqueInput;
};

export type SeoUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SeoUpdateInput;
  /** Unique document search */
  where: SeoWhereUniqueInput;
};

export type SeoUpsertInput = {
  /** Create document if it didn't exist */
  create: SeoCreateInput;
  /** Update document if it exists */
  update: SeoUpdateInput;
};

export type SeoUpsertLocalizationInput = {
  create: SeoCreateLocalizationDataInput;
  locale: Locale;
  update: SeoUpdateLocalizationDataInput;
};

export type SeoUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SeoUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: SeoWhereUniqueInput;
};

export type SeoUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SeoUpsertInput;
  /** Unique document search */
  where: SeoWhereUniqueInput;
};

/** Identifies documents */
export type SeoWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SeoWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SeoWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SeoWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<AssetWhereInput>;
  noArchive?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  noArchive_not?: InputMaybe<Scalars['Boolean']>;
  noIndex?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  noIndex_not?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']>;
};

/** References Seo record uniquely */
export type SeoWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Site = Entity &
  Node & {
    __typename?: 'Site';
    articles: Array<Article>;
    /** Represents a file for the site. */
    assets: Array<Asset>;
    authors: Array<Author>;
    bannerItems: Array<BannerItem>;
    categories: Array<Category>;
    /** The time the document was created */
    createdAt: Scalars['DateTime'];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<Site>;
    /** Only a max of 4 featured articles will be displayed */
    featuredArticles: Array<Article>;
    forms: Array<Form>;
    /** List of Site versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars['ID'];
    /** Represents site name. */
    name: Scalars['String'];
    pages: Array<Page>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    siteUsersConnection?: Maybe<SiteUsersConnection>;
    /** System stage field */
    stage: Stage;
    topics: Array<Topic>;
    /** The time the document was updated */
    updatedAt: Scalars['DateTime'];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
    /** Represents site URL. */
    url?: Maybe<Scalars['String']>;
  };

export type SiteArticlesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ArticleOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ArticleWhereInput>;
};

export type SiteAssetsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AssetWhereInput>;
};

export type SiteAuthorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AuthorOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AuthorWhereInput>;
};

export type SiteBannerItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<BannerItemOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BannerItemWhereInput>;
};

export type SiteCategoriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<CategoryOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CategoryWhereInput>;
};

export type SiteCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type SiteDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type SiteFeaturedArticlesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ArticleOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ArticleWhereInput>;
};

export type SiteFormsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<FormOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FormWhereInput>;
};

export type SiteHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};

export type SitePagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<PageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PageWhereInput>;
};

export type SitePublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type SiteScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type SiteSiteUsersConnectionArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type SiteTopicsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<TopicOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TopicWhereInput>;
};

export type SiteUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type SiteConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: SiteWhereUniqueInput;
};

/** A connection to a list of items. */
export type SiteConnection = {
  __typename?: 'SiteConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<SiteEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type SiteCreateInput = {
  articles?: InputMaybe<ArticleCreateManyInlineInput>;
  assets?: InputMaybe<AssetCreateManyInlineInput>;
  authors?: InputMaybe<AuthorCreateManyInlineInput>;
  bannerItems?: InputMaybe<BannerItemCreateManyInlineInput>;
  categories?: InputMaybe<CategoryCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  featuredArticles?: InputMaybe<ArticleCreateManyInlineInput>;
  forms?: InputMaybe<FormCreateManyInlineInput>;
  name: Scalars['String'];
  pages?: InputMaybe<PageCreateManyInlineInput>;
  siteUsersConnection?: InputMaybe<SiteUsersConnectionCreateOneInlineInput>;
  topics?: InputMaybe<TopicCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  url?: InputMaybe<Scalars['String']>;
};

export type SiteCreateManyInlineInput = {
  /** Connect multiple existing Site documents */
  connect?: InputMaybe<Array<SiteWhereUniqueInput>>;
  /** Create and connect multiple existing Site documents */
  create?: InputMaybe<Array<SiteCreateInput>>;
};

export type SiteCreateOneInlineInput = {
  /** Connect one existing Site document */
  connect?: InputMaybe<SiteWhereUniqueInput>;
  /** Create and connect one Site document */
  create?: InputMaybe<SiteCreateInput>;
};

/** An edge in a connection. */
export type SiteEdge = {
  __typename?: 'SiteEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Site;
};

/** Identifies documents */
export type SiteManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SiteWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SiteWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SiteWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  articles_every?: InputMaybe<ArticleWhereInput>;
  articles_none?: InputMaybe<ArticleWhereInput>;
  articles_some?: InputMaybe<ArticleWhereInput>;
  assets_every?: InputMaybe<AssetWhereInput>;
  assets_none?: InputMaybe<AssetWhereInput>;
  assets_some?: InputMaybe<AssetWhereInput>;
  authors_every?: InputMaybe<AuthorWhereInput>;
  authors_none?: InputMaybe<AuthorWhereInput>;
  authors_some?: InputMaybe<AuthorWhereInput>;
  bannerItems_every?: InputMaybe<BannerItemWhereInput>;
  bannerItems_none?: InputMaybe<BannerItemWhereInput>;
  bannerItems_some?: InputMaybe<BannerItemWhereInput>;
  categories_every?: InputMaybe<CategoryWhereInput>;
  categories_none?: InputMaybe<CategoryWhereInput>;
  categories_some?: InputMaybe<CategoryWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<SiteWhereStageInput>;
  documentInStages_none?: InputMaybe<SiteWhereStageInput>;
  documentInStages_some?: InputMaybe<SiteWhereStageInput>;
  featuredArticles_every?: InputMaybe<ArticleWhereInput>;
  featuredArticles_none?: InputMaybe<ArticleWhereInput>;
  featuredArticles_some?: InputMaybe<ArticleWhereInput>;
  forms_every?: InputMaybe<FormWhereInput>;
  forms_none?: InputMaybe<FormWhereInput>;
  forms_some?: InputMaybe<FormWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']>;
  pages_every?: InputMaybe<PageWhereInput>;
  pages_none?: InputMaybe<PageWhereInput>;
  pages_some?: InputMaybe<PageWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  siteUsersConnection?: InputMaybe<SiteUsersConnectionWhereInput>;
  topics_every?: InputMaybe<TopicWhereInput>;
  topics_none?: InputMaybe<TopicWhereInput>;
  topics_some?: InputMaybe<TopicWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  url?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  url_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  url_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  url_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  url_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  url_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  url_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  url_starts_with?: InputMaybe<Scalars['String']>;
};

export enum SiteOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
}

export type SiteUpdateInput = {
  articles?: InputMaybe<ArticleUpdateManyInlineInput>;
  assets?: InputMaybe<AssetUpdateManyInlineInput>;
  authors?: InputMaybe<AuthorUpdateManyInlineInput>;
  bannerItems?: InputMaybe<BannerItemUpdateManyInlineInput>;
  categories?: InputMaybe<CategoryUpdateManyInlineInput>;
  featuredArticles?: InputMaybe<ArticleUpdateManyInlineInput>;
  forms?: InputMaybe<FormUpdateManyInlineInput>;
  name?: InputMaybe<Scalars['String']>;
  pages?: InputMaybe<PageUpdateManyInlineInput>;
  siteUsersConnection?: InputMaybe<SiteUsersConnectionUpdateOneInlineInput>;
  topics?: InputMaybe<TopicUpdateManyInlineInput>;
  url?: InputMaybe<Scalars['String']>;
};

export type SiteUpdateManyInlineInput = {
  /** Connect multiple existing Site documents */
  connect?: InputMaybe<Array<SiteConnectInput>>;
  /** Create and connect multiple Site documents */
  create?: InputMaybe<Array<SiteCreateInput>>;
  /** Delete multiple Site documents */
  delete?: InputMaybe<Array<SiteWhereUniqueInput>>;
  /** Disconnect multiple Site documents */
  disconnect?: InputMaybe<Array<SiteWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Site documents */
  set?: InputMaybe<Array<SiteWhereUniqueInput>>;
  /** Update multiple Site documents */
  update?: InputMaybe<Array<SiteUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Site documents */
  upsert?: InputMaybe<Array<SiteUpsertWithNestedWhereUniqueInput>>;
};

export type SiteUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']>;
};

export type SiteUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SiteUpdateManyInput;
  /** Document search */
  where: SiteWhereInput;
};

export type SiteUpdateOneInlineInput = {
  /** Connect existing Site document */
  connect?: InputMaybe<SiteWhereUniqueInput>;
  /** Create and connect one Site document */
  create?: InputMaybe<SiteCreateInput>;
  /** Delete currently connected Site document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected Site document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single Site document */
  update?: InputMaybe<SiteUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Site document */
  upsert?: InputMaybe<SiteUpsertWithNestedWhereUniqueInput>;
};

export type SiteUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SiteUpdateInput;
  /** Unique document search */
  where: SiteWhereUniqueInput;
};

export type SiteUpsertInput = {
  /** Create document if it didn't exist */
  create: SiteCreateInput;
  /** Update document if it exists */
  update: SiteUpdateInput;
};

export type SiteUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SiteUpsertInput;
  /** Unique document search */
  where: SiteWhereUniqueInput;
};

export type SiteUsersConnection = Entity &
  Node & {
    __typename?: 'SiteUsersConnection';
    /** The time the document was created */
    createdAt: Scalars['DateTime'];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<SiteUsersConnection>;
    /** List of SiteUsersConnection versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars['ID'];
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    site?: Maybe<Site>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars['DateTime'];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
    users: Array<User>;
  };

export type SiteUsersConnectionCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type SiteUsersConnectionDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type SiteUsersConnectionHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};

export type SiteUsersConnectionPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type SiteUsersConnectionScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type SiteUsersConnectionSiteArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type SiteUsersConnectionUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type SiteUsersConnectionUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<UserOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};

export type SiteUsersConnectionConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: SiteUsersConnectionWhereUniqueInput;
};

/** A connection to a list of items. */
export type SiteUsersConnectionConnection = {
  __typename?: 'SiteUsersConnectionConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<SiteUsersConnectionEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type SiteUsersConnectionCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  site?: InputMaybe<SiteCreateOneInlineInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateManyInlineInput>;
};

export type SiteUsersConnectionCreateManyInlineInput = {
  /** Connect multiple existing SiteUsersConnection documents */
  connect?: InputMaybe<Array<SiteUsersConnectionWhereUniqueInput>>;
  /** Create and connect multiple existing SiteUsersConnection documents */
  create?: InputMaybe<Array<SiteUsersConnectionCreateInput>>;
};

export type SiteUsersConnectionCreateOneInlineInput = {
  /** Connect one existing SiteUsersConnection document */
  connect?: InputMaybe<SiteUsersConnectionWhereUniqueInput>;
  /** Create and connect one SiteUsersConnection document */
  create?: InputMaybe<SiteUsersConnectionCreateInput>;
};

/** An edge in a connection. */
export type SiteUsersConnectionEdge = {
  __typename?: 'SiteUsersConnectionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: SiteUsersConnection;
};

/** Identifies documents */
export type SiteUsersConnectionManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SiteUsersConnectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SiteUsersConnectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SiteUsersConnectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<SiteUsersConnectionWhereStageInput>;
  documentInStages_none?: InputMaybe<SiteUsersConnectionWhereStageInput>;
  documentInStages_some?: InputMaybe<SiteUsersConnectionWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  site?: InputMaybe<SiteWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  users_every?: InputMaybe<UserWhereInput>;
  users_none?: InputMaybe<UserWhereInput>;
  users_some?: InputMaybe<UserWhereInput>;
};

export enum SiteUsersConnectionOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type SiteUsersConnectionUpdateInput = {
  site?: InputMaybe<SiteUpdateOneInlineInput>;
  users?: InputMaybe<UserUpdateManyInlineInput>;
};

export type SiteUsersConnectionUpdateManyInlineInput = {
  /** Connect multiple existing SiteUsersConnection documents */
  connect?: InputMaybe<Array<SiteUsersConnectionConnectInput>>;
  /** Create and connect multiple SiteUsersConnection documents */
  create?: InputMaybe<Array<SiteUsersConnectionCreateInput>>;
  /** Delete multiple SiteUsersConnection documents */
  delete?: InputMaybe<Array<SiteUsersConnectionWhereUniqueInput>>;
  /** Disconnect multiple SiteUsersConnection documents */
  disconnect?: InputMaybe<Array<SiteUsersConnectionWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing SiteUsersConnection documents */
  set?: InputMaybe<Array<SiteUsersConnectionWhereUniqueInput>>;
  /** Update multiple SiteUsersConnection documents */
  update?: InputMaybe<
    Array<SiteUsersConnectionUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple SiteUsersConnection documents */
  upsert?: InputMaybe<
    Array<SiteUsersConnectionUpsertWithNestedWhereUniqueInput>
  >;
};

export type SiteUsersConnectionUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']>;
};

export type SiteUsersConnectionUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SiteUsersConnectionUpdateManyInput;
  /** Document search */
  where: SiteUsersConnectionWhereInput;
};

export type SiteUsersConnectionUpdateOneInlineInput = {
  /** Connect existing SiteUsersConnection document */
  connect?: InputMaybe<SiteUsersConnectionWhereUniqueInput>;
  /** Create and connect one SiteUsersConnection document */
  create?: InputMaybe<SiteUsersConnectionCreateInput>;
  /** Delete currently connected SiteUsersConnection document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected SiteUsersConnection document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single SiteUsersConnection document */
  update?: InputMaybe<SiteUsersConnectionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single SiteUsersConnection document */
  upsert?: InputMaybe<SiteUsersConnectionUpsertWithNestedWhereUniqueInput>;
};

export type SiteUsersConnectionUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SiteUsersConnectionUpdateInput;
  /** Unique document search */
  where: SiteUsersConnectionWhereUniqueInput;
};

export type SiteUsersConnectionUpsertInput = {
  /** Create document if it didn't exist */
  create: SiteUsersConnectionCreateInput;
  /** Update document if it exists */
  update: SiteUsersConnectionUpdateInput;
};

export type SiteUsersConnectionUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SiteUsersConnectionUpsertInput;
  /** Unique document search */
  where: SiteUsersConnectionWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type SiteUsersConnectionWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type SiteUsersConnectionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SiteUsersConnectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SiteUsersConnectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SiteUsersConnectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<SiteUsersConnectionWhereStageInput>;
  documentInStages_none?: InputMaybe<SiteUsersConnectionWhereStageInput>;
  documentInStages_some?: InputMaybe<SiteUsersConnectionWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  site?: InputMaybe<SiteWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  users_every?: InputMaybe<UserWhereInput>;
  users_none?: InputMaybe<UserWhereInput>;
  users_some?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type SiteUsersConnectionWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SiteUsersConnectionWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SiteUsersConnectionWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SiteUsersConnectionWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<SiteUsersConnectionWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References SiteUsersConnection record uniquely */
export type SiteUsersConnectionWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** This contains a set of filters that can be used to compare values internally */
export type SiteWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type SiteWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SiteWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SiteWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SiteWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  articles_every?: InputMaybe<ArticleWhereInput>;
  articles_none?: InputMaybe<ArticleWhereInput>;
  articles_some?: InputMaybe<ArticleWhereInput>;
  assets_every?: InputMaybe<AssetWhereInput>;
  assets_none?: InputMaybe<AssetWhereInput>;
  assets_some?: InputMaybe<AssetWhereInput>;
  authors_every?: InputMaybe<AuthorWhereInput>;
  authors_none?: InputMaybe<AuthorWhereInput>;
  authors_some?: InputMaybe<AuthorWhereInput>;
  bannerItems_every?: InputMaybe<BannerItemWhereInput>;
  bannerItems_none?: InputMaybe<BannerItemWhereInput>;
  bannerItems_some?: InputMaybe<BannerItemWhereInput>;
  categories_every?: InputMaybe<CategoryWhereInput>;
  categories_none?: InputMaybe<CategoryWhereInput>;
  categories_some?: InputMaybe<CategoryWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<SiteWhereStageInput>;
  documentInStages_none?: InputMaybe<SiteWhereStageInput>;
  documentInStages_some?: InputMaybe<SiteWhereStageInput>;
  featuredArticles_every?: InputMaybe<ArticleWhereInput>;
  featuredArticles_none?: InputMaybe<ArticleWhereInput>;
  featuredArticles_some?: InputMaybe<ArticleWhereInput>;
  forms_every?: InputMaybe<FormWhereInput>;
  forms_none?: InputMaybe<FormWhereInput>;
  forms_some?: InputMaybe<FormWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']>;
  pages_every?: InputMaybe<PageWhereInput>;
  pages_none?: InputMaybe<PageWhereInput>;
  pages_some?: InputMaybe<PageWhereInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  siteUsersConnection?: InputMaybe<SiteUsersConnectionWhereInput>;
  topics_every?: InputMaybe<TopicWhereInput>;
  topics_none?: InputMaybe<TopicWhereInput>;
  topics_some?: InputMaybe<TopicWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  url?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  url_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  url_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  url_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  url_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  url_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  url_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  url_starts_with?: InputMaybe<Scalars['String']>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type SiteWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SiteWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SiteWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SiteWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<SiteWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Site record uniquely */
export type SiteWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type SocialMediaShare = Entity & {
  __typename?: 'SocialMediaShare';
  /** Represents how the social media should be aligned */
  alignment?: Maybe<BoxJustifyContent>;
  bleedTop?: Maybe<SmsBleedTop>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System stage field */
  stage: Stage;
};

export type SocialMediaShareConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: SocialMediaShareWhereUniqueInput;
};

/** A connection to a list of items. */
export type SocialMediaShareConnection = {
  __typename?: 'SocialMediaShareConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<SocialMediaShareEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type SocialMediaShareCreateInput = {
  alignment?: InputMaybe<BoxJustifyContent>;
  bleedTop?: InputMaybe<SmsBleedTop>;
};

export type SocialMediaShareCreateManyInlineInput = {
  /** Create and connect multiple existing SocialMediaShare documents */
  create?: InputMaybe<Array<SocialMediaShareCreateInput>>;
};

export type SocialMediaShareCreateOneInlineInput = {
  /** Create and connect one SocialMediaShare document */
  create?: InputMaybe<SocialMediaShareCreateInput>;
};

export type SocialMediaShareCreateWithPositionInput = {
  /** Document to create */
  data: SocialMediaShareCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type SocialMediaShareEdge = {
  __typename?: 'SocialMediaShareEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: SocialMediaShare;
};

/** Identifies documents */
export type SocialMediaShareManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SocialMediaShareWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SocialMediaShareWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SocialMediaShareWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  alignment?: InputMaybe<BoxJustifyContent>;
  /** All values that are contained in given list. */
  alignment_in?: InputMaybe<Array<InputMaybe<BoxJustifyContent>>>;
  /** Any other value that exists and is not equal to the given value. */
  alignment_not?: InputMaybe<BoxJustifyContent>;
  /** All values that are not contained in given list. */
  alignment_not_in?: InputMaybe<Array<InputMaybe<BoxJustifyContent>>>;
  bleedTop?: InputMaybe<SmsBleedTop>;
  /** All values that are contained in given list. */
  bleedTop_in?: InputMaybe<Array<InputMaybe<SmsBleedTop>>>;
  /** Any other value that exists and is not equal to the given value. */
  bleedTop_not?: InputMaybe<SmsBleedTop>;
  /** All values that are not contained in given list. */
  bleedTop_not_in?: InputMaybe<Array<InputMaybe<SmsBleedTop>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
};

export enum SocialMediaShareOrderByInput {
  AlignmentAsc = 'alignment_ASC',
  AlignmentDesc = 'alignment_DESC',
  BleedTopAsc = 'bleedTop_ASC',
  BleedTopDesc = 'bleedTop_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type SocialMediaShareParent = CCustomComponent;

export type SocialMediaShareParentConnectInput = {
  CCustomComponent?: InputMaybe<CCustomComponentConnectInput>;
};

export type SocialMediaShareParentCreateInput = {
  CCustomComponent?: InputMaybe<CCustomComponentCreateInput>;
};

export type SocialMediaShareParentCreateManyInlineInput = {
  /** Create and connect multiple existing SocialMediaShareParent documents */
  create?: InputMaybe<Array<SocialMediaShareParentCreateInput>>;
};

export type SocialMediaShareParentCreateOneInlineInput = {
  /** Create and connect one SocialMediaShareParent document */
  create?: InputMaybe<SocialMediaShareParentCreateInput>;
};

export type SocialMediaShareParentCreateWithPositionInput = {
  CCustomComponent?: InputMaybe<CCustomComponentCreateWithPositionInput>;
};

export type SocialMediaShareParentUpdateInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpdateInput>;
};

export type SocialMediaShareParentUpdateManyInlineInput = {
  /** Create and connect multiple SocialMediaShareParent component instances */
  create?: InputMaybe<Array<SocialMediaShareParentCreateWithPositionInput>>;
  /** Delete multiple SocialMediaShareParent documents */
  delete?: InputMaybe<Array<SocialMediaShareParentWhereUniqueInput>>;
  /** Update multiple SocialMediaShareParent component instances */
  update?: InputMaybe<
    Array<SocialMediaShareParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple SocialMediaShareParent component instances */
  upsert?: InputMaybe<
    Array<SocialMediaShareParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type SocialMediaShareParentUpdateManyWithNestedWhereInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpdateManyWithNestedWhereInput>;
};

export type SocialMediaShareParentUpdateOneInlineInput = {
  /** Create and connect one SocialMediaShareParent document */
  create?: InputMaybe<SocialMediaShareParentCreateInput>;
  /** Delete currently connected SocialMediaShareParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single SocialMediaShareParent document */
  update?: InputMaybe<SocialMediaShareParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single SocialMediaShareParent document */
  upsert?: InputMaybe<SocialMediaShareParentUpsertWithNestedWhereUniqueInput>;
};

export type SocialMediaShareParentUpdateWithNestedWhereUniqueAndPositionInput =
  {
    CCustomComponent?: InputMaybe<CCustomComponentUpdateWithNestedWhereUniqueAndPositionInput>;
  };

export type SocialMediaShareParentUpdateWithNestedWhereUniqueInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpdateWithNestedWhereUniqueInput>;
};

export type SocialMediaShareParentUpsertWithNestedWhereUniqueAndPositionInput =
  {
    CCustomComponent?: InputMaybe<CCustomComponentUpsertWithNestedWhereUniqueAndPositionInput>;
  };

export type SocialMediaShareParentUpsertWithNestedWhereUniqueInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpsertWithNestedWhereUniqueInput>;
};

export type SocialMediaShareParentWhereInput = {
  CCustomComponent?: InputMaybe<CCustomComponentWhereInput>;
};

export type SocialMediaShareParentWhereUniqueInput = {
  CCustomComponent?: InputMaybe<CCustomComponentWhereUniqueInput>;
};

export type SocialMediaShareUpdateInput = {
  alignment?: InputMaybe<BoxJustifyContent>;
  bleedTop?: InputMaybe<SmsBleedTop>;
};

export type SocialMediaShareUpdateManyInlineInput = {
  /** Create and connect multiple SocialMediaShare component instances */
  create?: InputMaybe<Array<SocialMediaShareCreateWithPositionInput>>;
  /** Delete multiple SocialMediaShare documents */
  delete?: InputMaybe<Array<SocialMediaShareWhereUniqueInput>>;
  /** Update multiple SocialMediaShare component instances */
  update?: InputMaybe<
    Array<SocialMediaShareUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple SocialMediaShare component instances */
  upsert?: InputMaybe<
    Array<SocialMediaShareUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type SocialMediaShareUpdateManyInput = {
  alignment?: InputMaybe<BoxJustifyContent>;
  bleedTop?: InputMaybe<SmsBleedTop>;
};

export type SocialMediaShareUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SocialMediaShareUpdateManyInput;
  /** Document search */
  where: SocialMediaShareWhereInput;
};

export type SocialMediaShareUpdateOneInlineInput = {
  /** Create and connect one SocialMediaShare document */
  create?: InputMaybe<SocialMediaShareCreateInput>;
  /** Delete currently connected SocialMediaShare document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single SocialMediaShare document */
  update?: InputMaybe<SocialMediaShareUpdateWithNestedWhereUniqueInput>;
  /** Upsert single SocialMediaShare document */
  upsert?: InputMaybe<SocialMediaShareUpsertWithNestedWhereUniqueInput>;
};

export type SocialMediaShareUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SocialMediaShareUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: SocialMediaShareWhereUniqueInput;
};

export type SocialMediaShareUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SocialMediaShareUpdateInput;
  /** Unique document search */
  where: SocialMediaShareWhereUniqueInput;
};

export type SocialMediaShareUpsertInput = {
  /** Create document if it didn't exist */
  create: SocialMediaShareCreateInput;
  /** Update document if it exists */
  update: SocialMediaShareUpdateInput;
};

export type SocialMediaShareUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SocialMediaShareUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: SocialMediaShareWhereUniqueInput;
};

export type SocialMediaShareUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SocialMediaShareUpsertInput;
  /** Unique document search */
  where: SocialMediaShareWhereUniqueInput;
};

/** Identifies documents */
export type SocialMediaShareWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SocialMediaShareWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SocialMediaShareWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SocialMediaShareWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  alignment?: InputMaybe<BoxJustifyContent>;
  /** All values that are contained in given list. */
  alignment_in?: InputMaybe<Array<InputMaybe<BoxJustifyContent>>>;
  /** Any other value that exists and is not equal to the given value. */
  alignment_not?: InputMaybe<BoxJustifyContent>;
  /** All values that are not contained in given list. */
  alignment_not_in?: InputMaybe<Array<InputMaybe<BoxJustifyContent>>>;
  bleedTop?: InputMaybe<SmsBleedTop>;
  /** All values that are contained in given list. */
  bleedTop_in?: InputMaybe<Array<InputMaybe<SmsBleedTop>>>;
  /** Any other value that exists and is not equal to the given value. */
  bleedTop_not?: InputMaybe<SmsBleedTop>;
  /** All values that are not contained in given list. */
  bleedTop_not_in?: InputMaybe<Array<InputMaybe<SmsBleedTop>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
};

/** References SocialMediaShare record uniquely */
export type SocialMediaShareWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Space = Entity & {
  __typename?: 'Space';
  /** The unique identifier */
  id: Scalars['ID'];
  space: BraidSizes;
  /** System stage field */
  stage: Stage;
};

export type SpaceConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: SpaceWhereUniqueInput;
};

/** A connection to a list of items. */
export type SpaceConnection = {
  __typename?: 'SpaceConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<SpaceEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type SpaceCreateInput = {
  space: BraidSizes;
};

export type SpaceCreateManyInlineInput = {
  /** Create and connect multiple existing Space documents */
  create?: InputMaybe<Array<SpaceCreateInput>>;
};

export type SpaceCreateOneInlineInput = {
  /** Create and connect one Space document */
  create?: InputMaybe<SpaceCreateInput>;
};

export type SpaceCreateWithPositionInput = {
  /** Document to create */
  data: SpaceCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type SpaceEdge = {
  __typename?: 'SpaceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Space;
};

/** Identifies documents */
export type SpaceManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SpaceWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SpaceWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SpaceWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  space?: InputMaybe<BraidSizes>;
  /** All values that are contained in given list. */
  space_in?: InputMaybe<Array<InputMaybe<BraidSizes>>>;
  /** Any other value that exists and is not equal to the given value. */
  space_not?: InputMaybe<BraidSizes>;
  /** All values that are not contained in given list. */
  space_not_in?: InputMaybe<Array<InputMaybe<BraidSizes>>>;
};

export enum SpaceOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SpaceAsc = 'space_ASC',
  SpaceDesc = 'space_DESC',
}

export type SpaceParent = CHeading;

export type SpaceParentConnectInput = {
  CHeading?: InputMaybe<CHeadingConnectInput>;
};

export type SpaceParentCreateInput = {
  CHeading?: InputMaybe<CHeadingCreateInput>;
};

export type SpaceParentCreateManyInlineInput = {
  /** Create and connect multiple existing SpaceParent documents */
  create?: InputMaybe<Array<SpaceParentCreateInput>>;
};

export type SpaceParentCreateOneInlineInput = {
  /** Create and connect one SpaceParent document */
  create?: InputMaybe<SpaceParentCreateInput>;
};

export type SpaceParentCreateWithPositionInput = {
  CHeading?: InputMaybe<CHeadingCreateWithPositionInput>;
};

export type SpaceParentUpdateInput = {
  CHeading?: InputMaybe<CHeadingUpdateInput>;
};

export type SpaceParentUpdateManyInlineInput = {
  /** Create and connect multiple SpaceParent component instances */
  create?: InputMaybe<Array<SpaceParentCreateWithPositionInput>>;
  /** Delete multiple SpaceParent documents */
  delete?: InputMaybe<Array<SpaceParentWhereUniqueInput>>;
  /** Update multiple SpaceParent component instances */
  update?: InputMaybe<
    Array<SpaceParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple SpaceParent component instances */
  upsert?: InputMaybe<
    Array<SpaceParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type SpaceParentUpdateManyWithNestedWhereInput = {
  CHeading?: InputMaybe<CHeadingUpdateManyWithNestedWhereInput>;
};

export type SpaceParentUpdateOneInlineInput = {
  /** Create and connect one SpaceParent document */
  create?: InputMaybe<SpaceParentCreateInput>;
  /** Delete currently connected SpaceParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single SpaceParent document */
  update?: InputMaybe<SpaceParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single SpaceParent document */
  upsert?: InputMaybe<SpaceParentUpsertWithNestedWhereUniqueInput>;
};

export type SpaceParentUpdateWithNestedWhereUniqueAndPositionInput = {
  CHeading?: InputMaybe<CHeadingUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type SpaceParentUpdateWithNestedWhereUniqueInput = {
  CHeading?: InputMaybe<CHeadingUpdateWithNestedWhereUniqueInput>;
};

export type SpaceParentUpsertWithNestedWhereUniqueAndPositionInput = {
  CHeading?: InputMaybe<CHeadingUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type SpaceParentUpsertWithNestedWhereUniqueInput = {
  CHeading?: InputMaybe<CHeadingUpsertWithNestedWhereUniqueInput>;
};

export type SpaceParentWhereInput = {
  CHeading?: InputMaybe<CHeadingWhereInput>;
};

export type SpaceParentWhereUniqueInput = {
  CHeading?: InputMaybe<CHeadingWhereUniqueInput>;
};

export type SpaceUpdateInput = {
  space?: InputMaybe<BraidSizes>;
};

export type SpaceUpdateManyInlineInput = {
  /** Create and connect multiple Space component instances */
  create?: InputMaybe<Array<SpaceCreateWithPositionInput>>;
  /** Delete multiple Space documents */
  delete?: InputMaybe<Array<SpaceWhereUniqueInput>>;
  /** Update multiple Space component instances */
  update?: InputMaybe<Array<SpaceUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple Space component instances */
  upsert?: InputMaybe<Array<SpaceUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type SpaceUpdateManyInput = {
  space?: InputMaybe<BraidSizes>;
};

export type SpaceUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SpaceUpdateManyInput;
  /** Document search */
  where: SpaceWhereInput;
};

export type SpaceUpdateOneInlineInput = {
  /** Create and connect one Space document */
  create?: InputMaybe<SpaceCreateInput>;
  /** Delete currently connected Space document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single Space document */
  update?: InputMaybe<SpaceUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Space document */
  upsert?: InputMaybe<SpaceUpsertWithNestedWhereUniqueInput>;
};

export type SpaceUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SpaceUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: SpaceWhereUniqueInput;
};

export type SpaceUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SpaceUpdateInput;
  /** Unique document search */
  where: SpaceWhereUniqueInput;
};

export type SpaceUpsertInput = {
  /** Create document if it didn't exist */
  create: SpaceCreateInput;
  /** Update document if it exists */
  update: SpaceUpdateInput;
};

export type SpaceUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SpaceUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: SpaceWhereUniqueInput;
};

export type SpaceUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SpaceUpsertInput;
  /** Unique document search */
  where: SpaceWhereUniqueInput;
};

/** Identifies documents */
export type SpaceWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SpaceWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SpaceWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SpaceWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  space?: InputMaybe<BraidSizes>;
  /** All values that are contained in given list. */
  space_in?: InputMaybe<Array<InputMaybe<BraidSizes>>>;
  /** Any other value that exists and is not equal to the given value. */
  space_not?: InputMaybe<BraidSizes>;
  /** All values that are not contained in given list. */
  space_not_in?: InputMaybe<Array<InputMaybe<BraidSizes>>>;
};

/** References Space record uniquely */
export type SpaceWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Stage system enumeration */
export enum Stage {
  /** The Draft is the default stage for all your content. */
  Draft = 'DRAFT',
  /** The Published stage is where you can publish your content to. */
  Published = 'PUBLISHED',
}

export enum SystemDateTimeFieldVariation {
  Base = 'BASE',
  Combined = 'COMBINED',
  Localization = 'LOCALIZATION',
}

export type Tab = Entity & {
  __typename?: 'Tab';
  content: RichText;
  /** The unique identifier */
  id: Scalars['ID'];
  label: Scalars['String'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Tab>;
  /** System stage field */
  stage: Stage;
};

export type TabLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type TabConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: TabWhereUniqueInput;
};

/** A connection to a list of items. */
export type TabConnection = {
  __typename?: 'TabConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<TabEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type TabCreateInput = {
  /** content input for default locale (default) */
  content: Scalars['RichTextAST'];
  /** label input for default locale (default) */
  label: Scalars['String'];
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<TabCreateLocalizationsInput>;
};

export type TabCreateLocalizationDataInput = {
  content: Scalars['RichTextAST'];
  label: Scalars['String'];
};

export type TabCreateLocalizationInput = {
  /** Localization input */
  data: TabCreateLocalizationDataInput;
  locale: Locale;
};

export type TabCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<TabCreateLocalizationInput>>;
};

export type TabCreateManyInlineInput = {
  /** Create and connect multiple existing Tab documents */
  create?: InputMaybe<Array<TabCreateInput>>;
};

export type TabCreateOneInlineInput = {
  /** Create and connect one Tab document */
  create?: InputMaybe<TabCreateInput>;
};

export type TabCreateWithPositionInput = {
  /** Document to create */
  data: TabCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type TabEdge = {
  __typename?: 'TabEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Tab;
};

export type TabGroup = Entity & {
  __typename?: 'TabGroup';
  /** The unique identifier */
  id: Scalars['ID'];
  /** System stage field */
  stage: Stage;
  tabs: Array<Tab>;
};

export type TabGroupTabsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<TabOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TabWhereInput>;
};

export type TabGroupConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: TabGroupWhereUniqueInput;
};

/** A connection to a list of items. */
export type TabGroupConnection = {
  __typename?: 'TabGroupConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<TabGroupEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type TabGroupCreateInput = {
  tabs?: InputMaybe<TabCreateManyInlineInput>;
};

export type TabGroupCreateManyInlineInput = {
  /** Create and connect multiple existing TabGroup documents */
  create?: InputMaybe<Array<TabGroupCreateInput>>;
};

export type TabGroupCreateOneInlineInput = {
  /** Create and connect one TabGroup document */
  create?: InputMaybe<TabGroupCreateInput>;
};

export type TabGroupCreateWithPositionInput = {
  /** Document to create */
  data: TabGroupCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type TabGroupEdge = {
  __typename?: 'TabGroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: TabGroup;
};

/** Identifies documents */
export type TabGroupManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TabGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TabGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TabGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  tabs_every?: InputMaybe<TabWhereInput>;
  tabs_none?: InputMaybe<TabWhereInput>;
  tabs_some?: InputMaybe<TabWhereInput>;
};

export enum TabGroupOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type TabGroupParent = CBlock;

export type TabGroupParentConnectInput = {
  CBlock?: InputMaybe<CBlockConnectInput>;
};

export type TabGroupParentCreateInput = {
  CBlock?: InputMaybe<CBlockCreateInput>;
};

export type TabGroupParentCreateManyInlineInput = {
  /** Connect multiple existing TabGroupParent documents */
  connect?: InputMaybe<Array<TabGroupParentWhereUniqueInput>>;
  /** Create and connect multiple existing TabGroupParent documents */
  create?: InputMaybe<Array<TabGroupParentCreateInput>>;
};

export type TabGroupParentCreateOneInlineInput = {
  /** Connect one existing TabGroupParent document */
  connect?: InputMaybe<TabGroupParentWhereUniqueInput>;
  /** Create and connect one TabGroupParent document */
  create?: InputMaybe<TabGroupParentCreateInput>;
};

export type TabGroupParentUpdateInput = {
  CBlock?: InputMaybe<CBlockUpdateInput>;
};

export type TabGroupParentUpdateManyInlineInput = {
  /** Connect multiple existing TabGroupParent documents */
  connect?: InputMaybe<Array<TabGroupParentConnectInput>>;
  /** Create and connect multiple TabGroupParent documents */
  create?: InputMaybe<Array<TabGroupParentCreateInput>>;
  /** Delete multiple TabGroupParent documents */
  delete?: InputMaybe<Array<TabGroupParentWhereUniqueInput>>;
  /** Disconnect multiple TabGroupParent documents */
  disconnect?: InputMaybe<Array<TabGroupParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing TabGroupParent documents */
  set?: InputMaybe<Array<TabGroupParentWhereUniqueInput>>;
  /** Update multiple TabGroupParent documents */
  update?: InputMaybe<Array<TabGroupParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple TabGroupParent documents */
  upsert?: InputMaybe<Array<TabGroupParentUpsertWithNestedWhereUniqueInput>>;
};

export type TabGroupParentUpdateManyWithNestedWhereInput = {
  CBlock?: InputMaybe<CBlockUpdateManyWithNestedWhereInput>;
};

export type TabGroupParentUpdateOneInlineInput = {
  /** Connect existing TabGroupParent document */
  connect?: InputMaybe<TabGroupParentWhereUniqueInput>;
  /** Create and connect one TabGroupParent document */
  create?: InputMaybe<TabGroupParentCreateInput>;
  /** Delete currently connected TabGroupParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected TabGroupParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single TabGroupParent document */
  update?: InputMaybe<TabGroupParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single TabGroupParent document */
  upsert?: InputMaybe<TabGroupParentUpsertWithNestedWhereUniqueInput>;
};

export type TabGroupParentUpdateWithNestedWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockUpdateWithNestedWhereUniqueInput>;
};

export type TabGroupParentUpsertWithNestedWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockUpsertWithNestedWhereUniqueInput>;
};

export type TabGroupParentWhereInput = {
  CBlock?: InputMaybe<CBlockWhereInput>;
};

export type TabGroupParentWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockWhereUniqueInput>;
};

export type TabGroupUpdateInput = {
  tabs?: InputMaybe<TabUpdateManyInlineInput>;
};

export type TabGroupUpdateManyInlineInput = {
  /** Create and connect multiple TabGroup component instances */
  create?: InputMaybe<Array<TabGroupCreateWithPositionInput>>;
  /** Delete multiple TabGroup documents */
  delete?: InputMaybe<Array<TabGroupWhereUniqueInput>>;
  /** Update multiple TabGroup component instances */
  update?: InputMaybe<
    Array<TabGroupUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple TabGroup component instances */
  upsert?: InputMaybe<
    Array<TabGroupUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type TabGroupUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']>;
};

export type TabGroupUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: TabGroupUpdateManyInput;
  /** Document search */
  where: TabGroupWhereInput;
};

export type TabGroupUpdateOneInlineInput = {
  /** Create and connect one TabGroup document */
  create?: InputMaybe<TabGroupCreateInput>;
  /** Delete currently connected TabGroup document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single TabGroup document */
  update?: InputMaybe<TabGroupUpdateWithNestedWhereUniqueInput>;
  /** Upsert single TabGroup document */
  upsert?: InputMaybe<TabGroupUpsertWithNestedWhereUniqueInput>;
};

export type TabGroupUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<TabGroupUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: TabGroupWhereUniqueInput;
};

export type TabGroupUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: TabGroupUpdateInput;
  /** Unique document search */
  where: TabGroupWhereUniqueInput;
};

export type TabGroupUpsertInput = {
  /** Create document if it didn't exist */
  create: TabGroupCreateInput;
  /** Update document if it exists */
  update: TabGroupUpdateInput;
};

export type TabGroupUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<TabGroupUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: TabGroupWhereUniqueInput;
};

export type TabGroupUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: TabGroupUpsertInput;
  /** Unique document search */
  where: TabGroupWhereUniqueInput;
};

/** Identifies documents */
export type TabGroupWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TabGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TabGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TabGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  tabs_every?: InputMaybe<TabWhereInput>;
  tabs_none?: InputMaybe<TabWhereInput>;
  tabs_some?: InputMaybe<TabWhereInput>;
};

/** References TabGroup record uniquely */
export type TabGroupWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Identifies documents */
export type TabManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TabWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TabWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TabWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
};

export enum TabOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  LabelAsc = 'label_ASC',
  LabelDesc = 'label_DESC',
}

export type TabParent = TabGroup;

export type TabParentConnectInput = {
  TabGroup?: InputMaybe<TabGroupConnectInput>;
};

export type TabParentCreateInput = {
  TabGroup?: InputMaybe<TabGroupCreateInput>;
};

export type TabParentCreateManyInlineInput = {
  /** Create and connect multiple existing TabParent documents */
  create?: InputMaybe<Array<TabParentCreateInput>>;
};

export type TabParentCreateOneInlineInput = {
  /** Create and connect one TabParent document */
  create?: InputMaybe<TabParentCreateInput>;
};

export type TabParentCreateWithPositionInput = {
  TabGroup?: InputMaybe<TabGroupCreateWithPositionInput>;
};

export type TabParentUpdateInput = {
  TabGroup?: InputMaybe<TabGroupUpdateInput>;
};

export type TabParentUpdateManyInlineInput = {
  /** Create and connect multiple TabParent component instances */
  create?: InputMaybe<Array<TabParentCreateWithPositionInput>>;
  /** Delete multiple TabParent documents */
  delete?: InputMaybe<Array<TabParentWhereUniqueInput>>;
  /** Update multiple TabParent component instances */
  update?: InputMaybe<
    Array<TabParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple TabParent component instances */
  upsert?: InputMaybe<
    Array<TabParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type TabParentUpdateManyWithNestedWhereInput = {
  TabGroup?: InputMaybe<TabGroupUpdateManyWithNestedWhereInput>;
};

export type TabParentUpdateOneInlineInput = {
  /** Create and connect one TabParent document */
  create?: InputMaybe<TabParentCreateInput>;
  /** Delete currently connected TabParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single TabParent document */
  update?: InputMaybe<TabParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single TabParent document */
  upsert?: InputMaybe<TabParentUpsertWithNestedWhereUniqueInput>;
};

export type TabParentUpdateWithNestedWhereUniqueAndPositionInput = {
  TabGroup?: InputMaybe<TabGroupUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type TabParentUpdateWithNestedWhereUniqueInput = {
  TabGroup?: InputMaybe<TabGroupUpdateWithNestedWhereUniqueInput>;
};

export type TabParentUpsertWithNestedWhereUniqueAndPositionInput = {
  TabGroup?: InputMaybe<TabGroupUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type TabParentUpsertWithNestedWhereUniqueInput = {
  TabGroup?: InputMaybe<TabGroupUpsertWithNestedWhereUniqueInput>;
};

export type TabParentWhereInput = {
  TabGroup?: InputMaybe<TabGroupWhereInput>;
};

export type TabParentWhereUniqueInput = {
  TabGroup?: InputMaybe<TabGroupWhereUniqueInput>;
};

export type TabUpdateInput = {
  /** content input for default locale (default) */
  content?: InputMaybe<Scalars['RichTextAST']>;
  /** label input for default locale (default) */
  label?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<TabUpdateLocalizationsInput>;
};

export type TabUpdateLocalizationDataInput = {
  content?: InputMaybe<Scalars['RichTextAST']>;
  label?: InputMaybe<Scalars['String']>;
};

export type TabUpdateLocalizationInput = {
  data: TabUpdateLocalizationDataInput;
  locale: Locale;
};

export type TabUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<TabCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<TabUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<TabUpsertLocalizationInput>>;
};

export type TabUpdateManyInlineInput = {
  /** Create and connect multiple Tab component instances */
  create?: InputMaybe<Array<TabCreateWithPositionInput>>;
  /** Delete multiple Tab documents */
  delete?: InputMaybe<Array<TabWhereUniqueInput>>;
  /** Update multiple Tab component instances */
  update?: InputMaybe<Array<TabUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple Tab component instances */
  upsert?: InputMaybe<Array<TabUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type TabUpdateManyInput = {
  /** content input for default locale (default) */
  content?: InputMaybe<Scalars['RichTextAST']>;
  /** label input for default locale (default) */
  label?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<TabUpdateManyLocalizationsInput>;
};

export type TabUpdateManyLocalizationDataInput = {
  content?: InputMaybe<Scalars['RichTextAST']>;
  label?: InputMaybe<Scalars['String']>;
};

export type TabUpdateManyLocalizationInput = {
  data: TabUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type TabUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<TabUpdateManyLocalizationInput>>;
};

export type TabUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: TabUpdateManyInput;
  /** Document search */
  where: TabWhereInput;
};

export type TabUpdateOneInlineInput = {
  /** Create and connect one Tab document */
  create?: InputMaybe<TabCreateInput>;
  /** Delete currently connected Tab document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single Tab document */
  update?: InputMaybe<TabUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Tab document */
  upsert?: InputMaybe<TabUpsertWithNestedWhereUniqueInput>;
};

export type TabUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<TabUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: TabWhereUniqueInput;
};

export type TabUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: TabUpdateInput;
  /** Unique document search */
  where: TabWhereUniqueInput;
};

export type TabUpsertInput = {
  /** Create document if it didn't exist */
  create: TabCreateInput;
  /** Update document if it exists */
  update: TabUpdateInput;
};

export type TabUpsertLocalizationInput = {
  create: TabCreateLocalizationDataInput;
  locale: Locale;
  update: TabUpdateLocalizationDataInput;
};

export type TabUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<TabUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: TabWhereUniqueInput;
};

export type TabUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: TabUpsertInput;
  /** Unique document search */
  where: TabWhereUniqueInput;
};

/** Identifies documents */
export type TabWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TabWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TabWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TabWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  label?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  label_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  label_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  label_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  label_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  label_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  label_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  label_starts_with?: InputMaybe<Scalars['String']>;
};

/** References Tab record uniquely */
export type TabWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Testimonial = Entity & {
  __typename?: 'Testimonial';
  description?: Maybe<Scalars['String']>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Testimonial>;
  paragraph?: Maybe<RichText>;
  /** System stage field */
  stage: Stage;
  /** Represents how Testimonial content should be aligned */
  style?: Maybe<TestimonialStyle>;
  testimonialImage: Asset;
  title: Scalars['String'];
};

export type TestimonialLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type TestimonialTestimonialImageArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
  where?: InputMaybe<AssetSingleRelationWhereInput>;
};

export type TestimonialConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: TestimonialWhereUniqueInput;
};

/** A connection to a list of items. */
export type TestimonialConnection = {
  __typename?: 'TestimonialConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<TestimonialEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type TestimonialCreateInput = {
  /** description input for default locale (default) */
  description?: InputMaybe<Scalars['String']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<TestimonialCreateLocalizationsInput>;
  /** paragraph input for default locale (default) */
  paragraph?: InputMaybe<Scalars['RichTextAST']>;
  style?: InputMaybe<TestimonialStyle>;
  testimonialImage: AssetCreateOneInlineInput;
  /** title input for default locale (default) */
  title: Scalars['String'];
};

export type TestimonialCreateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']>;
  paragraph?: InputMaybe<Scalars['RichTextAST']>;
  title: Scalars['String'];
};

export type TestimonialCreateLocalizationInput = {
  /** Localization input */
  data: TestimonialCreateLocalizationDataInput;
  locale: Locale;
};

export type TestimonialCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<TestimonialCreateLocalizationInput>>;
};

export type TestimonialCreateManyInlineInput = {
  /** Create and connect multiple existing Testimonial documents */
  create?: InputMaybe<Array<TestimonialCreateInput>>;
};

export type TestimonialCreateOneInlineInput = {
  /** Create and connect one Testimonial document */
  create?: InputMaybe<TestimonialCreateInput>;
};

export type TestimonialCreateWithPositionInput = {
  /** Document to create */
  data: TestimonialCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type TestimonialEdge = {
  __typename?: 'TestimonialEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Testimonial;
};

export type TestimonialGroup = Entity & {
  __typename?: 'TestimonialGroup';
  heading?: Maybe<Scalars['String']>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<TestimonialGroup>;
  paragraph?: Maybe<Scalars['String']>;
  /** System stage field */
  stage: Stage;
  testimonials: Array<Testimonial>;
};

export type TestimonialGroupLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type TestimonialGroupTestimonialsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<TestimonialOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TestimonialWhereInput>;
};

export type TestimonialGroupConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: TestimonialGroupWhereUniqueInput;
};

/** A connection to a list of items. */
export type TestimonialGroupConnection = {
  __typename?: 'TestimonialGroupConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<TestimonialGroupEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type TestimonialGroupCreateInput = {
  /** heading input for default locale (default) */
  heading?: InputMaybe<Scalars['String']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<TestimonialGroupCreateLocalizationsInput>;
  /** paragraph input for default locale (default) */
  paragraph?: InputMaybe<Scalars['String']>;
  testimonials: TestimonialCreateManyInlineInput;
};

export type TestimonialGroupCreateLocalizationDataInput = {
  heading?: InputMaybe<Scalars['String']>;
  paragraph?: InputMaybe<Scalars['String']>;
};

export type TestimonialGroupCreateLocalizationInput = {
  /** Localization input */
  data: TestimonialGroupCreateLocalizationDataInput;
  locale: Locale;
};

export type TestimonialGroupCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<TestimonialGroupCreateLocalizationInput>>;
};

export type TestimonialGroupCreateManyInlineInput = {
  /** Create and connect multiple existing TestimonialGroup documents */
  create?: InputMaybe<Array<TestimonialGroupCreateInput>>;
};

export type TestimonialGroupCreateOneInlineInput = {
  /** Create and connect one TestimonialGroup document */
  create?: InputMaybe<TestimonialGroupCreateInput>;
};

export type TestimonialGroupCreateWithPositionInput = {
  /** Document to create */
  data: TestimonialGroupCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type TestimonialGroupEdge = {
  __typename?: 'TestimonialGroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: TestimonialGroup;
};

/** Identifies documents */
export type TestimonialGroupManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TestimonialGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TestimonialGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TestimonialGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  testimonials_every?: InputMaybe<TestimonialWhereInput>;
  testimonials_none?: InputMaybe<TestimonialWhereInput>;
  testimonials_some?: InputMaybe<TestimonialWhereInput>;
};

export enum TestimonialGroupOrderByInput {
  HeadingAsc = 'heading_ASC',
  HeadingDesc = 'heading_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ParagraphAsc = 'paragraph_ASC',
  ParagraphDesc = 'paragraph_DESC',
}

export type TestimonialGroupUpdateInput = {
  /** heading input for default locale (default) */
  heading?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<TestimonialGroupUpdateLocalizationsInput>;
  /** paragraph input for default locale (default) */
  paragraph?: InputMaybe<Scalars['String']>;
  testimonials?: InputMaybe<TestimonialUpdateManyInlineInput>;
};

export type TestimonialGroupUpdateLocalizationDataInput = {
  heading?: InputMaybe<Scalars['String']>;
  paragraph?: InputMaybe<Scalars['String']>;
};

export type TestimonialGroupUpdateLocalizationInput = {
  data: TestimonialGroupUpdateLocalizationDataInput;
  locale: Locale;
};

export type TestimonialGroupUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<TestimonialGroupCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<TestimonialGroupUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<TestimonialGroupUpsertLocalizationInput>>;
};

export type TestimonialGroupUpdateManyInlineInput = {
  /** Create and connect multiple TestimonialGroup component instances */
  create?: InputMaybe<Array<TestimonialGroupCreateWithPositionInput>>;
  /** Delete multiple TestimonialGroup documents */
  delete?: InputMaybe<Array<TestimonialGroupWhereUniqueInput>>;
  /** Update multiple TestimonialGroup component instances */
  update?: InputMaybe<
    Array<TestimonialGroupUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple TestimonialGroup component instances */
  upsert?: InputMaybe<
    Array<TestimonialGroupUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type TestimonialGroupUpdateManyInput = {
  /** heading input for default locale (default) */
  heading?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<TestimonialGroupUpdateManyLocalizationsInput>;
  /** paragraph input for default locale (default) */
  paragraph?: InputMaybe<Scalars['String']>;
};

export type TestimonialGroupUpdateManyLocalizationDataInput = {
  heading?: InputMaybe<Scalars['String']>;
  paragraph?: InputMaybe<Scalars['String']>;
};

export type TestimonialGroupUpdateManyLocalizationInput = {
  data: TestimonialGroupUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type TestimonialGroupUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<TestimonialGroupUpdateManyLocalizationInput>>;
};

export type TestimonialGroupUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: TestimonialGroupUpdateManyInput;
  /** Document search */
  where: TestimonialGroupWhereInput;
};

export type TestimonialGroupUpdateOneInlineInput = {
  /** Create and connect one TestimonialGroup document */
  create?: InputMaybe<TestimonialGroupCreateInput>;
  /** Delete currently connected TestimonialGroup document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single TestimonialGroup document */
  update?: InputMaybe<TestimonialGroupUpdateWithNestedWhereUniqueInput>;
  /** Upsert single TestimonialGroup document */
  upsert?: InputMaybe<TestimonialGroupUpsertWithNestedWhereUniqueInput>;
};

export type TestimonialGroupUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<TestimonialGroupUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: TestimonialGroupWhereUniqueInput;
};

export type TestimonialGroupUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: TestimonialGroupUpdateInput;
  /** Unique document search */
  where: TestimonialGroupWhereUniqueInput;
};

export type TestimonialGroupUpsertInput = {
  /** Create document if it didn't exist */
  create: TestimonialGroupCreateInput;
  /** Update document if it exists */
  update: TestimonialGroupUpdateInput;
};

export type TestimonialGroupUpsertLocalizationInput = {
  create: TestimonialGroupCreateLocalizationDataInput;
  locale: Locale;
  update: TestimonialGroupUpdateLocalizationDataInput;
};

export type TestimonialGroupUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<TestimonialGroupUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: TestimonialGroupWhereUniqueInput;
};

export type TestimonialGroupUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: TestimonialGroupUpsertInput;
  /** Unique document search */
  where: TestimonialGroupWhereUniqueInput;
};

/** Identifies documents */
export type TestimonialGroupWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TestimonialGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TestimonialGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TestimonialGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  heading?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  heading_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  heading_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  heading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  heading_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  heading_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  heading_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  heading_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  heading_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  paragraph?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  paragraph_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  paragraph_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  paragraph_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  paragraph_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  paragraph_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  paragraph_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  paragraph_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  paragraph_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  paragraph_starts_with?: InputMaybe<Scalars['String']>;
  testimonials_every?: InputMaybe<TestimonialWhereInput>;
  testimonials_none?: InputMaybe<TestimonialWhereInput>;
  testimonials_some?: InputMaybe<TestimonialWhereInput>;
};

/** References TestimonialGroup record uniquely */
export type TestimonialGroupWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Identifies documents */
export type TestimonialManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TestimonialWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TestimonialWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TestimonialWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  style?: InputMaybe<TestimonialStyle>;
  /** All values that are contained in given list. */
  style_in?: InputMaybe<Array<InputMaybe<TestimonialStyle>>>;
  /** Any other value that exists and is not equal to the given value. */
  style_not?: InputMaybe<TestimonialStyle>;
  /** All values that are not contained in given list. */
  style_not_in?: InputMaybe<Array<InputMaybe<TestimonialStyle>>>;
  testimonialImage?: InputMaybe<AssetWhereInput>;
};

export enum TestimonialOrderByInput {
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  StyleAsc = 'style_ASC',
  StyleDesc = 'style_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type TestimonialParent = TestimonialGroup;

export type TestimonialParentConnectInput = {
  TestimonialGroup?: InputMaybe<TestimonialGroupConnectInput>;
};

export type TestimonialParentCreateInput = {
  TestimonialGroup?: InputMaybe<TestimonialGroupCreateInput>;
};

export type TestimonialParentCreateManyInlineInput = {
  /** Create and connect multiple existing TestimonialParent documents */
  create?: InputMaybe<Array<TestimonialParentCreateInput>>;
};

export type TestimonialParentCreateOneInlineInput = {
  /** Create and connect one TestimonialParent document */
  create?: InputMaybe<TestimonialParentCreateInput>;
};

export type TestimonialParentCreateWithPositionInput = {
  TestimonialGroup?: InputMaybe<TestimonialGroupCreateWithPositionInput>;
};

export type TestimonialParentUpdateInput = {
  TestimonialGroup?: InputMaybe<TestimonialGroupUpdateInput>;
};

export type TestimonialParentUpdateManyInlineInput = {
  /** Create and connect multiple TestimonialParent component instances */
  create?: InputMaybe<Array<TestimonialParentCreateWithPositionInput>>;
  /** Delete multiple TestimonialParent documents */
  delete?: InputMaybe<Array<TestimonialParentWhereUniqueInput>>;
  /** Update multiple TestimonialParent component instances */
  update?: InputMaybe<
    Array<TestimonialParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple TestimonialParent component instances */
  upsert?: InputMaybe<
    Array<TestimonialParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type TestimonialParentUpdateManyWithNestedWhereInput = {
  TestimonialGroup?: InputMaybe<TestimonialGroupUpdateManyWithNestedWhereInput>;
};

export type TestimonialParentUpdateOneInlineInput = {
  /** Create and connect one TestimonialParent document */
  create?: InputMaybe<TestimonialParentCreateInput>;
  /** Delete currently connected TestimonialParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single TestimonialParent document */
  update?: InputMaybe<TestimonialParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single TestimonialParent document */
  upsert?: InputMaybe<TestimonialParentUpsertWithNestedWhereUniqueInput>;
};

export type TestimonialParentUpdateWithNestedWhereUniqueAndPositionInput = {
  TestimonialGroup?: InputMaybe<TestimonialGroupUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type TestimonialParentUpdateWithNestedWhereUniqueInput = {
  TestimonialGroup?: InputMaybe<TestimonialGroupUpdateWithNestedWhereUniqueInput>;
};

export type TestimonialParentUpsertWithNestedWhereUniqueAndPositionInput = {
  TestimonialGroup?: InputMaybe<TestimonialGroupUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type TestimonialParentUpsertWithNestedWhereUniqueInput = {
  TestimonialGroup?: InputMaybe<TestimonialGroupUpsertWithNestedWhereUniqueInput>;
};

export type TestimonialParentWhereInput = {
  TestimonialGroup?: InputMaybe<TestimonialGroupWhereInput>;
};

export type TestimonialParentWhereUniqueInput = {
  TestimonialGroup?: InputMaybe<TestimonialGroupWhereUniqueInput>;
};

/** Represents Testimonial styles */
export enum TestimonialStyle {
  Inline = 'Inline',
  Stack = 'Stack',
}

export type TestimonialUpdateInput = {
  /** description input for default locale (default) */
  description?: InputMaybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: InputMaybe<TestimonialUpdateLocalizationsInput>;
  /** paragraph input for default locale (default) */
  paragraph?: InputMaybe<Scalars['RichTextAST']>;
  style?: InputMaybe<TestimonialStyle>;
  testimonialImage?: InputMaybe<AssetUpdateOneInlineInput>;
  /** title input for default locale (default) */
  title?: InputMaybe<Scalars['String']>;
};

export type TestimonialUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']>;
  paragraph?: InputMaybe<Scalars['RichTextAST']>;
  title?: InputMaybe<Scalars['String']>;
};

export type TestimonialUpdateLocalizationInput = {
  data: TestimonialUpdateLocalizationDataInput;
  locale: Locale;
};

export type TestimonialUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<TestimonialCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<TestimonialUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<TestimonialUpsertLocalizationInput>>;
};

export type TestimonialUpdateManyInlineInput = {
  /** Create and connect multiple Testimonial component instances */
  create?: InputMaybe<Array<TestimonialCreateWithPositionInput>>;
  /** Delete multiple Testimonial documents */
  delete?: InputMaybe<Array<TestimonialWhereUniqueInput>>;
  /** Update multiple Testimonial component instances */
  update?: InputMaybe<
    Array<TestimonialUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple Testimonial component instances */
  upsert?: InputMaybe<
    Array<TestimonialUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type TestimonialUpdateManyInput = {
  /** description input for default locale (default) */
  description?: InputMaybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<TestimonialUpdateManyLocalizationsInput>;
  /** paragraph input for default locale (default) */
  paragraph?: InputMaybe<Scalars['RichTextAST']>;
  style?: InputMaybe<TestimonialStyle>;
  /** title input for default locale (default) */
  title?: InputMaybe<Scalars['String']>;
};

export type TestimonialUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['String']>;
  paragraph?: InputMaybe<Scalars['RichTextAST']>;
  title?: InputMaybe<Scalars['String']>;
};

export type TestimonialUpdateManyLocalizationInput = {
  data: TestimonialUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type TestimonialUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<TestimonialUpdateManyLocalizationInput>>;
};

export type TestimonialUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: TestimonialUpdateManyInput;
  /** Document search */
  where: TestimonialWhereInput;
};

export type TestimonialUpdateOneInlineInput = {
  /** Create and connect one Testimonial document */
  create?: InputMaybe<TestimonialCreateInput>;
  /** Delete currently connected Testimonial document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single Testimonial document */
  update?: InputMaybe<TestimonialUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Testimonial document */
  upsert?: InputMaybe<TestimonialUpsertWithNestedWhereUniqueInput>;
};

export type TestimonialUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<TestimonialUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: TestimonialWhereUniqueInput;
};

export type TestimonialUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: TestimonialUpdateInput;
  /** Unique document search */
  where: TestimonialWhereUniqueInput;
};

export type TestimonialUpsertInput = {
  /** Create document if it didn't exist */
  create: TestimonialCreateInput;
  /** Update document if it exists */
  update: TestimonialUpdateInput;
};

export type TestimonialUpsertLocalizationInput = {
  create: TestimonialCreateLocalizationDataInput;
  locale: Locale;
  update: TestimonialUpdateLocalizationDataInput;
};

export type TestimonialUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<TestimonialUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: TestimonialWhereUniqueInput;
};

export type TestimonialUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: TestimonialUpsertInput;
  /** Unique document search */
  where: TestimonialWhereUniqueInput;
};

/** Identifies documents */
export type TestimonialWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TestimonialWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TestimonialWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TestimonialWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  style?: InputMaybe<TestimonialStyle>;
  /** All values that are contained in given list. */
  style_in?: InputMaybe<Array<InputMaybe<TestimonialStyle>>>;
  /** Any other value that exists and is not equal to the given value. */
  style_not?: InputMaybe<TestimonialStyle>;
  /** All values that are not contained in given list. */
  style_not_in?: InputMaybe<Array<InputMaybe<TestimonialStyle>>>;
  testimonialImage?: InputMaybe<AssetWhereInput>;
  title?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars['String']>;
};

/** References Testimonial record uniquely */
export type TestimonialWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** A parent link with a selection of children links. Used for displaying a dropdown menu in navigation components. */
export type TextLinkGroup = Entity & {
  __typename?: 'TextLinkGroup';
  /** The unique identifier */
  id: Scalars['ID'];
  /** The primary link of the group */
  link?: Maybe<CTextLink>;
  /** System stage field */
  stage: Stage;
  /** Links to appear as children of the parent link */
  subLinks: Array<CTextLink>;
};

/** A parent link with a selection of children links. Used for displaying a dropdown menu in navigation components. */
export type TextLinkGroupLinkArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** A parent link with a selection of children links. Used for displaying a dropdown menu in navigation components. */
export type TextLinkGroupSubLinksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<CTextLinkOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CTextLinkWhereInput>;
};

export type TextLinkGroupConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: TextLinkGroupWhereUniqueInput;
};

/** A connection to a list of items. */
export type TextLinkGroupConnection = {
  __typename?: 'TextLinkGroupConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<TextLinkGroupEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type TextLinkGroupCreateInput = {
  link?: InputMaybe<CTextLinkCreateOneInlineInput>;
  subLinks?: InputMaybe<CTextLinkCreateManyInlineInput>;
};

export type TextLinkGroupCreateManyInlineInput = {
  /** Create and connect multiple existing TextLinkGroup documents */
  create?: InputMaybe<Array<TextLinkGroupCreateInput>>;
};

export type TextLinkGroupCreateOneInlineInput = {
  /** Create and connect one TextLinkGroup document */
  create?: InputMaybe<TextLinkGroupCreateInput>;
};

export type TextLinkGroupCreateWithPositionInput = {
  /** Document to create */
  data: TextLinkGroupCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type TextLinkGroupEdge = {
  __typename?: 'TextLinkGroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: TextLinkGroup;
};

/** Identifies documents */
export type TextLinkGroupManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TextLinkGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TextLinkGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TextLinkGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  link?: InputMaybe<CTextLinkWhereInput>;
  subLinks_every?: InputMaybe<CTextLinkWhereInput>;
  subLinks_none?: InputMaybe<CTextLinkWhereInput>;
  subLinks_some?: InputMaybe<CTextLinkWhereInput>;
};

export enum TextLinkGroupOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type TextLinkGroupParent = CustomHeader;

export type TextLinkGroupParentConnectInput = {
  CustomHeader?: InputMaybe<CustomHeaderConnectInput>;
};

export type TextLinkGroupParentCreateInput = {
  CustomHeader?: InputMaybe<CustomHeaderCreateInput>;
};

export type TextLinkGroupParentCreateManyInlineInput = {
  /** Create and connect multiple existing TextLinkGroupParent documents */
  create?: InputMaybe<Array<TextLinkGroupParentCreateInput>>;
};

export type TextLinkGroupParentCreateOneInlineInput = {
  /** Create and connect one TextLinkGroupParent document */
  create?: InputMaybe<TextLinkGroupParentCreateInput>;
};

export type TextLinkGroupParentCreateWithPositionInput = {
  CustomHeader?: InputMaybe<CustomHeaderCreateWithPositionInput>;
};

export type TextLinkGroupParentUpdateInput = {
  CustomHeader?: InputMaybe<CustomHeaderUpdateInput>;
};

export type TextLinkGroupParentUpdateManyInlineInput = {
  /** Create and connect multiple TextLinkGroupParent component instances */
  create?: InputMaybe<Array<TextLinkGroupParentCreateWithPositionInput>>;
  /** Delete multiple TextLinkGroupParent documents */
  delete?: InputMaybe<Array<TextLinkGroupParentWhereUniqueInput>>;
  /** Update multiple TextLinkGroupParent component instances */
  update?: InputMaybe<
    Array<TextLinkGroupParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple TextLinkGroupParent component instances */
  upsert?: InputMaybe<
    Array<TextLinkGroupParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type TextLinkGroupParentUpdateManyWithNestedWhereInput = {
  CustomHeader?: InputMaybe<CustomHeaderUpdateManyWithNestedWhereInput>;
};

export type TextLinkGroupParentUpdateOneInlineInput = {
  /** Create and connect one TextLinkGroupParent document */
  create?: InputMaybe<TextLinkGroupParentCreateInput>;
  /** Delete currently connected TextLinkGroupParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single TextLinkGroupParent document */
  update?: InputMaybe<TextLinkGroupParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single TextLinkGroupParent document */
  upsert?: InputMaybe<TextLinkGroupParentUpsertWithNestedWhereUniqueInput>;
};

export type TextLinkGroupParentUpdateWithNestedWhereUniqueAndPositionInput = {
  CustomHeader?: InputMaybe<CustomHeaderUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type TextLinkGroupParentUpdateWithNestedWhereUniqueInput = {
  CustomHeader?: InputMaybe<CustomHeaderUpdateWithNestedWhereUniqueInput>;
};

export type TextLinkGroupParentUpsertWithNestedWhereUniqueAndPositionInput = {
  CustomHeader?: InputMaybe<CustomHeaderUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type TextLinkGroupParentUpsertWithNestedWhereUniqueInput = {
  CustomHeader?: InputMaybe<CustomHeaderUpsertWithNestedWhereUniqueInput>;
};

export type TextLinkGroupParentWhereInput = {
  CustomHeader?: InputMaybe<CustomHeaderWhereInput>;
};

export type TextLinkGroupParentWhereUniqueInput = {
  CustomHeader?: InputMaybe<CustomHeaderWhereUniqueInput>;
};

export type TextLinkGroupUpdateInput = {
  link?: InputMaybe<CTextLinkUpdateOneInlineInput>;
  subLinks?: InputMaybe<CTextLinkUpdateManyInlineInput>;
};

export type TextLinkGroupUpdateManyInlineInput = {
  /** Create and connect multiple TextLinkGroup component instances */
  create?: InputMaybe<Array<TextLinkGroupCreateWithPositionInput>>;
  /** Delete multiple TextLinkGroup documents */
  delete?: InputMaybe<Array<TextLinkGroupWhereUniqueInput>>;
  /** Update multiple TextLinkGroup component instances */
  update?: InputMaybe<
    Array<TextLinkGroupUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple TextLinkGroup component instances */
  upsert?: InputMaybe<
    Array<TextLinkGroupUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type TextLinkGroupUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']>;
};

export type TextLinkGroupUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: TextLinkGroupUpdateManyInput;
  /** Document search */
  where: TextLinkGroupWhereInput;
};

export type TextLinkGroupUpdateOneInlineInput = {
  /** Create and connect one TextLinkGroup document */
  create?: InputMaybe<TextLinkGroupCreateInput>;
  /** Delete currently connected TextLinkGroup document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single TextLinkGroup document */
  update?: InputMaybe<TextLinkGroupUpdateWithNestedWhereUniqueInput>;
  /** Upsert single TextLinkGroup document */
  upsert?: InputMaybe<TextLinkGroupUpsertWithNestedWhereUniqueInput>;
};

export type TextLinkGroupUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<TextLinkGroupUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: TextLinkGroupWhereUniqueInput;
};

export type TextLinkGroupUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: TextLinkGroupUpdateInput;
  /** Unique document search */
  where: TextLinkGroupWhereUniqueInput;
};

export type TextLinkGroupUpsertInput = {
  /** Create document if it didn't exist */
  create: TextLinkGroupCreateInput;
  /** Update document if it exists */
  update: TextLinkGroupUpdateInput;
};

export type TextLinkGroupUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<TextLinkGroupUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: TextLinkGroupWhereUniqueInput;
};

export type TextLinkGroupUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: TextLinkGroupUpsertInput;
  /** Unique document search */
  where: TextLinkGroupWhereUniqueInput;
};

/** Identifies documents */
export type TextLinkGroupWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TextLinkGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TextLinkGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TextLinkGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  link?: InputMaybe<CTextLinkWhereInput>;
  subLinks_every?: InputMaybe<CTextLinkWhereInput>;
  subLinks_none?: InputMaybe<CTextLinkWhereInput>;
  subLinks_some?: InputMaybe<CTextLinkWhereInput>;
};

/** References TextLinkGroup record uniquely */
export type TextLinkGroupWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** text weight for braid text component */
export enum TextWeight {
  Medium = 'medium',
  Regular = 'regular',
  Strong = 'strong',
}

export enum Theme {
  Apac = 'apac',
  Sara = 'sara',
  SeekJobs = 'seekJobs',
  Star = 'star',
}

export type TipsCard = Entity & {
  __typename?: 'TipsCard';
  badge?: Maybe<Badge>;
  description: RichText;
  downloadButton?: Maybe<CButtonLink>;
  /** Icon for tip representation */
  icon?: Maybe<Icon>;
  /** The unique identifier */
  id: Scalars['ID'];
  inlineLink?: Maybe<CTextLink>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<TipsCard>;
  /** System stage field */
  stage: Stage;
};

export type TipsCardBadgeArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type TipsCardDownloadButtonArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type TipsCardInlineLinkArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type TipsCardLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type TipsCardConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: TipsCardWhereUniqueInput;
};

/** A connection to a list of items. */
export type TipsCardConnection = {
  __typename?: 'TipsCardConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<TipsCardEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type TipsCardCreateInput = {
  badge?: InputMaybe<BadgeCreateOneInlineInput>;
  /** description input for default locale (default) */
  description: Scalars['RichTextAST'];
  downloadButton?: InputMaybe<CButtonLinkCreateOneInlineInput>;
  icon?: InputMaybe<Icon>;
  inlineLink?: InputMaybe<CTextLinkCreateOneInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<TipsCardCreateLocalizationsInput>;
};

export type TipsCardCreateLocalizationDataInput = {
  description: Scalars['RichTextAST'];
};

export type TipsCardCreateLocalizationInput = {
  /** Localization input */
  data: TipsCardCreateLocalizationDataInput;
  locale: Locale;
};

export type TipsCardCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<TipsCardCreateLocalizationInput>>;
};

export type TipsCardCreateManyInlineInput = {
  /** Create and connect multiple existing TipsCard documents */
  create?: InputMaybe<Array<TipsCardCreateInput>>;
};

export type TipsCardCreateOneInlineInput = {
  /** Create and connect one TipsCard document */
  create?: InputMaybe<TipsCardCreateInput>;
};

export type TipsCardCreateWithPositionInput = {
  /** Document to create */
  data: TipsCardCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type TipsCardEdge = {
  __typename?: 'TipsCardEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: TipsCard;
};

export type TipsCardGroup = Entity & {
  __typename?: 'TipsCardGroup';
  /** The unique identifier */
  id: Scalars['ID'];
  items: Array<TipsCard>;
  /** System stage field */
  stage: Stage;
};

export type TipsCardGroupItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<TipsCardOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TipsCardWhereInput>;
};

export type TipsCardGroupConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: TipsCardGroupWhereUniqueInput;
};

/** A connection to a list of items. */
export type TipsCardGroupConnection = {
  __typename?: 'TipsCardGroupConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<TipsCardGroupEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type TipsCardGroupCreateInput = {
  items?: InputMaybe<TipsCardCreateManyInlineInput>;
};

export type TipsCardGroupCreateManyInlineInput = {
  /** Create and connect multiple existing TipsCardGroup documents */
  create?: InputMaybe<Array<TipsCardGroupCreateInput>>;
};

export type TipsCardGroupCreateOneInlineInput = {
  /** Create and connect one TipsCardGroup document */
  create?: InputMaybe<TipsCardGroupCreateInput>;
};

export type TipsCardGroupCreateWithPositionInput = {
  /** Document to create */
  data: TipsCardGroupCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type TipsCardGroupEdge = {
  __typename?: 'TipsCardGroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: TipsCardGroup;
};

/** Identifies documents */
export type TipsCardGroupManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TipsCardGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TipsCardGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TipsCardGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  items_every?: InputMaybe<TipsCardWhereInput>;
  items_none?: InputMaybe<TipsCardWhereInput>;
  items_some?: InputMaybe<TipsCardWhereInput>;
};

export enum TipsCardGroupOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type TipsCardGroupParent = Datalab;

export type TipsCardGroupParentConnectInput = {
  Datalab?: InputMaybe<DatalabConnectInput>;
};

export type TipsCardGroupParentCreateInput = {
  Datalab?: InputMaybe<DatalabCreateInput>;
};

export type TipsCardGroupParentCreateManyInlineInput = {
  /** Create and connect multiple existing TipsCardGroupParent documents */
  create?: InputMaybe<Array<TipsCardGroupParentCreateInput>>;
};

export type TipsCardGroupParentCreateOneInlineInput = {
  /** Create and connect one TipsCardGroupParent document */
  create?: InputMaybe<TipsCardGroupParentCreateInput>;
};

export type TipsCardGroupParentCreateWithPositionInput = {
  Datalab?: InputMaybe<DatalabCreateWithPositionInput>;
};

export type TipsCardGroupParentUpdateInput = {
  Datalab?: InputMaybe<DatalabUpdateInput>;
};

export type TipsCardGroupParentUpdateManyInlineInput = {
  /** Create and connect multiple TipsCardGroupParent component instances */
  create?: InputMaybe<Array<TipsCardGroupParentCreateWithPositionInput>>;
  /** Delete multiple TipsCardGroupParent documents */
  delete?: InputMaybe<Array<TipsCardGroupParentWhereUniqueInput>>;
  /** Update multiple TipsCardGroupParent component instances */
  update?: InputMaybe<
    Array<TipsCardGroupParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple TipsCardGroupParent component instances */
  upsert?: InputMaybe<
    Array<TipsCardGroupParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type TipsCardGroupParentUpdateManyWithNestedWhereInput = {
  Datalab?: InputMaybe<DatalabUpdateManyWithNestedWhereInput>;
};

export type TipsCardGroupParentUpdateOneInlineInput = {
  /** Create and connect one TipsCardGroupParent document */
  create?: InputMaybe<TipsCardGroupParentCreateInput>;
  /** Delete currently connected TipsCardGroupParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single TipsCardGroupParent document */
  update?: InputMaybe<TipsCardGroupParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single TipsCardGroupParent document */
  upsert?: InputMaybe<TipsCardGroupParentUpsertWithNestedWhereUniqueInput>;
};

export type TipsCardGroupParentUpdateWithNestedWhereUniqueAndPositionInput = {
  Datalab?: InputMaybe<DatalabUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type TipsCardGroupParentUpdateWithNestedWhereUniqueInput = {
  Datalab?: InputMaybe<DatalabUpdateWithNestedWhereUniqueInput>;
};

export type TipsCardGroupParentUpsertWithNestedWhereUniqueAndPositionInput = {
  Datalab?: InputMaybe<DatalabUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type TipsCardGroupParentUpsertWithNestedWhereUniqueInput = {
  Datalab?: InputMaybe<DatalabUpsertWithNestedWhereUniqueInput>;
};

export type TipsCardGroupParentWhereInput = {
  Datalab?: InputMaybe<DatalabWhereInput>;
};

export type TipsCardGroupParentWhereUniqueInput = {
  Datalab?: InputMaybe<DatalabWhereUniqueInput>;
};

export type TipsCardGroupUpdateInput = {
  items?: InputMaybe<TipsCardUpdateManyInlineInput>;
};

export type TipsCardGroupUpdateManyInlineInput = {
  /** Create and connect multiple TipsCardGroup component instances */
  create?: InputMaybe<Array<TipsCardGroupCreateWithPositionInput>>;
  /** Delete multiple TipsCardGroup documents */
  delete?: InputMaybe<Array<TipsCardGroupWhereUniqueInput>>;
  /** Update multiple TipsCardGroup component instances */
  update?: InputMaybe<
    Array<TipsCardGroupUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple TipsCardGroup component instances */
  upsert?: InputMaybe<
    Array<TipsCardGroupUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type TipsCardGroupUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']>;
};

export type TipsCardGroupUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: TipsCardGroupUpdateManyInput;
  /** Document search */
  where: TipsCardGroupWhereInput;
};

export type TipsCardGroupUpdateOneInlineInput = {
  /** Create and connect one TipsCardGroup document */
  create?: InputMaybe<TipsCardGroupCreateInput>;
  /** Delete currently connected TipsCardGroup document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single TipsCardGroup document */
  update?: InputMaybe<TipsCardGroupUpdateWithNestedWhereUniqueInput>;
  /** Upsert single TipsCardGroup document */
  upsert?: InputMaybe<TipsCardGroupUpsertWithNestedWhereUniqueInput>;
};

export type TipsCardGroupUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<TipsCardGroupUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: TipsCardGroupWhereUniqueInput;
};

export type TipsCardGroupUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: TipsCardGroupUpdateInput;
  /** Unique document search */
  where: TipsCardGroupWhereUniqueInput;
};

export type TipsCardGroupUpsertInput = {
  /** Create document if it didn't exist */
  create: TipsCardGroupCreateInput;
  /** Update document if it exists */
  update: TipsCardGroupUpdateInput;
};

export type TipsCardGroupUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<TipsCardGroupUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: TipsCardGroupWhereUniqueInput;
};

export type TipsCardGroupUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: TipsCardGroupUpsertInput;
  /** Unique document search */
  where: TipsCardGroupWhereUniqueInput;
};

/** Identifies documents */
export type TipsCardGroupWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TipsCardGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TipsCardGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TipsCardGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  items_every?: InputMaybe<TipsCardWhereInput>;
  items_none?: InputMaybe<TipsCardWhereInput>;
  items_some?: InputMaybe<TipsCardWhereInput>;
};

/** References TipsCardGroup record uniquely */
export type TipsCardGroupWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Identifies documents */
export type TipsCardManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TipsCardWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TipsCardWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TipsCardWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  badge?: InputMaybe<BadgeWhereInput>;
  downloadButton?: InputMaybe<CButtonLinkWhereInput>;
  icon?: InputMaybe<Icon>;
  /** All values that are contained in given list. */
  icon_in?: InputMaybe<Array<InputMaybe<Icon>>>;
  /** Any other value that exists and is not equal to the given value. */
  icon_not?: InputMaybe<Icon>;
  /** All values that are not contained in given list. */
  icon_not_in?: InputMaybe<Array<InputMaybe<Icon>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  inlineLink?: InputMaybe<CTextLinkWhereInput>;
};

export enum TipsCardOrderByInput {
  IconAsc = 'icon_ASC',
  IconDesc = 'icon_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type TipsCardParent = TipsCardGroup;

export type TipsCardParentConnectInput = {
  TipsCardGroup?: InputMaybe<TipsCardGroupConnectInput>;
};

export type TipsCardParentCreateInput = {
  TipsCardGroup?: InputMaybe<TipsCardGroupCreateInput>;
};

export type TipsCardParentCreateManyInlineInput = {
  /** Create and connect multiple existing TipsCardParent documents */
  create?: InputMaybe<Array<TipsCardParentCreateInput>>;
};

export type TipsCardParentCreateOneInlineInput = {
  /** Create and connect one TipsCardParent document */
  create?: InputMaybe<TipsCardParentCreateInput>;
};

export type TipsCardParentCreateWithPositionInput = {
  TipsCardGroup?: InputMaybe<TipsCardGroupCreateWithPositionInput>;
};

export type TipsCardParentUpdateInput = {
  TipsCardGroup?: InputMaybe<TipsCardGroupUpdateInput>;
};

export type TipsCardParentUpdateManyInlineInput = {
  /** Create and connect multiple TipsCardParent component instances */
  create?: InputMaybe<Array<TipsCardParentCreateWithPositionInput>>;
  /** Delete multiple TipsCardParent documents */
  delete?: InputMaybe<Array<TipsCardParentWhereUniqueInput>>;
  /** Update multiple TipsCardParent component instances */
  update?: InputMaybe<
    Array<TipsCardParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple TipsCardParent component instances */
  upsert?: InputMaybe<
    Array<TipsCardParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type TipsCardParentUpdateManyWithNestedWhereInput = {
  TipsCardGroup?: InputMaybe<TipsCardGroupUpdateManyWithNestedWhereInput>;
};

export type TipsCardParentUpdateOneInlineInput = {
  /** Create and connect one TipsCardParent document */
  create?: InputMaybe<TipsCardParentCreateInput>;
  /** Delete currently connected TipsCardParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single TipsCardParent document */
  update?: InputMaybe<TipsCardParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single TipsCardParent document */
  upsert?: InputMaybe<TipsCardParentUpsertWithNestedWhereUniqueInput>;
};

export type TipsCardParentUpdateWithNestedWhereUniqueAndPositionInput = {
  TipsCardGroup?: InputMaybe<TipsCardGroupUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type TipsCardParentUpdateWithNestedWhereUniqueInput = {
  TipsCardGroup?: InputMaybe<TipsCardGroupUpdateWithNestedWhereUniqueInput>;
};

export type TipsCardParentUpsertWithNestedWhereUniqueAndPositionInput = {
  TipsCardGroup?: InputMaybe<TipsCardGroupUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type TipsCardParentUpsertWithNestedWhereUniqueInput = {
  TipsCardGroup?: InputMaybe<TipsCardGroupUpsertWithNestedWhereUniqueInput>;
};

export type TipsCardParentWhereInput = {
  TipsCardGroup?: InputMaybe<TipsCardGroupWhereInput>;
};

export type TipsCardParentWhereUniqueInput = {
  TipsCardGroup?: InputMaybe<TipsCardGroupWhereUniqueInput>;
};

export type TipsCardUpdateInput = {
  badge?: InputMaybe<BadgeUpdateOneInlineInput>;
  /** description input for default locale (default) */
  description?: InputMaybe<Scalars['RichTextAST']>;
  downloadButton?: InputMaybe<CButtonLinkUpdateOneInlineInput>;
  icon?: InputMaybe<Icon>;
  inlineLink?: InputMaybe<CTextLinkUpdateOneInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<TipsCardUpdateLocalizationsInput>;
};

export type TipsCardUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['RichTextAST']>;
};

export type TipsCardUpdateLocalizationInput = {
  data: TipsCardUpdateLocalizationDataInput;
  locale: Locale;
};

export type TipsCardUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<TipsCardCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<TipsCardUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<TipsCardUpsertLocalizationInput>>;
};

export type TipsCardUpdateManyInlineInput = {
  /** Create and connect multiple TipsCard component instances */
  create?: InputMaybe<Array<TipsCardCreateWithPositionInput>>;
  /** Delete multiple TipsCard documents */
  delete?: InputMaybe<Array<TipsCardWhereUniqueInput>>;
  /** Update multiple TipsCard component instances */
  update?: InputMaybe<
    Array<TipsCardUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple TipsCard component instances */
  upsert?: InputMaybe<
    Array<TipsCardUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type TipsCardUpdateManyInput = {
  /** description input for default locale (default) */
  description?: InputMaybe<Scalars['RichTextAST']>;
  icon?: InputMaybe<Icon>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<TipsCardUpdateManyLocalizationsInput>;
};

export type TipsCardUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['RichTextAST']>;
};

export type TipsCardUpdateManyLocalizationInput = {
  data: TipsCardUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type TipsCardUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<TipsCardUpdateManyLocalizationInput>>;
};

export type TipsCardUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: TipsCardUpdateManyInput;
  /** Document search */
  where: TipsCardWhereInput;
};

export type TipsCardUpdateOneInlineInput = {
  /** Create and connect one TipsCard document */
  create?: InputMaybe<TipsCardCreateInput>;
  /** Delete currently connected TipsCard document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single TipsCard document */
  update?: InputMaybe<TipsCardUpdateWithNestedWhereUniqueInput>;
  /** Upsert single TipsCard document */
  upsert?: InputMaybe<TipsCardUpsertWithNestedWhereUniqueInput>;
};

export type TipsCardUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<TipsCardUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: TipsCardWhereUniqueInput;
};

export type TipsCardUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: TipsCardUpdateInput;
  /** Unique document search */
  where: TipsCardWhereUniqueInput;
};

export type TipsCardUpsertInput = {
  /** Create document if it didn't exist */
  create: TipsCardCreateInput;
  /** Update document if it exists */
  update: TipsCardUpdateInput;
};

export type TipsCardUpsertLocalizationInput = {
  create: TipsCardCreateLocalizationDataInput;
  locale: Locale;
  update: TipsCardUpdateLocalizationDataInput;
};

export type TipsCardUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<TipsCardUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: TipsCardWhereUniqueInput;
};

export type TipsCardUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: TipsCardUpsertInput;
  /** Unique document search */
  where: TipsCardWhereUniqueInput;
};

/** Identifies documents */
export type TipsCardWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TipsCardWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TipsCardWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TipsCardWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  badge?: InputMaybe<BadgeWhereInput>;
  downloadButton?: InputMaybe<CButtonLinkWhereInput>;
  icon?: InputMaybe<Icon>;
  /** All values that are contained in given list. */
  icon_in?: InputMaybe<Array<InputMaybe<Icon>>>;
  /** Any other value that exists and is not equal to the given value. */
  icon_not?: InputMaybe<Icon>;
  /** All values that are not contained in given list. */
  icon_not_in?: InputMaybe<Array<InputMaybe<Icon>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  inlineLink?: InputMaybe<CTextLinkWhereInput>;
};

/** References TipsCard record uniquely */
export type TipsCardWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Represents badge tone */
export enum Tone {
  Caution = 'caution',
  Critical = 'critical',
  Info = 'info',
  Neutral = 'neutral',
  Positive = 'positive',
  Promote = 'promote',
}

export type Topic = Entity &
  Node & {
    __typename?: 'Topic';
    /** The time the document was created */
    createdAt: Scalars['DateTime'];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** The description will be used to describe the topic. */
    description?: Maybe<RichText>;
    /** Get the document in other stages */
    documentInStages: Array<Topic>;
    /** List of Topic versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars['ID'];
    /** System Locale field */
    locale: Locale;
    /** Get the other localizations for this document */
    localizations: Array<Topic>;
    /** Topic name will be used in articles. */
    name: Scalars['String'];
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    relatedArticlesInTopic: Array<Article>;
    scheduledIn: Array<ScheduledOperation>;
    sites: Array<Site>;
    /** Represents a topic name that contains keywords separated by hyphens. */
    slug: Scalars['String'];
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars['DateTime'];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type TopicCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type TopicCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type TopicDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

export type TopicHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: InputMaybe<Stage>;
};

export type TopicLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type TopicPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type TopicPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type TopicRelatedArticlesInTopicArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ArticleOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ArticleWhereInput>;
};

export type TopicScheduledInArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type TopicSitesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<SiteOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SiteWhereInput>;
};

export type TopicUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type TopicUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type TopicConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: TopicWhereUniqueInput;
};

/** A connection to a list of items. */
export type TopicConnection = {
  __typename?: 'TopicConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<TopicEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type TopicCreateInput = {
  clyzq76cz048807tbbpd3cp4x?: InputMaybe<TopicListCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** description input for default locale (default) */
  description?: InputMaybe<Scalars['RichTextAST']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<TopicCreateLocalizationsInput>;
  /** name input for default locale (default) */
  name: Scalars['String'];
  relatedArticlesInTopic?: InputMaybe<ArticleCreateManyInlineInput>;
  sites?: InputMaybe<SiteCreateManyInlineInput>;
  slug: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TopicCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['RichTextAST']>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TopicCreateLocalizationInput = {
  /** Localization input */
  data: TopicCreateLocalizationDataInput;
  locale: Locale;
};

export type TopicCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<TopicCreateLocalizationInput>>;
};

export type TopicCreateManyInlineInput = {
  /** Connect multiple existing Topic documents */
  connect?: InputMaybe<Array<TopicWhereUniqueInput>>;
  /** Create and connect multiple existing Topic documents */
  create?: InputMaybe<Array<TopicCreateInput>>;
};

export type TopicCreateOneInlineInput = {
  /** Connect one existing Topic document */
  connect?: InputMaybe<TopicWhereUniqueInput>;
  /** Create and connect one Topic document */
  create?: InputMaybe<TopicCreateInput>;
};

/** An edge in a connection. */
export type TopicEdge = {
  __typename?: 'TopicEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Topic;
};

export type TopicList = Entity & {
  __typename?: 'TopicList';
  /** Fields of information to display on the Article Cards, if available on their respective Article */
  cardFields: Array<ArticleFeature>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<TopicList>;
  /** If enabled, the first topic will be selected as the default topic for the topic dropdown filter. */
  setDefaultTopic?: Maybe<Scalars['Boolean']>;
  /** System stage field */
  stage: Stage;
  /** Topic Dropdown Filter Label (text to display for the dropdown) */
  topicFilterLabel: Scalars['String'];
  /** Topic Dropdown Filter Placeholder (text to display in the dropdown when no  value are selected) */
  topicFilterPlaceholder?: Maybe<Scalars['String']>;
  topics: Array<Topic>;
};

export type TopicListLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type TopicListTopicsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<TopicOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TopicWhereInput>;
};

export type TopicListConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: TopicListWhereUniqueInput;
};

/** A connection to a list of items. */
export type TopicListConnection = {
  __typename?: 'TopicListConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<TopicListEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type TopicListCreateInput = {
  cardFields?: InputMaybe<Array<ArticleFeature>>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<TopicListCreateLocalizationsInput>;
  /** setDefaultTopic input for default locale (default) */
  setDefaultTopic?: InputMaybe<Scalars['Boolean']>;
  /** topicFilterLabel input for default locale (default) */
  topicFilterLabel: Scalars['String'];
  /** topicFilterPlaceholder input for default locale (default) */
  topicFilterPlaceholder?: InputMaybe<Scalars['String']>;
  topics?: InputMaybe<TopicCreateManyInlineInput>;
};

export type TopicListCreateLocalizationDataInput = {
  setDefaultTopic?: InputMaybe<Scalars['Boolean']>;
  topicFilterLabel: Scalars['String'];
  topicFilterPlaceholder?: InputMaybe<Scalars['String']>;
};

export type TopicListCreateLocalizationInput = {
  /** Localization input */
  data: TopicListCreateLocalizationDataInput;
  locale: Locale;
};

export type TopicListCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<TopicListCreateLocalizationInput>>;
};

export type TopicListCreateManyInlineInput = {
  /** Create and connect multiple existing TopicList documents */
  create?: InputMaybe<Array<TopicListCreateInput>>;
};

export type TopicListCreateOneInlineInput = {
  /** Create and connect one TopicList document */
  create?: InputMaybe<TopicListCreateInput>;
};

export type TopicListCreateWithPositionInput = {
  /** Document to create */
  data: TopicListCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type TopicListEdge = {
  __typename?: 'TopicListEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: TopicList;
};

/** Identifies documents */
export type TopicListManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TopicListWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TopicListWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TopicListWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  cardFields?: InputMaybe<Array<ArticleFeature>>;
  /** Matches if the field array contains *all* items provided to the filter */
  cardFields_contains_all?: InputMaybe<Array<ArticleFeature>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  cardFields_contains_none?: InputMaybe<Array<ArticleFeature>>;
  /** Matches if the field array contains at least one item provided to the filter */
  cardFields_contains_some?: InputMaybe<Array<ArticleFeature>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  cardFields_not?: InputMaybe<Array<ArticleFeature>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  topics_every?: InputMaybe<TopicWhereInput>;
  topics_none?: InputMaybe<TopicWhereInput>;
  topics_some?: InputMaybe<TopicWhereInput>;
};

export enum TopicListOrderByInput {
  CardFieldsAsc = 'cardFields_ASC',
  CardFieldsDesc = 'cardFields_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  SetDefaultTopicAsc = 'setDefaultTopic_ASC',
  SetDefaultTopicDesc = 'setDefaultTopic_DESC',
  TopicFilterLabelAsc = 'topicFilterLabel_ASC',
  TopicFilterLabelDesc = 'topicFilterLabel_DESC',
  TopicFilterPlaceholderAsc = 'topicFilterPlaceholder_ASC',
  TopicFilterPlaceholderDesc = 'topicFilterPlaceholder_DESC',
}

export type TopicListParent = CBlock;

export type TopicListParentConnectInput = {
  CBlock?: InputMaybe<CBlockConnectInput>;
};

export type TopicListParentCreateInput = {
  CBlock?: InputMaybe<CBlockCreateInput>;
};

export type TopicListParentCreateManyInlineInput = {
  /** Connect multiple existing TopicListParent documents */
  connect?: InputMaybe<Array<TopicListParentWhereUniqueInput>>;
  /** Create and connect multiple existing TopicListParent documents */
  create?: InputMaybe<Array<TopicListParentCreateInput>>;
};

export type TopicListParentCreateOneInlineInput = {
  /** Connect one existing TopicListParent document */
  connect?: InputMaybe<TopicListParentWhereUniqueInput>;
  /** Create and connect one TopicListParent document */
  create?: InputMaybe<TopicListParentCreateInput>;
};

export type TopicListParentUpdateInput = {
  CBlock?: InputMaybe<CBlockUpdateInput>;
};

export type TopicListParentUpdateManyInlineInput = {
  /** Connect multiple existing TopicListParent documents */
  connect?: InputMaybe<Array<TopicListParentConnectInput>>;
  /** Create and connect multiple TopicListParent documents */
  create?: InputMaybe<Array<TopicListParentCreateInput>>;
  /** Delete multiple TopicListParent documents */
  delete?: InputMaybe<Array<TopicListParentWhereUniqueInput>>;
  /** Disconnect multiple TopicListParent documents */
  disconnect?: InputMaybe<Array<TopicListParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing TopicListParent documents */
  set?: InputMaybe<Array<TopicListParentWhereUniqueInput>>;
  /** Update multiple TopicListParent documents */
  update?: InputMaybe<Array<TopicListParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple TopicListParent documents */
  upsert?: InputMaybe<Array<TopicListParentUpsertWithNestedWhereUniqueInput>>;
};

export type TopicListParentUpdateManyWithNestedWhereInput = {
  CBlock?: InputMaybe<CBlockUpdateManyWithNestedWhereInput>;
};

export type TopicListParentUpdateOneInlineInput = {
  /** Connect existing TopicListParent document */
  connect?: InputMaybe<TopicListParentWhereUniqueInput>;
  /** Create and connect one TopicListParent document */
  create?: InputMaybe<TopicListParentCreateInput>;
  /** Delete currently connected TopicListParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected TopicListParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single TopicListParent document */
  update?: InputMaybe<TopicListParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single TopicListParent document */
  upsert?: InputMaybe<TopicListParentUpsertWithNestedWhereUniqueInput>;
};

export type TopicListParentUpdateWithNestedWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockUpdateWithNestedWhereUniqueInput>;
};

export type TopicListParentUpsertWithNestedWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockUpsertWithNestedWhereUniqueInput>;
};

export type TopicListParentWhereInput = {
  CBlock?: InputMaybe<CBlockWhereInput>;
};

export type TopicListParentWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockWhereUniqueInput>;
};

export type TopicListUpdateInput = {
  cardFields?: InputMaybe<Array<ArticleFeature>>;
  /** Manage document localizations */
  localizations?: InputMaybe<TopicListUpdateLocalizationsInput>;
  /** setDefaultTopic input for default locale (default) */
  setDefaultTopic?: InputMaybe<Scalars['Boolean']>;
  /** topicFilterLabel input for default locale (default) */
  topicFilterLabel?: InputMaybe<Scalars['String']>;
  /** topicFilterPlaceholder input for default locale (default) */
  topicFilterPlaceholder?: InputMaybe<Scalars['String']>;
  topics?: InputMaybe<TopicUpdateManyInlineInput>;
};

export type TopicListUpdateLocalizationDataInput = {
  setDefaultTopic?: InputMaybe<Scalars['Boolean']>;
  topicFilterLabel?: InputMaybe<Scalars['String']>;
  topicFilterPlaceholder?: InputMaybe<Scalars['String']>;
};

export type TopicListUpdateLocalizationInput = {
  data: TopicListUpdateLocalizationDataInput;
  locale: Locale;
};

export type TopicListUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<TopicListCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<TopicListUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<TopicListUpsertLocalizationInput>>;
};

export type TopicListUpdateManyInlineInput = {
  /** Create and connect multiple TopicList component instances */
  create?: InputMaybe<Array<TopicListCreateWithPositionInput>>;
  /** Delete multiple TopicList documents */
  delete?: InputMaybe<Array<TopicListWhereUniqueInput>>;
  /** Update multiple TopicList component instances */
  update?: InputMaybe<
    Array<TopicListUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple TopicList component instances */
  upsert?: InputMaybe<
    Array<TopicListUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type TopicListUpdateManyInput = {
  cardFields?: InputMaybe<Array<ArticleFeature>>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<TopicListUpdateManyLocalizationsInput>;
  /** setDefaultTopic input for default locale (default) */
  setDefaultTopic?: InputMaybe<Scalars['Boolean']>;
  /** topicFilterLabel input for default locale (default) */
  topicFilterLabel?: InputMaybe<Scalars['String']>;
  /** topicFilterPlaceholder input for default locale (default) */
  topicFilterPlaceholder?: InputMaybe<Scalars['String']>;
};

export type TopicListUpdateManyLocalizationDataInput = {
  setDefaultTopic?: InputMaybe<Scalars['Boolean']>;
  topicFilterLabel?: InputMaybe<Scalars['String']>;
  topicFilterPlaceholder?: InputMaybe<Scalars['String']>;
};

export type TopicListUpdateManyLocalizationInput = {
  data: TopicListUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type TopicListUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<TopicListUpdateManyLocalizationInput>>;
};

export type TopicListUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: TopicListUpdateManyInput;
  /** Document search */
  where: TopicListWhereInput;
};

export type TopicListUpdateOneInlineInput = {
  /** Create and connect one TopicList document */
  create?: InputMaybe<TopicListCreateInput>;
  /** Delete currently connected TopicList document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single TopicList document */
  update?: InputMaybe<TopicListUpdateWithNestedWhereUniqueInput>;
  /** Upsert single TopicList document */
  upsert?: InputMaybe<TopicListUpsertWithNestedWhereUniqueInput>;
};

export type TopicListUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<TopicListUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: TopicListWhereUniqueInput;
};

export type TopicListUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: TopicListUpdateInput;
  /** Unique document search */
  where: TopicListWhereUniqueInput;
};

export type TopicListUpsertInput = {
  /** Create document if it didn't exist */
  create: TopicListCreateInput;
  /** Update document if it exists */
  update: TopicListUpdateInput;
};

export type TopicListUpsertLocalizationInput = {
  create: TopicListCreateLocalizationDataInput;
  locale: Locale;
  update: TopicListUpdateLocalizationDataInput;
};

export type TopicListUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<TopicListUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: TopicListWhereUniqueInput;
};

export type TopicListUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: TopicListUpsertInput;
  /** Unique document search */
  where: TopicListWhereUniqueInput;
};

/** Identifies documents */
export type TopicListWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TopicListWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TopicListWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TopicListWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  cardFields?: InputMaybe<Array<ArticleFeature>>;
  /** Matches if the field array contains *all* items provided to the filter */
  cardFields_contains_all?: InputMaybe<Array<ArticleFeature>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  cardFields_contains_none?: InputMaybe<Array<ArticleFeature>>;
  /** Matches if the field array contains at least one item provided to the filter */
  cardFields_contains_some?: InputMaybe<Array<ArticleFeature>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  cardFields_not?: InputMaybe<Array<ArticleFeature>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  setDefaultTopic?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  setDefaultTopic_not?: InputMaybe<Scalars['Boolean']>;
  topicFilterLabel?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  topicFilterLabel_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  topicFilterLabel_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  topicFilterLabel_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  topicFilterLabel_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  topicFilterLabel_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  topicFilterLabel_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  topicFilterLabel_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  topicFilterLabel_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  topicFilterLabel_starts_with?: InputMaybe<Scalars['String']>;
  topicFilterPlaceholder?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  topicFilterPlaceholder_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  topicFilterPlaceholder_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  topicFilterPlaceholder_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  topicFilterPlaceholder_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  topicFilterPlaceholder_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  topicFilterPlaceholder_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  topicFilterPlaceholder_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']>>
  >;
  /** All values not starting with the given string. */
  topicFilterPlaceholder_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  topicFilterPlaceholder_starts_with?: InputMaybe<Scalars['String']>;
  topics_every?: InputMaybe<TopicWhereInput>;
  topics_none?: InputMaybe<TopicWhereInput>;
  topics_some?: InputMaybe<TopicWhereInput>;
};

/** References TopicList record uniquely */
export type TopicListWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Identifies documents */
export type TopicManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TopicWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TopicWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TopicWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<TopicWhereStageInput>;
  documentInStages_none?: InputMaybe<TopicWhereStageInput>;
  documentInStages_some?: InputMaybe<TopicWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  relatedArticlesInTopic_every?: InputMaybe<ArticleWhereInput>;
  relatedArticlesInTopic_none?: InputMaybe<ArticleWhereInput>;
  relatedArticlesInTopic_some?: InputMaybe<ArticleWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  sites_every?: InputMaybe<SiteWhereInput>;
  sites_none?: InputMaybe<SiteWhereInput>;
  sites_some?: InputMaybe<SiteWhereInput>;
  slug?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum TopicOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type TopicUpdateInput = {
  clyzq76cz048807tbbpd3cp4x?: InputMaybe<TopicListUpdateManyInlineInput>;
  /** description input for default locale (default) */
  description?: InputMaybe<Scalars['RichTextAST']>;
  /** Manage document localizations */
  localizations?: InputMaybe<TopicUpdateLocalizationsInput>;
  /** name input for default locale (default) */
  name?: InputMaybe<Scalars['String']>;
  relatedArticlesInTopic?: InputMaybe<ArticleUpdateManyInlineInput>;
  sites?: InputMaybe<SiteUpdateManyInlineInput>;
  slug?: InputMaybe<Scalars['String']>;
};

export type TopicUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars['RichTextAST']>;
  name?: InputMaybe<Scalars['String']>;
};

export type TopicUpdateLocalizationInput = {
  data: TopicUpdateLocalizationDataInput;
  locale: Locale;
};

export type TopicUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<TopicCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<TopicUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<TopicUpsertLocalizationInput>>;
};

export type TopicUpdateManyInlineInput = {
  /** Connect multiple existing Topic documents */
  connect?: InputMaybe<Array<TopicConnectInput>>;
  /** Create and connect multiple Topic documents */
  create?: InputMaybe<Array<TopicCreateInput>>;
  /** Delete multiple Topic documents */
  delete?: InputMaybe<Array<TopicWhereUniqueInput>>;
  /** Disconnect multiple Topic documents */
  disconnect?: InputMaybe<Array<TopicWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Topic documents */
  set?: InputMaybe<Array<TopicWhereUniqueInput>>;
  /** Update multiple Topic documents */
  update?: InputMaybe<Array<TopicUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Topic documents */
  upsert?: InputMaybe<Array<TopicUpsertWithNestedWhereUniqueInput>>;
};

export type TopicUpdateManyInput = {
  /** description input for default locale (default) */
  description?: InputMaybe<Scalars['RichTextAST']>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<TopicUpdateManyLocalizationsInput>;
  /** name input for default locale (default) */
  name?: InputMaybe<Scalars['String']>;
};

export type TopicUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars['RichTextAST']>;
  name?: InputMaybe<Scalars['String']>;
};

export type TopicUpdateManyLocalizationInput = {
  data: TopicUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type TopicUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<TopicUpdateManyLocalizationInput>>;
};

export type TopicUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: TopicUpdateManyInput;
  /** Document search */
  where: TopicWhereInput;
};

export type TopicUpdateOneInlineInput = {
  /** Connect existing Topic document */
  connect?: InputMaybe<TopicWhereUniqueInput>;
  /** Create and connect one Topic document */
  create?: InputMaybe<TopicCreateInput>;
  /** Delete currently connected Topic document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected Topic document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single Topic document */
  update?: InputMaybe<TopicUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Topic document */
  upsert?: InputMaybe<TopicUpsertWithNestedWhereUniqueInput>;
};

export type TopicUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: TopicUpdateInput;
  /** Unique document search */
  where: TopicWhereUniqueInput;
};

export type TopicUpsertInput = {
  /** Create document if it didn't exist */
  create: TopicCreateInput;
  /** Update document if it exists */
  update: TopicUpdateInput;
};

export type TopicUpsertLocalizationInput = {
  create: TopicCreateLocalizationDataInput;
  locale: Locale;
  update: TopicUpdateLocalizationDataInput;
};

export type TopicUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: TopicUpsertInput;
  /** Unique document search */
  where: TopicWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type TopicWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type TopicWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TopicWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TopicWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TopicWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<TopicWhereStageInput>;
  documentInStages_none?: InputMaybe<TopicWhereStageInput>;
  documentInStages_some?: InputMaybe<TopicWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  relatedArticlesInTopic_every?: InputMaybe<ArticleWhereInput>;
  relatedArticlesInTopic_none?: InputMaybe<ArticleWhereInput>;
  relatedArticlesInTopic_some?: InputMaybe<ArticleWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  sites_every?: InputMaybe<SiteWhereInput>;
  sites_none?: InputMaybe<SiteWhereInput>;
  sites_some?: InputMaybe<SiteWhereInput>;
  slug?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type TopicWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TopicWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TopicWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TopicWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<TopicWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Topic record uniquely */
export type TopicWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type Union_Actions_Clbvqgt4j01rj01ul45jv8qk0 =
  | CButtonLink
  | CImageLink
  | CTextLink;

export type Union_Actions_Clbvqgt4j01rj01ul45jv8qk0ConnectInput = {
  CButtonLink?: InputMaybe<CButtonLinkConnectInput>;
  CImageLink?: InputMaybe<CImageLinkConnectInput>;
  CTextLink?: InputMaybe<CTextLinkConnectInput>;
};

export type Union_Actions_Clbvqgt4j01rj01ul45jv8qk0CreateInput = {
  CButtonLink?: InputMaybe<CButtonLinkCreateInput>;
  CImageLink?: InputMaybe<CImageLinkCreateInput>;
  CTextLink?: InputMaybe<CTextLinkCreateInput>;
};

export type Union_Actions_Clbvqgt4j01rj01ul45jv8qk0CreateManyInlineInput = {
  /** Connect multiple existing Union_actions_clbvqgt4j01rj01ul45jv8qk0 documents */
  connect?: InputMaybe<
    Array<Union_Actions_Clbvqgt4j01rj01ul45jv8qk0WhereUniqueInput>
  >;
  /** Create and connect multiple existing Union_actions_clbvqgt4j01rj01ul45jv8qk0 documents */
  create?: InputMaybe<
    Array<Union_Actions_Clbvqgt4j01rj01ul45jv8qk0CreateInput>
  >;
};

export type Union_Actions_Clbvqgt4j01rj01ul45jv8qk0CreateOneInlineInput = {
  /** Connect one existing Union_actions_clbvqgt4j01rj01ul45jv8qk0 document */
  connect?: InputMaybe<Union_Actions_Clbvqgt4j01rj01ul45jv8qk0WhereUniqueInput>;
  /** Create and connect one Union_actions_clbvqgt4j01rj01ul45jv8qk0 document */
  create?: InputMaybe<Union_Actions_Clbvqgt4j01rj01ul45jv8qk0CreateInput>;
};

export type Union_Actions_Clbvqgt4j01rj01ul45jv8qk0UpdateInput = {
  CButtonLink?: InputMaybe<CButtonLinkUpdateInput>;
  CImageLink?: InputMaybe<CImageLinkUpdateInput>;
  CTextLink?: InputMaybe<CTextLinkUpdateInput>;
};

export type Union_Actions_Clbvqgt4j01rj01ul45jv8qk0UpdateManyInlineInput = {
  /** Connect multiple existing Union_actions_clbvqgt4j01rj01ul45jv8qk0 documents */
  connect?: InputMaybe<
    Array<Union_Actions_Clbvqgt4j01rj01ul45jv8qk0ConnectInput>
  >;
  /** Create and connect multiple Union_actions_clbvqgt4j01rj01ul45jv8qk0 documents */
  create?: InputMaybe<
    Array<Union_Actions_Clbvqgt4j01rj01ul45jv8qk0CreateInput>
  >;
  /** Delete multiple Union_actions_clbvqgt4j01rj01ul45jv8qk0 documents */
  delete?: InputMaybe<
    Array<Union_Actions_Clbvqgt4j01rj01ul45jv8qk0WhereUniqueInput>
  >;
  /** Disconnect multiple Union_actions_clbvqgt4j01rj01ul45jv8qk0 documents */
  disconnect?: InputMaybe<
    Array<Union_Actions_Clbvqgt4j01rj01ul45jv8qk0WhereUniqueInput>
  >;
  /** Override currently-connected documents with multiple existing Union_actions_clbvqgt4j01rj01ul45jv8qk0 documents */
  set?: InputMaybe<
    Array<Union_Actions_Clbvqgt4j01rj01ul45jv8qk0WhereUniqueInput>
  >;
  /** Update multiple Union_actions_clbvqgt4j01rj01ul45jv8qk0 documents */
  update?: InputMaybe<
    Array<Union_Actions_Clbvqgt4j01rj01ul45jv8qk0UpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple Union_actions_clbvqgt4j01rj01ul45jv8qk0 documents */
  upsert?: InputMaybe<
    Array<Union_Actions_Clbvqgt4j01rj01ul45jv8qk0UpsertWithNestedWhereUniqueInput>
  >;
};

export type Union_Actions_Clbvqgt4j01rj01ul45jv8qk0UpdateManyWithNestedWhereInput =
  {
    CButtonLink?: InputMaybe<CButtonLinkUpdateManyWithNestedWhereInput>;
    CImageLink?: InputMaybe<CImageLinkUpdateManyWithNestedWhereInput>;
    CTextLink?: InputMaybe<CTextLinkUpdateManyWithNestedWhereInput>;
  };

export type Union_Actions_Clbvqgt4j01rj01ul45jv8qk0UpdateOneInlineInput = {
  /** Connect existing Union_actions_clbvqgt4j01rj01ul45jv8qk0 document */
  connect?: InputMaybe<Union_Actions_Clbvqgt4j01rj01ul45jv8qk0WhereUniqueInput>;
  /** Create and connect one Union_actions_clbvqgt4j01rj01ul45jv8qk0 document */
  create?: InputMaybe<Union_Actions_Clbvqgt4j01rj01ul45jv8qk0CreateInput>;
  /** Delete currently connected Union_actions_clbvqgt4j01rj01ul45jv8qk0 document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected Union_actions_clbvqgt4j01rj01ul45jv8qk0 document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single Union_actions_clbvqgt4j01rj01ul45jv8qk0 document */
  update?: InputMaybe<Union_Actions_Clbvqgt4j01rj01ul45jv8qk0UpdateWithNestedWhereUniqueInput>;
  /** Upsert single Union_actions_clbvqgt4j01rj01ul45jv8qk0 document */
  upsert?: InputMaybe<Union_Actions_Clbvqgt4j01rj01ul45jv8qk0UpsertWithNestedWhereUniqueInput>;
};

export type Union_Actions_Clbvqgt4j01rj01ul45jv8qk0UpdateWithNestedWhereUniqueInput =
  {
    CButtonLink?: InputMaybe<CButtonLinkUpdateWithNestedWhereUniqueInput>;
    CImageLink?: InputMaybe<CImageLinkUpdateWithNestedWhereUniqueInput>;
    CTextLink?: InputMaybe<CTextLinkUpdateWithNestedWhereUniqueInput>;
  };

export type Union_Actions_Clbvqgt4j01rj01ul45jv8qk0UpsertWithNestedWhereUniqueInput =
  {
    CButtonLink?: InputMaybe<CButtonLinkUpsertWithNestedWhereUniqueInput>;
    CImageLink?: InputMaybe<CImageLinkUpsertWithNestedWhereUniqueInput>;
    CTextLink?: InputMaybe<CTextLinkUpsertWithNestedWhereUniqueInput>;
  };

export type Union_Actions_Clbvqgt4j01rj01ul45jv8qk0WhereInput = {
  CButtonLink?: InputMaybe<CButtonLinkWhereInput>;
  CImageLink?: InputMaybe<CImageLinkWhereInput>;
  CTextLink?: InputMaybe<CTextLinkWhereInput>;
};

export type Union_Actions_Clbvqgt4j01rj01ul45jv8qk0WhereUniqueInput = {
  CButtonLink?: InputMaybe<CButtonLinkWhereUniqueInput>;
  CImageLink?: InputMaybe<CImageLinkWhereUniqueInput>;
  CTextLink?: InputMaybe<CTextLinkWhereUniqueInput>;
};

export type Union_Actions_Cljcktvum001101tbchtibrb0 =
  | CButtonLink
  | CImageLink
  | CTextLink;

export type Union_Actions_Cljcktvum001101tbchtibrb0ConnectInput = {
  CButtonLink?: InputMaybe<CButtonLinkConnectInput>;
  CImageLink?: InputMaybe<CImageLinkConnectInput>;
  CTextLink?: InputMaybe<CTextLinkConnectInput>;
};

export type Union_Actions_Cljcktvum001101tbchtibrb0CreateInput = {
  CButtonLink?: InputMaybe<CButtonLinkCreateInput>;
  CImageLink?: InputMaybe<CImageLinkCreateInput>;
  CTextLink?: InputMaybe<CTextLinkCreateInput>;
};

export type Union_Actions_Cljcktvum001101tbchtibrb0CreateManyInlineInput = {
  /** Connect multiple existing Union_actions_cljcktvum001101tbchtibrb0 documents */
  connect?: InputMaybe<
    Array<Union_Actions_Cljcktvum001101tbchtibrb0WhereUniqueInput>
  >;
  /** Create and connect multiple existing Union_actions_cljcktvum001101tbchtibrb0 documents */
  create?: InputMaybe<
    Array<Union_Actions_Cljcktvum001101tbchtibrb0CreateInput>
  >;
};

export type Union_Actions_Cljcktvum001101tbchtibrb0CreateOneInlineInput = {
  /** Connect one existing Union_actions_cljcktvum001101tbchtibrb0 document */
  connect?: InputMaybe<Union_Actions_Cljcktvum001101tbchtibrb0WhereUniqueInput>;
  /** Create and connect one Union_actions_cljcktvum001101tbchtibrb0 document */
  create?: InputMaybe<Union_Actions_Cljcktvum001101tbchtibrb0CreateInput>;
};

export type Union_Actions_Cljcktvum001101tbchtibrb0UpdateInput = {
  CButtonLink?: InputMaybe<CButtonLinkUpdateInput>;
  CImageLink?: InputMaybe<CImageLinkUpdateInput>;
  CTextLink?: InputMaybe<CTextLinkUpdateInput>;
};

export type Union_Actions_Cljcktvum001101tbchtibrb0UpdateManyInlineInput = {
  /** Connect multiple existing Union_actions_cljcktvum001101tbchtibrb0 documents */
  connect?: InputMaybe<
    Array<Union_Actions_Cljcktvum001101tbchtibrb0ConnectInput>
  >;
  /** Create and connect multiple Union_actions_cljcktvum001101tbchtibrb0 documents */
  create?: InputMaybe<
    Array<Union_Actions_Cljcktvum001101tbchtibrb0CreateInput>
  >;
  /** Delete multiple Union_actions_cljcktvum001101tbchtibrb0 documents */
  delete?: InputMaybe<
    Array<Union_Actions_Cljcktvum001101tbchtibrb0WhereUniqueInput>
  >;
  /** Disconnect multiple Union_actions_cljcktvum001101tbchtibrb0 documents */
  disconnect?: InputMaybe<
    Array<Union_Actions_Cljcktvum001101tbchtibrb0WhereUniqueInput>
  >;
  /** Override currently-connected documents with multiple existing Union_actions_cljcktvum001101tbchtibrb0 documents */
  set?: InputMaybe<
    Array<Union_Actions_Cljcktvum001101tbchtibrb0WhereUniqueInput>
  >;
  /** Update multiple Union_actions_cljcktvum001101tbchtibrb0 documents */
  update?: InputMaybe<
    Array<Union_Actions_Cljcktvum001101tbchtibrb0UpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple Union_actions_cljcktvum001101tbchtibrb0 documents */
  upsert?: InputMaybe<
    Array<Union_Actions_Cljcktvum001101tbchtibrb0UpsertWithNestedWhereUniqueInput>
  >;
};

export type Union_Actions_Cljcktvum001101tbchtibrb0UpdateManyWithNestedWhereInput =
  {
    CButtonLink?: InputMaybe<CButtonLinkUpdateManyWithNestedWhereInput>;
    CImageLink?: InputMaybe<CImageLinkUpdateManyWithNestedWhereInput>;
    CTextLink?: InputMaybe<CTextLinkUpdateManyWithNestedWhereInput>;
  };

export type Union_Actions_Cljcktvum001101tbchtibrb0UpdateOneInlineInput = {
  /** Connect existing Union_actions_cljcktvum001101tbchtibrb0 document */
  connect?: InputMaybe<Union_Actions_Cljcktvum001101tbchtibrb0WhereUniqueInput>;
  /** Create and connect one Union_actions_cljcktvum001101tbchtibrb0 document */
  create?: InputMaybe<Union_Actions_Cljcktvum001101tbchtibrb0CreateInput>;
  /** Delete currently connected Union_actions_cljcktvum001101tbchtibrb0 document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected Union_actions_cljcktvum001101tbchtibrb0 document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single Union_actions_cljcktvum001101tbchtibrb0 document */
  update?: InputMaybe<Union_Actions_Cljcktvum001101tbchtibrb0UpdateWithNestedWhereUniqueInput>;
  /** Upsert single Union_actions_cljcktvum001101tbchtibrb0 document */
  upsert?: InputMaybe<Union_Actions_Cljcktvum001101tbchtibrb0UpsertWithNestedWhereUniqueInput>;
};

export type Union_Actions_Cljcktvum001101tbchtibrb0UpdateWithNestedWhereUniqueInput =
  {
    CButtonLink?: InputMaybe<CButtonLinkUpdateWithNestedWhereUniqueInput>;
    CImageLink?: InputMaybe<CImageLinkUpdateWithNestedWhereUniqueInput>;
    CTextLink?: InputMaybe<CTextLinkUpdateWithNestedWhereUniqueInput>;
  };

export type Union_Actions_Cljcktvum001101tbchtibrb0UpsertWithNestedWhereUniqueInput =
  {
    CButtonLink?: InputMaybe<CButtonLinkUpsertWithNestedWhereUniqueInput>;
    CImageLink?: InputMaybe<CImageLinkUpsertWithNestedWhereUniqueInput>;
    CTextLink?: InputMaybe<CTextLinkUpsertWithNestedWhereUniqueInput>;
  };

export type Union_Actions_Cljcktvum001101tbchtibrb0WhereInput = {
  CButtonLink?: InputMaybe<CButtonLinkWhereInput>;
  CImageLink?: InputMaybe<CImageLinkWhereInput>;
  CTextLink?: InputMaybe<CTextLinkWhereInput>;
};

export type Union_Actions_Cljcktvum001101tbchtibrb0WhereUniqueInput = {
  CButtonLink?: InputMaybe<CButtonLinkWhereUniqueInput>;
  CImageLink?: InputMaybe<CImageLinkWhereUniqueInput>;
  CTextLink?: InputMaybe<CTextLinkWhereUniqueInput>;
};

export type Union_HideEmbeddedForm_HideEmbeddedFormInSection = Article | Page;

export type Union_HideEmbeddedForm_HideEmbeddedFormInSectionConnectInput = {
  Article?: InputMaybe<ArticleConnectInput>;
  Page?: InputMaybe<PageConnectInput>;
};

export type Union_HideEmbeddedForm_HideEmbeddedFormInSectionCreateInput = {
  Article?: InputMaybe<ArticleCreateInput>;
  Page?: InputMaybe<PageCreateInput>;
};

export type Union_HideEmbeddedForm_HideEmbeddedFormInSectionCreateManyInlineInput =
  {
    /** Connect multiple existing Union_hideEmbeddedForm_hideEmbeddedFormInSection documents */
    connect?: InputMaybe<
      Array<Union_HideEmbeddedForm_HideEmbeddedFormInSectionWhereUniqueInput>
    >;
    /** Create and connect multiple existing Union_hideEmbeddedForm_hideEmbeddedFormInSection documents */
    create?: InputMaybe<
      Array<Union_HideEmbeddedForm_HideEmbeddedFormInSectionCreateInput>
    >;
  };

export type Union_HideEmbeddedForm_HideEmbeddedFormInSectionCreateOneInlineInput =
  {
    /** Connect one existing Union_hideEmbeddedForm_hideEmbeddedFormInSection document */
    connect?: InputMaybe<Union_HideEmbeddedForm_HideEmbeddedFormInSectionWhereUniqueInput>;
    /** Create and connect one Union_hideEmbeddedForm_hideEmbeddedFormInSection document */
    create?: InputMaybe<Union_HideEmbeddedForm_HideEmbeddedFormInSectionCreateInput>;
  };

export type Union_HideEmbeddedForm_HideEmbeddedFormInSectionUpdateInput = {
  Article?: InputMaybe<ArticleUpdateInput>;
  Page?: InputMaybe<PageUpdateInput>;
};

export type Union_HideEmbeddedForm_HideEmbeddedFormInSectionUpdateManyInlineInput =
  {
    /** Connect multiple existing Union_hideEmbeddedForm_hideEmbeddedFormInSection documents */
    connect?: InputMaybe<
      Array<Union_HideEmbeddedForm_HideEmbeddedFormInSectionConnectInput>
    >;
    /** Create and connect multiple Union_hideEmbeddedForm_hideEmbeddedFormInSection documents */
    create?: InputMaybe<
      Array<Union_HideEmbeddedForm_HideEmbeddedFormInSectionCreateInput>
    >;
    /** Delete multiple Union_hideEmbeddedForm_hideEmbeddedFormInSection documents */
    delete?: InputMaybe<
      Array<Union_HideEmbeddedForm_HideEmbeddedFormInSectionWhereUniqueInput>
    >;
    /** Disconnect multiple Union_hideEmbeddedForm_hideEmbeddedFormInSection documents */
    disconnect?: InputMaybe<
      Array<Union_HideEmbeddedForm_HideEmbeddedFormInSectionWhereUniqueInput>
    >;
    /** Override currently-connected documents with multiple existing Union_hideEmbeddedForm_hideEmbeddedFormInSection documents */
    set?: InputMaybe<
      Array<Union_HideEmbeddedForm_HideEmbeddedFormInSectionWhereUniqueInput>
    >;
    /** Update multiple Union_hideEmbeddedForm_hideEmbeddedFormInSection documents */
    update?: InputMaybe<
      Array<Union_HideEmbeddedForm_HideEmbeddedFormInSectionUpdateWithNestedWhereUniqueInput>
    >;
    /** Upsert multiple Union_hideEmbeddedForm_hideEmbeddedFormInSection documents */
    upsert?: InputMaybe<
      Array<Union_HideEmbeddedForm_HideEmbeddedFormInSectionUpsertWithNestedWhereUniqueInput>
    >;
  };

export type Union_HideEmbeddedForm_HideEmbeddedFormInSectionUpdateManyWithNestedWhereInput =
  {
    Article?: InputMaybe<ArticleUpdateManyWithNestedWhereInput>;
    Page?: InputMaybe<PageUpdateManyWithNestedWhereInput>;
  };

export type Union_HideEmbeddedForm_HideEmbeddedFormInSectionUpdateOneInlineInput =
  {
    /** Connect existing Union_hideEmbeddedForm_hideEmbeddedFormInSection document */
    connect?: InputMaybe<Union_HideEmbeddedForm_HideEmbeddedFormInSectionWhereUniqueInput>;
    /** Create and connect one Union_hideEmbeddedForm_hideEmbeddedFormInSection document */
    create?: InputMaybe<Union_HideEmbeddedForm_HideEmbeddedFormInSectionCreateInput>;
    /** Delete currently connected Union_hideEmbeddedForm_hideEmbeddedFormInSection document */
    delete?: InputMaybe<Scalars['Boolean']>;
    /** Disconnect currently connected Union_hideEmbeddedForm_hideEmbeddedFormInSection document */
    disconnect?: InputMaybe<Scalars['Boolean']>;
    /** Update single Union_hideEmbeddedForm_hideEmbeddedFormInSection document */
    update?: InputMaybe<Union_HideEmbeddedForm_HideEmbeddedFormInSectionUpdateWithNestedWhereUniqueInput>;
    /** Upsert single Union_hideEmbeddedForm_hideEmbeddedFormInSection document */
    upsert?: InputMaybe<Union_HideEmbeddedForm_HideEmbeddedFormInSectionUpsertWithNestedWhereUniqueInput>;
  };

export type Union_HideEmbeddedForm_HideEmbeddedFormInSectionUpdateWithNestedWhereUniqueInput =
  {
    Article?: InputMaybe<ArticleUpdateWithNestedWhereUniqueInput>;
    Page?: InputMaybe<PageUpdateWithNestedWhereUniqueInput>;
  };

export type Union_HideEmbeddedForm_HideEmbeddedFormInSectionUpsertWithNestedWhereUniqueInput =
  {
    Article?: InputMaybe<ArticleUpsertWithNestedWhereUniqueInput>;
    Page?: InputMaybe<PageUpsertWithNestedWhereUniqueInput>;
  };

export type Union_HideEmbeddedForm_HideEmbeddedFormInSectionWhereInput = {
  Article?: InputMaybe<ArticleWhereInput>;
  Page?: InputMaybe<PageWhereInput>;
};

export type Union_HideEmbeddedForm_HideEmbeddedFormInSectionWhereUniqueInput = {
  Article?: InputMaybe<ArticleWhereUniqueInput>;
  Page?: InputMaybe<PageWhereUniqueInput>;
};

export type Union_To_Cl74c8n5m0e4w01un15kvc319 =
  | Article
  | Asset
  | Category
  | ExternalUrl
  | Page;

export type Union_To_Cl74c8n5m0e4w01un15kvc319ConnectInput = {
  Article?: InputMaybe<ArticleConnectInput>;
  Asset?: InputMaybe<AssetConnectInput>;
  Category?: InputMaybe<CategoryConnectInput>;
  ExternalUrl?: InputMaybe<ExternalUrlConnectInput>;
  Page?: InputMaybe<PageConnectInput>;
};

export type Union_To_Cl74c8n5m0e4w01un15kvc319CreateInput = {
  Article?: InputMaybe<ArticleCreateInput>;
  Asset?: InputMaybe<AssetCreateInput>;
  Category?: InputMaybe<CategoryCreateInput>;
  ExternalUrl?: InputMaybe<ExternalUrlCreateInput>;
  Page?: InputMaybe<PageCreateInput>;
};

export type Union_To_Cl74c8n5m0e4w01un15kvc319CreateManyInlineInput = {
  /** Connect multiple existing Union_to_cl74c8n5m0e4w01un15kvc319 documents */
  connect?: InputMaybe<
    Array<Union_To_Cl74c8n5m0e4w01un15kvc319WhereUniqueInput>
  >;
  /** Create and connect multiple existing Union_to_cl74c8n5m0e4w01un15kvc319 documents */
  create?: InputMaybe<Array<Union_To_Cl74c8n5m0e4w01un15kvc319CreateInput>>;
};

export type Union_To_Cl74c8n5m0e4w01un15kvc319CreateOneInlineInput = {
  /** Connect one existing Union_to_cl74c8n5m0e4w01un15kvc319 document */
  connect?: InputMaybe<Union_To_Cl74c8n5m0e4w01un15kvc319WhereUniqueInput>;
  /** Create and connect one Union_to_cl74c8n5m0e4w01un15kvc319 document */
  create?: InputMaybe<Union_To_Cl74c8n5m0e4w01un15kvc319CreateInput>;
};

export type Union_To_Cl74c8n5m0e4w01un15kvc319UpdateInput = {
  Article?: InputMaybe<ArticleUpdateInput>;
  Asset?: InputMaybe<AssetUpdateInput>;
  Category?: InputMaybe<CategoryUpdateInput>;
  ExternalUrl?: InputMaybe<ExternalUrlUpdateInput>;
  Page?: InputMaybe<PageUpdateInput>;
};

export type Union_To_Cl74c8n5m0e4w01un15kvc319UpdateManyInlineInput = {
  /** Connect multiple existing Union_to_cl74c8n5m0e4w01un15kvc319 documents */
  connect?: InputMaybe<Array<Union_To_Cl74c8n5m0e4w01un15kvc319ConnectInput>>;
  /** Create and connect multiple Union_to_cl74c8n5m0e4w01un15kvc319 documents */
  create?: InputMaybe<Array<Union_To_Cl74c8n5m0e4w01un15kvc319CreateInput>>;
  /** Delete multiple Union_to_cl74c8n5m0e4w01un15kvc319 documents */
  delete?: InputMaybe<
    Array<Union_To_Cl74c8n5m0e4w01un15kvc319WhereUniqueInput>
  >;
  /** Disconnect multiple Union_to_cl74c8n5m0e4w01un15kvc319 documents */
  disconnect?: InputMaybe<
    Array<Union_To_Cl74c8n5m0e4w01un15kvc319WhereUniqueInput>
  >;
  /** Override currently-connected documents with multiple existing Union_to_cl74c8n5m0e4w01un15kvc319 documents */
  set?: InputMaybe<Array<Union_To_Cl74c8n5m0e4w01un15kvc319WhereUniqueInput>>;
  /** Update multiple Union_to_cl74c8n5m0e4w01un15kvc319 documents */
  update?: InputMaybe<
    Array<Union_To_Cl74c8n5m0e4w01un15kvc319UpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple Union_to_cl74c8n5m0e4w01un15kvc319 documents */
  upsert?: InputMaybe<
    Array<Union_To_Cl74c8n5m0e4w01un15kvc319UpsertWithNestedWhereUniqueInput>
  >;
};

export type Union_To_Cl74c8n5m0e4w01un15kvc319UpdateManyWithNestedWhereInput = {
  Article?: InputMaybe<ArticleUpdateManyWithNestedWhereInput>;
  Asset?: InputMaybe<AssetUpdateManyWithNestedWhereInput>;
  Category?: InputMaybe<CategoryUpdateManyWithNestedWhereInput>;
  ExternalUrl?: InputMaybe<ExternalUrlUpdateManyWithNestedWhereInput>;
  Page?: InputMaybe<PageUpdateManyWithNestedWhereInput>;
};

export type Union_To_Cl74c8n5m0e4w01un15kvc319UpdateOneInlineInput = {
  /** Connect existing Union_to_cl74c8n5m0e4w01un15kvc319 document */
  connect?: InputMaybe<Union_To_Cl74c8n5m0e4w01un15kvc319WhereUniqueInput>;
  /** Create and connect one Union_to_cl74c8n5m0e4w01un15kvc319 document */
  create?: InputMaybe<Union_To_Cl74c8n5m0e4w01un15kvc319CreateInput>;
  /** Delete currently connected Union_to_cl74c8n5m0e4w01un15kvc319 document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected Union_to_cl74c8n5m0e4w01un15kvc319 document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single Union_to_cl74c8n5m0e4w01un15kvc319 document */
  update?: InputMaybe<Union_To_Cl74c8n5m0e4w01un15kvc319UpdateWithNestedWhereUniqueInput>;
  /** Upsert single Union_to_cl74c8n5m0e4w01un15kvc319 document */
  upsert?: InputMaybe<Union_To_Cl74c8n5m0e4w01un15kvc319UpsertWithNestedWhereUniqueInput>;
};

export type Union_To_Cl74c8n5m0e4w01un15kvc319UpdateWithNestedWhereUniqueInput =
  {
    Article?: InputMaybe<ArticleUpdateWithNestedWhereUniqueInput>;
    Asset?: InputMaybe<AssetUpdateWithNestedWhereUniqueInput>;
    Category?: InputMaybe<CategoryUpdateWithNestedWhereUniqueInput>;
    ExternalUrl?: InputMaybe<ExternalUrlUpdateWithNestedWhereUniqueInput>;
    Page?: InputMaybe<PageUpdateWithNestedWhereUniqueInput>;
  };

export type Union_To_Cl74c8n5m0e4w01un15kvc319UpsertWithNestedWhereUniqueInput =
  {
    Article?: InputMaybe<ArticleUpsertWithNestedWhereUniqueInput>;
    Asset?: InputMaybe<AssetUpsertWithNestedWhereUniqueInput>;
    Category?: InputMaybe<CategoryUpsertWithNestedWhereUniqueInput>;
    ExternalUrl?: InputMaybe<ExternalUrlUpsertWithNestedWhereUniqueInput>;
    Page?: InputMaybe<PageUpsertWithNestedWhereUniqueInput>;
  };

export type Union_To_Cl74c8n5m0e4w01un15kvc319WhereInput = {
  Article?: InputMaybe<ArticleWhereInput>;
  Asset?: InputMaybe<AssetWhereInput>;
  Category?: InputMaybe<CategoryWhereInput>;
  ExternalUrl?: InputMaybe<ExternalUrlWhereInput>;
  Page?: InputMaybe<PageWhereInput>;
};

export type Union_To_Cl74c8n5m0e4w01un15kvc319WhereUniqueInput = {
  Article?: InputMaybe<ArticleWhereUniqueInput>;
  Asset?: InputMaybe<AssetWhereUniqueInput>;
  Category?: InputMaybe<CategoryWhereUniqueInput>;
  ExternalUrl?: InputMaybe<ExternalUrlWhereUniqueInput>;
  Page?: InputMaybe<PageWhereUniqueInput>;
};

export type UnpublishLocaleInput = {
  /** Locales to unpublish */
  locale: Locale;
  /** Stages to unpublish selected locales from */
  stages: Array<Stage>;
};

/** User system model */
export type User = Entity &
  Node & {
    __typename?: 'User';
    /** The time the document was created */
    createdAt: Scalars['DateTime'];
    /** Get the document in other stages */
    documentInStages: Array<User>;
    /** The unique identifier */
    id: Scalars['ID'];
    /** Flag to determine if user is active or not */
    isActive: Scalars['Boolean'];
    /** User Kind. Can be either MEMBER, PAT or PUBLIC */
    kind: UserKind;
    /** The username */
    name: Scalars['String'];
    /** Profile Picture url */
    picture?: Maybe<Scalars['String']>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars['DateTime']>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars['DateTime'];
    users?: Maybe<SiteUsersConnection>;
  };

/** User system model */
export type UserDocumentInStagesArgs = {
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
  stages?: Array<Stage>;
};

/** User system model */
export type UserUsersArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type UserConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: UserWhereUniqueInput;
};

/** A connection to a list of items. */
export type UserConnection = {
  __typename?: 'UserConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<UserEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type UserCreateManyInlineInput = {
  /** Connect multiple existing User documents */
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
};

export type UserCreateOneInlineInput = {
  /** Connect one existing User document */
  connect?: InputMaybe<UserWhereUniqueInput>;
};

/** An edge in a connection. */
export type UserEdge = {
  __typename?: 'UserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: User;
};

/** System User Kind */
export enum UserKind {
  Member = 'MEMBER',
  Pat = 'PAT',
  Public = 'PUBLIC',
  Webhook = 'WEBHOOK',
}

/** Identifies documents */
export type UserManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<UserWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<UserWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<UserWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  documentInStages_every?: InputMaybe<UserWhereStageInput>;
  documentInStages_none?: InputMaybe<UserWhereStageInput>;
  documentInStages_some?: InputMaybe<UserWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  isActive_not?: InputMaybe<Scalars['Boolean']>;
  kind?: InputMaybe<UserKind>;
  /** All values that are contained in given list. */
  kind_in?: InputMaybe<Array<InputMaybe<UserKind>>>;
  /** Any other value that exists and is not equal to the given value. */
  kind_not?: InputMaybe<UserKind>;
  /** All values that are not contained in given list. */
  kind_not_in?: InputMaybe<Array<InputMaybe<UserKind>>>;
  name?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']>;
  picture?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  picture_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  picture_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  picture_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  picture_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  picture_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  picture_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  picture_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  picture_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  picture_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  users?: InputMaybe<SiteUsersConnectionWhereInput>;
};

export enum UserOrderByInput {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IsActiveAsc = 'isActive_ASC',
  IsActiveDesc = 'isActive_DESC',
  KindAsc = 'kind_ASC',
  KindDesc = 'kind_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PictureAsc = 'picture_ASC',
  PictureDesc = 'picture_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
}

export type UserUpdateManyInlineInput = {
  /** Connect multiple existing User documents */
  connect?: InputMaybe<Array<UserConnectInput>>;
  /** Disconnect multiple User documents */
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing User documents */
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
};

export type UserUpdateOneInlineInput = {
  /** Connect existing User document */
  connect?: InputMaybe<UserWhereUniqueInput>;
  /** Disconnect currently connected User document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

/** This contains a set of filters that can be used to compare values internally */
export type UserWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type UserWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<UserWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<UserWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<UserWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  documentInStages_every?: InputMaybe<UserWhereStageInput>;
  documentInStages_none?: InputMaybe<UserWhereStageInput>;
  documentInStages_some?: InputMaybe<UserWhereStageInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  /** Any other value that exists and is not equal to the given value. */
  isActive_not?: InputMaybe<Scalars['Boolean']>;
  kind?: InputMaybe<UserKind>;
  /** All values that are contained in given list. */
  kind_in?: InputMaybe<Array<InputMaybe<UserKind>>>;
  /** Any other value that exists and is not equal to the given value. */
  kind_not?: InputMaybe<UserKind>;
  /** All values that are not contained in given list. */
  kind_not_in?: InputMaybe<Array<InputMaybe<UserKind>>>;
  name?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars['String']>;
  picture?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  picture_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  picture_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  picture_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  picture_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  picture_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  picture_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  picture_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  picture_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  picture_starts_with?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars['DateTime']>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  users?: InputMaybe<SiteUsersConnectionWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type UserWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<UserWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<UserWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<UserWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<UserWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References User record uniquely */
export type UserWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Version = {
  __typename?: 'Version';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  revision: Scalars['Int'];
  stage: Stage;
};

export type VersionWhereInput = {
  id: Scalars['ID'];
  revision: Scalars['Int'];
  stage: Stage;
};

export type Video = Entity & {
  __typename?: 'Video';
  box?: Maybe<CBox>;
  /** The unique identifier */
  id: Scalars['ID'];
  source?: Maybe<VideosourceUnion>;
  /** System stage field */
  stage: Stage;
};

export type VideoBoxArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type VideoSourceArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

/** This component is used for video block */
export type VideoBlock = Entity & {
  __typename?: 'VideoBlock';
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<VideoBlock>;
  /** System stage field */
  stage: Stage;
  /** Description for video block. */
  videoDescription?: Maybe<RichText>;
  /** Heading for video block. */
  videoHeading?: Maybe<Scalars['String']>;
  /** Video id for video block. */
  videoId?: Maybe<Scalars['String']>;
};

/** This component is used for video block */
export type VideoBlockLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

/** A connection to a list of items. */
export type VideoBlockConnection = {
  __typename?: 'VideoBlockConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<VideoBlockEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type VideoBlockCreateInput = {
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<VideoBlockCreateLocalizationsInput>;
  /** videoDescription input for default locale (default) */
  videoDescription?: InputMaybe<Scalars['RichTextAST']>;
  /** videoHeading input for default locale (default) */
  videoHeading?: InputMaybe<Scalars['String']>;
  /** videoId input for default locale (default) */
  videoId?: InputMaybe<Scalars['String']>;
};

export type VideoBlockCreateLocalizationDataInput = {
  videoDescription?: InputMaybe<Scalars['RichTextAST']>;
  videoHeading?: InputMaybe<Scalars['String']>;
  videoId?: InputMaybe<Scalars['String']>;
};

export type VideoBlockCreateLocalizationInput = {
  /** Localization input */
  data: VideoBlockCreateLocalizationDataInput;
  locale: Locale;
};

export type VideoBlockCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<VideoBlockCreateLocalizationInput>>;
};

export type VideoBlockCreateWithPositionInput = {
  /** Document to create */
  data: VideoBlockCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type VideoBlockEdge = {
  __typename?: 'VideoBlockEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: VideoBlock;
};

/** Identifies documents */
export type VideoBlockManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VideoBlockWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VideoBlockWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VideoBlockWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
};

export enum VideoBlockOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VideoHeadingAsc = 'videoHeading_ASC',
  VideoHeadingDesc = 'videoHeading_DESC',
  VideoIdAsc = 'videoId_ASC',
  VideoIdDesc = 'videoId_DESC',
}

export type VideoBlockUpdateInput = {
  /** Manage document localizations */
  localizations?: InputMaybe<VideoBlockUpdateLocalizationsInput>;
  /** videoDescription input for default locale (default) */
  videoDescription?: InputMaybe<Scalars['RichTextAST']>;
  /** videoHeading input for default locale (default) */
  videoHeading?: InputMaybe<Scalars['String']>;
  /** videoId input for default locale (default) */
  videoId?: InputMaybe<Scalars['String']>;
};

export type VideoBlockUpdateLocalizationDataInput = {
  videoDescription?: InputMaybe<Scalars['RichTextAST']>;
  videoHeading?: InputMaybe<Scalars['String']>;
  videoId?: InputMaybe<Scalars['String']>;
};

export type VideoBlockUpdateLocalizationInput = {
  data: VideoBlockUpdateLocalizationDataInput;
  locale: Locale;
};

export type VideoBlockUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<VideoBlockCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<VideoBlockUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<VideoBlockUpsertLocalizationInput>>;
};

export type VideoBlockUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<VideoBlockUpdateManyLocalizationsInput>;
  /** videoDescription input for default locale (default) */
  videoDescription?: InputMaybe<Scalars['RichTextAST']>;
  /** videoHeading input for default locale (default) */
  videoHeading?: InputMaybe<Scalars['String']>;
  /** videoId input for default locale (default) */
  videoId?: InputMaybe<Scalars['String']>;
};

export type VideoBlockUpdateManyLocalizationDataInput = {
  videoDescription?: InputMaybe<Scalars['RichTextAST']>;
  videoHeading?: InputMaybe<Scalars['String']>;
  videoId?: InputMaybe<Scalars['String']>;
};

export type VideoBlockUpdateManyLocalizationInput = {
  data: VideoBlockUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type VideoBlockUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<VideoBlockUpdateManyLocalizationInput>>;
};

export type VideoBlockUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: VideoBlockUpdateManyInput;
  /** Document search */
  where: VideoBlockWhereInput;
};

export type VideoBlockUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<VideoBlockUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: VideoBlockWhereUniqueInput;
};

export type VideoBlockUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: VideoBlockUpdateInput;
  /** Unique document search */
  where: VideoBlockWhereUniqueInput;
};

export type VideoBlockUpsertInput = {
  /** Create document if it didn't exist */
  create: VideoBlockCreateInput;
  /** Update document if it exists */
  update: VideoBlockUpdateInput;
};

export type VideoBlockUpsertLocalizationInput = {
  create: VideoBlockCreateLocalizationDataInput;
  locale: Locale;
  update: VideoBlockUpdateLocalizationDataInput;
};

export type VideoBlockUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<VideoBlockUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: VideoBlockWhereUniqueInput;
};

export type VideoBlockUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: VideoBlockUpsertInput;
  /** Unique document search */
  where: VideoBlockWhereUniqueInput;
};

/** Identifies documents */
export type VideoBlockWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VideoBlockWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VideoBlockWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VideoBlockWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  videoHeading?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  videoHeading_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  videoHeading_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  videoHeading_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  videoHeading_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  videoHeading_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  videoHeading_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  videoHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  videoHeading_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  videoHeading_starts_with?: InputMaybe<Scalars['String']>;
  videoId?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  videoId_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  videoId_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  videoId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  videoId_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  videoId_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  videoId_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  videoId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  videoId_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  videoId_starts_with?: InputMaybe<Scalars['String']>;
};

/** References VideoBlock record uniquely */
export type VideoBlockWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type VideoConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: VideoWhereUniqueInput;
};

/** A connection to a list of items. */
export type VideoConnection = {
  __typename?: 'VideoConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<VideoEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type VideoCreateInput = {
  box?: InputMaybe<CBoxCreateOneInlineInput>;
  source?: InputMaybe<VideosourceUnionCreateOneInlineInput>;
};

export type VideoCreateManyInlineInput = {
  /** Create and connect multiple existing Video documents */
  create?: InputMaybe<Array<VideoCreateInput>>;
};

export type VideoCreateOneInlineInput = {
  /** Create and connect one Video document */
  create?: InputMaybe<VideoCreateInput>;
};

export type VideoCreateWithPositionInput = {
  /** Document to create */
  data: VideoCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

export type VideoDialogGroup = Entity & {
  __typename?: 'VideoDialogGroup';
  /** The unique identifier */
  id: Scalars['ID'];
  /** System stage field */
  stage: Stage;
  tile?: Maybe<CTile>;
  videos: Array<DialogVideo>;
};

export type VideoDialogGroupTileArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
};

export type VideoDialogGroupVideosArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<DialogVideoOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DialogVideoWhereInput>;
};

export type VideoDialogGroupConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: VideoDialogGroupWhereUniqueInput;
};

/** A connection to a list of items. */
export type VideoDialogGroupConnection = {
  __typename?: 'VideoDialogGroupConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<VideoDialogGroupEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type VideoDialogGroupCreateInput = {
  tile?: InputMaybe<CTileCreateOneInlineInput>;
  videos?: InputMaybe<DialogVideoCreateManyInlineInput>;
};

export type VideoDialogGroupCreateManyInlineInput = {
  /** Create and connect multiple existing VideoDialogGroup documents */
  create?: InputMaybe<Array<VideoDialogGroupCreateInput>>;
};

export type VideoDialogGroupCreateOneInlineInput = {
  /** Create and connect one VideoDialogGroup document */
  create?: InputMaybe<VideoDialogGroupCreateInput>;
};

export type VideoDialogGroupCreateWithPositionInput = {
  /** Document to create */
  data: VideoDialogGroupCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type VideoDialogGroupEdge = {
  __typename?: 'VideoDialogGroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: VideoDialogGroup;
};

/** Identifies documents */
export type VideoDialogGroupManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VideoDialogGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VideoDialogGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VideoDialogGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  tile?: InputMaybe<CTileWhereInput>;
  videos_every?: InputMaybe<DialogVideoWhereInput>;
  videos_none?: InputMaybe<DialogVideoWhereInput>;
  videos_some?: InputMaybe<DialogVideoWhereInput>;
};

export enum VideoDialogGroupOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type VideoDialogGroupParent = CBlock;

export type VideoDialogGroupParentConnectInput = {
  CBlock?: InputMaybe<CBlockConnectInput>;
};

export type VideoDialogGroupParentCreateInput = {
  CBlock?: InputMaybe<CBlockCreateInput>;
};

export type VideoDialogGroupParentCreateManyInlineInput = {
  /** Connect multiple existing VideoDialogGroupParent documents */
  connect?: InputMaybe<Array<VideoDialogGroupParentWhereUniqueInput>>;
  /** Create and connect multiple existing VideoDialogGroupParent documents */
  create?: InputMaybe<Array<VideoDialogGroupParentCreateInput>>;
};

export type VideoDialogGroupParentCreateOneInlineInput = {
  /** Connect one existing VideoDialogGroupParent document */
  connect?: InputMaybe<VideoDialogGroupParentWhereUniqueInput>;
  /** Create and connect one VideoDialogGroupParent document */
  create?: InputMaybe<VideoDialogGroupParentCreateInput>;
};

export type VideoDialogGroupParentUpdateInput = {
  CBlock?: InputMaybe<CBlockUpdateInput>;
};

export type VideoDialogGroupParentUpdateManyInlineInput = {
  /** Connect multiple existing VideoDialogGroupParent documents */
  connect?: InputMaybe<Array<VideoDialogGroupParentConnectInput>>;
  /** Create and connect multiple VideoDialogGroupParent documents */
  create?: InputMaybe<Array<VideoDialogGroupParentCreateInput>>;
  /** Delete multiple VideoDialogGroupParent documents */
  delete?: InputMaybe<Array<VideoDialogGroupParentWhereUniqueInput>>;
  /** Disconnect multiple VideoDialogGroupParent documents */
  disconnect?: InputMaybe<Array<VideoDialogGroupParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing VideoDialogGroupParent documents */
  set?: InputMaybe<Array<VideoDialogGroupParentWhereUniqueInput>>;
  /** Update multiple VideoDialogGroupParent documents */
  update?: InputMaybe<
    Array<VideoDialogGroupParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple VideoDialogGroupParent documents */
  upsert?: InputMaybe<
    Array<VideoDialogGroupParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type VideoDialogGroupParentUpdateManyWithNestedWhereInput = {
  CBlock?: InputMaybe<CBlockUpdateManyWithNestedWhereInput>;
};

export type VideoDialogGroupParentUpdateOneInlineInput = {
  /** Connect existing VideoDialogGroupParent document */
  connect?: InputMaybe<VideoDialogGroupParentWhereUniqueInput>;
  /** Create and connect one VideoDialogGroupParent document */
  create?: InputMaybe<VideoDialogGroupParentCreateInput>;
  /** Delete currently connected VideoDialogGroupParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected VideoDialogGroupParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single VideoDialogGroupParent document */
  update?: InputMaybe<VideoDialogGroupParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single VideoDialogGroupParent document */
  upsert?: InputMaybe<VideoDialogGroupParentUpsertWithNestedWhereUniqueInput>;
};

export type VideoDialogGroupParentUpdateWithNestedWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockUpdateWithNestedWhereUniqueInput>;
};

export type VideoDialogGroupParentUpsertWithNestedWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockUpsertWithNestedWhereUniqueInput>;
};

export type VideoDialogGroupParentWhereInput = {
  CBlock?: InputMaybe<CBlockWhereInput>;
};

export type VideoDialogGroupParentWhereUniqueInput = {
  CBlock?: InputMaybe<CBlockWhereUniqueInput>;
};

export type VideoDialogGroupUpdateInput = {
  tile?: InputMaybe<CTileUpdateOneInlineInput>;
  videos?: InputMaybe<DialogVideoUpdateManyInlineInput>;
};

export type VideoDialogGroupUpdateManyInlineInput = {
  /** Create and connect multiple VideoDialogGroup component instances */
  create?: InputMaybe<Array<VideoDialogGroupCreateWithPositionInput>>;
  /** Delete multiple VideoDialogGroup documents */
  delete?: InputMaybe<Array<VideoDialogGroupWhereUniqueInput>>;
  /** Update multiple VideoDialogGroup component instances */
  update?: InputMaybe<
    Array<VideoDialogGroupUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple VideoDialogGroup component instances */
  upsert?: InputMaybe<
    Array<VideoDialogGroupUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type VideoDialogGroupUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']>;
};

export type VideoDialogGroupUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: VideoDialogGroupUpdateManyInput;
  /** Document search */
  where: VideoDialogGroupWhereInput;
};

export type VideoDialogGroupUpdateOneInlineInput = {
  /** Create and connect one VideoDialogGroup document */
  create?: InputMaybe<VideoDialogGroupCreateInput>;
  /** Delete currently connected VideoDialogGroup document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single VideoDialogGroup document */
  update?: InputMaybe<VideoDialogGroupUpdateWithNestedWhereUniqueInput>;
  /** Upsert single VideoDialogGroup document */
  upsert?: InputMaybe<VideoDialogGroupUpsertWithNestedWhereUniqueInput>;
};

export type VideoDialogGroupUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<VideoDialogGroupUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: VideoDialogGroupWhereUniqueInput;
};

export type VideoDialogGroupUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: VideoDialogGroupUpdateInput;
  /** Unique document search */
  where: VideoDialogGroupWhereUniqueInput;
};

export type VideoDialogGroupUpsertInput = {
  /** Create document if it didn't exist */
  create: VideoDialogGroupCreateInput;
  /** Update document if it exists */
  update: VideoDialogGroupUpdateInput;
};

export type VideoDialogGroupUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<VideoDialogGroupUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: VideoDialogGroupWhereUniqueInput;
};

export type VideoDialogGroupUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: VideoDialogGroupUpsertInput;
  /** Unique document search */
  where: VideoDialogGroupWhereUniqueInput;
};

/** Identifies documents */
export type VideoDialogGroupWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VideoDialogGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VideoDialogGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VideoDialogGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  tile?: InputMaybe<CTileWhereInput>;
  videos_every?: InputMaybe<DialogVideoWhereInput>;
  videos_none?: InputMaybe<DialogVideoWhereInput>;
  videos_some?: InputMaybe<DialogVideoWhereInput>;
};

/** References VideoDialogGroup record uniquely */
export type VideoDialogGroupWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** An edge in a connection. */
export type VideoEdge = {
  __typename?: 'VideoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Video;
};

/** Identifies documents */
export type VideoManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VideoWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VideoWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VideoWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  box?: InputMaybe<CBoxWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values in which the modular component is connected to the given models */
  source?: InputMaybe<VideosourceUnionWhereInput>;
  /** All values in which the union is empty. */
  source_empty?: InputMaybe<Scalars['Boolean']>;
};

export enum VideoOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
}

export type VideoParent = CBannerAward | CBannerInline | CBlock | DialogItem;

export type VideoParentConnectInput = {
  CBannerAward?: InputMaybe<CBannerAwardConnectInput>;
  CBannerInline?: InputMaybe<CBannerInlineConnectInput>;
  CBlock?: InputMaybe<CBlockConnectInput>;
  DialogItem?: InputMaybe<DialogItemConnectInput>;
};

export type VideoParentCreateInput = {
  CBannerAward?: InputMaybe<CBannerAwardCreateInput>;
  CBannerInline?: InputMaybe<CBannerInlineCreateInput>;
  CBlock?: InputMaybe<CBlockCreateInput>;
  DialogItem?: InputMaybe<DialogItemCreateInput>;
};

export type VideoParentCreateManyInlineInput = {
  /** Connect multiple existing VideoParent documents */
  connect?: InputMaybe<Array<VideoParentWhereUniqueInput>>;
  /** Create and connect multiple existing VideoParent documents */
  create?: InputMaybe<Array<VideoParentCreateInput>>;
};

export type VideoParentCreateOneInlineInput = {
  /** Connect one existing VideoParent document */
  connect?: InputMaybe<VideoParentWhereUniqueInput>;
  /** Create and connect one VideoParent document */
  create?: InputMaybe<VideoParentCreateInput>;
};

export type VideoParentUpdateInput = {
  CBannerAward?: InputMaybe<CBannerAwardUpdateInput>;
  CBannerInline?: InputMaybe<CBannerInlineUpdateInput>;
  CBlock?: InputMaybe<CBlockUpdateInput>;
  DialogItem?: InputMaybe<DialogItemUpdateInput>;
};

export type VideoParentUpdateManyInlineInput = {
  /** Connect multiple existing VideoParent documents */
  connect?: InputMaybe<Array<VideoParentConnectInput>>;
  /** Create and connect multiple VideoParent documents */
  create?: InputMaybe<Array<VideoParentCreateInput>>;
  /** Delete multiple VideoParent documents */
  delete?: InputMaybe<Array<VideoParentWhereUniqueInput>>;
  /** Disconnect multiple VideoParent documents */
  disconnect?: InputMaybe<Array<VideoParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing VideoParent documents */
  set?: InputMaybe<Array<VideoParentWhereUniqueInput>>;
  /** Update multiple VideoParent documents */
  update?: InputMaybe<Array<VideoParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple VideoParent documents */
  upsert?: InputMaybe<Array<VideoParentUpsertWithNestedWhereUniqueInput>>;
};

export type VideoParentUpdateManyWithNestedWhereInput = {
  CBannerAward?: InputMaybe<CBannerAwardUpdateManyWithNestedWhereInput>;
  CBannerInline?: InputMaybe<CBannerInlineUpdateManyWithNestedWhereInput>;
  CBlock?: InputMaybe<CBlockUpdateManyWithNestedWhereInput>;
  DialogItem?: InputMaybe<DialogItemUpdateManyWithNestedWhereInput>;
};

export type VideoParentUpdateOneInlineInput = {
  /** Connect existing VideoParent document */
  connect?: InputMaybe<VideoParentWhereUniqueInput>;
  /** Create and connect one VideoParent document */
  create?: InputMaybe<VideoParentCreateInput>;
  /** Delete currently connected VideoParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Disconnect currently connected VideoParent document */
  disconnect?: InputMaybe<Scalars['Boolean']>;
  /** Update single VideoParent document */
  update?: InputMaybe<VideoParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single VideoParent document */
  upsert?: InputMaybe<VideoParentUpsertWithNestedWhereUniqueInput>;
};

export type VideoParentUpdateWithNestedWhereUniqueInput = {
  CBannerAward?: InputMaybe<CBannerAwardUpdateWithNestedWhereUniqueInput>;
  CBannerInline?: InputMaybe<CBannerInlineUpdateWithNestedWhereUniqueInput>;
  CBlock?: InputMaybe<CBlockUpdateWithNestedWhereUniqueInput>;
  DialogItem?: InputMaybe<DialogItemUpdateWithNestedWhereUniqueInput>;
};

export type VideoParentUpsertWithNestedWhereUniqueInput = {
  CBannerAward?: InputMaybe<CBannerAwardUpsertWithNestedWhereUniqueInput>;
  CBannerInline?: InputMaybe<CBannerInlineUpsertWithNestedWhereUniqueInput>;
  CBlock?: InputMaybe<CBlockUpsertWithNestedWhereUniqueInput>;
  DialogItem?: InputMaybe<DialogItemUpsertWithNestedWhereUniqueInput>;
};

export type VideoParentWhereInput = {
  CBannerAward?: InputMaybe<CBannerAwardWhereInput>;
  CBannerInline?: InputMaybe<CBannerInlineWhereInput>;
  CBlock?: InputMaybe<CBlockWhereInput>;
  DialogItem?: InputMaybe<DialogItemWhereInput>;
};

export type VideoParentWhereUniqueInput = {
  CBannerAward?: InputMaybe<CBannerAwardWhereUniqueInput>;
  CBannerInline?: InputMaybe<CBannerInlineWhereUniqueInput>;
  CBlock?: InputMaybe<CBlockWhereUniqueInput>;
  DialogItem?: InputMaybe<DialogItemWhereUniqueInput>;
};

export type VideoUpdateInput = {
  box?: InputMaybe<CBoxUpdateOneInlineInput>;
  source?: InputMaybe<VideosourceUnionUpdateOneInlineInput>;
};

export type VideoUpdateManyInlineInput = {
  /** Create and connect multiple Video component instances */
  create?: InputMaybe<Array<VideoCreateWithPositionInput>>;
  /** Delete multiple Video documents */
  delete?: InputMaybe<Array<VideoWhereUniqueInput>>;
  /** Update multiple Video component instances */
  update?: InputMaybe<Array<VideoUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple Video component instances */
  upsert?: InputMaybe<Array<VideoUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type VideoUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars['String']>;
};

export type VideoUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: VideoUpdateManyInput;
  /** Document search */
  where: VideoWhereInput;
};

export type VideoUpdateOneInlineInput = {
  /** Create and connect one Video document */
  create?: InputMaybe<VideoCreateInput>;
  /** Delete currently connected Video document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single Video document */
  update?: InputMaybe<VideoUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Video document */
  upsert?: InputMaybe<VideoUpsertWithNestedWhereUniqueInput>;
};

export type VideoUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<VideoUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: VideoWhereUniqueInput;
};

export type VideoUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: VideoUpdateInput;
  /** Unique document search */
  where: VideoWhereUniqueInput;
};

export type VideoUpsertInput = {
  /** Create document if it didn't exist */
  create: VideoCreateInput;
  /** Update document if it exists */
  update: VideoUpdateInput;
};

export type VideoUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<VideoUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: VideoWhereUniqueInput;
};

export type VideoUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: VideoUpsertInput;
  /** Unique document search */
  where: VideoWhereUniqueInput;
};

/** Identifies documents */
export type VideoWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<VideoWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<VideoWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<VideoWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  box?: InputMaybe<CBoxWhereInput>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values in which the modular component is connected to the given models */
  source?: InputMaybe<VideosourceUnionWhereInput>;
  /** All values in which the union is empty. */
  source_empty?: InputMaybe<Scalars['Boolean']>;
};

/** References Video record uniquely */
export type VideoWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export type VideosourceUnion = BrightcoveVideo | YouTubeVideo;

export type VideosourceUnionConnectInput = {
  BrightcoveVideo?: InputMaybe<BrightcoveVideoConnectInput>;
  YouTubeVideo?: InputMaybe<YouTubeVideoConnectInput>;
};

export type VideosourceUnionCreateInput = {
  BrightcoveVideo?: InputMaybe<BrightcoveVideoCreateInput>;
  YouTubeVideo?: InputMaybe<YouTubeVideoCreateInput>;
};

export type VideosourceUnionCreateManyInlineInput = {
  /** Create and connect multiple existing VideosourceUnion documents */
  create?: InputMaybe<Array<VideosourceUnionCreateInput>>;
};

export type VideosourceUnionCreateOneInlineInput = {
  /** Create and connect one VideosourceUnion document */
  create?: InputMaybe<VideosourceUnionCreateInput>;
};

export type VideosourceUnionCreateWithPositionInput = {
  BrightcoveVideo?: InputMaybe<BrightcoveVideoCreateWithPositionInput>;
  YouTubeVideo?: InputMaybe<YouTubeVideoCreateWithPositionInput>;
};

export type VideosourceUnionUpdateInput = {
  BrightcoveVideo?: InputMaybe<BrightcoveVideoUpdateInput>;
  YouTubeVideo?: InputMaybe<YouTubeVideoUpdateInput>;
};

export type VideosourceUnionUpdateManyInlineInput = {
  /** Create and connect multiple VideosourceUnion component instances */
  create?: InputMaybe<Array<VideosourceUnionCreateWithPositionInput>>;
  /** Delete multiple VideosourceUnion documents */
  delete?: InputMaybe<Array<VideosourceUnionWhereUniqueInput>>;
  /** Update multiple VideosourceUnion component instances */
  update?: InputMaybe<
    Array<VideosourceUnionUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple VideosourceUnion component instances */
  upsert?: InputMaybe<
    Array<VideosourceUnionUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type VideosourceUnionUpdateManyWithNestedWhereInput = {
  BrightcoveVideo?: InputMaybe<BrightcoveVideoUpdateManyWithNestedWhereInput>;
  YouTubeVideo?: InputMaybe<YouTubeVideoUpdateManyWithNestedWhereInput>;
};

export type VideosourceUnionUpdateOneInlineInput = {
  /** Create and connect one VideosourceUnion document */
  create?: InputMaybe<VideosourceUnionCreateInput>;
  /** Delete currently connected VideosourceUnion document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single VideosourceUnion document */
  update?: InputMaybe<VideosourceUnionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single VideosourceUnion document */
  upsert?: InputMaybe<VideosourceUnionUpsertWithNestedWhereUniqueInput>;
};

export type VideosourceUnionUpdateWithNestedWhereUniqueAndPositionInput = {
  BrightcoveVideo?: InputMaybe<BrightcoveVideoUpdateWithNestedWhereUniqueAndPositionInput>;
  YouTubeVideo?: InputMaybe<YouTubeVideoUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type VideosourceUnionUpdateWithNestedWhereUniqueInput = {
  BrightcoveVideo?: InputMaybe<BrightcoveVideoUpdateWithNestedWhereUniqueInput>;
  YouTubeVideo?: InputMaybe<YouTubeVideoUpdateWithNestedWhereUniqueInput>;
};

export type VideosourceUnionUpsertWithNestedWhereUniqueAndPositionInput = {
  BrightcoveVideo?: InputMaybe<BrightcoveVideoUpsertWithNestedWhereUniqueAndPositionInput>;
  YouTubeVideo?: InputMaybe<YouTubeVideoUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type VideosourceUnionUpsertWithNestedWhereUniqueInput = {
  BrightcoveVideo?: InputMaybe<BrightcoveVideoUpsertWithNestedWhereUniqueInput>;
  YouTubeVideo?: InputMaybe<YouTubeVideoUpsertWithNestedWhereUniqueInput>;
};

export type VideosourceUnionWhereInput = {
  BrightcoveVideo?: InputMaybe<BrightcoveVideoWhereInput>;
  YouTubeVideo?: InputMaybe<YouTubeVideoWhereInput>;
};

export type VideosourceUnionWhereUniqueInput = {
  BrightcoveVideo?: InputMaybe<BrightcoveVideoWhereUniqueInput>;
  YouTubeVideo?: InputMaybe<YouTubeVideoWhereUniqueInput>;
};

/** WebForm */
export type WebForm = Entity & {
  __typename?: 'WebForm';
  /** Confirmation Message */
  confirmationMessage?: Maybe<RichText>;
  document?: Maybe<Asset>;
  formType: FormType;
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<WebForm>;
  /** System stage field */
  stage: Stage;
  /** Represents how many columns for the web form. */
  webFormColumns: Scalars['Int'];
};

/** WebForm */
export type WebFormDocumentArgs = {
  forceParentLocale?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<Locale>>;
  where?: InputMaybe<AssetSingleRelationWhereInput>;
};

/** WebForm */
export type WebFormLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type WebFormConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: WebFormWhereUniqueInput;
};

/** A connection to a list of items. */
export type WebFormConnection = {
  __typename?: 'WebFormConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<WebFormEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type WebFormCreateInput = {
  /** confirmationMessage input for default locale (default) */
  confirmationMessage?: InputMaybe<Scalars['RichTextAST']>;
  document?: InputMaybe<AssetCreateOneInlineInput>;
  formType: FormType;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<WebFormCreateLocalizationsInput>;
  webFormColumns: Scalars['Int'];
};

export type WebFormCreateLocalizationDataInput = {
  confirmationMessage?: InputMaybe<Scalars['RichTextAST']>;
};

export type WebFormCreateLocalizationInput = {
  /** Localization input */
  data: WebFormCreateLocalizationDataInput;
  locale: Locale;
};

export type WebFormCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<WebFormCreateLocalizationInput>>;
};

export type WebFormCreateManyInlineInput = {
  /** Create and connect multiple existing WebForm documents */
  create?: InputMaybe<Array<WebFormCreateInput>>;
};

export type WebFormCreateOneInlineInput = {
  /** Create and connect one WebForm document */
  create?: InputMaybe<WebFormCreateInput>;
};

export type WebFormCreateWithPositionInput = {
  /** Document to create */
  data: WebFormCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type WebFormEdge = {
  __typename?: 'WebFormEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: WebForm;
};

/** Identifies documents */
export type WebFormManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<WebFormWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<WebFormWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<WebFormWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  document?: InputMaybe<AssetWhereInput>;
  formType?: InputMaybe<FormType>;
  /** All values that are contained in given list. */
  formType_in?: InputMaybe<Array<InputMaybe<FormType>>>;
  /** Any other value that exists and is not equal to the given value. */
  formType_not?: InputMaybe<FormType>;
  /** All values that are not contained in given list. */
  formType_not_in?: InputMaybe<Array<InputMaybe<FormType>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  webFormColumns?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  webFormColumns_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  webFormColumns_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  webFormColumns_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** All values less than the given value. */
  webFormColumns_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  webFormColumns_lte?: InputMaybe<Scalars['Int']>;
  /** Any other value that exists and is not equal to the given value. */
  webFormColumns_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  webFormColumns_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

export enum WebFormOrderByInput {
  FormTypeAsc = 'formType_ASC',
  FormTypeDesc = 'formType_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  WebFormColumnsAsc = 'webFormColumns_ASC',
  WebFormColumnsDesc = 'webFormColumns_DESC',
}

export type WebFormParent = CCustomComponent;

export type WebFormParentConnectInput = {
  CCustomComponent?: InputMaybe<CCustomComponentConnectInput>;
};

export type WebFormParentCreateInput = {
  CCustomComponent?: InputMaybe<CCustomComponentCreateInput>;
};

export type WebFormParentCreateManyInlineInput = {
  /** Create and connect multiple existing WebFormParent documents */
  create?: InputMaybe<Array<WebFormParentCreateInput>>;
};

export type WebFormParentCreateOneInlineInput = {
  /** Create and connect one WebFormParent document */
  create?: InputMaybe<WebFormParentCreateInput>;
};

export type WebFormParentCreateWithPositionInput = {
  CCustomComponent?: InputMaybe<CCustomComponentCreateWithPositionInput>;
};

export type WebFormParentUpdateInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpdateInput>;
};

export type WebFormParentUpdateManyInlineInput = {
  /** Create and connect multiple WebFormParent component instances */
  create?: InputMaybe<Array<WebFormParentCreateWithPositionInput>>;
  /** Delete multiple WebFormParent documents */
  delete?: InputMaybe<Array<WebFormParentWhereUniqueInput>>;
  /** Update multiple WebFormParent component instances */
  update?: InputMaybe<
    Array<WebFormParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple WebFormParent component instances */
  upsert?: InputMaybe<
    Array<WebFormParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type WebFormParentUpdateManyWithNestedWhereInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpdateManyWithNestedWhereInput>;
};

export type WebFormParentUpdateOneInlineInput = {
  /** Create and connect one WebFormParent document */
  create?: InputMaybe<WebFormParentCreateInput>;
  /** Delete currently connected WebFormParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single WebFormParent document */
  update?: InputMaybe<WebFormParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single WebFormParent document */
  upsert?: InputMaybe<WebFormParentUpsertWithNestedWhereUniqueInput>;
};

export type WebFormParentUpdateWithNestedWhereUniqueAndPositionInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type WebFormParentUpdateWithNestedWhereUniqueInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpdateWithNestedWhereUniqueInput>;
};

export type WebFormParentUpsertWithNestedWhereUniqueAndPositionInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type WebFormParentUpsertWithNestedWhereUniqueInput = {
  CCustomComponent?: InputMaybe<CCustomComponentUpsertWithNestedWhereUniqueInput>;
};

export type WebFormParentWhereInput = {
  CCustomComponent?: InputMaybe<CCustomComponentWhereInput>;
};

export type WebFormParentWhereUniqueInput = {
  CCustomComponent?: InputMaybe<CCustomComponentWhereUniqueInput>;
};

export type WebFormUpdateInput = {
  /** confirmationMessage input for default locale (default) */
  confirmationMessage?: InputMaybe<Scalars['RichTextAST']>;
  document?: InputMaybe<AssetUpdateOneInlineInput>;
  formType?: InputMaybe<FormType>;
  /** Manage document localizations */
  localizations?: InputMaybe<WebFormUpdateLocalizationsInput>;
  webFormColumns?: InputMaybe<Scalars['Int']>;
};

export type WebFormUpdateLocalizationDataInput = {
  confirmationMessage?: InputMaybe<Scalars['RichTextAST']>;
};

export type WebFormUpdateLocalizationInput = {
  data: WebFormUpdateLocalizationDataInput;
  locale: Locale;
};

export type WebFormUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<WebFormCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<WebFormUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<WebFormUpsertLocalizationInput>>;
};

export type WebFormUpdateManyInlineInput = {
  /** Create and connect multiple WebForm component instances */
  create?: InputMaybe<Array<WebFormCreateWithPositionInput>>;
  /** Delete multiple WebForm documents */
  delete?: InputMaybe<Array<WebFormWhereUniqueInput>>;
  /** Update multiple WebForm component instances */
  update?: InputMaybe<
    Array<WebFormUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple WebForm component instances */
  upsert?: InputMaybe<
    Array<WebFormUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type WebFormUpdateManyInput = {
  /** confirmationMessage input for default locale (default) */
  confirmationMessage?: InputMaybe<Scalars['RichTextAST']>;
  formType?: InputMaybe<FormType>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<WebFormUpdateManyLocalizationsInput>;
  webFormColumns?: InputMaybe<Scalars['Int']>;
};

export type WebFormUpdateManyLocalizationDataInput = {
  confirmationMessage?: InputMaybe<Scalars['RichTextAST']>;
};

export type WebFormUpdateManyLocalizationInput = {
  data: WebFormUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type WebFormUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<WebFormUpdateManyLocalizationInput>>;
};

export type WebFormUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: WebFormUpdateManyInput;
  /** Document search */
  where: WebFormWhereInput;
};

export type WebFormUpdateOneInlineInput = {
  /** Create and connect one WebForm document */
  create?: InputMaybe<WebFormCreateInput>;
  /** Delete currently connected WebForm document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single WebForm document */
  update?: InputMaybe<WebFormUpdateWithNestedWhereUniqueInput>;
  /** Upsert single WebForm document */
  upsert?: InputMaybe<WebFormUpsertWithNestedWhereUniqueInput>;
};

export type WebFormUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<WebFormUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: WebFormWhereUniqueInput;
};

export type WebFormUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: WebFormUpdateInput;
  /** Unique document search */
  where: WebFormWhereUniqueInput;
};

export type WebFormUpsertInput = {
  /** Create document if it didn't exist */
  create: WebFormCreateInput;
  /** Update document if it exists */
  update: WebFormUpdateInput;
};

export type WebFormUpsertLocalizationInput = {
  create: WebFormCreateLocalizationDataInput;
  locale: Locale;
  update: WebFormUpdateLocalizationDataInput;
};

export type WebFormUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<WebFormUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: WebFormWhereUniqueInput;
};

export type WebFormUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: WebFormUpsertInput;
  /** Unique document search */
  where: WebFormWhereUniqueInput;
};

/** Identifies documents */
export type WebFormWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<WebFormWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<WebFormWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<WebFormWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  document?: InputMaybe<AssetWhereInput>;
  formType?: InputMaybe<FormType>;
  /** All values that are contained in given list. */
  formType_in?: InputMaybe<Array<InputMaybe<FormType>>>;
  /** Any other value that exists and is not equal to the given value. */
  formType_not?: InputMaybe<FormType>;
  /** All values that are not contained in given list. */
  formType_not_in?: InputMaybe<Array<InputMaybe<FormType>>>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  webFormColumns?: InputMaybe<Scalars['Int']>;
  /** All values greater than the given value. */
  webFormColumns_gt?: InputMaybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  webFormColumns_gte?: InputMaybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  webFormColumns_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** All values less than the given value. */
  webFormColumns_lt?: InputMaybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  webFormColumns_lte?: InputMaybe<Scalars['Int']>;
  /** Any other value that exists and is not equal to the given value. */
  webFormColumns_not?: InputMaybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  webFormColumns_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
};

/** References WebForm record uniquely */
export type WebFormWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

/** Content width standards */
export enum Width {
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
  Xsmall = 'xsmall',
}

/** Used to embed YouTube videos. */
export type YouTubeVideo = Entity & {
  __typename?: 'YouTubeVideo';
  /** The unique identifier */
  id: Scalars['ID'];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<YouTubeVideo>;
  /** System stage field */
  stage: Stage;
  videoId: Scalars['String'];
};

/** Used to embed YouTube videos. */
export type YouTubeVideoLocalizationsArgs = {
  includeCurrent?: Scalars['Boolean'];
  locales?: Array<Locale>;
};

export type YouTubeVideoConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: YouTubeVideoWhereUniqueInput;
};

/** A connection to a list of items. */
export type YouTubeVideoConnection = {
  __typename?: 'YouTubeVideoConnection';
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<YouTubeVideoEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type YouTubeVideoCreateInput = {
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<YouTubeVideoCreateLocalizationsInput>;
  /** videoId input for default locale (default) */
  videoId: Scalars['String'];
};

export type YouTubeVideoCreateLocalizationDataInput = {
  videoId: Scalars['String'];
};

export type YouTubeVideoCreateLocalizationInput = {
  /** Localization input */
  data: YouTubeVideoCreateLocalizationDataInput;
  locale: Locale;
};

export type YouTubeVideoCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<YouTubeVideoCreateLocalizationInput>>;
};

export type YouTubeVideoCreateManyInlineInput = {
  /** Create and connect multiple existing YouTubeVideo documents */
  create?: InputMaybe<Array<YouTubeVideoCreateInput>>;
};

export type YouTubeVideoCreateOneInlineInput = {
  /** Create and connect one YouTubeVideo document */
  create?: InputMaybe<YouTubeVideoCreateInput>;
};

export type YouTubeVideoCreateWithPositionInput = {
  /** Document to create */
  data: YouTubeVideoCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type YouTubeVideoEdge = {
  __typename?: 'YouTubeVideoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: YouTubeVideo;
};

/** Identifies documents */
export type YouTubeVideoManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<YouTubeVideoWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<YouTubeVideoWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<YouTubeVideoWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
};

export enum YouTubeVideoOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  VideoIdAsc = 'videoId_ASC',
  VideoIdDesc = 'videoId_DESC',
}

export type YouTubeVideoParent = Video;

export type YouTubeVideoParentConnectInput = {
  Video?: InputMaybe<VideoConnectInput>;
};

export type YouTubeVideoParentCreateInput = {
  Video?: InputMaybe<VideoCreateInput>;
};

export type YouTubeVideoParentCreateManyInlineInput = {
  /** Create and connect multiple existing YouTubeVideoParent documents */
  create?: InputMaybe<Array<YouTubeVideoParentCreateInput>>;
};

export type YouTubeVideoParentCreateOneInlineInput = {
  /** Create and connect one YouTubeVideoParent document */
  create?: InputMaybe<YouTubeVideoParentCreateInput>;
};

export type YouTubeVideoParentCreateWithPositionInput = {
  Video?: InputMaybe<VideoCreateWithPositionInput>;
};

export type YouTubeVideoParentUpdateInput = {
  Video?: InputMaybe<VideoUpdateInput>;
};

export type YouTubeVideoParentUpdateManyInlineInput = {
  /** Create and connect multiple YouTubeVideoParent component instances */
  create?: InputMaybe<Array<YouTubeVideoParentCreateWithPositionInput>>;
  /** Delete multiple YouTubeVideoParent documents */
  delete?: InputMaybe<Array<YouTubeVideoParentWhereUniqueInput>>;
  /** Update multiple YouTubeVideoParent component instances */
  update?: InputMaybe<
    Array<YouTubeVideoParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple YouTubeVideoParent component instances */
  upsert?: InputMaybe<
    Array<YouTubeVideoParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type YouTubeVideoParentUpdateManyWithNestedWhereInput = {
  Video?: InputMaybe<VideoUpdateManyWithNestedWhereInput>;
};

export type YouTubeVideoParentUpdateOneInlineInput = {
  /** Create and connect one YouTubeVideoParent document */
  create?: InputMaybe<YouTubeVideoParentCreateInput>;
  /** Delete currently connected YouTubeVideoParent document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single YouTubeVideoParent document */
  update?: InputMaybe<YouTubeVideoParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single YouTubeVideoParent document */
  upsert?: InputMaybe<YouTubeVideoParentUpsertWithNestedWhereUniqueInput>;
};

export type YouTubeVideoParentUpdateWithNestedWhereUniqueAndPositionInput = {
  Video?: InputMaybe<VideoUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type YouTubeVideoParentUpdateWithNestedWhereUniqueInput = {
  Video?: InputMaybe<VideoUpdateWithNestedWhereUniqueInput>;
};

export type YouTubeVideoParentUpsertWithNestedWhereUniqueAndPositionInput = {
  Video?: InputMaybe<VideoUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type YouTubeVideoParentUpsertWithNestedWhereUniqueInput = {
  Video?: InputMaybe<VideoUpsertWithNestedWhereUniqueInput>;
};

export type YouTubeVideoParentWhereInput = {
  Video?: InputMaybe<VideoWhereInput>;
};

export type YouTubeVideoParentWhereUniqueInput = {
  Video?: InputMaybe<VideoWhereUniqueInput>;
};

export type YouTubeVideoUpdateInput = {
  /** Manage document localizations */
  localizations?: InputMaybe<YouTubeVideoUpdateLocalizationsInput>;
  /** videoId input for default locale (default) */
  videoId?: InputMaybe<Scalars['String']>;
};

export type YouTubeVideoUpdateLocalizationDataInput = {
  videoId?: InputMaybe<Scalars['String']>;
};

export type YouTubeVideoUpdateLocalizationInput = {
  data: YouTubeVideoUpdateLocalizationDataInput;
  locale: Locale;
};

export type YouTubeVideoUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<YouTubeVideoCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<YouTubeVideoUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<YouTubeVideoUpsertLocalizationInput>>;
};

export type YouTubeVideoUpdateManyInlineInput = {
  /** Create and connect multiple YouTubeVideo component instances */
  create?: InputMaybe<Array<YouTubeVideoCreateWithPositionInput>>;
  /** Delete multiple YouTubeVideo documents */
  delete?: InputMaybe<Array<YouTubeVideoWhereUniqueInput>>;
  /** Update multiple YouTubeVideo component instances */
  update?: InputMaybe<
    Array<YouTubeVideoUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple YouTubeVideo component instances */
  upsert?: InputMaybe<
    Array<YouTubeVideoUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type YouTubeVideoUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<YouTubeVideoUpdateManyLocalizationsInput>;
  /** videoId input for default locale (default) */
  videoId?: InputMaybe<Scalars['String']>;
};

export type YouTubeVideoUpdateManyLocalizationDataInput = {
  videoId?: InputMaybe<Scalars['String']>;
};

export type YouTubeVideoUpdateManyLocalizationInput = {
  data: YouTubeVideoUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type YouTubeVideoUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<YouTubeVideoUpdateManyLocalizationInput>>;
};

export type YouTubeVideoUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: YouTubeVideoUpdateManyInput;
  /** Document search */
  where: YouTubeVideoWhereInput;
};

export type YouTubeVideoUpdateOneInlineInput = {
  /** Create and connect one YouTubeVideo document */
  create?: InputMaybe<YouTubeVideoCreateInput>;
  /** Delete currently connected YouTubeVideo document */
  delete?: InputMaybe<Scalars['Boolean']>;
  /** Update single YouTubeVideo document */
  update?: InputMaybe<YouTubeVideoUpdateWithNestedWhereUniqueInput>;
  /** Upsert single YouTubeVideo document */
  upsert?: InputMaybe<YouTubeVideoUpsertWithNestedWhereUniqueInput>;
};

export type YouTubeVideoUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<YouTubeVideoUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: YouTubeVideoWhereUniqueInput;
};

export type YouTubeVideoUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: YouTubeVideoUpdateInput;
  /** Unique document search */
  where: YouTubeVideoWhereUniqueInput;
};

export type YouTubeVideoUpsertInput = {
  /** Create document if it didn't exist */
  create: YouTubeVideoCreateInput;
  /** Update document if it exists */
  update: YouTubeVideoUpdateInput;
};

export type YouTubeVideoUpsertLocalizationInput = {
  create: YouTubeVideoCreateLocalizationDataInput;
  locale: Locale;
  update: YouTubeVideoUpdateLocalizationDataInput;
};

export type YouTubeVideoUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<YouTubeVideoUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: YouTubeVideoWhereUniqueInput;
};

export type YouTubeVideoUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: YouTubeVideoUpsertInput;
  /** Unique document search */
  where: YouTubeVideoWhereUniqueInput;
};

/** Identifies documents */
export type YouTubeVideoWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<YouTubeVideoWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<YouTubeVideoWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<YouTubeVideoWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars['ID']>;
  videoId?: InputMaybe<Scalars['String']>;
  /** All values containing the given string. */
  videoId_contains?: InputMaybe<Scalars['String']>;
  /** All values ending with the given string. */
  videoId_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are contained in given list. */
  videoId_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Any other value that exists and is not equal to the given value. */
  videoId_not?: InputMaybe<Scalars['String']>;
  /** All values not containing the given string. */
  videoId_not_contains?: InputMaybe<Scalars['String']>;
  /** All values not ending with the given string */
  videoId_not_ends_with?: InputMaybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  videoId_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** All values not starting with the given string. */
  videoId_not_starts_with?: InputMaybe<Scalars['String']>;
  /** All values starting with the given string. */
  videoId_starts_with?: InputMaybe<Scalars['String']>;
};

/** References YouTubeVideo record uniquely */
export type YouTubeVideoWhereUniqueInput = {
  id?: InputMaybe<Scalars['ID']>;
};

export enum _FilterKind {
  And = 'AND',
  Not = 'NOT',
  Or = 'OR',
  Contains = 'contains',
  ContainsAll = 'contains_all',
  ContainsNone = 'contains_none',
  ContainsSome = 'contains_some',
  EndsWith = 'ends_with',
  Eq = 'eq',
  EqNot = 'eq_not',
  Gt = 'gt',
  Gte = 'gte',
  In = 'in',
  JsonPathExists = 'json_path_exists',
  JsonValueRecursive = 'json_value_recursive',
  Lt = 'lt',
  Lte = 'lte',
  NotContains = 'not_contains',
  NotEndsWith = 'not_ends_with',
  NotIn = 'not_in',
  NotStartsWith = 'not_starts_with',
  RelationalEvery = 'relational_every',
  RelationalNone = 'relational_none',
  RelationalSingle = 'relational_single',
  RelationalSome = 'relational_some',
  Search = 'search',
  StartsWith = 'starts_with',
  UnionEmpty = 'union_empty',
  UnionEvery = 'union_every',
  UnionNone = 'union_none',
  UnionSingle = 'union_single',
  UnionSome = 'union_some',
}

export enum _MutationInputFieldKind {
  Enum = 'enum',
  Relation = 'relation',
  RichText = 'richText',
  RichTextWithEmbeds = 'richTextWithEmbeds',
  Scalar = 'scalar',
  Union = 'union',
  Virtual = 'virtual',
}

export enum _MutationKind {
  Create = 'create',
  Delete = 'delete',
  DeleteMany = 'deleteMany',
  Publish = 'publish',
  PublishMany = 'publishMany',
  SchedulePublish = 'schedulePublish',
  ScheduleUnpublish = 'scheduleUnpublish',
  Unpublish = 'unpublish',
  UnpublishMany = 'unpublishMany',
  Update = 'update',
  UpdateMany = 'updateMany',
  Upsert = 'upsert',
}

export enum _OrderDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export enum _RelationInputCardinality {
  Many = 'many',
  One = 'one',
}

export enum _RelationInputKind {
  Create = 'create',
  Update = 'update',
}

export enum _RelationKind {
  Regular = 'regular',
  Union = 'union',
}

export enum _SystemDateTimeFieldVariation {
  Base = 'base',
  Combined = 'combined',
  Localization = 'localization',
}

export type RichTextAccordionFragment = {
  __typename?: 'Accordion';
  id: string;
  locale: Locale;
  label: string;
  content: { __typename?: 'RichText'; raw: any };
};

export type EmbedAccordionGroupFragment = {
  __typename?: 'EmbedAccordionGroup';
  id: string;
  accordion: Array<{ __typename?: 'Accordion' } & RichTextAccordionFragment>;
};

export type EmbedCustomContentFragment = {
  __typename?: 'EmbedCustomContent';
  id: string;
  title: string;
};

export type GetArticlesQueryVariables = Exact<{
  where: ArticleWhereInput;
  locale: Array<Locale> | Locale;
  localesWithDefault: Array<Locale> | Locale;
  stage: Stage;
}>;

export type GetArticlesQuery = {
  __typename?: 'Query';
  articles: Array<{
    __typename?: 'Article';
    id: string;
    stage: Stage;
    localizations: Array<{
      __typename?: 'Article';
      locale: Locale;
      slug: string;
      timeToRead?: number | null;
      title: string;
      articleType?: ArticleType | null;
      excerpt?: string | null;
      showExcerpt: boolean;
      mediaUrl?: string | null;
      stage: Stage;
      authors: Array<{ __typename?: 'Author'; name: string; slug: string }>;
      content: {
        __typename?: 'ArticleContentRichText';
        raw: any;
        text: string;
        references: Array<
          | ({
              __typename: 'EmbedAccordionGroup';
            } & EmbedAccordionGroupFragment)
          | ({ __typename: 'EmbedCustomContent' } & EmbedCustomContentFragment)
        >;
      };
      hideEmbeddedFormInSection?: {
        __typename?: 'Section';
        id: string;
        sectionName: string;
      } | null;
      featureImage: Array<{
        __typename?: 'Asset';
        alt?: string | null;
        url: string;
        height?: number | null;
        width?: number | null;
      }>;
      podcast?: {
        __typename?: 'Podcast';
        appleUrl?: string | null;
        googleUrl?: string | null;
        spotifyUrl?: string | null;
      } | null;
      seo?: {
        __typename?: 'Seo';
        title?: string | null;
        description?: string | null;
        noArchive?: boolean | null;
        noIndex?: boolean | null;
        image?: { __typename?: 'Asset'; url: string } | null;
      } | null;
      category?: {
        __typename?: 'Category';
        name: string;
        slug: string;
        relatedCategorySection?: {
          __typename?: 'Section';
          sectionName: string;
          displayName?: string | null;
          theme?: Theme | null;
          footerOptions?:
            | ({ __typename?: 'SectionFooter' } & SectionFooterFragment)
            | null;
          headerOptions?:
            | ({ __typename?: 'SectionHeader' } & SectionHeaderFragment)
            | null;
          parentSection?: {
            __typename?: 'Section';
            sectionName: string;
            displayName?: string | null;
          } | null;
        } | null;
        parent?: { __typename?: 'Category'; name: string; slug: string } | null;
        additionalContent?:
          | ({
              __typename?: 'ArticleAdditionalContent';
            } & ArticleAdditionalContentFragment)
          | null;
      } | null;
      additionalContent?:
        | ({
            __typename?: 'ArticleAdditionalContent';
          } & ArticleAdditionalContentFragment)
        | null;
    }>;
    sites: Array<{ __typename?: 'Site'; name: string }>;
  }>;
};

export type GetCategoryQueryVariables = Exact<{
  where: CategoryWhereUniqueInput;
}>;

export type GetCategoryQuery = {
  __typename?: 'Query';
  category?: {
    __typename?: 'Category';
    id: string;
    name: string;
    relatedCategorySection?: {
      __typename?: 'Section';
      sectionName: string;
      displayName?: string | null;
      theme?: Theme | null;
      footerOptions?:
        | ({ __typename?: 'SectionFooter' } & SectionFooterFragment)
        | null;
      headerOptions?:
        | ({ __typename?: 'SectionHeader' } & SectionHeaderFragment)
        | null;
    } | null;
  } | null;
};

export type GetFormDataQueryVariables = Exact<{
  where: FormWhereInput;
  locale: Array<Locale> | Locale;
  stage: Stage;
  first: Scalars['Int'];
}>;

export type GetFormDataQuery = {
  __typename?: 'Query';
  forms: Array<{
    __typename?: 'Form';
    id: string;
    title?: string | null;
    description?: string | null;
    disclaimerText?: string | null;
    buttonColour?: Colour | null;
    locale: Locale;
    skipDefaultLocale?: boolean | null;
    columns: number;
    apiEndpoint: string;
    slug?: string | null;
    ctaText?: string | null;
    formType?: CFormType | null;
    consentText?: { __typename?: 'RichText'; raw: any } | null;
    confirmationMessage?: { __typename?: 'RichText'; raw: any } | null;
    sites: Array<{ __typename?: 'Site'; name: string }>;
    fields: Array<
      | {
          __typename: 'FormCheckbox';
          key: string;
          required: boolean;
          locale: Locale;
          checkboxLabel: string;
        }
      | {
          __typename: 'FormCheckboxGroup';
          key: string;
          label: string;
          rowSpan: boolean;
          required: boolean;
          locale: Locale;
          options: Array<{
            __typename?: 'FieldOption';
            name: string;
            checkboxLabel: string;
          }>;
          validators: Array<
            | {
                __typename: 'MaxCheckedItemValidator';
                value: number;
                errorMessage?: string | null;
              }
            | {
                __typename: 'MinCheckedItemValidator';
                value: number;
                errorMessage?: string | null;
              }
          >;
        }
      | {
          __typename: 'FormInputEmail';
          key: string;
          placeholder?: string | null;
          rowSpan: boolean;
          required: boolean;
          locale: Locale;
          inputLabel: string;
          validators: Array<{
            __typename: 'EmailValidator';
            errorMessage?: string | null;
          }>;
        }
      | {
          __typename: 'FormInputNumber';
          key: string;
          placeholder?: string | null;
          rowSpan: boolean;
          required: boolean;
          locale: Locale;
          inputLabel: string;
          validators: Array<
            | {
                __typename: 'MaxNumberValidator';
                value: number;
                errorMessage?: string | null;
              }
            | {
                __typename: 'MinNumberValidator';
                value: number;
                errorMessage?: string | null;
              }
          >;
        }
      | {
          __typename: 'FormInputText';
          key: string;
          placeholder?: string | null;
          rowSpan: boolean;
          required: boolean;
          locale: Locale;
          inputLabel: string;
          validators: Array<
            | {
                __typename: 'MaxLengthValidator';
                value: number;
                errorMessage?: string | null;
              }
            | {
                __typename: 'MinLengthValidator';
                value: number;
                errorMessage?: string | null;
              }
          >;
        }
      | {
          __typename: 'FormSelect';
          key: string;
          placeholder?: string | null;
          locale: Locale;
          rowSpan: boolean;
          required: boolean;
          selectLabel: string;
          options: Array<{
            __typename?: 'FieldOption';
            value: string;
            label: string;
          }>;
        }
      | {
          __typename: 'FormTextarea';
          key: string;
          placeholder?: string | null;
          required: boolean;
          locale: Locale;
          textareaLabel: string;
          validators: Array<
            | {
                __typename: 'MaxLengthValidator';
                value: number;
                errorMessage?: string | null;
              }
            | {
                __typename: 'MinLengthValidator';
                value: number;
                errorMessage?: string | null;
              }
          >;
        }
    >;
  }>;
};

export type GetImageAssetQueryVariables = Exact<{
  where: AssetWhereUniqueInput;
}>;

export type GetImageAssetQuery = {
  __typename?: 'Query';
  asset?: {
    __typename?: 'Asset';
    id: string;
    width?: number | null;
    height?: number | null;
    alt?: string | null;
    url: string;
  } | null;
};

export type GetLatestArticlesByCategoryQueryVariables = Exact<{
  where: CategoryWhereInput;
  totalArticles?: InputMaybe<Scalars['Int']>;
  site: Scalars['String'];
  locale: Array<Locale> | Locale;
  localesWithDefault: Array<Locale> | Locale;
  stage: Stage;
  ignoredArticleId?: InputMaybe<Scalars['ID']>;
}>;

export type GetLatestArticlesByCategoryQuery = {
  __typename?: 'Query';
  categories: Array<{
    __typename?: 'Category';
    stage: Stage;
    localizations: Array<{
      __typename?: 'Category';
      locale: Locale;
      name: string;
      slug: string;
      parent?: {
        __typename?: 'Category';
        id: string;
        name: string;
        slug: string;
      } | null;
      description?: { __typename?: 'RichText'; text: string } | null;
      relatedCategorySection?: {
        __typename?: 'Section';
        id: string;
        sectionName: string;
        displayName?: string | null;
        theme?: Theme | null;
        footerOptions?:
          | ({ __typename?: 'SectionFooter' } & SectionFooterFragment)
          | null;
        headerOptions?:
          | ({ __typename?: 'SectionHeader' } & SectionHeaderFragment)
          | null;
        parentSection?: {
          __typename?: 'Section';
          id: string;
          sectionName: string;
          displayName?: string | null;
        } | null;
      } | null;
      relatedArticlesInCategory: Array<{
        __typename?: 'Article';
        title: string;
        articleType?: ArticleType | null;
        slug: string;
        excerpt?: string | null;
        publishDate: any;
        timeToRead?: number | null;
        category?: { __typename?: 'Category'; name: string } | null;
        featureImage: Array<{
          __typename?: 'Asset';
          id: string;
          fileName: string;
          locale: Locale;
          url: string;
        }>;
      }>;
    }>;
    sites: Array<{ __typename?: 'Site'; name: string }>;
  }>;
};

export type GetArticlesByAuthorPaginationQueryVariables = Exact<{
  where: ArticleWhereInput;
  locale: Array<Locale> | Locale;
  localesWithDefault: Array<Locale> | Locale;
  first: Scalars['Int'];
  skip: Scalars['Int'];
}>;

export type GetArticlesByAuthorPaginationQuery = {
  __typename?: 'Query';
  articlesConnection: {
    __typename?: 'ArticleConnection';
    edges: Array<{
      __typename?: 'ArticleEdge';
      cursor: string;
      node: {
        __typename?: 'Article';
        id: string;
        title: string;
        articleType?: ArticleType | null;
        timeToRead?: number | null;
        excerpt?: string | null;
        slug: string;
        locale: Locale;
        publishDate: any;
        featureImage: Array<{
          __typename?: 'Asset';
          locale: Locale;
          id: string;
          fileName: string;
          url: string;
        }>;
        category?: {
          __typename?: 'Category';
          name: string;
          slug: string;
          locale: Locale;
          relatedCategorySection?: {
            __typename?: 'Section';
            sectionName: string;
            displayName?: string | null;
          } | null;
        } | null;
        authors: Array<{
          __typename?: 'Author';
          name: string;
          slug: string;
          locale: Locale;
          description?: {
            __typename?: 'RichText';
            raw: any;
            text: string;
          } | null;
          image?: {
            __typename?: 'Asset';
            alt?: string | null;
            url: string;
          } | null;
        }>;
      };
    }>;
    aggregate: { __typename?: 'Aggregate'; count: number };
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
      pageSize?: number | null;
    };
  };
};

export type GetArticlesByCategoryPaginationQueryVariables = Exact<{
  where: ArticleWhereInput;
  locale: Array<Locale> | Locale;
  localesWithDefault: Array<Locale> | Locale;
  stage: Stage;
  first: Scalars['Int'];
  skip: Scalars['Int'];
}>;

export type GetArticlesByCategoryPaginationQuery = {
  __typename?: 'Query';
  articlesConnection: {
    __typename?: 'ArticleConnection';
    edges: Array<{
      __typename?: 'ArticleEdge';
      cursor: string;
      node: {
        __typename?: 'Article';
        id: string;
        title: string;
        articleType?: ArticleType | null;
        timeToRead?: number | null;
        excerpt?: string | null;
        slug: string;
        publishDate: any;
        featureImage: Array<{
          __typename?: 'Asset';
          locale: Locale;
          id: string;
          fileName: string;
          url: string;
        }>;
        category?: {
          __typename?: 'Category';
          name: string;
          slug: string;
          description?: { __typename?: 'RichText'; text: string } | null;
          parent?: {
            __typename?: 'Category';
            id: string;
            name: string;
            slug: string;
          } | null;
          relatedCategorySection?: {
            __typename?: 'Section';
            sectionName: string;
            displayName?: string | null;
            theme?: Theme | null;
            footerOptions?:
              | ({ __typename?: 'SectionFooter' } & SectionFooterFragment)
              | null;
            headerOptions?:
              | ({ __typename?: 'SectionHeader' } & SectionHeaderFragment)
              | null;
            parentSection?: {
              __typename?: 'Section';
              sectionName: string;
              displayName?: string | null;
            } | null;
          } | null;
          localizations: Array<{ __typename?: 'Category'; locale: Locale }>;
          sites: Array<{ __typename?: 'Site'; name: string }>;
        } | null;
      };
    }>;
    aggregate: { __typename?: 'Aggregate'; count: number };
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
      pageSize?: number | null;
    };
  };
};

export type GetPreviewDataQueryVariables = Exact<{
  whereArticle: ArticleWhereUniqueInput;
  wherePage: PageWhereUniqueInput;
  stage: Stage;
}>;

export type GetPreviewDataQuery = {
  __typename?: 'Query';
  article?: {
    __typename?: 'Article';
    id: string;
    stage: Stage;
    localizations: Array<{
      __typename?: 'Article';
      title: string;
      slug: string;
      locale: Locale;
      category?: {
        __typename?: 'Category';
        relatedCategorySection?: {
          __typename?: 'Section';
          sectionName: string;
          parentSection?: {
            __typename?: 'Section';
            sectionName: string;
          } | null;
        } | null;
      } | null;
    }>;
    sites: Array<{ __typename?: 'Site'; name: string }>;
  } | null;
  page?: {
    __typename?: 'Page';
    stage: Stage;
    id: string;
    relatedPageSection?: {
      __typename?: 'Section';
      id: string;
      sectionName: string;
      parentSection?: { __typename?: 'Section'; sectionName: string } | null;
    } | null;
    sites: Array<{ __typename?: 'Site'; name: string }>;
    localizations: Array<{
      __typename?: 'Page';
      title: string;
      slug: string;
      locale: Locale;
    }>;
  } | null;
};

export type GetSectionsQueryVariables = Exact<{
  where?: InputMaybe<SectionWhereInput>;
}>;

export type GetSectionsQuery = {
  __typename?: 'Query';
  sections: Array<{
    __typename?: 'Section';
    sectionName: string;
    theme?: Theme | null;
    footerOptions?:
      | ({ __typename?: 'SectionFooter' } & SectionFooterFragment)
      | null;
    headerOptions?:
      | ({ __typename?: 'SectionHeader' } & SectionHeaderFragment)
      | null;
  }>;
};

export type GetAuthorWithArticlesQueryVariables = Exact<{
  slug: Scalars['String'];
  site: Scalars['String'];
  locale: Array<Locale> | Locale;
  localesWithDefault: Array<Locale> | Locale;
  stage: Stage;
  first: Scalars['Int'];
  skip: Scalars['Int'];
}>;

export type GetAuthorWithArticlesQuery = {
  __typename?: 'Query';
  authors: Array<{
    __typename?: 'Author';
    id: string;
    stage: Stage;
    localizations: Array<{
      __typename?: 'Author';
      name: string;
      slug: string;
      locale: Locale;
      description?: { __typename?: 'RichText'; raw: any; text: string } | null;
      image?: { __typename?: 'Asset'; alt?: string | null; url: string } | null;
    }>;
    sites: Array<{ __typename?: 'Site'; name: string }>;
  }>;
  articlesConnection: {
    __typename?: 'ArticleConnection';
    edges: Array<{
      __typename?: 'ArticleEdge';
      cursor: string;
      node: {
        __typename?: 'Article';
        id: string;
        title: string;
        articleType?: ArticleType | null;
        timeToRead?: number | null;
        excerpt?: string | null;
        slug: string;
        locale: Locale;
        publishDate: any;
        featureImage: Array<{
          __typename?: 'Asset';
          locale: Locale;
          id: string;
          fileName: string;
          url: string;
        }>;
        category?: {
          __typename?: 'Category';
          name: string;
          slug: string;
          locale: Locale;
          relatedCategorySection?: {
            __typename?: 'Section';
            sectionName: string;
          } | null;
        } | null;
      };
    }>;
    aggregate: { __typename?: 'Aggregate'; count: number };
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
      pageSize?: number | null;
    };
  };
};

export type GetAuthorsQueryVariables = Exact<{
  where: AuthorWhereInput;
  locale: Array<Locale> | Locale;
  stage: Stage;
}>;

export type GetAuthorsQuery = {
  __typename?: 'Query';
  authors: Array<{
    __typename?: 'Author';
    id: string;
    stage: Stage;
    localizations: Array<{
      __typename?: 'Author';
      name: string;
      slug: string;
      locale: Locale;
      description?: { __typename?: 'RichText'; raw: any; text: string } | null;
      image?: { __typename?: 'Asset'; alt?: string | null; url: string } | null;
    }>;
    sites: Array<{ __typename?: 'Site'; name: string }>;
  }>;
};

export type GetCategoriesWithLatestArticlesQueryVariables = Exact<{
  where: CategoryWhereInput;
  sectionWhere: SectionWhereInput;
  site: Scalars['String'];
  locale: Array<Locale> | Locale;
  localesWithDefault: Array<Locale> | Locale;
  stage: Stage;
}>;

export type GetCategoriesWithLatestArticlesQuery = {
  __typename?: 'Query';
  categories: Array<{
    __typename?: 'Category';
    stage: Stage;
    localizations: Array<{
      __typename?: 'Category';
      locale: Locale;
      name: string;
      slug: string;
      description?: { __typename?: 'RichText'; text: string } | null;
      parent?: {
        __typename?: 'Category';
        id: string;
        name: string;
        slug: string;
      } | null;
      children: Array<{
        __typename?: 'Category';
        id: string;
        name: string;
        slug: string;
        relatedArticlesInCategory: Array<{
          __typename?: 'Article';
          id: string;
          title: string;
          articleType?: ArticleType | null;
          slug: string;
          excerpt?: string | null;
          publishDate: any;
          timeToRead?: number | null;
          featureImage: Array<{
            __typename?: 'Asset';
            id: string;
            fileName: string;
            locale: Locale;
            url: string;
          }>;
        }>;
      }>;
      seo?: {
        __typename?: 'Seo';
        title?: string | null;
        description?: string | null;
        noArchive?: boolean | null;
        noIndex?: boolean | null;
        image?: { __typename?: 'Asset'; url: string } | null;
      } | null;
      relatedCategorySection?: {
        __typename?: 'Section';
        sectionName: string;
        displayName?: string | null;
        theme?: Theme | null;
        footerOptions?:
          | ({ __typename?: 'SectionFooter' } & SectionFooterFragment)
          | null;
        headerOptions?:
          | ({ __typename?: 'SectionHeader' } & SectionHeaderFragment)
          | null;
        parentSection?: {
          __typename?: 'Section';
          sectionName: string;
          displayName?: string | null;
        } | null;
      } | null;
    }>;
    sites: Array<{ __typename?: 'Site'; name: string }>;
  }>;
};

export type ResponsiveColumnValueFragment = {
  __typename?: 'ResponsiveColumnValue';
  mobile: number;
  tablet: number;
  desktop: number;
  wide: number;
};

export type LatestArticlesByCategoryFragment = {
  __typename?: 'LatestArticlesByCategory';
  id: string;
  cardFields: Array<ArticleFeature>;
  locale: Locale;
  stage: Stage;
  headingText?: string | null;
  descriptionText?: string | null;
  category: {
    __typename?: 'Category';
    name: string;
    slug: string;
    sites: Array<{ __typename?: 'Site'; name: string }>;
  };
  maximumArticlesToDisplay?:
    | ({ __typename?: 'ResponsiveColumnValue' } & ResponsiveColumnValueFragment)
    | null;
};

export type ArticleAdditionalContentFragment = {
  __typename?: 'ArticleAdditionalContent';
  box?: ({ __typename?: 'CBox' } & BoxFragment) | null;
  tile?: ({ __typename?: 'CTile' } & TileFragment) | null;
  components: Array<
    {
      __typename?: 'LatestArticlesByCategory';
    } & LatestArticlesByCategoryFragment
  >;
};

export type BannerItemFragment = {
  __typename?: 'BannerItem';
  backgroundColor?: BackgroundColor | null;
  borderRadius?: BorderRadius | null;
  bannerItemDecoration?: CustomDecoration | null;
  disableHorizontalPadding?: boolean | null;
  skipDefaultLocale?: boolean | null;
  heading?: ({ __typename?: 'CHeading' } & HeadingFragment) | null;
  paragraph?: ({ __typename?: 'CParagraph' } & ParagraphFragment) | null;
  c_actionGroup?:
    | ({ __typename?: 'CActionGroup' } & ActionGroupFragment)
    | null;
  sites: Array<{ __typename?: 'Site'; name: string }>;
};

export type GetBannerItemQueryVariables = Exact<{
  where: BannerItemWhereInput;
  locale: Array<Locale> | Locale;
  stage: Stage;
  first: Scalars['Int'];
}>;

export type GetBannerItemQuery = {
  __typename?: 'Query';
  bannerItems: Array<{ __typename?: 'BannerItem' } & BannerItemFragment>;
};

export type HeadingFragment = {
  __typename?: 'CHeading';
  id: string;
  text: string;
  level: number;
  weight?: CTextWeight | null;
  component?: string | null;
  icon?: Icon | null;
  locale: Locale;
  tone?: CTextTone | null;
  customHeadingSize?: CustomFontSize | null;
  paddingTopOverride?:
    | ({ __typename?: 'ResponsiveSpace' } & ResponsiveSpaceFragment)
    | ({ __typename?: 'Space' } & SpaceFragment)
    | null;
};

export type ParagraphFragment = {
  __typename?: 'CParagraph';
  align?: CTextAlign | null;
  tone?: CTextTone | null;
  size?: CTextSize | null;
  marginBottom?: CParagraphMarginBottom | null;
  locale: Locale;
  Paragraph_text?: { __typename?: 'RichText'; raw: any } | null;
};

export type SpaceFragment = { __typename?: 'Space'; space: BraidSizes };

export type ResponsiveSpaceFragment = {
  __typename?: 'ResponsiveSpace';
  mobile: BraidSizes;
  tablet: BraidSizes;
  desktop: BraidSizes;
  wide: BraidSizes;
};

export type DialogItemFragment = {
  __typename: 'DialogItem';
  title: string;
  description?: string | null;
  content: Array<
    | ({ __typename?: 'CHeading' } & HeadingFragment)
    | ({ __typename?: 'CParagraph' } & ParagraphFragment)
    | ({ __typename?: 'Video' } & VideoFragment)
  >;
};

export type LinkFragment = {
  __typename?: 'Link';
  openLinkInNewTab?: boolean | null;
  to?:
    | {
        __typename: 'Article';
        slug: string;
        category?: {
          __typename?: 'Category';
          relatedCategorySection?: {
            __typename?: 'Section';
            sectionName: string;
            displayName?: string | null;
            id: string;
            parentSection?: {
              __typename?: 'Section';
              sectionName: string;
              displayName?: string | null;
            } | null;
          } | null;
          sites: Array<{ __typename?: 'Site'; name: string }>;
        } | null;
      }
    | { __typename: 'Asset'; url: string }
    | {
        __typename: 'Category';
        slug: string;
        relatedCategorySection?: {
          __typename?: 'Section';
          sectionName: string;
          id: string;
          displayName?: string | null;
          parentSection?: {
            __typename?: 'Section';
            sectionName: string;
            displayName?: string | null;
          } | null;
        } | null;
      }
    | { __typename: 'ExternalUrl'; externalUrl?: string | null }
    | {
        __typename: 'Page';
        slug: string;
        relatedPageSection?: {
          __typename?: 'Section';
          sectionName: string;
          displayName?: string | null;
          id: string;
          parentSection?: {
            __typename?: 'Section';
            sectionName: string;
            displayName?: string | null;
          } | null;
        } | null;
        sites: Array<{ __typename?: 'Site'; name: string }>;
      }
    | null;
};

export type ButtonLinkFragment = {
  __typename?: 'CButtonLink';
  id: string;
  locale: Locale;
  text: string;
  size?: CButtonLinkSize | null;
  tone?: CButtonLinkTone | null;
  variant?: CButtonVariant | null;
  icon?: Icon | null;
  iconPosition?: IconPosition | null;
  link?: ({ __typename?: 'Link' } & LinkFragment) | null;
};

export type DialogButtonFragment = {
  __typename?: 'DialogButton';
  id: string;
  locale: Locale;
  text: string;
  size?: CButtonLinkSize | null;
  tone?: CButtonLinkTone | null;
  variant?: CButtonVariant | null;
  icon?: Icon | null;
  iconPosition?: IconPosition | null;
  dialogItem?: ({ __typename?: 'DialogItem' } & DialogItemFragment) | null;
};

export type TextLinkFragment = {
  __typename?: 'CTextLink';
  id: string;
  locale: Locale;
  text: string;
  weight?: CTextWeight | null;
  icon?: Icon | null;
  iconPosition?: IconPosition | null;
  skipDefaultLocale?: boolean | null;
  link?: ({ __typename?: 'Link' } & LinkFragment) | null;
};

export type ImageLinkFragment = {
  __typename?: 'CImageLink';
  id: string;
  locale: Locale;
  image: {
    __typename?: 'Asset';
    id: string;
    alt?: string | null;
    url: string;
    mimeType?: string | null;
    height?: number | null;
    width?: number | null;
  };
  link?: ({ __typename?: 'Link' } & LinkFragment) | null;
};

export type ActionGroupFragment = {
  __typename?: 'CActionGroup';
  boxAlignment?: BoxJustifyContent | null;
  display?: BoxDisplay | null;
  actionGroupSize?: ActionGroupSize | null;
  inline?: { __typename?: 'CInline'; align?: CTextAlign | null } | null;
  actions: Array<
    | ({ __typename: 'CButtonLink' } & ButtonLinkFragment)
    | ({ __typename: 'CImageLink' } & ImageLinkFragment)
    | ({ __typename: 'CTextLink' } & TextLinkFragment)
  >;
};

export type DefaultImageUrlTransformationFragment = {
  __typename?: 'Asset';
  mimeType?: string | null;
  url: string;
};

export type PrimaryBannerImageUrlTransformationFragment = {
  __typename?: 'Asset';
  locale: Locale;
  url: string;
};

export type InlineBannerImageUrlTransformationFragment = {
  __typename?: 'Asset';
  url: string;
  mimeType?: string | null;
};

export type InlineDividerFragment = {
  __typename?: 'InlineDivider';
  image: { __typename?: 'Asset' } & AssetFragment &
    DefaultImageUrlTransformationFragment;
  actions: Array<
    | ({ __typename: 'CButtonLink' } & ButtonLinkFragment)
    | ({ __typename: 'CImageLink' } & ImageLinkFragment)
    | ({ __typename: 'CTextLink' } & TextLinkFragment)
  >;
};

export type BannerInlineFragment = {
  __typename?: 'CBannerInline';
  reverse?: boolean | null;
  reverseContent?: ReverseContent | null;
  imageHiddenBelow?: HiddenBelow | null;
  imageBorderRadius?: BorderRadius | null;
  customComponentRenderer?: CustomComponent | null;
  image?:
    | ({ __typename?: 'Asset' } & AssetFragment &
        InlineBannerImageUrlTransformationFragment)
    | null;
  video?: ({ __typename?: 'Video' } & VideoFragment) | null;
  heading?: ({ __typename?: 'CHeading' } & HeadingFragment) | null;
  paragraph?: ({ __typename?: 'CParagraph' } & ParagraphFragment) | null;
  actionGroup?: ({ __typename?: 'CActionGroup' } & ActionGroupFragment) | null;
  inlineDivider?:
    | ({ __typename?: 'InlineDivider' } & InlineDividerFragment)
    | null;
  badge?: ({ __typename?: 'Badge' } & BadgeFragment) | null;
};

export type BannerFragment = {
  __typename?: 'CBanner';
  banner_items?:
    | ({ __typename?: 'CBannerArticle' } & BannerArticleFragment)
    | ({ __typename?: 'CBannerInline' } & BannerInlineFragment)
    | null;
};

export type BadgeFragment = { __typename?: 'Badge'; text: string; tone: Tone };

export type AssetFragment = {
  __typename?: 'Asset';
  id: string;
  alt?: string | null;
  width?: number | null;
  height?: number | null;
  migratedURL?: string | null;
  locale: Locale;
  mimeType?: string | null;
};

export type CardPrimaryFragment = {
  __typename?: 'CCardPrimary';
  id: string;
  border?: BoxShadows | null;
  borderRadius?: BorderRadius | null;
  cardContentAlignment?: BoxJustifyContent | null;
  textAlignment?: CTextAlign | null;
  heading?: ({ __typename?: 'CHeading' } & HeadingFragment) | null;
  paragraph?: ({ __typename?: 'CParagraph' } & ParagraphFragment) | null;
  actionGroup?: ({ __typename?: 'CActionGroup' } & ActionGroupFragment) | null;
  badge?: ({ __typename?: 'Badge' } & BadgeFragment) | null;
  image?:
    | ({ __typename?: 'Asset' } & AssetFragment &
        DefaultImageUrlTransformationFragment)
    | null;
  numericalBlock?:
    | ({ __typename?: 'NumericalBlock' } & NumericalBlockFragment)
    | null;
};

export type NumericalBlockFragment = {
  __typename?: 'NumericalBlock';
  textSize?: CustomFontSize | null;
  numberTone?: CTextTone | null;
  unit?: string | null;
  unitTone?: CTextTone | null;
  number?: string | null;
  hasAnimation?: boolean | null;
  animationSpeed?: AnimationSpeed | null;
  sameFontSize?: boolean | null;
  fontWeight?: TextWeight | null;
};

export type CardJobAdFragment = {
  __typename?: 'CCardJobAd';
  id: string;
  subHeading?: string | null;
  border?: BoxShadows | null;
  heading?: ({ __typename?: 'CHeading' } & HeadingFragment) | null;
  paragraph?: ({ __typename?: 'CParagraph' } & ParagraphFragment) | null;
  actionGroup?: ({ __typename?: 'CActionGroup' } & ActionGroupFragment) | null;
  badge?: ({ __typename?: 'Badge' } & BadgeFragment) | null;
  image?:
    | ({ __typename?: 'Asset' } & AssetFragment &
        DefaultImageUrlTransformationFragment)
    | null;
};

export type CardGroupFragment = {
  __typename?: 'CCardGroup';
  tile?: ({ __typename?: 'CTile' } & TileFragment) | null;
  cards: Array<
    | ({ __typename?: 'CCardJobAd' } & CardJobAdFragment)
    | ({ __typename?: 'CCardPrimary' } & CardPrimaryFragment)
  >;
};

export type GalleryFragment = {
  __typename?: 'CGallery';
  carousel?: boolean | null;
  imageBorderRadius?: BorderRadius | null;
  tile?: ({ __typename?: 'CTile' } & TileFragment) | null;
  assets: Array<
    { __typename?: 'Asset' } & AssetFragment &
      DefaultImageUrlTransformationFragment
  >;
};

export type IconBlockFragment = {
  __typename?: 'CIconBlock';
  id: string;
  icon: Icon;
  heading?: ({ __typename?: 'CHeading' } & HeadingFragment) | null;
  paragraph?: ({ __typename?: 'CParagraph' } & ParagraphFragment) | null;
};

export type IconBlockGroupFragment = {
  __typename?: 'CIconBlockGroup';
  inlineBlockItem?: boolean | null;
  blockBackground?: CBoxBackground | null;
  iconAlignment?: BoxJustifyContent | null;
  tile?: ({ __typename?: 'CTile' } & TileFragment) | null;
  items: Array<{ __typename?: 'CIconBlock' } & IconBlockFragment>;
};

export type ImageBlockFragment = {
  __typename?: 'CImageBlock';
  id: string;
  reverseContent?: ReverseContent | null;
  heading?: ({ __typename?: 'CHeading' } & HeadingFragment) | null;
  paragraph?: ({ __typename?: 'CParagraph' } & ParagraphFragment) | null;
  image?:
    | ({ __typename?: 'Asset' } & AssetFragment &
        DefaultImageUrlTransformationFragment)
    | null;
  cButtonLink?: ({ __typename?: 'CButtonLink' } & ButtonLinkFragment) | null;
  cTextLink?: ({ __typename?: 'CTextLink' } & TextLinkFragment) | null;
  dialogButton?:
    | ({ __typename?: 'DialogButton' } & DialogButtonFragment)
    | null;
};

export type ImageBlockGroupFragment = {
  __typename?: 'CImageBlockGroup';
  inlineBlockItem?: boolean | null;
  blockBackground?: CBoxBackground | null;
  blockBorder?: BoxShadows | null;
  imageAlignment?: BoxJustifyContent | null;
  stackBelow?: HiddenBelow | null;
  tile?: ({ __typename?: 'CTile' } & TileFragment) | null;
  box?: ({ __typename?: 'CBox' } & BoxFragment) | null;
  items: Array<{ __typename?: 'CImageBlock' } & ImageBlockFragment>;
};

export type StepBlockFragment = {
  __typename?: 'CStepBlock';
  id: string;
  heading?: ({ __typename?: 'CHeading' } & HeadingFragment) | null;
  paragraph?: ({ __typename?: 'CParagraph' } & ParagraphFragment) | null;
};

export type StepBlockGroupFragment = {
  __typename?: 'CStepBlockGroup';
  inlineBlockItem?: boolean | null;
  tile?: ({ __typename?: 'CTile' } & TileFragment) | null;
  items: Array<{ __typename?: 'CStepBlock' } & StepBlockFragment>;
};

export type AccordionFragment = {
  __typename?: 'Accordion';
  id: string;
  locale: Locale;
  label: string;
  content: { __typename?: 'RichText'; raw: any };
};

export type AccordionGroupFragment = {
  __typename?: 'AccordionGroup';
  id: string;
  accordions: Array<{ __typename?: 'Accordion' } & AccordionFragment>;
};

export type DisclosureFragment = {
  __typename?: 'Disclosure';
  id: string;
  locale: Locale;
  label: string;
  content: { __typename?: 'RichText'; raw: any };
};

export type ArticleFragment = {
  __typename?: 'Article';
  title: string;
  slug: string;
  timeToRead?: number | null;
  excerpt?: string | null;
  articleType?: ArticleType | null;
  publishDate: any;
  featureImage: Array<{
    __typename?: 'Asset';
    alt?: string | null;
    url: string;
  }>;
  category?: {
    __typename?: 'Category';
    name: string;
    relatedCategorySection?: {
      __typename?: 'Section';
      sectionName: string;
      displayName?: string | null;
      footerOptions?:
        | ({ __typename?: 'SectionFooter' } & SectionFooterFragment)
        | null;
      headerOptions?:
        | ({ __typename?: 'SectionHeader' } & SectionHeaderFragment)
        | null;
      parentSection?: {
        __typename?: 'Section';
        sectionName: string;
        displayName?: string | null;
      } | null;
    } | null;
  } | null;
};

export type TopicListFragment = {
  __typename?: 'TopicList';
  id: string;
  topicFilterLabel: string;
  topicFilterPlaceholder?: string | null;
  setDefaultTopic?: boolean | null;
  cardFields: Array<ArticleFeature>;
  topics: Array<{
    __typename?: 'Topic';
    id: string;
    name: string;
    description?: { __typename?: 'RichText'; raw: any; text: string } | null;
    relatedArticlesInTopic: Array<{ __typename?: 'Article' } & ArticleFragment>;
  }>;
};

export type SearchBoxFragment = { __typename?: 'SearchBox'; id: string };

export type LoaDataLabFragment = {
  __typename?: 'LoaDataLab';
  id: string;
  url: string;
};

export type WebFormFragment = {
  __typename?: 'WebForm';
  id: string;
  locale: Locale;
  webFormColumns: number;
  formType: FormType;
  document?: ({ __typename?: 'Asset'; url: string } & AssetFragment) | null;
  confirmationMessage?: { __typename?: 'RichText'; raw: any } | null;
};

export type SocialMediaShareFragment = {
  __typename?: 'SocialMediaShare';
  bleedTop?: SmsBleedTop | null;
  alignment?: BoxJustifyContent | null;
};

export type BraidComponentFragment = {
  __typename?: 'BraidComponent';
  braidCode: string;
};

export type BugcrowdFormFragment = { __typename?: 'BugcrowdForm'; id: string };

export type InterviewBuilderFragment = {
  __typename?: 'InterviewBuilder';
  id: string;
};

export type InvestorInformationFragment = {
  __typename?: 'InvestorInformation';
  id: string;
  options?: InvestorOptions | null;
};

export type CustomComponentFragment = {
  __typename?: 'CCustomComponent';
  data?:
    | ({ __typename: 'BraidComponent' } & BraidComponentFragment)
    | ({ __typename: 'BugcrowdForm' } & BugcrowdFormFragment)
    | {
        __typename: 'CForm';
        form?: ({ __typename?: 'Form' } & FormFragment) | null;
      }
    | ({ __typename: 'InterviewBuilder' } & InterviewBuilderFragment)
    | ({ __typename: 'InvestorInformation' } & InvestorInformationFragment)
    | { __typename: 'JobAdFeatureChart'; id: string }
    | ({ __typename: 'LoaDataLab' } & LoaDataLabFragment)
    | { __typename: 'ProfileVisibilityCardGroup' }
    | ({ __typename: 'SearchBox' } & SearchBoxFragment)
    | ({ __typename: 'SocialMediaShare' } & SocialMediaShareFragment)
    | ({ __typename: 'WebForm' } & WebFormFragment)
    | null;
};

export type BrightcoveVideoFragment = {
  __typename?: 'BrightcoveVideo';
  videoUrl: string;
  locale: Locale;
};

export type YouTubeVideoFragment = {
  __typename?: 'YouTubeVideo';
  videoId: string;
  locale: Locale;
};

export type VideoFragment = {
  __typename?: 'Video';
  box?: ({ __typename?: 'CBox' } & BoxFragment) | null;
  source?:
    | ({ __typename?: 'BrightcoveVideo' } & BrightcoveVideoFragment)
    | ({ __typename?: 'YouTubeVideo' } & YouTubeVideoFragment)
    | null;
};

export type TestimonialGroupFragment = {
  __typename?: 'CTestimonialGroup';
  id: string;
  cardBackground?: CBoxBackground | null;
  testimonials: Array<{
    __typename?: 'CTestimonial';
    id: string;
    quote?: string | null;
    author?: string | null;
    company?: string | null;
    locale: Locale;
  }>;
};

export type FormFragment = {
  __typename?: 'Form';
  id: string;
  title?: string | null;
  description?: string | null;
  disclaimerText?: string | null;
  buttonColour?: Colour | null;
  locale: Locale;
  columns: number;
  apiEndpoint: string;
  ctaText?: string | null;
  formType?: CFormType | null;
  consentText?: { __typename?: 'RichText'; raw: any } | null;
  confirmationMessage?: { __typename?: 'RichText'; raw: any } | null;
  fields: Array<
    | {
        __typename: 'FormCheckbox';
        key: string;
        required: boolean;
        checkboxLabel: string;
      }
    | {
        __typename: 'FormCheckboxGroup';
        key: string;
        label: string;
        rowSpan: boolean;
        required: boolean;
        options: Array<{
          __typename?: 'FieldOption';
          name: string;
          checkboxLabel: string;
        }>;
        validators: Array<
          | {
              __typename: 'MaxCheckedItemValidator';
              value: number;
              errorMessage?: string | null;
            }
          | {
              __typename: 'MinCheckedItemValidator';
              value: number;
              errorMessage?: string | null;
            }
        >;
      }
    | {
        __typename: 'FormInputEmail';
        key: string;
        placeholder?: string | null;
        rowSpan: boolean;
        required: boolean;
        inputLabel: string;
        validators: Array<{
          __typename: 'EmailValidator';
          errorMessage?: string | null;
        }>;
      }
    | {
        __typename: 'FormInputNumber';
        key: string;
        placeholder?: string | null;
        rowSpan: boolean;
        required: boolean;
        inputLabel: string;
        validators: Array<
          | {
              __typename: 'MaxNumberValidator';
              value: number;
              errorMessage?: string | null;
            }
          | {
              __typename: 'MinNumberValidator';
              value: number;
              errorMessage?: string | null;
            }
        >;
      }
    | {
        __typename: 'FormInputText';
        key: string;
        placeholder?: string | null;
        rowSpan: boolean;
        required: boolean;
        inputLabel: string;
        validators: Array<
          | {
              __typename: 'MaxLengthValidator';
              value: number;
              errorMessage?: string | null;
            }
          | {
              __typename: 'MinLengthValidator';
              value: number;
              errorMessage?: string | null;
            }
        >;
      }
    | {
        __typename: 'FormSelect';
        key: string;
        placeholder?: string | null;
        rowSpan: boolean;
        required: boolean;
        selectLabel: string;
        options: Array<{
          __typename?: 'FieldOption';
          value: string;
          label: string;
        }>;
      }
    | {
        __typename: 'FormTextarea';
        key: string;
        placeholder?: string | null;
        required: boolean;
        textareaLabel: string;
        validators: Array<
          | {
              __typename: 'MaxLengthValidator';
              value: number;
              errorMessage?: string | null;
            }
          | {
              __typename: 'MinLengthValidator';
              value: number;
              errorMessage?: string | null;
            }
        >;
      }
  >;
};

export type ProfileBioFragment = {
  __typename?: 'ProfileBio';
  name: string;
  roleTitle?: string | null;
  image?:
    | ({ __typename?: 'Asset' } & AssetFragment &
        DefaultImageUrlTransformationFragment)
    | null;
  description?: { __typename?: 'RichText'; raw: any; text: string } | null;
};

export type DocumentItemFragment = {
  __typename?: 'DocumentItem';
  id: string;
  itemIcon?: Icon | null;
  date?: any | null;
  title: string;
  type?: DocumentTypeForReportAndPresentation | null;
  externalUrl?: {
    __typename?: 'ExternalUrl';
    externalUrl?: string | null;
  } | null;
};

export type DocumentListFragment = {
  __typename?: 'DocumentList';
  icon?: Icon | null;
  groupName?: string | null;
  usePagination?: boolean | null;
  maxPageSize?: number | null;
  documentFilterOption?: DocumentListFilter | null;
  documents: Array<{ __typename?: 'DocumentItem' } & DocumentItemFragment>;
};

export type BlocksFragment = {
  __typename?: 'CBlock';
  id: string;
  name: string;
  skipDefaultLocale?: boolean | null;
  box?: ({ __typename?: 'CBox' } & BoxFragment) | null;
  items: Array<
    | ({ __typename: 'AccordionGroup' } & AccordionGroupFragment)
    | {
        __typename: 'BannerItemList';
        bannerItems: Array<{ __typename?: 'BannerItem' } & BannerItemFragment>;
      }
    | ({ __typename: 'CActionGroup' } & ActionGroupFragment)
    | ({ __typename: 'CAlert' } & AlertFragment)
    | ({ __typename: 'CBanner' } & BannerFragment)
    | ({ __typename: 'CCardGroup' } & CardGroupFragment)
    | ({ __typename: 'CCustomComponent' } & CustomComponentFragment)
    | ({ __typename: 'CGallery' } & GalleryFragment)
    | ({ __typename: 'CHeading' } & HeadingFragment)
    | ({ __typename: 'CIconBlockGroup' } & IconBlockGroupFragment)
    | ({ __typename: 'CImageBlockGroup' } & ImageBlockGroupFragment)
    | ({ __typename: 'CParagraph' } & ParagraphFragment)
    | ({ __typename: 'CStepBlockGroup' } & StepBlockGroupFragment)
    | ({ __typename: 'CTestimonialGroup' } & TestimonialGroupFragment)
    | ({ __typename: 'Datalab' } & DatalabFragment)
    | ({ __typename: 'Disclosure' } & DisclosureFragment)
    | ({ __typename: 'DocumentList' } & DocumentListFragment)
    | ({ __typename: 'KeyStepsBlock' } & KeyStepsBlockFragment)
    | ({
        __typename: 'LatestArticlesByCategory';
      } & LatestArticlesByCategoryFragment)
    | ({ __typename: 'ProfileBio' } & ProfileBioFragment)
    | { __typename: 'RichTextEditorWrapper' }
    | ({ __typename: 'TabGroup' } & TabGroupFragment)
    | ({ __typename: 'TopicList' } & TopicListFragment)
    | ({ __typename: 'Video' } & VideoFragment)
    | ({ __typename: 'VideoDialogGroup' } & VideoDialogGroupFragment)
  >;
  tile?: ({ __typename?: 'CTile' } & TileFragment) | null;
};

export type BoxFragment = {
  __typename: 'CBox';
  background?: CBoxBackground | null;
  textAlign?: CTextAlign | null;
  display?: BoxDisplay | null;
  justifyContent?: BoxJustifyContent | null;
  width?: Width | null;
  blockBorder?: BoxShadows | null;
  borderRadius?: BorderRadius | null;
  paddingWidth?: BraidSizes | null;
  paddingHeight?: BraidSizes | null;
  customDecoration?: CustomDecoration | null;
};

export type TileFragment = {
  __typename?: 'CTile';
  columns: number;
  space?: BraidSizes | null;
  dividers?: boolean | null;
  background?: BackgroundColor | null;
  responsiveColumnValue?:
    | ({ __typename?: 'ResponsiveColumnValue' } & ResponsiveColumnValueFragment)
    | null;
};

export type ContainersFragment = {
  __typename?: 'CContainer';
  id: string;
  htmlId?: string | null;
  box?: ({ __typename?: 'CBox' } & BoxFragment) | null;
  tile?: ({ __typename?: 'CTile' } & TileFragment) | null;
  blocks: Array<{ __typename?: 'CBlock' } & BlocksFragment>;
};

export type DividerFragment = {
  __typename?: 'Divider';
  image: { __typename?: 'Asset' } & AssetFragment &
    DefaultImageUrlTransformationFragment;
  actionGroup: { __typename?: 'CActionGroup' } & ActionGroupFragment;
};

export type BannerPrimaryFragment = {
  __typename: 'CBannerPrimary';
  id: string;
  logoDisplay?: boolean | null;
  imageHeight?: BannerImageHeight | null;
  isContentRight?: boolean | null;
  BannerPrimary_image: { __typename?: 'Asset' } & AssetFragment &
    PrimaryBannerImageUrlTransformationFragment;
  heading?: ({ __typename?: 'CHeading' } & HeadingFragment) | null;
  paragraph?: ({ __typename?: 'CParagraph' } & ParagraphFragment) | null;
  actionGroup?: ({ __typename?: 'CActionGroup' } & ActionGroupFragment) | null;
  footnotes?: ({ __typename?: 'CParagraph' } & ParagraphFragment) | null;
  divider?: ({ __typename?: 'Divider' } & DividerFragment) | null;
};

export type BannerAwardFragment = {
  __typename: 'CBannerAward';
  id: string;
  backgroundImage?:
    | ({ __typename?: 'Asset' } & AssetFragment &
        DefaultImageUrlTransformationFragment)
    | null;
  heading?: ({ __typename?: 'CHeading' } & HeadingFragment) | null;
  paragraph?: ({ __typename?: 'CParagraph' } & ParagraphFragment) | null;
  actionGroup?: ({ __typename?: 'CActionGroup' } & ActionGroupFragment) | null;
  video?: ({ __typename?: 'Video' } & VideoFragment) | null;
};

export type PromotionalBannerFragment = {
  __typename?: 'PromotionalBanner';
  id: string;
  brand: Brands;
  background: BackgroundColor;
  showLogo: boolean;
  heading?: ({ __typename?: 'CHeading' } & HeadingFragment) | null;
  paragraph?: ({ __typename?: 'CParagraph' } & ParagraphFragment) | null;
};

export type BannerShopfrontFragment = {
  __typename: 'CBannerShopfront';
  id: string;
  image: { __typename?: 'Asset'; url: string } & AssetFragment;
  heading?: ({ __typename?: 'CHeading' } & HeadingFragment) | null;
  paragraph?: ({ __typename?: 'CParagraph' } & ParagraphFragment) | null;
  BannerShopfront_footnotes: Array<
    { __typename?: 'RichTextEditorWrapper' } & RichTextEditorWrapperFragment
  >;
};

export type BannerArticleFragment = {
  __typename: 'CBannerArticle';
  id: string;
  name?: string | null;
  locale: Locale;
  article?: {
    __typename?: 'Article';
    id: string;
    title: string;
    articleType?: ArticleType | null;
    timeToRead?: number | null;
    slug: string;
    url: string;
    category?: {
      __typename?: 'Category';
      name: string;
      id: string;
      relatedCategorySection?: {
        __typename?: 'Section';
        sectionName: string;
        displayName?: string | null;
        parentSection?: {
          __typename?: 'Section';
          sectionName: string;
          displayName?: string | null;
        } | null;
      } | null;
    } | null;
    featureImage: Array<{ __typename?: 'Asset'; url: string }>;
  } | null;
};

export type EmbedSingleLineTextFragment = {
  __typename?: 'EmbedSingleLineText';
  id: string;
  value?: string | null;
  bold?: boolean | null;
};

export type RichTextEditorWrapperFragment = {
  __typename?: 'RichTextEditorWrapper';
  richTextEditorWrapper?: {
    __typename?: 'RichTextEditor';
    content?: {
      __typename?: 'RichTextEditorContentRichText';
      raw: any;
      references: Array<
        | { __typename: 'EmbedAccordionGroup' }
        | { __typename: 'EmbedCustomContent' }
        | ({ __typename: 'EmbedSingleLineText' } & EmbedSingleLineTextFragment)
      >;
    } | null;
  } | null;
};

export type TabFragment = {
  __typename?: 'Tab';
  id: string;
  locale: Locale;
  label: string;
  content: { __typename?: 'RichText'; raw: any };
};

export type TabGroupFragment = {
  __typename?: 'TabGroup';
  id: string;
  tabs: Array<{ __typename?: 'Tab' } & TabFragment>;
};

export type AlertFragment = {
  __typename?: 'CAlert';
  alertTone: CAlertTone;
  paragraph: { __typename?: 'CParagraph' } & ParagraphFragment;
};

export type SectionFooterFragment = {
  __typename?: 'SectionFooter';
  hideFooter?: boolean | null;
  footerType?: {
    __typename: 'CustomFooter';
    backgroundColor?: CBoxBackground | null;
    links: Array<{ __typename?: 'CTextLink' } & TextLinkFragment>;
    logo?:
      | ({ __typename?: 'Asset' } & AssetFragment &
          DefaultImageUrlTransformationFragment)
      | null;
  } | null;
};

export type SectionHeaderFragment = {
  __typename?: 'SectionHeader';
  hideHeader?: boolean | null;
  headerType?:
    | {
        __typename: 'CustomHeader';
        divider?: boolean | null;
        linksAlignment?: CTextAlign | null;
        logo?:
          | ({
              __typename?: 'CImageLink';
              title?: string | null;
            } & ImageLinkFragment)
          | null;
        links: Array<{
          __typename?: 'TextLinkGroup';
          link?: ({ __typename?: 'CTextLink' } & TextLinkFragment) | null;
          subLinks: Array<{ __typename?: 'CTextLink' } & TextLinkFragment>;
        }>;
      }
    | {
        __typename: 'SeekHeader';
        layout?: HeaderLayout | null;
        subnavLinks: Array<{ __typename?: 'CTextLink' } & TextLinkFragment>;
      }
    | null;
};

export type KeyStepsBlockFragment = {
  __typename?: 'KeyStepsBlock';
  id: string;
  stepTone?: BackgroundColor | null;
  heading?: ({ __typename?: 'CHeading' } & HeadingFragment) | null;
  image?:
    | ({ __typename?: 'Asset' } & AssetFragment &
        DefaultImageUrlTransformationFragment)
    | null;
  steps: Array<{ __typename?: 'CStepBlock' } & StepBlockFragment>;
};

export type DialogVideoFragment = {
  __typename?: 'DialogVideo';
  title?: string | null;
  description?: string | null;
  video?: ({ __typename?: 'BrightcoveVideo' } & BrightcoveVideoFragment) | null;
};

export type VideoDialogGroupFragment = {
  __typename?: 'VideoDialogGroup';
  id: string;
  tile?: ({ __typename?: 'CTile' } & TileFragment) | null;
  videos: Array<{ __typename?: 'DialogVideo' } & DialogVideoFragment>;
};

export type TipsCardFragment = {
  __typename?: 'TipsCard';
  icon?: Icon | null;
  description: { __typename?: 'RichText'; raw: any; text: string };
  downloadButton?: ({ __typename?: 'CButtonLink' } & ButtonLinkFragment) | null;
  inlineLink?: ({ __typename?: 'CTextLink' } & TextLinkFragment) | null;
  badge?: ({ __typename?: 'Badge' } & BadgeFragment) | null;
};

export type DatalabFragment = {
  __typename?: 'Datalab';
  tipsCardGroup?: {
    __typename?: 'TipsCardGroup';
    items: Array<{ __typename?: 'TipsCard' } & TipsCardFragment>;
  } | null;
  aboutResearchLink?: ({ __typename?: 'Link' } & LinkFragment) | null;
};

export type PagesWithEntitiesMinimalFragment = {
  __typename?: 'Page';
  id: string;
  title: string;
  slug: string;
  locale: Locale;
  stage: Stage;
  promotionalBanner?: {
    __typename: 'PromotionalBanner';
    id: string;
    stage: Stage;
  } | null;
  banner?:
    | { __typename: 'CBannerAward'; id: string; stage: Stage }
    | { __typename: 'CBannerPrimary'; id: string; stage: Stage }
    | { __typename: 'CBannerShopfront'; id: string; stage: Stage }
    | null;
  containers: Array<{
    __typename?: 'CContainer';
    id: string;
    htmlId?: string | null;
    box?: ({ __typename?: 'CBox' } & BoxFragment) | null;
    tile?: ({ __typename?: 'CTile' } & TileFragment) | null;
    blocks: Array<{
      __typename?: 'CBlock';
      id: string;
      name: string;
      skipDefaultLocale?: boolean | null;
      box?: ({ __typename?: 'CBox' } & BoxFragment) | null;
      tile?: ({ __typename?: 'CTile' } & TileFragment) | null;
      items: Array<
        | { __typename: 'AccordionGroup'; id: string; stage: Stage }
        | { __typename: 'BannerItemList'; id: string; stage: Stage }
        | { __typename: 'CActionGroup'; id: string; stage: Stage }
        | { __typename: 'CAlert'; id: string; stage: Stage }
        | { __typename: 'CBanner'; id: string; stage: Stage }
        | { __typename: 'CCardGroup'; id: string; stage: Stage }
        | { __typename: 'CCustomComponent'; id: string; stage: Stage }
        | { __typename: 'CGallery'; id: string; stage: Stage }
        | { __typename: 'CHeading'; id: string; stage: Stage }
        | { __typename: 'CIconBlockGroup'; id: string; stage: Stage }
        | { __typename: 'CImageBlockGroup'; id: string; stage: Stage }
        | { __typename: 'CParagraph'; id: string; stage: Stage }
        | { __typename: 'CStepBlockGroup'; id: string; stage: Stage }
        | { __typename: 'CTestimonialGroup'; id: string; stage: Stage }
        | ({
            __typename: 'Datalab';
            id: string;
            stage: Stage;
          } & DatalabFragment &
            DatalabFragment &
            DatalabFragment &
            DatalabFragment &
            DatalabFragment &
            DatalabFragment &
            DatalabFragment &
            DatalabFragment &
            DatalabFragment &
            DatalabFragment &
            DatalabFragment &
            DatalabFragment &
            DatalabFragment &
            DatalabFragment &
            DatalabFragment &
            DatalabFragment &
            DatalabFragment &
            DatalabFragment &
            DatalabFragment &
            DatalabFragment &
            DatalabFragment &
            DatalabFragment &
            DatalabFragment &
            DatalabFragment &
            DatalabFragment)
        | { __typename: 'Disclosure'; id: string; stage: Stage }
        | { __typename: 'DocumentList'; id: string; stage: Stage }
        | { __typename: 'KeyStepsBlock'; id: string; stage: Stage }
        | { __typename: 'LatestArticlesByCategory'; id: string; stage: Stage }
        | { __typename: 'ProfileBio'; id: string; stage: Stage }
        | { __typename: 'RichTextEditorWrapper'; id: string; stage: Stage }
        | { __typename: 'TabGroup'; id: string; stage: Stage }
        | { __typename: 'TopicList'; id: string; stage: Stage }
        | { __typename: 'Video'; id: string; stage: Stage }
        | { __typename: 'VideoDialogGroup'; id: string; stage: Stage }
      >;
    }>;
  }>;
  relatedPageSection?: {
    __typename?: 'Section';
    sectionName: string;
    displayName?: string | null;
    theme?: Theme | null;
    parentSection?: {
      __typename?: 'Section';
      sectionName: string;
      displayName?: string | null;
    } | null;
    footerOptions?:
      | ({ __typename?: 'SectionFooter' } & SectionFooterFragment)
      | null;
    headerOptions?:
      | ({ __typename?: 'SectionHeader' } & SectionHeaderFragment)
      | null;
  } | null;
  seo?: {
    __typename?: 'Seo';
    title?: string | null;
    description?: string | null;
    noArchive?: boolean | null;
    noIndex?: boolean | null;
    image?: { __typename?: 'Asset'; url: string } | null;
  } | null;
  sites: Array<{ __typename?: 'Site'; name: string }>;
  localizations: Array<{ __typename?: 'Page'; locale: Locale; title: string }>;
};

export type GetPagesQueryVariables = Exact<{
  where: PageWhereInput;
  locale: Array<Locale> | Locale;
  localesWithDefault: Array<Locale> | Locale;
}>;

export type GetPagesQuery = {
  __typename?: 'Query';
  pages: Array<{
    __typename?: 'Page';
    id: string;
    title: string;
    slug: string;
    locale: Locale;
    enableBreadcrumbs?: boolean | null;
    promotionalBanner?:
      | ({ __typename?: 'PromotionalBanner' } & PromotionalBannerFragment)
      | null;
    banner?:
      | ({ __typename: 'CBannerAward' } & BannerAwardFragment)
      | ({ __typename: 'CBannerPrimary' } & BannerPrimaryFragment)
      | ({ __typename: 'CBannerShopfront' } & BannerShopfrontFragment)
      | null;
    containers: Array<{ __typename?: 'CContainer' } & ContainersFragment>;
    relatedPageSection?: {
      __typename?: 'Section';
      sectionName: string;
      displayName?: string | null;
      theme?: Theme | null;
      parentSection?: {
        __typename?: 'Section';
        sectionName: string;
        displayName?: string | null;
      } | null;
      footerOptions?:
        | ({ __typename?: 'SectionFooter' } & SectionFooterFragment)
        | null;
      headerOptions?:
        | ({ __typename?: 'SectionHeader' } & SectionHeaderFragment)
        | null;
    } | null;
    seo?: {
      __typename?: 'Seo';
      title?: string | null;
      description?: string | null;
      noArchive?: boolean | null;
      noIndex?: boolean | null;
      image?: { __typename?: 'Asset'; url: string } | null;
    } | null;
    sites: Array<{ __typename?: 'Site'; name: string }>;
    hideEmbeddedFormInSection?: {
      __typename?: 'Section';
      id: string;
      sectionName: string;
    } | null;
    localizations: Array<{
      __typename?: 'Page';
      locale: Locale;
      title: string;
    }>;
  }>;
};

export type GetPagesLiteQueryVariables = Exact<{
  where: PageWhereInput;
  locale: Array<Locale> | Locale;
  stage: Stage;
}>;

export type GetPagesLiteQuery = {
  __typename?: 'Query';
  pages: Array<{
    __typename?: 'Page';
    id: string;
    title: string;
    slug: string;
    locale: Locale;
    stage: Stage;
    enableBreadcrumbs?: boolean | null;
    promotionalBanner?: { __typename?: 'PromotionalBanner'; id: string } | null;
    banner?:
      | { __typename: 'CBannerAward'; id: string }
      | { __typename: 'CBannerPrimary'; id: string }
      | { __typename: 'CBannerShopfront'; id: string }
      | null;
    containers: Array<{ __typename?: 'CContainer'; id: string }>;
    relatedPageSection?: {
      __typename?: 'Section';
      sectionName: string;
      displayName?: string | null;
      theme?: Theme | null;
      footerOptions?:
        | ({ __typename?: 'SectionFooter' } & SectionFooterFragment)
        | null;
      headerOptions?:
        | ({ __typename?: 'SectionHeader' } & SectionHeaderFragment)
        | null;
    } | null;
    seo?: {
      __typename?: 'Seo';
      title?: string | null;
      description?: string | null;
      noArchive?: boolean | null;
      noIndex?: boolean | null;
      image?: { __typename?: 'Asset'; url: string } | null;
    } | null;
    sites: Array<{ __typename?: 'Site'; name: string }>;
    localizations: Array<{
      __typename?: 'Page';
      locale: Locale;
      title: string;
    }>;
  }>;
};

export type GetPagesWithEntitiesMinimalQueryVariables = Exact<{
  where: PageWhereInput;
  locale: Array<Locale> | Locale;
  localesWithDefault: Array<Locale> | Locale;
  stage: Stage;
}>;

export type GetPagesWithEntitiesMinimalQuery = {
  __typename?: 'Query';
  pages: Array<{ __typename?: 'Page' } & PagesWithEntitiesMinimalFragment>;
};

export type GetPagesWithEntitiesQueryVariables = Exact<{
  where: PageWhereInput;
  locale: Array<Locale> | Locale;
  localesWithDefault: Array<Locale> | Locale;
  stage: Stage;
}>;

export type GetPagesWithEntitiesQuery = {
  __typename?: 'Query';
  pages: Array<
    {
      __typename?: 'Page';
      enableBreadcrumbs?: boolean | null;
      hideEmbeddedFormInSection?: {
        __typename?: 'Section';
        id: string;
        sectionName: string;
      } | null;
    } & PagesWithEntitiesMinimalFragment
  >;
};

export type GetEntitiesQueryVariables = Exact<{
  entityWhere: Array<EntityWhereInput> | EntityWhereInput;
  locale: Array<Locale> | Locale;
}>;

export type GetEntitiesQuery = {
  __typename?: 'Query';
  entities?: Array<
    | { __typename?: 'Accordion'; id: string }
    | ({ __typename?: 'AccordionGroup'; id: string } & AccordionGroupFragment)
    | { __typename?: 'Article'; id: string }
    | { __typename?: 'ArticleAdditionalContent'; id: string }
    | { __typename?: 'Asset'; id: string }
    | { __typename?: 'Author'; id: string }
    | { __typename?: 'Badge'; id: string }
    | { __typename?: 'BannerItem'; id: string }
    | {
        __typename?: 'BannerItemList';
        id: string;
        bannerItems: Array<{ __typename?: 'BannerItem' } & BannerItemFragment>;
      }
    | { __typename?: 'BraidComponent'; id: string }
    | { __typename?: 'BrightcoveVideo'; id: string }
    | { __typename?: 'BugcrowdForm'; id: string }
    | ({ __typename?: 'CActionGroup'; id: string } & ActionGroupFragment)
    | ({ __typename?: 'CAlert'; id: string } & AlertFragment)
    | ({ __typename?: 'CBanner'; id: string } & BannerFragment)
    | { __typename?: 'CBannerArticle'; id: string }
    | {
        __typename: 'CBannerAward';
        id: string;
        backgroundImage?:
          | ({ __typename?: 'Asset' } & AssetFragment &
              DefaultImageUrlTransformationFragment)
          | null;
        heading?: ({ __typename?: 'CHeading' } & HeadingFragment) | null;
        description?:
          | ({ __typename?: 'CParagraph' } & ParagraphFragment)
          | null;
        actionGroup?:
          | ({ __typename?: 'CActionGroup' } & ActionGroupFragment)
          | null;
        video?: ({ __typename?: 'Video' } & VideoFragment) | null;
      }
    | { __typename?: 'CBannerInline'; id: string }
    | {
        __typename: 'CBannerPrimary';
        id: string;
        logoDisplay?: boolean | null;
        imageHeight?: BannerImageHeight | null;
        isContentRight?: boolean | null;
        BannerPrimary_image: { __typename?: 'Asset' } & AssetFragment &
          PrimaryBannerImageUrlTransformationFragment;
        heading?: ({ __typename?: 'CHeading' } & HeadingFragment) | null;
        description?:
          | ({ __typename?: 'CParagraph' } & ParagraphFragment)
          | null;
        actionGroup?:
          | ({ __typename?: 'CActionGroup' } & ActionGroupFragment)
          | null;
        footnotes?: ({ __typename?: 'CParagraph' } & ParagraphFragment) | null;
        divider?: ({ __typename?: 'Divider' } & DividerFragment) | null;
      }
    | {
        __typename: 'CBannerShopfront';
        id: string;
        BannerShopfront_image: {
          __typename?: 'Asset';
          url: string;
        } & AssetFragment;
        heading?: ({ __typename?: 'CHeading' } & HeadingFragment) | null;
        description?:
          | ({ __typename?: 'CParagraph' } & ParagraphFragment)
          | null;
        BannerShopfront_footnotes: Array<
          {
            __typename?: 'RichTextEditorWrapper';
          } & RichTextEditorWrapperFragment
        >;
      }
    | { __typename?: 'CBlock'; id: string }
    | { __typename?: 'CBox'; id: string }
    | { __typename?: 'CButtonLink'; id: string }
    | ({ __typename?: 'CCardGroup'; id: string } & CardGroupFragment)
    | { __typename?: 'CCardJobAd'; id: string }
    | { __typename?: 'CCardPrimary'; id: string }
    | { __typename?: 'CContainer'; id: string }
    | ({
        __typename?: 'CCustomComponent';
        id: string;
      } & CustomComponentFragment)
    | { __typename?: 'CForm'; id: string }
    | ({ __typename?: 'CGallery'; id: string } & GalleryFragment)
    | ({ __typename?: 'CHeading'; id: string } & HeadingFragment)
    | { __typename?: 'CIconBlock'; id: string }
    | ({ __typename?: 'CIconBlockGroup'; id: string } & IconBlockGroupFragment)
    | { __typename?: 'CImageBlock'; id: string }
    | ({
        __typename?: 'CImageBlockGroup';
        id: string;
      } & ImageBlockGroupFragment)
    | { __typename?: 'CImageLink'; id: string }
    | { __typename?: 'CInline'; id: string }
    | ({ __typename?: 'CParagraph'; id: string } & ParagraphFragment)
    | { __typename?: 'CStepBlock'; id: string }
    | ({ __typename?: 'CStepBlockGroup'; id: string } & StepBlockGroupFragment)
    | { __typename?: 'CTestimonial'; id: string }
    | ({
        __typename?: 'CTestimonialGroup';
        id: string;
      } & TestimonialGroupFragment)
    | { __typename?: 'CTextLink'; id: string }
    | { __typename?: 'CTile'; id: string }
    | { __typename?: 'Category'; id: string }
    | { __typename?: 'CustomFooter'; id: string }
    | { __typename?: 'CustomHeader'; id: string }
    | { __typename?: 'Datalab'; id: string }
    | { __typename?: 'DialogButton'; id: string }
    | { __typename?: 'DialogItem'; id: string }
    | { __typename?: 'DialogVideo'; id: string }
    | ({ __typename?: 'Disclosure'; id: string } & DisclosureFragment)
    | { __typename?: 'Divider'; id: string }
    | { __typename?: 'DocumentItem'; id: string }
    | ({ __typename?: 'DocumentList'; id: string } & DocumentListFragment)
    | { __typename?: 'EmailValidator'; id: string }
    | { __typename?: 'EmbedAccordionGroup'; id: string }
    | { __typename?: 'EmbedCustomContent'; id: string }
    | { __typename?: 'EmbedSingleLineText'; id: string }
    | { __typename?: 'ExternalUrl'; id: string }
    | { __typename?: 'FieldOption'; id: string }
    | { __typename?: 'Form'; id: string }
    | { __typename?: 'FormCheckbox'; id: string }
    | { __typename?: 'FormCheckboxGroup'; id: string }
    | { __typename?: 'FormInputEmail'; id: string }
    | { __typename?: 'FormInputNumber'; id: string }
    | { __typename?: 'FormInputText'; id: string }
    | { __typename?: 'FormSelect'; id: string }
    | { __typename?: 'FormTextarea'; id: string }
    | { __typename?: 'Image'; id: string }
    | { __typename?: 'InlineDivider'; id: string }
    | { __typename?: 'InterviewBuilder'; id: string }
    | { __typename?: 'InvestorInformation'; id: string }
    | { __typename?: 'JobAdFeatureChart'; id: string }
    | ({ __typename?: 'KeyStepsBlock'; id: string } & KeyStepsBlockFragment)
    | ({
        __typename?: 'LatestArticlesByCategory';
        id: string;
      } & LatestArticlesByCategoryFragment)
    | { __typename?: 'Link'; id: string }
    | { __typename?: 'LoaDataLab'; id: string }
    | { __typename?: 'ManagedContentBanner'; id: string }
    | { __typename?: 'MaxCheckedItemValidator'; id: string }
    | { __typename?: 'MaxLengthValidator'; id: string }
    | { __typename?: 'MaxNumberValidator'; id: string }
    | { __typename?: 'MigrationHistory'; id: string }
    | { __typename?: 'MigrationState'; id: string }
    | { __typename?: 'MinCheckedItemValidator'; id: string }
    | { __typename?: 'MinLengthValidator'; id: string }
    | { __typename?: 'MinNumberValidator'; id: string }
    | { __typename?: 'NumericalBlock'; id: string }
    | { __typename?: 'Page'; id: string }
    | { __typename?: 'Podcast'; id: string }
    | ({ __typename?: 'ProfileBio'; id: string } & ProfileBioFragment)
    | { __typename?: 'ProfileVisibilityCardGroup'; id: string }
    | {
        __typename?: 'PromotionalBanner';
        id: string;
        brand: Brands;
        background: BackgroundColor;
        showLogo: boolean;
        heading?: ({ __typename?: 'CHeading' } & HeadingFragment) | null;
        description?:
          | ({ __typename?: 'CParagraph' } & ParagraphFragment)
          | null;
      }
    | { __typename?: 'ResponsiveColumnValue'; id: string }
    | { __typename?: 'ResponsiveSpace'; id: string }
    | { __typename?: 'RichTextEditor'; id: string }
    | { __typename?: 'RichTextEditorWrapper'; id: string }
    | { __typename?: 'ScheduledOperation'; id: string }
    | { __typename?: 'ScheduledRelease'; id: string }
    | { __typename?: 'SearchBox'; id: string }
    | { __typename?: 'Section'; id: string }
    | { __typename?: 'SectionFooter'; id: string }
    | { __typename?: 'SectionHeader'; id: string }
    | { __typename?: 'SeekHeader'; id: string }
    | { __typename?: 'Seo'; id: string }
    | { __typename?: 'Site'; id: string }
    | { __typename?: 'SiteUsersConnection'; id: string }
    | { __typename?: 'SocialMediaShare'; id: string }
    | { __typename?: 'Space'; id: string }
    | { __typename?: 'Tab'; id: string }
    | ({ __typename?: 'TabGroup'; id: string } & TabGroupFragment)
    | { __typename?: 'Testimonial'; id: string }
    | { __typename?: 'TestimonialGroup'; id: string }
    | { __typename?: 'TextLinkGroup'; id: string }
    | { __typename?: 'TipsCard'; id: string }
    | { __typename?: 'TipsCardGroup'; id: string }
    | { __typename?: 'Topic'; id: string }
    | ({ __typename?: 'TopicList'; id: string } & TopicListFragment)
    | { __typename?: 'User'; id: string }
    | ({ __typename?: 'Video'; id: string } & VideoFragment)
    | { __typename?: 'VideoBlock'; id: string }
    | ({
        __typename?: 'VideoDialogGroup';
        id: string;
      } & VideoDialogGroupFragment)
    | { __typename?: 'WebForm'; id: string }
    | { __typename?: 'YouTubeVideo'; id: string }
  > | null;
};

export type GetPagesContainersQueryVariables = Exact<{
  where: PageWhereInput;
  locale: Array<Locale> | Locale;
  stage: Stage;
}>;

export type GetPagesContainersQuery = {
  __typename?: 'Query';
  pages: Array<{
    __typename?: 'Page';
    id: string;
    containers: Array<{ __typename?: 'CContainer' } & ContainersFragment>;
  }>;
};

export type GetPagesPromotionalBannerQueryVariables = Exact<{
  where: PageWhereInput;
  locale: Array<Locale> | Locale;
  stage: Stage;
}>;

export type GetPagesPromotionalBannerQuery = {
  __typename?: 'Query';
  pages: Array<{
    __typename?: 'Page';
    id: string;
    promotionalBanner?:
      | ({ __typename?: 'PromotionalBanner' } & PromotionalBannerFragment)
      | null;
  }>;
};

export type GetPagesBannerQueryVariables = Exact<{
  where: PageWhereInput;
  locale: Array<Locale> | Locale;
  stage: Stage;
  localesWithDefault: Array<Locale> | Locale;
}>;

export type GetPagesBannerQuery = {
  __typename?: 'Query';
  pages: Array<{
    __typename?: 'Page';
    id: string;
    banner?:
      | ({ __typename: 'CBannerAward' } & BannerAwardFragment)
      | ({ __typename: 'CBannerPrimary' } & BannerPrimaryFragment)
      | ({ __typename: 'CBannerShopfront' } & BannerShopfrontFragment)
      | null;
  }>;
};

export type GetParentSectionQueryVariables = Exact<{
  where?: InputMaybe<SectionWhereInput>;
}>;

export type GetParentSectionQuery = {
  __typename?: 'Query';
  sections: Array<{
    __typename?: 'Section';
    id: string;
    parentSection?: {
      __typename?: 'Section';
      sectionName: string;
      displayName?: string | null;
    } | null;
  }>;
};

export const RichTextAccordionFragmentDoc = gql`
  fragment RichTextAccordion on Accordion {
    id
    locale
    content {
      raw
    }
    label
  }
`;
export const EmbedAccordionGroupFragmentDoc = gql`
  fragment EmbedAccordionGroup on EmbedAccordionGroup {
    id
    accordion(locales: $locale) {
      ...RichTextAccordion
    }
  }
`;
export const EmbedCustomContentFragmentDoc = gql`
  fragment EmbedCustomContent on EmbedCustomContent {
    id
    title
  }
`;
export const BoxFragmentDoc = gql`
  fragment Box on CBox {
    __typename
    background
    textAlign
    display
    justifyContent
    width
    blockBorder
    borderRadius
    paddingWidth
    paddingHeight
    customDecoration
  }
`;
export const ResponsiveColumnValueFragmentDoc = gql`
  fragment ResponsiveColumnValue on ResponsiveColumnValue {
    mobile
    tablet
    desktop
    wide
  }
`;
export const TileFragmentDoc = gql`
  fragment Tile on CTile {
    columns
    responsiveColumnValue {
      ...ResponsiveColumnValue
    }
    space
    dividers
    background
  }
`;
export const LatestArticlesByCategoryFragmentDoc = gql`
  fragment LatestArticlesByCategory on LatestArticlesByCategory {
    id
    headingText: heading
    descriptionText: description
    category {
      name
      slug
      sites {
        name
      }
    }
    cardFields
    maximumArticlesToDisplay {
      ...ResponsiveColumnValue
    }
    locale
    stage
  }
`;
export const ArticleAdditionalContentFragmentDoc = gql`
  fragment ArticleAdditionalContent on ArticleAdditionalContent {
    box {
      ...Box
    }
    tile {
      ...Tile
    }
    components {
      ... on LatestArticlesByCategory {
        ...LatestArticlesByCategory
      }
    }
  }
`;
export const SpaceFragmentDoc = gql`
  fragment Space on Space {
    space
  }
`;
export const ResponsiveSpaceFragmentDoc = gql`
  fragment ResponsiveSpace on ResponsiveSpace {
    mobile
    tablet
    desktop
    wide
  }
`;
export const HeadingFragmentDoc = gql`
  fragment Heading on CHeading {
    id
    text
    level
    weight
    component
    icon
    locale
    tone
    customHeadingSize
    paddingTopOverride {
      ... on Space {
        ...Space
      }
      ... on ResponsiveSpace {
        ...ResponsiveSpace
      }
    }
  }
`;
export const ParagraphFragmentDoc = gql`
  fragment Paragraph on CParagraph {
    align
    tone
    size
    Paragraph_text: text {
      raw
    }
    marginBottom
    locale
  }
`;
export const LinkFragmentDoc = gql`
  fragment Link on Link {
    to {
      __typename
      ... on ExternalUrl {
        externalUrl: url
      }
      ... on Asset {
        url
      }
      ... on Article {
        slug
        category {
          relatedCategorySection {
            sectionName
            displayName
            id
            parentSection {
              sectionName
              displayName
            }
          }
          sites {
            name
          }
        }
      }
      ... on Page {
        slug
        relatedPageSection {
          sectionName
          displayName
          id
          parentSection {
            sectionName
            displayName
          }
        }
        sites {
          name
        }
      }
      ... on Category {
        slug
        relatedCategorySection {
          sectionName
          id
          displayName
          parentSection {
            sectionName
            displayName
          }
        }
      }
    }
    openLinkInNewTab
  }
`;
export const ButtonLinkFragmentDoc = gql`
  fragment ButtonLink on CButtonLink {
    id
    locale
    text
    size
    tone
    variant
    icon
    iconPosition
    link {
      ...Link
    }
  }
`;
export const TextLinkFragmentDoc = gql`
  fragment TextLink on CTextLink {
    id
    locale
    text
    weight
    icon
    iconPosition
    link {
      ...Link
    }
    skipDefaultLocale
  }
`;
export const ImageLinkFragmentDoc = gql`
  fragment ImageLink on CImageLink {
    id
    locale
    image {
      id
      alt
      url
      mimeType
      height
      width
    }
    link {
      ...Link
    }
  }
`;
export const ActionGroupFragmentDoc = gql`
  fragment ActionGroup on CActionGroup {
    inline {
      align
    }
    actions {
      __typename
      ... on CButtonLink {
        ...ButtonLink
      }
      ... on CTextLink {
        ...TextLink
      }
      ... on CImageLink {
        ...ImageLink
      }
    }
    boxAlignment
    display
    actionGroupSize
  }
`;
export const AssetFragmentDoc = gql`
  fragment Asset on Asset {
    id
    alt
    width
    height
    migratedURL
    locale
    mimeType
  }
`;
export const InlineBannerImageUrlTransformationFragmentDoc = gql`
  fragment InlineBannerImageUrlTransformation on Asset {
    url
    mimeType
  }
`;
export const BrightcoveVideoFragmentDoc = gql`
  fragment BrightcoveVideo on BrightcoveVideo {
    videoUrl
    locale
  }
`;
export const YouTubeVideoFragmentDoc = gql`
  fragment YouTubeVideo on YouTubeVideo {
    videoId
    locale
  }
`;
export const VideoFragmentDoc = gql`
  fragment Video on Video {
    box {
      ...Box
    }
    source {
      ... on BrightcoveVideo {
        ...BrightcoveVideo
      }
      ... on YouTubeVideo {
        ...YouTubeVideo
      }
    }
  }
`;
export const DefaultImageUrlTransformationFragmentDoc = gql`
  fragment DefaultImageUrlTransformation on Asset {
    mimeType
    url
  }
`;
export const InlineDividerFragmentDoc = gql`
  fragment InlineDivider on InlineDivider {
    image {
      ...Asset
      ...DefaultImageUrlTransformation
    }
    actions {
      __typename
      ... on CButtonLink {
        ...ButtonLink
      }
      ... on CTextLink {
        ...TextLink
      }
      ... on CImageLink {
        ...ImageLink
      }
    }
  }
`;
export const BadgeFragmentDoc = gql`
  fragment Badge on Badge {
    text
    tone
  }
`;
export const BannerInlineFragmentDoc = gql`
  fragment BannerInline on CBannerInline {
    reverse
    reverseContent
    image {
      ...Asset
      ...InlineBannerImageUrlTransformation
    }
    video {
      ...Video
    }
    heading {
      ...Heading
    }
    paragraph {
      ...Paragraph
    }
    actionGroup {
      ...ActionGroup
    }
    inlineDivider {
      ...InlineDivider
    }
    badge {
      ...Badge
    }
    imageHiddenBelow
    imageBorderRadius
    customComponentRenderer
  }
`;
export const BannerArticleFragmentDoc = gql`
  fragment BannerArticle on CBannerArticle {
    __typename
    id
    name
    locale
    article {
      id
      title
      articleType
      category {
        name
        id
        relatedCategorySection {
          sectionName
          displayName
          parentSection {
            sectionName
            displayName
          }
        }
      }
      timeToRead
      slug
      featureImage {
        url
      }
      url: slug
    }
  }
`;
export const BannerFragmentDoc = gql`
  fragment Banner on CBanner {
    banner_items {
      ... on CBannerInline {
        ...BannerInline
      }
      ... on CBannerArticle {
        ...BannerArticle
      }
    }
  }
`;
export const NumericalBlockFragmentDoc = gql`
  fragment NumericalBlock on NumericalBlock {
    textSize
    numberTone
    unit
    unitTone
    number
    hasAnimation
    animationSpeed
    sameFontSize
    fontWeight
  }
`;
export const CardPrimaryFragmentDoc = gql`
  fragment CardPrimary on CCardPrimary {
    id
    heading {
      ...Heading
    }
    paragraph {
      ...Paragraph
    }
    actionGroup {
      ...ActionGroup
    }
    badge {
      ...Badge
    }
    image {
      ...Asset
      ...DefaultImageUrlTransformation
    }
    border
    borderRadius
    cardContentAlignment
    textAlignment
    numericalBlock {
      ...NumericalBlock
    }
  }
`;
export const CardJobAdFragmentDoc = gql`
  fragment CardJobAd on CCardJobAd {
    id
    heading {
      ...Heading
    }
    subHeading
    paragraph {
      ...Paragraph
    }
    actionGroup {
      ...ActionGroup
    }
    badge {
      ...Badge
    }
    image {
      ...Asset
      ...DefaultImageUrlTransformation
    }
    border
  }
`;
export const CardGroupFragmentDoc = gql`
  fragment CardGroup on CCardGroup {
    tile {
      ...Tile
    }
    cards {
      ... on CCardPrimary {
        ...CardPrimary
      }
      ... on CCardJobAd {
        ...CardJobAd
      }
    }
  }
`;
export const GalleryFragmentDoc = gql`
  fragment Gallery on CGallery {
    tile {
      ...Tile
    }
    assets {
      ...Asset
      ...DefaultImageUrlTransformation
    }
    carousel
    imageBorderRadius
  }
`;
export const IconBlockFragmentDoc = gql`
  fragment IconBlock on CIconBlock {
    id
    heading {
      ...Heading
    }
    paragraph {
      ...Paragraph
    }
    icon
  }
`;
export const IconBlockGroupFragmentDoc = gql`
  fragment IconBlockGroup on CIconBlockGroup {
    tile {
      ...Tile
    }
    items {
      ...IconBlock
    }
    inlineBlockItem
    blockBackground
    iconAlignment
  }
`;
export const DialogItemFragmentDoc = gql`
  fragment DialogItem on DialogItem {
    __typename
    title
    description
    content {
      ... on Video {
        ...Video
      }
      ... on CHeading {
        ...Heading
      }
      ... on CParagraph {
        ...Paragraph
      }
    }
  }
`;
export const DialogButtonFragmentDoc = gql`
  fragment DialogButton on DialogButton {
    id
    locale
    text
    size
    tone
    variant
    icon
    iconPosition
    dialogItem {
      ...DialogItem
    }
  }
`;
export const ImageBlockFragmentDoc = gql`
  fragment ImageBlock on CImageBlock {
    id
    heading {
      ...Heading
    }
    paragraph {
      ...Paragraph
    }
    image {
      ...Asset
      ...DefaultImageUrlTransformation
    }
    cButtonLink {
      ...ButtonLink
    }
    cTextLink {
      ...TextLink
    }
    dialogButton {
      ...DialogButton
    }
    reverseContent
  }
`;
export const ImageBlockGroupFragmentDoc = gql`
  fragment ImageBlockGroup on CImageBlockGroup {
    tile {
      ...Tile
    }
    box {
      ...Box
    }
    items {
      ...ImageBlock
    }
    inlineBlockItem
    blockBackground
    blockBorder
    imageAlignment
    stackBelow
  }
`;
export const StepBlockFragmentDoc = gql`
  fragment StepBlock on CStepBlock {
    id
    heading {
      ...Heading
    }
    paragraph {
      ...Paragraph
    }
  }
`;
export const StepBlockGroupFragmentDoc = gql`
  fragment StepBlockGroup on CStepBlockGroup {
    tile {
      ...Tile
    }
    items {
      ...StepBlock
    }
    inlineBlockItem
  }
`;
export const AccordionFragmentDoc = gql`
  fragment Accordion on Accordion {
    id
    locale
    label
    content {
      raw
    }
  }
`;
export const AccordionGroupFragmentDoc = gql`
  fragment AccordionGroup on AccordionGroup {
    id
    accordions {
      ...Accordion
    }
  }
`;
export const DisclosureFragmentDoc = gql`
  fragment Disclosure on Disclosure {
    id
    locale
    label
    content {
      raw
    }
  }
`;
export const SectionFooterFragmentDoc = gql`
  fragment SectionFooter on SectionFooter {
    footerType {
      ... on CustomFooter {
        __typename
        backgroundColor
        links {
          ... on CTextLink {
            ...TextLink
          }
        }
        logo {
          ...Asset
          ...DefaultImageUrlTransformation
        }
      }
    }
    hideFooter
  }
`;
export const SectionHeaderFragmentDoc = gql`
  fragment SectionHeader on SectionHeader {
    headerType {
      ... on SeekHeader {
        __typename
        layout
        subnavLinks {
          ... on CTextLink {
            ...TextLink
          }
        }
      }
      ... on CustomHeader {
        __typename
        divider
        logo {
          title
          ... on CImageLink {
            ...ImageLink
          }
        }
        links {
          ... on TextLinkGroup {
            link {
              ... on CTextLink {
                ...TextLink
              }
            }
            subLinks {
              ... on CTextLink {
                ...TextLink
              }
            }
          }
        }
        linksAlignment
      }
    }
    hideHeader
  }
`;
export const ArticleFragmentDoc = gql`
  fragment Article on Article {
    title
    slug
    timeToRead
    excerpt
    articleType
    publishDate
    featureImage {
      alt
      url
    }
    category {
      name
      relatedCategorySection {
        sectionName
        displayName
        footerOptions {
          ... on SectionFooter {
            ...SectionFooter
          }
        }
        headerOptions {
          ... on SectionHeader {
            ...SectionHeader
          }
        }
        parentSection {
          sectionName
          displayName
        }
      }
    }
  }
`;
export const TopicListFragmentDoc = gql`
  fragment TopicList on TopicList {
    id
    topicFilterLabel
    topicFilterPlaceholder
    setDefaultTopic
    cardFields
    topics {
      id
      name
      description {
        raw
        text
      }
      relatedArticlesInTopic {
        ...Article
      }
    }
  }
`;
export const SearchBoxFragmentDoc = gql`
  fragment SearchBox on SearchBox {
    id
  }
`;
export const LoaDataLabFragmentDoc = gql`
  fragment LoaDataLab on LoaDataLab {
    id
    url
  }
`;
export const WebFormFragmentDoc = gql`
  fragment WebForm on WebForm {
    id
    locale
    webFormColumns
    formType
    document(locales: $locale) {
      ...Asset
      url
    }
    confirmationMessage {
      raw
    }
  }
`;
export const SocialMediaShareFragmentDoc = gql`
  fragment SocialMediaShare on SocialMediaShare {
    bleedTop
    alignment
  }
`;
export const FormFragmentDoc = gql`
  fragment Form on Form {
    id
    title
    description
    disclaimerText
    consentText {
      raw
    }
    buttonColour
    confirmationMessage {
      raw
    }
    locale
    columns
    apiEndpoint
    ctaText
    fields {
      __typename
      ... on FormInputText {
        key
        inputLabel: label
        placeholder
        rowSpan
        required
        validators {
          __typename
          ... on MinLengthValidator {
            value
            errorMessage
          }
          ... on MaxLengthValidator {
            value
            errorMessage
          }
        }
      }
      ... on FormInputNumber {
        key
        inputLabel: label
        placeholder
        rowSpan
        required
        validators {
          __typename
          ... on MinNumberValidator {
            value
            errorMessage
          }
          ... on MaxNumberValidator {
            value
            errorMessage
          }
        }
      }
      ... on FormInputEmail {
        key
        inputLabel: label
        placeholder
        rowSpan
        required
        validators {
          __typename
          ... on EmailValidator {
            errorMessage
          }
        }
      }
      ... on FormTextarea {
        key
        textareaLabel: label
        placeholder
        required
        validators {
          __typename
          ... on MinLengthValidator {
            value
            errorMessage
          }
          ... on MaxLengthValidator {
            value
            errorMessage
          }
        }
      }
      ... on FormCheckbox {
        key
        checkboxLabel: label
        required
      }
      ... on FormCheckboxGroup {
        key
        label
        rowSpan
        required
        options {
          ... on FieldOption {
            name: value
            checkboxLabel: label
          }
        }
        validators {
          __typename
          ... on MinCheckedItemValidator {
            value
            errorMessage
          }
          ... on MaxCheckedItemValidator {
            value
            errorMessage
          }
        }
      }
      ... on FormSelect {
        key
        selectLabel: label
        placeholder
        options {
          value
          label
        }
        rowSpan
        required
      }
    }
    formType
  }
`;
export const BraidComponentFragmentDoc = gql`
  fragment BraidComponent on BraidComponent {
    braidCode
  }
`;
export const BugcrowdFormFragmentDoc = gql`
  fragment BugcrowdForm on BugcrowdForm {
    id
  }
`;
export const InterviewBuilderFragmentDoc = gql`
  fragment InterviewBuilder on InterviewBuilder {
    id
  }
`;
export const InvestorInformationFragmentDoc = gql`
  fragment InvestorInformation on InvestorInformation {
    id
    options
  }
`;
export const CustomComponentFragmentDoc = gql`
  fragment CustomComponent on CCustomComponent {
    data: component {
      __typename
      ... on SearchBox {
        ...SearchBox
      }
      ... on LoaDataLab {
        ...LoaDataLab
      }
      ... on WebForm {
        ...WebForm
      }
      ... on SocialMediaShare {
        ...SocialMediaShare
      }
      ... on CForm {
        form {
          ...Form
        }
      }
      ... on BraidComponent {
        ...BraidComponent
      }
      ... on BugcrowdForm {
        ...BugcrowdForm
      }
      ... on InterviewBuilder {
        ...InterviewBuilder
      }
      ... on InvestorInformation {
        ...InvestorInformation
      }
      ... on JobAdFeatureChart {
        id
      }
    }
  }
`;
export const TabFragmentDoc = gql`
  fragment Tab on Tab {
    id
    locale
    label
    content {
      raw
    }
  }
`;
export const TabGroupFragmentDoc = gql`
  fragment TabGroup on TabGroup {
    id
    tabs {
      ...Tab
    }
  }
`;
export const AlertFragmentDoc = gql`
  fragment Alert on CAlert {
    alertTone: tone
    paragraph {
      ...Paragraph
    }
  }
`;
export const TestimonialGroupFragmentDoc = gql`
  fragment TestimonialGroup on CTestimonialGroup {
    id
    testimonials {
      id
      quote
      author
      company
      locale
    }
    cardBackground
  }
`;
export const ProfileBioFragmentDoc = gql`
  fragment ProfileBio on ProfileBio {
    image {
      ...Asset
      ...DefaultImageUrlTransformation
    }
    name
    roleTitle
    description {
      raw
      text
    }
  }
`;
export const DocumentItemFragmentDoc = gql`
  fragment DocumentItem on DocumentItem {
    id
    itemIcon
    date
    title
    type
    externalUrl {
      ... on ExternalUrl {
        externalUrl: url
      }
    }
  }
`;
export const DocumentListFragmentDoc = gql`
  fragment DocumentList on DocumentList {
    icon
    groupName
    documents(first: 500) {
      ...DocumentItem
    }
    usePagination
    maxPageSize
    documentFilterOption
  }
`;
export const KeyStepsBlockFragmentDoc = gql`
  fragment KeyStepsBlock on KeyStepsBlock {
    id
    heading {
      ... on CHeading {
        ...Heading
      }
    }
    image {
      ... on Asset {
        ...Asset
        ...DefaultImageUrlTransformation
      }
    }
    steps {
      ...StepBlock
    }
    stepTone: tone
  }
`;
export const DialogVideoFragmentDoc = gql`
  fragment DialogVideo on DialogVideo {
    title
    description
    video {
      ... on BrightcoveVideo {
        ...BrightcoveVideo
      }
    }
  }
`;
export const VideoDialogGroupFragmentDoc = gql`
  fragment VideoDialogGroup on VideoDialogGroup {
    id
    tile {
      ...Tile
    }
    videos {
      ... on DialogVideo {
        ...DialogVideo
      }
    }
  }
`;
export const TipsCardFragmentDoc = gql`
  fragment TipsCard on TipsCard {
    icon
    description {
      raw
      text
    }
    downloadButton {
      ...ButtonLink
    }
    inlineLink {
      ... on CTextLink {
        ...TextLink
      }
    }
    badge {
      ...Badge
    }
  }
`;
export const DatalabFragmentDoc = gql`
  fragment Datalab on Datalab {
    tipsCardGroup {
      items {
        ... on TipsCard {
          ...TipsCard
        }
      }
    }
    aboutResearchLink {
      ...Link
    }
  }
`;
export const BannerItemFragmentDoc = gql`
  fragment BannerItem on BannerItem {
    backgroundColor
    borderRadius
    bannerItemDecoration
    heading {
      ... on CHeading {
        ...Heading
      }
    }
    paragraph {
      ... on CParagraph {
        ...Paragraph
      }
    }
    c_actionGroup {
      ... on CActionGroup {
        ...ActionGroup
      }
    }
    disableHorizontalPadding
    skipDefaultLocale
    sites {
      name
    }
  }
`;
export const BlocksFragmentDoc = gql`
  fragment Blocks on CBlock {
    id
    name
    skipDefaultLocale
    box {
      ...Box
    }
    items {
      __typename
      ... on CHeading {
        ...Heading
      }
      ... on CParagraph {
        ...Paragraph
      }
      ... on CActionGroup {
        ...ActionGroup
      }
      ... on CBanner {
        ...Banner
      }
      ... on CCardGroup {
        ...CardGroup
      }
      ... on CGallery {
        ...Gallery
      }
      ... on CIconBlockGroup {
        ...IconBlockGroup
      }
      ... on CImageBlockGroup {
        ...ImageBlockGroup
      }
      ... on CStepBlockGroup {
        ...StepBlockGroup
      }
      ... on AccordionGroup {
        ...AccordionGroup
      }
      ... on Disclosure {
        ...Disclosure
      }
      ... on TopicList {
        ...TopicList
      }
      ... on LatestArticlesByCategory {
        ...LatestArticlesByCategory
      }
      ... on CCustomComponent {
        ...CustomComponent
      }
      ... on Video {
        ...Video
      }
      ... on TabGroup {
        ...TabGroup
      }
      ... on CAlert {
        ...Alert
      }
      ... on CTestimonialGroup {
        ...TestimonialGroup
      }
      ... on ProfileBio {
        ...ProfileBio
      }
      ... on DocumentList {
        ...DocumentList
      }
      ... on KeyStepsBlock {
        ...KeyStepsBlock
      }
      ... on VideoDialogGroup {
        ...VideoDialogGroup
      }
      ... on Datalab {
        ...Datalab
      }
      ... on BannerItemList {
        bannerItems {
          ...BannerItem
        }
      }
    }
    tile {
      ...Tile
    }
  }
`;
export const ContainersFragmentDoc = gql`
  fragment Containers on CContainer {
    id
    htmlId
    box {
      ...Box
    }
    tile {
      ...Tile
    }
    blocks {
      ...Blocks
    }
  }
`;
export const PrimaryBannerImageUrlTransformationFragmentDoc = gql`
  fragment PrimaryBannerImageUrlTransformation on Asset {
    locale
    url(
      transformation: {
        image: { resize: { width: 1920, height: 600, fit: crop } }
      }
    )
  }
`;
export const DividerFragmentDoc = gql`
  fragment Divider on Divider {
    image {
      ...Asset
      ...DefaultImageUrlTransformation
    }
    actionGroup {
      ...ActionGroup
    }
  }
`;
export const BannerPrimaryFragmentDoc = gql`
  fragment BannerPrimary on CBannerPrimary {
    __typename
    id
    logoDisplay
    imageHeight
    isContentRight
    BannerPrimary_image: image(locales: $localesWithDefault) {
      ...Asset
      ...PrimaryBannerImageUrlTransformation
    }
    heading {
      ...Heading
    }
    paragraph {
      ...Paragraph
    }
    actionGroup {
      ...ActionGroup
    }
    footnotes {
      ...Paragraph
    }
    divider {
      ...Divider
    }
  }
`;
export const BannerAwardFragmentDoc = gql`
  fragment BannerAward on CBannerAward {
    __typename
    id
    backgroundImage {
      ...Asset
      ...DefaultImageUrlTransformation
    }
    heading {
      ...Heading
    }
    paragraph {
      ...Paragraph
    }
    actionGroup {
      ...ActionGroup
    }
    video {
      ...Video
    }
  }
`;
export const PromotionalBannerFragmentDoc = gql`
  fragment PromotionalBanner on PromotionalBanner {
    id
    heading {
      ...Heading
    }
    paragraph {
      ...Paragraph
    }
    brand
    background
    showLogo
  }
`;
export const EmbedSingleLineTextFragmentDoc = gql`
  fragment EmbedSingleLineText on EmbedSingleLineText {
    id
    value
    bold
  }
`;
export const RichTextEditorWrapperFragmentDoc = gql`
  fragment RichTextEditorWrapper on RichTextEditorWrapper {
    richTextEditorWrapper {
      content {
        raw
        references {
          __typename
          ... on EmbedSingleLineText {
            ...EmbedSingleLineText
          }
        }
      }
    }
  }
`;
export const BannerShopfrontFragmentDoc = gql`
  fragment BannerShopfront on CBannerShopfront {
    __typename
    id
    image {
      ...Asset
      url
    }
    heading {
      ...Heading
    }
    paragraph {
      ...Paragraph
    }
    BannerShopfront_footnotes: footnotes {
      ...RichTextEditorWrapper
    }
  }
`;
export const PagesWithEntitiesMinimalFragmentDoc = gql`
  fragment PagesWithEntitiesMinimal on Page {
    id
    title
    slug
    locale
    promotionalBanner(locales: $localesWithDefault) {
      ... on Entity {
        id
        stage
        __typename
      }
    }
    banner(locales: $localesWithDefault) {
      ... on Entity {
        id
        stage
        __typename
      }
    }
    containers(locales: $localesWithDefault) {
      id
      htmlId
      box {
        ...Box
      }
      tile {
        ...Tile
      }
      blocks(locales: $localesWithDefault) {
        id
        name
        skipDefaultLocale
        box {
          ...Box
        }
        tile {
          ...Tile
        }
        items(locales: $localesWithDefault) {
          ... on Entity {
            id
            stage
            __typename
            ... on Datalab {
              ...Datalab
            }
          }
        }
      }
    }
    relatedPageSection {
      sectionName
      displayName
      parentSection {
        sectionName
        displayName
      }
      footerOptions {
        ... on SectionFooter {
          ...SectionFooter
        }
      }
      headerOptions {
        ... on SectionHeader {
          ...SectionHeader
        }
      }
      theme
    }
    seo {
      title
      description
      noArchive
      noIndex
      image {
        url
      }
    }
    sites {
      name
    }
    localizations(includeCurrent: true) {
      locale
      title
    }
    stage
  }
`;
export const GetArticlesDocument = gql`
  query getArticles(
    $where: ArticleWhereInput!
    $locale: [Locale!]!
    $localesWithDefault: [Locale!]!
    $stage: Stage!
  ) {
    articles(where: $where, locales: $locale, stage: $stage) {
      id
      localizations(includeCurrent: true) {
        locale
        slug
        timeToRead
        title
        articleType
        authors {
          name
          slug
        }
        content {
          raw
          text
          references {
            __typename
            ... on EmbedAccordionGroup {
              ...EmbedAccordionGroup
            }
            ... on EmbedCustomContent {
              ...EmbedCustomContent
            }
          }
        }
        hideEmbeddedFormInSection {
          id
          sectionName
        }
        excerpt
        showExcerpt
        featureImage(locales: $localesWithDefault) {
          alt
          url
          height
          width
        }
        mediaUrl
        podcast(locales: $localesWithDefault) {
          appleUrl
          googleUrl
          spotifyUrl
        }
        seo(forceParentLocale: true) {
          title
          description
          noArchive
          noIndex
          image {
            url
          }
        }
        category(locales: $localesWithDefault) {
          name
          slug
          relatedCategorySection {
            sectionName
            displayName
            theme
            footerOptions {
              ... on SectionFooter {
                ...SectionFooter
              }
            }
            headerOptions {
              ... on SectionHeader {
                ...SectionHeader
              }
            }
            parentSection {
              sectionName
              displayName
            }
          }
          parent {
            name
            slug
          }
          additionalContent {
            ...ArticleAdditionalContent
          }
        }
        stage
        additionalContent {
          ...ArticleAdditionalContent
        }
      }
      sites {
        name
      }
      stage
    }
  }
  ${EmbedAccordionGroupFragmentDoc}
  ${RichTextAccordionFragmentDoc}
  ${EmbedCustomContentFragmentDoc}
  ${SectionFooterFragmentDoc}
  ${TextLinkFragmentDoc}
  ${LinkFragmentDoc}
  ${AssetFragmentDoc}
  ${DefaultImageUrlTransformationFragmentDoc}
  ${SectionHeaderFragmentDoc}
  ${ImageLinkFragmentDoc}
  ${ArticleAdditionalContentFragmentDoc}
  ${BoxFragmentDoc}
  ${TileFragmentDoc}
  ${ResponsiveColumnValueFragmentDoc}
  ${LatestArticlesByCategoryFragmentDoc}
`;

/**
 * __useGetArticlesQuery__
 *
 * To run a query within a React component, call `useGetArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticlesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      locale: // value for 'locale'
 *      localesWithDefault: // value for 'localesWithDefault'
 *      stage: // value for 'stage'
 *   },
 * });
 */
export function useGetArticlesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetArticlesQuery,
    GetArticlesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetArticlesQuery, GetArticlesQueryVariables>(
    GetArticlesDocument,
    options,
  );
}
export function useGetArticlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetArticlesQuery,
    GetArticlesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetArticlesQuery, GetArticlesQueryVariables>(
    GetArticlesDocument,
    options,
  );
}
export type GetArticlesQueryHookResult = ReturnType<typeof useGetArticlesQuery>;
export type GetArticlesLazyQueryHookResult = ReturnType<
  typeof useGetArticlesLazyQuery
>;
export type GetArticlesQueryResult = Apollo.QueryResult<
  GetArticlesQuery,
  GetArticlesQueryVariables
>;
export const GetCategoryDocument = gql`
  query GetCategory($where: CategoryWhereUniqueInput!) {
    category(where: $where) {
      id
      name
      relatedCategorySection {
        sectionName
        displayName
        theme
        footerOptions {
          ... on SectionFooter {
            ...SectionFooter
          }
        }
        headerOptions {
          ... on SectionHeader {
            ...SectionHeader
          }
        }
      }
    }
  }
  ${SectionFooterFragmentDoc}
  ${TextLinkFragmentDoc}
  ${LinkFragmentDoc}
  ${AssetFragmentDoc}
  ${DefaultImageUrlTransformationFragmentDoc}
  ${SectionHeaderFragmentDoc}
  ${ImageLinkFragmentDoc}
`;

/**
 * __useGetCategoryQuery__
 *
 * To run a query within a React component, call `useGetCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetCategoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCategoryQuery,
    GetCategoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCategoryQuery, GetCategoryQueryVariables>(
    GetCategoryDocument,
    options,
  );
}
export function useGetCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCategoryQuery,
    GetCategoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCategoryQuery, GetCategoryQueryVariables>(
    GetCategoryDocument,
    options,
  );
}
export type GetCategoryQueryHookResult = ReturnType<typeof useGetCategoryQuery>;
export type GetCategoryLazyQueryHookResult = ReturnType<
  typeof useGetCategoryLazyQuery
>;
export type GetCategoryQueryResult = Apollo.QueryResult<
  GetCategoryQuery,
  GetCategoryQueryVariables
>;
export const GetFormDataDocument = gql`
  query GetFormData(
    $where: FormWhereInput!
    $locale: [Locale!]!
    $stage: Stage!
    $first: Int!
  ) {
    forms(where: $where, stage: $stage, locales: $locale, first: $first) {
      id
      title
      description
      disclaimerText
      consentText {
        raw
      }
      buttonColour
      locale
      skipDefaultLocale
      confirmationMessage {
        raw
      }
      sites {
        name
      }
      columns
      apiEndpoint
      slug
      ctaText
      fields(forceParentLocale: true) {
        __typename
        ... on FormInputText {
          key
          inputLabel: label
          placeholder
          rowSpan
          required
          locale
          validators {
            __typename
            ... on MinLengthValidator {
              value
              errorMessage
            }
            ... on MaxLengthValidator {
              value
              errorMessage
            }
          }
        }
        ... on FormInputNumber {
          key
          inputLabel: label
          placeholder
          rowSpan
          required
          locale
          validators {
            __typename
            ... on MinNumberValidator {
              value
              errorMessage
            }
            ... on MaxNumberValidator {
              value
              errorMessage
            }
          }
        }
        ... on FormInputEmail {
          key
          inputLabel: label
          placeholder
          rowSpan
          required
          locale
          validators {
            __typename
            ... on EmailValidator {
              errorMessage
            }
          }
        }
        ... on FormTextarea {
          key
          textareaLabel: label
          placeholder
          required
          locale
          validators {
            __typename
            ... on MinLengthValidator {
              value
              errorMessage
            }
            ... on MaxLengthValidator {
              value
              errorMessage
            }
          }
        }
        ... on FormCheckbox {
          key
          checkboxLabel: label
          required
          locale
        }
        ... on FormCheckboxGroup {
          key
          label
          rowSpan
          required
          locale
          options {
            ... on FieldOption {
              name: value
              checkboxLabel: label
            }
          }
          validators {
            __typename
            ... on MinCheckedItemValidator {
              value
              errorMessage
            }
            ... on MaxCheckedItemValidator {
              value
              errorMessage
            }
          }
        }
        ... on FormSelect {
          key
          selectLabel: label
          placeholder
          locale
          options {
            value
            label
          }
          rowSpan
          required
        }
      }
      formType
    }
  }
`;

/**
 * __useGetFormDataQuery__
 *
 * To run a query within a React component, call `useGetFormDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormDataQuery({
 *   variables: {
 *      where: // value for 'where'
 *      locale: // value for 'locale'
 *      stage: // value for 'stage'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetFormDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFormDataQuery,
    GetFormDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFormDataQuery, GetFormDataQueryVariables>(
    GetFormDataDocument,
    options,
  );
}
export function useGetFormDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFormDataQuery,
    GetFormDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFormDataQuery, GetFormDataQueryVariables>(
    GetFormDataDocument,
    options,
  );
}
export type GetFormDataQueryHookResult = ReturnType<typeof useGetFormDataQuery>;
export type GetFormDataLazyQueryHookResult = ReturnType<
  typeof useGetFormDataLazyQuery
>;
export type GetFormDataQueryResult = Apollo.QueryResult<
  GetFormDataQuery,
  GetFormDataQueryVariables
>;
export const GetImageAssetDocument = gql`
  query GetImageAsset($where: AssetWhereUniqueInput!) {
    asset(where: $where) {
      id
      width
      height
      alt
      url
    }
  }
`;

/**
 * __useGetImageAssetQuery__
 *
 * To run a query within a React component, call `useGetImageAssetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImageAssetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImageAssetQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetImageAssetQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetImageAssetQuery,
    GetImageAssetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetImageAssetQuery, GetImageAssetQueryVariables>(
    GetImageAssetDocument,
    options,
  );
}
export function useGetImageAssetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetImageAssetQuery,
    GetImageAssetQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetImageAssetQuery, GetImageAssetQueryVariables>(
    GetImageAssetDocument,
    options,
  );
}
export type GetImageAssetQueryHookResult = ReturnType<
  typeof useGetImageAssetQuery
>;
export type GetImageAssetLazyQueryHookResult = ReturnType<
  typeof useGetImageAssetLazyQuery
>;
export type GetImageAssetQueryResult = Apollo.QueryResult<
  GetImageAssetQuery,
  GetImageAssetQueryVariables
>;
export const GetLatestArticlesByCategoryDocument = gql`
  query GetLatestArticlesByCategory(
    $where: CategoryWhereInput!
    $totalArticles: Int = 4
    $site: String!
    $locale: [Locale!]!
    $localesWithDefault: [Locale!]!
    $stage: Stage!
    $ignoredArticleId: ID
  ) {
    categories(where: $where, locales: $locale, stage: $stage, first: 1) {
      localizations(includeCurrent: true) {
        locale
        name
        slug
        parent {
          id
          name
          slug
        }
        description {
          text
        }
        relatedCategorySection {
          id
          sectionName
          displayName
          theme
          footerOptions {
            ... on SectionFooter {
              ...SectionFooter
            }
          }
          headerOptions {
            ... on SectionHeader {
              ...SectionHeader
            }
          }
          parentSection {
            id
            sectionName
            displayName
          }
        }
        relatedArticlesInCategory(
          where: {
            title_not: "-"
            documentInStages_some: { stage: $stage }
            sites_some: { name: $site }
            id_not: $ignoredArticleId
          }
          first: $totalArticles
          orderBy: publishDate_DESC
          locales: $locale
        ) {
          title
          articleType
          slug
          category {
            name
          }
          excerpt
          featureImage(locales: $localesWithDefault) {
            id
            fileName
            locale
            url(
              transformation: {
                image: { resize: { width: 480, height: 320, fit: crop } }
              }
            )
          }
          publishDate
          timeToRead
        }
      }
      sites(locales: $localesWithDefault) {
        name
      }
      stage
    }
  }
  ${SectionFooterFragmentDoc}
  ${TextLinkFragmentDoc}
  ${LinkFragmentDoc}
  ${AssetFragmentDoc}
  ${DefaultImageUrlTransformationFragmentDoc}
  ${SectionHeaderFragmentDoc}
  ${ImageLinkFragmentDoc}
`;

/**
 * __useGetLatestArticlesByCategoryQuery__
 *
 * To run a query within a React component, call `useGetLatestArticlesByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestArticlesByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestArticlesByCategoryQuery({
 *   variables: {
 *      where: // value for 'where'
 *      totalArticles: // value for 'totalArticles'
 *      site: // value for 'site'
 *      locale: // value for 'locale'
 *      localesWithDefault: // value for 'localesWithDefault'
 *      stage: // value for 'stage'
 *      ignoredArticleId: // value for 'ignoredArticleId'
 *   },
 * });
 */
export function useGetLatestArticlesByCategoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLatestArticlesByCategoryQuery,
    GetLatestArticlesByCategoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLatestArticlesByCategoryQuery,
    GetLatestArticlesByCategoryQueryVariables
  >(GetLatestArticlesByCategoryDocument, options);
}
export function useGetLatestArticlesByCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLatestArticlesByCategoryQuery,
    GetLatestArticlesByCategoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLatestArticlesByCategoryQuery,
    GetLatestArticlesByCategoryQueryVariables
  >(GetLatestArticlesByCategoryDocument, options);
}
export type GetLatestArticlesByCategoryQueryHookResult = ReturnType<
  typeof useGetLatestArticlesByCategoryQuery
>;
export type GetLatestArticlesByCategoryLazyQueryHookResult = ReturnType<
  typeof useGetLatestArticlesByCategoryLazyQuery
>;
export type GetLatestArticlesByCategoryQueryResult = Apollo.QueryResult<
  GetLatestArticlesByCategoryQuery,
  GetLatestArticlesByCategoryQueryVariables
>;
export const GetArticlesByAuthorPaginationDocument = gql`
  query GetArticlesByAuthorPagination(
    $where: ArticleWhereInput!
    $locale: [Locale!]!
    $localesWithDefault: [Locale!]!
    $first: Int!
    $skip: Int!
  ) {
    articlesConnection(
      where: $where
      orderBy: publishDate_DESC
      locales: $locale
      first: $first
      skip: $skip
    ) {
      edges {
        cursor
        node {
          id
          title
          articleType
          timeToRead
          excerpt
          slug
          featureImage(locales: $localesWithDefault) {
            locale
            id
            fileName
            url(
              transformation: {
                image: { resize: { width: 480, height: 320, fit: crop } }
              }
            )
          }
          locale
          category(locales: $localesWithDefault) {
            name
            slug
            locale
            relatedCategorySection {
              sectionName
              displayName
            }
          }
          publishDate
          authors(locales: $localesWithDefault) {
            name
            slug
            description {
              raw
              text
            }
            locale
            image(locales: $localesWithDefault) {
              alt
              url
            }
          }
        }
      }
      aggregate {
        count
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
        pageSize
      }
    }
  }
`;

/**
 * __useGetArticlesByAuthorPaginationQuery__
 *
 * To run a query within a React component, call `useGetArticlesByAuthorPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArticlesByAuthorPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticlesByAuthorPaginationQuery({
 *   variables: {
 *      where: // value for 'where'
 *      locale: // value for 'locale'
 *      localesWithDefault: // value for 'localesWithDefault'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetArticlesByAuthorPaginationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetArticlesByAuthorPaginationQuery,
    GetArticlesByAuthorPaginationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetArticlesByAuthorPaginationQuery,
    GetArticlesByAuthorPaginationQueryVariables
  >(GetArticlesByAuthorPaginationDocument, options);
}
export function useGetArticlesByAuthorPaginationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetArticlesByAuthorPaginationQuery,
    GetArticlesByAuthorPaginationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetArticlesByAuthorPaginationQuery,
    GetArticlesByAuthorPaginationQueryVariables
  >(GetArticlesByAuthorPaginationDocument, options);
}
export type GetArticlesByAuthorPaginationQueryHookResult = ReturnType<
  typeof useGetArticlesByAuthorPaginationQuery
>;
export type GetArticlesByAuthorPaginationLazyQueryHookResult = ReturnType<
  typeof useGetArticlesByAuthorPaginationLazyQuery
>;
export type GetArticlesByAuthorPaginationQueryResult = Apollo.QueryResult<
  GetArticlesByAuthorPaginationQuery,
  GetArticlesByAuthorPaginationQueryVariables
>;
export const GetArticlesByCategoryPaginationDocument = gql`
  query GetArticlesByCategoryPagination(
    $where: ArticleWhereInput!
    $locale: [Locale!]!
    $localesWithDefault: [Locale!]!
    $stage: Stage!
    $first: Int!
    $skip: Int!
  ) {
    articlesConnection(
      where: $where
      orderBy: publishDate_DESC
      locales: $locale
      stage: $stage
      first: $first
      skip: $skip
    ) {
      edges {
        cursor
        node {
          id
          title
          articleType
          timeToRead
          excerpt
          slug
          featureImage(locales: $localesWithDefault) {
            locale
            id
            fileName
            url(
              transformation: {
                image: { resize: { width: 480, height: 320, fit: crop } }
              }
            )
          }
          publishDate
          category {
            name
            slug
            description {
              text
            }
            slug
            parent {
              id
              name
              slug
            }
            relatedCategorySection {
              sectionName
              displayName
              theme
              footerOptions {
                ... on SectionFooter {
                  ...SectionFooter
                }
              }
              headerOptions {
                ... on SectionHeader {
                  ...SectionHeader
                }
              }
              parentSection {
                sectionName
                displayName
              }
            }
            localizations(includeCurrent: true) {
              locale
            }
            sites {
              name
            }
          }
        }
      }
      aggregate {
        count
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
        pageSize
      }
    }
  }
  ${SectionFooterFragmentDoc}
  ${TextLinkFragmentDoc}
  ${LinkFragmentDoc}
  ${AssetFragmentDoc}
  ${DefaultImageUrlTransformationFragmentDoc}
  ${SectionHeaderFragmentDoc}
  ${ImageLinkFragmentDoc}
`;

/**
 * __useGetArticlesByCategoryPaginationQuery__
 *
 * To run a query within a React component, call `useGetArticlesByCategoryPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArticlesByCategoryPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArticlesByCategoryPaginationQuery({
 *   variables: {
 *      where: // value for 'where'
 *      locale: // value for 'locale'
 *      localesWithDefault: // value for 'localesWithDefault'
 *      stage: // value for 'stage'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetArticlesByCategoryPaginationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetArticlesByCategoryPaginationQuery,
    GetArticlesByCategoryPaginationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetArticlesByCategoryPaginationQuery,
    GetArticlesByCategoryPaginationQueryVariables
  >(GetArticlesByCategoryPaginationDocument, options);
}
export function useGetArticlesByCategoryPaginationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetArticlesByCategoryPaginationQuery,
    GetArticlesByCategoryPaginationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetArticlesByCategoryPaginationQuery,
    GetArticlesByCategoryPaginationQueryVariables
  >(GetArticlesByCategoryPaginationDocument, options);
}
export type GetArticlesByCategoryPaginationQueryHookResult = ReturnType<
  typeof useGetArticlesByCategoryPaginationQuery
>;
export type GetArticlesByCategoryPaginationLazyQueryHookResult = ReturnType<
  typeof useGetArticlesByCategoryPaginationLazyQuery
>;
export type GetArticlesByCategoryPaginationQueryResult = Apollo.QueryResult<
  GetArticlesByCategoryPaginationQuery,
  GetArticlesByCategoryPaginationQueryVariables
>;
export const GetPreviewDataDocument = gql`
  query GetPreviewData(
    $whereArticle: ArticleWhereUniqueInput!
    $wherePage: PageWhereUniqueInput!
    $stage: Stage!
  ) {
    article(where: $whereArticle, stage: $stage) {
      id
      stage
      localizations(includeCurrent: true) {
        title
        slug
        locale
        category {
          relatedCategorySection {
            sectionName
            parentSection {
              sectionName
            }
          }
        }
      }
      sites {
        name
      }
    }
    page(where: $wherePage, stage: $stage) {
      relatedPageSection {
        id
        sectionName
        parentSection {
          sectionName
        }
      }
      sites {
        name
      }
      stage
      id
      localizations(includeCurrent: true) {
        title
        slug
        locale
      }
    }
  }
`;

/**
 * __useGetPreviewDataQuery__
 *
 * To run a query within a React component, call `useGetPreviewDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPreviewDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPreviewDataQuery({
 *   variables: {
 *      whereArticle: // value for 'whereArticle'
 *      wherePage: // value for 'wherePage'
 *      stage: // value for 'stage'
 *   },
 * });
 */
export function useGetPreviewDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPreviewDataQuery,
    GetPreviewDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPreviewDataQuery, GetPreviewDataQueryVariables>(
    GetPreviewDataDocument,
    options,
  );
}
export function useGetPreviewDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPreviewDataQuery,
    GetPreviewDataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPreviewDataQuery, GetPreviewDataQueryVariables>(
    GetPreviewDataDocument,
    options,
  );
}
export type GetPreviewDataQueryHookResult = ReturnType<
  typeof useGetPreviewDataQuery
>;
export type GetPreviewDataLazyQueryHookResult = ReturnType<
  typeof useGetPreviewDataLazyQuery
>;
export type GetPreviewDataQueryResult = Apollo.QueryResult<
  GetPreviewDataQuery,
  GetPreviewDataQueryVariables
>;
export const GetSectionsDocument = gql`
  query GetSections($where: SectionWhereInput) {
    sections(where: $where) {
      sectionName
      theme
      footerOptions {
        ... on SectionFooter {
          ...SectionFooter
        }
      }
      headerOptions {
        ... on SectionHeader {
          ...SectionHeader
        }
      }
    }
  }
  ${SectionFooterFragmentDoc}
  ${TextLinkFragmentDoc}
  ${LinkFragmentDoc}
  ${AssetFragmentDoc}
  ${DefaultImageUrlTransformationFragmentDoc}
  ${SectionHeaderFragmentDoc}
  ${ImageLinkFragmentDoc}
`;

/**
 * __useGetSectionsQuery__
 *
 * To run a query within a React component, call `useGetSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSectionsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetSectionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSectionsQuery,
    GetSectionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSectionsQuery, GetSectionsQueryVariables>(
    GetSectionsDocument,
    options,
  );
}
export function useGetSectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSectionsQuery,
    GetSectionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSectionsQuery, GetSectionsQueryVariables>(
    GetSectionsDocument,
    options,
  );
}
export type GetSectionsQueryHookResult = ReturnType<typeof useGetSectionsQuery>;
export type GetSectionsLazyQueryHookResult = ReturnType<
  typeof useGetSectionsLazyQuery
>;
export type GetSectionsQueryResult = Apollo.QueryResult<
  GetSectionsQuery,
  GetSectionsQueryVariables
>;
export const GetAuthorWithArticlesDocument = gql`
  query GetAuthorWithArticles(
    $slug: String!
    $site: String!
    $locale: [Locale!]!
    $localesWithDefault: [Locale!]!
    $stage: Stage!
    $first: Int!
    $skip: Int!
  ) {
    authors(
      where: { slug: $slug, sites_some: { name: $site } }
      locales: $locale
      stage: $stage
      first: 1
    ) {
      id
      localizations(includeCurrent: true) {
        name
        slug
        description {
          raw
          text
        }
        locale
        image {
          alt
          url(
            transformation: {
              image: { resize: { width: 320, height: 320, fit: crop } }
            }
          )
        }
      }
      sites {
        name
      }
      stage
    }
    articlesConnection(
      where: {
        authors_some: { slug: $slug, sites_some: { name: $site } }
        sites_some: { name: $site }
      }
      orderBy: publishDate_DESC
      locales: $locale
      first: $first
      skip: $skip
    ) {
      edges {
        cursor
        node {
          id
          title
          articleType
          timeToRead
          excerpt
          slug
          featureImage(locales: $localesWithDefault) {
            locale
            id
            fileName
            url(
              transformation: {
                image: { resize: { width: 480, height: 320, fit: crop } }
              }
            )
          }
          locale
          category(locales: $localesWithDefault) {
            name
            slug
            locale
            relatedCategorySection {
              sectionName
            }
          }
          publishDate
        }
      }
      aggregate {
        count
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
        pageSize
      }
    }
  }
`;

/**
 * __useGetAuthorWithArticlesQuery__
 *
 * To run a query within a React component, call `useGetAuthorWithArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthorWithArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthorWithArticlesQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *      site: // value for 'site'
 *      locale: // value for 'locale'
 *      localesWithDefault: // value for 'localesWithDefault'
 *      stage: // value for 'stage'
 *      first: // value for 'first'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetAuthorWithArticlesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAuthorWithArticlesQuery,
    GetAuthorWithArticlesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAuthorWithArticlesQuery,
    GetAuthorWithArticlesQueryVariables
  >(GetAuthorWithArticlesDocument, options);
}
export function useGetAuthorWithArticlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAuthorWithArticlesQuery,
    GetAuthorWithArticlesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAuthorWithArticlesQuery,
    GetAuthorWithArticlesQueryVariables
  >(GetAuthorWithArticlesDocument, options);
}
export type GetAuthorWithArticlesQueryHookResult = ReturnType<
  typeof useGetAuthorWithArticlesQuery
>;
export type GetAuthorWithArticlesLazyQueryHookResult = ReturnType<
  typeof useGetAuthorWithArticlesLazyQuery
>;
export type GetAuthorWithArticlesQueryResult = Apollo.QueryResult<
  GetAuthorWithArticlesQuery,
  GetAuthorWithArticlesQueryVariables
>;
export const GetAuthorsDocument = gql`
  query GetAuthors(
    $where: AuthorWhereInput!
    $locale: [Locale!]!
    $stage: Stage!
  ) {
    authors(where: $where, locales: $locale, stage: $stage) {
      id
      localizations(includeCurrent: true) {
        name
        slug
        description {
          raw
          text
        }
        locale
        image {
          alt
          url(
            transformation: {
              image: { resize: { width: 320, height: 320, fit: crop } }
            }
          )
        }
      }
      sites {
        name
      }
      stage
    }
  }
`;

/**
 * __useGetAuthorsQuery__
 *
 * To run a query within a React component, call `useGetAuthorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthorsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      locale: // value for 'locale'
 *      stage: // value for 'stage'
 *   },
 * });
 */
export function useGetAuthorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAuthorsQuery,
    GetAuthorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAuthorsQuery, GetAuthorsQueryVariables>(
    GetAuthorsDocument,
    options,
  );
}
export function useGetAuthorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAuthorsQuery,
    GetAuthorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAuthorsQuery, GetAuthorsQueryVariables>(
    GetAuthorsDocument,
    options,
  );
}
export type GetAuthorsQueryHookResult = ReturnType<typeof useGetAuthorsQuery>;
export type GetAuthorsLazyQueryHookResult = ReturnType<
  typeof useGetAuthorsLazyQuery
>;
export type GetAuthorsQueryResult = Apollo.QueryResult<
  GetAuthorsQuery,
  GetAuthorsQueryVariables
>;
export const GetCategoriesWithLatestArticlesDocument = gql`
  query GetCategoriesWithLatestArticles(
    $where: CategoryWhereInput!
    $sectionWhere: SectionWhereInput!
    $site: String!
    $locale: [Locale!]!
    $localesWithDefault: [Locale!]!
    $stage: Stage!
  ) {
    categories(
      where: $where
      orderBy: name_ASC
      locales: $locale
      stage: $stage
    ) {
      localizations(includeCurrent: true) {
        locale
        name
        slug
        description {
          text
        }
        parent(locales: $localesWithDefault) {
          id
          name
          slug
        }
        children(
          where: {
            sites_some: { name: $site }
            relatedCategorySection: $sectionWhere
            documentInStages_some: { stage: $stage }
          }
          orderBy: name_ASC
          locales: $localesWithDefault
        ) {
          id
          name
          slug
          relatedArticlesInCategory(
            where: {
              title_not: "-"
              documentInStages_some: { stage: $stage }
              sites_some: { name: $site }
            }
            first: 4
            orderBy: publishDate_DESC
            locales: $localesWithDefault
          ) {
            id
            title
            articleType
            slug
            excerpt
            featureImage(locales: $localesWithDefault) {
              id
              fileName
              locale
              url(
                transformation: {
                  image: { resize: { width: 480, height: 320, fit: crop } }
                }
              )
            }
            publishDate
            timeToRead
          }
        }
        seo(forceParentLocale: true) {
          title
          description
          noArchive
          noIndex
          image {
            url
          }
        }
        relatedCategorySection(locales: $localesWithDefault) {
          sectionName
          displayName
          theme
          footerOptions {
            ... on SectionFooter {
              ...SectionFooter
            }
          }
          headerOptions {
            ... on SectionHeader {
              ...SectionHeader
            }
          }
          parentSection {
            sectionName
            displayName
          }
        }
      }
      sites(locales: $localesWithDefault) {
        name
      }
      stage
    }
  }
  ${SectionFooterFragmentDoc}
  ${TextLinkFragmentDoc}
  ${LinkFragmentDoc}
  ${AssetFragmentDoc}
  ${DefaultImageUrlTransformationFragmentDoc}
  ${SectionHeaderFragmentDoc}
  ${ImageLinkFragmentDoc}
`;

/**
 * __useGetCategoriesWithLatestArticlesQuery__
 *
 * To run a query within a React component, call `useGetCategoriesWithLatestArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesWithLatestArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesWithLatestArticlesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      sectionWhere: // value for 'sectionWhere'
 *      site: // value for 'site'
 *      locale: // value for 'locale'
 *      localesWithDefault: // value for 'localesWithDefault'
 *      stage: // value for 'stage'
 *   },
 * });
 */
export function useGetCategoriesWithLatestArticlesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCategoriesWithLatestArticlesQuery,
    GetCategoriesWithLatestArticlesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCategoriesWithLatestArticlesQuery,
    GetCategoriesWithLatestArticlesQueryVariables
  >(GetCategoriesWithLatestArticlesDocument, options);
}
export function useGetCategoriesWithLatestArticlesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCategoriesWithLatestArticlesQuery,
    GetCategoriesWithLatestArticlesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCategoriesWithLatestArticlesQuery,
    GetCategoriesWithLatestArticlesQueryVariables
  >(GetCategoriesWithLatestArticlesDocument, options);
}
export type GetCategoriesWithLatestArticlesQueryHookResult = ReturnType<
  typeof useGetCategoriesWithLatestArticlesQuery
>;
export type GetCategoriesWithLatestArticlesLazyQueryHookResult = ReturnType<
  typeof useGetCategoriesWithLatestArticlesLazyQuery
>;
export type GetCategoriesWithLatestArticlesQueryResult = Apollo.QueryResult<
  GetCategoriesWithLatestArticlesQuery,
  GetCategoriesWithLatestArticlesQueryVariables
>;
export const GetBannerItemDocument = gql`
  query getBannerItem(
    $where: BannerItemWhereInput!
    $locale: [Locale!]!
    $stage: Stage!
    $first: Int!
  ) {
    bannerItems(where: $where, locales: $locale, stage: $stage, first: $first) {
      ... on BannerItem {
        ...BannerItem
      }
    }
  }
  ${BannerItemFragmentDoc}
  ${HeadingFragmentDoc}
  ${SpaceFragmentDoc}
  ${ResponsiveSpaceFragmentDoc}
  ${ParagraphFragmentDoc}
  ${ActionGroupFragmentDoc}
  ${ButtonLinkFragmentDoc}
  ${LinkFragmentDoc}
  ${TextLinkFragmentDoc}
  ${ImageLinkFragmentDoc}
`;

/**
 * __useGetBannerItemQuery__
 *
 * To run a query within a React component, call `useGetBannerItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBannerItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBannerItemQuery({
 *   variables: {
 *      where: // value for 'where'
 *      locale: // value for 'locale'
 *      stage: // value for 'stage'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetBannerItemQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBannerItemQuery,
    GetBannerItemQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBannerItemQuery, GetBannerItemQueryVariables>(
    GetBannerItemDocument,
    options,
  );
}
export function useGetBannerItemLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBannerItemQuery,
    GetBannerItemQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBannerItemQuery, GetBannerItemQueryVariables>(
    GetBannerItemDocument,
    options,
  );
}
export type GetBannerItemQueryHookResult = ReturnType<
  typeof useGetBannerItemQuery
>;
export type GetBannerItemLazyQueryHookResult = ReturnType<
  typeof useGetBannerItemLazyQuery
>;
export type GetBannerItemQueryResult = Apollo.QueryResult<
  GetBannerItemQuery,
  GetBannerItemQueryVariables
>;
export const GetPagesDocument = gql`
  query GetPages(
    $where: PageWhereInput!
    $locale: [Locale!]!
    $localesWithDefault: [Locale!]!
  ) {
    pages(where: $where, locales: $locale, orderBy: slug_ASC) {
      id
      title
      slug
      locale
      promotionalBanner {
        ...PromotionalBanner
      }
      banner {
        __typename
        ... on CBannerPrimary {
          ...BannerPrimary
        }
        ... on CBannerShopfront {
          ...BannerShopfront
        }
        ... on CBannerAward {
          ...BannerAward
        }
      }
      containers {
        ...Containers
      }
      relatedPageSection {
        sectionName
        displayName
        parentSection {
          sectionName
          displayName
        }
        footerOptions {
          ... on SectionFooter {
            ...SectionFooter
          }
        }
        headerOptions {
          ... on SectionHeader {
            ...SectionHeader
          }
        }
        theme
      }
      seo {
        title
        description
        noArchive
        noIndex
        image {
          url
        }
      }
      sites {
        name
      }
      hideEmbeddedFormInSection {
        id
        sectionName
      }
      localizations(includeCurrent: true) {
        locale
        title
      }
      enableBreadcrumbs
    }
  }
  ${PromotionalBannerFragmentDoc}
  ${HeadingFragmentDoc}
  ${SpaceFragmentDoc}
  ${ResponsiveSpaceFragmentDoc}
  ${ParagraphFragmentDoc}
  ${BannerPrimaryFragmentDoc}
  ${AssetFragmentDoc}
  ${PrimaryBannerImageUrlTransformationFragmentDoc}
  ${ActionGroupFragmentDoc}
  ${ButtonLinkFragmentDoc}
  ${LinkFragmentDoc}
  ${TextLinkFragmentDoc}
  ${ImageLinkFragmentDoc}
  ${DividerFragmentDoc}
  ${DefaultImageUrlTransformationFragmentDoc}
  ${BannerShopfrontFragmentDoc}
  ${RichTextEditorWrapperFragmentDoc}
  ${EmbedSingleLineTextFragmentDoc}
  ${BannerAwardFragmentDoc}
  ${VideoFragmentDoc}
  ${BoxFragmentDoc}
  ${BrightcoveVideoFragmentDoc}
  ${YouTubeVideoFragmentDoc}
  ${ContainersFragmentDoc}
  ${TileFragmentDoc}
  ${ResponsiveColumnValueFragmentDoc}
  ${BlocksFragmentDoc}
  ${BannerFragmentDoc}
  ${BannerInlineFragmentDoc}
  ${InlineBannerImageUrlTransformationFragmentDoc}
  ${InlineDividerFragmentDoc}
  ${BadgeFragmentDoc}
  ${BannerArticleFragmentDoc}
  ${CardGroupFragmentDoc}
  ${CardPrimaryFragmentDoc}
  ${NumericalBlockFragmentDoc}
  ${CardJobAdFragmentDoc}
  ${GalleryFragmentDoc}
  ${IconBlockGroupFragmentDoc}
  ${IconBlockFragmentDoc}
  ${ImageBlockGroupFragmentDoc}
  ${ImageBlockFragmentDoc}
  ${DialogButtonFragmentDoc}
  ${DialogItemFragmentDoc}
  ${StepBlockGroupFragmentDoc}
  ${StepBlockFragmentDoc}
  ${AccordionGroupFragmentDoc}
  ${AccordionFragmentDoc}
  ${DisclosureFragmentDoc}
  ${TopicListFragmentDoc}
  ${ArticleFragmentDoc}
  ${SectionFooterFragmentDoc}
  ${SectionHeaderFragmentDoc}
  ${LatestArticlesByCategoryFragmentDoc}
  ${CustomComponentFragmentDoc}
  ${SearchBoxFragmentDoc}
  ${LoaDataLabFragmentDoc}
  ${WebFormFragmentDoc}
  ${SocialMediaShareFragmentDoc}
  ${FormFragmentDoc}
  ${BraidComponentFragmentDoc}
  ${BugcrowdFormFragmentDoc}
  ${InterviewBuilderFragmentDoc}
  ${InvestorInformationFragmentDoc}
  ${TabGroupFragmentDoc}
  ${TabFragmentDoc}
  ${AlertFragmentDoc}
  ${TestimonialGroupFragmentDoc}
  ${ProfileBioFragmentDoc}
  ${DocumentListFragmentDoc}
  ${DocumentItemFragmentDoc}
  ${KeyStepsBlockFragmentDoc}
  ${VideoDialogGroupFragmentDoc}
  ${DialogVideoFragmentDoc}
  ${DatalabFragmentDoc}
  ${TipsCardFragmentDoc}
  ${BannerItemFragmentDoc}
`;

/**
 * __useGetPagesQuery__
 *
 * To run a query within a React component, call `useGetPagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      locale: // value for 'locale'
 *      localesWithDefault: // value for 'localesWithDefault'
 *   },
 * });
 */
export function useGetPagesQuery(
  baseOptions: Apollo.QueryHookOptions<GetPagesQuery, GetPagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPagesQuery, GetPagesQueryVariables>(
    GetPagesDocument,
    options,
  );
}
export function useGetPagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPagesQuery,
    GetPagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPagesQuery, GetPagesQueryVariables>(
    GetPagesDocument,
    options,
  );
}
export type GetPagesQueryHookResult = ReturnType<typeof useGetPagesQuery>;
export type GetPagesLazyQueryHookResult = ReturnType<
  typeof useGetPagesLazyQuery
>;
export type GetPagesQueryResult = Apollo.QueryResult<
  GetPagesQuery,
  GetPagesQueryVariables
>;
export const GetPagesLiteDocument = gql`
  query GetPagesLite(
    $where: PageWhereInput!
    $locale: [Locale!]!
    $stage: Stage!
  ) {
    pages(where: $where, locales: $locale, stage: $stage, orderBy: slug_ASC) {
      id
      title
      slug
      locale
      promotionalBanner {
        id
      }
      banner {
        __typename
        ... on CBannerPrimary {
          id
        }
        ... on CBannerShopfront {
          id
        }
        ... on CBannerAward {
          id
        }
      }
      containers {
        id
      }
      relatedPageSection {
        sectionName
        displayName
        footerOptions {
          ... on SectionFooter {
            ...SectionFooter
          }
        }
        headerOptions {
          ... on SectionHeader {
            ...SectionHeader
          }
        }
        theme
      }
      seo {
        title
        description
        noArchive
        noIndex
        image {
          url
        }
      }
      sites {
        name
      }
      localizations(includeCurrent: true) {
        locale
        title
      }
      stage
      enableBreadcrumbs
    }
  }
  ${SectionFooterFragmentDoc}
  ${TextLinkFragmentDoc}
  ${LinkFragmentDoc}
  ${AssetFragmentDoc}
  ${DefaultImageUrlTransformationFragmentDoc}
  ${SectionHeaderFragmentDoc}
  ${ImageLinkFragmentDoc}
`;

/**
 * __useGetPagesLiteQuery__
 *
 * To run a query within a React component, call `useGetPagesLiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPagesLiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagesLiteQuery({
 *   variables: {
 *      where: // value for 'where'
 *      locale: // value for 'locale'
 *      stage: // value for 'stage'
 *   },
 * });
 */
export function useGetPagesLiteQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPagesLiteQuery,
    GetPagesLiteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPagesLiteQuery, GetPagesLiteQueryVariables>(
    GetPagesLiteDocument,
    options,
  );
}
export function useGetPagesLiteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPagesLiteQuery,
    GetPagesLiteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPagesLiteQuery, GetPagesLiteQueryVariables>(
    GetPagesLiteDocument,
    options,
  );
}
export type GetPagesLiteQueryHookResult = ReturnType<
  typeof useGetPagesLiteQuery
>;
export type GetPagesLiteLazyQueryHookResult = ReturnType<
  typeof useGetPagesLiteLazyQuery
>;
export type GetPagesLiteQueryResult = Apollo.QueryResult<
  GetPagesLiteQuery,
  GetPagesLiteQueryVariables
>;
export const GetPagesWithEntitiesMinimalDocument = gql`
  query GetPagesWithEntitiesMinimal(
    $where: PageWhereInput!
    $locale: [Locale!]!
    $localesWithDefault: [Locale!]!
    $stage: Stage!
  ) {
    pages(
      where: $where
      locales: $locale
      stage: $stage
      first: 2
      orderBy: slug_ASC
    ) {
      ...PagesWithEntitiesMinimal
    }
  }
  ${PagesWithEntitiesMinimalFragmentDoc}
  ${BoxFragmentDoc}
  ${TileFragmentDoc}
  ${ResponsiveColumnValueFragmentDoc}
  ${DatalabFragmentDoc}
  ${TipsCardFragmentDoc}
  ${ButtonLinkFragmentDoc}
  ${LinkFragmentDoc}
  ${TextLinkFragmentDoc}
  ${BadgeFragmentDoc}
  ${SectionFooterFragmentDoc}
  ${AssetFragmentDoc}
  ${DefaultImageUrlTransformationFragmentDoc}
  ${SectionHeaderFragmentDoc}
  ${ImageLinkFragmentDoc}
`;

/**
 * __useGetPagesWithEntitiesMinimalQuery__
 *
 * To run a query within a React component, call `useGetPagesWithEntitiesMinimalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPagesWithEntitiesMinimalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagesWithEntitiesMinimalQuery({
 *   variables: {
 *      where: // value for 'where'
 *      locale: // value for 'locale'
 *      localesWithDefault: // value for 'localesWithDefault'
 *      stage: // value for 'stage'
 *   },
 * });
 */
export function useGetPagesWithEntitiesMinimalQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPagesWithEntitiesMinimalQuery,
    GetPagesWithEntitiesMinimalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPagesWithEntitiesMinimalQuery,
    GetPagesWithEntitiesMinimalQueryVariables
  >(GetPagesWithEntitiesMinimalDocument, options);
}
export function useGetPagesWithEntitiesMinimalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPagesWithEntitiesMinimalQuery,
    GetPagesWithEntitiesMinimalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPagesWithEntitiesMinimalQuery,
    GetPagesWithEntitiesMinimalQueryVariables
  >(GetPagesWithEntitiesMinimalDocument, options);
}
export type GetPagesWithEntitiesMinimalQueryHookResult = ReturnType<
  typeof useGetPagesWithEntitiesMinimalQuery
>;
export type GetPagesWithEntitiesMinimalLazyQueryHookResult = ReturnType<
  typeof useGetPagesWithEntitiesMinimalLazyQuery
>;
export type GetPagesWithEntitiesMinimalQueryResult = Apollo.QueryResult<
  GetPagesWithEntitiesMinimalQuery,
  GetPagesWithEntitiesMinimalQueryVariables
>;
export const GetPagesWithEntitiesDocument = gql`
  query GetPagesWithEntities(
    $where: PageWhereInput!
    $locale: [Locale!]!
    $localesWithDefault: [Locale!]!
    $stage: Stage!
  ) {
    pages(
      where: $where
      locales: $locale
      stage: $stage
      first: 2
      orderBy: slug_ASC
    ) {
      ...PagesWithEntitiesMinimal
      hideEmbeddedFormInSection {
        id
        sectionName
      }
      enableBreadcrumbs
    }
  }
  ${PagesWithEntitiesMinimalFragmentDoc}
  ${BoxFragmentDoc}
  ${TileFragmentDoc}
  ${ResponsiveColumnValueFragmentDoc}
  ${DatalabFragmentDoc}
  ${TipsCardFragmentDoc}
  ${ButtonLinkFragmentDoc}
  ${LinkFragmentDoc}
  ${TextLinkFragmentDoc}
  ${BadgeFragmentDoc}
  ${SectionFooterFragmentDoc}
  ${AssetFragmentDoc}
  ${DefaultImageUrlTransformationFragmentDoc}
  ${SectionHeaderFragmentDoc}
  ${ImageLinkFragmentDoc}
`;

/**
 * __useGetPagesWithEntitiesQuery__
 *
 * To run a query within a React component, call `useGetPagesWithEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPagesWithEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagesWithEntitiesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      locale: // value for 'locale'
 *      localesWithDefault: // value for 'localesWithDefault'
 *      stage: // value for 'stage'
 *   },
 * });
 */
export function useGetPagesWithEntitiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPagesWithEntitiesQuery,
    GetPagesWithEntitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPagesWithEntitiesQuery,
    GetPagesWithEntitiesQueryVariables
  >(GetPagesWithEntitiesDocument, options);
}
export function useGetPagesWithEntitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPagesWithEntitiesQuery,
    GetPagesWithEntitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPagesWithEntitiesQuery,
    GetPagesWithEntitiesQueryVariables
  >(GetPagesWithEntitiesDocument, options);
}
export type GetPagesWithEntitiesQueryHookResult = ReturnType<
  typeof useGetPagesWithEntitiesQuery
>;
export type GetPagesWithEntitiesLazyQueryHookResult = ReturnType<
  typeof useGetPagesWithEntitiesLazyQuery
>;
export type GetPagesWithEntitiesQueryResult = Apollo.QueryResult<
  GetPagesWithEntitiesQuery,
  GetPagesWithEntitiesQueryVariables
>;
export const GetEntitiesDocument = gql`
  query GetEntities($entityWhere: [EntityWhereInput!]!, $locale: [Locale!]!) {
    entities(where: $entityWhere, locales: $locale) {
      id
      ... on CHeading {
        ...Heading
      }
      ... on CParagraph {
        ...Paragraph
      }
      ... on CActionGroup {
        ...ActionGroup
      }
      ... on CBanner {
        ...Banner
      }
      ... on CCardGroup {
        ...CardGroup
      }
      ... on CGallery {
        ...Gallery
      }
      ... on CIconBlockGroup {
        ...IconBlockGroup
      }
      ... on CImageBlockGroup {
        ...ImageBlockGroup
      }
      ... on CStepBlockGroup {
        ...StepBlockGroup
      }
      ... on AccordionGroup {
        ...AccordionGroup
      }
      ... on Disclosure {
        ...Disclosure
      }
      ... on TopicList {
        ...TopicList
      }
      ... on LatestArticlesByCategory {
        ...LatestArticlesByCategory
      }
      ... on CCustomComponent {
        ...CustomComponent
      }
      ... on Video {
        ...Video
      }
      ... on TabGroup {
        ...TabGroup
      }
      ... on CAlert {
        ...Alert
      }
      ... on CTestimonialGroup {
        ...TestimonialGroup
      }
      ... on PromotionalBanner {
        id
        heading {
          ...Heading
        }
        description: paragraph {
          ...Paragraph
        }
        brand
        background
        showLogo
      }
      ... on CBannerPrimary {
        __typename
        id
        logoDisplay
        imageHeight
        isContentRight
        BannerPrimary_image: image(locales: $locale) {
          ...Asset
          ...PrimaryBannerImageUrlTransformation
        }
        heading {
          ...Heading
        }
        description: paragraph {
          ...Paragraph
        }
        actionGroup {
          ...ActionGroup
        }
        footnotes {
          ...Paragraph
        }
        divider {
          ...Divider
        }
      }
      ... on CBannerAward {
        __typename
        id
        backgroundImage {
          ...Asset
          ...DefaultImageUrlTransformation
        }
        heading {
          ...Heading
        }
        description: paragraph {
          ...Paragraph
        }
        actionGroup {
          ...ActionGroup
        }
        video {
          ...Video
        }
      }
      ... on CBannerShopfront {
        __typename
        id
        BannerShopfront_image: image {
          ...Asset
          url
        }
        heading {
          ...Heading
        }
        description: paragraph {
          ...Paragraph
        }
        BannerShopfront_footnotes: footnotes {
          ...RichTextEditorWrapper
        }
      }
      ... on ProfileBio {
        ...ProfileBio
      }
      ... on DocumentList {
        ...DocumentList
      }
      ... on KeyStepsBlock {
        ...KeyStepsBlock
      }
      ... on VideoDialogGroup {
        ...VideoDialogGroup
      }
      ... on BannerItemList {
        bannerItems {
          ...BannerItem
        }
      }
    }
  }
  ${HeadingFragmentDoc}
  ${SpaceFragmentDoc}
  ${ResponsiveSpaceFragmentDoc}
  ${ParagraphFragmentDoc}
  ${ActionGroupFragmentDoc}
  ${ButtonLinkFragmentDoc}
  ${LinkFragmentDoc}
  ${TextLinkFragmentDoc}
  ${ImageLinkFragmentDoc}
  ${BannerFragmentDoc}
  ${BannerInlineFragmentDoc}
  ${AssetFragmentDoc}
  ${InlineBannerImageUrlTransformationFragmentDoc}
  ${VideoFragmentDoc}
  ${BoxFragmentDoc}
  ${BrightcoveVideoFragmentDoc}
  ${YouTubeVideoFragmentDoc}
  ${InlineDividerFragmentDoc}
  ${DefaultImageUrlTransformationFragmentDoc}
  ${BadgeFragmentDoc}
  ${BannerArticleFragmentDoc}
  ${CardGroupFragmentDoc}
  ${TileFragmentDoc}
  ${ResponsiveColumnValueFragmentDoc}
  ${CardPrimaryFragmentDoc}
  ${NumericalBlockFragmentDoc}
  ${CardJobAdFragmentDoc}
  ${GalleryFragmentDoc}
  ${IconBlockGroupFragmentDoc}
  ${IconBlockFragmentDoc}
  ${ImageBlockGroupFragmentDoc}
  ${ImageBlockFragmentDoc}
  ${DialogButtonFragmentDoc}
  ${DialogItemFragmentDoc}
  ${StepBlockGroupFragmentDoc}
  ${StepBlockFragmentDoc}
  ${AccordionGroupFragmentDoc}
  ${AccordionFragmentDoc}
  ${DisclosureFragmentDoc}
  ${TopicListFragmentDoc}
  ${ArticleFragmentDoc}
  ${SectionFooterFragmentDoc}
  ${SectionHeaderFragmentDoc}
  ${LatestArticlesByCategoryFragmentDoc}
  ${CustomComponentFragmentDoc}
  ${SearchBoxFragmentDoc}
  ${LoaDataLabFragmentDoc}
  ${WebFormFragmentDoc}
  ${SocialMediaShareFragmentDoc}
  ${FormFragmentDoc}
  ${BraidComponentFragmentDoc}
  ${BugcrowdFormFragmentDoc}
  ${InterviewBuilderFragmentDoc}
  ${InvestorInformationFragmentDoc}
  ${TabGroupFragmentDoc}
  ${TabFragmentDoc}
  ${AlertFragmentDoc}
  ${TestimonialGroupFragmentDoc}
  ${PrimaryBannerImageUrlTransformationFragmentDoc}
  ${DividerFragmentDoc}
  ${RichTextEditorWrapperFragmentDoc}
  ${EmbedSingleLineTextFragmentDoc}
  ${ProfileBioFragmentDoc}
  ${DocumentListFragmentDoc}
  ${DocumentItemFragmentDoc}
  ${KeyStepsBlockFragmentDoc}
  ${VideoDialogGroupFragmentDoc}
  ${DialogVideoFragmentDoc}
  ${BannerItemFragmentDoc}
`;

/**
 * __useGetEntitiesQuery__
 *
 * To run a query within a React component, call `useGetEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntitiesQuery({
 *   variables: {
 *      entityWhere: // value for 'entityWhere'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGetEntitiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEntitiesQuery,
    GetEntitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEntitiesQuery, GetEntitiesQueryVariables>(
    GetEntitiesDocument,
    options,
  );
}
export function useGetEntitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEntitiesQuery,
    GetEntitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEntitiesQuery, GetEntitiesQueryVariables>(
    GetEntitiesDocument,
    options,
  );
}
export type GetEntitiesQueryHookResult = ReturnType<typeof useGetEntitiesQuery>;
export type GetEntitiesLazyQueryHookResult = ReturnType<
  typeof useGetEntitiesLazyQuery
>;
export type GetEntitiesQueryResult = Apollo.QueryResult<
  GetEntitiesQuery,
  GetEntitiesQueryVariables
>;
export const GetPagesContainersDocument = gql`
  query GetPagesContainers(
    $where: PageWhereInput!
    $locale: [Locale!]!
    $stage: Stage!
  ) {
    pages(where: $where, locales: $locale, stage: $stage, orderBy: slug_ASC) {
      id
      containers {
        ...Containers
      }
    }
  }
  ${ContainersFragmentDoc}
  ${BoxFragmentDoc}
  ${TileFragmentDoc}
  ${ResponsiveColumnValueFragmentDoc}
  ${BlocksFragmentDoc}
  ${HeadingFragmentDoc}
  ${SpaceFragmentDoc}
  ${ResponsiveSpaceFragmentDoc}
  ${ParagraphFragmentDoc}
  ${ActionGroupFragmentDoc}
  ${ButtonLinkFragmentDoc}
  ${LinkFragmentDoc}
  ${TextLinkFragmentDoc}
  ${ImageLinkFragmentDoc}
  ${BannerFragmentDoc}
  ${BannerInlineFragmentDoc}
  ${AssetFragmentDoc}
  ${InlineBannerImageUrlTransformationFragmentDoc}
  ${VideoFragmentDoc}
  ${BrightcoveVideoFragmentDoc}
  ${YouTubeVideoFragmentDoc}
  ${InlineDividerFragmentDoc}
  ${DefaultImageUrlTransformationFragmentDoc}
  ${BadgeFragmentDoc}
  ${BannerArticleFragmentDoc}
  ${CardGroupFragmentDoc}
  ${CardPrimaryFragmentDoc}
  ${NumericalBlockFragmentDoc}
  ${CardJobAdFragmentDoc}
  ${GalleryFragmentDoc}
  ${IconBlockGroupFragmentDoc}
  ${IconBlockFragmentDoc}
  ${ImageBlockGroupFragmentDoc}
  ${ImageBlockFragmentDoc}
  ${DialogButtonFragmentDoc}
  ${DialogItemFragmentDoc}
  ${StepBlockGroupFragmentDoc}
  ${StepBlockFragmentDoc}
  ${AccordionGroupFragmentDoc}
  ${AccordionFragmentDoc}
  ${DisclosureFragmentDoc}
  ${TopicListFragmentDoc}
  ${ArticleFragmentDoc}
  ${SectionFooterFragmentDoc}
  ${SectionHeaderFragmentDoc}
  ${LatestArticlesByCategoryFragmentDoc}
  ${CustomComponentFragmentDoc}
  ${SearchBoxFragmentDoc}
  ${LoaDataLabFragmentDoc}
  ${WebFormFragmentDoc}
  ${SocialMediaShareFragmentDoc}
  ${FormFragmentDoc}
  ${BraidComponentFragmentDoc}
  ${BugcrowdFormFragmentDoc}
  ${InterviewBuilderFragmentDoc}
  ${InvestorInformationFragmentDoc}
  ${TabGroupFragmentDoc}
  ${TabFragmentDoc}
  ${AlertFragmentDoc}
  ${TestimonialGroupFragmentDoc}
  ${ProfileBioFragmentDoc}
  ${DocumentListFragmentDoc}
  ${DocumentItemFragmentDoc}
  ${KeyStepsBlockFragmentDoc}
  ${VideoDialogGroupFragmentDoc}
  ${DialogVideoFragmentDoc}
  ${DatalabFragmentDoc}
  ${TipsCardFragmentDoc}
  ${BannerItemFragmentDoc}
`;

/**
 * __useGetPagesContainersQuery__
 *
 * To run a query within a React component, call `useGetPagesContainersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPagesContainersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagesContainersQuery({
 *   variables: {
 *      where: // value for 'where'
 *      locale: // value for 'locale'
 *      stage: // value for 'stage'
 *   },
 * });
 */
export function useGetPagesContainersQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPagesContainersQuery,
    GetPagesContainersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPagesContainersQuery,
    GetPagesContainersQueryVariables
  >(GetPagesContainersDocument, options);
}
export function useGetPagesContainersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPagesContainersQuery,
    GetPagesContainersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPagesContainersQuery,
    GetPagesContainersQueryVariables
  >(GetPagesContainersDocument, options);
}
export type GetPagesContainersQueryHookResult = ReturnType<
  typeof useGetPagesContainersQuery
>;
export type GetPagesContainersLazyQueryHookResult = ReturnType<
  typeof useGetPagesContainersLazyQuery
>;
export type GetPagesContainersQueryResult = Apollo.QueryResult<
  GetPagesContainersQuery,
  GetPagesContainersQueryVariables
>;
export const GetPagesPromotionalBannerDocument = gql`
  query GetPagesPromotionalBanner(
    $where: PageWhereInput!
    $locale: [Locale!]!
    $stage: Stage!
  ) {
    pages(where: $where, locales: $locale, stage: $stage, orderBy: slug_ASC) {
      id
      promotionalBanner {
        ...PromotionalBanner
      }
    }
  }
  ${PromotionalBannerFragmentDoc}
  ${HeadingFragmentDoc}
  ${SpaceFragmentDoc}
  ${ResponsiveSpaceFragmentDoc}
  ${ParagraphFragmentDoc}
`;

/**
 * __useGetPagesPromotionalBannerQuery__
 *
 * To run a query within a React component, call `useGetPagesPromotionalBannerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPagesPromotionalBannerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagesPromotionalBannerQuery({
 *   variables: {
 *      where: // value for 'where'
 *      locale: // value for 'locale'
 *      stage: // value for 'stage'
 *   },
 * });
 */
export function useGetPagesPromotionalBannerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPagesPromotionalBannerQuery,
    GetPagesPromotionalBannerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPagesPromotionalBannerQuery,
    GetPagesPromotionalBannerQueryVariables
  >(GetPagesPromotionalBannerDocument, options);
}
export function useGetPagesPromotionalBannerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPagesPromotionalBannerQuery,
    GetPagesPromotionalBannerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPagesPromotionalBannerQuery,
    GetPagesPromotionalBannerQueryVariables
  >(GetPagesPromotionalBannerDocument, options);
}
export type GetPagesPromotionalBannerQueryHookResult = ReturnType<
  typeof useGetPagesPromotionalBannerQuery
>;
export type GetPagesPromotionalBannerLazyQueryHookResult = ReturnType<
  typeof useGetPagesPromotionalBannerLazyQuery
>;
export type GetPagesPromotionalBannerQueryResult = Apollo.QueryResult<
  GetPagesPromotionalBannerQuery,
  GetPagesPromotionalBannerQueryVariables
>;
export const GetPagesBannerDocument = gql`
  query GetPagesBanner(
    $where: PageWhereInput!
    $locale: [Locale!]!
    $stage: Stage!
    $localesWithDefault: [Locale!]!
  ) {
    pages(where: $where, locales: $locale, stage: $stage, orderBy: slug_ASC) {
      id
      banner {
        __typename
        ... on CBannerPrimary {
          ...BannerPrimary
        }
        ... on CBannerAward {
          ...BannerAward
        }
        ... on CBannerShopfront {
          ...BannerShopfront
        }
      }
    }
  }
  ${BannerPrimaryFragmentDoc}
  ${AssetFragmentDoc}
  ${PrimaryBannerImageUrlTransformationFragmentDoc}
  ${HeadingFragmentDoc}
  ${SpaceFragmentDoc}
  ${ResponsiveSpaceFragmentDoc}
  ${ParagraphFragmentDoc}
  ${ActionGroupFragmentDoc}
  ${ButtonLinkFragmentDoc}
  ${LinkFragmentDoc}
  ${TextLinkFragmentDoc}
  ${ImageLinkFragmentDoc}
  ${DividerFragmentDoc}
  ${DefaultImageUrlTransformationFragmentDoc}
  ${BannerAwardFragmentDoc}
  ${VideoFragmentDoc}
  ${BoxFragmentDoc}
  ${BrightcoveVideoFragmentDoc}
  ${YouTubeVideoFragmentDoc}
  ${BannerShopfrontFragmentDoc}
  ${RichTextEditorWrapperFragmentDoc}
  ${EmbedSingleLineTextFragmentDoc}
`;

/**
 * __useGetPagesBannerQuery__
 *
 * To run a query within a React component, call `useGetPagesBannerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPagesBannerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPagesBannerQuery({
 *   variables: {
 *      where: // value for 'where'
 *      locale: // value for 'locale'
 *      stage: // value for 'stage'
 *      localesWithDefault: // value for 'localesWithDefault'
 *   },
 * });
 */
export function useGetPagesBannerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPagesBannerQuery,
    GetPagesBannerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPagesBannerQuery, GetPagesBannerQueryVariables>(
    GetPagesBannerDocument,
    options,
  );
}
export function useGetPagesBannerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPagesBannerQuery,
    GetPagesBannerQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPagesBannerQuery, GetPagesBannerQueryVariables>(
    GetPagesBannerDocument,
    options,
  );
}
export type GetPagesBannerQueryHookResult = ReturnType<
  typeof useGetPagesBannerQuery
>;
export type GetPagesBannerLazyQueryHookResult = ReturnType<
  typeof useGetPagesBannerLazyQuery
>;
export type GetPagesBannerQueryResult = Apollo.QueryResult<
  GetPagesBannerQuery,
  GetPagesBannerQueryVariables
>;
export const GetParentSectionDocument = gql`
  query GetParentSection($where: SectionWhereInput) {
    sections(where: $where) {
      id
      parentSection {
        sectionName
        displayName
      }
    }
  }
`;

/**
 * __useGetParentSectionQuery__
 *
 * To run a query within a React component, call `useGetParentSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParentSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParentSectionQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetParentSectionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetParentSectionQuery,
    GetParentSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetParentSectionQuery, GetParentSectionQueryVariables>(
    GetParentSectionDocument,
    options,
  );
}
export function useGetParentSectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetParentSectionQuery,
    GetParentSectionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetParentSectionQuery,
    GetParentSectionQueryVariables
  >(GetParentSectionDocument, options);
}
export type GetParentSectionQueryHookResult = ReturnType<
  typeof useGetParentSectionQuery
>;
export type GetParentSectionLazyQueryHookResult = ReturnType<
  typeof useGetParentSectionLazyQuery
>;
export type GetParentSectionQueryResult = Apollo.QueryResult<
  GetParentSectionQuery,
  GetParentSectionQueryVariables
>;
